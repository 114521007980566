const defaultBannerText = 'You\'ll need to complete each step before moving on to the next.';

export default [
    {
        key: 'panel',
        bannerTitle: 'Here we go!',
        bannerText: defaultBannerText,
        title: 'Panel Setup',
        description: 'Position, power up, and connect your panel to Frontpoint.',
        action: /*@ngInject*/ (context, step, $log, $state) => {
            $log.debug(step.title + ' action called');
            $state.go('instruction.home');
        }
    },
    {
        key: 'sensor',
        bannerTitle: 'Doing Great!',
        bannerText: defaultBannerText,
        title: 'Sensor Installation',
        description: 'We\'ll position, install, name, and test your system sensors connection to the control panel.',
        action: /*@ngInject*/ (context, step, $log, $state) => {
            $log.debug(step.title + ' action called');
            $state.go('sensor.home');
        }
    },
    {
        key: 'signage',
        bannerTitle: 'Almost done!',
        bannerText: defaultBannerText,
        title: 'Accessories, Yard Sign & Window Decal Placement',
        description: 'Set up and place your Yard Sign and Window Decal.',
        action: /*@ngInject*/ (context, step, $log, $state, constants) => {
            $log.debug(step.title + ' action called');
            $state.go(constants.routerStates.signageAccessories);
        }
    },
    {
        key: 'automation',
        bannerTitle: 'JUST ONE MORE!',
        bannerText: defaultBannerText,
        title: 'Home Automation (Cameras & Locks)',
        description: 'Set up your cameras, locks and other smart home devices.',
        action: /*@ngInject*/ (context, step, $log, $state, constants) => {
            $log.debug(step.title + ' action called');
            $state.go(constants.routerStates.cameraInstructions);
        }
    },
    {
        key: 'training and system activation',
        bannerTitle: 'Last step!',
        bannerText: defaultBannerText,
        title: 'Training and System Activation',
        description: 'Activate and learn how to use your Frontpoint Security System.',
        action: /*@ngInject*/ (context, step, $log, $state) => {
            $log.debug(step.title + ' action called');
            $state.go('training.home');
        }
    }
];