import * as _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';

import './helpOverlay.scss';
import template from './helpOverlay.html';

class HelpModalComponent {
    /*@ngInject*/
    constructor($ngRedux, constants, dataContext, actionContext, $scope, $state) {
        this.$ngRedux = $ngRedux;
        this.constants = constants;
        this.actionContext = actionContext;
        this.$scope = $scope;
        this.configurationsService = dataContext.configurations;
        this.$state = $state;

        this.$scope.$on('mobile-angular-ui.state.changed.uiSidebarRight', function (e, newVal, oldVal) {
            if (newVal) {
                if ($ngRedux.getState().account.airfxFlow.targetSku === constants.panelVersions.iqPanel) {
                    $scope.customerSupportPhone = constants.customerSupportIQ.phone;
                } else {
                    $scope.customerSupportPhone = $ngRedux.getState().account.origin.startedOnAmazon
                        ? constants.customerSupportHub.amazonPhone
                        : $ngRedux.getState().account.user.isIq4Panel ? constants.customerSupportIQ.phone : constants.customerSupportHub.phone;
                }

                if ($ngRedux.getState().account.customer.isVideoOnly === true) {
                    $scope.customerSupportPhone = constants.customerSupport.videoOnly;
                }

                var cleaned = ('' + $scope.customerSupportPhone).replace(/\D/g, '');
                var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
                $scope.formattedCustomerSupportPhone = [match[1] ? '1' : '', match[2], match[3], match[4]].join('-');
            }
            $scope.isSpecificHelpPage = constants.specificHelpPages.includes($state.current.name) && $ngRedux.getState().account.customer.isVideoOnly === false;
        });

    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    _mapState(state) {
        let userGuide = `//${this.constants.urls.standardUserGuide}`;
        if (state.system.panel.type === this.constants.panelVersions.iqPanel) {
            userGuide = `//${this.constants.urls.touchScreenUserGuide}`;
        }

        return {
            userGuide: userGuide,
            hours: state.application.config.businessHours,
            targetSku: state.account.airfxFlow.targetSku,
            isIq4Panel: state.account.user.isIq4Panel
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: HelpModalComponent
};
