import template from './bluetooth.html';

import * as _ from 'lodash';

class BluetoothController {
    /*@ngInject*/
    constructor($log, actionContext, constants, dataContext, $ngRedux) {
        this.$log = $log;
        this.actionContext = actionContext;
        this.constants = constants;
        this.dataContext = dataContext;
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.actionContext.panel.fetchLastMessageDate();
        this.isIq4Panel = this.$ngRedux.getState().account.user.isIq4Panel;
        this.isInitialOrder = this.$ngRedux.getState().account.user.isInitialOrder;
    }
}

export default {
    template: template,
    bindings: {},
    controller: BluetoothController
};