import * as angular from 'angular';

import bindFilter from './bind.filter';
import sortByFilter from './sortBy.filter';
import telFilter from './tel.filter';
import trustedHtmlFilter from './trustedHtml.filter';
import trustedResourceUrlFilter from './trustedResourceUrl.filter';
import trustedUrlFilter from './trustedUrl.filter';

export default angular.module('fp:customerportal:common:filter', [])
    .filter('bind', bindFilter)
    .filter('sortBy', sortByFilter)
    .filter('tel', telFilter)
    .filter('trustedHtml', trustedHtmlFilter)
    .filter('trustedResourceUrl', trustedResourceUrlFilter)
    .filter('trustedUrl', trustedUrlFilter);