import * as _ from 'lodash';

import template from './edit.html';

const minContacts = 2;
const maxContacts = 3;

class EditController {
    /*@ngInject*/
    constructor($filter, $log, $ngRedux, $state, $scope, constants, actionContext, $timeout) {
        // services
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$scope = $scope;
        this.constants = constants;
        this.actionContext = actionContext;
        this.$filter = $filter;
        this.$timeout = $timeout;
        // data
        this.hasError = false;
        this.hasDuplicate = false;
        this.message = null;
        this.isFetching = false;
        this.isPosting = false;
        this.contacts = [];
        this.isAmazonUser = false;
    }

    $onInit() {
        this.actionContext.predispatch.fetchContacts()
            .finally(() => {
                this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
                if (_.isNil(this.contacts)) {
                    this.contacts = [];
                }

                if (this.contacts.length < 2) {
                    this.doCreate();
                }
            });

        if (this.$ngRedux.getState().account.user.isAmazonUser ||
            this.$ngRedux.getState().account.origin.startedOnAmazon) {
            this.isAmazonUser = true;
        }

        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
        this.isVideoOnly = this.$ngRedux.getState().account.customer.isVideoOnly;
    }

    clearError() {
        this.actionContext.predispatch.clearError();
        this.hasDuplicate = false;
    }

    $onDestroy() {
        this.unsubscribe();
    }

    doSave(form) {
        form.$commitViewValue();

        if (!form.$valid) {
            return;
        }
        this.hasDuplicate = false;

        _.forEach(this.contacts, (c) => { return c.Phone = _.replace(c.Phone, RegExp('-', 'g'), ''); });

        let groupedContacts = _.groupBy(this.contacts,
            (c) => {
                return c.Phone;
            });

        _.forEach(groupedContacts,
            (gc) => {
                if (gc.length > 1) {
                    this.hasDuplicate = true;
                    return;
                }
            });

        // do not try to post update with duplicate pre-dispatch contacts
        if (this.hasDuplicate) {
            return;
        }

        _.forEach(this.contacts, (contact) => {
            contact.Phone = this.$filter('tel')(contact.Phone);
        });


        this.actionContext.predispatch.updateContacts(this.contacts, this.isAmazonUser)
            .then(() => {
                this.$state.go('predispatch.home');
            });
    }

    doCreate() {
        if (!this.canCreate()) {
            return null;
        }

        let newContact = {
            IsPreDispatch: true,
            Priority: this.contacts.length + 1
        };

        this.contacts.push(newContact);
        this._updatePriorities();
    }

    /**
     * Returns a flag indicating if a new contact can be
     * created. A contact can only be created if there
     * are currently less than the 1 - max number of contacts.
     * 
     * @returns {} 
     */
    canCreate() {
        let canCreate = (this.contacts.length < maxContacts) || (_.isNil(this.contacts.length));
        return canCreate;
    }

    doDelete(contact) {
        if (!this.canDelete(contact)) {
            return null;
        }

        let idx = this.getIndex(contact);
        this.contacts.splice(idx, 1);
        this._updatePriorities();
    }

    /**
     * Returns a flag indicating if a contact can
     * be deleted. A contact can only be deleted
     * if there are currently more than the 1 - min number)
     * of contacts.
     * 
     * @returns {} 
     */
    canDelete(contact) {
        let index = this.getIndex(contact);

        let isFound = index !== -1;
        let canDelete = this.contacts.length > minContacts;
        return isFound && canDelete;
    }

    doPromote(contact) {
        if (!this.canPromote(contact)) {
            return null;
        }
        this._activateAnimation(contact, true);

        let curIdx = this.getIndex(contact);
        let newIdx = curIdx - 1;

        this._swap(this.contacts, curIdx, newIdx);
        this._updatePriorities();
    }

    /**
     * Returns a flag indicating if a contact can be
     * promoted. A contact can be promoted if another
     * contact has a higher priority.
     * 
     * @param {} contact 
     * @returns {} 
     */
    canPromote(contact) {
        let index = this.getIndex(contact);

        let canPromote = index > 0;
        return canPromote;
    }

    doDemote(contact) {
        if (!this.canDemote(contact)) {
            return null;
        }
        this._activateAnimation(contact, false);

        let curIdx = this.getIndex(contact);
        let newIdx = curIdx + 1;

        this._swap(this.contacts, curIdx, newIdx);
        this._updatePriorities();
    }

    /**
     * Returns a flag indicating if a contact can be
     * demoted. A contact can be demoted if another
     * contact has a lower priority.
     * 
     * @param {} contact 
     * @returns {} 
     */
    canDemote(contact) {
        let index = this.getIndex(contact);

        let canDemote = index < this.contacts.length - 1;
        return canDemote;
    }

    /**
     * Gets the index for the given contact.
     * 
     * @param {} contact 
     * @returns {} 
     */
    getIndex(contact) {
        return this.contacts.indexOf(contact);
    }

    _activateAnimation(contact, isSlideUp) {
        contact.slideUp = isSlideUp;
        this.$timeout(() => {
            _.forEach(this.contacts, (contact) => {
                contact.slideUp = null;
            });
        }, 250);
    }

    _updatePriorities() {
        for (var i = 0; i < this.contacts.length; i++) {
            this.contacts[i].Priority = i + 1;
        }
    }

    _swap(array, x, y) {
        let tmp = array[x];
        array[x] = array[y];
        array[y] = tmp;
    }

    _mapState(state) {
        let map = {};

        let isUpdating = this.isUpdating || state.account.predispatch.isUpdating;
        if (!isUpdating) {
            map.contacts = state.account.predispatch.contacts;

        }

        map.hasError = state.account.predispatch.hasError;
        map.isUpdating = state.account.predispatch.isUpdating;
        map.message = state.account.predispatch.message;

        return _.cloneDeep(map);
    }
}

export default {
    template: template,
    bindings: {},
    controller: EditController
};