import * as _ from 'lodash';
import ngRedux from 'ng-redux';
import { Unsubscribe } from 'redux';
import DataContext from "@app/common/data/dataContext.service";

export class WifiHomeController {
    public isSearching: boolean;
    public customerPortalUrl: string;
    public showModal: boolean;
    public isConnected: boolean;
    public hasHub: boolean;
    public hasTouchscreen: boolean;
    public networkName: string;
    public targetSku: string;
    public swapType: string;
    public isModSwap: boolean;
    public isEmailLive: boolean;

    private unsubscribe: Unsubscribe;

    constructor(
        private $ngRedux: ngRedux.INgRedux,
        public constants: any,
        public $state: any,
        private navigation: any,
        private dataContext: DataContext,
        private SharedState: any
    ) {
        'ngInject';
        this.isSearching = false;
        this.customerPortalUrl = constants.urls.customerPortal;
        this.swapType = this.$ngRedux.getState().system.swap.swapType;
        this.setIsEmailLive();
    }

    private async setIsEmailLive() {
        this.isEmailLive = await this.dataContext.email.isEmailLive();
    }

    public $onInit() {
        this.isModSwap = this.swapType === this.constants.swapType.mod;
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this))(this);
    }

    public $onDestroy() {
        this.unsubscribe();
    }

    public onNegativeClick() {
        this.showModal = false;
    }

    public onPositiveClick() {
        this.dataContext.email.sendEmail("WifiNetwork", this.targetSku);
        this.navigation.goNext();
    }

    public goBack() {

        if (this.swapType === this.constants.swapType.mod) {
            if (this.targetSku === this.constants.panelVersions.iqPanel) {
                this.$state.go('instruction.plugin');
            } else {
                this.$state.go('instruction.keypadswap');
            }

        } else {
            this.$state.go('usercode.home');
        }
    }

    public onPositiveSkipClick() {
        if (this.isEmailLive) {
            this.dataContext.email.sendEmail("WifiNetwork");
        }
        this.navigation.goNext();
    }

    public onSkip() {
        if (this.isConnected) {
            this.navigation.goNext();
        } else {
            this.SharedState.turnOn('skipWifi');
        }
    }

    public mapState(state: any) {
        const hasHub = state.system.panel.type === this.constants.panelVersions.climaxHub;
        const hasTouchscreen = _.some(state.account.order.itemsByID,
            (i: any) => _.some(this.constants.touchscreens.skus, (t: string) => i.ProductSku === t));

        return {
            hasHub,
            hasTouchscreen,
            targetSku: state.account.airfxFlow.targetSku,
            isConnected: state.system.wifi.isConnected,
            networkName: state.system.wifi.networkName,
            isInitialOrder: state.account.user.isInitialOrder,
            isIq4Panel: state.account.user.isIq4Panel
        };
    }
}

export const wifiHomeComponent = {
    template: require('./home.html'),
    bindings: {},
    controller: WifiHomeController
};
