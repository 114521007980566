import * as _ from 'lodash';

import template from './passcode.html';

class PasscodeComponent {
    /*@ngInject*/
    constructor($document, $log, $ngRedux, $state, actionContext, constants, navigation) {
        this.$document = $document;
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.actionContext = actionContext;
        this.constants = constants;
        this.navigation = navigation;

        this.maxLength = 11;
        this.password = '';
        this.verifyPassword = '';
        this.passcodeChangeRequired = true;
        this.maskedPasscode = '';
        this.currentPasscode = '';
        this.samePasscodeAsPrevious = false;
        this.isValidPasscode = true;
        this.isAmazonUser = false;
        this.typePassword = true;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        if (this.targetSku === this.constants.panelVersions.iqPanel) {
            this.customerSupportPhone = this.constants.customerSupportIQ.phone;
        } else {
            this.customerSupportPhone = !this.$ngRedux.getState().account.origin.startedOnAmazon
                ? this.constants.customerSupportHub.phone
                : this.constants.customerSupportHub.amazonPhone;
        }

        this.actionContext.customer.stopLoading();

        if (!_.isNil(this.passcode)) {
            this.maskPasscode(this.passcode);
            this.currentPasscode = this.passcode;

            if (this.currentPasscode.match(/^[0-9]{1,45}$/)) {
                this.passcodeChangeRequired = true;
            } else {
                this.passcodeChangeRequired = false;
            }
            this.passcode = '';
        }
        if (this.$ngRedux.getState().account.user.isAmazonUser ||
            this.$ngRedux.getState().account.origin.startedOnAmazon) {
            this.isAmazonUser = true;
        }
    }

    maskPasscode(passcode) {
        for (var i = 0; i < passcode.length; i++) {
            if (i !== 0 && i !== passcode.length - 1) {
                this.maskedPasscode += '*';
            } else {
                this.maskedPasscode += passcode[i];
            }
        }
    }

    $onDestroy() {
        this.actionContext.customer.stopLoading();
        this.unsubscribe();
    }

    skip() {
        this.navigation.goNext();
    }

    togglePassword() {
        this.typePassword = !this.typePassword;
    }

    handleSubmit() {
        const form = this.passcodeEditForm;
        const newPasscode = this.passcode;
        
        if (!_.isNil(this.currentPasscode) && (_.isNil(this.passcode) || _.isEmpty(this.passcode)) && !this.passcodeChangeRequired) {
            this.navigation.goNext();
            return;
        }

        if (!_.isNil(this.passcode) &&
            !_.isNil(this.currentPasscode) &&
            (this.currentPasscode.toLowerCase() === this.passcode.toLowerCase())) {
            form.$invalid = true;
        }

        if (!form || form.$invalid) {
            this.actionContext.customer.stopLoading();
            return;
        }

        this.actionContext.customer.checkPasscode(newPasscode).then(() => {
            this.isValidPasscode = true;
            return this.actionContext.customer.updatePasscode(newPasscode)
                .then(() => {
                    this.navigation.goNext();
                });
        }).catch(() => {
            this.confirmPasscode = '';
            this.isValidPasscode = false;
            this.actionContext.customer.stopLoading();
        });
    }

    validatePasscode() {
        this.isValidPasscode = true;
        if (!_.isNil(this.passcode) &&
            (this.passcode.trim().toLowerCase() === this.currentPasscode.trim().toLowerCase())) {
            return this.samePasscodeAsPrevious = true;
        }
        return this.samePasscodeAsPrevious = false;
    }

    _mapState(state) {
        return {
            username: state.account.user.name,
            isUpdating: state.account.customer.isUpdating,
            hasError: state.account.customer.hasError,
            message: state.account.customer.message,
            passcode: state.account.customer.value.Passcode,
            lastName: state.account.customer.value.LastName,
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: PasscodeComponent
};