import { combineReducers } from 'redux';

import staticReducer from '../static.reducer';

import customerReducer from './customer.reducer';
import orderReducer from './order.reducer';
import passwordReducer from './password.reducer';
import predispatchReducer from './predispatch.reducer';
import premisesReducer from './premises.reducer';
import userReducer from './login.reducer';
import airfxFlowReducer from './airfxFlow.reducer';
import passcodeReducer from './passcode.reducer';
import kitCustomerReducer from './kitCustomer.reducer';
import customerBillingInfoReducer from './customerBillingInfo.reducer';
import originReducer from './origin.reducer';
import smsReducer from './sms.reducer';

import { ACTIVATE_ACCOUNT_SUCCESS } from '../../action/account.actions';

const accountReducer = (state = false, action: any) => {
    switch (action.type) {
        case ACTIVATE_ACCOUNT_SUCCESS:
            return true;
        default:
            return state;
    }
};

export default combineReducers({
    ignore: staticReducer(['customer', 'user']),
    customer: customerReducer,
    order: orderReducer,
    password: passwordReducer,
    predispatch: predispatchReducer,
    premises: premisesReducer,
    user: userReducer,
    isActivated: accountReducer,
    airfxFlow: airfxFlowReducer,
    passcode: passcodeReducer,
    kitCustomer: kitCustomerReducer,
    customerBillingInfo: customerBillingInfoReducer,
    origin: originReducer,
    sms: smsReducer
});
