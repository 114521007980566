import './indeterminateProgressOverlay.scss';
import template from './indeterminateProgressOverlay.html';

class IndeterminateProgressOveralyController {
    /*@ngInject*/
    constructor($interval) {
        this.$interval = $interval;
    }

    $onInit() {
        if (this.statusChanges && this.statusChanges[0]) {
            this.statusText = this.statusChanges[0];
        }

        if (this.statusChanges) {
            this.statusTextInterval = this.$interval(count => {
                    if (this.statusChanges[count]) {
                        this.statusText = this.statusChanges[count];
                    }
                },
                1000);
        }
    }
}

export default {
    template: template,
    bindings: {
        navbarTitle: '@',
        title: '@',
        text: '@',
        show: '<',
        warning: '@', //text to show warning
        statusChanges: '<', // dictionary of {int: seconds, string: text}
        statusChangeTitle: '@'
    },
controller: IndeterminateProgressOveralyController
};