import * as _ from 'lodash';

import template from './videos.html';

const CATEGORY = 'training';

class VideosController {
    /*@ngInject*/
    constructor($ngRedux, constants, $state, segment, actionContext) {
        this.$ngRedux = $ngRedux;
        this.constants = constants;
        this.$state = $state;
        this.segment = segment;
        this.actionContext = actionContext;
        this.hasKeypad = false;
    }
    $onInit() {
        this.panelType = this.constants.panelVersions.climaxHub;
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);

        const deviceId = this.$state.params.sensorId;

        this.actionContext.panel.CheckKeypadStatus(deviceId).then((result) => {
            if (result === "Active") {
                this.disableContinue = false;
                this.Activated = true;
                clearInterval(timerId);
            }
        });

        this.hasKeypad = this.actionContext.order.hasKeypad();
    }

    $onDestroy() {
        this.unsubscribe();
    }

    onComplete() {
        this.segment.registerEvent(this.constants.events.segment.panelSwapClickInstructionsNextButton, {});
        this.actionContext.modSwap.onModSwapComplete();
        
        //only move to armAway if customer has keypad - this was a story requirement
        if (this.hasKeypad === true) {
            this.$state.go('airfx.armAway');
        }
        else {
            //if no keypad, we go to modswap success
            this.$state.go('airfx.modswapSuccess');
        }
        
    }

    _mapState(state) {  
        return {
            panel: state.system.panel,
            videos: state.application.config.video.items
        };
    }

    videoFilter(video) {
        return video.category === CATEGORY &&
          video.condition.panel === this.panelType;
    }
}

export default {
    template: template,
    bindings: {},
    controller: VideosController
};