import template from './activate.html';
import * as _ from 'lodash';

class ActivateComponent {
    /*@ngInject*/
    constructor(constants) {
        this.constants = constants;
        this.hasError = false;
    }

    click(form) {
        if (form.$dirty) {
            this.onSubmitCode({ activationCode: this.activationCode, form: form })
                .then(() => {
                    this.activationCode = '';
                    this.hasError = true;
                });
        }
    }
}

export default {
    template: template,
    bindings: {
        isBusy: '<',
        onSubmitCode: '&'
    },
    controller: ActivateComponent
};