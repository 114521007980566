class AddressService {
    /*@ngInject*/
    constructor(Restangular) {
        this.restangular = Restangular;
    }

    validate(obj) {
        // Returns a true if the address is valid, otherwise false or undefined 
        // is returned.
        return this.restangular
            .all('address')
            .customGET('validate', obj)
            .then((result) => {
                let stripped = result.plain();
                return stripped;
            });
    }

    isSaleRestricted(obj) {
        return this.restangular
            .all('address')
            .one('isSaleRestricted')
            .customPOST(obj);

    }
}

export default AddressService;