import { IService } from 'restangular';

class LoggingService {

    restangular: IService

    /*@ngInject*/
    constructor(Restangular: IService) {
        'ngInject';
        this.restangular = Restangular;
    }

    logActivity(state: any) {
        return this.restangular
            .one('logging')
            .one('log-activity')
            .customPUT(state);
    }
}

export default LoggingService;