import * as _ from 'lodash';
import moment from 'moment';

import AbstractTestController from './AbstractTestController';

import template from './contact.html';

class ContactTestController extends AbstractTestController {
	/*@ngInject*/
    constructor ($document, $log, $ngRedux, $state, actionContext, constants) {
        super($document, $log, $ngRedux, $state, constants);

        this.constants = constants;
        this.actionContext = actionContext;

        this.step = 'step-1';
        this.showErrorModal = false;
        this.showTimeoutModal = false;
        // look for sensor status updates up to 1 hour in the past
        this.startDate = moment.utc().add(-1, 'h'); 
    }

    $onInit() {
        super.$onInit();
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
    }

    /**
     * Complete the provided step.
     * 
     * @param {string} step A string the reprsents the step to complete.
     */
    completeStep(step) {
        switch (step) {
            case 'step-1':
                this.step = 'instructions-step-2';
                break;
            case 'instructions-step-2':
                this.step = 'step-2';
                this.startTest();
                break;
            case 'step-2':
                this.$state.go('sensor.item.complete');
                break;
        }
    }

    /**
     * Returns true if the provided step is the current step; otherwise, false.
     * 
     * @param {...string} steps One or more strings that represents a step.
     * @returns {boolean} True if any of the provided steps are the current step; otherwise, false. 
     */
    onStep() {
        return _.some(arguments, (value) => this.step === value);
    }

    canSupportSensor(sensor) {
        return this.isDoorWindow(sensor) || this.isGarageDoor(sensor);
    }

    getName() {
        return 'Contact';
    }

    startTest() {
        this.showErrorModal = false;
        this.showTimeoutModal = false;

        switch (this.step) {
        case 'step-1':
                this._handlePoll(this.constants.deviceStatus.closed.Name);
            break;
        case 'step-2':
                this._handlePoll(this.constants.deviceStatus.opened.Name);
            break;
        }
    }

    stopTest() {
        this.actionContext.device.cancelPoll(this.poll);
    }

    _handlePoll(desiredStatus) {
        const deviceManager = this.actionContext.device;
        const maxInterval = this.constants.polling.tests.contact.pollingInterval;
        const maxAttempts = this.constants.polling.tests.contact.maxIntervalCount;

        this.poll = deviceManager.startPoll(this.sensor, desiredStatus, maxInterval, maxAttempts);
        this.poll.finally(() => {
            const isOpened = this.sensor.StatusType === this.constants.deviceStatus.opened.Name;
            const isClosed = this.sensor.StatusType === this.constants.deviceStatus.closed.Name;
            if (!isOpened && !isClosed) {
                this.showErrorModal = true;
            }
        });
    }
}

export default {
    template: template,
    bindings: { },
    controller: ContactTestController
};