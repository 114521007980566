import template from './progressRound.html';
import './progressRound.scss';

class ProgressRoundComponent {
    /*@ngInject*/
    constructor($timeout, $interval) {
        this.$timeout = $timeout;
        this.$interval = $interval;
        this.currentPercentage = 0;
    }

    //logic for automated seconds
    $onInit() {
        if (this.showAutomated) {
            this._runAutomated(0);
        }

        if (this.statusChanges && this.statusChanges[0]) {
            this.statusText = this.statusChanges[0];
        }

        if (this.statusChanges) {
            this.statusTextInterval = this.$interval(count => {
                    if (this.statusChanges[count]) {
                        this.statusText = this.statusChanges[count];
                    }
                },
                1000);
        }
    }

    $onDestroy() {
        this.$interval.cancel(this.statusTextInterval);
    }

    _runAutomated(current) {
        return this._automatedTimeout(current)
            .then(() => {
                if (this.currentPercentage < 100) {
                    current++;
                    return this._runAutomated(current);
                } else
                    return this.onComplete();
            });
    }

    _automatedTimeout(currentPercentage) {
        return this.$timeout(() => {
                this.currentPercentage = currentPercentage;
            },
            this.automatedSeconds * 10);
    }
}

export default {
    template: template,
    bindings: {
        title: '<',
        text: '<', //text to display on loading
        warning: '<', //text to show warning
        show: '<', 
        showAutomated: '<', //flag to do automated percentage based on seconds
        automatedSeconds: '<', //if showAutomated true, then use automated progress based on seconds
        currentPercentage : '<', //if not then you can manually set the currentPercentage
        onComplete: '&', // function to handle after the progress is completed
        statusChanges: '<' // dictionary of {int: seconds, string: text}
    },
    controller: ProgressRoundComponent
};