import * as _ from 'lodash';

import template from './complete.html';

class SensorItemCompleteController {
    /*@ngInject*/
    constructor ($ngRedux, $state, actionContext) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.actionContext = actionContext;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    _mapState(state) {
        return {
            sensor: state.system.devices.sensorsByID[state.system.devices.selectedSensorID] || this.sensor,
            isInitialOrder: state.account.user.isInitialOrder,
            isIq4Panel: state.account.user.isIq4Panel
        };
    }
}

export default {
    template: template,
    bindings: { },
    controller: SensorItemCompleteController
};