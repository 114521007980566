import SmsActions from './sms.actions';

export default class ActionContext {
    public sms: SmsActions;
    public customerBillingInfo: any;
    public kitCustomer: any;
    public equipmentSetup: any;
    public radioSwap: any;
    public modSwap: any;
    public airfxFlow: any;
    public zwave: any;
    public code: any;
    public partner: any;
    public account: any;
    public wifi: any;
    public tosOptions: any;
    public tos: any;
    public testmode: any;
    public product: any;
    public premises: any;
    public predispatch: any;
    public password: any;
    public panel: any;
    public order: any;
    public login: any;
    public device: any;
    public customer: any;
    public common: any;
    public app: any;

    constructor(
        appActions: any,
        commonActions: any,
        customerActions: any,
        deviceActions: any,
        loginActions: any,
        orderActions: any,
        panelActions: any,
        passwordActions: any,
        predispatchActions: any,
        premisesActions: any,
        productActions: any,
        testmodeActions: any,
        tosActions: any,
        tosOptionsActions: any,
        wifiActions: any,
        accountActions: any,
        partnerActions: any,
        codeActions: any,
        zwaveActions: any,
        airfxflowActions: any,
        modSwapActions: any,
        radioSwapActions: any,
        equipmentSetupActions: any,
        kitCustomerActions: any,
        customerBillingInfoActions: any,
        smsActions: SmsActions) {
        'ngInject';
        this.sms = smsActions;
        this.customerBillingInfo = customerBillingInfoActions;
        this.kitCustomer = kitCustomerActions;
        this.equipmentSetup = equipmentSetupActions;
        this.radioSwap = radioSwapActions;
        this.modSwap = modSwapActions;
        this.airfxFlow = airfxflowActions;
        this.zwave = zwaveActions;
        this.code = codeActions;
        this.partner = partnerActions;
        this.account = accountActions;
        this.wifi = wifiActions;
        this.tosOptions = tosOptionsActions;
        this.tos = tosActions;
        this.testmode = testmodeActions;
        this.product = productActions;
        this.premises = premisesActions;
        this.predispatch = predispatchActions;
        this.password = passwordActions;
        this.panel = panelActions;
        this.order = orderActions;
        this.login = loginActions;
        this.device = deviceActions;
        this.customer = customerActions;
        this.common = commonActions;
        this.app = appActions;
    }
}
