import moment from 'moment';

import template from './complete.html';

class CompleteComponent {
    /*@ngInject*/
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState)(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    _mapState(state) {
        return {
            isTestMode: state.system.testmode.onTest,
            monitoringStartDate: moment(state.system.testmode.value).add(1, 'minutes')
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: CompleteComponent
};