import * as _ from 'lodash';

class AbstractTestController {
    constructor ($document, $log, $ngRedux, $state, constants) {
        this.$document = $document;
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;

        this.constants = constants;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState)(this);

        if (!this.canSupportSensor(this.sensor)) {
            throw `${this.getName()} does not support the provided sensor type.`;
        }

        if (!_.isNil(this.constants.debug) && this.constants.debug) {      
            this.$document.bind('keypress', _.bind(this._onKeyPress, this));
        }

        this.startTest();
    }

    $onDestroy() {
        this.stopTest();

        if (!_.isNil(this.constants.debug) && this.constants.debug) {      
            this.$document.unbind('keypress');
        }

        this.unsubscribe();
    }

    /**
     * Returns true if the provided sensor is a door/window sensor; otherwise, false.
     * 
     * @public
     * @param {Object} sensor A sensor object.
     * @returns {boolean} True if the current sensor is a door/window sensor; otherwise, false. 
     */
    isDoorWindow(sensor) {
        return ['DWS', 'RDW', 'TX-E221', 'GDOOR2', 'RE122', 'RE101'].indexOf(sensor.ProductSku) >= 0;
    }

    /**
     * Returns true if the provided sensor is a garage door sensor; otherwise, false.
     * 
     * @public
     * @param {Object} sensor A sensor object.
     * @returns {boolean} True if the current sensor is a garage door sensor; otherwise, false. 
     */
    isGarageDoor(sensor) {
        return ['GDOORDL', 'TX-E401', 'RE106'].indexOf(sensor.ProductSku) >= 0;
    }

    /**
     * Returns true if the provided sensor is a motion sensor; otherwise, false.
     * 
     * @public
     * @param {Object} sensor A sensor object.
     * @returns {boolean} True if the current sensor is a motion sensor; otherwise, false. 
     */
    isMotion(sensor) {
        return ['MD', 'RE110P', 'RE111P_FP'].indexOf(sensor.ProductSku) >= 0;
    }

    /**
     * Returns true if the provided sensor is currently open; otherwise, false.
     * 
     * @public
     * @param {Object} sensor A sensor object.
     * @returns {boolean} True if the provided sensor is currently open; otherwise, false.
     */
    isOpen(sensor) {
        return sensor.StatusType === this.constants.deviceStatus.opened.Name;
    }

    /**
     * Returns true if the provided sensor is currently closed; otherwise, false.
     * 
     * @public
     * @param {Object} sensor A sensor object.
     * @returns {boolean} True if the provided sensor is currently closed; otherwise, false.
     */
    isClosed(sensor) {
        return sensor.StatusType === this.constants.deviceStatus.closed.Name;
    }

    /**
    * Returns true if the provided sensor is currently openclosed; otherwise, false.
    * 
    * @public
    * @param {Object} sensor A sensor object.
    * @returns {boolean} True if the provided sensor is currently openclosed; otherwise, false.
    */
    isOpenClosed(sensor) {
        return sensor.StatusType === this.constants.deviceStatus.openedClosed.Name;
    }

    /**
     * Returns true if the provided sensor is currently tampered; otherwise, false.
     * 
     * @public
     * @param {Object} sensor A sensor object.
     * @returns {boolean} True if the provided sensor is currently tampered; otherwise, false.
     */
    isTampered(sensor) {
        return sensor.StatusType === this.constants.deviceStatus.tampered.Name;
    }

    /**
     * Returns true if the provided sensor is currently malfunctioning; otherwise, false.
     * 
     * @param {Object} sensor 
     * @returns {boolean} 
     */
    isMalfunctioning(sensor) {
        return sensor.StatusType === this.constants.deviceStatus.malfunction.Name;
    }

    /**
     * Returns true if the provided sensor is currently unknown; otherwise, false.
     * 
     * @public
     * @param {Object} sensor A sensor object.
     * @returns {boolean} True if the provided sensor is currently unknown; otherwise, false.
     */
    isUnknown(sensor) {
        return !this.isOpen(sensor) && !this.isClosed(sensor) && !this.isTampered(sensor) && !this.isMalfunctioning(sensor);
    }

    /**
     * Restart the test for the current sensor.
     * 
     * @public
     */
    restartTest() {
        this.startTest();
    }

    /**
     * Returns a string that represents the current status of the provided sensor.
     * 
     * @public
     * @param {Object} sensor A sensor object.
     * @returns {string} A string that represents the current status of the provided sensor. 
     */
    getStatusText(sensor) {
        if (this.isOpen(sensor)) {
            return 'Opened';
        }

        if (this.isClosed(sensor)) {
            return 'Closed';
        }

        if (this.isTampered(sensor)) {
            return 'Tampered';
        }

        return 'Unknown';
    }

    /**
     * Returns a string that represents the type of the provided sensor.
     * 
     * @public
     * @param {Object} sensor A sensor object.
     * @returns {string} A string that represents the type of the provided sensor. 
     */
    getTypeText(sensor) {
        if (this.isDoorWindow(sensor)) {
            return 'Door and Window Sensor';
        }

        if (this.isGarageDoor(sensor)) {
            return 'Garage Door';
        }

        if (this.isMotion(sensor)) {
            return 'Motion';
        }

        return 'Unknown';
    }

    /**
     * Called when the test for the current sensor should be started.
     * 
     * @abstract
     */
    startTest() {
        throw 'AbstractTestController.startTest() must be overridden.';
    }

    /**
     * Called when the test for the current sensor should be stopped.
     * 
     * @abstract 
     */
    stopTest() {
        throw 'AbstractTestController.stopTest() must be overridden.';
    }

    /**
     * Returns a true if the current test supports the provided sensor type; otherwise, false.
     * 
     * @abstract
     * @param {Object} sensor A sensor object.
     * @returns {boolean} Ture if the current test supports the provided sensor type; otherwise, false. 
     */
    canSupportSensor(sensor) {
        throw 'AbstractTestController.canSupportSensor() must be overridden.';
    }

    /**
     * Returns the name of the current test.
     * 
     * @abstract
     * @returns {string} 
     */
    getName() {
        throw 'AbstractTestController.getName() must be overridden.';
    }

    /**
     * 
     * @private
     * @param {} state 
     * @returns {} 
     */
    _mapState(state) {
        return {
            sensor: state.system.devices.sensorsByID[state.system.devices.selectedSensorID],
            isPolling: state.system.devices.isPolling,
            isInitialOrder: state.account.user.isInitialOrder,
            startedOnAmazon: state.account.origin.startedOnAmazon
        };
    }

    /**
     * 
     * @private
     * @param {} event 
     * @returns {} 
     */
    _onKeyPress(event) {
        if (event.ctrlKey && event.code === 'Space') {
            this.$state.go('sensor.item.complete');
        }
    }
}

export default AbstractTestController;