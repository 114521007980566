import * as _ from 'lodash';
import * as angular from 'angular';
import moment from 'moment';

class UserService {
    /*@ngInject*/ 
    constructor($rootScope, $q, $location, $http, $log, $window, Restangular, authService, fpStore, fpDataCache, constants, $state, orderService) {
        this.$rootScope = $rootScope;
        this.$q = $q;
        this.$location = $location;
        this.$http = $http;
        this.$log = $log;
        this.$window = $window;
        this.Restangular = Restangular;
        this.$state = $state;

        this.authService = authService;
        this.fpStore = fpStore;
        this.fpDataCache = fpDataCache;
        this.constants = constants;
        this.orderService = orderService;
        this.isBetaUser = false;

        this.accessTokenKey = constants.storage.accessTokenKey;
    }

    login(activationCode, captchaResponse) {
        return this.Restangular
            .withConfig((config) => {
                config.setDefaultHttpFields({
                    ignoreAuthModule: true
                });
            })
            .all('login')
            .withHttpConfig({
                cache: false
            })
            .post({
                activationCode: activationCode,
                captchaResponse: captchaResponse
            })
            .then((result) => {
                    let token = result;

                    if (!this.isTokenValid(token)) {
                        result = this.$q.reject(new Error('Invalid token'));
                    } else {
                        this.setAccessToken(token);
                        this.authService.loginConfirmed();
                        result = token;
                    }

                    return result;
                },
                (error) => {
                    this.$log.error('Login Failed', error);
                    return this.$q.reject(error);
                });
    }

    changeUser() {
        return this.Restangular
            .withConfig((config) => {
                config.setDefaultHttpFields({
                    ignoreAuthModule: true
                });
            })
            .all('login')
            .all('change-user')
            .withHttpConfig({
                cache: false
            })
            .post()
            .then((result) => {
                    let token = result;

                    if (!this.isTokenValid(token)) {
                        result = this.$q.reject(new Error('Invalid token'));
                    } else {
                        this.setAccessToken(token);
                        this.authService.loginConfirmed();
                        result = token;
                    }
                    return result;
                },
                (error) => {
                    this.$log.error('Login Failed', error);
                    return this.$q.reject(error);
                });
    }

    logout() {
        return this.Restangular
            .all('logout')
            .withHttpConfig({
                cache: false
            })
            .post()
            .then((result) => {
                return result;
            })
            .finally(() => {
                this.reset();
            });
    }

    hasSetting(key, value) {
        let setting = _.find(this.current.Settings, (s) => {
            return s.Name === key;
        });
        return !_.isNil(setting) &&
               (_.isNil(value) || (setting.Value === value) || (_.isString(setting.Value) && _.toLower(setting.Value) === _.toLower(value.toString())));
    }

    getUserInfo() {
        return this.Restangular
            .all('login')
            .one('user')
            .withHttpConfig({
                cache: false
            })
            .get()
            .then((result) => {
                if (this.isUserValid(result)) {
                    let stripped = result.plain();
                    this.current = stripped;
                    this.isBetaUser = stripped.Settings;
                    result = stripped;
                } else {
                    result = this.$q.reject('No user found.');
                }

                return result;
            }).catch((err) => {
                this.$log.debug('Failed to get user info.', err);
                return this.$q.reject(err);
            });
    }

    getSSOToken() {
        return this.Restangular
            .all('login')
            .one('ssoToken')
            .withHttpConfig({
                cache: false
            })
            .get()
            .then((result) => {
                return result;
            }, (error) => {
                this.$log.error('Failed on get sso token', error);
                return this.$q.reject(error);
            });
    }

    getAdcRedirectUrl() {
        return this.Restangular
            .all('Account')
            .all('AdcRedirectUrl')
            .withHttpConfig({
                cache: false
            })
            .post({
                Href: this.$state.href('zwave-completed', null, {absolute:true})
            })
            .then((result) => {
                this.$log.info('Redirecting to ADC...');
                this.$log.debug(result);
                return result;
            }, (error) => {
                this.$log.error('Failed on ADC redirect', error);
                return this.$q.reject(error);
            });
    }

    isBetaUser() {
        return this.isBetaUser;
    }

    isUserValid(data) {
        return !_.isNil(data) &&
               data.UserId > 0;
    }

    raiseLoginRequired(force = false) {
        this.$rootScope.$broadcast(this.constants.events.loginRequired, force);
    }

        loadAccessToken() {
            let result;
            let temp = this.fpStore.get(this.accessTokenKey);

            if (!_.isEmpty(temp)) {
                result = angular.fromJson(temp);
                if (!this.isTokenValid(result)) {
                    result = null;
                }
            }

            return result;
        }

        setAccessToken(token) {
            if (this.isTokenValid(token)) {
                this.$http.defaults.headers.common.Authorization = 'Bearer ' + token;

                let tokenJson = angular.toJson(token);

                this.fpStore.set(this.accessTokenKey, tokenJson, 'session');
            } else {
                this.clearAccessToken();
            }
        }

        reset() {
            this.clearAccessToken();
            this.clearInMobileApp();
            this.fpDataCache.clear();
            this.$window.isFirstLoadComplete = false;
            this.orderService.clearOrderIdToActivate();
        }

        clearAccessToken() {
            this.fpStore.remove(this.accessTokenKey);
            delete this.$http.defaults.headers.common.Authorization;
        }

        isTokenValid(token) {
            let result = false;

            if (!_.isEmpty(token)) {
                let accessToken = token;

                result = accessToken !== 'undefined' &&
                         accessToken !== 'null' &&
                         !_.includes(accessToken.toLowerCase(), '<!doctype html>');
            }

            return result;
        }

        setPassword(newPassword) {
            return this.Restangular
                .all('Login')
                .all('SetPassword')
                .withHttpConfig({
                    cache: false
                })
                .post({
                    Token: '',
                    Username: '',
                    OldPassword: '',
                    NewPassword: newPassword,
                    Confirm: '',
                    SecurityQuestionResponse: ''
                });
        }

        initialize() {
            this.setAccessToken(this.loadAccessToken());
        }

        /**
         * Sets a flag that indicates the application is inside the Frontpoint mobile applicaiton.
         */
        enableInMobileApp() {
            this.fpStore.set(this.constants.storage.inMobileAppKey, true, 'session');
        }

        /**
         * Returns a true if the application is inside the Frontpoint mobile application; otherwise, false.
         * 
         * @returns {boolean} 
         */
        isInMobileApp() {
            const value = this.fpStore.get(this.constants.storage.inMobileAppKey, 'session');
            if (_.isNil(value) || !_.isBoolean(value)) {
                return false;
            }

            return value;
        }

        /**
         * Remove the flag that indicates the application is inside the Frontpoint mobile application.
         */
        clearInMobileApp() {
            this.fpStore.remove(this.constants.storage.inMobileAppKey, 'session');
        }

        /**
         * Since we use isMobile app above for addiitonal orders, we need to distinguish if it is browser or mobile for tracking purposes
         */
        enableIsBrowser() {
            this.fpStore.set(this.constants.storage.isBrowser, true, 'session');
        }

        /**
         * Returns a true if the application is a mobile application; otherwise, false.
         * 
         * @returns {boolean} 
         */
        isBrowser() {
            const value = this.fpStore.get(this.constants.storage.isBrowser, 'session');
            if (_.isNil(value) || !_.isBoolean(value)) {
                return false;
            }

            return value;
        }

        /**
         * Remove the flag that indicates the application is from a mobile application.
         */
        clearIsBrowser() {
            this.fpStore.remove(this.constants.storage.isBrowser, 'session');
        }

        /**
        * Sets a flag that indicates the application is a first session for additional equipment.
        */
        setFirstAdditionalAttempt() {
            this.fpStore.set(this.constants.storage.FirstAdditionalAttempt, true, 'session');
        }

        /**
         * Remove the flag that indicates the application is inside the Frontpoint mobile application.
         */
        clearFirstAdditionalAttempt() {
            this.fpStore.remove(this.constants.storage.FirstAdditionalAttempt, 'session');
        }

        setSkipOrderArrivalCheckExpiration(value) {
            this.fpStore.set(this.constants.storage.skipOrderArrivalCheckExpiration, value, 'local');
        }

        getSkipOrderArrivalCheckExpiration() {
            return this.fpStore.get(this.constants.storage.skipOrderArrivalCheckExpiration, 'local');
    }

        clearSkipOrderArrivalCheckExpiration() {
            this.fpStore.remove(this.constants.storage.skipOrderArrivalCheckExpiration, 'local');
        }

        /**
         * Returns a true if the application is the first additional attempt
         * 
         * @returns {boolean} 
         */
        isFirstAdditionalAttempt() {
            const value = this.fpStore.get(this.constants.storage.FirstAdditionalAttempt, 'session');
            if (_.isNil(value) || !_.isBoolean(value)) {
                return false;
            }

            return value;
        }
    }

    export default UserService;