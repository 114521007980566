import * as _ from 'lodash';

let navNextDirective = (navigation, $q) => {
    'ngInject';
    return {
        restrict: 'A',
        link: ($scope, $element, $attr, ctrl) => {
            $element.bind('click', (evt) => {
                if (!_.isEmpty($attr.awNavNext)) {
                    $q.resolve($scope.$apply($attr.awNavNext))
                    .then(() => {
                        navigation.goNext();
                        evt.preventDefault();
                    });
                } else {
                    navigation.goNext();
                    evt.preventDefault();
                }
            });
        }
    };
};

export default navNextDirective;