import * as _ from 'lodash';

import template from './home.html';

const numPanels = 5;

const swipeDirection = {
    left: 1,
    right: 2
};

class HomeController {
    /*@ngInject*/
    constructor($ngRedux, $log, $timeout, actionContext, constants) {
        this.$ngRedux = $ngRedux;
        this.$log = $log;
        this.$timeout = $timeout;
        this.actionContext = actionContext;
        this.constants = constants;
        this.activePanel = 0;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState)(this);
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
    }

    $onDestroy() {
        this.unsubscribe();
    }

    next() {
        this.$log.debug('Swipe Left');
        
        this.swipeDirection = null;
        return this.$timeout(() => {
            this.swipeDirection = swipeDirection.left;
            this.activePanel = (this.activePanel + 1) % numPanels;
        });
    }

    prev() {
        this.$log.debug('Swipe Right');

        this.swipeDirection = null;
        return this.$timeout(() => {
            this.swipeDirection = swipeDirection.right;
            this.activePanel = (this.activePanel - 1) < 0 ? (numPanels - 1) : this.activePanel - 1;
        });
    }

    isSwipingLeft() {
        return this.swipeDirection === swipeDirection.left;
    }

    isSwipingRight() {
        return this.swipeDirection === swipeDirection.right;
    }

    _mapState(state) {
        return {
            panelVersion: state.system.panel.type
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: HomeController
};