import template from './addSensorsComplete.html';

class AddSensorsCompleteController {
    /*@ngInject*/
    constructor($state, constants) {
        this.showProgress = false;
        this.$state = $state;
        this.constants = constants;
    }

    onClick() {
        this.showProgress = true;
    }   

    onComplete() {
        this.showProgress = false;
        this.$state.go(this.constants.routerStates.airfxPanelConnected);
    }
}

export default {
    template: template,
    bindings: {},
    controller: AddSensorsCompleteController
};