import template from './box.html';

class SensorBoxController {
    /*@ngInject*/
    constructor(actionContext, $ngRedux) {
        this.actionContext = actionContext;
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState)(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    _mapState(state) {
        return {
            sensor: state.system.devices.sensorsByID[state.system.devices.selectedSensorID]
        };
    }
}

export default {
    template: template,
    bindings: {
        sensor: '<'
    },
    controller: SensorBoxController
};