import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import './tos.scss';

import commonModule from '../common';
import menuModule from '../menu';

import tosComponent from './tos.component';
import tosOptionsComponent from './options.component';

export default angular
    .module('fp:activationswizard:tos', [
        'ui.router',
        ngRedux,
        commonModule.name,
        menuModule.name
    ])
    .config( /*@ngInject*/($stateProvider, constants) => {
        $stateProvider
            .state('tos', {
                abstract: true,
                parent: 'secure',
                url: '/tos',
                template: '<ui-view></ui-view>',
                data: {
                    allowPostActivation: true,
                    prev: /*@ngInject*/ (appActions, $state) => {
                        $state.go('review.home');
                    },
                    allowHorizontal: true,
                    sectionType: constants.sectionTypes.tos,
                    theme: 'app-theme-default-navbar-gray'
                }
            })
            .state('tos.options', {
                url: '',
                template: '<aw-tos-options></aw-tos-options>',
                onEnter: /*@ngInject*/ (appActions) => {
                    appActions.startModule(constants.sectionTypes.tos);
                }
            })
            .state('tos.home', {
                url: '',
                template: '<aw-tos></aw-tos>',
                onEnter: /*@ngInject*/ (appActions) => {
                    appActions.startModule(constants.sectionTypes.tos);
                },
                data: {
                    prev: /*@ngInject*/ (appActions, $state) => {
                        $state.go('tos.options');
                    }
                }
            });
    })
    .component('awTos', tosComponent)
    .component('awTosOptions', tosOptionsComponent);