import { ACTIVATION_CODE_CHANGED, LOGIN_BUSY, LOGIN_SUCCESSFUL, SSO_SUCCESSFUL, LOGIN_FAILED, LOGIN_REQUIRED, LOGOUT, USER_INFO_UPDATED, ACCOUNT_STATUS_UPDATED, IS_BETA_USER, IS_AMAZON_USER, ADDITIONAL_ORDER, IS_APP_CHANGED } from '../../action/login.actions';
import * as _ from 'lodash';

const initialState = {
    ignore: [],
    name: null,
    isBusy: false,
    activationCode: null,
    isAuthenticated: false,
    hasError: false,
    message: null,
    accountStatus: 'Unknown',
    isImpersonator: false,
    activationDate: null,
    isInitialOrder: true,
    isBetaUser: false,
    isInMobileApp: false
};

export default (state = initialState, action = {}) => {
    let newState = null;

    switch (action.type) {
        case ACTIVATION_CODE_CHANGED:
            newState = _.mergeCustom({}, state, {
                activationCode: action.payload.activationCode
            });
            break;
        case IS_APP_CHANGED:
            newState = _.mergeCustom({}, state, {
                isInMobileApp: action.payload.isInMobileApp
            });
            break;

        case LOGIN_BUSY:
            newState = _.mergeCustom({}, state, {
                isBusy: true
            });
            break;

        case LOGIN_SUCCESSFUL:
        case SSO_SUCCESSFUL:
            newState = _.mergeCustom({}, state, {
                activationCode: action.payload.activationCode,
                isAuthenticated: true,
                isAmazon: (action.payload.activationCode.length === 6 || action.payload.activationCode.length === 12),
                isBusy: false,
                hasError: false,
                message: null
            });
            break;

        case LOGIN_FAILED:
            newState = _.mergeCustom({}, state, {
                activationCode: action.payload.activationCode,
                isAuthenticated: false,
                isBusy: false,
                hasError: true,
                message: action.payload.message
            });
            break;

        case LOGIN_REQUIRED:
        case LOGOUT:
            newState = _.mergeCustom({}, state, {
                activationCode: null,
                isAuthenticated: false,
                name: null,
                isInitialOrder: true
            });
            break;
        case ADDITIONAL_ORDER:
            newState = _.mergeCustom({}, state, {
                activationCode: null,
                isInitialOrder: false
            }); 
            break;
        case USER_INFO_UPDATED:
            newState = _.mergeCustom({}, state, {
                isAuthenticated: true,
                name: action.payload.userInfo.UserName,
                accountId: action.payload.userInfo.AccountId,
                isImpersonator: action.payload.userInfo.IsImpersonator,
                isInitialOrder: action.payload.userInfo.IsInitialOrder,
                arNumber: action.payload.userInfo.ARNumber,
                isAmazonUser: action.payload.userInfo.IsAmazonUser,
                isIq4Panel: action.payload.userInfo.IsIQ4Panel
            });
            break;

        case ACCOUNT_STATUS_UPDATED:
            newState = _.mergeCustom({}, state, {
                accountStatus: action.payload.accountStatus.Status,
                activationDate: action.payload.accountStatus.ActivationDate
            });
            break;


        case IS_AMAZON_USER:
            newState = _.mergeCustom({}, state, {
                isAmazonUser: true
            });
            break;

        case IS_BETA_USER:
            return _.mergeCustom({}, state, {
                isBetaUser: true
            });
      
        default:
            newState = state;
            break;
    }

    return newState;
};