import template from './testUserCodes.html';
import * as _ from 'lodash';


class TestUserCodesController {
    /*@ngInject*/
    constructor($state, $stateParams, constants, $ngRedux) {
        this.$state = $state;
        this.$ngRedux = $ngRedux;
        this.swapType = $ngRedux.getState().system.swap.swapType;
        this.constants = constants;
    }

    goToModSwapSuccess() {
        if (this.swapType === this.constants.swapType.radio) {
            this.$state.go('airfx.modswapSuccess', { swapType: this.constants.swapType.radio });
        } else {
            this.$state.go('airfx.training', { swapType: this.constants.swapType.mod });
        }
    }

    onPrevious() {
        this.$state.go(this.constants.routerStates.modifyUserCodes, { swapType: this.swapType });
    }
}
export default {
    template: template,
    bindings: {},
    controller: TestUserCodesController
};