import template from './home.html';
import * as _ from 'lodash';
const requiredContacts = 2;
const maxContacts = 3;
class OverviewController {
    /*@ngInject*/
    constructor($ngRedux, $state, actionContext) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.actionContext = actionContext;
        this.isAmazonUser = false;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        if (this.contacts.length < 2 || _.isNil(this.contacts.length)) {
            this.$state.go('predispatch.edit');
        }
        if (this.$ngRedux.getState().account.user.isAmazonUser ||
            this.$ngRedux.getState().account.origin.startedOnAmazon) {
            this.isAmazonUser = true;
        }
        this.isVideoOnly = this.$ngRedux.getState().account.customer.isVideoOnly;
    }

    canContinue() {
        let canProceed = this.contacts.length >= requiredContacts && this.contacts.length <= maxContacts;
        if (canProceed) {
            this.actionContext.predispatch.confirm();
        }
        return canProceed;
    }

    $onDestroy() {
        this.unsubscribe();
    }

    _mapState(state) {
        return {
            contacts: state.account.predispatch.contacts
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: OverviewController
};