import * as _ from 'lodash';
import error from '../../util/error';
import moment from 'moment';

import {
    PANEL_CONNECT,
    PANEL_CONNECT_FAILURE,
    PANEL_CONNECT_SUCCESS,
    PANEL_LAST_MESSAGE_DATE_REQUEST,
    PANEL_LAST_MESSAGE_DATE_FAILURE,
    PANEL_LAST_MESSAGE_DATE_SUCCESS,
    PANEL_VERSION_FETCH_REQUEST,
    PANEL_VERSION_FETCH_FAILURE,
    PANEL_VERSION_FETCH_SUCCESS,
    PANEL_SIGNAL_STRENGTH_FETCH_REQUEST,
    PANEL_SIGNAL_STRENGTH_FETCH_FAILURE,
    PANEL_SIGNAL_STRENGTH_FETCH_SUCCESS,
    PANEL_SETTINGS_REQUEST,
    PANEL_SETTINGS_REQUEST_SUCCESS,
    PANEL_SETTINGS_REQUEST_FAILURE
} from '../../action/panel.actions';

const initialState = {
    ignore: [],
    isOnline: false,
    mastercode: null,
    type: null,
    version: null,
    lastUpdatedDate: null,
    isPinging: false,
    isFetching: false,
    isUpdating: false,
    hasError: false,
    message: null,
    signalStrength: null,
    lastSignalDate: null,
    isConnected: false,
    isLoadingSystemSettings: false
};

let pingFn = (state, action) => {
    switch (action.type) {
    case PANEL_CONNECT:
        return _.mergeCustom({}, state, {
            isPinging: true,
            hasError: false,
            message: null
        });
    case PANEL_CONNECT_FAILURE:
        return _.mergeCustom({}, state, {
            isPinging: false,
            isConnected: false,
            hasError: true,
            message: error.parse(action.payload.error)
        });
    case PANEL_CONNECT_SUCCESS:
        return _.mergeCustom({}, state, {
            isConnected: true,
            lastUpdatedDate: moment.utc(),
            isPinging: false
        });
    default:
        return state;
    }
};

let messageFn = (state, action) => {
    switch (action.type) {
    case PANEL_LAST_MESSAGE_DATE_REQUEST:
        return _.mergeCustom({}, state, {
            isFetching: false,
            hasError: true,
            message: null
        });
    case PANEL_LAST_MESSAGE_DATE_FAILURE:
        return _.mergeCustom({}, state, {
            isFetching: false,
            hasError: true,
            message: error.parse(action.payload.error)
        });
    case PANEL_LAST_MESSAGE_DATE_SUCCESS:
        return _.mergeCustom({}, state, {
            lastMessageDate: action.payload.data,
            lastUpdatedDate: moment.utc(),
            isFetching: false
        });
    default:
        return state;
    }
};

let versionFn = (state, action) => {
    switch (action.type) {
    case PANEL_VERSION_FETCH_REQUEST:
        return _.mergeCustom({}, state, {
            isFetching: true,
            hasError: false,
            message: null
        });
    case PANEL_VERSION_FETCH_FAILURE:
        return _.mergeCustom({}, state, {
            isFetching: false,
            hasError: true,
            message: error.parse(action.payload.error)
        });
    case PANEL_VERSION_FETCH_SUCCESS:
        return _.mergeCustom({}, state, {
            type: action.payload.data.PanelType.toUpperCase(),
            version: action.payload.data.PanelVersion.toUpperCase(),
            lastUpdatedDate: moment.utc(),
            isFetching: false
        });
    default:
        return state;
    }
};

let signalFn = (state, action) => {
    switch (action.type) {
        case PANEL_SIGNAL_STRENGTH_FETCH_REQUEST:
            return _.mergeCustom({}, state, {
                isFetching: true
            });
        case PANEL_SIGNAL_STRENGTH_FETCH_FAILURE:
            return _.mergeCustom({}, state, {
                hasError: true,
                message: error.parse(action.payload.error),
                lastSignalDate: null,
                isFetching: false
            });
        case PANEL_SIGNAL_STRENGTH_FETCH_SUCCESS:
            return _.mergeCustom({}, state, {
                signalStrength: action.payload.signalStrength,
                lastSignalDate: action.payload.lastSignalDate,
                isFetching: false
            });
        default:
            return state;
    }
};

let systemSettingsFn = (state, action) => {
    switch(action.type) {
        case PANEL_SETTINGS_REQUEST:
            return _.mergeCustom({},
                state,
                {
                    isLoadingSystemSettings: true
                });
        case PANEL_SETTINGS_REQUEST_FAILURE:
            return _.mergeCustom({},
                state,
                {
                    isLoadingSystemSettings: false
                });
        case PANEL_SETTINGS_REQUEST_SUCCESS:
            return _.mergeCustom({},
                state,
                {
                    isLoadingSystemSettings: false
                });
        default:
            return state;
    }
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case PANEL_CONNECT:
    case PANEL_CONNECT_FAILURE:
    case PANEL_CONNECT_SUCCESS:
        return pingFn(state, action);
    case PANEL_LAST_MESSAGE_DATE_REQUEST:
    case PANEL_LAST_MESSAGE_DATE_FAILURE:
    case PANEL_LAST_MESSAGE_DATE_SUCCESS:
        return messageFn(state, action);
    case PANEL_VERSION_FETCH_REQUEST:
    case PANEL_VERSION_FETCH_FAILURE:
    case PANEL_VERSION_FETCH_SUCCESS:
        return versionFn(state, action);
    case PANEL_SIGNAL_STRENGTH_FETCH_REQUEST:
    case PANEL_SIGNAL_STRENGTH_FETCH_FAILURE:
    case PANEL_SIGNAL_STRENGTH_FETCH_SUCCESS:
        return signalFn(state, action);
    case PANEL_SETTINGS_REQUEST:
    case PANEL_SETTINGS_REQUEST_FAILURE:
    case PANEL_SETTINGS_REQUEST_SUCCESS:
        return systemSettingsFn(state, action);
    default:
        return state;
    }
};