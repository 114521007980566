import template from './plugin.html';

class PluginController {
    /*@ngInject*/
    constructor($ngRedux, $state, constants, actionContext) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.actionContext = actionContext;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.swapType = this.$ngRedux.getState().system.swap.swapType;
    }

    $onDestroy() {
        this.unsubscribe();
    }

    getRoute() {
        return this.panelVersion === this.constants.panelVersions.climaxHub
            ? this.constants.routerStates.instructionKeypad
            : this.constants.routerStates.premisesHome;
    }

    _mapState(state) {
        return {
            targetSku: state.account.airfxFlow.targetSku,
            isIq4Panel: state.account.user.isIq4Panel
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: PluginController
};