import template from './phoneLink.html';

class PhoneController {
    /*@ngInject*/
    constructor () {
    }
}

export default {
    template: template,
    bindings: {
        number: '<',
        showCountry: '<',
        disabled: '<'
    },
    controller: PhoneController
};