import * as _ from 'lodash';
import * as angular from 'angular';
import ngRedux from 'ng-redux';

import commonModule from '../../common';
import instructionModule from '../../instruction';
import airfxModule from '../../airfx';

import sensorItemNameComponent from './sensorItemName.component';
import sensorChimeSoundComponent from './sensorChimeSound.component';

export default angular
    .module('fp:activationswizard:sensor:components', [
        ngRedux,
        commonModule.name,
        instructionModule.name,
        airfxModule.name
    ])
    .component('awSensorItemName', sensorItemNameComponent)
    .component('awSensorChimeSound', sensorChimeSoundComponent);