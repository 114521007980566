import * as _ from 'lodash';
import moment from 'moment';

export const ACTIVATION_CODE_CHANGED = 'ACTIVATION_CODE_CHANGED';
export const LOGIN_BUSY = 'LOGIN_BUSY';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const SSO_SUCCESSFUL = 'SSO_SUCCESSFUL';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_REQUIRED = 'LOGIN_REQUIRED';
export const LOGOUT = 'LOGOUT';
export const USER_INFO_UPDATED = 'USER_INFO_UPDATED';
export const ACCOUNT_STATUS_UPDATED = 'ACCOUNT_STATUS_UPDATED';
export const IS_BETA_USER = 'IS_BETA_USER';
export const IS_AMAZON_USER = 'IS_AMAZON_USER';
export const ADDITIONAL_ORDER = 'ADDITIONAL_ORDER';
export const IS_APP_CHANGED = 'IS_APP_CHANGED';

export const HYDRATE = 'HYDRATE';

const DEFAULT_ROUTER_STATE = 'welcome.settingUp';


class LoginActions {
    /*@ngInject*/
    constructor($log, $ngRedux, $q, $state, constants, authService, dataContext, commonActions, $window, $rootScope, appActions) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.$state = $state;
        this.constants = constants;
        this.authService = authService;
        this.dataContext = dataContext;
        this.commonActions = commonActions;
        this.$window = $window;
        this.appActions = appActions;
        this.$rootScope = $rootScope;
    }

    getUserSettings() {
        return this.dataContext.user.getUserInfo()
            .then((result) => {
                const betaSettings = _.get(this.constants, 'beta.realTimeSensorTesting', {});
                if (this.dataContext.user.hasSetting(betaSettings.settingKey, 'true')) {
                    this._dispatch({
                        type: IS_BETA_USER
                    });
                }

                this.$rootScope.$broadcast(this.constants.events.userInfoReceived, result);
            })
            .catch((error) => {
                this.$log.error('Error fetching the customer\'s user settings', error);
                return this.$q.reject('getUserSettings');
            });
    }

    setActivationCode(activationCode) {
        this.$ngRedux.dispatch({
            type: ACTIVATION_CODE_CHANGED,
            payload: {
                activationCode
            }
        });
    }

    setIfUserInMobileApp(isInMobileApp) {
        this.$ngRedux.dispatch({
            type: IS_APP_CHANGED,
            payload: {
                isInMobileApp
            }
        });
    }

    userInfoUpdated(userInfo) {
        if (!userInfo.IsInitialOrder && !userInfo.IsAmazonUser) {
            return this.dataContext.panel.getVersion()
                .then((ver) => {
                    if (ver.PanelType == 'IQPANEL') {
                        userInfo.IsIQ4Panel = true;
                    }
                    this.$ngRedux.dispatch({
                        type: USER_INFO_UPDATED,
                        payload: {
                            userInfo
                        }
                    });
                });
        }
        else {
            this.$ngRedux.dispatch({
                type: USER_INFO_UPDATED,
                payload: {
                    userInfo
                }
            });
        }
        
        if (this.$window && this.$window.hj) {
            const { account } = this.$ngRedux.getState();
            const kitAccountId = account && account.kitCustomer && account.kitCustomer.accountId !== 0 ? account.kitCustomer.accountId : null;

            this.$window.hj('identify', null, {
                'ARNumber': userInfo.ARNumber,
                'WizardType': userInfo.IsInitialOrder ? 'Initial' : 'Additional',
                'IsAmazon': userInfo.IsAmazonUser || kitAccountId ? 'true' : 'false'
            });
        }
        return Promise.resolve();
    }

    postActivationCode(activationCode, captchaResponse) {
        this.appActions.tryStartLoad();
        this.setActivationCode(activationCode);
        this._loginStarted();
        this.$rootScope.$broadcast(this.constants.events.startTimer, { isStart: true });

        // loging
        return this.dataContext.user.login(activationCode, captchaResponse)
            .then(() => {
                this._loginSuccessful(activationCode);

                // get hydrated state
                this.hydrate().then(res => {

                    // get destination from persisted state
                    const destination = res && res.router && res.router.currentState ? res.router.currentState.name : null;

                    // navigate
                    this.navigateUser(destination);

                }).catch(error => {
                    console.warn("State failed to rehydrate.", error);
                    this.navigateUser();
                });
            })
            .catch((error) => {
                this._loginFailed(error.data.Message);
                this.appActions.tryStopLoad();
                this.commonActions.error(this.constants.actionTypes.error, this.constants.sectionTypes.login, `[Error: ${error.data.Message}]  [Input: ${activationCode}]`);
                return this.$q.reject(error);
            });
    }

    navigateUser(state = null) {
        this.commonActions.other(this.constants.actionTypes.other, this.constants.sectionTypes.login, /* isHydratable */ false);
        this.$state.go(state || DEFAULT_ROUTER_STATE);
    }

    postActivationCodeDesktop(activationCode) {
        this.appActions.tryStartLoad();
        this.setActivationCode(activationCode);
        this._loginStarted();
        this.$rootScope.$broadcast(this.constants.events.startTimer, { isStart: true });

        return this.dataContext.user.login(activationCode)
            .then(() => {
                this._loginSuccessful(activationCode);
                this.commonActions.other(this.constants.actionTypes.other, this.constants.sectionTypes.login, /* isHydratable */ false);
            })
            .catch((error) => {
                this._loginFailed(error.data.Message);
                this.commonActions.error(this.constants.actionTypes.error, this.constants.sectionTypes.login, `[Error: ${error.data.Message}]  [Input: ${activationCode}]`);
            });
    }

    logout() {
        this.commonActions.other(this.constants.actionTypes.other, this.constants.sectionTypes.save, /* isHydratable */ false);
        return this.dataContext.user.logout()
            .then(() => {
                this.$log.debug(`User logged out successfully`);
            })
            .catch((error) => {
                this.$log.error(`Failed to logout [${error.data.Message}]`);
            })
            .finally(() => {
                this._onLogout();
            });
    }

    handleLoginRequired() {
        this._loginRequired();
        this.authService.loginCancelled(null, 'Login required'); // Spins forever otherwise...

        if (!_.startsWith(this.$state.current.name, 'login')) {
            this.$state.go('login', null, { location: 'replace' });
        }
    }

    processSingleSignOn(token, isInMobileApp, isBrowser) {
        this._loginStarted();
        this.$rootScope.$broadcast(this.constants.events.startTimer, { isStart: true });

        if (!this.dataContext.user.isTokenValid(token)) {
            this._loginFailed('Invalid token');
            this.handleLoginRequired();
            return null;
        }
        this.dataContext.user.setAccessToken(token);

        return this.dataContext.user.getUserInfo()
            .then((result) => {
                this._ssoSuccessful();
                if (isInMobileApp) {
                    //need to distinguish is it is from a mobile app or desktop app
                    if (isBrowser) {
                        this.dataContext.user.enableIsBrowser();
                    }
                    this.additionalOrder();
                    // Set isFirstLoadComplete to false so resolves in the shell component are run again.
                    this.$window.isFirstLoadComplete = false;
                    this.$window.isAdditionalOrders = true;
                    this.dataContext.account.order.setOrderIdToActivate(this.constants.additionalOrdersOrderId);
                    this.dataContext.user.enableInMobileApp();
                    this.dataContext.user.clearFirstAdditionalAttempt();

                    const skipCheckExpiration = this.dataContext.user.getSkipOrderArrivalCheckExpiration();
                    const currentUtcDate = moment.utc();

                    if (!_.isNil(skipCheckExpiration) && moment.utc(skipCheckExpiration) > currentUtcDate) {
                        this.$state.go(this.constants.routerStates.airfxCategoryList, null, { location: 'replace' });
                    } else {
                        this.$state.go(this.constants.routerStates.orderArrivalCheck, null, { location: 'replace' });
                    }

                } else {
                    this.$state.go(DEFAULT_ROUTER_STATE, null, { location: 'replace' });
                }
            })
            .catch((error) => {
                this._loginFailed(error.data.Message);
            });
    }

    getCurrentUserInfo() {
        return this.dataContext.user.getUserInfo()
            .then((result) => {
                return this.userInfoUpdated(result).then(() => {
                    return result;
                });                
            });        
    }

    getAccountStatus() {
        return this.dataContext.account.getAccountStatus()
            .then((result) => {
                this._accountStatusUpdated(result);
                return result;
            });
    }

    changeUser() {
        return this.dataContext.user.changeUser()
            .then(() => {
                return this.dataContext.user.getUserInfo().then((result) => {
                    return this.userInfoUpdated(result).then(() => {
                        return result;
                    });
                });
            })
            .catch((error) => {
                // not sure what to do here either?
                return this.$q.reject(error);
            });
    }

    hydrate() {
        const { account } = this.$ngRedux.getState();

        // amazon account information
        const kitAccountId = account && account.kitCustomer && account.kitCustomer.accountId !== 0 ? account.kitCustomer.accountId : null;
        const kitOrderId = account && account.kitCustomer && account.kitCustomer.orderId !== 0 ? account.kitCustomer.orderId : null;

        // only set is amazon if valid kit account and order
        const isAmazon = kitAccountId !== null && kitOrderId !== null && account && account.user ? account.user.isAmazonUser == true : null;

        return this.dataContext.application.state.getCurrentState(isAmazon, kitAccountId, kitOrderId)
            .then((result) => {
                // We did not recieve a result from the server; however, the request was successful. This may indicate 
                // that there are no saved states on the server.
                if (_.isNil(result)) {
                    return this.$q.resolve();
                }

                // We recieved a result from the server but it does not contain a current state. This should never 
                // happen.
                if (_.isNil(result.Current)) {
                    return this.$q.resolve();
                }

                // Only hydrate states that are marked as hydratable.
                if (result.IsHydratable !== true) {
                    return this.$q.resolve();
                }

                // parse state and rehydrate
                const state = JSON.parse(result.Current);
                this._onHydrate(state);
                return this.$q.resolve(state);
            })
            .catch((error) => {
                const message = error && error.data ? error.data.Message : "NA";
                this.$log.warn(`Error parsing hydrated state [${message}].`);
                this.$q.reject(message);
            });
    }

    clearState() {
        this._onLogout();
    }

    additionalOrder() {
        this.$ngRedux.dispatch({
            type: ADDITIONAL_ORDER
        });
        return this.$q.resolve();
    }

    _dispatch(obj) {
        this.$ngRedux.dispatch(obj);
    }

    _loginSuccessful(activationCode) {
        this.$ngRedux.dispatch({
            type: LOGIN_SUCCESSFUL,
            payload: {
                activationCode
            },
            metadata: {
                persist: false,
                sectionType: this.constants.sectionTypes.login,
                description: `User logged in with activation code: [${activationCode}].`,
                actionType: 'Info'
            }
        });
    }

    _loginFailed(message) {
        this.$ngRedux.dispatch({
            type: LOGIN_FAILED,
            payload: {
                message
            }
        });
    }

    _loginRequired() {
        this.$ngRedux.dispatch({
            type: LOGIN_REQUIRED
        });
    }

    _isAmazonUser() {
        this.$ngRedux.dispatch({
            type: IS_AMAZON_USER
        });
    }

    _loginStarted() {
        this.$ngRedux.dispatch({
            type: LOGIN_BUSY
        });
    }

    _ssoSuccessful() {
        this.$ngRedux.dispatch({
            type: SSO_SUCCESSFUL,
            payload: {
                activationCode: ''
            }
        });
    }

    _accountStatusUpdated(accountStatus) {
        this.$ngRedux.dispatch({
            type: ACCOUNT_STATUS_UPDATED,
            payload: {
                accountStatus
            }
        });
    }

    _onHydrate(state) {
        this.$ngRedux.dispatch({
            type: HYDRATE,
            payload: {
                state: state
            }
        });
    }

    _onLogout() {
        this.$ngRedux.dispatch({
            type: LOGOUT
        });
    }
}

export default LoginActions;