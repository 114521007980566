import * as _ from 'lodash';

class BarcodeService {
    /*@ngInject*/
    constructor($ngRedux, actionContext, $state, mobileDetectService,constants, dataContext, segment) {
        this.unsubscribe = $ngRedux.connect(this._mapState)(this);
        this.actionContext = actionContext;
        this.$state = $state;
        this.$ngRedux = $ngRedux;
        this.mobileDetectService = mobileDetectService;
        this.constants = constants;
        this.isAndroid = RegExp('Android', 'i').test(this.mobileDetectService.getOs());
        this.scanCounter = 0;
        this.dataContext = dataContext;
        this.segment = segment;
        this.isAsw = this.actionContext.app.isAdditional();
        this.isBrowser = this.dataContext.user.isBrowser();
        this.os = this.mobileDetectService.getOs();
        this.fromManual = false;
    }

    activateSensorWithDlCode(dlCode, attemptNumber, fromManual = false) {
        this.fromManual = fromManual;
        let status = false;
        if (!_.isUndefined(attemptNumber) && attemptNumber === 1) {
            this.scanCounter = 0;
        }
        const device = _.find(this.actionContext.order.getItemsById(),
            (item) => {
                let pathToGet = this.actionContext.airfxFlow.isKeypad() ? 'SerialNumber' : 'DLCode';
                const itemDlCode = _.get(item, pathToGet, undefined);
                return !_.isNil(itemDlCode) && !item.isActivated && itemDlCode.toUpperCase() === dlCode.toUpperCase();
            });

        if (_.isNil(device)) {
            if (!fromManual) {
                this.scanCounter++;
            }
            this._handleInValidBarcode(dlCode);
        } else {
            this.actionContext.order.queueAirFx(device);
            if (!device.IsConnected) {
                this.actionContext.airfxFlow.setCurrentDlcode(dlCode);
            }
            this._processBarcode(device);
            status = true;
        }

        return status;
    }

    getCameraUrl() {
        if (this.dataContext.user.isBrowser()) {
            return '';
        }
        else if (this.isAndroid) {
            return "android-app://#barcode;action=com.alarm.alarmmobile.android.BARCODE;B.AutoFocus=true;S.fieldName=dlCode;";
        } else {
            return "//#barcode;action=com.alarm.alarmmobile.android.BARCODE;B.AutoFocus=true;S.fieldName=dlCode;";
        }
    }

    _handleInValidBarcode(dlCode) {
        this._registerSegmentScan(dlCode, 'failure');
        if (this.fromManual) {
          this.$state.go('airfx.barcodeManual', { error: true }, { notify: false });
        }
        else if (this.scanCounter > this.constants.numberOfInvalidBarcodeScans) {
          this.$state.go('airfx.barcodeManual', { error: true });
        }
        else {
          this.$state.go('airfx.barcodeRescan');
        }
    }

    _registerSegmentScan(dlCode, result) {
        const eventName = this.fromManual ? 'Barcode Manual Entry' : 'Barcode Scan';
        this.segment.registerEvent(eventName, {
            Flow: this.isAsw ? 'ASW' : 'ISW',
            Application: this.isBrowser ? 'WEB' : this.os,
            DlCode: dlCode,
            Result: result
        });
    }

    registerSegmentScannerLoaded(seconds) {
        this.segment.registerEvent(this.constants.events.segment.barcodeScannerLoadTime, {
            TimeInSeconds: seconds
        });
    }

    _processBarcode(device) {
        this._registerSegmentScan(device.DLCode, 'success');
        this.$state.go('airfx.process', { sku: device.ProductSku });
    }

    _mapState(state) {
        return {
            sku: _.map(state.account.airfxFlow.sku)
        };
    }
}

export default BarcodeService;