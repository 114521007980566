import { IService } from 'restangular';

class ApplicationStateService {

    restangular: IService

    /*@ngInject*/
    constructor(Restangular: IService) {
        'ngInject';
        this.restangular = Restangular;
    }

    getCurrentState(isAmazon?: boolean, kitAccountId?: number, kitOrderId?: number) {
        return this.restangular
            .all('application')
            .customGET('state', {
                isAmazon,
                kitAccountId,
                kitOrderId
            });
    }

    updateCurrentState(state: any) {
        return this.restangular
            .one('application')
            .one('state')
            .withHttpConfig({ clearCache: false } as any)
            .customPUT(state);
    }
}

export default ApplicationStateService;