import './errorBanner.scss';
import template from './errorBanner.html';

class ErrorBannerController {
    constructor() { }
}

export default {
    template: template,
    bindings: {
        message: '@'
    },
    controller: ErrorBannerController
}