import * as angular from 'angular';

import menuMetadataInitialOrder from './menuInitialOrder.metadata';
import menuMetadataInitialOrderHomeAutomation from './menuInitialOrderHomeAutomation.metadata';
import menuMetadataInitialOrderClimaxHub from './menuInitialOrderClimaxHub.metadata';
import menuMetadataInitialOrderClimaxHubHomeAutomation from './menuInitialOrderClimaxHubHomeAutomation.metadata';
import menuMetadataInitialOrderIQ4Panel from './menuInitialOrderIQ4Panel.metadata';

export default angular.module('fp:activationswizard:common:metadata', [])
    .constant('menuMetadataInitialOrder', menuMetadataInitialOrder)
    .constant('menuMetadataInitialOrderHomeAutomation', menuMetadataInitialOrderHomeAutomation)
    .constant('menuMetadataInitialOrderClimaxHub', menuMetadataInitialOrderClimaxHub)
    .constant('menuMetadataInitialOrderClimaxHubHomeAutomation', menuMetadataInitialOrderClimaxHubHomeAutomation)
    .constant('menuMetadataInitialOrderIQ4Panel', menuMetadataInitialOrderIQ4Panel)
