import template from './registering.html';

class RegisteringController {
    /*@ngInject*/
    constructor($ngRedux, $state, actionContext, dataContext, constants, $q) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.actionContext = actionContext;
        this.dataContext = dataContext;
        this.constants = constants;
        this.$q = $q;
        this.isUpdating = true;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);

        this.actionContext.kitCustomer.fetchKitCustomer().then(() => {
            return this.actionContext.account.createCentralStation(this.accountId, this.orderId).then(() => {
                    return this.actionContext.account.createAdc(this.accountId, this.orderId);
            }).then(() => {
                this.dataContext.account.UpdatePetsInfoInRapid(this.accountId).then(() => { }).catch(() => {
                    return this.$q.reject('Error occured while passing pets information to rapid.');
                });
                return this.actionContext.login.changeUser();
                })
                .then(() => {
                    var promises = [
                        this.actionContext.panel.fetchPanelVersion(),
                        this.actionContext.app.fetchEligibleSensorNames(),
                        this.actionContext.code.fetchCode(),
                        this.actionContext.order.fetchOrderProducts(),
                        this.actionContext.login.getUserSettings()
                    ];

                    return this.$q.all(promises);
                })
                .then(() => {
                    this.isUpdating = false;
                    this.$state.go('account.registering-complete');
                })
                .catch(() => {
                    this.isError = true;
                    this.isUpdating = false;
                });
        });
    }

    _mapState(state) {
        let map = {};
        map.orderId = state.account.kitCustomer.orderId;
        map.accountId = state.account.kitCustomer.accountId;
        return _.cloneDeep(map);
    }
}

export default {
    template: template,
    bindings: {},
    controller: RegisteringController
};