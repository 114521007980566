import template from './doorWindowInstallationModal.html';

class WindowInstallationModalComponent {
    /*@ngInject*/
    constructor(actionContext, $ngRedux) {
        this.actionContext = actionContext;
        this.$ngRedux = $ngRedux;
        this.installationImages = [];
        this.productSku = '';
        this.images = {
            new: {
                doorInstallationCorrect: '/Content/images/photos/2018_Door_Correct-Edited_FINAL.jpg',
                doorInstallationIncorrect: '/Content/images/photos/2018_Door_Incorrect-Edited_FINAL.jpg',
                windowInstallationCorrect: '/Content/images/photos/2018_Window_Correct-Edited_FINAL.jpg',
                windowInstallationIncorrect: '/Content/images/photos/2018_Window_Incorrect-Edited_FINAL.jpg'
            },
            old: {
                doorInstallationCorrect: '/Content/images/photos/doorInstallation_Correct.png',
                doorInstallationIncorrect: '/Content/images/photos/doorInstallation_Incorrect.png',
                windowInstallationCorrect: '/Content/images/photos/windowInstallation_Correct.png',
                windowInstallationIncorrect: '/Content/images/photos/windowInstallation_Incorrect.png'
            }
        }
    }

    $onInit() {
        this.getProductSku();
    }

    getProductSku() {
        this.productSku = this.$ngRedux.getState().system.devices.sensorsByID[this.$ngRedux.getState().system.devices.selectedSensorID].ProductSku;
        if (this.productSku === 'DWS') {
            this.installationImages = this.images.old;
        }
        if (this.productSku === 'RE122') {
            this.installationImages = this.images.new;
        }
    }
}

export default {
    template: template,
    bindings: {},
    controller: WindowInstallationModalComponent
};