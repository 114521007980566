import * as _ from 'lodash';
import './categoryList.scss';
import template from './categoryList.html';

class CategoryListController {
    /*@ngInject*/
    constructor(actionContext, $state, dataContext, constants, $log) {
        this.actionContext = actionContext;
        this.$state = $state;
        this.dataContext = dataContext;
        this.constants = constants;
        this.$log = $log;
    }

    $onInit() {
        this.setTargetSku();
    }

    setTargetSku() {
        this.dataContext.modSwap.checkEligibility().then((res) => {
            if (res != null && res.Message === 'Eligible') {
                if (_.some(this.constants.panelIDs.hub, (productId) => res.TargetPanelProductId === productId)) {
                    this.actionContext.airfxFlow.setTargetSku('HSVGW');
                }
                else if (_.some(this.constants.panelIDs.iq4, (productId) => res.TargetPanelProductId === productId)) {
                    this.actionContext.airfxFlow.setTargetSku('IQPANEL');
                }
            }
        }).catch((error) => {
            this.$log.error('Error setting Target Sku', error);
        });
    }

    setCategory(category) {
        this.actionContext.equipmentSetup.onCategorySelect(category);
        this.actionContext.airfxFlow.setCurrentCategory(category);
        this.$state.go('airfx.redirectToAirfx', { category: category });
    }
}

export default {
    template: template,
    bindings: {},
    controller: CategoryListController
};