import template from './decal.html';

class DecalComponent {
    /*@ngInject*/
    constructor() {
        
    }
}

export default {
    template: template,
    bindings: {},
    controller: DecalComponent
};