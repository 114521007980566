import * as _ from 'lodash';
import { combineReducers } from 'redux';

import createLogger from 'redux-logger';
import thunk from 'redux-thunk';

import { router as routerReducer } from 'redux-ui-router';

import staticReducer from './common/reducer/static.reducer';
import accountReducer from './common/reducer/account';
import applicationReducer from './common/reducer/application';
import systemReducer from './common/reducer/system';
import zWaveReducer from './common/reducer/zWave.reducer';

const makeRootReducer = (reducer) => (state, action) => {
    switch (action.type) {
    case 'HYDRATE':
        state = _.mergeCustom({}, state, action.payload.state);
        break;
    case 'LOGOUT':
        state = undefined;
        break;
    }

    return reducer(state, action);
};

const reducer = combineReducers({
    ignore: staticReducer(['shell']),
    account: accountReducer,
    application: applicationReducer,
    router: routerReducer,
    system: systemReducer,
    zWave: zWaveReducer
});

export default function($ngReduxProvider, constants) {
    'ngInject';

    let middlewares = [
        // promises are not returned from dispatch functions unless redux-thunk
        // is the first middleware
        thunk,
        'ngUiRouterMiddleware',
        'persistStateToServerMiddleware',
        'stateDescriptionMiddleware'
    ];

    if (constants.debug === true) {
        middlewares = _.concat(middlewares, [
            createLogger({ collapsed: true })
        ]);
    }

    $ngReduxProvider.createStoreWith(makeRootReducer(reducer), middlewares, window.__REDUX_DEVTOOLS_EXTENSION__ && [window.__REDUX_DEVTOOLS_EXTENSION__()]);
}
