import template from './home.html';

class HomeController {
    /*@ngInject*/
    constructor(constants) {
        this.constants = constants;
    }
}

export default {
    template: template,
    bindings: {},
    controller: HomeController
};