import * as angular from 'angular';
import * as _ from 'lodash';

class PreloaderService {
    /*@ngInject*/
    constructor($q, $log) {
        this.$q = $q;
        this.$log = $log;
    }

    preloadImages(imageLocations) {
        return this.$q.all(_.map(imageLocations, (img) => {
            return this.preloadImage(img);
        }));
    }

    preloadImage(imageLocation) {
        let defer = this.$q.defer();

        angular.element(new Image())
            .on('load', (data) => {
                this.$log.debug('Preloaded ' + imageLocation);
                defer.resolve(imageLocation);
            })
            .on('error', (data) => {
                this.$log.error('Failed to preload image ' + imageLocation);
                defer.reject(imageLocation);
            })
            .prop('src', imageLocation);

        return defer.promise;
    }
}

export default PreloaderService;