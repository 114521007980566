import * as _ from 'lodash';
import moment from 'moment';
import dateKind from '../common/util/dateKind';

import template from './home.html';

class SetTestModeComponent {
    /*@ngInject*/
    constructor($ngRedux, $state, $q, actionContext, constants, dataContext) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$q = $q;
        this.actionContext = actionContext;
        this.constants = constants;
        this.dataContext = dataContext;

        this.slider = {
            value: 3,
            options: {
                floor: 0,
                ceil: 30,
                step: 1,
                translate: (value) => {
                    if (value === 0) {
                        return `<span class="test-duration">${value} Days</span>`;
                    } else if (value === 1) {
                        return `<span class="test-duration">${value} Day</span>`;
                    } else {
                        return `<span class="test-duration">${value} Days</span>`;
                    }
                }
            }
        };
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        if (this.targetSku === this.constants.panelVersions.iqPanel) {
            this.customerSupportPhone = this.constants.customerSupportIQ.phone;
        } else {
            this.customerSupportPhone = !this.$ngRedux.getState().account.origin.startedOnAmazon
                ? this.constants.customerSupportHub.phone
                : this.constants.customerSupportHub.amazonPhone;
        }

    }

    $onDestroy() {
        this.unsubscribe();
    }

    currentDate() {
        return moment().format('MMMM Do');
    }

    armedStartDate() {
        return this.slider.value !== 0
            ? moment().add(this.slider.value + 1, 'days').format('MMMM D, YYYY')
            : 'Now';
    }

    submit() {
        this.isUpdating = true;

        return this._updateTestMode()
            .then(() => {
                if (this.isIq4Panel) {
                    return this.$state.go('training.arm-away');
                } else {
                    return this.$state.go('panel.finalizing');
                }                
            }).finally(() => {
                this.isUpdating = false;
            });
    }

    _updateTestMode() {
        const endDate = moment().add(this.slider.value, 'days').endOf('day').toDate();
        const utcEndDate = dateKind.toUtcKind(endDate);
        const inTestMode = this.slider.value !== 0;
        return this.actionContext.testmode.updateEndDate(utcEndDate, inTestMode);
    }

    _mapState(state) {
        return {
            hasError: state.system.testmode.hasError,
            targetSku: state.account.airfxFlow.targetSku,
            isIq4Panel: state.account.user.isIq4Panel
        };
    }
}

export default {
    template: template,
    bindings: { },
    controller: SetTestModeComponent
};