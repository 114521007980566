let scrollToIfDirective = ($window, $timeout) => {
    'ngInject';

    let isElementInViewport = (el) => {
        var rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= ($window.innerHeight || $window.document.documentElement.clientHeight) &&
            rect.right <= ($window.innerWidth || $window.document.documentElement.clientWidth)
        );
    };

    return {
        restrict: 'A',
        require: '^scrollableContent',
        link: (scope, element, attributes, controller) => {
            let condition = attributes.awScrollToIf === true || attributes.awScrollToIf === 'true';
            if (condition) {
                // Angular Mobile UI's scrollableHeader directive applies the padding using a 
                // 30 millisecond interval. We must apply our scroll sometime after the initial
                // 30 millisecond interval.
                $timeout(() => {
                    let target = element[0];
                    if (!isElementInViewport(target)) {
                        // Find the padding top applied to the scrollable conatiner.
                        let scrollableContent = controller.scrollableContent;
                        let scrollable = scrollableContent.offsetParent;
                        while (!scrollable && !scrollable.hasClass('scrollable')) {
                            scrollable = scrollable.offsetParent;
                        }
                        let styles = $window.getComputedStyle(scrollable);
                        let paddingTop = parseInt(styles.paddingTop);
                        let marginTop = parseInt(attributes.awScrollToMargin) || 0;

                        controller.scrollTo(target, paddingTop + marginTop);
                    }
                }, 40);
            }
        }
    };
};

export default scrollToIfDirective;