export const ACCOUNT_CUSTOMER_BILLING_INFO_SUBMISSION_BUSY = ' ACCOUNT_CUSTOMER_BILLING_INFO_SUBMISSION_BUSY ';
export const ACCOUNT_CUSTOMER_BILLING_INFO_SUBMISSION_SUCCESSFUL = 'ACCOUNT_CUSTOMER_BILLING_INFO_SUBMISSION_SUCCESSFUL';
export const ACCOUNT_CUSTOMER_BILLING_INFO_SUBMISSION_FAILED = 'ACCOUNT_CUSTOMER_BILLING_INFO_SUBMISSION_FAILED';
export const VALIDATE_ROUTING_NUMBER_BUSY = 'VALIDATE_ROUTING_NUMBER_BUSY';
export const VALIDATE_ROUTING_NUMBER_SUCCESSFUL = 'VALIDATE_ROUTING_NUMBER_SUCCESSFUL';
export const VALIDATE_ROUTING_NUMBER_FAILED = 'VALIDATE_ROUTING_NUMBER_FAILED';
export const ACCOUNT_CUSTOMER_BILLING_INFO_RETRIEVAL_BUSY = 'ACCOUNT_CUSTOMER_BILLING_INFO_RETRIEVAL_BUSY';
export const ACCOUNT_CUSTOMER_BILLING_INFO_RETRIEVAL_SUCCESSFUL = 'ACCOUNT_CUSTOMER_BILLING_INFO_RETRIEVAL_SUCCESSFUL';
export const ACCOUNT_CUSTOMER_BILLING_INFO_RETRIEVAL_FAILED = 'ACCOUNT_CUSTOMER_BILLING_INFO_RETRIEVAL_FAILED';

export const TOGGLE_IS_CREDIT = 'TOGGLE_IS_CREDIT';

export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export const ALIGN_STATE = 'ALIGN_STATE';
export const RESET_STATE = 'RESET_STATE';

class CustomerBillingInfoActions {
    /*@ngInject*/
    constructor($log, $ngRedux, $q, $state, dataContext) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.$state = $state;
        this.dataContext = dataContext;
        this.dispatch = $ngRedux.dispatch;
    }

    saveCustomerBillingInfo(newcbi, accountId) {
        this.$ngRedux.dispatch({
            type: START_LOADING
        });

        return this.dataContext.customerBillingInfo.saveCustomerBillingInfo(newcbi, accountId).then((result) => {
            this.$ngRedux.dispatch({
                type: ACCOUNT_CUSTOMER_BILLING_INFO_SUBMISSION_SUCCESSFUL,
                payload: {
                    CustomerBillingInfoID: result
                }
            });
        })
            .catch((error) => {
                this.$ngRedux.dispatch({
                    type: ACCOUNT_CUSTOMER_BILLING_INFO_SUBMISSION_FAILED
                });

                this.$ngRedux.dispatch({
                    type: STOP_LOADING
                });

                this.$log.error(`Error submitting payment method info for the customer with accountID : ${accountId}`, error);
                return this.$q.reject();
            });
    }

    getCustomerBillingInfo() {
        const { account: { customerBillingInfo: { customerBillingInfoID } } } = this.$ngRedux.getState();
        
        this.$ngRedux.dispatch({
            type: START_LOADING
        });

        if (customerBillingInfoID) {
            return this.dataContext.customerBillingInfo.getCustomerBillingInfo(customerBillingInfoID).then((result) => {
                    this.$ngRedux.dispatch({
                        type: ACCOUNT_CUSTOMER_BILLING_INFO_RETRIEVAL_SUCCESSFUL,
                        payload: result
                    });
                })
                .catch((error) => {
                    this.$ngRedux.dispatch({
                        type: ACCOUNT_CUSTOMER_BILLING_INFO_RETRIEVAL_FAILED
                    });

                    this.$ngRedux.dispatch({
                        type: STOP_LOADING
                    });

                    this.$log.error('Error retrieval payment method info for the customer', error);
                    return this.$q.reject();
                });
        } else {
            this.$ngRedux.dispatch({
                type: STOP_LOADING
            });
        }
    }

    toggleIsCredit() {
        this.$ngRedux.dispatch({ type: TOGGLE_IS_CREDIT });
    }

    startLoading() {
        this.$ngRedux.dispatch({ type: START_LOADING });
    }

    stopLoading() {
        this.$ngRedux.dispatch({ type: STOP_LOADING });
    }

    alignState(newState) {
        this.$ngRedux.dispatch({ type: ALIGN_STATE, payload: newState });
    }

    resetState() {
        this.$ngRedux.dispatch({ type: RESET_STATE });
    }
}

export default CustomerBillingInfoActions;