import './desktopOverlay.scss';
import Template from './desktopOverlay.html';

class DesktopComponent {
    /*@ngInject*/
    constructor($ngRedux, actionContext, constants, mobileDetectService) {
        this.customerSupport = constants.customerSupport;
        this.constants = constants;
        this.actionContext = actionContext;
        this.mobileDetectService = mobileDetectService;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis.bind(this))(this);
    }

    $onInit() {
        if (!_.isEmpty(this.activationCode)) {
            this.actionContext.login.postActivationCodeDesktop(this.activationCode);
        }
    }

    $onDestroy() {
        this.unsubscribe();
    }

    mapStateToThis(state) {
        return {
            activationCode: state.account.user.activationCode,
            hasError: state.account.user.hasError,
            message: state.account.user.message,
            isBusy: state.account.user.isBusy,
            show: !this.mobileDetectService.isMobile() && !this.constants.disableDesktopCheck && !state.account.user.isImpersonator
        };
    }
}

export default {
    template: Template,
    controller: DesktopComponent,
    bindings: {}
};