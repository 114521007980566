import * as _ from 'lodash';
import ensure from '../util/ensure';

export const PREMISES_FETCH_REQUEST = 'PREMISES_FETCH_REQUEST';
export const PREMISES_FETCH_FAILURE = 'PREMISES_FETCH_FAILURE';
export const PREMISES_FETCH_SUCCESS = 'PREMISES_FETCH_SUCCESS';

export const PREMISES_UPDATE_REQUEST = 'PREMISES_UPDATE_REQUEST';
export const PREMISES_UPDATE_FAILURE = 'PREMISES_UPDATE_FAILURE';
export const PREMISES_UPDATE_SUCCESS = 'PREMISES_UPDATE_SUCCESS';

export const PREMISES_CONFIRMED = 'PREMISES_CONFIRMED';

export const PREMISES_CLEAR_ERROR = 'PREMISES_CLEAR_ERROR';

class PremisesActions {
    /*@ngInject*/
    constructor ($log, $ngRedux, $q, constants, dataContext) {
        // services
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.constants = constants;
        this.dataContext = dataContext;
    }

    /**
     * Fetches the current user's premises address and adds it to the application state.
     * 
     * @returns {promise} A promise that returns undefined if resolved and an error if
     * rejected. 
     */
    fetchAddress() {
        this._onFetchPremises();

        return this.dataContext.account.getAccountPremises()
            .then((data) => {
                this._onFetchPremisesSuccess(data);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error fetching the customer\'s address.', error);
                this._onFetchPremisesFailure(error);
                return this.$q.reject('fetchAddress');
            });
    }

    /**
     * Updates the current user's address if the given address is valid. An address is
     * considered valid if its city, state, and zipcode can be validated by Melissa data.
     * 
     * {@link forceAddress} can be used to force an address to update regardless of its validity.
     * 
     * @param {Object} address An address to update.
     * @returns {promise} A promise that returns undefined if resolved and an error if
     * rejected.
     */
    updateAddress(address) {
        ensure.isObject('address', address);
        this._onUpdatePremises();

        return this.dataContext.address.validate(address)
            .then((response) => {
                // The address validation service returns true if the address is valid.
                if (!response.IsValid) {
                    throw this._error({
                        data: {
                            Message: 'Invalid address.'
                        }
                    });
                }

                return this.dataContext.account.updateAccount(address);
            })
            .then((response) => {
                this._onUpdatePremisesSuccess(response);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error updating the customer\'s address.', error);
                this._onUpdatePremisesFailure(error);
                return this.$q.reject(error);
            });
    }

    /**
     * Updates the current user's address regardless of its validity.
     * 
     * @param {Object} address An address to update. 
     * @returns {promise} A promise that returns undefined if resolved and an error if
     * rejected. 
     */
    forceAddress(address) {
        ensure.isObject('address', address);
        this._onUpdatePremises();

        return this.dataContext.account.updateAccount(address)
            .then((response) => {
                this._onUpdatePremisesSuccess(response);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error updating the customer\'s address.', error);
                this._onUpdatePremisesFailure(error);
                return this.$q.reject(error);
            });
    }

    /**
     * Validate the given address. If the address is not valid a suggested address
     * may be returned.
     * 
     * @param {Object} address An address to validate.
     * @returns {promise} A promise that returns {@link AddressValidationResult} if
     * resolved and an error if rejected. 
     */
    validateAddress(address) {
        ensure.isObject('address', address);
        return this.dataContext.address.validate(address);
    }

    isSaleRestricted(address) {
        ensure.isObject('address', address);
        return this.dataContext.address.isSaleRestricted(address);
    }
    /**
     * Checks if user entered new address before updating
     * @param {Object} address the existing address for the user
     * @param {Object} currentAddress the new user submitted address
     * @returns {Boolean} Returns true or false if the new address is different from the old address 
     */
    isDifferentAddress(address, currentAddress) {
        return !_.isEqual(address, currentAddress);
    }

    /**
     * Marks the user's premises address as confirmed.
     */
    confirm() {
        this.$ngRedux.dispatch({
            type: PREMISES_CONFIRMED
        });
    }

    /**
     * Clears any errors associated with predispatch contacts in the
     * application state.
     */
    clearError() {
        this.$ngRedux.dispatch({
            type: PREMISES_CLEAR_ERROR
        });
    }

    _onFetchPremises() {
        this.$ngRedux.dispatch({
            type: PREMISES_FETCH_REQUEST
        });
    }

    _onFetchPremisesFailure(error) {
        this.$ngRedux.dispatch({
            type: PREMISES_FETCH_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onFetchPremisesSuccess(data) {
        this.$ngRedux.dispatch({
            type: PREMISES_FETCH_SUCCESS,
            payload: {
                data: data
            }
        });
    }

    _onUpdatePremises() {
        this.$ngRedux.dispatch({
            type: PREMISES_UPDATE_REQUEST
        });
    }

    _onUpdatePremisesFailure(error) {
        this.$ngRedux.dispatch({
            type: PREMISES_UPDATE_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onUpdatePremisesSuccess(data) {
        this.$ngRedux.dispatch({
            type: PREMISES_UPDATE_SUCCESS,
            payload: {
                data: data
            },
            metadata: {
                sectionType: this.constants.sectionTypes.premise,
                customDescription:true,
                description: '',
                persist: true 
            }
        });
    }
}

export default PremisesActions;