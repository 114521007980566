import * as _ from 'lodash';

import template from './skipped-review.html';

class SkippedReviewController {
    /*@ngInject*/
    constructor ($ngRedux, $state, actionContext) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.actionContext = actionContext;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
    }

    $onDestory() {
        this.unsubscribe();
    }

    /**
     * Invoked when the customer clicks the continue button.
     */
    onContinue() {
        _.forEach(this.sensors, sensor => {
            // During an initial order, sensors that are not connected to the customer's panel display an error
            // message and allow them to skip the setup of that sensor. Since the sensor is already not connected
            // to the customer's panel, calling disableSensor() will cause an error.
            if (sensor.IsConnected) {
                this.actionContext.device.disableSensor(sensor);
            }
        });
    }

    /**
     * Invoked when the customer selects a sensor.
     * 
     * @param {Object} sensor
     */
    onSensorSelected(sensor) {
        const deviceActions = this.actionContext.device;
        const productActions = this.actionContext.product;

        if (deviceActions.isSensorTampered(sensor)) {
            this.$state.go('sensor.item.tamper', { sensorId: sensor.SensorID });
            return;
        }

        if (deviceActions.isSensorMalfunctioning(sensor)) {
            this.$state.go('sensor.item.malfunction', { sensorId: sensor.SensorID });
            return;
        }

        if (!deviceActions.isSensorConnected(sensor)) {
            this.$state.go('sensor.item.not-connected', { sensorId: sensor.SensorID });
            return;
        }
        
        if (!_.isNil(productActions.getBoxText(sensor.sku))) {
            this.$state.go('sensor.item.box', { sensorId: sensor.SensorID });
            return;
        }

        this.$state.go('sensor.item.overview', { sensorId: sensor.SensorID });
    }

    _mapState(state) {
        return {
            isInitialOrder: state.account.user.isInitialOrder,
            sensors: _.filter(this.actionContext.device.getOrderedSensors(),sensor => {
                return sensor.isSkipped;
            })
        };
    }
}

export default {
template: template,
    bindings: {},
controller: SkippedReviewController
};