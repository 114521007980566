import * as _ from 'lodash';
import error from '../../util/error';
import moment from 'moment';

import { PASSCODE_UPDATE_REQUEST, PASSCODE_UPDATE_FAILURE, PASSCODE_UPDATE_SUCCESS, PASSCODE_VALIDITYCHECK_REQUEST, PASSCODE_VALIDITYCHECK_FAILURE, PASSCODE_VALIDITYCHECK_SUCCESS } from '../../action/customer.actions';

const initialState = {
    ignore: ['lastUpdatedDate', 'isUpdating', 'hasError', 'message'],
    value: null,
    lastUpdatedDate: null,
    isUpdating: false,
    hasError: false,
    message: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case PASSCODE_UPDATE_REQUEST:
            return _.mergeCustom({},
                state,
                {
                    isUpdating: true,
                    hasError: false,
                    message: null
                });
        case PASSCODE_UPDATE_FAILURE:
            return _.mergeCustom({},
                state,
                {
                    isUpdating: false,
                    hasError: true,
                    message: error.parse(action.payload.error)
                });
        case PASSCODE_UPDATE_SUCCESS:
            return _.mergeCustom({},
                state,
                {
                    value: action.payload.data,
                    lastUpdatedDate: moment.utc(),
                    isUpdating: false
                });
        case PASSCODE_VALIDITYCHECK_REQUEST:
            return _.mergeCustom({},
                state,
                {
                    isUpdating: true,
                    hasError: false,
                    message: null
                });
        case PASSCODE_VALIDITYCHECK_FAILURE:
            return _.mergeCustom({},
                state,
                {
                    isUpdating: false,
                    hasError: true,
                    message: error.parse(action.payload.error)
                });
        case PASSCODE_VALIDITYCHECK_SUCCESS:
            return _.mergeCustom({},
                state,
                {
                    isUpdating: false
                });
    default:
        return state;
    }
};