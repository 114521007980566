import template from './garageDoorInstallationModal.html';

class GarageDoorInstallationModalController {
    /*@ngInject*/
    constructor () {
        
    }
}

export default {
    template: template,
    bindings: { },
    controller: GarageDoorInstallationModalController
};