import template from './accessories.html';

class AccessoriesController {
    /*@ngInject*/
    constructor(constants, actionContext) {
        this.constants = constants;
        this.actionContext = actionContext;
    }
}

export default {
    template: template,
    bindings: {},
    controller: AccessoriesController
};