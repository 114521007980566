import template from './home.html';

import * as _ from 'lodash';

class HomeController {
    /*@ngInject*/
    constructor($log, actionContext, constants, dataContext, $ngRedux) {
        this.$log = $log;
        this.actionContext = actionContext;
        this.constants = constants;
        this.dataContext = dataContext;
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.actionContext.panel.fetchLastMessageDate();
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
    }

    _mapState(state) {
        return {
            panelVersion: state.system.panel.type
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: HomeController
};