import * as _ from 'lodash';

const SECOND = 1000;
const MINUTE = 60 * SECOND;

export default _.merge({
    appInfo: window.appInfo, // from index.html

    api: {
        prefix: window.__baseHref + 'api',
        timeout: 1 * MINUTE, // 60 seconds max wait time for an API call
        warningAfter: 5 * SECOND
    },

    analytics: window.analytics ||
    {
        enabled: false
    },

    alertDuration: 5 * SECOND,

    beta: {
        realTimeSensorTesting: {
            settingKey: 'ActivationsWizard:RealTimeSensorTesting'
        }
    },

    suppressAnalyticsSettingKey: 'SuppressAnalytics',

    cacheRefreshInterval: 5 * MINUTE, // 5 minutes

    customerSupport: {
        phone: '18776025276',
        callback: {
            timeIncrement: 15,
            timeZone: 'local'
        },
        videoOnly: '18553422063'
    },

    customerSupportHub: {
        phone: "18332539654",
        amazonPhone: "18663633478"
    },

    customerSupportIQ: {
        phone: "18442247222"
    },

    urls: {
        customerPortal: 'my.frontpointsecurity.com',
        activationsWizard: 'activate.frontpointsecurity.com',
        activationsWizardSetup: 'activate.frontpointsecurity.com/setup',
        standardUserGuide:
            'www.frontpointsecurity.com/~/media/frontpoint/files/equipment-guides/resource-center-overall2/quickstart_standard_controlpanel.pdf',
        touchScreenUserGuide:
            'www.frontpointsecurity.com/~/media/frontpoint/files/equipment-guides/resource-center-overall2/quickstart_touchscreen_controlpanel.pdf',
        appleAppStore: 'itunes.apple.com/us/app/frontpoint/id945797198',
        googlePlayStore: 'play.google.com/store/apps/details?id=com.alarm.alarmmobile.android.frontpoint',
        frontpointSupport: 'www.frontpointsecurity.com/support/faqs',
        adcSSO: 'alarmfrontpoint://loginaslogin?token='
    },

    storage: {
        prefix: 'fp',
        accessTokenKey: 'accessToken',
        orderIdToActivateKey: 'orderId',
        inMobileAppKey: 'inMobileApp',
        FirstAdditionalAttempt: 'firstAdditionalAttempt',
        isBrowser: 'isBrowser',
        numberOfSmartLocksToActivate: 'numberOfSmartLocksToActivate',
        skipOrderArrivalCheckExpiration: 'skipOrderArrivalCheckExpiration'
    },

    http: {
        associatedOrdersHeader: 'X-Frontpoint-Associated-Orders'
    },

    events: {
        stateChangeSuccess: '$stateChangeSuccess',
        stateChangeError: '$stateChangeError',
        stateNotFound: '$stateNotFound',
        stateChangeStart: '$stateChangeStart',

        login: 'event:login',
        loginRequired: 'event:auth-loginRequired',
        logout: 'event:logout',

        showProgress: 'event:progress.show',
        closeProgress: 'event:progress.close',
        callSupportNotification: {
            name: '$supportNotification',
            intervalCount: 30
        },
        userInfoReceived: 'userInfoReceived',
        startTimer: 'startTimer',
        zWaveWizardCompleted: 'zwaveComplete',
        segment: {
            barcodeScannerLoadTime: 'Scanner Load Time',
            panelSwapStartSwap: 'Panel Swap - Start Swap',
            panelSwapClickSetupOption: 'Panel Swap - Click Setup Option',
            panelSwapKeyScreenLanding: 'Panel Swap - Key screen landing',
            panelSwapNotSupportedLanding: 'Panel Swap - Panel not supported landing',
            panelSwapClickCallSupport: 'Panel Swap - Click Call Support Button',
            panelSwapSensorTransferNext: 'Panel Swap - Sensor Transfer Next Button',
            panelSwapComplete: 'Panel Swap - Complete',
            panelSwapUserCodeAdded: 'Panel Swap - Add User Code',
            panelSwapUserCodeDeleted: 'Panel Swap - Delete User Code',
            panelSwapClickInstructionsNextButton: 'Panel Swap - Click Instructions Next Button',
            panelSwapClickKeypadSetupNextButton: 'Panel Swap - Click Keypad Setup Next Button',
            radioSwapStartSwap: 'Radio Swap - Start Swap',
            radioSwapComplete: 'Radio Swap - Complete'
        }
    },

    errors: {
        grantRejected: 'grant.rejected',
        resolveRedirect: 'error:resolve-redirect'
    },

    deviceStatus: {
        unknown: { Name: 'Unknown', ID: 0 },
        opened: { Name: 'Opened', ID: 2 },
        closed: { Name: 'Closed', ID: 3 },
        malfunction: { Name: 'Malfunction' },
        tampered: { Name: 'Tamper', ID: 4 },
        openedClosed: { Name: 'OpenedClosed' },
        disarmed: { Name: 'Disarmed', ID: 5 },
        armedStay: { Name: 'ArmedStay', ID: 6 },
        armedAway: { Name: 'ArmedAway', ID: 7 }
    },

    chimeSounds: {
        sounds: [
            { "id": 0, "name": "Voice" },
            { "id": 1, "name": "High Wire" },
            { "id": 2, "name": "On The Hunt" },
            { "id": 3, "name": "Doorbell" },
            { "id": 4, "name": "Heaven" },
            { "id": 5, "name": "Moon Beam" },
            { "id": 6, "name": "Dear Deer" },
            { "id": 7, "name": "Sonar" },
            { "id": 8, "name": "F1 New" },
            { "id": 9, "name": "Tinker Bell" },
            { "id": 10, "name": "Pizzicato" },
            { "id": 11, "name": "Beep" }
        ]
    },

    panelVersions: {
        iqPanel: 'IQPANEL',
        xtPanel: 'SIMONXT',
        climaxHub: 'HSVGW'
    },

    panelIDs: {
        hub: [139, 207, 212, 215],
        iq4: [221, 222]
    },

    regex: {
        // USA or Canadian phone number
        // https://www.safaribooksonline.com/library/view/regular-expressions-cookbook/9781449327453/
        phoneNumber: /^(?!(\d)\1+$|(?:0(?=1)|1(?=2)|2(?=3)|3(?=4)|4(?=5)|5(?=6)|6(?=7)|7(?=8)|8(?=9)|9(?=0)){9}\d$|(?:0(?=9)|1(?=0)|2(?=1)|3(?=2)|4(?=3)|5(?=4)|6(?=5)|7(?=6)|8(?=7)|9(?=8)){9}\d$)[\d\s\-()]{10}$/,
        phoneNumberMasked: /^(?!\((\d)\1+\)\s(\d)\1+-(\d)\1+$|^\(123\)\s456-7890$|^\(098\)\s765-4321$)[\d\s\-()]{14}$/,
        activationCode: /[0-9]*/,
        // USA or Canadian postal codes
        // USA: ^[0-9]{5}(?:-[0-9]{4})?$
        // Canadian: ^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$
        // https://www.safaribooksonline.com/library/view/regular-expressions-cookbook/9781449327453/ch04s14.html
        // https://www.safaribooksonline.com/library/view/regular-expressions-cookbook/9781449327453/ch04s15.html
        postalCode: /^[0-9]{5}(?:-[0-9]{4})?$|^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/,
        masterCode: /^[0-9][0-9]{3}$/,
        //fullname regex, require minimum of two "name" regexs
        fullname: /^([a-z ,.'-]*)\s+([a-z ,.'-]*)+$/i,
        name: /^[a-z ,.'-]+$/i,
        userCodename: /^[a-zA-Z]+$/,
        email:
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        dlCode: /^[a-zA-Z0-9]{6}$/,
        dlCodeHalf: /^[a-zA-Z0-9]{3}$/,
        dlCodeFormatted: /^[a-zA-Z0-9\-]{7}$/,
        imsid: /^[a-zA-Z0-9]{15}$/,
        keypadDlCode: /^[a-zA-Z0-9]{14}$/,
        latestKeypadDlCode: /^[a-zA-Z0-9]{13}[2-9]{1}$/,
        accountNumber: /^\s*((\d{5,19})|(\*{12}\d{4}))\s*$/,
        routingNumber: /^\s*(\d{9})\s*$/,
        creditCardNumber:
            /^\s*((?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))-?\d{4}-?\d{4}-?\d{4}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})|(?:\*{12}\d{4}))\s*$/,
        cvv: /^[0-9]{3}$/,
        mastercard: /^5[1-5][0-9]{14}|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))-?\d{4}-?\d{4}-?\d{4}/,
        visa: /^4/,
        amex: /^3[47]/,
        discover: /^6011|65|64[4-9]|622(1(2[6-9]|[3-9]\d)|[2-8]\d{2}|9([01]\d|2[0-5]))/
    },

    actionTypes: {
        stateChange: 'State Change',
        start: 'Start',
        end: 'End',
        error: 'Error',
        other: 'Other'
    },

    swapType: {
        radio: 'radio',
        mod: 'mod'
    },

    sectionTypes: {
        // Main
        airfx: 'Add Sensors',
        activation: 'Activation',
        boxcheck: 'Box Check',
        completed: 'Completed',
        instruction: 'Instruction',
        account: 'Account',
        login: 'Login',
        mastercode: 'Master Code',
        usercode: 'User Code',
        duresscode: 'Duress Code',
        menu: 'Menu',
        panel: 'Panel',
        passcode: 'Passcode',
        password: 'Password',
        predispatch: 'Predispatch',
        premise: 'Premise',
        review: 'Review',
        sensor: 'Sensor',
        signage: 'Signage',
        testmode: 'Testmode',
        training: 'Training',
        tos: 'Terms & Conditions',
        welcome: 'Welcome',
        wifi: 'Wifi',
        // Other
        help: 'Help',
        save: 'Save Progress',
        equipmentSetup: 'Equipment Setup',
        modSwap: {
            sensorActivation: 'Sensor Activation',
            dlCodeVerification: 'DL Code Verification',
            timeout: 'Timeout',
            roundTrip: 'Round Trip',
            success: 'Success',
            intro: 'Intro',
            sensorTransfer: 'Sensor Transfer'
        },
        radioSwap: {
            intro: 'Intro',
            roundTrip: 'Round Trip',
            timeout: 'Timeout',
            sensorTransfer: 'Sensor Transfer',
            success: 'Success'
        },
        logging: {
            error: 'Error',
            warning: 'Warn'
        },
        contact: "Contact",
        contract: "Contract",
        contractDeclined: "Contract Declined",
        premiseAddress: "Premise Address",
        paymentMethod: "Payment Method",
        registering: "Registering",
        registeringCompleted: "Registering Completed",
        servicePlanDescription: "Service Plan Description",
        saleRestricted: "Sale Restricted"
    },

    accountStatusTypes: {
        pendingActivation: 6,
        riskFreeTrial: 7
    },

    polling: {
        application: {
            keepAliveInterval: 59 * MINUTE,
            pingInterval: 5 * MINUTE
        },
        tests: {
            contact: {
                pollingInterval: 2.5 * SECOND,
                maxIntervalCount: 24
            },
            event: {
                pollingInterval: 2.5 * SECOND,
                maxIntervalCount: 24
            },
            interactive: {
                pollingInterval: 2.5 * SECOND,
                maxIntervalCount: 9999
            },
            walkthrough: {
                pollingInterval: 2.5 * SECOND,
                maxIntervalCount: 24
            },
            status: {
                pollingInterval: 2.5 * SECOND,
                maxIntervalCount: 24
            }
        },
        panel: {
            pollingInterval: 5.5 * SECOND,
            maxIntervalCount: 24
        },
        wifi: {
            pollingInterval: 5.5 * SECOND,
            maxIntervalGettingCount: 10,
            maxIntervalSettingCount: 10,
            delay: 2000
        },
        airfx: {
            initialization: {
                maxIntervalCount: 15,
                dblMaxIntervalCount: 30
            }
        },
        training: {
            pollingInterval: 2.5 * SECOND
        }
    },

    panel: {
        cacheExpiration: 60 * MINUTE,
        maxIterations: 30,
        iterationTime: 10 * SECOND
    },

    dualBand: {
        maxIterations: 6,
        iterationTime: 10 * SECOND
    },

    ping: {
        Swap: {
            pollingInterval: 15000,
            maxIntervalCount: 12
        },
        Keypad: {
            pollingInterval: 5000,
            maxIntervalCount: 60000
        }
    },

    roundProgress: {
        automatedSeconds: 40,
        pollingInterval: 250,
        barcodeFlowTimeout: 2500,
        nonBarcodeFlowTimeout: 10000
    },

    additionalOrdersDelay: 10 * SECOND,

    barcodePollingInterval: 250,
    barcodeScanTimeoutLengthInMilliSeconds: 60000,
    barcodeChanceAsPercent: 5,

    numberOfInvalidBarcodeScans: 1,

    password: {
        numberOfUnmaskedCharacters: 2,
        numberOfMaskedCharacters: 7
    },

    codes: {
        maxUserCodes: 4,
        blackListCodes: {
            iqPanel: ['0000', '0001', '1111', '2222', '7286', '6827', '9998'],
            xtPanel: ['0000', '0001', '4321', '6287']
        },
        insecureCodes: ['1234']
    },

    productsNotToActivate: [
        'camera',
        'wireless light control',
        'digital doorbell adapter'
    ],

    maxBoxSize: {
        DWS: 10,
        RE122: 10,
        GDOOR2: 0,
        RE101: 0,
        RE106: 1,
        MD: 3,
        RE110P: 3,
        RE111P_FP: 3,
        RDW: 0,
        'TX-E221': 0,
        CMS: 0,
        RE113: 0,
        SD: 2,
        'SDX-135Z': 2,
        RE114: 2,
        GBD: 2,
        '60-873-95': 2
    },

    boxLocation: {
        MD: 'in the sensors box.',
        SD: 'under the sensors box.',
        'SDX-135Z': 'under the sensors box.',
        RE114: 'under the sensors box.'
    },

    callSupportNotificationExceptions: [
        'activation',
        'airfx.noSensors',
        'airfx.modswapIntro',
        'airfx.radioSwapIntro',
        'error',
        'error-partner',
        'instruction',
        'login',
        'mastercode.home',
        'menu.home',
        'panel.home',
        'panel.connected',
        'predispatch',
        'premises.home',
        'premises.edit',
        'password',
        'review',
        'saved',
        'signage',
        'sensor.locks-setup',
        'sensor.complete',
        'sensor.item.box',
        'sensor.item.complete',
        'sensor.item.overview',
        'sensor.item.test',
        'training',
        'testmode.home',
        'testmode.complete',
        'usercode',
        'welcome.home'
    ],

    routerStates: {
        activationComplete: 'activation.complete',
        airfxCategoryList: 'airfx.categoryList',
        airfxBarcodeScanner: 'airfx.barcodeScanner',
        airfxBarcodeList: 'airfx.barcodeList',
        airfxList: 'airfx.list',
        airfxTunnel: 'airfx.tunnel',
        airfxNoSensors: 'airfx.noSensors',
        airfxComplete: 'airfx.complete',
        airfxFrontpointConnected: 'airfx.frontpointConnected',
        airfxPanelConnected: 'airfx.panelConnected',
        airfxInitialize: 'airfx.initialize',
        airfxSubCategoryList: 'airfx.subCategoryList',
        boxCheckHome: 'boxcheck.home',
        bluetooth: 'instruction.bluetooth',
        cameraInstructions: 'sensor.cameras-instructions',
        instructionPower: 'instruction.power',
        instructionPlace: 'instruction.place',
        instructionHome: 'instruction.home',
        instructionPlugin: 'instruction.plugin',
        instructionKeypad: 'instruction.keypad',
        locksHome: 'sensor.locks-home',
        keychainRemote: 'sensor.keychain-remote',
        keychainRemoteOverview: 'sensor.keychain-remote-overview',
        panicPendant: 'sensor.panic-pendant',
        panicPendantOverview: 'sensor.panic-pendant-overview',
        masterCodeHome: 'mastercode.home',
        menuHome: 'menu.home',
        orderSelectorActivate: 'orderselector.activate',
        passwordCreated: 'password.success',
        panelHome: 'panel.home',
        panelConnected: 'panel.connected',
        predispatch: 'predispatch',
        premisesHome: 'premises.home',
        premisesEdit: 'premises.edit',
        redirectToAirfx: 'airfx.redirectToAirfx',
        sensorHome: 'sensor.home',
        sensorList: 'sensor.list',
        sensorItemBox: 'sensor.item.box',
        sensorItemOverview: 'sensor.item.overview',
        sensorItemComplete: 'sensor.item.complete',
        signageHome: 'signage.home',
        sensorComplete: 'sensor.complete',
        signageKeychainRemote: 'signage.keychain-remote',
        signagePanicPendant: 'signage.panic-pendant',
        signageAccessories: 'signage.accessories',
        signageAssembly: 'signage.assembly',
        trainingHome: 'training.home',
        usercodeHome: 'usercode',
        testUserCode: 'airfx.testusercodes',
        welcome: 'welcome.home',
        swapintro: 'airfx.modswapIntro',
        radioSwapIntro: 'airfx.radioSwapIntro',
        swapSensorTransfer: 'airfx.modswapSensorTransfer',
        swapSuccess: 'airfx.modswapSuccess',
        radioSwapSensorTransfer: 'airfx.radioswapSensorTransfer',
        tosHome: 'tos.home',
        orderArrivalCheck: 'airfx.orderArrivalCheck',
        modifyUserCodes: 'airfx.userCodes'
    },

    armDisarmNavigationParams: {
        trainingDisarm: 'training.disarm-system',
        trainingArmStayBack: 'training.arm-stay-back',
        trainingArmAway: 'training.arm-away',
        trainingArmStayContinue: 'training.arm-stay-continue',
        userCodeHome: 'usercode.home'
    },

    airFxArmDisarmNavigationParams: {
        airFxTrainingDisarm: 'airfx.disarmSystem',
        airFxTrainingArmStayBack: 'airfx.armStay-back',
        airFxTrainingArmAway: 'airfx.armAway',
        airFxTrainingArmStayContinue: 'training.armStay-continue'
    },

    specificHelpPages: [
        'instruction.place',
        'instruction.plugin',
        'account.contact',
        'account.premise-address',
        'account.service-plan',
        'account.payment-method',
        'predispatch.edit',
        'passcode.home',
        'account.contract',
        'wifi.home',
        'wifi.networks',
        'testmode.home'
    ],

    additionalOrdersOrderId: -1,

    sensorListExceptionSkus: ['RE103', 'FP100'],

    zWaveProducts: {
        locks: ['^46241', '^46240', '^46242', 'YRD156ZW2-605', 'YRD156-ZW2-619', 'YRD156-ZW2-0BP'],
        yaleLocks: ['YRD156ZW2-605', 'YRD156-ZW2-619', 'YRD156-ZW2-0BP'],
        lights: ['LB60Z-1']
    },

    cameras: {
        skus: ['ADC-VDB101', 'ADC-VDB102', 'ODCAM', 'WCAM', 'ADC-V521IR', 'ADC-V522IR', 'ADC-V622']
    },

    zWaveOverlayTimeout: 1000,
    zWaveReturnUrl: '/web/wizard/v1/automation/zwaveinstaller2',
    zWaveWizardReturnUrl: '/sensor/zwave',
    enableLocksForInitialSetupWizard: false,

    mask: {
        phone: '(999) 999-9999'
    },

    monitoringPlan: {
        VideoOnly: {id: 6, name: 'Video Only', code: 'VO'}
    },

    productGroups: [
        { id: 1, name: 'intrusion', type: 'sensors' },
        { id: 3, name: 'environmental', type: 'sensors' },
        { id: 5, name: 'automation', type: 'home automation' },
        { id: 8, name: 'light control', type: 'home automation' },
        { id: 10, name: 'control panel', type: 'system control' },
        { id: 2, name: 'Keypad', type: 'system control' }
    ],

    keypads: {
        skus: ['Frontpoint Keypad - Rubber Key', 'Frontpoint Keypad - Standard Keys', 'FPKEY2']
    },

    touchscreens: {
        skus: ['FPTS-1']
    },

    modSwapGroupIdsToHide: ['0', '-1'],
    radioSwapGroupIdsToHide: ['0'],

    commTest: {
        pollingInterval: 4 * SECOND,
        lastMessageDateInterval: 12 * SECOND,
        maxIntervalCount: 10 * MINUTE
    },

    latestHubFirmwareVersion: 1928,

    panelDeviceId: '127',

    estimatedTimePerSensorSeconds: 60,

    setupWizardConfigRMR: 49.99,

    workflowStatus: {
        pending: 0,
        processing: 1,
        complete: 2,
        failed: 3,
        skipped: 4,
        cancelled: 5
    },

    fulfillmentStepIds: {
        programControlPanel: 8,
        checkPendingCommands: 40
    }
}, window.serverConstants);