import moment from 'moment';

import AbstractTestController from './AbstractTestController';

import template from './interactive.html';

class InteractiveTestController extends AbstractTestController {
    /*@ngInject*/
    constructor ($document, $log, $ngRedux, $state, actionContext, constants) {
        super($document, $log, $ngRedux, $state, constants);

        this.actionContext = actionContext;
        this.constants = constants;

        // look for sensor status updates up to 1 hour in the past
        this.startDate = moment.utc().add(-1, 'h'); 
    }

    $onInit() {
        super.$onInit();
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
    }

    canSupportSensor(sensor) {
        return this.isDoorWindow(sensor) || this.isGarageDoor(sensor);
    }

    getName() {
        return 'Interactive';
    }

    startTest() {
        const maxInterval = this.constants.polling.tests.interactive.pollingInterval;
        const maxAttempts = this.constants.polling.tests.interactive.maxIntervalCount;

        this.poll = this.actionContext.device.startPoll(this.sensor, '', maxInterval, maxAttempts);
    }

    stopTest() {
        this.actionContext.device.cancelPoll(this.poll);
    }
}

export default {
    template: template,
    bindings: {},
    controller: InteractiveTestController
};