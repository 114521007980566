import * as _ from 'lodash';
import './addUserCodeForm.scss';
import template from './addUserCodeForm.html';

class AddUserCodeFormController {
    /*@ngInject*/
    constructor(constants, $ngRedux, segment) {
        this.constants = constants;
        this.formName = 'addUserCodeForm';
        this.formInvalid = false;
        this.code = { FirstName: '', LastName: '', Code: '' };
        this.$ngRedux = $ngRedux;
        this.segment = segment;
        this.typeUserCode = true;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
    }

    onAddCode(form) {
        this.validateUserCodes();
        if (!this.formInvalid) {
            this.segment.registerEvent(this.constants.events.segment.panelSwapUserCodeAdded, {});
            this.onAddUserCode(this.code);
        } else {
            form.$setDirty();
        }
    }

    validateUserCodes() {
        const isBlacklisted = this._isBlacklistedUserCode();
        const isCodeInUse = this._isUserCodeInUse();
        const doCodesmatch = this._codesMatch();
        const areCodesEmpty = this._codesEmpty();
        const firstNameHasError = this._firstNameHasError();
        const lastNameHasError = this._lastNameHasError();

        if (isBlacklisted || isCodeInUse || !doCodesmatch || areCodesEmpty || firstNameHasError || lastNameHasError) {
            this.formInvalid = true;
            this.userCodeError();
        } else {
            this.formInvalid = false;
        }
    }
    
    userCodeError() {
        const isBlacklisted = this._isBlacklistedUserCode();
        const isCodeInUse = this._isUserCodeInUse();
        const doCodesmatch = this._codesMatch();
        const areCodesEmpty = this._codesEmpty();
        const firstNameHasError = this._firstNameHasError();
        const lastNameHasError = this._lastNameHasError();

        if (firstNameHasError) {
            return 'Please provide a first name for this user code.';
        }
        else if (lastNameHasError) {
            return 'Please provide a last name for this user code.';
        }
        if (isBlacklisted) {
            return 'The code you selected is not allowed. Please choose a different code';
        }
        else if (isCodeInUse) {
            return 'The code is not valid because it is already in use on your panel. Please choose a different code.';
        }
        else if (areCodesEmpty) {
            return 'User code fields cannot be empty. Please set your user codes and try again.';
        }
        else if (!doCodesmatch) {
            return 'The user codes provided do not match. Please check your values and try again.';
        }
    }

    _isUserCodeInUse() {
        const codes = _.map(this.userCodes, (code) => { return code.Code; });
        return _.includes(codes, this.code.Code);
    }

    _isBlacklistedUserCode() {
        /*
            GCR: Previously, we always passed codes through the XT panel constant. There
            is an IQ constant, so I added our 9998 duress value to it and will query all
            IQ targetsku systems through this constant. just want to see if anyone can review
            the constants between XT and iQ and make sure were okay to introduce this logic
         
         */
        if (this.targetSku === this.constants.panelVersions.iqPanel) {
            return _.includes(this.constants.codes.blackListCodes.iqPanel, this.code.Code);
        } else {
            return _.includes(this.constants.codes.blackListCodes.xtPanel, this.code.Code);
        }
        
    }

    _codesMatch() {
        if (this.code.Code === this.code.confirmCode) {
            return true;
        } else {
            return false;
        }
    }

    _codesEmpty() {
        if (this.code.Code === "" ||
            this.code.Code === undefined ||
            this.code.confirmCode === "" ||
            this.code.confirmCode === undefined) {
            return true;
        } else {
            return false;
        }
    }

    _firstNameHasError() {
        if (this.code.FirstName) {
            const firstNameRegex = new RegExp(this.constants.regex.userCodename);
            if (firstNameRegex.test(this.code.FirstName)) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    _lastNameHasError() {
        if (this.code.LastName) {
            const lastNameRegex = new RegExp(this.constants.regex.userCodename);
            if (lastNameRegex.test(this.code.LastName)) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    toggleUserCode() {
        this.typeUserCode = !this.typeUserCode;
    }

    _mapState(state) {
        return _.cloneDeep({
            userCodes: state.system.code.codes,
            targetSku: state.account.airfxFlow.targetSku
        });
    }

}

export default {
    template: template,
    bindings: {
        onCancel: '&',
        onAddUserCode: '&'
    },
    controller: AddUserCodeFormController
};