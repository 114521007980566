import * as angular from 'angular';
import 'angular-ui-router';

import NavigationService from './navigation.service';
import HistoryService from './history.service';
import SegmentService from './segment.service';

export default angular.module('fp:customerportal:common:service', [
        'ui.router'
    ])
    .service('navigation', NavigationService)
    .service('browserHistory', HistoryService)
    .service('segment', SegmentService);