class ModSwapService {
    /*@ngInject*/
    constructor(Restangular) {
        this.Restangular = Restangular;
    }

    checkEligibility() {
        return this.Restangular
            .all('modswap')
            .one('eligibilitycheck')
            .get();
    }

    swapPanel() {
        return this.Restangular
            .all('modswap')
            .one('swappanel')
            .withHttpConfig({ cache: false })
            .get();
    }

    getSensorList() {
        return this.Restangular
            .all('modswap')
            .one('sensorList')
            .customGET()
            .then((response) => {
                return response.plain();
            });
    }
}

export default ModSwapService;