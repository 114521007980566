import * as _ from 'lodash';

import template from './searching.html';

class SearchingComponent {
    /*@ngInject*/
    constructor($document, $ngRedux, $q, $state, constants, actionContext, dataContext, $interval) {
        this.$document = $document;
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.$state = $state;
        this.constants = constants;
        this.actionContext = actionContext;
        this.dataContext = dataContext;
        this.$interval = $interval;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);

        if (this.targetSku === this.constants.panelVersions.iqPanel) {
            this.customerSupportPhone = this.constants.customerSupportIQ.phone;
        } else {
            this.customerSupportPhone = !this.$ngRedux.getState().account.origin.startedOnAmazon
                ? this.constants.customerSupportHub.phone
                : this.constants.customerSupportHub.amazonPhone;
        }

        if (!_.isNil(this.constants.debug) && this.constants.debug) {
            this.$document.bind('keypress', _.bind(this._onKeypress, this));
        }

        if (this.startedOnAmazon) {
            this._pollFulfillmentStepStatus();
        } else {
            this._startPing();
        }
    }

    $onDestroy() {
        this._cancelPing();
        if (this.fulfillmentStepPollInterval) {
            this.$interval.cancel(this.fulfillmentStepPollInterval);
        }

        if (!_.isNil(this.constants.debug) && this.constants.debug) {
            this.$document.unbind('keypress');
        }

        this.unsubscribe();
    }

    tryAgain() {
        this.actionContext.kitCustomer.beginOrderConfiguration();
        this._pollFulfillmentStepStatus();
    }

    _startPing() {
        this.isSearching = true;
        this.isVerified = false;

        this.pingPromise = this.actionContext.panel.startPing();

        this.pingPromise
            .then(() => {
                // startPing() resolves the promise on success and rejects the promise on failure.
                this.isConnected = true;
            })
            .then(() => {
                return this.actionContext.panel.enableInteractiveMode();
            })
            .then(() => {
                this.isVerified = true;
            })
            .finally(() => {
                if (this.isConnected && this.isVerified) {
                    this._cancelPing();
                    this.$state.go('panel.connected');
                }

                this.isSearching = false;
                this.isConnected = false;
            });

        return this.pingPromise;
    }

    _pollFulfillmentStepStatus() {
        this.isSearching = true;
        this.isVerified = false;

        this.fulfillmentStepPollInterval = this.$interval((count) => {
                return this.dataContext.account.order.getOrderFulfillmentSteps().then(steps => {
                    this.panelProgramCompleted = _.some(steps,
                        (s) => {
                            return s.FulfillmentStepID === this.constants.fulfillmentStepIds.programControlPanel && s.DateFinished;
                        });
                    this.panelProgramFailed = _.some(steps,
                        (s) => {
                            return s.FulfillmentStepID === this.constants.fulfillmentStepIds.programControlPanel && s.ErrorMessage;
                        });

                    // run for 5 minutes
                    if (count === 50) {
                        this.$interval.cancel(this.fulfillmentStepPollInterval);
                        this.isSearching = false;
                    }

                    if (this.panelProgramCompleted || this.panelProgramFailed) {
                        this.$interval.cancel(this.fulfillmentStepPollInterval);
                        this.$state.go('panel.connected');
                        this.isSearching = false;
                    }
                });
            },
            6000);
    }

    _cancelPing() {
        this.actionContext.panel.cancelPing(this.pingPromise);
        delete this.pingPromise;
    }

    _mapState(state) {
        return {
            startedOnAmazon: state.account.origin.startedOnAmazon,
            isConnected: state.system.panel.isConnected,
            targetSku: state.account.airfxFlow.targetSku
        };
    }

    _onKeypress(event) {
        if (event.ctrlKey && event.code === 'Space') {
            this.actionContext.panel._onConnectSuccess();
            this.$state.go('panel.connected');
        }
    }
}

export default {
    template: template,
    bindings: {},
    controller: SearchingComponent
};