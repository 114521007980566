import './spinner.scss';
import template from './spinner.html';

class SpinnerController {
    constructor() {
        
    }
}

export default {
    template: template,
    bindings: {
        color: '@',
        message: '@',
        opacity: '<',
        isLoading: '<'
    },
    transclude: true,
    controller: SpinnerController
}