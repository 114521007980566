import template from './deleteUserCode.html';

class DeleteUserCodeController {
    /*@ngInject*/
    constructor() { }

    onCancel() {
        this.onCancel();
    }

    onDeleteUserCode() {
        this.onDeleteUserCode();
    }
}

export default {
    template: template,
    bindings: {
        onCancel: '&',
        onDeleteUserCode: '&'
    },
    controller: DeleteUserCodeController
};