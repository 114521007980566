import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import './panel.scss';

import commonModule from '../common';
import menuModule from '../menu';

import panelSearchingComponent from './searching.component';
import panelConnectedComponent from './connected.component';
import panelFinalizingComponent from './finalizing.component';
import panelCompleteComponent from './complete.component';

export default angular
    .module('fp:activationswizard:panel',
        [
            'ui.router',
            'angularMoment',
            ngRedux,
            commonModule.name,
            menuModule.name
        ])
    .config(/*@ngInject*/
        ($stateProvider, constants) => {
            $stateProvider
                .state('panel',
                    {
                        abstract: true,
                        parent: 'secure',
                        url: '/panel',
                        template: '<ui-view></ui-view>',
                        data: {
                            next: /*@ngInject*/($state, appActions) => {
                                appActions.completeModule(constants.sectionTypes.panel);
                                $state.go('mastercode.home');
                            },
                            prev: /*@ngInject*/ () => {

                            },
                            sectionType: constants.sectionTypes.panel,
                            theme: 'app-theme-default-navbar-gray'
                        },
                        resolve: {
                            isPasswordComplete: /*@ngInject*/ (grant, setupComplete) => {
                                return grant.only({
                                    test: 'isPasswordComplete',
                                    state: 'password.home'
                                });
                            }
                        }
                    })
                .state('panel.searching',
                    {
                        url: '/searching',
                        template: '<aw-panel-searching></aw-panel-searching>',
                        onEnter: /*@ngInject*/ (appActions) => {
                            appActions.startModule(constants.sectionTypes.panel);
                        }
                    })
                .state('panel.connected',
                    {
                        url: '/connected',
                        template: '<aw-panel-connected></aw-panel-connected>',
                        data: {
                            next: /*@ngInject*/($state) => {
                                $state.go('wifi.home');
                            },
                            theme: 'app-theme-green'
                        }
                    })
                .state('panel.finalizing',
                    {
                        url: '/finalizing',
                        template: '<aw-panel-finalizing></aw-panel-finalizing>',
                        data: {
                            next: /*@ngInject*/($state, appActions) => {
                                $state.go('panel.complete');
                            },
                            theme: 'app-theme-default-navbar-gray'
                        }
                    })
                .state('panel.complete',
                    {
                        url: '/complete',
                        template: '<aw-panel-complete></aw-panel-complete>',
                        data: {
                            next: /*@ngInject*/($state, appActions) => {
                                $state.go('menu.home');
                            },
                            theme: 'app-theme-green'
                        }
                    });
        })
    .component('awPanelSearching', panelSearchingComponent)
    .component('awPanelConnected', panelConnectedComponent)
    .component('awPanelFinalizing', panelFinalizingComponent)
    .component('awPanelComplete', panelCompleteComponent);
