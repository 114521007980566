import * as angular from 'angular';
import ngRedux from 'ng-redux';
import * as _ from 'lodash';
import 'angular-ui-router';
import 'ui-router.grant';
import './airfx.scss';

import commonModule from '../common';
import menuModule from '../menu';
import barcodeModule from './barcode';
import modswapModule from './modswap';
import radioswapModule from './radioswap';
import trainingModule from './training';
import sensorModule from '../sensor/item';
import { wifiModule } from '../wifi';

import airFxModalModule from './modals';
import airFxCompleteComponent from './complete.component';
import airFxHomeComponent from './home.component';
import airFxInitializeComponent from './initialize.component';
import airFxInitializeItemComponent from './initialize-item.component';
import airFxListComponent from './list.component';
import airFxListItemComponent from './list-item.component';
import airFxProcessComponent from './process.component';
import airFxNoSensorsComponent from './noSensors.component';
import airFxTunnel from './airfxTunnel.component';
import addSensorsCompleteComponent from './addSensorsComplete.component';
import addSensorsDelayCompleteComponent from './addSensorsDelayComplete.component';
import categoryListComponent from './categoryList.component';
import subCategoryListComponent from './subCategoryList.component';
import subCategoryListItemComponent from './subCategoryListItem.component';
import systemControlListComponent from './systemControlList.component';
import systemControlListItemComponent from './systemControlListItem.component';
import categoryEmptyComponent from './categoryEmpty.component';
import keypadComponent from './keypadActivation.component';
import keypadConnectedComponent from './keypadConnected.component';
import addKeypadComponent from './addKeypad.component';
import orderArrivalCheck from './orderArrivalCheck.component';

export default angular
    .module('fp:activationswizard:airfx',
        [
            'ui.router',
            'ui.router.grant',
            ngRedux,
            airFxModalModule.name,
            commonModule.name,
            menuModule.name,
            barcodeModule.name,
            modswapModule.name,
            radioswapModule.name,
            trainingModule.name,
            sensorModule.name
        ])
    .config(/*@ngInject*/
        ($stateProvider, constants) => {
            $stateProvider
                .state('airfx',
                    {
                        abstract: true,
                        parent: 'secure',
                        url: '/airfx',
                        template: '<ui-view></ui-view>',
                        data: {
                            next: /*@ngInject*/ ($state, appActions, $ngRedux, dataContext) => {
                                appActions.completeModule(constants.sectionTypes.airfx);
                                appActions.completeStep('airfx');
                                $state.go(constants.routerStates.sensorHome, { sku: $state.params.sku });
                            },
                            prev: /*@ngInject*/ ($state) => {
                                $state.go(constants.routerStates.menuHome);
                            },
                            sectionType: constants.sectionTypes.airfx,
                            theme: 'app-theme-default-navbar-gray',
                            progress: false
                        }
                    })
                .state('airfx.home',
                    {
                        url: '',
                        template: '<aw-airfx-home></aw-airfx-home>'
                    })
                .state('airfx.list',
                    {
                        url: '/list',
                        template: '<aw-airfx-list></aw-airfx-list>',
                        onEnter: /*@ngInject*/ (appActions) => {
                            appActions.startModule(constants.sectionTypes.airfx);
                        }
                    })
                .state('airfx.initial',
                    {
                        url: '/initial:sku',
                        controller: ($state, appActions, dataContext, constants) => {
                            const isInitialWithAdditional =
                                appActions.isInitialWithAdditional() && !dataContext.user.isInMobileApp();
                            if (isInitialWithAdditional) {
                                $state.go(constants.routerStates.airfxTunnel);
                            } else {
                                $state.go(constants.routerStates.airfxSubCategoryList, { sku: $state.params.sku });
                            }
                        }
                    })
                .state('airfx.process',
                    {
                        url: '/process:sku',
                        template: '<aw-airfx-process></aw-airfx-process>',
                        data: {
                            isHydratable: false
                        }
                    })

                .state('airfx.keypadActivate',
                    {
                        url: '/keypadActivate:sensorId',
                        template: '<aw-airfx-keypad-activation></aw-airfx-keypad-activation>',
                        data: {
                            test: false
                        }
                    })

                .state('airfx.initialize',
                    {
                        url: '/initialize',
                        template: '<aw-airfx-initialize></aw-airfx-initialize>'
                    })
                .state('airfx.complete',
                    {
                        url: '/complete:sku',
                        template: '<aw-airfx-complete></aw-airfx-complete>',
                        data: {
                            theme: 'app-theme-green'
                        }
                    })
                .state('airfx.noSensors',
                    {
                        url: '/noSensors',
                        template: '<aw-airfx-no-sensors></aw-airfx-no-sensors>',
                        data: {
                            navbar: false,
                            theme: 'app-theme-green'
                        }
                    })
                .state('airfx.tunnel',
                    {
                        url: '/tunnel',
                        template: '<aw-airfx-tunnel></aw-airfx-tunnel>',
                        data: {
                            navbar: true,
                            theme: 'app-theme-default-navbar-green'
                        }
                    })
                .state('airfx.frontpointConnected',
                    {
                        url: '/frontpointConnected',
                        template: '<aw-add-sensors-complete></aw-add-sensors-complete>',
                        data: {
                            theme: 'app-theme-green'
                        }
                    })
                .state('airfx.panelConnected',
                    {
                        url: '/panelConnected',
                        template: '<aw-add-sensors-delay-complete></aw-add-sensors-delay-complete>',
                        data: {
                            theme: 'app-theme-green'
                        }
                    })
                .state('airfx.categoryList',
                    {
                        url: '/category',
                        template: '<aw-category-list></aw-category-list>',
                        data: {
                            navbar: {
                                bottom: false
                            },
                            theme: 'app-theme-green-with-gray-navbar'
                        }
                    })
                .state('airfx.subCategoryList',
                    {
                        url: '/subcategory',
                        template: '<aw-subcategory-list></aw-subcategory-list>',
                        data: {
                            navbar: {
                                bottom: false
                            },
                            theme: 'app-theme-gray'
                        }
                    })
                .state('airfx.systemControl',
                    {
                        url: '/systemControl',
                        template: '<aw-system-control-list></aw-system-control-list>',
                        params: {
                            category: ''
                        },
                        data: {
                            theme: 'app-theme-gray'
                        }
                    })
                .state('airfx.categoryEmpty',
                    {
                        url: '/categoryEmpty',
                        template: '<aw-category-empty></aw-category-empty>',
                        params: {
                            category: ''
                        },
                        data: {
                            navbar: {
                                bottom: false
                            },
                            theme: 'app-theme-green'
                        }
                    })
                .state('airfx.individual',
                    {
                        url: '/individual:sku',
                        resolve: {
                            previousState: /*@ngInject*/($state) => {
                                return _.cloneDeep($state);
                            }
                        },
                        controller: (browserHistory, $state, previousState, constants, actionContext) => {
                            $state.go(actionContext.product.getAirfxSensorFlow());
                        }
                    })
                .state('airfx.barcodeScanner',
                    {
                        url: '/scan',
                        template: '<aw-scan-barcode></aw-scan-barcode>',
                        data: {
                            navbar: {
                                bottom: false
                            },
                            showBarcodeScanner: true
                        }
                    })
                .state('airfx.barcodeList',
                    {
                        url: '/barcodeList',
                        template: '<aw-barcode-list></aw-barcode-list>',
                        data: {
                            navbar: {
                                bottom: false
                            }
                        }
                    })
                .state('airfx.barcodeRescan',
                    {
                        url: '/barcodeRescan',
                        template: '<aw-barcode-rescan></aw-barcode-rescan>'
                    })
                .state('airfx.barcodeManual',
                    {
                        url: '/barcodeEntry/:error',
                        template: '<aw-barcode-manual></aw-barcode-manual>'
                    })
                .state('airfx.modswapIntro',
                    {
                        url: '/modswapIntro',
                        template: '<aw-modswap-intro></aw-modswap-intro>'
                    })
                .state('airfx.disconnectPanel',
                    {
                        url: '/disconnectPanel',
                        template: '<aw-disconnect-panel></aw-disconnect-panel>'
                    })
                .state('airfx.modswapInEligible',
                    {
                        url: '/modswapInEligible',
                        template: '<aw-modswap-in-eligible></aw-modswap-in-eligible>',
                        data: {
                            theme: 'app-theme-green'
                        }
                    })
                .state('airfx.radioSwapIntro',
                    {
                        url: '/radioSwapIntro',
                        template: '<aw-radioswap-intro></aw-radioswap-intro>'
                    })

                .state('airfx.userCodes',
                    {
                        url: '/usercodes',
                        params: {
                            swapType: null
                        },
                        template: '<aw-modswap-user-codes></aw-modswap-user-codes>',
                        data: {
                            theme: 'app-theme-gray-navbar-white'
                        }
                    })
                .state('airfx.duressCodes',
                    {
                        url: '/duressCodes',
                        template: '<aw-duress-codes></aw-duress-codes>',
                        data: {
                            theme: 'app-theme-gray-navbar-white'
                        }
                    })
                .state('airfx.testusercodes',
                    {
                        url: '/testusercodes',
                        template: '<aw-radioswap-test-user-codes></aw-radioswap-test-user-codes>',
                        params: {
                            swapType: null
                        },
                        data: {
                            prevState: 'airfx.userCodes',
                            nextState: 'airfx.modswapSuccess'
                        }

                    }
                )
                .state('airfx.training',
                    {
                        url: '/airfxtraining',
                        template: '<aw-training-videos></aw-training-videos>',
                        data: {
                            theme: 'app-theme-gray-navbar-white',
                            prevState: 'airfx.userCodes',
                            nextState: 'airfx.armAway'
                        }
                    })
                .state('airfx.armAway',
                    {
                        url: '/arm-away',
                        template: '<aw-air-fx-arm-away></aw-air-fx-arm-away>',
                        data: {
                            next: /*@ngInject*/($state, $ngRedux, actionContext) => {
                                actionContext.device.updateArmDisarmPanelStatus('Panel armed in away mode.');
                                $state.go('airfx.disarmSystem', { sourceLocation: $ngRedux.getState().router.currentState.name });
                            },
                            prev: /*@ngInject*/ ($state) => {
                                $state.go('airfx.training');
                            },
                            sectionType: constants.sectionTypes.training,
                            persist: true,
                            theme: 'app-theme-default-navbar-gray'
                        },
                        params: { sourceLocation: null }
                    })
                .state('airfx.disarmSystem',
                    {
                        url: '/disarm-system',
                        template: '<aw-air-fx-disarm-system></aw-air-fx-disarm-system>',
                        data: {
                            next: /*@ngInject*/($state, $stateParams, constants, $ngRedux, actionContext) => {
                                if ($stateParams.sourceLocation === constants.airFxArmDisarmNavigationParams.airFxTrainingArmAway ||
                                        $stateParams.sourceLocation === constants.airFxArmDisarmNavigationParams.airFxTrainingArmStayBack ||
                                        $ngRedux.getState().router.prevParams.sourceLocation === constants.airFxArmDisarmNavigationParams.airFxTrainingArmAway ||
                                        $ngRedux.getState().router.prevParams.sourceLocation === constants.airFxArmDisarmNavigationParams.airFxTrainingArmStayBack) {
                                    actionContext.device.updateArmDisarmPanelStatus('Panel disarmed.');
                                    $state.go('airfx.armStay', { sourceLocation: $ngRedux.getState().router.currentState.name });
                                } else {
                                    actionContext.device.updateArmDisarmPanelStatus('Panel disarmed.');
                                    actionContext.device.completeArmDisarmTesting();
                                    $state.go('airfx.modswapSuccess', { sourceLocation: $ngRedux.getState().router.currentState.name });
                                }
                            },
                            sectionType: constants.sectionTypes.training,
                            persist: true,
                            theme: 'app-theme-default-navbar-gray'
                        },
                        params: { sourceLocation: null }
                    })
                .state('airfx.armStay',
                    {
                        url: '/arm-stay',
                        template: '<aw-air-fx-arm-stay></aw-air-fx-arm-stay>',
                        data: {
                            next: /*@ngInject*/($state, $ngRedux, actionContext) => {
                                actionContext.device.updateArmDisarmPanelStatus('Panel armed in stay mode.');
                                $state.go('airfx.disarmSystem', { sourceLocation: $ngRedux.getState().router.currentState.name + '-continue' });
                            },
                            prev: /*@ngInject*/ ($state, $ngRedux) => {
                                $state.go('airfx.disarmSystem', { sourceLocation: $ngRedux.getState().router.currentState.name + '-back' });
                            },
                            sectionType: constants.sectionTypes.training,
                            persist: true,
                            theme: 'app-theme-default-navbar-gray'
                        },
                        params: { sourceLocation: null }
                    })
                .state('airfx.modswapSensorTransfer',
                    {
                        url: '/modswapSensorTransfer',
                        template: '<aw-modswap-sensor-transfer></aw-modswap-sensor-transfer>'
                    })
                .state('airfx.radioswapSensorTransfer',
                    {
                        params: {
                            swapType: null
                        },
                        url: '/radioswapSensorTransfer',
                        template: '<aw-modswap-sensor-transfer></aw-modswap-sensor-transfer>'
                    })
                .state('airfx.modswapSuccess',
                    {
                        url: '/modswapSuccess',
                        template: '<aw-modswap-success></aw-modswap-success>',
                        params: {
                            swapType: null
                        },
                        data: {
                            theme: 'app-theme-green',
                            prevState: '#',
                            nextState: 'airfx.categoryList'
                        }
                    })
                .state('airfx.redirectToAirfx',
                    {
                        url: '/redirectToAirfx:category',
                        controller: ($state, deviceActions, actionContext) => {
                            const category = $state.params.category;
                            if (deviceActions.getDevicesFromCategory(category, true).length > 0 || category === 'system control') {
                                if (category === 'sensors') {
                                    $state.go('airfx.subCategoryList', { category: category });
                                }
                                if (category === 'system control') {
                                    $state.go('airfx.systemControl', { category: category });
                                }
                            } else {
                                $state.go('airfx.categoryEmpty', { category: category });
                            }
                        }
                    })
                .state('airfx.keypadConnected', {
                    url: '/keypadConnected:additionalOrderKeypadsCount',
                    template: '<aw-keypad-connected></aw-keypad-connected>',
                    data: {
                        theme: 'app-theme-green',
                        prevState: '#'
                    }
                })
                .state('airfx.addKeypad', {
                    url: '/addKeypad:deviceName',
                    template: '<aw-add-keypad></aw-add-keypad>',
                    data: {
                        prevState: '#'
                    }
                })
                .state('airfx.orderArrivalCheck', {
                    url: '/orderArrivalCheck',
                    template: '<aw-order-arrival-check></aw-order-arrival-check>',
                    data: {
                        theme: 'app-theme-green'
                    }
                });
        })
    .component('awAirfxComplete', airFxCompleteComponent)
    .component('awAirfxHome', airFxHomeComponent)
    .component('awAirfxInitialize', airFxInitializeComponent)
    .component('awAirfxInitializeItem', airFxInitializeItemComponent)
    .component('awAirfxList', airFxListComponent)
    .component('awAirfxListItem', airFxListItemComponent)
    .component('awAirfxProcess', airFxProcessComponent)
    .component('awAirfxNoSensors', airFxNoSensorsComponent)
    .component('awAirfxTunnel', airFxTunnel)
    .component('awAddSensorsComplete', addSensorsCompleteComponent)
    .component('awAddSensorsDelayComplete', addSensorsDelayCompleteComponent)
    .component('awCategoryList', categoryListComponent)
    .component('awSubcategoryList', subCategoryListComponent)
    .component('awSubCategoryListItem', subCategoryListItemComponent)
    .component('awSystemControlList', systemControlListComponent)
    .component('awSystemControlListItem', systemControlListItemComponent)
    .component('awCategoryEmpty', categoryEmptyComponent)
    .component('awAirfxKeypadActivation', keypadComponent)
    .component('awKeypadConnected', keypadConnectedComponent)
    .component('awAddKeypad', addKeypadComponent)
    .component('awOrderArrivalCheck', orderArrivalCheck);
