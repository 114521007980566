class PanelDataService {
    /*@ngInject*/
    constructor(Restangular, fpDataCache) {
        this.Restangular = Restangular;
        this.fpDataCache = fpDataCache;
    }

    getPanelLastMessageDate() {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('GetLastMessageDate')
            .withHttpConfig({ cache: false })
            .get();
    }

    getVersion() {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('version')
            .withHttpConfig({ cache: false })
            .get();
    }

    getRequestRoundTrip() {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('RequestRoundTrip')
            .withHttpConfig({ cache: false })
            .get();
    }

    getLastCsEventDate() {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('GetLastCsEventDate')
            .withHttpConfig({ cache: false })
            .get();
    }

    getPanelSignalStrength() {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('PanelSignalStrength')
            .get()
            .then((response) => {
                return response.plain();
            });
    }

    requestUploadOfSystemSettings(devices) {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('settings')
            .withHttpConfig({ clearCache: false })
            .customPOST(devices);
    }

    getSystemSettings() {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('settings')
            .withHttpConfig({ cache: false })
            .get();
    }

    enableInteractiveMode() {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('interactive')
            .withHttpConfig({ cache: false })
            .post();
    }

    disableInteractiveMode() {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('interactive')
            .withHttpConfig({ clearCache: false })
            .remove();
    }

    isInteractiveModeEnabled() {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('interactive')
            .get();
    }

    addSensor(sensor) {
        return this.Restangular
            .one('system')
            .one('panel')
            .all('sensors')
            .post(sensor);
    }

    deleteSensor(sensor) {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('sensors', sensor.SensorID)
            .remove();
    }

    requestUpdatedDevices() {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('request-updated-devices')
            .withHttpConfig({ cache: false })
            .get();
    }

    getLockCount() {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('lock-count')
            .get();
    }

    CheckKeypadStatus(deviceId) {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('keypads', deviceId)
            .withHttpConfig({ cache: false })
            .get();
    }

    getLightCount() {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('light-count')
            .get();
    }

    getExtendedFirmwareVersion() {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('extended-firmware-version')
            .get();
    }

    checkPendingCommands() {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('check-pending-commands')
            .withHttpConfig({ cache: false })
            .get();
    }

    downloadSettingsToPanel(settings) {
        return this.Restangular
            .one('system')
            .one('panel')
            .all('DownloadSettingsToPanel')
            .post(settings);
    }
}

export default PanelDataService;