import * as _ from 'lodash';

import {
    KIT_CUSTOMER_CONTACT_SUBMISSION_BUSY,
    KIT_CUSTOMER_CONTACT_SUBMISSION_SUCCESSFUL,
    KIT_CUSTOMER_CONTACT_SUBMISSION_FAILED,
    KIT_CUSTOMER_ADDRESS_SUBMISSION_BUSY,
    KIT_CUSTOMER_ADDRESS_SUBMISSION_SUCCESSFUL,
    KIT_CUSTOMER_ADDRESS_SUBMISSION_FAILED,
    KIT_CUSTOMER_FETCH_BUSY,
    KIT_CUSTOMER_FETCH_SUCCESSFUL,
    KIT_CUSTOMER_FETCH_FAILED
} from '../../action/kitCustomer.actions';

import {
    FETCH_DOCUSIGN_SUCCESS,
    FETCH_DOCUSIGN_FAILURE,
    FETCH_DOCUSIGN_BUSY
} from '../../action/account.actions';

import { CS_ACCOUNT_CREATION_SUCCESS, ADC_ACCOUNT_CREATION_SUCCESS } from '../../action/account.actions';

const initialState = {
    contact: null,
    address: null,
    isUpdating: false,
    hasError: false,
    quoteId: null,
    contractId: null,
    accountId: null,
    docusignUrl: null,
    leadId: null,
    isLoadingContract: false,
    products: []
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case KIT_CUSTOMER_CONTACT_SUBMISSION_SUCCESSFUL:
            return _.mergeCustom({},
                state,
                {
                    leadId: action.payload.LeadID,
                    contact: {
                        FirstName: action.payload.FirstName,
                        LastName: action.payload.LastName,
                        Email: action.payload.Email,
                        Phone: action.payload.Phone
                    },
                    quoteId: action.payload.QuoteID,
                    kitProductSerialNumber: action.payload.KitProductSerialNumber,
                    isUpdating: false,
                    hasError: false
                });
        case KIT_CUSTOMER_CONTACT_SUBMISSION_FAILED:
            return _.mergeCustom({},
                state,
                {
                    isUpdating: false,
                    hasError: true
                });
        case KIT_CUSTOMER_CONTACT_SUBMISSION_BUSY:
            return _.mergeCustom({},
                state,
                {
                    isUpdating: true
                });
        case KIT_CUSTOMER_ADDRESS_SUBMISSION_SUCCESSFUL:
            return _.mergeCustom({},
                state,
                {
                    address: action.payload.address,
                    accountId: action.payload.accountId,
                    isUpdating: false,
                    hasError: false
                });
        case KIT_CUSTOMER_ADDRESS_SUBMISSION_FAILED:
            return _.mergeCustom({},
                state,
                {
                    isUpdating: false,
                    hasError: true
                });
        case KIT_CUSTOMER_ADDRESS_SUBMISSION_BUSY:
            return _.mergeCustom({},
                state,
                {
                    isUpdating: true
                });
        case FETCH_DOCUSIGN_SUCCESS:
            return _.mergeCustom({},
                state,
                {
                    docusignUrl: action.payload.url,
                    contractId: action.payload.contractId,
                    isLoadingContract: false,
                    hasError: false
                });
        case FETCH_DOCUSIGN_FAILURE:
            return _.mergeCustom({},
                state,
                {
                    isLoadingContract: false,
                    hasError: true
                });
        case FETCH_DOCUSIGN_BUSY:
            return _.mergeCustom({},
                state,
                {
                    isLoadingContract: true
                });
        case CS_ACCOUNT_CREATION_SUCCESS:
            return _.mergeCustom({},
                state,
                {
                    adcAccountId: action.payload.adcAccountId
                });
        case ADC_ACCOUNT_CREATION_SUCCESS:
            return _.mergeCustom({},
                state,
                {
                    centralStationId: action.payload.CentralStationId
                });
        case KIT_CUSTOMER_FETCH_SUCCESSFUL:
            return {
                ...state,
                ...action.payload,
                isUpdating: false,
                hasError: false
            };
        case KIT_CUSTOMER_FETCH_FAILED:
            return _.mergeCustom({},
                state,
                {
                    isUpdating: false,
                    hasError: true
                });
        case KIT_CUSTOMER_FETCH_BUSY:
            return _.mergeCustom({},
                state,
                {
                    isUpdating: true
                });
        default:
            return state;
    }
};