import template from './contractDeclined.html';

class ContractDeclinedController {
    /*@ngInject*/
    constructor() {

    }

    $onInit() {
    }
}

export default {
    template: template,
    bindings: {},
    controller: ContractDeclinedController
};