import * as _ from 'lodash';
import ngRedux from 'ng-redux';
import { Unsubscribe } from 'redux';
import DataContext from "@app/common/data/dataContext.service";

export class WifiNetworksController {
    public customerPortalUrl: string;
    public passwordRequired: boolean;
    public networkName: string;
    public encryptionType: string;
    public showActivateModal: boolean;
    public isConnecting: boolean;
    public hdnNetwork: string;
    public activateNetworkPassword: string;
    public showCannotConnectModal: boolean;
    public showSkipModal: boolean;
    public isConnected: boolean;
    public isFetching: boolean;
    public hasHub: boolean;
    public hasTouchscreen: boolean;
    public swapType: string;
    public networkPasswordForm: ng.IFormController;
    public typePassword: boolean;
    public isEmailLive: boolean;
    private unsubscribe: Unsubscribe;
    public disableHdnConnect: boolean;
    public isModSwap: boolean;
    public modalPanelType: string;
    public hasConnectedSafety: boolean;
    public connectionAttempts: number;

    constructor(
        private $document: ng.IDocumentService,
        private $ngRedux: ngRedux.INgRedux,
        private $state: ng.ui.IStateService,
        private actionContext: any,
        private dataContext: DataContext,
        public constants: any,
        private navigation: any,
        private SharedState: any,
        private $interval: any
    ) {
        'ngInject';
        this.customerPortalUrl = constants.urls.customerPortal;
        this.swapType = this.$ngRedux.getState().system.swap.swapType;
        this.typePassword = true;
        this.setIsEmailLive();
        this.disableHdnConnect = true;
        this.hasConnectedSafety = false;
        this.swapType = this.$ngRedux.getState().system.swap.swapType;
        this.connectionAttempts = 0;
    }
    private async setIsEmailLive() {
        this.isEmailLive = await this.dataContext.email.isEmailLive();
    }

    public $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this))(this);

        // debug on space event skip
        if (!_.isNil(this.constants.debug) && this.constants.debug) {
            this.$document.bind('keypress', this.onKeypress.bind(this));
        }

        this.isModSwap = this.swapType === this.constants.swapType.mod;
        this.setPanelType();
        this.fetching();
    }

    public $onDestroy() {
        if (!_.isNil(this.constants.debug) && this.constants.debug) {
            this.$document.unbind('keypress');
        }

        this.actionContext.wifi.clearError();

        this.unsubscribe();
    }

    public togglePassword() {
        this.typePassword = !this.typePassword;
    }

    public mapState(state: any) {
        const hasHub = state.system.panel.type === this.constants.panelVersions.climaxHub;
        const hasTouchscreen = _.some(state.account.order.itemsByID,
            (i: any) => _.some(this.constants.touchscreens.skus, (t: string) => i.ProductSku === t));

        return {
            hasHub,
            hasTouchscreen,
            isFetching: state.system.wifi.isFetching,
            isConnecting: state.system.wifi.isConnecting,
            isConnected: state.system.wifi.isConnected,
            networks: state.system.wifi.networks,
            showCannotConnectModal: state.system.wifi.showCannotConnectModal,
            panelVersion: state.system.panel.type
        };
    }

    public configureModal(network: any) {
        if (network.Connected === true) {
            return;
        }

        this.passwordRequired = network.PasswordRequired;
        this.networkName = network.WiFiNetworkName;
        this.encryptionType = network.EncryptionType;

        if (network.PasswordRequired) {
            this.SharedState.turnOn('networkCredentials');
        } else {
            const networkCredentials = {
                SSID: network.WiFiNetworkName,
                Password: '',
                EncryptionType: network.EncryptionType
            };

            this.isConnecting = true;
            this.connecting(networkCredentials);
        }
    }

    public openHdnNetwork() {
        this.networkName = this.hdnNetwork;
        this.SharedState.turnOn('networkCredentials');
    }

    public disableHdnNetwork() {
        if (this.hdnNetwork.length > 0) {
            this.disableHdnConnect = false;
        } else {
            this.disableHdnConnect = true;
        }
    }

    public onPositiveActivateClick() {
        if (!this.networkPasswordForm.$invalid) {
            this.isConnecting = true;

            const networkCredentials = {
                SSID: this.networkName,
                Password: this.activateNetworkPassword,
                EncryptionType: this.encryptionType
            };

            this.connecting(networkCredentials);
        }
    }

    public onPositiveSkipClick() {
        if (this.isEmailLive) {
            this.dataContext.email.sendEmail("WifiNetwork");
        }
        this.navigation.goNext();
    }

    public onPositiveIQSkip() {
        this.navigation.goNext();
    }

    public onSkip() {
        if (this.isConnected) {
            this.navigation.goNext();
        } else {
            this.SharedState.turnOn('skipWifi');
        }
    }

    private onKeypress(event: any) {
        if (event.ctrlKey && event.code === 'Space') {
            this.$state.go('wifi.connected');
        }
    }

    private fetching() {
        this.actionContext.wifi.fetching(true)
            .then((result: number) => {
                if (result === this.constants.polling.wifi.maxIntervalGettingCount) {
                    this.isFetching = false;
                }
            });
    }
    
    private connecting(networkCredentials: any) {
        if (!networkCredentials) {
            networkCredentials = {
                SSID: this.networkName,
                Password: this.activateNetworkPassword,
                EncryptionType: this.encryptionType
            };
        }

        this.actionContext.wifi.connecting(networkCredentials)
            .then(() => {
                let maxIterations = this.constants.dualBand.maxIterations;
                let iterationTime = this.constants.dualBand.iterationTime;

                const wifiVerificationInterval = this.$interval(() => {
                    this.dataContext.wifi.getDualPath().then((dualPathResult: any) => {
                        if (dualPathResult.EthernetWorking) {
                            this.actionContext.wifi.wifiConnected().then((result: any) => {
                                if (result.UploadedValue === this.networkName && this.hasConnectedSafety === false) {
                                    this.hasConnectedSafety = true;
                                    this.$interval.cancel(wifiVerificationInterval);
                                    this.$state.go('wifi.connected');
                                }
                            }).catch((error: any) => {
                                this.isConnecting = false;
                                this.SharedState.turnOn('errorConnectingModal');
                            });
                        }
                    }).catch((error: any) => {
                        this.isConnecting = false;
                        this.SharedState.turnOn('errorConnectingModal');
                    });
                },
                    iterationTime,
                    maxIterations);

                wifiVerificationInterval.then(() => {
                    this.connectionAttempts++;
                    console.log('max interval time hit');
                    this.showActivateModal = false;
                    this.isConnecting = false;
                    this.SharedState.turnOn('cantConnectModal');
                    this.actionContext.wifi._onConnectingFailure('Could not connect to network. Max attempts: [' +
                        this.constants.dualBand.maxIterations +
                        ']');
                });
            })
            .catch((error: any) => {
                this.isConnecting = false;
                this.SharedState.turnOn('errorConnectingModal');
            });;
    }

    private setPanelType() {
        if (this.isModSwap) {
            this.modalPanelType = this.$ngRedux.getState().account.airfxFlow.targetSku;
        } else {
            this.modalPanelType = this.$ngRedux.getState().system.panel.type;
        }
    }
}

export const wifiNetworksComponent = {
    template: require('./networks.html'),
    bindings: {},
    controller: WifiNetworksController
};
