import template from './home.html';

class SensorOverviewController {
    /*@ngInject*/
    constructor(dataContext, actionContext,$state) {
        this.dataContext = dataContext;
        this.actionContext = actionContext;
        this.$state = $state;
        this.sku = this.$state.params.sku;
    }

    isInitialOrder() {
        return this.actionContext.order.isInitialOrder();
    }
}

export default {
    template: template,
    bindings: {},
    controller: SensorOverviewController
};