let allowTouchmoveDefaultDirective = (allowTouchmoveDefault) => {
    'ngInject';
    return {
        restrict: 'A',
        link: ($scope, $element, $attr, ctrl) => {
            allowTouchmoveDefault($element);
        }
    };
};

export default allowTouchmoveDefaultDirective;