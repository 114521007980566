import * as angular from 'angular';
import ngRedux from 'ng-redux';

import modalModule from '../modals';
import scanner from './scanner.component';
import scannerService from './scanner.service';
import barcodeScannerOverlay from './barcodeScannerOverlay.component';

export default angular
    .module('fp:activationswizard:common:ui:barcode', [
        ngRedux,
        modalModule.name
    ])
    .component('awScanner', scanner)
    .component('awBarcodeScannerOverlay', barcodeScannerOverlay)
    .service('scannerService', scannerService);