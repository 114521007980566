import * as _ from 'lodash';

import template from './test.html';

class SensorItemTestController {
    /*@ngInject*/
    constructor($ngRedux, actionContext) {
        this.$ngRedux = $ngRedux;
        this.actionContext = actionContext;
    }
    
    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState)(this);
    }

    getName(sensor) {
        if (sensor) {
            return this.actionContext.device.getName(this.sensor);
        }
        return "";
    }

    $onDestroy() {
        this.unsubscribe();
    }

    _mapState(state) {
        return {
            eligibleSensorNames: state.application.eligibleSensorNames,
            isPolling: state.system.devices.isPolling,
            panelType: state.system.panel.type,
            sensor: state.system.devices.sensorsByID[state.system.devices.selectedSensorID],
            isInitialOrder: state.account.user.isInitialOrder
        };
    }
}

export default {
    template: template,
    bindings: { },
    controller: SensorItemTestController
};