import template from './placement.html';

class PlacementComponent {
    /*@ngInject*/
    constructor() {
        
    }
}

export default {
    template: template,
    bindings: {},
    controller: PlacementComponent
};