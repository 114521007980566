import * as _ from 'lodash';

const NEXT_ACTION_NAME = 'next';
const PREV_ACTION_NAME = 'prev';

class NavigationService {
    /*@ngInject*/
    constructor ($injector, $state) {
        this.$injector = $injector;
        this.$state = $state;
    }

    isNextDefined() {
        return this._isDefined(NEXT_ACTION_NAME);
    }

    isPrevDefined() {
        return this._isDefined(PREV_ACTION_NAME);
    }

    goNext() {
        this._go(NEXT_ACTION_NAME);
    }

    goPrev() {
        this._go(PREV_ACTION_NAME);
    }

    _isDefined(name) {
        return _getFunctionByName(name) !== _.noop;
    }

    _go(name) {
        this.$injector.invoke(this._getFunctionByName(name), null, this._getResolves());
    }

    _getFunctionByName(name) {
        const current = this.$state.$current;
        const data = current.data;
        const fn = data ? data[name] : null;

        return fn ? fn : _.noop;
    }

    _getResolves() {
        return this.$state.$current.locals.globals;
    }
}

export default NavigationService;