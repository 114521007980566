import template from './sensorChimeSound.html';

class SensorChimeSoundController {
    /*@ngInject*/
    constructor($ngRedux, $q, actionContext, constants) {
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.actionContext = actionContext;
        this.constants = constants;
        this.chimeSounds = this.constants.chimeSounds.sounds;
        this.chimeSoundSelection = null;

    }

    $onInit() {
        if (this.handleChimeConnect) {
            this.handleChimeConnect({ ctrl: this });
        }

        // Non readonly forms must bind a handleUpdate() function.
        if (!this.isReadonly && !this.handleChimeUpdate) {
            throw 'handleUpdate cannot be null or undefined';
        }
    }
    

    /**
     * Returns true if the submit() function can be called.
     * 
     * @returns {boolean} True if the submit() function can be called; otherwise, false. 
     */
    canUpdate() {
        var form = this.sensorChimeForm;

        // 0. The form has NOT been initalized.
        if (!form) {
            return false;
        }
        
        // 1. The use has touched the form and it is valid.
        if (form.$dirty && form.$valid) {
            return true;
        }

        //2. The user has made a selection of sounds from teh dropdown
        if (this.chimeSoundSelection !== null) {
            return true;
        }

        // 2. The user has touched the form and it is NOT valid.
        return false;
    }

    /**
     * Invokes handleUpdate() if canUpdate() returns true. 
     * 
     * @returns {promise} A promise that will resolve with the result of handleSumbit() or will reject if canUpdate() 
     * returns false.
     */
    submit() {
        if (this.canUpdate()) {

            //For this setting set, we want to enable sensor vocalization (37010004) only if a chime sound has not been selected.
            //If no chime sound is selected we ensure that sensor vocalization is on
            //The filter at the end removes the non-applicable setting to turn voice on/off as it will be set to null
            var chimeSettings = [
                { DeviceId: this.sensor.SensorID.toString(), SystemSettingId: 37010005, SystemSettingValue: this.chimeSoundSelection.id },
                (this.chimeSoundSelection.id === 0) ? { DeviceId: this.sensor.SensorID.toString(), SystemSettingId: 37010004, SystemSettingValue: 1 } : null,
                (this.chimeSoundSelection.id !== 0) ? { DeviceId: this.sensor.SensorID.toString(), SystemSettingId: 37010004, SystemSettingValue: 0 } : null
            ].filter(Boolean);

            this.sensorChimeForm.$setValidity('valid', true);
            return this.$q.resolve(this.handleChimeUpdate({ chimeSettings: chimeSettings }));
        }

        this.sensorChimeForm.$setDirty();
        this.sensorChimeForm.$setValidity('valid', false);
        return this.$q.reject('invalid');
    }
}

export default {
    template: template,
    bindings: {
        eligibleSensorNames: '<',
        isReadonly: '<',
        panelType: '<',
        sensor: '<',
        handleChimeConnect: '&',
        handleChimeUpdate: '&',
        chimeSettings: '&'
    },
    controller: SensorChimeSoundController
};