import template from "./intro.html";
import * as _ from 'lodash';

class SwapIntroController {
    /*@ngInject*/
    constructor($ngRedux, $state, constants, $log, SharedState, segment, dataContext, actionContext, $interval) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.showModal = false;
        this.SharedState = SharedState;
        this.isLoading = false;
        this.showhelpModal = false;
        this.segment = segment;
        this.$log = $log;
        this.dataContext = dataContext;
        this.actionContext = actionContext;
        this.$interval = $interval;
        this.showZWaveModal = false;
        this.zWaveDeviceSkus = _.flatMap(this.constants.zWaveProducts);
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        
    }

    hasZWaveDevices() {
        this.hasZWave = _.some(this.sensors, sensor => this.zWaveDeviceSkus.includes(sensor.ProductSku));
        if (this.hasZWave) {
            this.SharedState.turnOn('zWaveModal');
        } else {
            this.$state.go('airfx.disconnectPanel');
        }
    }

    onContinue() {
        if (this.targetSku === this.constants.panelVersions.iqPanel) {
            this.hasZWaveDevices();
        } else {
            this.$state.go('airfx.disconnectPanel');
        }
    }

    _mapState(state) {
        let map = {};
        map.targetSku = state.account.airfxFlow.targetSku;
        map.currentSku = state.account.airfxFlow.sku;
        map.sensors = state.system.devices.sensorsByID;
        return _.cloneDeep(map);
    }
}

export default {
    template: template,
    bindings: {},
    controller: SwapIntroController
};