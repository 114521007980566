import * as _ from 'lodash';

export const CONTRACT_OFFER_SKIPPED = 'CONTRACT_OFFER_SKIPPED';
export const CONTRACT_OFFER_ACCEPTED = 'CONTRACT_OFFER_ACCEPTED';

class TosOptionsActions {
    /*@ngInject*/
    constructor($log, $ngRedux, $state, constants) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
    }

    tosSkipped() {
        this._dispatch({
            type: CONTRACT_OFFER_SKIPPED,
            metadata: {
                sectionType: this.constants.sectionTypes.tos,
                description: 'Terms of Service declined and skipped',
                persist: true
            }
        });
    }

    tosAccepted() {
        this._dispatch({
            type: CONTRACT_OFFER_ACCEPTED,
            metadata: {
                sectionType: this.constants.sectionTypes.tos,
                description: 'Terms of Service accepted',
                persist: true
            }
        });
    }

    _dispatch(obj) {
        this.$ngRedux.dispatch(obj);
    }
}

export default TosOptionsActions;