import template from './assembly.html';

class AssemblyComponent {
    /*@ngInject*/
    constructor() {
        
    }
}

export default {
    template: template,
    bindings: {},
    controller: AssemblyComponent
};