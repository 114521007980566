import * as _ from 'lodash';

/**
 * @name sortBy
 *
 * @description
 * Creates an array of elements, sorted by the result of running each element
 * through each predicate.
 * 
 * This is filter is a wrapper around lodash.orderBy.
 *
 * @param {Array} array The array to sort.
 * @param {function|Array.<function>} predicates The predicates to sort by.
 * @param {string|Array.<string>} orders The sort orders of the predicates.
 * @returns {function(*)} A sorted copy of the array.
 */
let sortByFilter = () => {
    'ngInject';
    return (array, predicates, orders) => {
        return _.orderBy(array, predicates, orders);
    };
};

export default sortByFilter;