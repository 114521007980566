import './list.scss';
import * as _ from 'lodash';
import template from './list.html';

class BarcodeListController {
    /*@ngInject*/
    constructor($state, $ngRedux, actionContext, $timeout, deviceActions, mobileDetectService, $interval, constants, barcodeService, dataContext) {
        this.$state = $state;
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
        this.$interval = $interval;
        this.actionContext = actionContext;
        this.mobileDetectService = mobileDetectService;
        this.constants = constants;
        this.barcodeService = barcodeService;
        this.dataContext = dataContext;
        this.isKeypad = this.actionContext.airfxFlow.isKeypad();
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState)(this);
        this.cameraUrl = this.barcodeService.getCameraUrl();
        this.showProgress = false;
        this.resetDlCodeData();
    }

    $onDestroy() {
        this.unsubscribe();
    }

    onBarcodeReceived() {
        const dlCode = _.cloneDeep(this.dlCode);
        this.resetDlCodeData();
        this.barcodeService.activateSensorWithDlCode(dlCode, 1);
    }

    scan() {
        if (this.dataContext.user.isBrowser()) {
            this.$state.go('airfx.barcodeScanner');
        } else {
            this._watchForDlCodeAdcApp();
        }
    }

    cancel() {
        this.$state.go('airfx.categoryList');
    }

    resetDlCodeData() {
        this.dlCode = '';
        this.initialDlCode = '';
    }

    _watchForDlCodeAdcApp() {
        this.$interval(() => {
            const code = document.getElementById('dlCode').value;
            if (!_.isEqual(code, this.initialDlCode)) {
                this.dlCode = code;
                this.initialDlCode = _.cloneDeep(code);
                this.$interval.cancel(this.interval);
                this.onBarcodeReceived();
            }
        }, this.constants.barcodePollingInterval);
    }

    _mapState(state) {
        return {
            sku: state.account.airfxFlow.sku,
            category: state.account.airfxFlow.category
        };
    }
}

export default {
    template: template,
    bindings: {
    },
    controller: BarcodeListController
}