import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';
import 'ui-router.grant';

import './boxcheck.scss';

import commonModule from '../common';
import menuModule from '../menu';

import boxCheckCompleteComponent from './complete.component';
import boxCheckHomeComponent from './home.component';
import boxCheckItemsComponent from './items.component';
import boxCheckMissingComponent from './missing.component';

export default angular
    .module('fp:activationswizard:boxcheck', [
        'mobile-angular-ui',
        'ui.router',
        'ui.router.grant',
        ngRedux,
        commonModule.name,
        menuModule.name
    ])
    .config( /*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            .state('boxcheck', {
                abstract: true,
                parent: 'secure',
                url: '/boxcheck',
                template: '<ui-view></ui-view>',
                data: {
                    next: /*@ngInject*/ ($state, appActions) => {
                        appActions.completeModule(constants.sectionTypes.boxcheck);
                        appActions.completeStep('boxcheck');
                        $state.go('menu.home');
                    },
                    prev: /*@ngInject*/ ($state) => {
                        $state.go('menu.home');
                    },
                    sectionType: constants.sectionTypes.boxcheck,
                    theme: 'app-theme-default-navbar-gray'
                }
            })
            .state('boxcheck.home', {
                url: '',
                template: '<aw-box-check-home></aw-box-check-home>',
                onEnter: /*@ngInject*/ (appActions) => {
                    appActions.startModule(constants.sectionTypes.boxcheck);
                }
            })
            .state('boxcheck.items', {
                url: '/items',
                template: '<aw-box-check-items></aw-box-check-items>'
            })
            .state('boxcheck.complete', {
                url: '/complete',
                template: '<aw-box-check-complete></aw-box-check-complete>',
                data: {
                    theme: 'app-theme-green'
                }
            })
            .state('boxcheck.missing', {
                url: '/missing',
                template: '<aw-box-check-missing></aw-box-check-missing>'
            });
    })
    .component('awBoxCheckHome', boxCheckHomeComponent)
    .component('awBoxCheckItems', boxCheckItemsComponent)
    .component('awBoxCheckComplete', boxCheckCompleteComponent)
    .component('awBoxCheckMissing', boxCheckMissingComponent);
