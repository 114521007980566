import * as _ from 'lodash';
import moment from 'moment';

class ZwaveService {
    /*@ngInject*/
    constructor($log, $timeout, $q, $state, Restangular, constants, dataContext, actionContext, fpStore) {
        // services
        this.$log = $log;
        this.$timeout = $timeout;
        this.$q = $q;
        this.$state = $state;
        this.restangular = Restangular;
        this.constants = constants;
        this.dataContext = dataContext;
        this.actionContext = actionContext;
        this.fpStore = fpStore;
    }

    /**
     * Get all sensors attached to the curent account.
     * @param {boolean=} isInitialOrder
     * @param {array=} products
     * 
     * @returns {} 
     */
    activateZwaveDevice(isInitialOrder, products) {
        let updateProductsPromises = [];

        let locks = _.filter(products, product => {
            return _.includes(this.constants.zWaveProducts.locks, product.ProductSku) && !_.get(product, 'isActivated', false);
        });

        let lights = _.filter(products, product => {
            return _.includes(this.constants.zWaveProducts.lights, product.ProductSku) && !_.get(product, 'isActivated', false);

        });

        let updateProducts = this.$q.resolve();
        if (isInitialOrder) {
            _.forEach(locks, (lock) => {
                updateProductsPromises.push(this.actionContext.order.setOrderProductActivated(lock));
            });
            _.forEach(lights, (light) => {
                updateProductsPromises.push(this.actionContext.order.setOrderProductActivated(light));
            });
        } else {
            updateProducts = this.$q.all([this.dataContext.panel.getLockCount(), this.dataContext.panel.getLightCount(), this.dataContext.account.order.getAllOrderProducts()])
                .then(response => {
                    //Locks
                    const totalAccountLocks = response[2].filter(sensor => {
                        return this.actionContext.device.isSmartLock(sensor);
                    }).length;

                    const totalUnactivatedLocks = locks.length;

                    if (!_.isNil(response[0])) {
                        const connectedLockCount = response[0];

                        let lockCount = 0;
                        if (totalAccountLocks == totalUnactivatedLocks) {
                            //No locks have been activated 
                            lockCount = connectedLockCount;
                        } else {
                            lockCount = connectedLockCount - (totalAccountLocks - totalUnactivatedLocks);
                        }

                        for (let i = 0; i < lockCount; i++) {
                            updateProductsPromises.push(this.actionContext.order.setOrderProductActivated(locks[i]));
                        } 
                    }

                    //Lights
                    const totalAccountLights = response[2].filter(sensor => {
                        return this.actionContext.device.isLight(sensor);
                    }).length;

                    const totalUnactivatedLights = lights.length;

                    if (!_.isNil(response[1])) {
                        const connectedLightCount = response[1];

                        let lightCount = 0;
                        if (totalAccountLights == totalUnactivatedLights) {
                            //No lights have been activated 
                            lightCount = connectedLightCount;
                        } else {
                            lightCount = connectedLightCount - (totalAccountLights - totalUnactivatedLights);
                        }

                        for (let i = 0; i < lightCount; i++) {
                            updateProductsPromises.push(this.actionContext.order.setOrderProductActivated(lights[i]));
                        } 
                    }
                })
                .catch((error) => {
                    this.$log.error('Error getting the Smart Lock/Light count from ADC.', error);
                });
        }
        
        return updateProducts.then(() => {
            this.$q.all(updateProductsPromises).then(() => {
                updateProductsPromises = [];
            });
        }).finally(() => {
            return this.$q.resolve();
        });
    }
}

export default ZwaveService;