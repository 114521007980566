class AccountService {
    /*@ngInject*/
    constructor(Restangular) {
        this.restangular = Restangular;
    }

    getAccountInformation(accountId) {
        return this.restangular
            .one('account')
            .one(`getAccountInformation?accountId=${accountId ? accountId : 0}`)
            .get();
    }

    getAccountOrigin() {
        return this.restangular
            .one('account')
            .one('getAccountOrigin')
            .get();
    }

    getAccountPremises() {
        return this.restangular
            .one('account')
            .one('address')
            .one('premises')
            .customGET()
            .then((response) => {
                return response.plain();
            });
    }

    getCustomer() {
        return this.restangular
            .one('account')
            .one('customer')
            .customGET()
            .then((response) => {
                return response.plain();
            });
    }

    updateCustomer(obj) {
        return this.restangular
            .one('account')
            .customPUT(obj);
    }

    updateAccount(obj) {
        return this.restangular
            .one('account')
            .one('address')
            .one('premises')
            .customPUT(obj)
            .then((response) => {
                return response.plain();
            });
    }

    getMonitoringInfo() {
        return this.restangular
            .all('Account')
            .one('MonitoringInfo')
            .get()
            .then((response) => {
                return response.plain();
            });
    }

    updateAccountStatus() {
        return this.restangular
            .all('Account')
            .one('UpdateAccountStatus')
            .get();
    }

    updateAccountStatusV2(accountStatusTypeId) {
        return this.restangular
            .all('Account')
            .one('UpdateAccountStatus')
            .customPOST(accountStatusTypeId);
    }

    deleteAccount() {
        return this.restangular
            .one('Account')
            .remove();
    }

    getAccountStatus() {
        return this.restangular
            .all('account')
            .one('status')
            .get();
    }

    activateAccount() {
        return this.restangular
            .all('Account')
            .one('Activate')
            .customPUT();
    }

    updatePasscode(passcode) {
        return this.restangular
            .all("account")
            .one("passcode")
            .customPUT(passcode)
            .then((response) => {
                return response;
            });
    }
    isPasscodeValid(passcode) {
        return this.restangular
            .all("account")
            .one("passcode")
            .one("is-valid?code=" + passcode).get();
    }

    canSignContract() {
        return this.restangular
            .all('Account')
            .one('CanSignContract')
            .get();
    }

    generateDocusignUrl(accountId) {
        return this.restangular
            .one('account', accountId)
            .one('GenerateDocusignSigningURL')
            .customPOST();
    }

    createCentralStationAccount(accountId, orderId) {
        return this.restangular
            .one('account', accountId)
            .one('CreateCentralStationAccount')
            .customPOST(orderId);
    }

    createAlarmDotComAccount(accountId, orderId, hubSerial) {
        return this.restangular
            .one('account', accountId)
            .one('serialNumber', hubSerial)
            .one('CreateAlarmDotComAccount')
            .customPOST(orderId);
    }

    addAccountPets(obj) {
        return this.restangular
            .all('Account')
            .one('AddAccountPets')
            .customPOST(obj);
    }

    UpdatePetsInfoInRapid(accountId) {
        return this.restangular
            .all('Account')
            .one(`UpdatePetsInfoInRapid?accountId=${accountId ? accountId : 0}`)
            .customPUT();
    }
}

export default AccountService;