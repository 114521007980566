import * as _ from 'lodash';

export const CONTRACT_DECLINED = 'CONTRACT_DECLINED';
export const CONTRACT_SIGNED = 'CONTRACT_SIGNED';

class TosActions {
    /*@ngInject*/
    constructor($log, $ngRedux, $state, constants) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
    }

    skipContract() {
        this._dispatch({
            type: CONTRACT_DECLINED,
            metadata: {
                sectionType: this.constants.sectionTypes.tos,
                description: 'Contract declined and skipped',
                persist: true
            }
        });
    }

    contractSigningCompleted() {
        this._dispatch({
            type: CONTRACT_SIGNED,
            metadata: {
                sectionType: this.constants.sectionTypes.tos,
                description: 'Contract signed and completed',
                persist: true
            }
        });
    }

    _dispatch(obj) {
        this.$ngRedux.dispatch(obj);
    }
}

export default TosActions;