const defaultBannerText = 'For a successful installation, all steps must be completed at once.';

export default [
    {
        key: 'account',
        bannerTitle: 'Setup Overview:',
        bannerText: defaultBannerText,
        title: 'Account Setup',
        description: 'Power on your Hub to get started. Set up your account details and credentials.',
        action: /*@ngInject*/ (context, step, $log, $state) => {
            $log.debug(step.title + ' action called');
            $state.go('instruction.place');
        }
    },
    {
        key: 'panel',
        bannerTitle: 'Doing great!',
        bannerText: 'Remember: your system will not be fully activated until you reach the end.',
        title: 'Hub & Keypad Setup',
        description: 'Configure your Hub and Keypad to ensure proper communication and optimized usage.',
        action: /*@ngInject*/ (context, step, $log, $state) => {
            $log.debug(step.title + ' action called');
            $state.go('instruction.keypad');
        }
    },
    {
        key: 'sensor',
        bannerTitle: 'Doing Great!',
        bannerText: 'Remember: your system will not be fully activated until you reach the end.',
        title: 'Sensor Installation',
        description: 'Mount, name, and test your system sensors.',
        action: /*@ngInject*/ (context, step, $log, $state) => {
            $log.debug(step.title + ' action called');
            $state.go('sensor.list');
        }
    },
    {
        key: 'explore',
        bannerTitle: 'Almost done!',
        bannerText: 'Your system will be fully activated once you press \"Continue\".',
        title: 'Explore Mobile App',
        description: 'After downloading, login and add any cameras or devices you\'ve purchased.',
        descriptionApp: 'Set up notifications and add any cameras or devices you\'ve purchased.',
        action: /*@ngInject*/ (context, step, $log, $state) => {
            $log.debug(step.title + ' action called');
            $state.go('activation.complete');
        }
    }
];