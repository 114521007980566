import { combineReducers } from 'redux';

import staticReducer from '../static.reducer';

import devicesReducer from './devices.reducer';
import panelReducer from './panel.reducer';
import testModeReducer from './testmode.reducer';
import wifiReducer from './wifi.reducer';
import codeReducer from './code.reducer';
import swapReducer from './swap.reducer';

export default combineReducers({
    ignore: staticReducer(['panel', 'testmode']),
    panel: panelReducer,
    devices: devicesReducer,
    testmode: testModeReducer,
    wifi: wifiReducer,
    code: codeReducer,
    swap: swapReducer
});