import * as _ from 'lodash';

export const GET_ACCOUNT_INFORMATION_SUCCESS = 'GET_ACCOUNT_INFORMATION_SUCCESS';
export const GET_ACCOUNT_ORIGIN_SUCCESS = 'GET_ACCOUNT_ORIGIN_SUCCESS';
export const ACTIVATE_ACCOUNT_REQUEST = 'ACTIVATE_ACCOUNT_REQUEST';
export const ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS';
export const FETCH_DOCUSIGN_SUCCESS = 'FETCH_DOCUSIGN_SUCCESS';
export const FETCH_DOCUSIGN_FAILURE = 'FETCH_DOCUSIGN_FAILURE';
export const FETCH_DOCUSIGN_BUSY = 'FETCH_DOCUSIGN_BUSY';
export const CS_ACCOUNT_CREATION_SUCCESS = 'CS_ACCOUNT_CREATION_SUCCESS';
export const ADC_ACCOUNT_CREATION_SUCCESS = 'ADC_ACCOUNT_CREATION_SUCCESS';

class AccountActions {
    /*@ngInject*/
    constructor($log, $ngRedux, $state, $q, dataContext, constants, $sce) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.$state = $state;
        this.dataContext = dataContext;
        this.constants = constants;
        this.$sce = $sce;
    }

    getAccountInformation() {
        const { account: { kitCustomer: { accountId } } } = this.$ngRedux.getState();
        return this.dataContext.account.getAccountInformation(accountId).then(res => {
            this._onGetAccountInformationSuccess(res);
        }).catch(error => {
            this.$log.error(`Error getting account ${error}`);
            return this.$q.reject(error);
        });
    }

    getAccountOrigin() {
        return this.dataContext.account.getAccountOrigin().then(res => {
            this._onGetAccountOriginSuccess(res);
        }).catch(error => {
            this.$log.error(`Error getting account ${error}`);
            return this.$q.reject(error);
        });
    }

    /**
     * Activate the account
     * 
     * @returns {promise} A promise that returns a undefined if resolved 
     * and an error if rejected.  
     */
    activateAccount() {
        this._onActivateAccountRequest();

        return this.dataContext.account.activateAccount()
            .then(() => {
                this._onActivateAccountSuccess();
            })
            .catch((error) => {
                this.$log.error('Error in final account activate', error);
                return this.$q.reject(error);
            });
    }

    createAdc(accountId, orderId) {
        var hubSerial = this.$ngRedux.getState().account.kitCustomer.kitProductSerialNumber;
        return this.dataContext.account.createAlarmDotComAccount(accountId, orderId, hubSerial)
            .then((result) => {
                this._onAdcCreationSuccess(result);
            })
            .catch((error) => {
                this.$log.error('Error in adc account creation', error);
                return this.$q.reject(error);
            });
    }

    generateDocusignUrl(accountUrl) {
        this.$ngRedux.dispatch({
            type: FETCH_DOCUSIGN_BUSY
        });

        return this.dataContext.account.generateDocusignUrl(accountUrl).then((response) => {
            if (_.isNil(response)) {
                this.$ngRedux.dispatch({
                    type: FETCH_DOCUSIGN_FAILURE
                });
            }

            this.$ngRedux.dispatch({
                type: FETCH_DOCUSIGN_SUCCESS,
                payload: { url: this.$sce.trustAsResourceUrl(response.Url), contractId: response.ContractId }
            });

            return response;
        });
    }

    createCentralStation(accountId, orderId) {
        return this.dataContext.account.createCentralStationAccount(accountId, orderId)
            .then((result) => {
                this._onCentralStationCreationSuccess(result);
            })
            .catch((error) => {
                this.$log.error('Error in central station account creation', error);
                return this.$q.reject(error);
            });
    }

    _onActivateAccountRequest() {
        this.$ngRedux.dispatch({
            type: ACTIVATE_ACCOUNT_REQUEST
        });
    }

    _onAdcCreationSuccess(adcAccountId) {
        this.$ngRedux.dispatch({
            type: ADC_ACCOUNT_CREATION_SUCCESS,
            payload: {
                AlarmDotComId: adcAccountId
            }
        });
    }

    _onCentralStationCreationSuccess(centralStationAccountId) {
        this.$ngRedux.dispatch({
            type: CS_ACCOUNT_CREATION_SUCCESS,
            payload: {
                CentralStationId: centralStationAccountId
            }
        });
    }

    _onActivateAccountSuccess() {
        this.$ngRedux.dispatch({
            type: ACTIVATE_ACCOUNT_SUCCESS,
            metadata: {
                description: 'The account is activated',
                sectionType: this.constants.sectionTypes.completed,
                persist: true
            }
        });
    }

    _onGetAccountInformationSuccess(payload) {
        this.$ngRedux.dispatch({
            type: GET_ACCOUNT_INFORMATION_SUCCESS,
            payload
        });
    }

    _onGetAccountOriginSuccess(startedOnAmazon) {
        this.$ngRedux.dispatch({
            type: GET_ACCOUNT_ORIGIN_SUCCESS,
            payload: {
                startedOnAmazon
            }
        });
    }
}

export default AccountActions;