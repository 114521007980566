import template from './sensorItemName.html';

const minNumNameParts = 1;
const maxNumNameParts = 4;

class SensorItemNameController {
    /*@ngInject*/
    constructor($ngRedux, $q, actionContext, constants) {
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.actionContext = actionContext;
        this.constants = constants;
        this.isKeypad = this.actionContext.airfxFlow.isKeypad();
    }

    $onInit() {
        this.isIq4Panel = this.$ngRedux.getState().account.user.isIq4Panel;
        this.numNameParts = minNumNameParts;
        if (!_.isEmpty(this.sensor.NameSuffix)) this.numNameParts++;
        if (!_.isEmpty(this.sensor.NameToken3)) this.numNameParts++;
        if (!_.isEmpty(this.sensor.NameToken4)) this.numNameParts++;

        if (this.handleConnect) {
            this.handleConnect({ ctrl: this });
        }

        //this is a edge case catch to ensure ASW and ISW both properly evaluate the presence of an IQPanel
        if (this.panelType.toUpperCase() === this.constants.panelVersions.iqPanel.toUpperCase()) {
            this.isIq4Panel = true;
        }

        // Non readonly forms must bind a handleUpdate() function.
        if (!this.isReadonly && !this.handleUpdate) {
            throw 'handleUpdate cannot be null or undefined';
        }

        this.setInitialSensorNames();
    }

    /**
     * Adds an input to the view.
     * 
     * NOTE: This method only works when the current panel is an XT panel. Calling this method when the current panel 
     * is an IQ panel does nothing.
     */
    handleAddInput() {
        this.numNameParts++;
        this.sensorNameForm.$setDirty();
    }

    /**
     * Removes an input from the view. 
     * 
     * NOTE: This method only works when the current panel is an XT panel. Calling this method when the current panel 
     * is an IQ panel does nothing.
     */
    handleRemoveInput() {
        this.numNameParts--;
        this.sensorNameForm.$setDirty();
    }

    /**
     * Returns true if the handleRemoveInput() function can be called.
     * 
     * @returns {boolean} True if the handleRemoveInput() function can be called; otherwise, false.
     */
    canAddInput() {
        return this.numNameParts < maxNumNameParts;
    }

    /**
     * Returns true if the handleAddInput() function can be called.
     * 
     * @returns {boolean} True if the handleAddInput() function can be called; otherwise, false. 
     */
    canRemoveInput() {
        return this.numNameParts > minNumNameParts;
    }

    /**
     * Alias for canUpdate().
     */
    isValid() {
        return this.canUpdate();
    }

    /**
     * Returns true if the submit() function can be called.
     * 
     * @returns {boolean} True if the submit() function can be called; otherwise, false. 
     */
    canUpdate() {
        const form = this.sensorNameForm;

        // 0. The form has NOT been initalized.
        if (!form) {
            return false;
        }

        // 1. The user has NOT touched the form but the sensor is already named.
        if (form.$pristine && this.sensor.isNamed === true) {
            return true;
        }

        // 2. The use has touched the form and it is valid.
        if (form.$dirty && form.$valid) {
            return true;
        }

        // 3. The user has touched the form and it is NOT valid.
        return false;
    }

    getEligibleSensorName(str) {
        if (this.isEligibleSensorName(str)) {
            return str;
        }

        for (let i = 0; i < str.length; i++) {
            const char = str[i].toLowerCase();

            if (this.isEligibleSensorName(char)) {
                return char;
            }
        }

        return null;
    }

    /**
     * Sets the input values for the sensor names if users have set them up
     */
    setInitialSensorNames() {
        if (this.panelType.toUpperCase() === this.constants.panelVersions.xtPanel.toUpperCase()) {
            const names = !_.isNil(this.sensor.SensorName) ? this.sensor.SensorName.split('|') : '';
            if (_.isNil(this.sensor.NameBase) && !_.isNil(names[0]) && this.isEligibleSensorName(names[0])) {
                this.sensor.NameBase = names[0];
                this.numNameParts = 1;
            }
            if (_.isNil(this.sensor.NameSufix) && !_.isNil(names[1]) && this.isEligibleSensorName(names[1])) {
                this.sensor.NameSuffix = names[1];
                this.numNameParts = 2;
            }
            if (_.isNil(this.sensor.NameToken3) && !_.isNil(names[2]) && this.isEligibleSensorName(names[2])) {
                this.sensor.NameToken3 = names[2];
                this.numNameParts = 3;
            }
            if (_.isNil(this.sensor.NameToken4) && !_.isNil(names[3]) && this.isEligibleSensorName(names[3])) {
                this.sensor.NameToken4 = names[3];
                this.numNameParts = 4;
            }
        } else {
            if (!_.isNil(this.sensor.Name) && this.sensor.Name.includes(" ")) {
                if (!this.isIq4Panel) {
                    const split = this.sensor.Name.split(" ");
                    
                    this.sensor.NameBase = this.getEligibleSensorName(split[0]);
                    this.numNameParts = 1;

                    if (split.length > 1) {
                        if (split[0] === "Motion") {
                            this.sensor.NameSuffix = "Sensor";
                            this.numNameParts = 2;
                        } else {
                            this.sensor.NameSuffix = this.getEligibleSensorName(split[1]);
                            this.numNameParts = 2;
                        }
                    }

                    if (split.length > 2) {
                        this.sensor.NameToken3 = this.getEligibleSensorName(split[2]);
                        this.numNameParts = 3;
                    }

                    if (split.length > 3) {
                        this.sensor.NameToken4 = this.getEligibleSensorName(split[3]);
                        this.numNameParts = 4;
                    }
                }           
            }
            if (_.isNil(this.sensor.Name) && !_.isNil(this.sensor.SensorName))
                this.sensor.Name = this.sensor.SensorName;
        }
    }

    isEligibleSensorName(sensorName) {
        return _.some(this.eligibleSensorNames, (eligibleSensorName) => {
            return eligibleSensorName === sensorName;
        });
    }

    /**
     * Invokes handleUpdate() if canUpdate() returns true. 
     * 
     * @returns {promise} A promise that will resolve with the result of handleSumbit() or will reject if canUpdate() 
     * returns false.
     */
    submit() {
        if (this.canUpdate()) {
            return this.$q.resolve(this.handleUpdate({ sensor: this.sensor }));
        }

        this.sensorNameForm.$setDirty();
        return this.$q.reject('invalid');
    }
}

export default {
    template: template,
    bindings: {
        eligibleSensorNames: '<',
        isReadonly: '<',
        panelType: '<',
        sensor: '<',
        handleConnect: '&',
        handleUpdate: '&'
    },
    controller: SensorItemNameController
};