import './cancelConfirmModal.scss';
import template from './cancelConfirmModal.html';

class CancelConfirmModalController {
    /*@ngInject*/
    constructor(SharedState, $ngRedux) {
        this.SharedState = SharedState;
        this.$ngRedux = $ngRedux;
    }

    close() {
        this.SharedState.turnOff('cancelConfirmModal');
    }

    onClickCancel() {
        this.cancelBarcode();
    }
}

export default {
    template: template,
    bindings: {
        cancelBarcode: '&'
    },
    controller: CancelConfirmModalController
};  