import './cameras.scss';
import * as _ from 'lodash';

import template from './cameras.html';

class CamerasController {
    /*@ngInject*/
    constructor($ngRedux, constants, actionContext, $state, orderActions, appActions) {
        this.$ngRedux = $ngRedux;
        this.constants = constants;
        this.actionContext = actionContext;
        this.$state = $state;
        this.orderActions = orderActions;
        this.appActions = appActions;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState)(this);
        this.message = this.instructions
            ? 'To setup your camera, please follow the instructions included in the camera box.'
            : 'Let\'s finish activating your system before getting your cameras setup.';
    }

    $onDestroy() {
        this.unsubscribe();
    }

    getCameraImgSrc() {
        if (this.theme !== 'green') {
            if (_.some(this.cameras, c => c.ProductSku === 'ADC-V722W')) {
                return "/Content/images/outdoor_camera_green.svg";
            } else if (_.some(this.cameras, c => c.ProductSku === 'ADC-V622')) {
                return "/Content/images/premium_indoor_green.svg";
            }

            return "/Content/images/wireless_camera_green.svg";
        }

        if (_.some(this.cameras, c => c.ProductSku === 'ADC-V722W')) {
            return "/Content/images/outdoor_camera.svg";
        } else if (_.some(this.cameras, c => c.ProductSku === 'ADC-V622')) {
            return "/Content/images/premium_indoor.svg";
        }

        return "/Content/images/wireless_camera.svg";
    }

    processNext() {
        if (!this.actionContext.order.hasLock() || !this.constants.enableLocksForInitialSetupWizard) {
            this.appActions.completeStep('automation');
            this.orderActions.hasAdditionalEquipment(false)
                .then((result) => {
                    if (result)
                        this.$state.go(this.constants.routerStates.airfxTunnel);
                    else
                        this.$state.go(this.constants.routerStates.menuHome);
                });
        } else
            this.$state.go(this.constants.routerStates.locksHome);
    }

    _mapState(state) {
        return {
            cameras: _.filter(_.map(state.account.order.itemsByID), (item) => {
                return _.includes(!_.isNil(item.ProductName) ? item.ProductName.toLowerCase() : null, 'camera');
            })
        };
    }
}

export default {
    template: template,
    bindings: {
        instructions: '=',
        theme: '@',
        finalStep: '='
    },
    controller: CamerasController
};