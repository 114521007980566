import template from './contact.html';
import './contact.scss';

class ContactController {
    /*@ngInject*/
    constructor () {
    }
}

export default {
    template: template,
    bindings: {
        contact: '<'
    },
    controller: ContactController
};