import * as _ from 'lodash';
import ngRedux from 'ng-redux';
import { Unsubscribe } from 'redux';

export class DuresscodeHomeController {
    public isIq4Panel: boolean;
    public typeDuressCode: boolean;
    public duresscode: string;

    private unsubscribe: Unsubscribe;

    constructor(
        private $ngRedux: ngRedux.INgRedux,
        public constants: any,
        public $state: any,
        private navigation: any,
    ) {
        'ngInject';
        this.isIq4Panel = this.$ngRedux.getState().account.user.isIq4Panel;
    }   

    public $onInit() {
        this.typeDuressCode = true;
        this.duresscode = '9998';
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this))(this);
    }

    public goNext() {
        this.navigation.goNext();
    }
    public $onDestroy() {
        this.unsubscribe();
    }    

    public toggleDuressCode() {
        this.typeDuressCode = !this.typeDuressCode;
    }

    public mapState(state: any) {
        const hasHub = state.system.panel.type === this.constants.panelVersions.climaxHub;        

        return {
            hasHub,
            targetSku: state.account.airfxFlow.targetSku,
            isConnected: state.system.wifi.isConnected,
            networkName: state.system.wifi.networkName,
            isInitialOrder: state.account.user.isInitialOrder
        };
    }
}

export const duresscodeHomeComponent = {
    template: require('./home.html'),
    bindings: {},
    controller: DuresscodeHomeController
};
