import './barcodeScannerOverlay.scss';
import template from './barcodeScannerOverlay.html';
import * as _ from 'lodash';

class BarcodeScannerOverlay {
    /*@ngInject*/
    constructor(barcodeService, constants, $state, scannerService, $ngRedux, actionContext) {
        this.barcodeService = barcodeService;
        this.constants = constants;
        this.$state = $state;
        this.setIsLoading(true);
        this.scannerService = scannerService;
        this.$ngRedux = $ngRedux;
        this.actionContext = actionContext;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState)(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    show() {
        return _.get(this, '$state.$current.data.showBarcodeScanner', false);
    }

    setIsLoading(isLoading) {
        this.isLoading = isLoading;
    }

    logHelp() {
        this.actionContext.common.other(this.constants.actionTypes.other, this.constants.sectionTypes.help, false);
    }

    _mapState(state) {
        return {
            sku: state.account.airfxFlow.sku,
            category: state.account.airfxFlow.category
        };
    }

}

export default {
    template: template,
    bindings: {},
    controller: BarcodeScannerOverlay
};
