import ensure from './ensure';

class DateKind {
    /**
     * Changes a date time object's kind to UTC without affecting its value. 
     * 
     * Example:
     * 2016-06-28T09:00-4:00 (EST) -> 2016-06-28T09:00+0:00 (UTC)
     * 
     * @param {Date} date A date object.
     * @returns {Date} A date object with a kind of UTC. 
     */
    static toUtcKind(date) {
        ensure.isDate('date', date);

        return new Date(Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()));
    }

    /**
     * Changes a date time object's kind to local without affecting its value. 
     * 
     * Example:
     * 2016-06-28T09:00+0:00 (UTC) -> 2016-06-28T09:00-4:00 (EST)
     * 
     * @param {Date} date A date object.
     * @returns {Date} A date object with a kind of local. 
     */
    static toLocalKind(date) {
        ensure.isDate('date', date);

        return new Date(
            date.getUTCFullYear(), 
            date.getUTCMonth(), 
            date.getUTCDate(), 
            date.getUTCHours(), 
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getUTCMilliseconds());
    }
}

export default DateKind;