import * as _ from 'lodash';

import template from './edit.html';

class EditComponent {
    /*@ngInject*/
    constructor($document, $log, $ngRedux, $state, actionContext, constants, navigation) {
        this.$document = $document;
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.actionContext = actionContext;
        this.constants = constants;
        this.navigation = navigation;

        this.minLength = 10;
        this.maxLength = 100;
        this.password = '';
        this.verifyPassword = '';
        this.typePassword = true;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);

        if (this.targetSku === this.constants.panelVersions.iqPanel) {
            this.customerSupportPhone = this.constants.customerSupportIQ.phone;
        } else {
            this.customerSupportPhone = !this.$ngRedux.getState().account.origin.startedOnAmazon
                ? this.constants.customerSupportHub.phone
                : this.constants.customerSupportHub.amazonPhone;
        }


    }

    $onDestroy() {
        this.unsubscribe();
    }

    togglePassword() {
        this.typePassword = !this.typePassword;
    }

    handleSubmit() {
        const form = this.passwordEditForm;
        if (!form || form.$invalid) {
            return;
        }

        this.actionContext.password.updatePassword(this.password)
            .then(() => {
                this.navigation.goNext();
            });
    }

    _mapState(state) {
        return {
            username: state.account.user.name,
            isUpdating: state.account.password.isUpdating,
            hasError: state.account.password.hasError,
            message: state.account.password.message,
            targetSku: state.account.airfxFlow.targetSku
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: EditComponent
};