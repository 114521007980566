class CustomerBillingInfoService {
    /*@ngInject*/
    constructor(Restangular, constants, applicationStateService, $q) {
        this.Restangular = Restangular;
        this.constants = constants;
        this.applicationStateService = applicationStateService;
        this.$q = $q;
    }

    saveCustomerBillingInfo(paymentMethodInfo) {
        return this.Restangular
            .one('customerBillingInfo')
            .withHttpConfig({
                cache: false
            })
            .customPOST(paymentMethodInfo);
    }

    getCustomerBillingInfo(customerBillingInfoId) {
        return this.Restangular
            .one('customerBillingInfo')
            .one(`getPaymentMethod?customerBillingInfoID=${customerBillingInfoId || 0}`)
            .withHttpConfig({
                cache: false
            })
            .get();
    }
}

export default CustomerBillingInfoService;