let maxLimitNumber =  () => {
    'ngInject';
    return {
        require: 'ngModel',
        restrict: 'A',
        link: ($scope, $element, $attr, ctrl) => {
            $element.on('keypress', (event) => {
                if (event.keyCode !== 13 && $element.val().length >= parseInt($attr.awMaxLimitNumber) ) { 
                    event.preventDefault();
                }
            });
        }
    };
};

export default maxLimitNumber;