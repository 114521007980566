import template from "./systemControlList.html";
import * as _ from "lodash";

class systemControlListController {
    /*@ngInject*/
    constructor(actionContext, dataContext, constants) {
        this.isRadioSwapEligible = false;
        this.isLoading = true;

        actionContext.panel.fetchPanelVersion().then((result) => {
            if (result.PanelType === constants.panelVersions.xtPanel) {
                dataContext.radioSwap.checkEligibility().then((res) => {
                    this.isLoading = false;
                    this.isRadioSwapEligible = !_.isNil(res) && res.Message === "Eligible";
                });
            } else {
                this.isLoading = false;
            }
        });
    }
}

export default {
    template: template,
    bindings: {},
    controller: systemControlListController
};

