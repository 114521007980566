import * as angular from 'angular';
import template from './contract.html';

class ContractController {
    /*@ngInject*/
    constructor($ngRedux, $state, constants, actionContext, SharedState ) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.actionContext = actionContext;
        this.isUpdating = false;
        this.isLoadingContract = true;
        this.SharedState = SharedState;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);

        // account already has a signed contract, and we do not need to show the contract page
        if (this.contractId) {
            this.isAmazon ? this.$state.go('account.registering') : this.$state.go('predispatch.home');
        }

        window.addEventListener("message",
            (msg) => {
                if (msg.data === 'DocusignSigningCompleted') {
                    this.isAmazon ? this.$state.go('account.registering') : this.$state.go('predispatch.home');
                } 
            }, false);

        window.addEventListener("message",
            (msg) => {
                if (msg.data === 'DocusignSigningDeclined') {
                    this.$state.go('account.contract-declined');
                }
            }, false);

        this.actionContext.account.generateDocusignUrl(this.accountId).then((response) => {
            // account already has a signed contract, and we do not need to show the contract page
            if (response.ContractID) {
                this.isUpdating = true;
                this.isAmazon ? this.$state.go('account.registering') : this.$state.go('predispatch.home');
            }
        });
    }

    _mapState(state) {
        let map = {};

        map.contact = state.account.kitCustomer.contact;
        map.contractId = state.account.kitCustomer.contractId;
        map.accountId = state.account.kitCustomer.accountId;
        map.isUpdating = state.account.kitCustomer.isUpdating;
        map.isLoadingContract = state.account.kitCustomer.isLoadingContract;
        map.docusignUrl = state.account.kitCustomer.docusignUrl;
        map.isAmazon = state.account.user.isAmazonUser;
        if (!map.isAmazon) {
            map.accountId = state.account.user.accountId;
        }

        return _.cloneDeep(map);
    }
}

export default {
    template: template,
    bindings: {},
    controller: ContractController
};