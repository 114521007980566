import * as _ from 'lodash'

import {GET_ACCOUNT_ORIGIN_SUCCESS} from '../../action/account.actions'

const initialState = {
	startedOnAmazon: true
};

export default(state = initialState, action = {}) => {
    switch (action.type) {
    case GET_ACCOUNT_ORIGIN_SUCCESS:
        const {startedOnAmazon} = action.payload;
        return {
            ...state,
            startedOnAmazon
        };
    default:
        return state;
    }
}