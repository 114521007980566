import * as _ from 'lodash';

import template from './disarmSystem.html';

class DisarmSystemController {
    /*@ngInject*/
    constructor(deviceActions, constants, $interval, $state, $stateParams, $ngRedux) {
        this.deviceActions = deviceActions;
        this.constants = constants;
        this.$interval = $interval;
        this.$ngRedux = $ngRedux;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.isPanelDisarmed = false;
        this.displayDisarmTextAfterArmAway =
            this.$stateParams.sourceLocation === this.constants.airFxArmDisarmNavigationParams.airFxTrainingArmAway ||
        this.$stateParams.sourceLocation === this.constants.airFxArmDisarmNavigationParams.airFxTrainingArmStayBack ||
        this.$ngRedux.getState().router.prevParams.sourceLocation === this.constants.airFxArmDisarmNavigationParams.airFxTrainingArmAway ||
        this.$ngRedux.getState().router.prevParams.sourceLocation === this.constants.airFxArmDisarmNavigationParams.airFxTrainingArmStayBack;
    }

    $onInit() {
        this.pollPanelStatus();
    }

    pollPanelStatus() {
        // number of milliseconds between each interval
        const intervalDelay = this.constants.polling.training.pollingInterval;
        // indefinite interval to keep checking for the panel status
        this.$interval(() => {
            this.deviceActions.getPanelStatus().then((result) => {
                let panelStatus = result;
                this.isPanelDisarmed = panelStatus === this.constants.deviceStatus.disarmed.ID;
            });
        }, intervalDelay); 
    }
}

export default {
    template: template,
    bindings: {},
    controller: DisarmSystemController
};