import * as _ from 'lodash';
import * as ScanditSDK from 'scandit-sdk';

class ScannerService {
    /*@ngInject*/
    
    constructor(constants, $q, actionContext, dataContext, $ngRedux) {
        this.constants = constants;
        this.picker = {};
        this.$q = $q;
        this.actionContext = actionContext;
        this.dataContext = dataContext;
        this.unsubscribe = $ngRedux.connect(this._mapState.bind(this))(this);
    }

    initialize(container) {
        if (_.isEmpty(this.picker) && this.isInitializable()) {
            // Normally we will want to run through this call via Redux and Map the state or get it from State Resolve.
            // However, this service is called from scanner.component.js that is attached directly on index.cshtml 
            // thus will not respect both Redux State nor State Resolve
            return this.dataContext.configurations.getScanditLicenseKey().then((result) => {
                ScanditSDK.configure(result.scanditLicenseKey, {
                    engineLocation: window.__baseHref + 'Scripts/build/',
                    preloadEngineLibrary: false,
                    preloadCameras: false
                });
                return ScanditSDK.BarcodePicker.create(container, {
                    guiStyle: 'none',
                    accessCamera: false,
                    scanningPaused: true,
                    playSoundOnScan: false,
                    vibrateOnScan: true,
                    cameraSettings: { resolutionPreference: 'HD' }
                })
                .then(barcodePicker => {
                    this.picker = barcodePicker;
                    const scanSettings = new ScanditSDK.ScanSettings({
                        enabledSymbologies: ['code128']
                    });
                    this.picker.setVideoFit('cover');
                    this.picker.setCameraSwitcherEnabled(false);
                    this.picker.setTorchToggleEnabled(false);
                    this.picker.setTargetScanningFPS(10);
                    this.picker.applyScanSettings(scanSettings);
                    this.picker.onScanError(error => alert(error.message));
                })
                .catch(error => alert(error));
            });
        }
    }

    checkBrowserCompatibility() {
        return ScanditSDK.BrowserHelper.checkBrowserCompatibility();
    }

    startScanning() {
        this.scanning = true;
        if (this.picker) {
            return this.picker.resumeScanning().then((barcodePicker) => {
                this.picker = barcodePicker;
            });
        }
        return this.$q.resolve();
    }

    stopScanning() {
        this.scanning = false;
        if (this.picker) {
            this.picker = this.picker.pauseScanning(true);
        }
    }

    handleScan(callback) {
        const scan = (scanResult) => {
            this.stopScanning();
            callback(scanResult);
        };
        this.picker.onScan(scan.bind(this));
    }

    handleReady(callback) {
        const ready = () => {
            callback();
        };
        this.picker.onReady(ready.bind(this));
    }

    accessCamera() {
        if (this.picker) {
            return this.picker.accessCamera()
                .then((barcodePicker) => {
                    this.picker = barcodePicker;
                });
        }
        return this.$q.resolve();
    }

    getPicker() {
        return this.picker;
    }

    destroy() {
        this.picker.destroy();
    }

    hasScannableSensors() {
        const scannableSensorList = this.constants.barcodeScannerFlowSkus;
        const customersSensorSkus = _.map(this.sensors, (sensor) => { return sensor.sku; });
        return _.intersection(scannableSensorList, customersSensorSkus).length > 0;
    }

    isInitializable() {
        return this.actionContext.app.isAdditional() && this.hasScannableSensors && this.dataContext.user.isBrowser();
    }

    _mapState(state) {
        return {
            sensors: _.map(state.system.devices.sensorsByID)
        };
    }
}

export default ScannerService;