import * as _ from 'lodash';
import * as angular from 'angular';
import ngRedux from 'ng-redux';

import commonModule from '../../../common';

import sensorNotConnectedComponent from './notConnected.component';
import sensorMalfunctionComponent from './malfunction.component';
import sensorTamperedComponent from './tamper.component';

export default angular
    .module('fp:activationswizard:sensor:error', [
        'ui.router',
        ngRedux,
        commonModule.name
    ])
    .config( /*@ngInject*/($stateProvider) => {
        $stateProvider
            .state('sensor.item.not-connected', {
                url: '/not-connected',
                template: '<aw-sensor-not-connected></aw-sensor-not-connected>',
                data: {
                    persist: false
                }
            })
            .state('sensor.item.malfunction', {
                url: '/malfunction',
                template: '<aw-sensor-malfunction></aw-sensor-malfunction>',
                data: {
                    persist: false
                }
            })
            .state('sensor.item.tamper', {
                url: '/tamper',
                template: '<aw-sensor-tamper></aw-sensor-tamper>',
                data: {
                    persist: false
                }
            });
    })
    .component('awSensorMalfunction', sensorMalfunctionComponent)
    .component('awSensorNotConnected', sensorNotConnectedComponent)
    .component('awSensorTamper', sensorTamperedComponent);