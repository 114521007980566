import * as _ from 'lodash';

import template from './tos.html';

class TosController {
    /*@ngInject*/
    constructor($ngRedux, $state, $timeout, actionContext, constants, appActions, $sce, dataContext) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$timeout = $timeout;
        this.actionContext = actionContext;
        this.constants = constants;
        this.appActions = appActions;
        this.$sce = $sce;
        this.dataContext = dataContext;
        this.isLoading = true;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.docusignLink = '';

        // Generate docusign link
        this.dataContext.account.generateDocusignSigningURL().then((response) => {

            // If the API returns nothing we don't have a contract to sign so skip to the testmode step
            if (_.isNil(response)) {
                this.appActions.completeModule(this.constants.sectionTypes.tos);
                this.$state.go('testmode.home');
                return;
            }

            this.docusignLink = this.$sce.trustAsResourceUrl(response);
            this.isLoading = false;
        });

        window.addEventListener("message",
            (msg) => {
                if (msg.data === 'DocusignSigningCompleted') {
                    this.appActions.completeModule(this.constants.sectionTypes.tos);
                    this.actionContext.tos.contractSigningCompleted();
                    this.$state.go('testmode.home');
                }
            }, false);
    }

    onClickSkip() {
        this.appActions.completeModule(this.constants.sectionTypes.tos);
        this.actionContext.tos.skipContract();
        this.$state.go('testmode.home');
    }

    $onDestroy() {
        this.unsubscribe();
    }

    _mapState(state) {
        return {
            panel: state.system.panel
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: TosController
};