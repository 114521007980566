import './email.scss';
import template from './email.html';

class EmailModalController {
    /*@ngInject*/
    constructor($log, $scope, SharedState, constants, dataContext) {
        this.$log = $log;
        this.$scope = $scope;
        this.SharedState = SharedState;
        this.constants = constants;
        this.dataContext = dataContext;
    }

    $onInit() {
        this.unsubscribe = this.$scope.$watch(() =>  this._isOpen(), (value) => value ? this._onOpen() : null);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    onClick_SendEmailSubmit() {
        this.isBusy = true;
        this.dataContext.support.sendEmail(this.address, this.subject, this.body)
            .then(() => {
                this.$log.debug('Successfully sent email.');
                this.isSuccess = true;
            })
            .catch((error) => {
                this.$log.error('Error sending email.', error);
                this.isError = true;
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    onClick_SendEmailErrorContinue() {
        this.isError = false;
    }

    onClick_SendEmailSuccessContinue() {
        this.SharedState.turnOff('modalEmail');
        this.isSuccess = false;
    }

    _isOpen() {
        return this.SharedState.isActive('modalEmail');
    }

    _onOpen() {
        // Values
        this.address = null;
        this.subject = null;
        this.body = null;
        // Flags
        this.isSuccess = false;
        this.isError = false;
        this.isBusy = false;
    }
}

export default {
    template: template,
    bindings: {},
    controller: EmailModalController
};