import moment from 'moment';
import * as _ from 'lodash';
import template from "./sensorTransfer.html";

class SensorTransferController {
    /*@ngInject*/
    constructor($ngRedux, $state, $log, dataContext, constants, $interval, actionContext, segment, $q, $timeout,
        $stateParams, SharedState) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.dataContext = dataContext;
        this.constants = constants;
        this.$interval = $interval;
        this.actionContext = actionContext;
        this.segment = segment;
        this.$q = $q;
        this.$log = $log;
        this.$timeout = $timeout;
        this.swapType = $ngRedux.getState().system.swap.swapType;
        this.SharedState = SharedState;
        this.showModal = false;
        this.MAX_RETRIES = constants.polling.airfx.initialization.maxIntervalCount;
        this.DOUBLED_MAX_RETRIES = constants.polling.airfx.initialization.dblMaxIntervalCount;
        this.INITIAL_POLL_INTERVAL = 20000;
        this.systemControlSensorDevices = {};
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        if (this.swapType === this.constants.swapType.radio) {
            this.actionContext.radioSwap.onSensorTransferStarted();
        } else {
            this.actionContext.modSwap.onSensorTransferStarted();
        }
        this.showProgress = true;
        this.dateStarted = moment();

        if (this.constants.enableCsRoundtrip) {
            this.verifyCommTest();
        } else {
            this._verifyDevices();
        }

        if (this.targetSku === this.constants.panelVersions.iqPanel) {
            this.customerSupportPhone = this.constants.customerSupportIQ.phone;
        } else {
            this.customerSupportPhone = !this.$ngRedux.getState().account.origin.startedOnAmazon
                ? this.constants.customerSupportHub.phone
                : this.constants.customerSupportHub.amazonPhone;
        }
    }

    verifyCommTest() {
        var isCommTestVerified = false;
        let maxIterations = this.constants.panel.maxIterations;
        let iterationTime = this.constants.panel.iterationTime;

        var lastCsEventInterval = this.$interval(() => {
                return this.dataContext.panel.getLastCsEventDate().then((result) => {
                    if (result.Success) {
                        var currentDateUtc = moment().utc().format();
                        var lastDate = result.LastSystemDateUtc;
                        if (!_.isNil(lastDate) && !_.isNil(currentDateUtc)) {
                            var diff = moment(currentDateUtc).diff(moment(lastDate), 'seconds');
                            // checking if lastEventDate is within a minute 
                            if (diff >= 0 && diff < 60) {
                                if (this.swapType === this.constants.swapType.radio) {
                                    this.actionContext.radioSwap.onRoundTripCommunicationSuccess();
                                } else {
                                    this.actionContext.modSwap.onRoundTripCommunicationSuccess();
                                }
                                isCommTestVerified = true;
                                this.$interval.cancel(lastCsEventInterval);
                            }
                        }
                    }
                }).catch(() => {
                    this.$interval.cancel(lastCsEventInterval);
                });
            },
            iterationTime,
            maxIterations);

        this.dataContext.panel.getRequestRoundTrip().then((result) => {
            if (result) {
                return lastCsEventInterval.then(() => {
                    this.showProgress = false;
                    if (this.swapType === this.constants.swapType.radio) {
                        this.actionContext.radioSwap.onRoundTripCommunicationFailure();
                    } else {
                        this.actionContext.modSwap.onRoundTripCommunicationFailure();
                    }
                }).catch((message) => {
                    if (message === 'canceled' && isCommTestVerified) {
                        this._verifyDevices();
                    } else {
                        this.showProgress = false;
                        this.actionContext.modSwap.onRoundTripCommunicationFailure();
                        return this.$q.reject('Error occured while fetching the last CsEvent date.');
                    }
                });
            }

            this.actionContext.modSwap.onRoundTripCommunicationFailure();
            return this.$q.reject('Error occured while verifying the sensors after the swap.');
        }).catch((error) => {
            this.$log.error(error);
            this.actionContext.modSwap.onRoundTripCommunicationFailure();
        });
    }

    onContinue() {
        this.segment.registerEvent(this.constants.events.segment.panelSwapSensorTransferNext, {});
        this._setUnlikeSwapOrderProductsActivated();

        this.$state.go('airfx.userCodes');
    }

    goToPrevious() {
        if (this.targetSku === this.constants.panelVersions.iqPanel) {
            this.$state.go('wifi.home');
        } else {
            this.$state.go('instruction.keypadswap');
        }
    }

    _setUnlikeSwapOrderProductsActivated() {
        // Get all order products in the unlike swap order to be activated
        var allUnactivatedOrderProducts = _.filter(this.orderProducts,
            (orderProduct) => { return !orderProduct.IsInitialOrder && _.isNil(orderProduct.ActivatedDate) });
        this.allClimaxHub = _.filter(allUnactivatedOrderProducts,
            (orderProduct) => { return orderProduct.ProductSku === 'climax' });
        if (this.allClimaxHub.length > 0) {
            _.forEach(this.allClimaxHub,
                hub => {
                    this.allKeypadsInUnlikeSwapOrder = _.filter(allUnactivatedOrderProducts,
                        (orderProduct) => {
                            return orderProduct.OrderID === hub.OrderID &&
                                _.indexOf(this.constants.keypads.skus, orderProduct.ProductSku) !== -1;
                        });
                    this.orderProductsToBeActivated = _.concat(hub, this.allKeypadsInUnlikeSwapOrder);
                    _.forEach(this.orderProductsToBeActivated,
                        orderProduct => {
                            // set order product as activated
                            this.actionContext.order.setOrderProductActivated(orderProduct);
                        });
                });
        }
    }

    _verifyDevices() {
        // get devices from systemControl
        return this.getSystemControlDevices().then(() => {
            let dlCodes = [];

            if (!_.isEmpty(this.systemControlSensorDevices)) {
                dlCodes = this.systemControlSensorDevices.map(d => d.DlCode);
            }
            
            return this.actionContext.panel.getDevices(true, dlCodes).then((devices) => {
                if (this.swapType === this.constants.swapType.radio) {
                    this.sensorList = _.filter(devices,
                        (sensor) => {
                            return !_.includes(this.constants.radioSwapGroupIdsToHide, sensor.GroupId);
                        });
                    this.actionContext.radioSwap.onSensorTransferCompleted(moment().diff(this.dateStarted));
                } else {
                    this.checkForHubIncompatibleDevices();
                    this.sensorList = _.filter(devices,
                        (sensor) => {
                            return !_.includes(this.constants.modSwapGroupIdsToHide, sensor.GroupId);
                        });
                    this.actionContext.modSwap.onSensorTransferCompleted(moment().diff(this.dateStarted));
                }
                this.showProgress = false;
            }).catch(() => {
                this.showProgress = false;
                return this.$q.reject('Error occured while verifying the sensors after the swap.');
            });
        });
    }

    getSystemControlDevices() {
        return this.dataContext.system.sensor.getSystemControlDevices().then((deviceResponse) => {
            if (!_.isNil(deviceResponse) &&
                deviceResponse.Success &&
                !_.isEmpty(deviceResponse.Devices)) {

                if (this.swapType === this.constants.swapType.radio) {
                    this.systemControlSensorDevices = _.filter(deviceResponse.Devices,
                        (device) => { return (!_.includes(this.constants.radioSwapGroupIdsToHide, device.GroupId)) && (device.DlCode != null || device.DlCode != undefined) });
                } else {
                    this.systemControlSensorDevices = _.filter(deviceResponse.Devices,
                        (device) => { return (!_.includes(this.constants.modSwapGroupIdsToHide, device.GroupId)) && (device.DlCode != null || device.DlCode != undefined) });
                }
            }
        });
    }

    checkForHubIncompatibleDevices() {
        this.dataContext.account.order.checkPanelCompatibility(this.targetSku).then(hasIncompatibleDevice => {
            if (!_.isNil(hasIncompatibleDevice) && hasIncompatibleDevice) {
                this.showModal = true;
                this.SharedState.turnOn('incompatibleDeviceModal');
            }
        });
    }

    _mapState(state) {
        return {
            orderProducts: state.account.order.itemsByID,
            targetSku: state.account.airfxFlow.targetSku
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: SensorTransferController
};