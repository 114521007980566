import './scanBarcode.scss';
import * as _ from 'lodash';

class ScanBarcodeController {
    /*@ngInject*/
    constructor($rootScope) {
        this.$rootScope = $rootScope;
    }

    $onInit() {
        this.subscribeToScanEvents();
    }

    subscribeToScanEvents() {
        this.$rootScope.$broadcast('accessScanner', [this.onDetectBarcode]);
    }
}

//no template here. Logic and component are in the barcode scanner overlay
export default {
    bindings: {},
    controller: ScanBarcodeController
}