class MonitoringService {
    /*@ngInject*/
    constructor(Restangular) {
        this.restangular = Restangular;
    }

    //api/account/dispatch-contacts
    getPreDispatch() {
        return this.restangular
            .one('account')
            .all('dispatch-contacts')
            .all('predispatch')
            .getList()
            .then((result) => {
                return result.plain();
            });
    }

    updatePreDispatch(obj) {
        return this.restangular
            .one('account')
            .all('dispatch-contacts')
            .all('predispatch')
            .customPOST(obj)
            .then((result) => {
                return result.plain();
            });
    }
}

export default MonitoringService;