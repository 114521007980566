import * as angular from 'angular';
import 'angular-ui-router';
import ngRedux from 'ng-redux';
import 'ui-router.grant';
import * as _ from 'lodash';

import './wifi.scss';

import { commonModule } from '../common';
import { commonUtilModule } from '../common/util';
import { wifiConnectedComponent } from './connected.component';
import { wifiHomeComponent } from './home.component';
import { wifiNetworksComponent } from './networks.component';

export const wifiModule = angular
    .module('fp:activationswizard:wifi',
        [
            ngRedux,
            'ui.router',
            'ui.router.grant',
            commonModule.name,
            commonUtilModule.name
        ])
    .config(/*@ngInject*/
        ($stateProvider: ng.ui.IStateProvider, constants: any) => {
            $stateProvider
                .state('wifi',
                    {
                        abstract: true,
                        parent: 'secure',
                        url: '/wifi',
                        template: '<ui-view></ui-view>',
                        data: {
                            next: ($state: ng.ui.IStateService, appActions: any, $ngRedux: any) => {
                                'ngInject';
                                appActions.completeModule(constants.sectionTypes.wifi);
                                if (!_.isNull($ngRedux.getState().account.user.isInitialOrder) &&
                                    !$ngRedux.getState().account.user.isInitialOrder) {
                                    $state.go('airfx.modswapSensorTransfer');
                                } else {
                                    const isIq4Panel = $ngRedux.getState().account.user.isIq4Panel;
                                    appActions.completeStep('panel');
                                    if (isIq4Panel) {
                                        $state.go('testmode.home');
                                    } else {
                                        $state.go('training.how-to');
                                    }                                    
                                }
                            },
                            prev: ($state: ng.ui.IStateService, $ngRedux: any) => {
                                'ngInject';
                                if (!_.isNull($ngRedux.getState().account.user.isInitialOrder) &&
                                    !$ngRedux.getState().account.user.isInitialOrder) {
                                    $state.go('instruction.keypadswap');
                                } else {
                                    $state.go('usercode.home');
                                }
                            },
                            sectionType: constants.sectionTypes.wifi,
                            theme: 'app-theme-default-navbar-gray'
                        },
                        resolve: {
                            isMasterCodeComplete: /*@ngInject*/ (grant: any, setupComplete: any) => {
                                return grant.only({
                                    test: 'isMasterCodeComplete',
                                    state: 'mastercode.home'
                                });
                            }
                        }
                    })
                .state('wifi.home',
                    {
                        url: '',
                        template: '<aw-wifi-home></aw-wifi-home>',
                        onEnter: /*@ngInject*/ (appActions: any) => {
                            appActions.startModule(constants.sectionTypes.wifi);
                        }
                    })
                .state('wifi.networks',
                    {
                        abstract: false,
                        url: '/networks',
                        template: '<aw-wifi-networks></aw-wifi-networks>'
                    })
                .state('wifi.connected',
                    {
                        abstract: false,
                        url: '/connected',
                        template: '<aw-wifi-connected></aw-wifi-connected>',
                        data: {
                            theme: 'app-theme-green'
                        }
                    });
        })
    .component('awWifiHome', wifiHomeComponent)
    .component('awWifiNetworks', wifiNetworksComponent)
    .component('awWifiConnected', wifiConnectedComponent);
