import * as _ from 'lodash';
import template from './finalize.html';

class FinalizeController {
    /*@ngInject*/
    constructor($ngRedux, $state, $q, actionContext, dataContext, constants, appActions, $rootScope) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$q = $q;
        this.actionContext = actionContext;
        this.dataContext = dataContext;
        this.constants = constants;
        this.appActions = appActions;
        this.showProgress = true;
        this.$rootScope = $rootScope;
        this.category = this.$state.params.category;
    }

    $onInit() {
        this.actionContext.device.fetchSensorsForInitialWithAdditional()
          .then(() => {
              this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
                this.finalize();
            });

        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    finalize() {
        this.showProgress = true;
        const promises = [];

        this.$q.resolve()
            .then(() => {
                //mark panic sensors complete
                _.forEach(this.sensors, sensor => {
                    if (sensor.DeviceType === 'ImageSensor' || sensor.DeviceType === 'PanicButton')
                        sensor.isComplete = true;
                });

                //find sensors to activate
                _.forEach(this.sensors, sensor => {
                    if (!sensor.isComplete) {
                        return;
                    }

                    //make sure both dl codes are uppercase
                    if (!_.isNil(sensor.DLCode))
                        sensor.DLCode = sensor.DLCode.toUpperCase();

                    _.each(this.products, (product) => {
                        if (!_.isNil(product.DLCode))
                            product.DLCode = product.DLCode.toUpperCase();
                    });

                    const product = _.find(this.products, { DLCode: sensor.DLCode });
                    if (_.isNil(product) || product.isActivated) {
                        //if panic pendant or image sensor process differently
                        if ((sensor.DeviceType === 'ImageSensor' || sensor.DeviceType === 'PanicButton') && sensor.isSkipped !== true) {
                            const linkedProduct = _.find(this.products, { SensorNumber: sensor.SensorID });
                            if (!_.isNil(linkedProduct)) {
                                promises.push(this.actionContext.order.setOrderProductActivated(linkedProduct));
                            }
                        }
                        return;
                    }
                    promises.push(this.actionContext.order.setOrderProductActivated(product));
                });
            })
            .then(() => {
                this.actionContext.order._resetProducts();
                return this.$q.all(promises);
            })
            .then(() => {
                //reload all data before restarting
                return this.$q.all([
                    this.actionContext.customer.fetchCustomer(),
                    this.actionContext.order.fetchOrderProducts(),
                    this.actionContext.app.fetchEligibleSensorNames(),
                    this.actionContext.app.fetchConfiguration(),
                    this.actionContext.device.fetchSensors()
                ]);
            })
            .then(() => {
                if (!this.appActions.isInitialWithAdditional())
                    this.$state.go(this.constants.routerStates.redirectToAirfx, { category : this.category });
                else {
                    this.appActions.completeStep('panel');
                    this.appActions.completeStep('sensor');
                    this.appActions.completeStep('activation');

                    if (this.actionContext.order.hasCameraInInitial() || (this.actionContext.order.hasLockInInitial() && this.constants.enableLocksForInitialSetupWizard))
                        this.appActions.completeStep('automation');
                    else
                        this.appActions.completeStep('signage');

                    this.$state.go('sensor.activation');
                }
            })
            .catch((err) => {
                var previousState = this.$rootScope.$resolves.previousState;
                var message = 'User directed to Oops page from finalize.component. ';
                if (!_.isNil(previousState)) {
                    message += `Previous State - Name: [${previousState.current.name}] Template: [${previousState.current.template}] Url: [${previousState.current.url}]  Next State - Name: [${previousState.next.name}] Url: [${previousState.next.url}] Error: [${err}].`;
                }

                this.actionContext.equipmentSetup.onErrorPageInitialization(message);
            })
            .finally(() => {
                this.showProgress = false;
            });
    }

    _mapState(state) {
        const products = _.map(state.account.order.itemsByID);
        const sensors = _.map(state.system.devices.sensorsByID);

        return {
            products: products,
            sensors: sensors
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: FinalizeController
};