class KitCustomerService {
    /*@ngInject*/
    constructor(Restangular) {
        this.Restangular = Restangular;
    }

    createLeadAndQuote(leadContact) {
        return this.Restangular
            .one('kitCustomer')
            .all('quote')
            .withHttpConfig({
                cache: false
            })
            .post(leadContact);
    }

    setAddressAndCreateAccount(accountRequest) {
        return this.Restangular
            .one('kitCustomer')
            .all('account')
            .withHttpConfig({
                cache: false
            })
            .post(accountRequest);
    }

    fetchKitCustomer() {
        return this.Restangular
            .one('kitCustomer')
            .withHttpConfig({
                cache: false
            })
            .get();
    }

    beginOrderConfiguration() {
        return this.Restangular
            .one('kitCustomer')
            .all('order-configuration')
            .withHttpConfig({
                cache: false
            })
            .post();
    }
}

export default KitCustomerService;