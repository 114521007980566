import template from './loaderProgressOverlay.html';
import * as _ from 'lodash';

class LoaderProgressOverlayController {
    /*@ngInject*/
    constructor ($ngRedux, $q, $rootScope, constants, $interval) {
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.step = 1;
        this.phases = 4;
        this.intervalDelay = 15000;
        this.$rootScope = $rootScope;
        this.constants = constants;
        this.$interval = $interval;
        this.spinnerInterval = null;
    }

    $onInit() {
        this.unregister = this.$ngRedux.connect(this._mapState)(this);
        this.$rootScope.$on(this.constants.events.startTimer, (ev, args) => {
            this.step = 1;
            if(this.spinnerInterval)
                this.$interval.cancel(this.spinnerInterval);
            this.timer(args.isStart);
        });
    }

    timer(isStart) {
        if (isStart) {
            this.spinnerInterval = 
                this.$interval(() => {
                    if (this.step < 4) {
                        this.step += 1;
                    }
                }, this.intervalDelay);
        }
    }

    $onDestroy() {
        this.$interval.cancel(this.spinnerInterval);
        this.unregister();
    }

    _mapState(state) {
        return {
            isLoading: state.application.isLoading
        };
    }
}

export default {
template: template,
    bindings: {},
controller: LoaderProgressOverlayController
};