import * as angular from 'angular';
import 'angular-ui-router';

import './signage.scss';

import commonModule from '../common';
import menuModule from '../menu';

import signageAssemblyComponent from './assembly.component';
import signageCompleteComponent from './complete.component';
import signageDecalComponent from './decal.component';
import signageHomeComponent from './home.component';
import signagePlacementComponent from './placement.component';
import signageKeychainRemoteComponent from './keychainRemote.component';
import signagePanicPendantComponent from './panicPendant.component';

import signageAccessoriesComponent from './accessories.component';

export default angular
    .module('fp:activationswizard:signage', [
        'ui.router',
        commonModule.name,
        menuModule.name
    ])
    .config( /*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            .state('signage', {
                abstract: true,
                parent: 'secure',
                url: '/signage',
                template: '<ui-view></ui-view>',
                data: {
                    allowPostActivation: true,
                    next: /*@ngInject*/($state, appActions, orderActions, constants, actionContext) => {
                        if ($state.current.name == constants.routerStates.signageAccessories) {
                            const hasPanicPendant = actionContext.order.hasProduct('PP') || actionContext.order.hasProduct('RE103') ;
                            const hasKeychainRemote = actionContext.order.hasProduct('KR') || actionContext.order.hasProduct('TX-E101') || actionContext.order.hasProduct('FP100');

                            if (hasKeychainRemote)
                                $state.go(constants.routerStates.signageKeychainRemote);
                            else if (hasPanicPendant)
                                $state.go(constants.routerStates.signagePanicPendant);
                            else 
                                $state.go(constants.routerStates.signageHome);
                        } else {
                            appActions.completeModule(constants.sectionTypes.signage);
                            appActions.completeStep('signage');

                            //check if order has cameras or locks
                            if (actionContext.order.hasCamera() || (constants.enableLocksForInitialSetupWizard && actionContext.order.hasLock()))
                                $state.go(constants.routerStates.menuHome);
                            else
                                orderActions.hasAdditionalEquipment(false)
                                    .then((result) => {

                                        if (result)
                                            $state.go(constants.routerStates.airfxTunnel);
                                        else
                                            $state.go(constants.routerStates.menuHome);
                                    });
                        }
                    },
                    prev: /*@ngInject*/ ($state, constants, actionContext) => {
                        const hasPanicPendant = actionContext.order.hasProduct('PP') || actionContext.order.hasProduct('RE103') ;
                        const hasKeychainRemote = actionContext.order.hasProduct('KR') || actionContext.order.hasProduct('TX-E101') || actionContext.order.hasProduct('FP100');

                        switch ($state.current.name) {
                            case constants.routerStates.signagePanicPendant:
                                if (hasKeychainRemote)
                                    $state.go(constants.routerStates.signageKeychainRemote);
                                else
                                    $state.go(constants.routerStates.signageAccessories);
                                break;
                            case constants.routerStates.signageHome:
                                if (hasPanicPendant)
                                    $state.go(constants.routerStates.signagePanicPendant);
                                else if (hasKeychainRemote)
                                    $state.go(constants.routerStates.signageKeychainRemote);
                                else
                                    $state.go(constants.routerStates.menuHome);
                        }
                    },
                    sectionType: constants.sectionTypes.signage,
                    theme: 'app-theme-default-navbar-gray'
                },
                resolve: {
                    isSensorSetupComplete: /*@ngInject*/ (grant) => {
                        return grant.only({
                            test: 'isSensorSetupComplete',
                            state: 'sensor.home'
                        });
                    }
                }
            })
            .state('signage.home', {
                url: '',
                template: '<aw-signage-home></aw-signage-home>',
                onEnter: /*@ngInject*/ (appActions) => {
                    appActions.startModule(constants.sectionTypes.signage);
                }
            })
            .state('signage.assembly', {
                url: '/assembly',
                template: '<aw-signage-assembly></aw-signage-assembly>'
            })
            .state('signage.placement', {
                url: '/placement',
                template: '<aw-signage-placement></aw-signage-placement>'
            })
            .state('signage.decal', {
                url: '/decal',
                template: '<aw-signage-decal></aw-signage-decal>'
            })
            .state('signage.complete', {
                url: '/complete',
                template: '<aw-signage-complete></aw-signage-complete>',
                data: {
                    theme: 'app-theme-green'
                }
            })
            .state('signage.keychain-remote', {
                url: '/keychain-remote',
                template: '<aw-signage-keychain-remote></aw-signage-keychain-remote>',
                resolve: {
                    hasKeychainRemote: /*@ngInject*/ (grant, constants) => {
                        return grant.only({
                            test: 'hasKeychainRemote',
                            state: constants.routerStates.signagePanicPendant
                        });
                    }
                }
            })
            .state('signage.panic-pendant', {
                url: '/panic-pendant',
                template: '<aw-signage-panic-pendant></aw-signage-panic-pendant>',
                resolve: {
                    hasPanicPendant: /*@ngInject*/ (grant, constants) => {
                        return grant
                            .only({
                                test: 'hasPanicPendant',
                                state: constants.routerStates.signageHome
                            });
                    }
                }
            })
             .state('signage.accessories', {
                 url: '/accessories',
                 template: '<aw-signage-accessories></aw-signage-accessories>',
                 resolve: {
                     hasAccessories: /*@ngInject*/ (grant, constants) => {
                         return grant
                             .only({
                                 test: 'hasPanicPendantOrKeychain',
                                 state: constants.routerStates.signageHome
                             });
                     }
                 }
             });
    })
    .component('awSignageHome', signageHomeComponent)
    .component('awSignageAssembly', signageAssemblyComponent)
    .component('awSignagePlacement', signagePlacementComponent)
    .component('awSignageDecal', signageDecalComponent)
    .component('awSignageComplete', signageCompleteComponent)
    .component('awSignageKeychainRemote', signageKeychainRemoteComponent)
    .component('awSignagePanicPendant', signagePanicPendantComponent)
    .component('awSignageAccessories', signageAccessoriesComponent);