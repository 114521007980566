import * as _ from 'lodash';

import { STATES_FETCH_REQUEST, STATES_FETCH_FAILURE, STATES_FETCH_SUCCESS } from '../../action/app.actions';

const initialState = [];

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case STATES_FETCH_REQUEST:
    case STATES_FETCH_FAILURE:
        return state;
    case STATES_FETCH_SUCCESS:
        return action.payload.data;
    default:
        return state;
    }
};