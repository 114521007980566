import * as angular from 'angular';
import 'angular-ui-router';
import 'ui-router.grant';

export default angular
    .module('fp:activationswizard:zwave', [])
    .config( /*@ngInject*/ ($stateProvider) => {
        $stateProvider.state('zwave-completed', {
            template: '<div ui-view></div>',
            parent: 'sensor',
            url: '/zwave',
            resolve: {
                sensorsCompleted: /*@ngInject*/ ($stateParams, $window, $state, constants) => {
                    $window.parent.postMessage(constants.events.zWaveWizardCompleted, '*');
                }
            }
        });
    });