import moment from 'moment';
import template from './home.html';

class HomeController {
    /*@ngInject*/
    constructor($ngRedux, $state, $window, actionContext, constants, dataContext) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$window = $window;
        this.actionContext = actionContext;
        this.constants = constants;
        this.dataContext = dataContext;
    }

    $onInit() {
        this.dataContext.account.order.clearOrderIdToActivate();
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);

        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    onOrderClicked(order) {
        // Clear the applications state so previous activations do not interfere with the new activation.
        this.actionContext.login.clearState();
        // Set isFirstLoadComplete to false so resolves in the shell component are run again.
        this.$window.isFirstLoadComplete = false;
        this.$window.isAdditionalOrders = true;

        this.dataContext.account.order.setOrderIdToActivate(order.OrderId);
        this.$state.go('welcome.home');
    }

    formatDate(date) {
        return new moment(date).format('MMMM DD, YYYY');
    }

    _mapState(state) {
        const orders = state.account.order.delivered;
        const additionalOrders = _.filter(orders, x => !x.IsInitialOrder);

        return {
            orders: additionalOrders
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: HomeController
};