import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import './training.scss';

import commonModule from '../common';
import menuModule from '../menu';

import trainingVideoComponent from './videos.component';
import howToComponent from './howTo.component';
import armAway from './armAway.component';
import armStay from './armStay.component';
import disarmSystem from './disarmSystem.component';

export default angular
    .module('fp:activationswizard:training', [
        'ui.router',
        ngRedux,
        commonModule.name,
        menuModule.name
    ])
    .config( /*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            .state('training',
                {
                    abstract: true,
                    parent: 'secure',
                    url: '/training',
                    template: '<ui-view></ui-view>',
                    data: {
                        allowPostActivation: true,
                        next: /*@ngInject*/(appActions, $state, constants, $ngRedux) => {
                            if (!_.isNull($ngRedux.getState().account.user.isInitialOrder) &&
                                !$ngRedux.getState().account.user.isInitialOrder) {
                                appActions.completeModule(constants.sectionTypes.training);
                                appActions.completeStep('signage');
                                $state.go('review.home');
                            } else {
                                const isIq4Panel = $ngRedux.getState().account.user.isIq4Panel;
                                if (isIq4Panel) {
                                    $state.go('testmode.home');
                                } else {
                                    $state.go('usercode.home');
                                }                                
                            }
                        },
                        prev: /*@ngInject*/ (appActions, $ngRedux) => {
                            if (!_.isNull($ngRedux.getState().account.user.isInitialOrder) &&
                                !$ngRedux.getState().account.user.isInitialOrder) {
                                appActions.goToMenu();
                            } else {
                                $state.go('wifi.home');
                            }
                        },
                        allowHorizontal: true,
                        sectionType: constants.sectionTypes.training,
                        theme: 'app-theme-default-navbar-gray'
                    }
                })
            .state('training.home',
                {
                    url: '',
                    template: '<aw-videos></aw-videos>',
                    onEnter: /*@ngInject*/ (appActions) => {
                        appActions.startModule(constants.sectionTypes.training);
                    }
                })
            .state('training.how-to',
                {
                    url: '/how-to',
                    template: '<aw-how-to></aw-how-to>',
                    data: {
                        next: /*@ngInject*/($state, $ngRedux, actionContext, constants) => {
                            const accountId = $ngRedux.getState().account.user.accountId;

                            // list of skus in the order
                            const skus = Object.keys($ngRedux.getState().account.order.itemsBySku);

                            // check if the list of skus has a keypad
                            const hasKeypad = skus.some(r => constants.keypads.skus.indexOf(r) >= 0);

                            // check if order is initial
                            const isInitialOrder = $ngRedux.getState().account.user.isInitialOrder;

                            const lastDigit = accountId % 10;

                            // configurable value to route x % users to arm-disarm testing
                            const armDisarmTestingConfig = $ngRedux.getState().application.config.armDisarmTestingConfig;

                            // navigate x% users that have keypad on initial or combined order to arm/disarm panel training
                            if (lastDigit <= armDisarmTestingConfig && isInitialOrder && hasKeypad) {
                                actionContext.device.startArmDisarmTesting();
                                $state.go('training.arm-away');
                            } else {
                                $state.go('usercode.home');
                            }
                        },
                        sectionType: constants.sectionTypes.training,
                        persist: true,
                        theme: 'app-theme-default-navbar-gray'
                    }
                })
            .state('training.arm-away',
                {
                    url: '/arm-away',
                    template: '<aw-arm-away></aw-arm-away>',
                    data: {
                        next: /*@ngInject*/($state, $ngRedux, actionContext) => {
                            actionContext.device.updateArmDisarmPanelStatus('Panel armed in away mode.');
                            $state.go('training.disarm-system', { sourceLocation: $ngRedux.getState().router.currentState.name });
                        },
                        prev: /*@ngInject*/ ($state, $ngRedux) => {
                            const isIq4Panel = $ngRedux.getState().account.user.isIq4Panel;
                            if (isIq4Panel) {
                                $state.go('testmode.home');
                            } else {
                                $state.go('training.how-to');
                            }                            
                        },
                        sectionType: constants.sectionTypes.training,
                        persist: true,
                        theme: 'app-theme-default-navbar-gray'
                    },
                    params: { sourceLocation: null }
                })
            .state('training.disarm-system',
                {
                    url: '/disarm-system',
                    template: '<aw-disarm-system></aw-disarm-system>',
                    data: {
                        next: /*@ngInject*/($state, $stateParams, constants, $ngRedux, actionContext) => {

                            if ($stateParams.sourceLocation === constants.armDisarmNavigationParams.trainingArmAway ||
                                $stateParams.sourceLocation === constants.armDisarmNavigationParams.trainingArmStayBack ||
                                $ngRedux.getState().router.prevParams.sourceLocation === constants.armDisarmNavigationParams.trainingArmAway ||
                                $ngRedux.getState().router.prevParams.sourceLocation === constants.armDisarmNavigationParams.trainingArmStayBack) {
                                actionContext.device.updateArmDisarmPanelStatus('Panel disarmed.');
                                $state.go('training.arm-stay', { sourceLocation: $ngRedux.getState().router.currentState.name });
                            } else {
                                const isIq4Panel = $ngRedux.getState().account.user.isIq4Panel;
                                if (isIq4Panel) {
                                    $state.go('instruction.bluetooth');
                                } else {
                                    actionContext.device.updateArmDisarmPanelStatus('Panel disarmed.');
                                    actionContext.device.completeArmDisarmTesting();
                                    $state.go('usercode.home', { sourceLocation: $ngRedux.getState().router.currentState.name });
                                }                                
                            }
                        },
                        sectionType: constants.sectionTypes.training,
                        persist: true,
                        theme: 'app-theme-default-navbar-gray'
                    },
                    params: { sourceLocation: null }
                })
            .state('training.arm-stay',
                {
                    url: '/arm-stay',
                    template: '<aw-arm-stay></aw-arm-stay>',
                    data: {
                        next: /*@ngInject*/($state, $ngRedux, actionContext) => {
                            actionContext.device.updateArmDisarmPanelStatus('Panel armed in stay mode.');
                            $state.go('training.disarm-system', { sourceLocation: $ngRedux.getState().router.currentState.name + '-continue' });
                        },
                        prev: /*@ngInject*/ ($state, $ngRedux) => {
                            $state.go('training.disarm-system', { sourceLocation: $ngRedux.getState().router.currentState.name + '-back' });
                        },
                        sectionType: constants.sectionTypes.training,
                        persist: true,
                        theme: 'app-theme-default-navbar-gray'
                    },
                    params: { sourceLocation: null }
                });
    })
    .component('awVideos', trainingVideoComponent)
    .component('awHowTo', howToComponent)
    .component('awArmAway', armAway)
    .component('awArmStay', armStay)
    .component('awDisarmSystem', disarmSystem);