let OnLoadIframeDirective = () => {
    'ngInject';
    return {
        restrict: 'A',
        link: (scope, element, attrs) => {
            element.on('load', () => {
                scope.$apply(attrs.awOnLoadIframe);
            });
        }
    };
};

export default OnLoadIframeDirective;