import template from './appStoreBadge.html';

class AppStoreBadgeController {
    /*@ngInject*/
    constructor (mobileDetectService) {
        this.storeUrl = `//${mobileDetectService.storeUrl()}`;
        this.storeBadge = mobileDetectService.storeBadge();
    }
}

export default {
    template: template,
    bindings: {},
    controller: AppStoreBadgeController
};