import './activation.scss';
import template from './activation.html';
import moment from 'moment';

class SensorActivateSystemController {
    /*@ngInject*/
    constructor(constants, $state, actionContext, $q, dataContext, $log, appActions) {
        this.constants = constants;
        this.isLoading = false;
        this.actionContext = actionContext;
        this.$state = $state;
        this.$q = $q;
        this.dataContext = dataContext;
        this.$log = $log;
        this.appActions = appActions;
    }

    $onInit() {
        this.isLoading = true;
        const inTestMode = false;
        if (this.actionContext.app.isAdditional() && !this.actionContext.app.isInitialWithAdditional()) {
            return this.$q.resolve().then(() => {
                this.$state.go(this.constants.routerStates.sensorComplete);
            });
        } else {
            return this._activateAccount()
                .then(() => {
                    return this.actionContext.testmode.updateEndDate(moment.utc().toDate(), false);
                }).finally(() => {
                    this.isLoading = false;
                });
        }
    }

    onFinishUp() {
        this.appActions.completeStep('sensor');
        this.$state.go('menu.home');
    }

    _activateAccount() {
        this.actionContext.common.start(this.constants.actionTypes.start, this.constants.sectionTypes.completed);
        return this.actionContext.account.activateAccount().catch((err) => {
            this.actionContext.common.error(this.constants.actionTypes.error, this.constants.sectionTypes.completed, err);
            this.$state.go('error');
            return this.$q.reject(err);
        });
    }
}

export default {
    template: template,
    bindings: {},
    controller: SensorActivateSystemController
};