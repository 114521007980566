import template from './panicPendant.html';
import * as _ from 'lodash';

class PanicPendantController {
    /*@ngInject*/
    constructor(constants, $ngRedux, $stateParams, $state, actionContext) {
        this.constants = constants;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.actionContext = actionContext;
        this.sensor = $stateParams.sensor;
            this._ = _;
        this.productImage = '';
        this.images = {
            old: {
                src: '/Content/images/illustrations/illu_panicPendant.svg'
            },
            new: {
                src: '/Content/images/illustrations/illu_panicPendant2.png'
            }
        }
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.getProductSku();
    }

    $onDestroy() {
        this.unsubscribe();
    }

    getProductSku() {
        return (this._.find(this.$ngRedux.getState().system.devices.sensorsByID, { ProductSku: 'RE103' })) ? this.productImage = this.images.new : this.productImage = this.images.old;
    }

    goToNext() {
        this.$state.go('sensor.panic-pendant-overview');
    }

    _mapState(state) {
        return {
            deviceSelected: state.system.devices.sensorsByID[state.system.devices.selectedSensorID]
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: PanicPendantController
};