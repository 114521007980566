class CodeDataService {
    /*@ngInject*/
    constructor(Restangular) {
        this.Restangular = Restangular;
    }

    getUserCodes() {
        return this.Restangular
            .all('code')
            .customGET()
            .then((response) => {
                return response.plain();
            });
    }
  
    putMasterCode(masterCode) {
        return this.Restangular
            .all('code')
            .one('mastercode')
            .customPUT(masterCode)
            .then((response) => {
                return response.plain();
            });
    }

    postUserCode(userCodeRequest) {
        return this.Restangular
         .all('code')
         .one('usercode')
         .customPOST(userCodeRequest)
         .then((response) => {
             return response.plain();
         });
    }

    putUserCode(userCodeRequest) {
        return this.Restangular
         .all('code')
         .one('usercode')
         .customPUT(userCodeRequest)
         .then((response) => {
             return response.plain();
         });
    }
}

export default CodeDataService;