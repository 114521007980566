import moment from 'moment';
import * as _ from 'lodash';
import template from './paymentMethod.html';

class PaymentMethodController {
    /*@ngInject*/
    constructor($ngRedux, $state, constants, actionContext, SharedState, $q, $log) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.actionContext = actionContext;
        this.SharedState = SharedState;
        this.$q = $q;
        this.$log = $log;

        this.creditCards = [
            { CreditCardType: 'visa', curClass: 'fpDisabled' },
            { CreditCardType: 'amex', curClass: 'fpDisabled' },
            { CreditCardType: 'mastercard', curClass: 'fpDisabled' },
            { CreditCardType: 'discover', curClass: 'fpDisabled' }
        ];

        this.ccExpMonths = Array.from({ length: 12 }, (v, i) => `0${i + 1}`.slice(-2));
        this.years = this.getCreditCardYears();

        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
    }

    $onInit() {
        this.actionContext.customerBillingInfo.getCustomerBillingInfo();
    }

    $onDestroy() {
        this.actionContext.customerBillingInfo.resetState();
        this.unsubscribe();
    }

    getCreditCardYears() {
        const currentDateYear = 2000 + ((new Date()).getYear() - 100);
        return Array.from({ length: 10 }, (v, i) => currentDateYear + i);
    }

    getCardType(cardNumber) {
        let result = 'unknown';

        if ((this.constants.regex.mastercard).test(cardNumber)) {
            result = 'mastercard';
        } else if ((this.constants.regex.visa).test(cardNumber)) {
            result = 'visa';
        } else if ((this.constants.regex.amex).test(cardNumber)) {
            result = 'amex';
        } else if ((this.constants.regex.discover).test(cardNumber)) {
            result = 'discover';
        }

        return result;
    }

    getSelectedCardType() {
        return this.getCardType(this.CardNumber);
    }

    toggleIsCredit() {
        this.actionContext.customerBillingInfo.toggleIsCredit();
    }

    startLoading() {
        this.actionContext.customerBillingInfo.startLoading();
    }

    stopLoading() {
        this.actionContext.customerBillingInfo.stopLoading();
    }

    doesDuplicateExists(localCardNumber, localAccountNumber, localRoutingNumber) {
        const { LastFour, RoutingNumber, customerBillingInfoID, PaymentTypeIsCredit } = this;

        // If there is not cbiId then short circuit
        if (!customerBillingInfoID) {
            return false;
        }

        // Short-circuit if customer is switching to payment methods
        if (this.IsCredit !== PaymentTypeIsCredit) {
            return false;
        }
        
        // Compare last four credit card numbers
        if (this.IsCredit && localCardNumber) {
            return LastFour === localCardNumber.slice(-4);
        // Compare last four of account and routing number
        } else if (localAccountNumber && localRoutingNumber) {
            return localAccountNumber.toString().slice(-4) === LastFour && localRoutingNumber.toString().slice(-4) === RoutingNumber.slice(-4);
        // If nothing matches, its a dup
        } else {
            return true;
        }
    }

    validateAccountNumber(form) {
        const accountNumber = form.AccountNumber.$modelValue;
        const confirmAccountNumber = form.ConfirmAccountNumber.$modelValue;

        if (accountNumber != confirmAccountNumber) {
            form.AccountNumber.$setValidity('valid', false);
            form.ConfirmAccountNumber.$setValidity('valid', false);
        }
        else {
            form.AccountNumber.$setValidity('valid', true);
            form.ConfirmAccountNumber.$setValidity('valid', true);
        }
    }

    validateExpDate(form) {
        const selectedMonth = form.CardExpirationMonth.$modelValue && parseInt(form.CardExpirationMonth.$modelValue);
        const selectedYear = form.CardExpirationYear.$modelValue;

        const currentYear = moment().year();
        const currentMonth = moment().month();

        if (selectedMonth && currentYear === selectedYear && currentMonth >= selectedMonth) {
            form.CardExpirationMonth.$setPristine();
            form.CardExpirationMonth.$setDirty();
        }

        const monthAdj = selectedYear === currentYear ? currentMonth + 1 : 0;
        this.ccExpMonths = Array.from({ length: 12 - monthAdj }, (v, i) => `0${i + 1 + monthAdj}`.slice(-2));
    }

    alignState(formData) {
        let localFirstName = null;
        let localLastName = null;
        if (this.IsCredit) {
            let localCardholderName = formData.CardholderName.$modelValue.split(" ");
            localLastName = localCardholderName[localCardholderName.length - 1];
            localFirstName = localCardholderName.slice(0, localCardholderName.length - 1).toString().replace(/,/g, ' ');
        }
        const {
            FirstName,
            LastName,
            AccountNumber,
            RoutingNumber,
            CardNumber,
            CardExpirationMonth,
            CardExpirationYear,
            CardholderName
        } = formData;
        this.actionContext.customerBillingInfo.alignState({
            FirstName: this.IsCredit ? localFirstName : FirstName && FirstName.$modelValue,
            LastName: this.IsCredit ? localLastName : LastName && LastName.$modelValue,
            AccountNumber: AccountNumber && AccountNumber.$modelValue,
            RoutingNumber: RoutingNumber && RoutingNumber.$modelValue,
            CardNumber: CardNumber && CardNumber.$modelValue,
            CardExpirationMonth: CardExpirationMonth && CardExpirationMonth.$modelValue,
            CardExpirationYear: CardExpirationYear && CardExpirationYear.$modelValue,
            AccountName: CardholderName && CardholderName.$modelValue
        });
    }

    onSave(form) {
        this.startLoading();

        // Check user entered values against autofilled values from state
        const localCardNumber = form.CardNumber && form.CardNumber.$modelValue;
        const localAccountNumber = form.AccountNumber && form.AccountNumber.$modelValue;
        const localRoutingNumber = form.RoutingNumber && form.RoutingNumber.$modelValue;
        const isDup = this.doesDuplicateExists(localCardNumber, localAccountNumber, localRoutingNumber);

        // Short circuit for dups
        if (isDup) {
            this.isAmazonUser || this.isFeedonomicOrder ? this.$state.go('account.contract') : this.$state.go('predispatch.home');
            return;
        }


        // Re-align redux state with user entered info
        this.alignState(form);

        const newcbi = this.configureAddNewCBI();

        this.actionContext.customerBillingInfo.saveCustomerBillingInfo(newcbi, this.accountId).then(() => {
            this.isAmazonUser || this.isFeedonomicOrder ? this.$state.go('account.contract') : this.$state.go('predispatch.home');
            return;
        }).catch(() => {
            this.isAmazonUser || this.isFeedonomicOrder ? this.$state.go('account.contract') : this.$state.go('predispatch.home');
                return;
            });
    }

    configureAddNewCBI() {
        return {
            CustomerBillingInfoID: 0,
            UserCustomerID: null,
            ContactID: 0,
            AccountNumber: this.IsCredit ? this.CardNumber : this.AccountNumber,
            RoutingNumber: this.RoutingNumber,
            VerificationNumber: null,
            IsCredit: this.IsCredit,
            DateCreated: null,
            CreatedByID: 0,
            IsActive: true,
            IsDeleted: false,
            PaymentDay: null,
            CCExp: this.IsCredit ? `${`0${this.CardExpirationMonth}`.slice(-2)}/${this.CardExpirationYear.toString().slice(-2)}` : null,
            CreditCardType: this.getSelectedCardType(),
            CheckType: this.IsCredit ? null : 'P',
            CheckAccountType: this.IsCredit ? null : 'C',
            LeadID: null,
            LastFour: this.IsCredit ? this.CardNumber.slice(-4) : this.AccountNumber.toString().slice(-4),
            AccountId: this.accountId,
            NickName: '',
            AddressID: 0,
            IsDefaultPayment: false,
            IsDefaultRmr: false,
            Line1: this.addressLine1,
            Line2: this.addressLine2,
            City: this.addressCity,
            State: this.addressState,
            PostalCode: this.addressPostalCode,
            Attention: null,
            FirstName: this.FirstName,
            LastName: this.LastName,
            AccountName: this.IsCredit ? this.AccountName : `${this.FirstName} ${this.LastName}`,
            phoneNumber: this.phone
        };
    }

    _mapState(state) {
        return {
            ...state.account.customerBillingInfo,
            accountId: state.account.kitCustomer.accountId && state.account.kitCustomer.accountId,
            phone: state.account.user.isAmazonUser ? state.account.kitCustomer.contact.Phone : state.account.customer.value.Phone,
            isAmazonUser: state.account.user.isAmazonUser,
            addressLine1: state.account.user.isAmazonUser ? state.account.kitCustomer.address.AddressLine1 : state.account.premises.value.AddressLine1,
            addressLine2: state.account.user.isAmazonUser ? state.account.kitCustomer.address.AddressLine2 : state.account.premises.value.AddressLine2,
            addressCity: state.account.user.isAmazonUser ? state.account.kitCustomer.address.City : state.account.premises.value.City,
            addressState: state.account.user.isAmazonUser ? state.account.kitCustomer.address.State : state.account.premises.value.State,
            addressPostalCode: state.account.user.isAmazonUser ? state.account.kitCustomer.address.PostalCode : state.account.premises.value.PostalCode,
            isFeedonomicOrder: state.account.order.isFeedonomicOrder
        }
    }
}

export default {
    template: template,
    bindings: {},
    controller: PaymentMethodController
};