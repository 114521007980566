import template from './progressArc.html';

class ProgressComponent {
    /*@ngInject*/
    constructor($ngRedux ,dataContext, appActions, $rootScope, constants, actionContext) {
        this.$ngRedux = $ngRedux;
        this.dataContext = dataContext;
        this.appActions = appActions;
        this.actionContext = actionContext;

        this.showProgress = true;

        // setup
        this.unsubscribe =  $ngRedux.connect(this._mapState.bind(this))(this);
        this.isMobileApp = this.dataContext.user.isInMobileApp();

        $rootScope.$on(constants.events.stateChangeSuccess, () => {
            this.progress = this._calculateProgress(this.currentState.name);
            this.actionContext.order.setProgress(this.progress);
        });
    }

    $onInit() {
        this.progress = this._calculateProgress(this.currentState.name);
        this.actionContext.order.setProgress(this.progress);
    }

    _calculateProgress(stateName) {
        let isInitialOrderWithAdditionalEquipment =  this.appActions.isInitialWithAdditional();

        if (this.isInitialOrder && !isInitialOrderWithAdditionalEquipment && !this.isMobileApp) {
            this.showProgress = true;
            if (_.startsWith(stateName, 'welcome'))
                return 0.03;
            else if (_.startsWith(stateName, 'boxcheck'))
                return 0.06;
            else if (_.startsWith(stateName, 'instruction'))
                return 0.09;
            else if (_.startsWith(stateName, 'premises'))
                return 0.12;
            else if (_.startsWith(stateName, 'predispatch'))
                return 0.15;
            else if (_.startsWith(stateName, 'password'))
                return 0.18;
            else if (_.startsWith(stateName, 'panel'))
                return 0.21;
            else if (_.startsWith(stateName, 'mastercode'))
                return 0.24;
            else if (_.startsWith(stateName, 'usercode'))
                return 0.27;
            else if (_.startsWith(stateName, 'wifi'))
                return 0.30;
            else if (_.startsWith(stateName, 'sensor') && !_.startsWith(stateName, 'sensor.cameras') && !_.startsWith(stateName, 'sensor.locks') && !_.startsWith(stateName, 'sensor.activation'))
                return this._calculateSensorProgress(isInitialOrderWithAdditionalEquipment);
            else if (_.startsWith(stateName, 'signage'))
                return 0.86;
            else if (_.startsWith(stateName, 'sensor.activation'))
                return this.progress;
            else if (_.startsWith(stateName, 'sensor.cameras') || _.startsWith(stateName, 'sensor.locks'))
                return 0.88;
            else if (_.startsWith(stateName, 'airfx'))
                return 0.90;
            else if (_.startsWith(stateName, 'training'))
                return 0.93;
            else if (_.startsWith(stateName, 'review') || _.startsWith(stateName, 'testmode'))
                return 0.95;
            else if (_.startsWith(stateName, 'menu') || (_.startsWith(stateName, 'saved')))
                return this.progress;

        } else if (this.isInitialOrder && isInitialOrderWithAdditionalEquipment && !this.isMobileApp) {
            this.showProgress = true;
    
            if (_.startsWith(stateName, 'airfx'))
                return 0.90;
            else if (_.startsWith(stateName, 'sensor') && !_.startsWith(stateName, 'sensor.cameras') && !_.startsWith(stateName, 'sensor.locks') && !_.startsWith(stateName, 'sensor.activation'))
                return this._calculateSensorProgress(isInitialOrderWithAdditionalEquipment);
            else if (_.startsWith(stateName, 'sensor.cameras') || _.startsWith(stateName, 'sensor.locks'))
                return 0.98;
            else if (_.startsWith(stateName, 'training'))
                return 0.98;
            else if (_.startsWith(stateName, 'menu'))
                return this.progress;

        } else if (this.isMobileApp) 
            this.showProgress = false;
    }

    _calculateSensorProgress(isInitialOrderWithAdditionalEquipment) {
        let totalDevices = 0;
        let firstDeviceId = 0;

        //need to figure out which sensor position we are on based on the sensor id
        let currentSensorPosition = 1;
        _.forEach(this.devices, (device) => {
            if (device.SensorID === this.currentSensorNum)
                return false;

            currentSensorPosition++;
        });

        //need to figure out total devices
        _.forEach(this.devices, (device) => {
            if (firstDeviceId === 0)
                firstDeviceId = device.SensorID;

            totalDevices++;
        });

        let sensorNum = currentSensorPosition;
        //case for intial with additional
        if (this.isInitialOrder && isInitialOrderWithAdditionalEquipment && !this.isMobileApp) {
            //case for initial
            switch (this.currentState.name) {
                case 'sensor.home' :
                    return 0.90;
                case 'sensor.list':
                case 'sensor.activation':
                case 'sensor.cameras-home':
                case 'sensor.cameras-instructions':
                    return this.progress;
                case 'sensor.locks-home':
                    return this.progress;
                case 'sensor.review' :
                case 'sensor.complete':
                case 'sensor.skipped-review':
                    return 0.98;
            }

            let eachStep =  8 / totalDevices;
            //need to offset since additional do not start with 1

            if (!_.isNil(this.currentSensorNum)) {
                let step = 0.90 + ((eachStep * sensorNum) / 100);
                return step;
            } 
        } else {
            //case for initial
            switch (this.currentState.name) {
                case 'sensor.home' :
                    return 0.30;
                case 'sensor.list':
                case 'sensor.cameras-instructions':
                case 'sensor.locks-home':
                case 'sensor.cameras-home':
                    return this.progress;
                case 'sensor.review' :
                case 'sensor.complete':
                case 'sensor.activation':
                case 'sensor.skipped-review':
                    return 0.80;
            }

            let eachStep =  50 / totalDevices;

            if (!_.isNil(this.currentSensorNum)) {
                let step = 0.30 + ((eachStep * sensorNum) / 100);
                return step;
            } 
        }

        return 0;
    }

    $onDestroy() {
        this.unsubscribe();
    }

    _mapState(state) {
        return {
            devices: this.actionContext.device.getOrderedSensors(),
            currentState: state.router.currentState,
            isInitialOrder: state.account.user.isInitialOrder,
            currentSensorNum: state.router.currentParams.sensorId,
            progress: _.get(state, 'account.order.progress', 0)
        };
    }
}

export default {
template: template,
    bindings: {
        theme: '<'
    },
controller: ProgressComponent
};