import * as _ from 'lodash';
import moment from 'moment';

export default function ($log, $window, dataContext, constants, $state) {
    'ngInject';
    return store => next => action => {
        let oldState = store.getState();
        let returnedValue = next(action);
        let newState = store.getState();

        if (constants.disableStateDehydration === true) {
            $log.debug(`State dehydration has been disabled. Skipping state dehydration.`);
            return returnedValue;
        }
        if (!shouldPersist(action, newState)) {
            return returnedValue;
        }

        $log.debug(`Dehydrating application state after action [${action.type}]...`);
        const persistedState = generatePersistedState(action, newState, $state);

        // log state change to event hubs
        dataContext.logging.logActivity(persistedState);

        // short circuit if state is not hydratable
        if (!persistedState.IsHydratable) {
            $log.debug(`State is not hydratable. Skipping state dehydration.`);
            return returnedValue;
        }

        // short circuit if request would be invalid
        const validState = checkStateValidity(persistedState);
        if (!validState) {
            $log.debug("State is not dehydratable. Skipping state dehydration");
            return returnedValue;
        }

        // save state
        dataContext.application.state.updateCurrentState(persistedState)
            .then(() => {
                $log.debug(`Successfully dehydrated application state after action [${action.type}].`);
            })
            .catch((error) => {
                $log.error(`Error dehydrating application state after action [${action.type}].`, error);
                if (error.statusText === 'Conflict') {
                    $log.error('Impersonator has control');
                    $state.go('inProgress');
                }
            });

        return returnedValue;
    };

    /**
     * Generate the state object that is persisted to the server.
     * 
     * @param {Object} action 
     * @param {Object} state 
     * @returns {Object} 
     */
    function generatePersistedState(action, state, $state) {
        if (_.isNil(action)) {
            throw 'Action cannot be null or undefined.';
        }

        if (_.isNil(state)) {
            throw 'State cannot be null or udefined.';
        }

        let stateCopy = _.cloneDeep(state);
        purgeState(stateCopy);

        let metadata = extractMetadata(action);

        let actionType = extractActionType(action);
        let isInMobileApp = state.account.user.isInMobileApp;

        // add amazon properties
        const IsAmazon = state.account && state.account.user ? state.account.user.isAmazonUser : null;
        const KitAccountId = state.account && state.account.kitCustomer ? state.account.kitCustomer.accountId : null;
        const KitOrderId = state.account && state.account.kitCustomer ? state.account.kitCustomer.orderId : null;

        return {
            DateCreatedUTC: moment().toISOString(),
            Action: action.type,
            Current: JSON.stringify(stateCopy),
            Description: extractDescription(action),
            ActionType: extractActionType(action),
            SectionType: extractSectionType(action),
            Version: constants.appInfo.version,
            IsHydratable: !_.isNil(metadata) 
                && !_.isNil(metadata.isHydratable) 
                && metadata.isHydratable === false 
                || (actionType === constants.actionTypes.stateChange 
                    && $state.$current.data.isHydratable === false) ? false : true,
            OrderType: extractOrderType(state),
            Url: $window.location.href,
            IsApp: isInMobileApp,
            IsAmazon,
            KitAccountId,
            KitOrderId
        };
    }

    function checkStateValidity(persistedState) {
        const { IsAmazon, KitAccountId, KitOrderId, IsHydratable } = persistedState;

        // validate amazon
        const kitAccountIsValid = KitAccountId !== null && KitAccountId !== undefined && KitAccountId !== 0;
        const kitOrderIsValid = KitOrderId !== null && KitOrderId !== undefined && KitOrderId !== 0;
        const amazonIsValid = kitAccountIsValid && kitOrderIsValid;

        return IsAmazon ? amazonIsValid : IsHydratable;
    }

    // !_.isNil(action.description) ? action.description : null
    /**
     * Extract the description from the action if it exists. If no
     * description exists the default description is returned.
     * 
     * @param {Object} action 
     * @returns {String} 
     */
    function extractDescription(action) {
        const defaultDescription = null;

        if (_.isNil(action)) {
            return defaultDescription;
        }

        let metadata = extractMetadata(action);
        if (_.isNil(metadata)) {
            return defaultDescription;
        }

        let description = metadata.description;
        return !_.isNil(description) ?
            description :
            defaultDescription;
    }

    // !_.isNil(action.actionType) ? action.actionType  : constants.actionTypes.other
    /**
     * Extract the action type from the action if it exists. If
     * no action type exists the default action type is returned.
     * 
     * @param {Object} action 
     * @returns {Number} 
     */
    function extractActionType(action) {
        const defaultActionType = constants.actionTypes.other;

        if (_.isNil(action)) {
            return defaultActionType;
        }

        let metadata = extractMetadata(action);
        if (_.isNil(metadata)) {
            return defaultActionType;
        }

        let actionType = metadata.actionType;
        if (_.isNil(actionType)) {
            if (action.type === '@@reduxUiRouter/$stateChangeSuccess') {
                return constants.actionTypes.stateChange;
            }

            return defaultActionType;
        }

        return actionType;
    }

    // !_.isNil(action.payload) && !_.isNil(action.payload.currentState) && !_.isNil(action.payload.currentState.sectionType) ? action.payload.currentState.sectionType : !_.isNil(action.sectionType) ? action.sectionType : 0
    /**
     * Extract the section type from the action if it exists. If no
     * section types exists the default section type is returned.
     * 
     * @param {Object} action 
     * @returns {Number} 
     */
    function extractSectionType(action) {
        const defaultSectionType = '';

        if (_.isNil(action)) {
            return defaultSectionType;
        }

        let metadata = extractMetadata(action);
        if (_.isNil(metadata)) {
            return defaultSectionType;
        }

        let sectionType = metadata.sectionType;
        return !_.isNil(sectionType) ?
            sectionType :
            defaultSectionType;
    }

    /**
     * Extract the metadata object from the action if it exists. If no
     * metadata object exists null is returned.
     * 
     * @param {Object} action 
     * @returns {Object} 
     */
    function extractMetadata(action) {
        if (_.isNil(action)) {
            return null;
        }
        if (!_.isNil(action.payload) &&
            !_.isNil(action.payload.currentState) &&
            !_.isNil(action.payload.currentState.data)) {
            return action.payload.currentState.data;
        }

        if (!_.isNil(action.metadata)) {
            return action.metadata;
        }

        return null;
    }

    /**
     * Recursively purge the state object of all ignored properties.
     * 
     * @param {Object} state 
     * @returns {} 
     */
    function purgeState(state) {
        if (_.isNil(state) || _isLeaf(state)) {
            return;
        }

        let ignoreList = state.ignore;
        delete state.ignore;

        for (var prop in state) {
            if (!state.hasOwnProperty(prop)) {
                continue;
            }

            if (!_.isNil(ignoreList) && ignoreList.indexOf(prop) > -1) {
                delete state[prop];
            } else {
                purgeState(state[prop]);
            }
        }
    }

    /**
     * Returns true if the state should be persisted, otherwise false.
     * 
     * @param {Object} action 
     * @param {Object} state 
     * @returns {Boolean} 
     */
    function shouldPersist(action, state) {
        let shouldPersist;

        if (_.includes(action.type, constants.events.stateChangeSuccess)) { // route state change
            shouldPersist = (state.router.currentState.abstract !== true) && // skip abstract states
                            !_.isEmpty(state.router.currentState.name) && // skip empty states
                            (_.isNil(state.router.currentState.data) || state.router.currentState.data.persist !== false); // disable route state perist with data.persist = false
        } else { // regular action
            let metadata = extractMetadata(action);
            shouldPersist = (!_.isNil(metadata) && metadata.persist === true); // default persist only route state changes unless action.persist === true
        }

        return shouldPersist;
    }

    function _isLeaf(node) {
        return _.isString(node) || _.isNumber(node) || _.isDate(node) || moment.isMoment(node);
    }

    /**
     * Finds the order type for metrics based on the isInitialOrder flag
     * Will extend this to have replacement when the time comes
     * 
     * @param {Object} state 
     * @returns {String} 
     */
    function extractOrderType(state) {

        if (_.isNil(state.account)) 
            return 'Unknown';

        if (_.isNil(state.account.user)) 
            return 'Unknown';

        return state.account.user.isInitialOrder === true ? 'Initial' : 'Additional';
    }
}