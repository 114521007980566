import template from './imageInstallationModal.html';

class ImageInstallationModalController {
    /*@ngInject*/
    constructor () {
    }
}

export default {
    template: template,
    bindings: {},
    controller: ImageInstallationModalController
};