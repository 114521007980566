import * as _ from 'lodash';
import * as angular from 'angular';
import * as duScroll from 'angular-scroll';
import ngRedux from 'ng-redux';
import 'angular-ui-router';
import 'angular-http-auth';
import 'ui-router.grant';

import './activation.scss';

import commonModule from '../common';

import activationCompleteComponent from './complete.component';
import activationReturnComponent from './return.component';
import ActionContext from '@app/common/action/actionContext.service';

export const activationModule = angular
    .module('fp:activationswizard:activation', [
        'ui.router',
        'ui.router.grant',
        'http-auth-interceptor',
        duScroll,
        ngRedux,
        commonModule.name
    ])
    .config( /*@ngInject*/ ($stateProvider: any, constants: any) => {
        $stateProvider
            .state('activation', {
                parent: 'secure',
                template: '<ui-view></ui-view>',
                data: {
                    allowPostActivation: true,
                    persist: false,
                    sectionType: constants.sectionTypes.activation,
                    theme: 'app-theme-default-navbar-gray'
                }
            })
            .state('activation.complete', {
                url: '/complete',
                template: '<aw-activation-complete></aw-activation-complete>'
            })
            .state('activation.return', {
                url: '/activated',
                template: '<aw-activation-return></aw-activation-return>',
                data: {
                    allowPostActivation: true
                },
                resolve: {
                    stopLoad: /*@ngInject*/ (actionContext: ActionContext) => {
                        actionContext.app.tryStopLoad();
                    }
                }
            });
    })
    .component('awActivationComplete', activationCompleteComponent)
    .component('awActivationReturn', activationReturnComponent);
