import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import KeyChainRemoteComponent from './keychainRemote.component';
import KeyChainRemoteOverviewComponent from './keychainRemoteOverview.component';
import PanicPendantComponent from './panicPendant.component';
import PanicPendantOverviewComponent from './panicPendantOverview.component';
import commonModule from '../../common';

export default angular
.module('fp:activationswizard:sensor:exceptionDevice', [
    commonModule.name
])
    .component('awKeychainRemote', KeyChainRemoteComponent)
    .component('awKeychainRemoteOverview', KeyChainRemoteOverviewComponent)
    .component('awPanicPendant', PanicPendantComponent)
    .component('awPanicPendantOverview', PanicPendantOverviewComponent);