import * as _ from 'lodash';
import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';
import 'angular-ui-mask';
import 'angular-http-auth';
import 'angular-recaptcha';
import './login.scss';

import commonModule from '../common';

import loginComponent from './login.component';
import activateComponent from './activate.component';
import setupActivationCodeComponent from './setupActivationCode.component';
import setupHubSidComponent from './setupHubSid.component';
import getStartedModalComponent from './modals/getStartedModal.component';

export default angular
    .module('fp:activationswizard:login', [
        'ui.router',
        'http-auth-interceptor',
        'ui.mask',
        'vcRecaptcha',
        ngRedux,
        commonModule.name
    ])
    .config( /*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            .state('login', {
                parent: 'root',
                redirectTo: 'activate',
                template: '<ui-view></ui-view>',
                data: {
                    navbar: {
                        top: false
                    },
                    persist: false,
                    sectionType: constants.sectionTypes.login,
                    theme: 'app-theme-default-navbar-green'
                },
                resolve: {
                    clearUserSession: /*@ngInject*/ ($q, $ngRedux, actionContext, dataContext) => {
                        let isAuthenticated = $ngRedux.getState().account.user.isAuthenticated;
                        return isAuthenticated
                            ? actionContext.login.logout()
                            : $q.when(dataContext.user.reset());
                    },
                    getActivationCodeFromUrl: /*@ngInject*/ ($stateParams, actionContext) => {
                        if (!_.isEmpty($stateParams.use)) {
                            actionContext.login.setActivationCode($stateParams.use);
                        }
                    }
                }
                
            })
            .state('sso', {
                url: '/sso/{token:string}?app&isBrowser',
                parent: 'root',
                resolve: {
                    startLoad: /*@ngInject*/ (actionContext) => {
                        actionContext.app.tryStartLoad();
                    },
                    processSingleSignOn: /*@ngInject*/ ($stateParams, actionContext) => {
                        let isApp = false;
                        let isBrowser = false;
                        let appParam = $stateParams.app;
                        let isBrowserParam = $stateParams.isBrowser;

                        if (!_.isNil(appParam)) {
                            isApp = _.isArray(appParam)
                                ? _.some(appParam, (p) => { return p === 'true'; })
                                : appParam === 'true';
                        }

                        if (!_.isNil(isBrowserParam)) {
                            isBrowser = _.isArray(isBrowserParam)
                              ? _.some(isBrowserParam, (p) => { return p === 'true'; })
                              : isBrowserParam === 'true';
                        }

                        return actionContext.login.processSingleSignOn($stateParams.token, isApp, isBrowser);
                    }
                }
            })
            .state('sso.auto', {
                url: '/sso?app&isBrowser',
                parent: 'root',
                resolve: {
                    startLoad: /*@ngInject*/ (actionContext) => {
                        actionContext.app.tryStartLoad();
                    },
                    processSingleSignOn: /*@ngInject*/ ($stateParams, actionContext, dataContext) => {
                        let isApp = false;
                        let isBrowser = false;
                        let appParam = $stateParams.app;
                        let isBrowserParam = $stateParams.isBrowser;

                        if (!_.isNil(appParam)) {
                            isApp = _.isArray(appParam)
                                ? _.some(appParam, (p) => { return p === 'true'; })
                                : appParam === 'true';
                        }

                        if (!_.isNil(isBrowserParam)) {
                            isBrowser = _.isArray(isBrowserParam)
                                ? _.some(isBrowserParam, (p) => { return p === 'true'; })
                                : isBrowserParam === 'true';
                        }

                        const token = dataContext.user.loadAccessToken();
                        return actionContext.login.processSingleSignOn(token, isApp, isBrowser);
                    }
                }
            })
            .state('setup', {
                url: '/setup?use&reset',
                template: '<aw-login></aw-login>',
                parent: 'login'
            })
            .state('activate', {
                redirectTo: 'setup'
            });
    })
    .run( /*@ngInject*/ (dataContext) => {
        if (!_.isNil(dataContext.user) && _.isFunction(dataContext.user.initialize)) {
            dataContext.user.initialize();
        }
    })
    .run( /*@ngInject*/ ($rootScope, actionContext, dataContext, constants) => {
        $rootScope.$on(constants.events.loginRequired, () => {
            actionContext.login.handleLoginRequired();
        });
    })
    .run(/*@ngInject*/ ($rootScope, $state) => {
        $rootScope.$on('$stateChangeStart', (evt, to, params) => {
            if (to.redirectTo) {
                evt.preventDefault();
                $state.go(to.redirectTo, params, { location: 'replace' });
            }
        });
    })
    .component('awLogin', loginComponent)
    .component('awActivate', activateComponent)
    .component('awSetupActivationCode', setupActivationCodeComponent)
    .component('awSetupHubSid', setupHubSidComponent)
    .component('awGetStartedModal', getStartedModalComponent);
