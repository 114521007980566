import * as angular from 'angular';
import 'angular-ui-router';

import './password.scss';

import commonModule from '../common';

import passwordEditComponent from './edit.component';

export default angular
    .module('fp:activationswizard:password', [
        'ui.router',
        commonModule.name
    ])
    .config( /*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            .state('password',
                {
                    abstract: true,
                    parent: 'secure',
                    url: '/password',
                    template: '<ui-view></ui-view>',
                    data: {
                        next: /*@ngInject*/($state, appActions, $ngRedux) => {
                            appActions.completeModule(constants.sectionTypes.password);
                            if ($ngRedux.getState().account.customer.isVideoOnly) {
                                $state.go('activation.complete');
                            } else {
                                $state.go('mastercode.home');
                            }
                            
                        },
                        prev: /*@ngInject*/ ($state) => {
                            $state.go('passcode.home');
                        },
                        sectionType: constants.sectionTypes.password,
                        theme: 'app-theme-default-navbar-gray'
                    }
                })
            .state('password.home',
                {
                    url: '',
                    template: '<aw-password-edit></aw-password-edit>',
                    onEnter: /*@ngInject*/ (appActions) => {
                        appActions.startModule(constants.sectionTypes.password);
                    }
                });
    })
    .component('awPasswordEdit', passwordEditComponent);