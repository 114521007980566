import * as _ from 'lodash';
import * as ScanditSDK from 'scandit-sdk';

class ProductActions {
    /*@ngInject*/
    constructor($ngRedux, userService, constants, dataContext, appActions, fpStore) {
        this.$ngRedux = $ngRedux;
        this.userService = userService;
        this.constants = constants;
        this.dataContext = dataContext;
        this.appActions = appActions;
        this.unsubscribe = $ngRedux.connect(this._mapState)(this);
        this.fpStore = fpStore;
    }

    /**
     * Gets the display name for the product with the given SKU.
     * 
     * @param {string=} sku A product SKU.
     * @returns {string} A string that represents the display name 
     * for the given SKU or an empty string if the SKU was not found.
     */
    getDisplayName(sku) {
        if (_.isNil(sku)) {
            return '';
        }

        let metadata = this.getMetadata(sku);
        if (!_.isNil(metadata) && !_.isNil(metadata.ReadableName)) {
            return metadata.ReadableName;
        }

        return '';
    }

    /**
    * Returns a flag indicating if the product has DL Code data or not.
    * 
    * @param {string=} sku A product SKU.
    * @returns {boolean} True if the product has DL Code, otherwise false.
    */
    hasDLCode(sku) {
        if (_.isNil(sku)) {
            return false;
        }

        let metadata = this.getMetadata(sku);
        return !_.isNil(metadata) && metadata.HasDLCode === true;
    }

    /**
     * Gets the description for the product with the given SKU.
     * 
     * @param {string=} sku A product SKU.
     * @returns {string} A string that represents the description for the 
     * given SKU or an empty string if the SKU was not found.
     */
    getDescriptionText(sku) {
        if (_.isNil(sku)) {
            return '';
        }

        let metadata = this.getMetadata(sku);
        if (!_.isNil(metadata)) {
            if (this.$ngRedux.getState().account.origin.startedOnAmazon && metadata.KitDescriptionText) {
                return metadata.KitDescriptionText;
            }
            if (this.$ngRedux.getState().account.user.isIq4Panel) {
                return metadata.IqDescriptionText;
            }

            return metadata.DescriptionText;
        }

        return '';
    }

    /**
     * Gets the box instructions for the product with the given SKU.
     * 
     * @param {string=} sku A product SKU.
     * @returns {string} A string that represents the description for the 
     * given SKU or an empty string if the SKU was not found.
     */
    getBoxText(sku) {
        if (_.isNil(sku)) {
            return '';
        }
        let metadata = this.getMetadata(sku);
        if (!_.isNil(metadata)) {
            return metadata.BoxText;
        }

        return '';
    }

    /**
     * Gets the installation text for the product with the given SKU.
     * 
     * @param {string=} sku A product SKU.
     * @returns {string} A string that represents the installation text 
     * for the given SKU or an empty string if the SKU was not found.
     */
    getInstallationText(sku) {
        if (_.isNil(sku)) {
            return '';
        }

        let metadata = this.getMetadata(sku);
        if (!_.isNil(metadata)) {
            if (this.$ngRedux.getState().account.origin.startedOnAmazon && metadata.KitInstallationText) {
                return metadata.KitInstallationText;
            }

            return metadata.InstallationText;
        }

        return '';
    }

    getFurtherHelpText(sku) {
        if (_.isNil(sku)) {
            return 'For further help and images, reference our ';
        }

        let metadata = this.getMetadata(sku);
        if (!_.isNil(metadata)) {
            if (this.$ngRedux.getState().account.origin.startedOnAmazon && metadata.KitFurtherHelpText) {
                return metadata.KitFurtherHelpText;
            } else if (metadata.FurtherHelpText) {
                return metadata.FurtherHelpText;
            } else {
                return 'For further help and images, reference our ';
            }
        }

        return 'For further help and images, reference our ';
    }

    /**
     * Gets the image for the product with the given SKU.
     * 
     * @param {string=} sku A product SKU.
     * @returns {string} A string that represents an image in base64 format
     * or a link to an image for the given SKU or an empty string if the SKU
     * was not found.
     */
    getImage(sku) {
        if (_.isNil(sku)) {
            return null;
        }
        let metadata = this.getMetadata(sku);
        if (!_.isNil(metadata)) {
            if (_.has(metadata.Image, sku)) {
                let productImage = _.pickBy(metadata.Image, (value, key) => {
                    if (_.startsWith(key, sku)) {
                        return value;
                    }
                });
                return productImage[Object.keys(productImage)[0]];
            } else {
                return metadata.Image.default;
            }
        }
        return null;
    }


    /**
     * Gets the image for the product box placement with the given SKU.
     * 
     * @param {string=} sku A product SKU.
     * @returns {string} A string that represents an image in base64 format
     * or a link to an image for the given SKU or an empty string if the SKU
     * was not found.
     */
    getBoxImage(sku) {
        if (_.isNil(sku)) {
            return null;
        }
        let metadata = this.getMetadata(sku);
        if (!_.isNil(metadata)) {
            if (_.has(metadata.BoxImage, sku)) {
                let boxImage = _.pickBy(metadata.BoxImage,
                    (value, key) => {
                        if (_.startsWith(key, sku)) {
                            return value;
                        }
                    });
                return boxImage[Object.keys(boxImage)[0]];
            } else {
                return metadata.BoxImage.default;
            }
        }
        return null;
    }


    /**
     * Gets the test type for the product with the given SKU.
     * 
     * @param {string=} sku A product SKU.
     * @returns {string} A string that represents the test type for
     * the given SKU or an empty string if the SKU was not found.
     */
    getTestType(sku) {
        if (_.isNil(sku)) {
            return '';
        }
        
        let metadata = this.getMetadata(sku);
        if (!_.isNil(metadata)) {
            return metadata.TestType;
        }

        return '';
    }

    /**
     * Determins whether the sensor will go through the barcode scanner flow.
     * 
     * @param {string=} sku A product SKU.
     * @returns {string} A string that represents the test type for
     * the given SKU or an empty string if the SKU was not found.
     */
    getAirfxSensorFlow() {
        let barcodeScanSupported = ScanditSDK.BrowserHelper.checkBrowserCompatibility().fullSupport;
        const sku = _.join(this.sku, '');
        if (_.isNil(sku) || this.appActions.isInitialWithAdditional()) {
            return 'airfx.list';
        }
        if (_.includes(this.constants.barcodeScannerFlowSkus, sku)) {
            this.setAirfxAllowBarcodeFlow();
            const allowBarcode = this.fpStore.get('allowBarcode', 'session');
            if (barcodeScanSupported && allowBarcode) {
                return 'airfx.barcodeList';
            } else {
                return 'airfx.list';
            }
        }
         else {
            return 'airfx.list';
        }
    }

    setAirfxAllowBarcodeFlow() {
        const hasAllowBarcode = this.fpStore.has('allowBarcode', 'session');
        if (!hasAllowBarcode) {
            this.fpStore.set('allowBarcode', this.allowBarcode(), 'session');
        }
    }

    allowBarcode() {
        return Math.floor((Math.random() * 100) + 1) <= this.constants.barcodeChanceAsPercent;
    }

    isSupportedBarcodeScannerWebBrowser() {
        return navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function';
    }


    /**
     * Gets the priority for the given SKU. A higher number represents a
     * higher priority.
     * 
     * @param {string=} sku A product SKU.
     * @returns {number} A number that represents the priority of a SKU. 
     */
    getPriority(sku) {
        if (this.isPanel(sku)) {
            return 3;
        }
        if (this.isSensor(sku)) {
            return 2;
        }
        if (!_.isNil(sku) && this.hasMetadata(sku)) {
            return 1;
        }
        return 0;
    }

    /**
     * Returns a flag indicating if the product with the given SKU
     * has a test.
     * 
     * @param {string=} sku A product SKU.
     * @returns {boolean} True if the given SKU has a test, otherwise false.
     */
    hasTest(sku) {
        if (_.isNil(sku)) {
            return false;
        }

        let metadata = this.getMetadata(sku);
        return !_.isNil(metadata) && !_.isNil(metadata.TestType);
    }

    /**
     * Returns a flag indicating if the product with the given SKU should be
     * shown to the user.
     * 
     * @param {string=} sku A product SKU.
     * @returns {boolean} True if the given SKU should be shown to the user, otherwise false.
     */
    isShown(sku) {
        if (_.isNil(sku)) {
            return false;
        }

        let metadata = this.getMetadata(sku);
        return !_.isNil(metadata) && metadata.IsShown === true;
    }

    /**
     * Returns a flag indicating if the product with the given SKU is a sensor.
     * 
     * @param {string=} sku A product SKU.
     * @returns {boolean} True if the given SKU is a sensor, otherwise false.
     */
    isSensor(sku) {
        if (_.isNil(sku)) {
            return false;
        }

        let metadata = this.getMetadata(sku);
        return !_.isNil(metadata) && metadata.IsSensor === true;
    }

    /**
     * Returns a flag indicating if the product with the given SKU is a keychain remote or panic pendant.
     * 
     * @param {string=} sku A product SKU.
     * @returns {boolean} True if the given SKU is a a keychain remote or panic pendant, otherwise false.
     */
    isExceptionDevice(sku) {
        if (_.isNil(sku)) {
            return false;
        }

        return _.includes(this.constants.sensorListExceptionSkus, sku);
    }

    /**
     * Returns a flag indicating if the product with the given SKU is a panel.
     * 
     * @param {string=} sku A product SKU.
     * @returns {boolean} True if the given SKU is a sensor, otherwise false. 
     */
    isPanel(sku) {
        if (_.isNil(sku)) {
            return false;
        }

        let metadata = this.getMetadata(sku);
        return !_.isNil(metadata) && metadata.IsPanel === true;
    }

    /**
     * Returns a flag indicating if the product with the given SKU should force the 'How To Install' content on install.
     * 
     * @param {string=} sku A product SKU.
     * @param {boolean} override check for previously completed sensors of the same type.
     * @returns {boolean} True if the given SKU requires training to be seen by the user, otherwise false.
     */
    isTrainingRequired(sku, force = false) {
        if (_.isNil(sku)) {
            return false;
        }

        let metadata = this.getMetadata(sku);
        let isTrainingRequired = !_.isNil(metadata) && metadata.IsTrainingRequired === true;
        if (isTrainingRequired && !force) {
            let state = this.$ngRedux.getState();

            isTrainingRequired = !_.includes(state.system.devices.installationInstructionsViewed, sku);
        }

        return isTrainingRequired;
    }

    getSetupName(sku) {
        if (_.isNil(sku)) {
            return '';
        }

        let metadata = this.getMetadata(sku);
        if (!_.isNil(metadata) && !_.isNil(metadata.SetupName)) {
            return metadata.SetupName;
        }

        return '';
    }

    /**
     * Returns a flag indicating if the product with the given SKU has associated metadata.
     * 
     * @param {string=} sku A product SKU. 
     * @returns {boolean} True if the given SKU has metadata, otherwise false.
     */
    hasMetadata(sku) {
        if (_.isNil(sku)) {
            return false;
        }

        let metadata = this.getMetadata(sku);
        return !_.isNil(metadata);
    }

    /**
     * Gets the metadata for the product with the given SKU.
     * 
     * @param {string=} sku A product SKU. 
     * @returns {Object} A {@link ProductMetadata} object.
     */
    getMetadata(sku) {
        if (_.isNil(sku)) {
            return null;
        }
        var products = this.$ngRedux.getState().application.config.products;
        if (this.$ngRedux.getState().account.user.isBetaUser) {
            return _.find(products, (value) => {
                if (value.Beta && !this.$ngRedux.getState().account.user.isIq4Panel) {
                    value.TestType = value.Beta.TestType;
                }
                return _.includes(value.Sku, sku);
            });
        } else {
            return _.find(products, (value) => {
                return _.includes(value.Sku, sku);
            });
        }
    }

    _mapState(state) {
        return {
            category: state.account.airfxFlow.category,
            sku: state.account.airfxFlow.sku,
            dlcode: state.account.airfxFlow.dlcode
        };
    }

}

export default ProductActions;