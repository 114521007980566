import template from './armedPanelModal.html';

class ArmedPanelModalController {
    /*@ngInject*/
    constructor (SharedState, $ngRedux) {
        this.SharedState = SharedState;
        this.$ngRedux = $ngRedux;
        this.panelArmed = false;
    }

    close() {
        this.SharedState.turnOff('armedPanelModal');
    }
}

export default {
template: template,
    bindings: {},
controller: ArmedPanelModalController
};  