import * as _ from 'lodash';

let IframeCapturePostMessageDataDirective = ($window, constants) => {
    'ngInject';
    return {
        restrict: 'A',
        link: (scope, element, attrs) => {
            $window.addEventListener('message', event => {
                let eventData = _.get(event, 'data', null);
                if (!_.isNil(eventData) && eventData === constants.events.zWaveWizardCompleted) {
                    scope.$apply(attrs.awIframeCapturePostMessageData);
                }
            });
        }
    };
};

export default IframeCapturePostMessageDataDirective;