import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-progress-arc';

import progressComponent from './progressArc.component';
import progressRoundComponent from './progressRound.component';

export default angular.module('fp:activationswizard:progress', [
        'angular-progress-arc',
        'ngRedux'
    ])
    .component('awProgress', progressComponent)
    .component('awProgressRound', progressRoundComponent);
