import template from './saleRestricted.html';

class SaleRestrictedController {
    /*@ngInject*/
    constructor() {

    }

    $onInit() {
    }
}

export default {
    template: template,
    bindings: {},
    controller: SaleRestrictedController
};