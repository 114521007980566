import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import './premises.scss';

import commonModule from '../common';

import premisesAddressComponent from './address.component';
import premisesEditComponent from './edit.component';
import premisesHomeComponent from './home.component';

export default angular
    .module('fp:activationswizard:premises', [
        'mobile-angular-ui',
        'ui.router',
        ngRedux,
        commonModule.name
    ])
    .config( /*@ngInject*/($stateProvider, constants) => {
        $stateProvider
            .state('premises', {
                abstract: true,
                parent: 'secure',
                url: '/premises',
                template: '<ui-view></ui-view>',
                data: {
                    next: /*@ngInject*/($state, appActions) => {
                        appActions.completeModule(constants.sectionTypes.premise);
                        $state.go('predispatch.home');
                    },
                    prev: /*@ngInject*/ ($state, actionContext) => {
                        if (actionContext.panel.isClimaxHub())
                            $state.go('instruction.keypad');
                        else 
                            $state.go('instruction.plugin');
                    },
                    sectionType: constants.sectionTypes.premise,
                    theme: 'app-theme-default-navbar-gray'
                },
                resolve: {
                    isInstructionComplete: /*@ngInject*/ (grant, setupComplete) => {
                        return grant.only({
                            test: 'isInstructionComplete',
                            state: 'instruction.home'
                        });
                    }
                }
            })
            .state('premises.home', {
                url: '',
                template: '<aw-premises-home></aw-premises-home>',
                onEnter: /*@ngInject*/ (appActions) => {
                    appActions.startModule(constants.sectionTypes.premise);
                }
            })
            .state('premises.edit', {
                url: '/edit',
                template: '<aw-premises-edit></aw-premises-edit>'
            });
    })
    .component('awAddress', premisesAddressComponent)
    .component('awPremisesHome', premisesHomeComponent)
    .component('awPremisesEdit', premisesEditComponent);