import * as _ from 'lodash';
import template from './subCategoryList.html';

class SubCategoryListController {
    /*@ngInject*/
    constructor($state, $stateParams, SharedState, appActions, $timeout, fpStore, deviceActions, $ngRedux, actionContext) {
        this.$timeout = $timeout;
        this.appActions = appActions;
        this.SharedState = SharedState;
        this.fpStore = fpStore;
        this.deviceActions = deviceActions;
        this.$state = $state;
        this.category = $stateParams.category;
        this.actionContext = actionContext;
        this.unsubscribe = $ngRedux.connect(this._mapState)(this);
    }

    $onInit() {
        this.deviceList = _.uniqBy(this.deviceActions.getDevicesFromCategory(this.category, true), 'ProductName');

        var eventMessage = 'List of sensors presented for selection - ';
        var sensors = '';
        _.forEach(this.deviceList, (device) => {
            sensors += `[${device.ProductName}]`;
        });
        this.actionContext.equipmentSetup.onSetupStart(eventMessage.concat(sensors));

        //display the warning modal for new sessions
        if (!this.fpStore.has('armedPanelModal', 'session') || this.fpStore.has('armedPanelModal', 'session') && !this.fpStore.get('armedPanelModal', 'session')) {
            this.$timeout(() => {

                this.SharedState.turnOn('armedPanelModal');
                this.fpStore.set('armedPanelModal', true, 'session');

            }, 100);
        }
    }

    _mapState(state) {
        return {
            category: state.account.airfxFlow.category
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: SubCategoryListController
};