import template from './premiseAddress.html';

class PremiseAddressController {
    /*@ngInject*/
    constructor($scope, $ngRedux, $state, constants, actionContext, SharedState, $q, $log, navigation, applicationStateService, dataContext) {
        this.$scope = $scope;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.actionContext = actionContext;
        this.dataContext = dataContext;
        this.SharedState = SharedState;
        this.$q = $q;
        this.$log = $log;
        this.isUpdating = false;
        this.navigation = navigation;
        this.emailSent = false;
        this.applicationStateService = applicationStateService;
        this.isEmailLive = dataContext.email.isEmailLive().then((res) => { this.isEmailLive = res });
        this.dogs = 0;
        this.cats = 0;
        this.otherPets = '';
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.showCompanyName = this.isAmazon ? !this.lead.contact.IsResidential : false;
        this.isPetChecked = false;
        this.isOtherChecked = false;
        this.isPetInfoConfirmed = false;
        this.isVideoOnly = this.$ngRedux.getState().account.customer.isVideoOnly;
    }

    residenceChanged() {
        this.showCompanyName = this.lead.contact.IsResidential === "false";
    }

    sendEmail(isDirty) {
        if (isDirty && !this.isAmazon && !this.emailSent && this.isEmailLive === true) {
            this.emailSent = true;
            this.dataContext.email.sendEmail("SystemAddress");
        }
    }

    onSave() {
        if (this.isPetChecked && !this.isPetInfoConfirmed) {
            this.SharedState.turnOn('addPetInfoModal');
            return this.$q.reject();
        }
        this.isUpdating = true;
        if (this.isAmazon) {
            this.actionContext.premises.validateAddress(this.premises)
                .then((response) => {
                    if (!response.IsValid) {
                        this.isUpdating = false;
                        this.validation = response;
                        this.SharedState.turnOn('addressValidationModal');
                        return this.$q.reject();
                    } else {
                        this.isUpdating = true;
                        return this.actionContext.premises.isSaleRestricted(this.premises).then(result => {
                            if (result) {
                                this.isUpdating = false;
                                this.$state.go('account.sale-restricted');
                                return this.$q.reject();
                            }
                            else {
                                return this.actionContext.kitCustomer.setAddressAndCreateAccount({
                                    LeadID: this.lead.leadId,
                                    ...this.premises,
                                    FirstName: this.lead.contact.FirstName,
                                    LastName: this.lead.contact.LastName,
                                    Email: this.lead.contact.Email,
                                    Phone: this.lead.contact.Phone,
                                    IsResidential: this.lead.contact.IsResidential,
                                    CompanyName: this.lead.contact.IsResidential === "true" ? null : this.lead.contact.CompanyName
                                }).then((response) => {
                                    if (this.isPetChecked)
                                        this.addAccountPets(response);
                                    this.isUpdating = false;
                                    this.navigation.goNext();
                                    return;
                                });
                            }
                        });
                    }
                })
                .then(() => {
                    this.navigation.goNext();
                })
                .finally(() => {
                    this.isUpdating = false;
                });
        } else {
            this.actionContext.premises.validateAddress(this.premises)
                .then((response) => {
                    const currentPremises = this.$ngRedux.getState().account.premises.value;
                    if (!this.actionContext.premises.isDifferentAddress(this.premises, currentPremises)) {
                        this.isUpdating = false;
                        if (this.isPetChecked)
                            this.addAccountPets();
                        this.navigation.goNext();
                        return this.$q.reject();
                    } else {
                        return response;
                    }
                })
                .then((response) => {
                    if (!response.IsValid) {
                        this.isUpdating = false;
                        this.validation = response;
                        this.SharedState.turnOn('addressValidationModal');
                        return this.$q.reject();
                    } else {
                        this.isUpdating = true;
                        if (this.isPetChecked)
                            this.addAccountPets();
                        return this.actionContext.premises.forceAddress(this.premises);
                    }
                })
                .then(() => {
                    this.navigation.goNext();
                })
                .finally(() => {
                    this.isUpdating = false;
                });
        }
    }

    addAccountPets(accountId) {
        var petsInfo = { accountID: accountId, dogs: this.dogs, cats: this.cats, otherPets: this.otherPets };
        this.dataContext.account.addAccountPets(petsInfo)
            .then(() => {
                if (!this.isAmazon) {
                    this.dataContext.account.UpdatePetsInfoInRapid(accountId).then(() => { }).catch(() => {
                        return this.$q.reject('Error occured while passing pets information to rapid.');
                    });
                }                
            }).catch(() => {
                return this.$q.reject('Error occured while adding pets information to the account.');
            });
    }

    increment(petType) {
        if (petType=='dog')
            this.dogs++;
        if (petType == 'cat')
            this.cats++;
    }

    decrement(petType) {
        if (petType == 'dog')
            this.dogs--;
        if (petType == 'cat')
            this.cats--;
    }

    onPetCheck() {
        this.SharedState.turnOn('addPetInfoModal');        
    }

    onPetsConfirmation() {
        if (this.isOtherChecked && this.otherPets.length == 0) {
            alert("Please input your pet information or deselect \"Other\".");
        }
        else if (!this.isOtherChecked && this.dogs === 0 && this.cats === 0) {
            alert("Please input your pet information.");
        }
        else if (this.dogs>25 || this.cats>25) {
            alert("The number of dogs or cats should not be more than 25");
        }
        else {
            this.SharedState.turnOff('addPetInfoModal');
            this.isPetInfoConfirmed = true;
            this.onSave();
        }        
    }

    petsNumberCheck() {
        if (this.dogs === null || this.dogs < 0) {
            this.dogs = 0;
        }
        if (this.cats === null || this.cats < 0) {
            this.cats = 0;
        }
    }
    onOtherCheck() {
        this.otherPets = '';
    }

    onAccept() {
        this.premises = this.validation.Address;
        this.SharedState.turnOff('addressValidationModal');
        this.onSave();
    }

    /**
     * Called when the customer has declined the suggested address.
     * 
     * @returns {} 
     */
    onDecline() {
        this.validation = null;
        this.SharedState.turnOff('addressValidationModal');
        this.isPetInfoConfirmed = false;
        this.isPetChecked = false;
        this.isOtherChecked = false;
        this.dogs = 0;
        this.cats = 0;
        this.otherPets = '';
    }

    _mapState(state) {
        let map = {};

        map.premises = state.account.kitCustomer.address;

        map.hasError = state.account.kitCustomer.hasError;
        map.message = state.account.kitCustomer.message;
        map.states = state.application.states;
        map.isAmazon = state.account.user.isAmazonUser;
        map.lead = state.account.kitCustomer;
        if (map.isAmazon) {
            map.lead.contact.IsResidential =
                map.lead.contact.IsResidential === null ? true : map.lead.contact.IsResidential;
        }

        if (!map.isAmazon) {
            map.premises = {
                AddressLine1: state.account.premises.value.AddressLine1,
                AddressLine2: state.account.premises.value.AddressLine2 === " " ? null : state.account.premises.value.AddressLine2,
                City: state.account.premises.value.City,
                State: state.account.premises.value.State,
                PostalCode: state.account.premises.value.PostalCode
            };
        }
        return _.cloneDeep(map);
    }
}

export default {
    template: template,
    bindings: {},
    controller: PremiseAddressController
};