import 'angular-ui-router';
import * as angular from 'angular';
import ngRedux from 'ng-redux';

// components
import carbonMonoxideInstallationModalComponent from './carbonMonoxideInstallationModal.component';
import doorWindowInstallationModalComponent from './doorWindowInstallationModal.component';
import garageDoorInstallationModalComponent from './garageDoorInstallationModal.component';
import glassBreakInstallationModalComponent from './glassBreakInstallationModal.component';
import imageInstallationModalComponent from './imageInstallationModal.component';
import installationModalComponent from './installationModal.component';
import motionInstallationModalComponent from './motionInstallationModal.component';
import recessedDoorInstallationModalComponent from './recessedDoorInstallationModal.component';
import smokeHeatInstallationModalComponent from './smokeHeatInstallationModal.component';
import waterFloodInstallationModalComponent from './waterFloodInstallationModal.component';

export default angular
    .module('fp:activationswizard:sensor:installation', [
        'ui.router',
        ngRedux
    ])
    .component('awCarbonMonoxideSensorInstallationModal', carbonMonoxideInstallationModalComponent)
    .component('awDoorWindowSensorInstallationModal', doorWindowInstallationModalComponent)
    .component('awGarageDoorSensorInstallationModal', garageDoorInstallationModalComponent)
    .component('awGlassBreakSensorInstallationModal', glassBreakInstallationModalComponent)
    .component('awImageSensorInstallationModal', imageInstallationModalComponent)
    .component('awInstallationModal', installationModalComponent)
    .component('awMotionSensorInstallationModal', motionInstallationModalComponent)
    .component('awRecessedDoorSensorInstallationModal', recessedDoorInstallationModalComponent)
    .component('awSmokeHeatSensorInstallationModal', smokeHeatInstallationModalComponent)
    .component('awWaterFloodSensorInstallationModal', waterFloodInstallationModalComponent);