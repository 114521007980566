import * as _ from 'lodash';
import { Z_WAVE_HANDBACK_FROM_ADC, Z_WAVE_HANDOFF_TO_ADC, Z_WAVE_INITIALIZE } from '../action/zWave.actions';

const initialState = {
    isSuccesfulHandbackFromADC: false,
    isSuccesfulHandoffToADC: false
};

export default (state = initialState, action: { type: string }) => {
    const newState = _.cloneDeep(state);
    switch (action.type) {
        case Z_WAVE_HANDOFF_TO_ADC:
            newState.isSuccesfulHandoffToADC = true;
            return newState;
        case Z_WAVE_HANDBACK_FROM_ADC:
            newState.isSuccesfulHandbackFromADC = true;
            return newState;
        case Z_WAVE_INITIALIZE:
            newState.isSuccesfulHandoffToADC = false;
            newState.isSuccesfulHandbackFromADC = false;
            return newState;
        default:
            return state;
    }
};
