import './duressCodes.scss';
import template from './duressCodes.html';
import * as _ from 'lodash';

class DuressCodesController {
    /*@ngInject*/
    constructor(actionContext, $ngRedux, SharedState, $stateParams, constants, $state) {
        this.actionContext = actionContext;
        this.$ngRedux = $ngRedux;
        this.SharedState = SharedState;
        this.swapType = $ngRedux.getState().system.swap.swapType;
        this.constants = constants;
        this.$state = $state;
        this.duressCode = ['9998'];
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }
    
    onContinue() {
        if (this.swapType === this.constants.swapType.radio) {
            this.actionContext.radioSwap.onRadioSwapComplete();
        }
        else if (this.targetSku === this.constants.panelVersions.iqPanel) {
            this.$state.go('instruction.bluetooth');
        }
        else {
            this.$state.go(this.constants.routerStates.testUserCode);
        }
    }

    onPrevious() {
        if (this.swapType === this.constants.swapType.radio) {
            this.$state.go(this.constants.routerStates.radioSwapSensorTransfer, { swapType: this.swapType });
        } else {
            this.$state.go(this.constants.routerStates.swapSensorTransfer);
        }
    }
    
    _mapState(state) {
        return _.cloneDeep({
            targetSku: state.account.airfxFlow.targetSku
        });
    }
}

export default {
    template: template,
    bindings: {},
    controller: DuressCodesController
};