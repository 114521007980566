import template from './notConnected.html';

import AbstractErrorController from './AbstractErrorController';

class SensorNotConnectedController extends AbstractErrorController {
    /*@ngInject*/
    constructor($log, $ngRedux, actionContext, constants) {
        super($log, $ngRedux, actionContext, constants);
    }

    $onInit() {
        super.$onInit();
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
    }

    getErrorMessage() {
        return `Sensor is not connected to the panel or is missing settings. [SensorID: ${this.sensor.SensorID}]`;
    }
}

export default {
    template: template,
    bindings: {},
    controller: SensorNotConnectedController
};