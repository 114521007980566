import * as _ from 'lodash';

import template from './shell.html';

const DEFAULT_OPTIONS = {
    navbar: {
        top: true,
        bottom: true
    },
    theme: 'app-theme-default'
};

class ShellComponent {
    /*@ngInject*/
    constructor($rootElement, $scope, $location, $ngRedux, actionContext, constants) {
        this.$rootElement = $rootElement;
        this.$scope = $scope;
        this.constants = constants;
        this.$location = $location;
        this.$ngRedux = $ngRedux;
        this.actionContext = actionContext;

        this.currentTheme = 'app-theme-default-navbar-gray';
    }

    $onInit() {
        this.unsubscribe = this.$scope.$on(this.constants.events.stateChangeSuccess, (event, toState) => {
            this.applyOptions(toState.data);
        });
        const isInMobileApp = localStorage.getItem("isApp") || this.$location.search().isApp;
        if (isInMobileApp) {
            this.actionContext.login.setIfUserInMobileApp(isInMobileApp);
            localStorage.setItem('isApp', true);
        }
    }

    $onDestroy() {
        this.unsubscribe();
    }

    /**
     * Apply all options in the given hash object.
     * 
     * @param {Object} options A hash object containing options to apply.
     */
    applyOptions(options) {
        options = _.merge({}, DEFAULT_OPTIONS, options);

        // navbar
        this.hasTopNav = options.navbar !== false && options.navbar.top !== false;
        this.hasBottomNav = options.navbar !== false && options.navbar.bottom !== false;

        // theme
        this.navbarTheme = options.theme;

        // progress
        this.hasProgress = options.progress !== false;

        this.applyStyles();
    }

    /**
     * Apply the current theme options to the UI.
     */
    applyStyles() {
        this.$rootElement.removeClass(this.currentTheme);
        this.currentTheme = this.navbarTheme || 'app-theme-default';
        this.$rootElement.addClass(this.currentTheme);

        this.$rootElement.toggleClass('has-navbar-top', this.hasTopNav);
        this.$rootElement.toggleClass('has-navbar-bottom', this.hasBottomNav);
    }
}

export default {
    template: template,
    bindings: {},
    controller: ShellComponent
};