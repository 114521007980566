import template from './setupHubSid.html';
import * as _ from 'lodash';

class SetupHubSidComponent {
    /*@ngInject*/
    constructor() {
        this.hasError = false;
    }

    click(form) {
        if (form.$dirty && !form.isBusy && !form.$invalid) {
            this.onSubmit({ activationCode: this.code, form: form })
                .then(() => {},
                    (error) => {
                        this.hasError = true;
                        this.code = '';
                    });
            form.$setPristine();
        }
    }
}

export default {
    template: template,
    bindings: {
        isBusy: '<',
        onSubmit: '&',
        setShowSid: '&'
    },
    controller: SetupHubSidComponent
};