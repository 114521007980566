import * as angular from 'angular';

import commonModule from '../../common';

import navbarTopComponent from './navbar-top.component';
import navbarBottomComponent from './navbar-bottom.component';

export default angular.module('fp:activationswizard:shell:nav', [
        'mobile-angular-ui',
        'angular-progress-arc',
        commonModule.name
    ])
    .component('awNavbarTop', navbarTopComponent)
    .component('awNavbarBottom', navbarBottomComponent);
