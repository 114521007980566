import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import './usercode.scss';

import commonModule from '../common';
import userCodeComponent from './home.component';

export default angular
    .module('fp:activationswizard:usercode',
        [
            'ui.router',
            ngRedux,
            commonModule.name
        ])
    .config(/*@ngInject*/
        ($stateProvider, constants) => {
            $stateProvider
                // Initial
                .state('usercode',
                    {
                        abstract: true,
                        parent: 'secure',
                        url: '/usercode',
                        template: '<ui-view></ui-view>',
                        data: {
                            next: /*@ngInject*/ ($state, isXTPanel, appActions, $ngRedux) => {
                                const isIq4Panel = $ngRedux.getState().account.user.isIq4Panel;
                                if (isIq4Panel) {
                                    appActions.completeModule(constants.sectionTypes.usercode);
                                    $state.go('duresscode.home');
                                } else {
                                    $state.go('testmode.home');
                                }
                                
                            },
                            prev: /*@ngInject*/ ($state, $stateParams, $ngRedux) => {
                                // if user is coming from AB testing, navigate them back to disarm page
                                if ($stateParams.sourceLocation ===
                                    constants.armDisarmNavigationParams.trainingDisarm) {
                                    $state.go('training.disarm-system', { sourceLocation: $ngRedux.getState().router.currentState.name });
                                }
                                if ($stateParams.sourceLocation ===
                                    constants.airFxArmDisarmNavigationParams.airFxTrainingDisarm) {
                                    $state.go('airfx.disarmSystem', { sourceLocation: $ngRedux.getState().router.currentState.name });
                                } else {
                                    const isIq4Panel = $ngRedux.getState().account.user.isIq4Panel;
                                    if (isIq4Panel) {
                                        $state.go('mastercode.home');
                                    } else {
                                        $state.go('training.how-to');
                                    }                                                                       
                                }
                            },
                            sectionType: constants.sectionTypes.usercode,
                            theme: 'app-theme-default-navbar-gray'
                        },
                        resolve: {
                            isPanelComplete: /*@ngInject*/ (grant, setupComplete) => {
                                return grant.only({
                                    test: 'isPanelComplete',
                                    state: 'panel.searching'
                                });
                            }
                        }
                    })
                .state('usercode.home',
                    {
                        url: '',
                        template: '<aw-user-code></aw-user-code>',
                        onEnter: /*@ngInject*/ (appActions, $ngRedux) => {
                            const isIq4Panel = $ngRedux.getState().account.user.isIq4Panel;
                            if (isIq4Panel) {
                                appActions.startModule(constants.sectionTypes.usercode);
                            }                            
                        }
                    });
        })
    .component('awUserCode', userCodeComponent);
