import * as _ from 'lodash';

import { FETCH_ELIGIBLE_SENSOR_NAMES_REQUEST, FETCH_ELIGIBLE_SENSOR_NAMES_FAILURE, FETCH_ELIGIBLE_SENSOR_NAMES_SUCCESS } from '../../action/app.actions';

const initialState = [];

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case FETCH_ELIGIBLE_SENSOR_NAMES_REQUEST:
    case FETCH_ELIGIBLE_SENSOR_NAMES_FAILURE:
        return state;
    case FETCH_ELIGIBLE_SENSOR_NAMES_SUCCESS:
        return action.payload.data;
    default:
        return state;
    }
};