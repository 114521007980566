class StateService {
    /*@ngInject*/
    constructor(Restangular) {
        this.restangular = Restangular;
    }

    getStates() {
        return this.restangular
            .all('states')
            .customGET()
            .then((result) => {
                return result.plain().Data;
            });
    }
}

export default StateService;