import * as _ from 'lodash';
import template from './addUserCodeModal.html';

class AddUserCodeModalController {
    /*@ngInject*/
    constructor(SharedState, $ngRedux, $state, actionContext, constants) {
        this.SharedState = SharedState;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.actionContext = actionContext;
        this.constants = constants;
        this.isLoading = false;
        this.showSuccessComponent = false;
    }

    onCancel() {
        this.SharedState.turnOff('addUserCodeModal');
    }

    onAddUserCode(FirstName, LastName, Code) {
        const code = { FirstName: FirstName, LastName: LastName, Code: Code, IsNew: true };
        this._addUserCode(code);
    }

    onContinue() {
        this.SharedState.turnOff('addUserCodeModal');
        this.showSuccessComponent = false;
    }

    _addUserCode(code) {
        this.isLoading = true;
        this.actionContext.code.editUserCodes([code])
            .then(() => {
                this.showSuccessComponent = true;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
}

export default {
    template: template,
    bindings: {},
    controller: AddUserCodeModalController
};