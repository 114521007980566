import * as _ from 'lodash';
import * as moment from 'moment';
import ngRedux from 'ng-redux';
import { Unsubscribe } from 'redux';
import ActionContext from '@app/common/action/actionContext.service';
import DataContext from '@app/common/data/dataContext.service';

export class CompleteController {
    public isBusy: true;
    public finishedWizard: boolean;
    public isClimaxHub: boolean;
    public isIQPanel: boolean;
    public customerSupportPhone: any;
    public codes: any[];
    public storeUrl = 'https://' + `${this.mobileDetectService.storeUrl()}`;
    public ssoUrl = this.constants.urls.adcSSO;
    public isInMobileApp: boolean;
    public showLoggingIn = false;
    public isVideoOnly: boolean;

    private readonly unsubscribe: Unsubscribe;

    constructor(
        private constants: any,
        private actionContext: ActionContext,
        private dataContext: DataContext,
        private $log: any,
        private mobileDetectService: any,
        private $window: ng.IWindowService,
        $ngRedux: ngRedux.INgRedux,
        ) {
        'ngInject';
        this.isBusy = true;
        this.unsubscribe = $ngRedux.connect(this._mapState)(this);
        this.isVideoOnly = $ngRedux.getState().account.customer.isVideoOnly;
    }

    public async $onInit() {
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);

        //Get SSO Token
        try {
            this.dataContext.user.getSSOToken().then((result: string) => {
                this.ssoUrl = this.ssoUrl + result;
            });
        } catch (error) {
            this.$log.error('Error occurred while getting SSO Token.', error);
        }
        
        
        if (!this.finishedWizard) {
            this.actionContext.app.completeActivationWizard();
            this.actionContext.common.end(this.constants.actionTypes.end, this.constants.sectionTypes.completed);
            this.actionContext.account.activateAccount();
        }
    }

    public $onDestroy() {
        this.unsubscribe();
    }

    public hasUserCodes() {
        return this.codes.length > 1;
    }

    public hasOneUserCode() {
        return this.codes.length === 2;
    }

    public async onCompleteButtonClick() {
        if (this.isInMobileApp) {
            this.showLoggingIn = true;
        } 

        try {
            await this.actionContext.sms.enrollCentralStationSms();
        } catch (error) {
            this.$log.error('Error occurred while enrolling emergency contacts in central station SMS service.', error);
        }

        if (this.isInMobileApp) {
            this.$window.location.href = this.ssoUrl;
        } else {
            this.$window.location.href = this.storeUrl;
        }
    }

    public _mapState(state: any) {
        const isTestMode = state.system.testmode.onTest;
        let monitoringStartDate: any = null;
        let testModeEndDate: any = null;
        if (isTestMode) {
            monitoringStartDate = moment(state.system.testmode.value).add(1, 'minutes').format('MMMM Do');
            testModeEndDate = moment(state.system.testmode.value).format('MMMM Do');
        }
        return _.cloneDeep({
            isTestMode,
            monitoringStartDate,
            testModeEndDate,
            testmode: state.system.testmode,
            lastDateInTest: state.system.testmode.value,
            code: state.system.code.mastercode,
            username: state.account.user.name,
            password: state.account.password.value,
            codes: state.system.code.codes,
            finishedWizard: state.application.finishedWizard,
            isInMobileApp: state.account.user.isInMobileApp
        });
    }

}

export default {
    template: require('./complete.html'),
    bindings: {},
    controller: CompleteController
};
