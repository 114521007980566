import { START_LOAD, STOP_LOAD } from '../../action/app.actions';

const initialState = false;

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case START_LOAD:
            return true;
        case STOP_LOAD:
            return false;
        default:
            return state;
    }
};