import * as _ from 'lodash';
import ensure from '../util/ensure';

export const PREDISPATCH_FETCH_REQUEST = 'PREDISPATCH_FETCH_REQUEST';
export const PREDISPATCH_FETCH_FAILURE = 'PREDISPATCH_FETCH_FAILURE';
export const PREDISPATCH_FETCH_SUCCESS = 'PREDISPATCH_FETCH_SUCCESS';

export const PREDISPATCH_UPDATE_REQUEST = 'PREDISPATCH_UPDATE_REQUEST';
export const PREDISPATCH_UPDATE_FAILURE = 'PREDISPATCH_UPDATE_FAILURE';
export const PREDISPATCH_UPDATE_SUCCESS = 'PREDISPATCH_UPDATE_SUCCESS';

export const PREDISPATCH_CONFIRMED = 'PREDISPATCH_CONFIRMED';

export const PREDISPATCH_CLEAR_ERROR = 'PREDISPATCH_CLEAR_ERROR';

class PredispatchActions {
    /*@ngInject*/
    constructor ($log, $ngRedux, $q, constants, dataContext) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.constants = constants;
        this.dataContext = dataContext;
    }

    /**
     * Fetch the current user's predispatch contacts list and add them to the application state.
     * 
     * @returns {promise} A promise that returns undefined if resolved and an error if rejected. 
     */
    fetchContacts() {
        this._onFetchPredispatch();

        return this.dataContext.monitoring.getPreDispatch()
            .then((data) => {
                this._onFetchPredispatchSuccess(data);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error fetching the customer\'s predispatch contacts.', error);
                this._onFetchPredispatchFailure(error);
                return this.$q.reject('fetchContacts');
            });
    }

    /**
     * Updates the current user's predispatch contacts.
     * 
     * @param {Array} contacts An array of predispatch contacts to update.
     * @returns {promise} A promise that returns undefined if resolved and an error if rejected.
     */
    updateContacts(contacts, isAmazonUser) {
        ensure.isArray('contacts', contacts);
        this._onUpdatePredispatch();

        return this.dataContext.monitoring.updatePreDispatch({ contacts, isAmazonUser })
            .then((data) => {
                this._onUpdatePredispatchSuccess(data);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error updating the customer\'s predispatch contacts.', error);
                this._onUpdatePredispatchFailure(error);
                return this.$q.reject(error);
            });
    }

    /**
     * Marks the user's predispatch contacts as confirmed.
     */
    confirm() {
        this._dispatch({
            type: PREDISPATCH_CONFIRMED
        });
    }

    /**
     * Clears any errors associated with predispatch contacts in the
     * application state.
     */
    clearError() {
        this._dispatch({
            type: PREDISPATCH_CLEAR_ERROR
        });
    }

    _dispatch(obj) {
        this.$ngRedux.dispatch(obj);
    }

    _onFetchPredispatch() {
        this._dispatch({
            type: PREDISPATCH_FETCH_REQUEST
        });
    }

    _onFetchPredispatchFailure(error) {
        this._dispatch({
            type: PREDISPATCH_FETCH_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onFetchPredispatchSuccess(data) {
        this._dispatch({
            type: PREDISPATCH_FETCH_SUCCESS,
            payload: {
                data: data
            }
        });
    }

    _onUpdatePredispatch() {
        this._dispatch({
            type: PREDISPATCH_UPDATE_REQUEST
        });
    }

    _onUpdatePredispatchFailure(error) {
        this._dispatch({
            type: PREDISPATCH_UPDATE_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onUpdatePredispatchSuccess(data) {
        this._dispatch({
            type: PREDISPATCH_UPDATE_SUCCESS,
            payload: {
                data: data
            },
            metadata: {
                sectionType: this.constants.sectionTypes.predispatch,
                customDescription:true,
                description: '',
                persist: true
            }
        });
    }
}

export default PredispatchActions;
