import * as angular from 'angular';
import * as _ from 'lodash';
import moment from 'moment';
import extendify from 'extendify';
import ngRedux from 'ng-redux';

import { JL } from 'jsnlog';

import 'angulartics';
import 'angulartics-segment';
import 'restangular';

// modules
import actionModule from './action';
import constantsModule from './constants';
import dataModule from './data';
import directiveModule from './directive';
import filterModule from './filter';
import metadataModule from './metadata';
import middlewareModule from './middleware';
import mobileModule from './mobile';
import progressModule from './progress';
import serviceModule from './service';
import uiModule from './ui';
import utilModule from './util';

const ERROR_LOGGED = 'ERROR_LOGGED';
const WARN_LOGGED = 'WARN_LOGGED';

const loggerName = 'Client';

export const commonModule = angular.module('fp:activationswizard:common', [
        ngRedux,
        'restangular',
        'angulartics',
        'angulartics.segment',

        actionModule.name,
        constantsModule.name,
        dataModule.name,
        directiveModule.name,
        filterModule.name,
        metadataModule.name,
        middlewareModule.name,
        mobileModule.name,
        progressModule.name,
        serviceModule.name,
        uiModule.name,
        utilModule.name
    ])

    .config( /*@ngInject*/ (RestangularProvider, constants) => {
        RestangularProvider.setBaseUrl(constants.api.prefix);
        RestangularProvider.setDefaultHttpFields({
            timeout: constants.api.timeout,
            warningAfter: constants.api.warningAfter
        });
    })

    .config(/*@ngInject*/ () => {
        _.mixin({
            mergeCustom: extendify({
                inPlace: false,
                isDeep: true,
                arrays: 'replace'
            }),

            /**
             * Merge two arrays and the values they contain. The values in each array are compared using the provided 
             * equality comparer. If a match is found the values are merged and placed in the result array. If no match 
             * is found the value is added to the result array unaltered.
             * 
             * The original arrays/values are not modified.
             * 
             * @param {Array} array1 
             * @param {Array} array2 
             * @param {Function} equalityComparer 
             * @returns {Array} 
             */
            mergeArray: (array1, array2, equalityComparer) => {
                var lodash = _.runInContext();
                if (!lodash.isArray(array1)) {
                    throw 'array1 must be an array.';
                }

                if (!lodash.isArray(array2)) {
                    throw 'array2 must be an array.';
                }

                if (!lodash.isFunction(equalityComparer)) {
                    throw 'equalityComparer must be a function.';
                }

                // Do not modify the original arrays.
                array1 = array1.slice();
                array2 = array2.slice();

                const sentinel = {};
                const result = [];
                for (let i = 0; i < array1.length; i++) {
                    const item1 = array1[i];
                    for (let j = 0; j < array2.length; j++) {
                        const item2 = array2[j];
                        // Sentinel values should be skipped because they have already been merged.
                        if(item2 === sentinel) {
                            continue;
                        }
          
                        if (equalityComparer(item1, item2)) {
                            // A match was found, merge the values into the result array and replace the value in array 2 with a 
                            // sentinel value so it will be ignored later.
                            result.push(lodash.merge({}, item1, item2));
                            array2[j] = sentinel;
                            break;
                        } else if (j === array2.length - 1) {
                            // No match was found, merge the value from array 1 into the result array.
                            result.push(lodash.merge({}, item1));
                            break;
                        }
                    }
                }

                // Add all values in array 2 that did not have a match to the result array.
                for (let i = 0; i < array2.length; i++) {
                    const item = array2[i];
                    if (item !== sentinel) {
                        result.push(lodash.merge({}, item));
                    }
                }

                return result;
            },

            findDirty: (before, after, predicate) => {
                return _.filter(after, (r) => {
                    let temp = _.find(before, (l) => {
                        return _.isFunction(predicate)
                            ? predicate(l, r)
                            : l === r;
                    });

                    return _.isNil(temp) || !_.isMatch(temp, r);
                });
            }
        });
    })

    .config(/*@ngInject*/ ($provide, constants) => {
        if (constants.disableRemoteLogging !== true) {
            const getMessage = (msg) => {
                let result = msg;
                if (!_.isEmpty(msg.Message)) {
                    result = msg.Message;
                }
                else if (!_.isEmpty(msg.message)) {
                    result = msg.message;
                }
                else if (!_.isNil(msg.data) && !_.isEmpty(msg.data.Message)) {
                    result = msg.data.Message;
                }

                return result;
            };

            $provide.decorator('$log', /*@ngInject*/ ($delegate, $injector) => {
                const logFn = $delegate.log;
                $delegate.log = (msg) => {
                    logFn(msg);
                    JL(loggerName).trace(getMessage(msg));
                };

                const debugFn = $delegate.debug;
                $delegate.debug = (msg) => {
                    debugFn(msg);
                    JL(loggerName).debug(getMessage(msg));
                };

                const infoFn = $delegate.info;
                $delegate.info = (msg) => {
                    // Hack from https://stackoverflow.com/questions/36706494/angular-ngmocks-error-in-log
                    if(_.isFunction($delegate.reset) && !_.isArray($delegate.info.logs)) { 
                        $delegate.reset();
                    }
                    infoFn(msg);
                    JL(loggerName).info(getMessage(msg));
                };

                const warnFn = $delegate.warn;
                $delegate.warn = (msg) => {
                    let ngRedux = $injector.get('$ngRedux');
                    warnFn(msg);
                    JL(loggerName).warn(getMessage(msg));

                    ngRedux.dispatch({
                        type: WARN_LOGGED,
                        metadata: {
                            sectionType: constants.sectionTypes.logging.warning,
                            description: `Warning logged: ${getMessage(msg)}`,
                            actionType: 'Warn',
                            persist: true
                        }
                    });
                };

                const errorFn = $delegate.error;
                $delegate.error = (msg, exception) => {
                    let ngRedux = $injector.get('$ngRedux');
                    errorFn(msg, exception);
                    JL(loggerName).error(getMessage(msg));

                    ngRedux.dispatch({
                        type: ERROR_LOGGED,
                        metadata: {
                            sectionType: constants.sectionTypes.logging.error,
                            description: `Error logged: ${getMessage(`${msg} Exception: ${JSON.stringify(exception)}`)}`,
                            actionType: 'Error',
                            persist: false
                        }
                    });
                };

                return $delegate;
            });
        }
    })
    
    .config(/*@ngInject*/ ($analyticsProvider, constants) => {

        $analyticsProvider.firstPageview(false);
        $analyticsProvider.virtualPageviews(false);

        $analyticsProvider.developerMode(constants.analytics.enabled === false);

        let appInsights = window.appInsights;

        $analyticsProvider.registerPageTrack((path) => {
            appInsights.trackPageView(path);
        });

        $analyticsProvider.registerEventTrack((eventName, eventProperties) => {
            let properties = {};
            let measurements = {};

            _.each(eventProperties, (value, key) => {
                if (_.isNumber(value)) {
                    measurements[key] = parseFloat(value);
                } else {
                    properties[key] = value;
                }
            });

            appInsights.trackEvent(eventName, properties, measurements);
        });
    })

    .config(/*@ngInject*/ ($httpProvider, constants) => {
        // Add the order id header to the http request via an interceptor to ensure it is always set even if the user 
        // refreshes their browser.
        // NOTE: Referencing dataContext causes a circular reference exception.
        $httpProvider.interceptors.push(/*@ngInject*/ (fpStore) => {
            return {
                request: (config) => {
                    const order = fpStore.get(constants.storage.orderIdToActivateKey, 'session');
                    config.headers[constants.http.associatedOrdersHeader] = order;
                    return config;
                }
            };
        });
    })

    .run( /*@ngInject*/ ($log, constants, $analytics, $rootScope, dataContext, $ngRedux) => {
        const appInfo = constants.appInfo;
        const dateFormat = 'llll';
        $log.info('Activation Wizard started ' + moment().format(dateFormat));
        $log.info('[Version] ' + appInfo.version);
        $log.info('[Build Date] ' + moment(new Date(appInfo.buildDate)).format(dateFormat));

        $rootScope.$on(constants.events.userInfoReceived, (e, user) => {
            var customer = $ngRedux.getState().account.customer.value;
            var system = $ngRedux.getState().system;
            $analytics.setUsername(user.UserName);
            $analytics.setSuperProperties({
                UserId: user.UserId,
                UserName: user.UserName
            });
            window.analytics.alias(user.UserId);
            window.analytics.identify(user.UserId, 
                {
                    name: customer.FirstName + " " + customer.LastName,
                    panel: system.panel.type,
                    planType: user.ServicePlanInfo.AdcServicePlan,
                    email: customer.Email
                });
            $analytics.setOptOut(dataContext.user.hasSetting(constants.suppressAnalyticsSettingKey, 'true'));

            const setCustomDimension = () => {
                const isBrowser = (dataContext.user.isInMobileApp() && dataContext.user.isBrowser()) || !dataContext.user.isInMobileApp();
                const custom = {
                    Source: isBrowser ? 'Browser' : 'Mobile App'
                };

                $analytics.setSuperProperties(custom);
            };

            setCustomDimension();
        });
    });

export default commonModule;