import template from './placeRequirementsModal.html';
import './placeRequirementsModal.scss';

class PlaceRequirementsModalController {
    /*@ngInject*/
    constructor(SharedState, $ngRedux, dataContext, constants, $state) {
        this.SharedState = SharedState;
        this.$ngRedux = $ngRedux;
        this.constants = constants;
        this.requirements = [
            { text: 'No electronics (like a router) are within 6\' of your panel', done: false },
            { text: 'No large metal appliances (like a refrigerator) are within 6\' of your panel', done: false },
            { text: 'An outlet is within a 6\' range and not controlled by an ON/OFF switch', done: false }
        ]
        this.isDisabled = true;
        this.showError = false;
        this.$state = $state;
    }

    allRequirementsMet() {
        this.status = this.requirements.every(function (requirement) {
            return requirement.done;
        });

        if (this.status === true) {
            this.isDisabled = false;
        }
        else {
            this.isDisabled = true;
        }

        return this.status;
    }

    close() {
        this.allRequirementsMet();

        if (this.isDisabled) {
            this.showError = true;
        }
        else {
            this.showError = false;
            this.completedRequirements();
            this.SharedState.turnOff('placeRequirementsModal');
            this.$state.go('instruction.plugin');
        }
        
    }
}

export default {
    template: template,
    bindings: {
        completedRequirements: '&'
    },
    controller: PlaceRequirementsModalController
};