import * as _ from 'lodash';
import moment from 'moment';

class Ensure {
    /**
     * Throws an exception if obj is null.
     * 
     * @param {string} argumentName 
     * @param {Object} obj 
     */
    static isNotNull(argumentName, obj) {
        if (_.isNull(obj)) {
            throw `${argumentName} cannot be null.`;
        }
    }

    /**
     * Throws an exception if obj is null or undefined.
     * 
     * @param {string} argumentName 
     * @param {Object} obj 
     */
    static isNotNullOrUndefined(argumentName, obj) {
        if (_.isNil(obj)) {
            throw `${argumentName} cannot be null or undefined.`;
        }
    }

    /**
     * Throws an exception if obj is not an object.
     * 
     * @param {string} argumentName 
     * @param {Object} obj 
     */
    static isObject(argumentName, obj) {
        if (!_.isObject(obj)) {
            throw `${argumentName} must be an object.`;
        }
    }

    /**
     * Throws an exception if obj is not an object.
     * 
     * @param {string} argumentName 
     * @param {Object} obj 
     */
    static isPlainObject(argumentName, obj) {
        if (!_.isPlainObject(obj)) {
            throw `${argumentName} must be a plain object.`;
        }
    }

    /**
     * Throws an exception if obj is not an string.
     * 
     * @param {string} argumentName 
     * @param {Object} obj 
     */
    static isString(argumentName, obj) {
        if (!_.isString(obj)) {
            throw `${argumentName} must be a string.`;
        }
    }

    /**
     * Throws an exception if obj is not an number.
     * 
     * @param {string} argumentName 
     * @param {Object} obj 
     */
    static isNumber(argumentName, obj) {
        if (!_.isNumber(obj)) {
            throw `${argumentName} must be a number.`;
        }   
    }

    /**
     * Throws an exception if obj is not an boolean.
     * 
     * @param {string} argumentName 
     * @param {Object} obj 
     */
    static isBoolean(argumentName, obj) {
        if (!_.isBoolean(obj)) {
            throw `${argumentName} must be a number.`;
        }   
    }

    /**
     * Throws an exception if obj is not an array.
     * 
     * @param {string} argumentName 
     * @param {Object} obj 
     */
    static isArray(argumentName, obj) {
        if (!_.isArray(obj)) {
            throw `${argumentName} must be an array.`;
        }
    }

    /**
     * Throws an exception if obj is not a moment object.
     * 
     * @param {string} argumentName 
     * @param {Object} obj 
     */
    static isMoment(argumentName, obj) {
        if (!moment.isMoment(obj)) {
            throw `${argumentName} must be a moment object.`;
        }
    }

    /**
     * Throws an exception if obj is not a date object.
     * 
     * @param {string} argumentName 
     * @param {Object} obj 
     */
    static isDate(argumentName, obj) {
        if (!_.isDate(obj)) {
            throw `${argumentName} must be a date.`;
        }
    }
}

export default Ensure;