import template from './complete.html';

class CompleteComponent {
    /*@ngInject*/
    constructor() {

    }
}

export default {
    template: template,
    bindings: {},
    controller: CompleteComponent
};