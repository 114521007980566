import * as _ from 'lodash';
import ngRedux from 'ng-redux';
import { Unsubscribe } from 'redux';

import ActionContext from "@app/common/action/actionContext.service";
import DataContext from "@app/common/data/dataContext.service";

class SensorItemInstallationController {
    public maxBoxSize: number;
    public boxLocation: any;
    public isInMobileApp: boolean;
    public isKeypad: boolean;
    public hasSkippedPrior: boolean;
    public sensor: any;
    public sensorName: any;
    public showSkippedModal: boolean;
    public sensors: any;
    public selectedSensor: any;
    public nameCtrl: any;
    public chimeCtrl: any;
    public orderProducts: any;
    public isUpdating: boolean;
    public isEmailLive: boolean;
    public swapType: string;
    public isModSwap: boolean;
    public handleSubmitPanelType: string;

    private unsubscribe: Unsubscribe;

    constructor(
        private $document: ng.IDocumentService,
        private $ngRedux: ngRedux.INgRedux,
        private $state: ng.ui.IStateService,
        private $timeout: any,
        public constants: any,
        public actionContext: ActionContext,
        public dataContext: DataContext,
        private $log: any) {
        // services
        'ngInject';
        this.$document = $document;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$timeout = $timeout;
        this.constants = constants;
        this.actionContext = actionContext;
        this.maxBoxSize = this.constants.maxBoxSize;
        this.boxLocation = this.constants.boxLocation.other;
        this.isInMobileApp = dataContext.user.isInMobileApp();
        this.isKeypad = this.actionContext.airfxFlow.isKeypad();
        this.$log = $log;
        this.hasSkippedPrior = actionContext.app.fpStore.get('skippedSensor', 'session');
        this.dataContext = dataContext;
        this.setIsEmailLive();
        this.swapType = this.$ngRedux.getState().system.swap.swapType;
    }

    private async setIsEmailLive() {
        this.isEmailLive = await this.dataContext.email.isEmailLive();
    }

    public $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this))(this);
        this.checkTrainingRequired();
        this.isModSwap = this.swapType === this.constants.swapType.mod;
        this.setPanelType();
        try {
            if (!_.isNil(this.sensor)) {
                this.sensorName = this.actionContext.device.getName(this.sensor);
            } else {
                let errorDeviceId = this.$ngRedux.getState().system.devices.selectedSensorID || this.sensor!!.SensorID;
                this.$log.error(
                    `Unable to find SelectedSensor from customer list of devices being activated. Current state: ${this.$ngRedux.getState()}. The sensorid we were trying to find was : ${errorDeviceId}`);
            }
        } catch (error) {
            let errorDeviceId = this.$ngRedux.getState().system.devices.selectedSensorID || this.sensor.SensorID;
            this.$log.error(`Error trying to get SelectedSensor name from customer list of devices being activated. Current state: ${this.$ngRedux.getState()}. The sensorid we were trying to find : ${errorDeviceId}`);
        }
    }

    public $onDestroy() {
        this.unsubscribe();
    }

    public hasDLCode() {
        return this.actionContext.product.hasDLCode(this.sensor.ProductSku);
    }

    public isInitialOrder() {
        return this.actionContext.order.isInitialOrder();
    }

    public sensorsToArray() {
        this.sensors = this.$ngRedux.getState().system.devices.sensorsByID;
        this.selectedSensor = this.$ngRedux.getState().system.devices.selectedSensorID;
        let copy = _.toArray(_.cloneDeep(this.sensors));
        let currentSensorArray = _.slice(copy, 0, this.selectedSensor);
        return currentSensorArray;
    }

    public getSensorLocation(sku: any) {
        let currentSensorCount = _.filter(this.sensorsToArray(), (s) => {
            return s.ProductSku === sku;
        }).length;

        let maxAllowedSensorsCountOnBox = _.find(this.constants.maxBoxSize, (max, sensorSku) => {
            return sensorSku === sku;
        });

        if (currentSensorCount <= maxAllowedSensorsCountOnBox) {
            this.boxLocation = _.find(this.constants.boxLocation, (msg, sensorSku) => {
                return sensorSku === sku;
            });
        }

        return true;
    }

    public handleBack() {
        if (this.isKeypad) {
            this.$state.go('airfx.keypadActivate', { sensorId: this.sensor.SensorID });
        } else {
            const isOverviewRequired = this.actionContext.device.isOverviewRequired(this.sensor);
            if (isOverviewRequired) {
                this.$state.go('sensor.item.overview');
            } else {
                this.$state.go('sensor.list');
            }
        }
    }

    public handleConnect(ctrl: any) {
        this.nameCtrl = ctrl;
    }

    public handleChimeConnect(ctrl: any) {
        this.chimeCtrl = ctrl;
    }

    public handleSubmit() {

        if (this.handleSubmitPanelType === this.constants.panelVersions.iqPanel) {
            this.chimeCtrl.submit().catch((err: any) => {
                if (err !== 'invalid') {
                    this.$state.go('error');
                }
            });
        }

        this.nameCtrl.submit()
            .then(() => {
                if (this.isKeypad) {
                    const product = _.find(this.orderProducts, { SensorNumber: this.sensor.SensorID });
                    if (!_.isNil(product)) {
                        this.actionContext.order.setOrderProductActivated(product);
                    }
                    this.$state.go('instruction.keypadsetup', { deviceName: this.sensor.Name });
                } else {
                    if (this.actionContext.product.hasTest(this.sensor.ProductSku)) {
                        this.$state.go('sensor.item.test', { sensorId: this.sensor.SensorID });
                    } else {
                        this.$state.go('sensor.item.complete', { sensorId: this.sensor.SensorID });
                    }
                }
           })
           .catch((err: any) => {
               if (err !== 'invalid') {
                   this.$state.go('error');
               }
           });
    }

    public showInstallationModal(sensor: any) {
        this.$timeout(() => {
            this.actionContext.device.showInstallationModal(sensor);
        });
    }

    public handleSkip() {
        if (this.isEmailLive) {
            this.showSkippedModal = !this.hasSkippedPrior;
            if (this.hasSkippedPrior) {
                this.$state.go('sensor.item.mark-skipped');
            }
        } else {
            this.showSkippedModal = true;
        }
    }

    public onNegativeSkippedModalClick() {
        this.showSkippedModal = false;
    }

    public onPositiveSkippedModalClick() {
        this.showSkippedModal = false;
        if (this.isEmailLive) {
            this.actionContext.app.fpStore.set('skippedSensor', true, 'session');
            this.dataContext.email.sendEmail("SensorsSetUp");
        }
        this.$state.go('sensor.item.mark-skipped');
    }

    public mapState(state: any) {
        const stateSlice = {
            eligibleSensorNames: state.application.eligibleSensorNames,
            isUpdating: state.system.devices.isUpdating,
            panelType: state.system.panel.type,
            orderProducts: state.account.order.itemsByID,
            sensor: state.system.devices.sensorsByID[state.system.devices.selectedSensorID] || this.sensor,
            isIq4Panel: state.account.user.isIq4Panel
        };

        // Only update the sensor mapped to this controller on the initial load and after it has been updated. 
        // If we map it on other state changes it may overwrite changes users have made to the sensors name. 
        // NOTE: An update has been completed when the isUpdating property of this controller is TRUE and the 
        // isUpdating property on the application state is false.
        if (!this.sensor || this.isUpdating && !stateSlice.isUpdating) {
            stateSlice.sensor = state.system.devices.sensorsByID[state.system.devices.selectedSensorID];
        }

        return stateSlice;
    }

    public checkTrainingRequired() {
        if (!this.isKeypad) {
            let isTrainingRequired = this.actionContext.product.isTrainingRequired(this.sensor.ProductSku);
            if (isTrainingRequired) {
                this.showInstallationModal(this.sensor);
            }
        }
    }

    private setPanelType() {
        if (this.isModSwap) {
            this.handleSubmitPanelType = this.$ngRedux.getState().account.airfxFlow.targetSku;
        } else {
            this.handleSubmitPanelType = this.$ngRedux.getState().system.panel.type;
        }
    }
}

export default {
    template: require('./installation.html'),
    bindings: {},
    controller: SensorItemInstallationController
};