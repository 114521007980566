import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import LocksHomeComponent from './home.component';
import LocksSetupComponent from './setup.component';
import commonModule from '../../common';

export default angular
.module('fp:activationswizard:sensor:locks', [
    commonModule.name
])
.component('awLocks', LocksHomeComponent)
.component('awLocksSetup', LocksSetupComponent);