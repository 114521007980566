import './editUserCodeConfirmation.scss';
import template from './editUserCodeConfirmation.html';

class EditUserCodeConfirmationController {
    /*@ngInject*/
    constructor() {
    }

    onClickContinue() {
        this.onContinue();
    }
}

export default {
    template: template,
    bindings: {
        title: '@',
        message: '@',
        onContinue: '&'
    },
    controller: EditUserCodeConfirmationController
};