import SmsService from './sms.service';
import LoggingService from './logging.service';
import EmailService from './email.service';

export default class DataContext {
    public application: { state: any };
    public system: { sensor: any };
    public wifi: any;
    public user: any;
    public panel: any;
    public account: any;
    public testMode: any;
    public address: any;
    public monitoring: any;
    public state: any;
    public configurations: any;
    public partner: any;
    public code: any;
    public support: any;
    public modSwap: any;
    public radioSwap: any;
    public kitCustomer: any;
    public customerBillingInfo: any;
    public logging: LoggingService;
    public sms: SmsService;
    public email: EmailService;

    /*@ngInject*/
    constructor(
        wifiService: any,
        userService: any,
        panelService: any,
        accountService: any,
        testModeService: any,
        addressService: any,
        monitoringService: any,
        stateService: any,
        orderService: any,
        configurationsService: any,
        partnerService: any,
        codeService: any,
        supportService: any,
        modSwapService: any,
        radioSwapService: any,
        kitCustomerService: any,
        customerBillingInfoService: any,
        smsService: SmsService,
        applicationStateService: any,
        loggingService: LoggingService,
        sensorService: any,
        emailService:any) {
        'ngInject';
        
        this.wifi = wifiService;
        this.user = userService;
        this.panel = panelService;
        this.account = accountService;
        this.testMode = testModeService;
        this.address = addressService;
        this.monitoring = monitoringService;
        this.state = stateService;
        this.configurations = configurationsService;
        this.application = {
            state: applicationStateService
        };
        this.system = {
            sensor: sensorService
        };
        this.account.order = orderService;
        this.partner = partnerService;
        this.code = codeService;
        this.support = supportService;
        this.modSwap = modSwapService;
        this.radioSwap = radioSwapService;
        this.kitCustomer = kitCustomerService;
        this.customerBillingInfo = customerBillingInfoService;
        this.logging = loggingService;
        this.sms = smsService;
        this.email = emailService;
    }
}
