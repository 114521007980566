import * as _ from 'lodash';
import ensure from '../util/ensure';
import moment from 'moment';

export const PANEL_TYPE_DETERMINED = 'PANEL_TYPE_DETERMINED';
export const PANEL_LOADED = 'PANEL_LOADED';
export const PANEL_LAST_MESSAGE_DATE = 'PANEL_LAST_MESSAGE_DATE';

export const PANEL_LAST_MESSAGE_DATE_REQUEST = 'PANEL_LAST_MESSAGE_DATE_REQUEST';
export const PANEL_LAST_MESSAGE_DATE_FAILURE = 'PANEL_LAST_MESSAGE_DATE_FAILURE';
export const PANEL_LAST_MESSAGE_DATE_SUCCESS = 'PANEL_LAST_MESSAGE_DATE_SUCCESS';

export const PANEL_VERSION_FETCH_REQUEST = 'PANEL_VERSION_FETCH_REQUEST';
export const PANEL_VERSION_FETCH_FAILURE = 'PANEL_VERSION_FETCH_FAILURE';
export const PANEL_VERSION_FETCH_SUCCESS = 'PANEL_VERSION_FETCH_SUCCESS';

export const PANEL_CONNECT = 'PANEL_CONNECT';
export const PANEL_CONNECT_FAILURE = 'PANEL_CONNECT_FAILURE';
export const PANEL_CONNECT_SUCCESS = 'PANEL_CONNECT_SUCCESS';

export const PANEL_SETTINGS_REQUEST = 'PANEL_SETTINGS_REQUEST';
export const PANEL_SETTINGS_REQUEST_FAILURE = 'PANEL_SETTINGS_REQUEST_FAILURE';
export const PANEL_SETTINGS_REQUEST_SUCCESS = 'PANEL_SETTINGS_REQUEST_SUCCESS';

export const PANEL_SETTINGS_ADD_REQUEST = 'PANEL_SETTINGS_ADD_REQUEST';
export const PANEL_SETTINGS_ADD_REQUEST_FAILURE = 'PANEL_SETTINGS_ADD_REQUEST_FAILURE';
export const PANEL_SETTINGS_ADD_REQUEST_SUCCESS = 'PANEL_SETTINGS_ADD_REQUEST_SUCCESS';

export const PANEL_SIGNAL_STRENGTH_FETCH_REQUEST = 'PANEL_SIGNAL_STRENGTH_FETCH_REQUEST';
export const PANEL_SIGNAL_STRENGTH_FETCH_FAILURE = 'PANEL_SIGNAL_STRENGTH_FETCH_FAILURE';
export const PANEL_SIGNAL_STRENGTH_FETCH_SUCCESS = 'PANEL_SIGNAL_STRENGTH_FETCH_SUCCESS';
export const PANEL_SIGNAL_STRENGTH_CAPTURED = 'PANEL_SIGNAL_STRENGTH_CAPTURED';

export const PANEL_ADD_SENSOR_QUEUE = 'PANEL_ADD_SENSOR_QUEUE';

export const PANEL_ADD_SENSOR_REQUEST = 'PANEL_ADD_SENSOR_REQUEST';
export const PANEL_ADD_SENSOR_FAILURE = 'PANEL_ADD_SENSOR_FAILURE';
export const PANEL_ADD_SENSOR_SUCCESS = 'PANEL_ADD_SENSOR_SUCCESS';

export const PANEL_UPDATED_EQUIPMENT_REQUEST = 'PANEL_UPDATED_EQUIPMENT_REQUEST';
export const PANEL_UPDATED_EQUIPMENT_FAILURE = 'PANEL_UPDATED_EQUIPMENT_FAILURE';
export const PANEL_UPDATED_EQUIPMENT_SUCCESS = 'PANEL_UPDATED_EQUIPMENT_SUCCESS';

export const DEVICES_REQUEST = 'DEVICES_REQUEST';
export const DEVICES_REQUEST_FAILURE = 'DEVICES_REQUEST_FAILURE';
export const DEVICES_REQUEST_SUCCESS = 'DEVICES_REQUEST_SUCCESS';
class PanelActions {
    /*@ngInject*/
    constructor($interval, $log, $ngRedux, $timeout, $q, constants, deviceActions, dataContext, productActions, $rootScope, fpStore) {
        this.$interval = $interval;
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
        this.$q = $q;
        this.constants = constants;
        this.deviceActions = deviceActions;
        this.dataContext = dataContext;
        this.productActions = productActions;
        this.$rootScope = $rootScope;
        this.fpStore = fpStore;

        $ngRedux.connect(this._mapState.bind(this))(this);
    }

    /**
     * Returns true if the customer's panel is an IQ Panel; otherwise, false.
     * 
     * @public
     * @returns {boolean} True if the customer's panel is an IQ Panel; otherwise, false. 
     */
    isIQPanel() {
        return this.constants.panelVersions.iqPanel === this.panelType;
    }

    /**
     * Returns true if the customer's panel is an XT Panel; otherwise, false.
     * 
     * @public
     * @returns {boolean} True if the customer's panel is an XT Panel; otherwise, false.
     */
    isXTPanel() {
        return this.constants.panelVersions.xtPanel === this.panelType;
    }

    /**
    * Returns true if the customer's panel is an Climax Hub; otherwise, false.
    * 
    * @public
    * @returns {boolean} True if the customer's panel is a Climax Hub; otherwise, false.
    */
    isClimaxHub() {
        return this.constants.panelVersions.climaxHub === this.panelType;
    }

    /**
     * Ping the user's panel. This method will continue to ping the user's panel until it responds or the max number of 
     * ping attempts is reached.
     * 
     * @returns {promise} A promise that is resolved when the panel responds and rejected if the max number 
     * of ping attemps is reached before the panel responds.
     */
    startPing() {
        const panelSerivce = this.dataContext.panel;
        const maxInterval = this.constants.polling.panel.pollingInterval;
        const maxAttempts = this.constants.polling.panel.maxIntervalCount;
        this.$log.debug('Polling panel for last message date.');

        this._onConnect();

        let state = this.$ngRedux.getState();
        let baselineDate = state.system.panel.lastMessageDate;

        let hasBaseline = !_.isNil(baselineDate);
        let isConnected = false;
        let isInitial = true;
        const initialInterval = this.$interval(() => {
            this.$rootScope.$emit(this.constants.events.callSupportNotification.name);
            this.$q.resolve()
                // Get a baseline message date if one does not already
                // exist in the application state.
                .then(() => {
                    return !hasBaseline
                        ? panelSerivce.getPanelLastMessageDate()
                        : this.$q.resolve(baselineDate);
                })
                .then((result) => {
                    baselineDate = result;
                })
                // Force communicaiton with the panel.
                .then(() => {
                    return isInitial
                        ? panelSerivce.getRequestRoundTrip()
                        : this.$q.resolve();
                })
                // Poll the panel for its last message date unti it does not 
                // match the baseline date.
                .then(() => {
                    return !isInitial
                        ? panelSerivce.getPanelLastMessageDate()
                        : this.$q.resolve(baselineDate);
                })
                .then((result) => {
                    if (_.isNil(result)) {
                        return;
                    }

                    this.$log.debug(`Last reported message date is [${result}]. `
                        + `The baseline message date is [${baselineDate}].`);

                    if (result !== baselineDate) {
                        isConnected = true;
                        this.$interval.cancel(initialInterval);
                    }
                })
                .then(() => {
                    hasBaseline = true;
                    isInitial = false;
                });
        }, maxInterval, maxAttempts);

        const chainedInterval = initialInterval
            .then(null, null, (tick) => {
                this.$log.debug(`Panel ping attempt [${tick + 1}] of [${maxAttempts}].`);
            })
            .then((result) => {
                return result === maxAttempts
                    ? this.$q.reject('timeout')
                    : this.$q.resolve();
            })
            .catch((error) => {
                if (error === 'canceled') {
                    if (isConnected) {
                        this.$log.info('Successfully connected to the customer\'s panel');
                        this._onConnectSuccess();
                    } else {
                        this.$log.info('Canceled connecting to the customer\'s panel');
                    }
                } else {
                    this.$log.error('Error connecting to the customer\'s panel', error);
                    this._onConnectFailure(error);
                }

                return error === 'canceled' && isConnected
                    ? this.$q.resolve()
                    : this.$q.reject(error);
            });

        chainedInterval._isPing = true;
        chainedInterval.$$intervalId = initialInterval.$$intervalId;
        return chainedInterval;
    }

    /**
     * Cancels the ping associated with the given promise.
     * 
     * @param {promise} promise A promise returned by {@link startPing}.
     * @returns {boolean} Returns true if the ping was successfully cancled.
     */
    cancelPing(promise) {
        if (promise && promise._isPing) {
            return this.$interval.cancel(promise);
        }

        return false;
    }

    /**
     * Fetches the current user's panel version and adds it to the application state.
     * 
     * @returns {promise} A promise that returns undefined if resolved and an error
     * if rejected. 
     */
    fetchPanelVersion() {
        this._onFetchVersion();
        if (this.isInitialOrder) {
            var panelData = {
                PanelType: this.isIq4Panel ? this.constants.panelVersions.iqPanel : this.constants.panelVersions.climaxHub,
                PanelVersion: "1" // we can safely use default data here, it's not currently used anywhere
            };
            this._onFetchVersionSuccess(panelData);
            return this.$q.resolve(panelData);
        }

        return this.dataContext.panel.getVersion()
            .then((result) => {
                this._onFetchVersionSuccess(result);
                return this.$q.resolve(result); 
            })
            .catch((error) => {
                this.$log.error('Error fetching the customer\'s panel version.', error);
                this._onFetchVersionFailure(error);
                return this.$q.reject('fetchPanelVersion');
            });
    }

    /**
     * Fetches the panels last message date and adds it to the application state.
     * 
     * @returns {promise} A promise that returns undefined if resolved and an error if
     * rejected. 
     */
    fetchLastMessageDate() {
        this._onFetchLastMessageDate();

        return this.dataContext.panel.getPanelLastMessageDate()
            .then((result) => {
                this._onFetchLastMessageDateSuccess(result);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error fetching the panel\'s last message date.', error);
                this._onFetchLastMessageDateFailure(error);
                return this.$q.reject(error);
            });
    }



    /**
     * Fetches the current signal strength for the user's panel and adds it to the application
     * state.
     * 
     * @returns {promise} A promise that returns undefined if resolved and an error if rejected.
     */
    fetchSignalStrength() {
        this._onFetchSignalStrength();

        return this.dataContext.panel.getPanelSignalStrength()
            .then((result) => {
                if (!_.isNil(result) && !_.isNil(result.AdcSignalStrengthViewModel)) {
                    this._onFetchSignalStrengthSuccess(result.AdcSignalStrengthViewModel.Date, result.AdcSignalStrengthViewModel.SignalStrength);

                    this.$log.info('Signal strength: [' + result.AdcSignalStrengthViewModel.SignalStrength + ']');
                    this.captureSignalStrength(result.AdcSignalStrengthViewModel.SignalStrength);
                }
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error fetching panel signal strength.', error);
                this._onFetchSignalStrengthFailure(error);
                return this.$q.reject(error);
            });
    }

    getSystemSettings(forceUpdate, verificationSettings) {
        this._dispatch({
            type: PANEL_SETTINGS_REQUEST
        });

        let userPhone = this.$ngRedux.getState().account.customer.value.Phone;
        let settingsCacheKey = `systemSettings-${userPhone || ''}`;

        let currentSettings = this.fpStore.get(settingsCacheKey, 'local');

        let cacheExpiration = this.constants.panel.cacheExpiration;

        let now = moment();
        let isCacheExpired = _.isNil(currentSettings) ||
            _.isNil(currentSettings.dateCreated) ||
            moment(currentSettings.dateCreated).add(cacheExpiration, 'milliseconds').isBefore(now);

        if (forceUpdate || isCacheExpired) {
            let isVerified = false;
            let maxIterations = this.constants.panel.maxIterations;
            let iterationTime = this.constants.panel.iterationTime;

            let systemSettings;
            var verificationInterval = this.$interval(() => {
                    return this.dataContext.panel.getSystemSettings().then((systemSettingsResponse) => {
                        if (!_.isNil(systemSettingsResponse) && systemSettingsResponse.Success && !_.isEmpty(verificationSettings)) {
                            _.each(verificationSettings,
                                (verificationSetting) => {
                                    if (verificationSetting === 'Wifi Network Name') {
                                        let wifiSetting =
                                            this.getWifiNetWorkSetting(systemSettingsResponse.Settings);

                                        if (!_.isEmpty(wifiSetting)) {
                                            systemSettings = systemSettingsResponse.Settings;
                                            isVerified = true;
                                            this.$interval.cancel(verificationInterval);
                                        }
                                    }
                                });
                        }
                        else if (!_.isNil(systemSettingsResponse) && systemSettingsResponse.Success && _.isEmpty(verificationSettings)) {
                            systemSettings = systemSettingsResponse.Settings;
                            isVerified = true;
                            this.$interval.cancel(verificationInterval);
                        }
                    });
                },
                iterationTime,
                maxIterations);

            return this.dataContext.panel.requestUploadOfSystemSettings().then(() => {
                return verificationInterval.then(() => {
                    return this.$q.reject(
                        `Unable to get settings ${maxIterations * (iterationTime / 1000)} seconds after requesting updated system settings.`);
                }).catch((message) => {
                    if (message === 'canceled' &&
                        isVerified &&
                        !_.isNil(systemSettings)) {

                        let cachedSettings = {
                            settings: systemSettings,
                            dateCreated: now
                        };

                        this.fpStore.set(settingsCacheKey, cachedSettings, 'local');

                        this._dispatch({
                            type: PANEL_SETTINGS_REQUEST_SUCCESS
                        });

                        return cachedSettings.settings;

                    } else {
                        return this.$q.reject(message);
                    }
                });
            }).catch((error) => {
                let errorMessage = 'Failed to get system Settings: ' + error;
                this.$log.error(errorMessage);
                this._dispatch({
                    type: PANEL_SETTINGS_REQUEST_FAILURE
                });
                return this.$q.reject(errorMessage);
            });
            
        }

        this._dispatch({
            type: PANEL_SETTINGS_REQUEST_SUCCESS
        });

        return this.$q.resolve(currentSettings.settings);
    }

    getDevices(forceUpdate, dlCodes) {
        this._dispatch({
            type: DEVICES_REQUEST
        });

        let userPhone = this.$ngRedux.getState().account.customer.value.Phone;
        let deviceCacheKey = `systemControlDevices-${userPhone || ''}`;

        let currentDevices = this.fpStore.get(deviceCacheKey, 'local');

        let cacheExpiration = this.constants.panel.cacheExpiration;
        let isCacheExpired = _.isNil(currentDevices) || _.isNil(currentDevices.dateCreated) ||
            moment(currentDevices.dateCreated).add(cacheExpiration, 'milliseconds').isBefore(moment());

        if (forceUpdate || isCacheExpired) {
            let isVerified = false;
            let maxIterations = this.constants.panel.maxIterations;
            let iterationTime = this.constants.panel.iterationTime;

            let systemDevices;

            let numIterations = 0;
            let verificationInterval = this.$interval(() => {
                return this.dataContext.system.sensor.getSystemControlDevices().then((deviceResponse) => {
                    if (!_.isNil(deviceResponse) && deviceResponse.Success && !_.isEmpty(dlCodes) && !_.isEmpty(deviceResponse.Devices)) {
                        let promises = [];
                        numIterations++;

                        let nullDLCodeDevices = _.filter(deviceResponse.Devices,
                            (device) => { return (_.isNil(device.DlCode) || device.DlCode === "000000") && device.DeviceId !== this.constants.panelDeviceId; });

                        if (!_.isNil(nullDLCodeDevices) && !_.isEmpty(nullDLCodeDevices) && ((numIterations === 1) || (numIterations % 5 === 0))) {
                            this.dataContext.panel.requestUploadOfSystemSettings(nullDLCodeDevices);
                        }

                        _.each(dlCodes,
                            (dlCode) => {
                                var promise =
                                    this.deviceActions.verifySensorByDLCode(
                                        deviceResponse.Devices,
                                        dlCode);
                                promises.push(promise);
                            });

                        return this.$q.all(promises).then(() => {
                            this.$interval.cancel(verificationInterval);

                            systemDevices = deviceResponse.Devices;
                            isVerified = true;
                        }).catch(() => {
                            this.$log.info('One of the sensors failed DL code verification. Trying again.');
                            if (numIterations >= (this.constants.panel.maxIterations / 2)) {
                                this.dataContext.panel.requestUpdatedDevices();
                            }
                        });
                    }
                    else if (!_.isNil(deviceResponse) && deviceResponse.Success && _.isEmpty(dlCodes)) {
                        systemDevices = deviceResponse.Devices;
                        isVerified = true;
                        this.$interval.cancel(verificationInterval);
                    }
                }).catch((error) => {
                    this.$interval.cancel(verificationInterval);
                    return this.$q.reject(error);
                });
            }, iterationTime, maxIterations);

            return this.dataContext.panel.requestUpdatedDevices().then(() => {
                return verificationInterval.then(() => {
                    return this.$q.reject(`Panel did not return matching devices ${maxIterations * (iterationTime / 1000)} seconds after intiating the request.`);
                }).catch((message) => {
                    if (message === 'canceled' && isVerified) {
                        this._dispatch({
                            type: DEVICES_REQUEST_SUCCESS
                        });

                        var cachedDevices = {
                            devices: systemDevices,
                            dateCreated: moment()
                        };

                        this.fpStore.set(deviceCacheKey, cachedDevices, 'local');

                        return cachedDevices.devices;

                    } else {
                        return this.$q.reject(message);
                    }
                });
            }).catch((error) => {
                this.$log.error(error);
                this._dispatch({
                    type: DEVICES_REQUEST_FAILURE
                });

                return this.$q.reject(error);
            });
        }

        this._dispatch({
            type: DEVICES_REQUEST_SUCCESS
        });

        // return the cached version
        return this.$q.resolve(currentDevices.devices);
    }

    getWifiNetWorkSetting(settings) {
        var wifiSetting = _.find(settings,
            (setting) => {
                return setting.SystemSettingName === 'Wi-Fi Network Name' ||
                    setting.SystemSettingName === 'Wifi Network Name';
            });

        if (!_.isNil(wifiSetting) && wifiSetting.UploadedValue === 'Climax Tech Co.Ltd.') {
            wifiSetting.UploadedValue = '';
        }

        return wifiSetting;
    }

    /**
     * Enable interactive mode on the customer's panel. if disableInteractiveMode() is
     * not explicitly called interactive mode will be disabled after 1 hour.
     * 
     * @returns {promise} A promise that returns undefined if resolved and an error if rejected.
     */
    enableInteractiveMode() {
        return this.$q.resolve(false) //this.dataContext.panel.isInteractiveModeEnabled()
            .then((res) => {
                if (res === true) {
                    this.$log.info('Interactive mode is already enabled for the customer\'s panel.');
                    return this.$q.resolve();
                }

                return this.dataContext.panel.enableInteractiveMode()
                    .then(() => {
                        this.$log.info('Interactive mode enabled for the customer\'s panel.');
                    });
            })
            .catch((err) => {
                this.$log.error('Error enabling interactive mode for the customer\'s panel.', err);
                return this.$q.reject('enableInteractiveMode');
            });
    }

    /**
     * Disable interactive mode on the customer's panel.
     * 
     * @returns {promise} A promise that returns undefined if resolved and an error if rejected. 
     */
    disableInteractiveMode() {
        return this.dataContext.panel.disableInteractiveMode();
    }

    /**
     * 
     * 
     * @returns {promise} A promise that returns a boolean if resolved and an error if rejected. 
     */
    isInteractiveModeEnabled() {
        return this.dataContext.panel.isInteractiveModeEnabled();
    }

    /**
     * Add the given sensor to the customer's panel.
     * 
     * @param {Object} sensor
     * @returns {promise} A promise that return undefined if resolved and an error if rejected.
     */
    addSensor(sensor) {
        ensure.isObject('sensor', sensor);
        if (this._hasDeviceBeenAddedToPanel(sensor)) {
            return this.$q.resolve(sensor);
        }

        this._dispatchAddSensorRequest(sensor);
        this.$log.debug('Adding sensor to the customer\s panel...');
        return this.dataContext.panel.addSensor(sensor)
            .then((sensor) => {
                this._dispatchAddSensorSuccess(sensor);
                this.$log.debug('Successfully added sensor to the customer\'s panel.');
                return this.$q.resolve(sensor);
            })
            .catch((error) => {
                this._dispatchAddSensorFailure(sensor, error);
                this.$log.error(`Error adding sensor with DLCode: [${sensor.DLCode}] SKU: [${sensor.ProductSku}] to the customer\'s panel.`, error); 
                return this.$q.reject();
            });
    }

    CheckKeypadStatus(deviceId) {
        this.$log.debug('Checking device status from customer\'s panel...');
        return this.dataContext.panel.CheckKeypadStatus(deviceId)
            .then((result) => {
                return this.$q.resolve(result);
            })
            .catch((error) => {
                this.$log.error('Error checking device status from the customer\'s panel.', error);
                return this.$q.reject(error);
            });
    }

    panelLoaded(isConnected) {
        this._dispatch({
            type: PANEL_LOADED,
            payload: {
                isConnected
            },
            metadata: {
                description: 'The panel is loaded',
                sectionType: this.constants.sectionTypes.panel,
                persist: true
            }
        });
    }

    captureSignalStrength(signalStrength) {
        this._dispatch({
            type: PANEL_SIGNAL_STRENGTH_CAPTURED,
            metadata: {
                description: 'Signal strength: [' + signalStrength + ']',
                sectionType: this.constants.sectionTypes.panel,
                persist: true
            }
        });
    }

    clearSkippedDevices() {
        _.each(this.devices, device => {
            device.isSkipped = false;
        });
    }

    /**
      * Add the child orders in the beginning of the initialization for initial orders
      * 
      * @returns {promise} 
      */
    addChildSensorsForInitialWithChildOrders() {
        let childOrderNotActivated = _.filter(this.items, (product) => { return _.isNumber(product.SensorNumber) && product.IsConnected === false && (!_.isNil(product.DLCode) || (_.isNil(product.DLCode) && !_.isNil(product.MacAddress))); });

        //filter out connected devices
        let devicesConnected = _.filter(this.devices, (device) => {
            return device.IsConnected === true;
        });

        _.forEach(devicesConnected, (device) => {
            let mappedSensor = _.find(childOrderNotActivated, { SensorNumber: device.SensorID });
            if (!_.isNil(mappedSensor)) {
                _.remove(childOrderNotActivated, (sensor) => {
                    return sensor.SensorNumber === mappedSensor.SensorNumber;
                });
            }

        });

        let promise = this.$q.resolve();
        let sensorNums = [];
        let dlCodes = [];
        _.forEach(childOrderNotActivated,
            (item) => {
                sensorNums.push(item.SensorNumber);
            });
        _.forEach(childOrderNotActivated, (item) => {
            promise = promise
                .then(() => {
                    return this.dataContext.account.order.convertToDevice(item.OrderProductID);
                }).then((res) => {
                    dlCodes.push(res.SerialNumber);
                    return this.addSensor(res);
                });
        });

        return promise.then(() => {
            if (dlCodes.length !== 0) {
                return this.getDevices(true, dlCodes);
            }
        });
    }

    
    retrieveChimeSettings(sensor) {
        return this.getSystemSettings(false, ['Chime Type']).then((chimes) => {
            var chimeSettings = _.filter(chimes, (chime) => {
                if (chime.SystemSettingName === 'Chime Type') {
                    if (sensor != undefined) {
                        if (chime.DeviceId === sensor.SensorID.toString()) {
                            return chime;
                        }
                    } else {
                        return chime.SystemSettingName === 'Chime Type';
                    }
                }
            });

            return chimeSettings;

        }).catch((error) => {
            this.$log.error('Retrieving Chime Settings', error);
            return this.$q.reject(error);
        });
    }

    setChime(chimeSettings) {
        return this.dataContext.panel.downloadSettingsToPanel(chimeSettings).then((result => {
            return result;
        })).catch((error) => {
            this.$log.error('Setting Chime Value', error);
            return this.$q.reject(error);
        });
    }

    _dispatch(obj) {
        this.$ngRedux.dispatch(obj);
    }

    _onConnect() {
        this._dispatch({
            type: PANEL_CONNECT
        });
    }

    _onConnectSuccess() {
        this._dispatch({
            type: PANEL_CONNECT_SUCCESS
        });
    }

    _onConnectFailure(error) {
        this._dispatch({
            type: PANEL_CONNECT_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onFetchLastMessageDate() {
        this._dispatch({
            type: PANEL_LAST_MESSAGE_DATE_REQUEST
        });
    }

    _onFetchLastMessageDateFailure(error) {
        this._dispatch({
            type: PANEL_LAST_MESSAGE_DATE_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onFetchLastMessageDateSuccess(data) {
        this._dispatch({
            type: PANEL_LAST_MESSAGE_DATE_SUCCESS,
            payload: {
                data: data
            }
        });
    }

    _onFetchVersion() {
        this._dispatch({
            type: PANEL_VERSION_FETCH_REQUEST
        });
    }

    _onFetchVersionFailure(error) {
        this._dispatch({
            type: PANEL_VERSION_FETCH_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onFetchVersionSuccess(data) {
        this._dispatch({
            type: PANEL_VERSION_FETCH_SUCCESS,
            payload: {
                data: data
            }
        });
    }

    _onFetchSignalStrength() {
        this._dispatch({
            type: PANEL_SIGNAL_STRENGTH_FETCH_REQUEST
        });
    }

    _onFetchSignalStrengthSuccess(lastSignalDate, signalStrength) {
        this._dispatch({
            type: PANEL_SIGNAL_STRENGTH_FETCH_SUCCESS,
            payload: {
                lastSignalDate: lastSignalDate,
                signalStrength: signalStrength
            }
        });
    }

    _onFetchSignalStrengthFailure(error) {
        this._dispatch({
            type: PANEL_SIGNAL_STRENGTH_FETCH_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _dispatchAddSensorRequest(sensor) {
        this.$ngRedux.dispatch({
            type: PANEL_ADD_SENSOR_REQUEST,
            payload: {
                sensor: sensor
            }
        });
    }

    _dispatchAddSensorSuccess(sensor) {
        this.$ngRedux.dispatch({
            type: PANEL_ADD_SENSOR_SUCCESS,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.panel,
                description: `Sensor with DLCode: [${sensor.DLCode}] SKU: [${sensor.ProductSku}] added successfully.`,
                actionType: 'Info'
            },
            payload: {
                sensor: sensor
            }
        });
    }

    _dispatchAddSensorFailure(sensor, error) {
        this.$ngRedux.dispatch({
            type: PANEL_ADD_SENSOR_FAILURE,
            payload: {
                sensor,
                error
            }
        });
    }

    // Returns true if the device has already been added to the customer's panel; otherwise, false. A device is
    //considered added to the customer's panel if it exists in the application state.
    _hasDeviceBeenAddedToPanel(device) {
        ensure.isObject('device', device);

        return _.some(this.devices, x => {
            return this._areDeviceCodesEqual(device, x);
        });
    }

    // Returns true if both devices have non null matching DLCodes; otherwise, false.
    _areDeviceCodesEqual(deviceA, deviceB) {
        ensure.isObject('deviceA', deviceA);
        ensure.isObject('deviceB', deviceB);
        return deviceA.DLCode && deviceB.DLCode && deviceA.DLCode.toUpperCase() === deviceB.DLCode.toUpperCase() && deviceA.IsConnected === true;
    }

    _mapState(state) {
        return {
            devices: _.map(state.system.devices.sensorsByID),
            items: _.map(state.account.order.itemsByID),
            panelType: state.system.panel.type,
            isInitialOrder: state.account.user.isInitialOrder,
            isIq4Panel: state.account.user.isIq4Panel
        };
    }
}

export default PanelActions;