export default class SmsService {

    constructor(private Restangular: any) {
        'ngInject';
    }

    public async sendInitialFpSmsForEmergencyContacts() {
        return this.Restangular
            .all('sms/emergency-contacts/send-fp-initial')
            .withHttpConfig({
                cache: false
            })
            .post();
    }

    public async enrollCentralStationSms() {
        return this.Restangular
            .all('sms/emergency-contacts/enroll')
            .withHttpConfig({
                cache: false
            })
            .post();
    }

}
