import * as _ from 'lodash';

import template from './armAway.html';

class ArmAwayController {
    /*@ngInject*/
    constructor(deviceActions, constants, $state, $interval, $ngRedux) {
        this.deviceActions = deviceActions;
        this.constants = constants;
        this.$state = $state;
        this.$interval = $interval;
        this.$ngRedux = $ngRedux;
        this.isPanelArmedAway = false;
        this.canSkip = false;
    }

    $onInit() {
        this.isIq4Panel = this.$ngRedux.getState().account.user.isIq4Panel;
        this.pollPanelStatus();        
    }

    pollPanelStatus() {
        // number of milliseconds between each interval
        const intervalDelay = this.constants.polling.training.pollingInterval;
        // indefinite interval to keep checking for the panel status
        this.$interval(() => {
            this.deviceActions.getPanelStatus().then((result) => {
                let panelStatus = result;
                this.isPanelArmedAway = panelStatus === this.constants.deviceStatus.armedAway.ID;
                this.canSkip = panelStatus === this.constants.deviceStatus.disarmed.ID;
            });
        }, intervalDelay);
    }

    skip() {
        if (this.isIq4Panel) {
            this.$state.go('instruction.bluetooth');
        } else {
            this.$state.go('usercode.home');
        }        
    }
}

export default {
    template: template,
    bindings: {},
    controller: ArmAwayController
};