import * as _ from 'lodash';
import template from './list-item.html';

class SensorItemController {
    /*@ngInject*/
    constructor (actionContext, $state, constants) {
        this.actionContext = actionContext;
        this.$state = $state;
        this.constants = constants;
        this.isSmartLock = _.get(this.sensor, 'IsSmartLock', false);
    }
    
    disableExceptionSkus() {
        if (this.hasExceptionSkus && _.includes(this.constants.sensorListExceptionSkus, this.sensor.ProductSku)) {
            return this.sensor.isComplete;
        }
    }

    showCompleteIcon() {
        return !this.isSkipped && !this.isTampered && !this.isMalfunctioning && this.isConnected && (this.isReview || this.isComplete);
    }

    showDisabledIcon() {
        return this.isSkipped;
    }

    showIcon() {
        return (this.showWarningIcon() || this.showDisabledIcon() || this.showCompleteIcon());
    }

    hideIndicators() {
        return (this.isInitialOrder || this.$state.$current.name === 'sensor.skipped-review');
    }

    showWarningIcon() {
        return !this.isSkipped && (!this.isConnected || this.isTampered || this.isMalfunctioning) && !this.isSmartLock;
    }
}

export default {
template: template,
    bindings: {
    isComplete: '<',
    isConnected: '<',
    isDisabled: '<',
    isEnabled: '<',
    isMalfunctioning: '<',
    isReview: '<',
    isSkipped: '<',
    isTampered: '<',
    onClick: '&',
    sensor: '<',
    hasExceptionSkus: '<',
    isInitialOrder: '<'
    },
controller: SensorItemController
};