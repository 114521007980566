import * as _ from 'lodash';

import template from './options.html';

class OptionsComponent {
    /*@ngInject*/
    constructor($ngRedux, $state, $timeout, actionContext, constants, appActions, $sce, dataContext) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$timeout = $timeout;
        this.actionContext = actionContext;
        this.constants = constants;
        this.appActions = appActions;
        this.$sce = $sce;
        this.dataContext = dataContext;
        this.isLoading = true;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.docusignLink = '';

        // Check if this customer can sign a contract
        this.dataContext.account.canSignContract().then((response) => {
            this.isLoading = false;
            if (response === false) {
                // Proceed to NERM step
                this.$state.go('testmode.home');
            }
        });
    }

    onClickContinue() {
        this.actionContext.tosOptions.tosAccepted();
        this.$state.go('tos.home');
    }

    onClickSkip() {
        this.actionContext.tosOptions.tosSkipped();
        this.$state.go('testmode.home');
    }

    $onDestroy() {
        this.unsubscribe();
    }

    _mapState(state) {
        return {
            panel: state.system.panel
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: OptionsComponent
};