import template from './keychainRemote.html';

class KeychainRemoteController {
    /*@ngInject*/
    constructor(constants, $state, actionContext, $ngRedux) {
        this.constants = constants;
        this.$state = $state;
        this.actionContext = actionContext;
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    goToNext() {
        this.$state.go('sensor.keychain-remote-overview');
    }

    _mapState(state) {
        return {
            deviceSelected: state.system.devices.sensorsByID[state.system.devices.selectedSensorID]
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: KeychainRemoteController
};