class TestModeService {
    /*@ngInject*/
    constructor (Restangular, $state) {
        this.Restangular = Restangular;
        this.$state = $state;
    }

    setTestMode(endDate, onTest) {
        return this.Restangular
            .all('TestMode')
            .all('Start')
            .post({
                'OnTest': onTest,
                'TestPeriod': '',
                'StartDate': null,
                'EndDate': onTest ? endDate : null
            });
    }

    getTestModeEndDate() {
        return this.Restangular
            .all('TestMode')
            .one('EndDate')
            .withHttpConfig({cache: false})
            .get();
    }
}

export default TestModeService;