import template from './zWaveModal.html';

class ZWaveModalController {
    /*@ngInject*/
    constructor(SharedState, $ngRedux, dataContext, constants, $state) {
        this.SharedState = SharedState;
        this.$ngRedux = $ngRedux;
        this.constants = constants;
        this.$state = $state;
    }
    
    close() {
        this.$state.go('airfx.disconnectPanel');
    }
}

export default {
    template: template,
    bindings: {},
    controller: ZWaveModalController
};