import template from './invalidBarCodeModal.html';

class InvalidBarCodeModalController {
    /*@ngInject*/
    constructor(SharedState, $ngRedux) {
        this.SharedState = SharedState;
        this.$ngRedux = $ngRedux;
    }
    close() {
        this.SharedState.turnOff('invalidBarCodeModal');
    }
}

export default {
    template: template,
    bindings: {},
    controller: InvalidBarCodeModalController
};  