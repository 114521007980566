import * as _ from 'lodash';

import template from './items.html';

class ItemsController {
    /*@ngInject*/
    constructor ($ngRedux, SharedState, actionContext, constants) {
        this.$ngRedux = $ngRedux;
        this.SharedState = SharedState;
        this.actionContext = actionContext;
        this.constants = constants;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    onClick(sku) {
        if (this.canClick(sku)) {
            this.selectedSku = sku;
            this.SharedState.turnOn('itemModal');
        }
    }

    openNonSkuModal(nonSkuItem) {
        this.selectedSku = nonSkuItem;
        this.SharedState.turnOn('itemModal');
    }

    canClick(sku) {
        return !_.isNil(this.actionContext.product.getImage(sku));
    }

    _mapState(state) {
        return {
            skus: _.keys(this.actionContext.order.exposeItemsBySku(state))
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: ItemsController
};