import * as _ from 'lodash';
import ngRedux from'ng-redux';
import { Unsubscribe } from "redux";

import DataContext from "@app/common/data/dataContext.service";

class SensorItemOverviewController {
    public isInMobileApp: boolean;
    public hasSkippedPrior: boolean;
    public isEmailLive: boolean;
    public showSkippedModal:boolean;
    public setupName: string;
    public sensor: any; 

    private unsubscribe: Unsubscribe;

    constructor(private $ngRedux:ngRedux.INgRedux, 
        private dataContext: DataContext, 
        private $state: ng.ui.IStateService,
        private appActions: any, 
        private actionContext: any) {
        'ngInject';
        this.$ngRedux = $ngRedux;
        this.actionContext = actionContext;
        this.isInMobileApp = dataContext.user.isInMobileApp();
        this.$state = $state;
        this.appActions = appActions;
        this.hasSkippedPrior = actionContext.app.fpStore.get('skippedSensor', 'session');
        this.dataContext = dataContext;
        this.setIsEmailLive();
    }

    private async setIsEmailLive() {
        this.isEmailLive = await this.dataContext.email.isEmailLive();
    }

    public $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this.mapState)(this);
        this.setupName = this.actionContext.product.getSetupName(this.sensor.ProductSku);
    }

    public $onDestroy() {
        this.unsubscribe();
    }

    public handleSkip() {
        if (this.isEmailLive) {
            this.showSkippedModal = !this.hasSkippedPrior;
            if (this.hasSkippedPrior) {
                this.$state.go('sensor.item.mark-skipped');
            }
        } else {
            this.showSkippedModal = true;
        }
    }

    public onNegativeSkippedModalClick() {
        this.showSkippedModal = false;
    }

    public onPositiveSkippedModalClick() {
        this.showSkippedModal = false;
        if (this.isEmailLive) {
            this.actionContext.app.fpStore.set('skippedSensor', true, 'session');
            this.dataContext.email.sendEmail("SensorsSetUp");
        }
        this.$state.go('sensor.item.mark-skipped');
    }

    public mapState(state:any) {
        return {
            isInitialOrder: state.account.user.isInitialOrder,
            sensor: state.system.devices.sensorsByID[state.system.devices.selectedSensorID]
        };
    }
}

export default {
    template: require('./overview.html'),
    bindings: {},
    controller: SensorItemOverviewController
};