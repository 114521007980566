import * as _ from 'lodash';
import error from '../../util/error';
import moment from 'moment';

import { WIFI_CHANGED, WIFI_CHANGED_FAILURE, WIFI_PANEL_ON, WIFI_NETWORKS_REFRESHED, WIFI_DATE_RECEIVED,
WIFI_NETWORKS_FETCH_REQUEST, WIFI_NETWORKS_FETCH_SUCCESS, WIFI_NETWORKS_FETCH_FAILURE, WIFI_NETWORKS_SET_REQUEST,
WIFI_NETWORKS_SET_FAILURE, WIFI_NETWORKS_SET_SUCCESS, WIFI_CLEAR_ERROR, WIFI_CONNECTED } from '../../action/wifi.actions';

const initialState = {
    ignore: ['networks'],
    networkName: null,
    lastNetworkDateReceived: null,
    lastUpdatedDate: null,
    message: null,
    isFetching: false,
    isFetched: false,
    isConnecting: false,
    isConnected: false,
    hasError: null,
    networks: null,
    showCannotConnectModal: false
};

export default (state = initialState, action = {}) => {
    let newState = null;
    switch (action.type) {
        case WIFI_CHANGED:
            newState = _.mergeCustom({}, state, {
                networkName: action.payload.networkName
            });
            break;
        case WIFI_NETWORKS_REFRESHED:
            if (action.payload.message)
                newState = _.mergeCustom({}, state, {
                    message: action.payload.message
                });
            else 
                newState = state;
            break;
        case WIFI_DATE_RECEIVED:
            newState = _.mergeCustom({}, state, {
                lastNetworkDateReceived: action.payload.lastNetworkDateReceived
            });
            break;
        case  WIFI_NETWORKS_FETCH_REQUEST:
            newState = _.mergeCustom({}, state, {
                isFetching: true
            });
            break;
        case WIFI_NETWORKS_FETCH_SUCCESS:
            newState = _.mergeCustom({}, state, {
                isFetching: false,
                isFetched: true,
                lastUpdatedDate: moment.utc(),
                networks:  action.payload.networks
            });
            break;
        case WIFI_NETWORKS_FETCH_FAILURE:
            newState = _.mergeCustom({}, state, {
                isFetching: false,
                hasError: true,
                message: error.parse(action.payload.error)
            });
            break;
        case WIFI_NETWORKS_SET_REQUEST:
            newState = _.mergeCustom({}, state, {
                isConnecting: true
            });
            break;
        case WIFI_NETWORKS_SET_SUCCESS:
            newState = _.mergeCustom({}, state, {
                isConnecting: false,
                isConnected: true,
                lastUpdatedDate: moment.utc()
            });
            break;
        case WIFI_NETWORKS_SET_FAILURE:
            newState = _.mergeCustom({}, state, {
                isConnecting: false,
                isConnected: null,
                networkName: null,
                hasError: true,
                message: error.parse(action.payload.error),
                showCannotConnectModal: true
            });
            break;
        case WIFI_CLEAR_ERROR:
            return _.mergeCustom({}, state, {
                hasError: false,
                showCannotConnectModal: false,
                message: null
            });
        case WIFI_CONNECTED:
            return _.mergeCustom({}, state, {
                networkName: action.payload.networkName,
                isConnecting: false,
                isConnected: true
            });
        default:
            newState = state;
            break;
    }

    return newState;
};

