import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';
import './instruction.scss';

import commonModule from '../common';
import { wifiModule } from '../wifi';
import instructionHomeComponent from './home.component';
import instructionPlaceComponent from './place.component';
import instructionPluginComponent from './plugin.component';
import instructionPowerComponent from './power.component';
import instructionKeypadComponent from './keypad.component';
import instructionBluetoothComponent from './bluetooth.component';
import placeRequirementsModalComponent from './placeRequirementsModal.component';

export default angular
    .module('fp:activationswizard:instruction', [
        'ui.router',
        ngRedux,
        commonModule.name,
        wifiModule.name
    ])
    .config( /*@ngInject*/($stateProvider, constants) => {
        $stateProvider
            .state('instruction',
                {
                    abstract: true,
                    parent: 'secure',
                    url: '/instruction',
                    template: '<ui-view></ui-view>',
                    data: {
                        next: /*@ngInject*/ ($state, appActions) => {
                            appActions.completeModule(constants.sectionTypes.instruction);
                            $state.go('panel.searching');
                        },
                        prev: /*@ngInject*/ ($state) => {
                            $state.go('menu.home');
                        },
                        sectionType: constants.sectionTypes.instruction,
                        theme: 'app-theme-default-navbar-gray'
                    },
                    resolve: {
                        isBoxCheckComplete: /*@ngInject*/ (grant, setupComplete) => {
                            return grant.only({
                                test: 'isBoxCheckComplete',
                                state: 'boxcheck.home'
                            });
                        }
                    }
                })
            .state('instruction.home',
                {
                    url: '',
                    template: '<aw-instruction-home></aw-instruction-home>',
                    onEnter: /*@ngInject*/ (appActions) => {
                        appActions.startModule(constants.sectionTypes.instruction);
                    }
                })
            .state('instruction.place',
                {
                    url: '/place',
                    template: '<aw-instruction-place></aw-instruction-place>',
                    data: {
                        prevState: 'menu.home'
                    }
                })
            .state('instruction.pluginswap',
                {
                    url: '/swapplugin',
                    template: '<aw-instruction-plugin></aw-instruction-plugin>',
                    data: {
                        next: /*@ngInject*/ ($state) => {
                            $state.go('instruction.keypadswap');
                        },
                        prev: /*@ngInject*/ ($state) => {
                            $state.go('instruction.placeswap');
                        }
                    }
                })
            .state('instruction.placeswap',
                {
                    url: '/swaplace',
                    template: '<aw-instruction-place></aw-instruction-place>',
                    data: {
                        nextState: 'instruction.pluginswap'
                    }
                })
            .state('instruction.radiopluginswap',
                {
                    url: '/radioswapplugin',
                    template: '<aw-instruction-plugin></aw-instruction-plugin>',
                    data: {
                        prevState: 'instruction.placeswap',
                        nextState: 'airfx.radioswapSensorTransfer',
                        swapType: 'radio'
                    }
                })
            .state('instruction.keypadswap',
                {
                    url: '/swapkeypad',
                    template: '<aw-instruction-keypad></aw-instruction-keypad>',
                    data: {
                        prevState: 'instruction.pluginswap',
                        nextState: 'wifi.home'
                    }
                })
            .state('instruction.power',
                {
                    url: '/power',
                    template: '<aw-instruction-power></aw-instruction-power>'
                })
            .state('instruction.plugin', {
                url: '/plugin',
                template: '<aw-instruction-plugin></aw-instruction-plugin>',
                data: {
                    next: /*@ngInject*/ ($state, $ngRedux) => {
                        if ($ngRedux.getState().account.user.isAmazonUser ||
                            $ngRedux.getState().account.origin.startedOnAmazon) {
                            $state.go('account.contact');
                        }
                        else if ($ngRedux.getState().system.swap.swapType == constants.swapType.mod &&
                            $ngRedux.getState().account.airfxFlow.targetSku == constants.panelVersions.iqPanel) {
                            $state.go('wifi.home');
                        }
                        else if ($ngRedux.getState().system.swap.swapType == constants.swapType.mod &&
                            $ngRedux.getState().account.airfxFlow.targetSku == constants.panelVersions.climaxHub) {
                            $state.go('instruction.keypad');
                        }
                        else {
                            $state.go('account.premise-address');
                        }

                    },
                    prev: /*@ngInject*/ ($state) => {
                        $state.go('instruction.place');
                    }
                }
            })
            .state('instruction.keypad', {
                url: '/keypad',
                template: '<aw-instruction-keypad></aw-instruction-keypad>',
                data: {
                    prevState: 'menu.home'
                }
            })
            .state('instruction.keypadsetup', {
                url: '/keypadsetup:deviceName',
                template: '<aw-instruction-keypad></aw-instruction-keypad>'
            })
            .state('instruction.bluetooth', {
                url: '/bluetooth',
                template: '<aw-instruction-bluetooth></aw-instruction-bluetooth>',
                data: {
                    next: /*@ngInject*/ ($state) => {                        
                        $state.go('panel.finalizing');
                    },
                    prev: /*@ngInject*/ ($state) => {
                        $state.go('training.arm-away');
                    }
                }
            });
    })
    .component('awInstructionHome', instructionHomeComponent)
    .component('awInstructionPlace', instructionPlaceComponent)
    .component('awInstructionPlugin', instructionPluginComponent)
    .component('awInstructionPower', instructionPowerComponent)
    .component('awInstructionKeypad', instructionKeypadComponent)
    .component('awInstructionBluetooth', instructionBluetoothComponent)
    .component('awPlaceRequirementsModal', placeRequirementsModalComponent)