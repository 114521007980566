import template from './carbonMonoxideInstallationModal.html';

class CarbonMonoxideInstallationModalController {
    /*@ngInject*/
    constructor () {
    }
}

export default {
template: template,
    bindings: {},
controller: CarbonMonoxideInstallationModalController
};