import template from './sensorNameModal.html';

class SensorNameModalController {
    /*@ngInject*/
    constructor ($state, SharedState, actionContext) {
        this.$state = $state;
        this.SharedState = SharedState;
        this.actionContext = actionContext;
    }

    handleSubmit() {
        this.nameCtrl.submit()
            .then(() => {
                this.SharedState.turnOff('sensorNameModal');
            }).catch((err) => {
                if (err !== 'invalid') {
                    this.$state.go('error');
                }
            });
    }

    handleConnect(ctrl) {
        this.nameCtrl = ctrl;
    }
}

export default {
    template: template,
    bindings: {
        eligibleSensorNames: '<',
        panelType: '<',
        sensor: '<'
    },
    controller: SensorNameModalController
};