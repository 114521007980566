import template from './recessedDoorInstallationModal.html';

class RecessedDoorInstallationModalController {
    /*@ngInject*/
    constructor () {
        
    }
}

export default {
    template: template,
    bindings: { },
    controller: RecessedDoorInstallationModalController
};