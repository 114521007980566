import * as angular from 'angular';
import ngRedux from 'ng-redux';
import uiRouter from 'angular-ui-router';
import uiRouterResolve from 'angular-ui-router-resolve';

import './menu.scss';

import commonModule from '../common';
import loginModule from '../login';

import menuHomeComponent from './home.component';
import menuSavedComponent from './saved.component';
import menuStepListComponent from './stepList.component';
import menuStepComponent from './step.component';

export const menuModule = angular
    .module('fp:activationswizard:menu',
        [
            uiRouter,
            uiRouterResolve,
            ngRedux,
            commonModule.name,
            loginModule.name
        ])
    .config(/*@ngInject*/
        ($stateProvider, constants) => {
            $stateProvider
                .state('menu',
                    {
                        abstract: true,
                        parent: 'secure',
                        url: '/menu',
                        template: '<ui-view></ui-view>',
                        data: {
                            theme: 'app-theme-default-navbar-gray'
                        }
                    })
                .state('menu.home',
                    {
                        url: '',
                        template: '<aw-menu-home></aw-menu-home>',
                        data: {
                            sectionType: constants.sectionTypes.menu,
                            persist: true
                        },
                        resolve: {
                            //Not using the loadData, but injecting it will ensure that the loadData on the shell will resolve
                            //before this is run
                            menu: /*@ngInject*/ ($ngRedux, menuMetadataInitialOrder, menuMetadataInitialOrderIQ4Panel) => {
                                let isIq4Panel = $ngRedux.getState().account.user.isIq4Panel;
                                return isIq4Panel ? menuMetadataInitialOrderIQ4Panel : menuMetadataInitialOrder;
                            }
                        }
                    })
                .state('saved',
                    {
                        url: '/saved',
                        parent: 'root',
                        template: '<aw-menu-saved></aw-menu-saved>',
                        data: {
                            navbar: {
                                bottom: false
                            },
                            persist: false,
                            sectionType: constants.sectionTypes.save
                        }
                    });
        })
    .component('awMenuHome', menuHomeComponent)
    .component('awMenuSaved', menuSavedComponent)
    .component('awMenuStepList', menuStepListComponent)
    .component('awMenuStep', menuStepComponent);

export default menuModule;