import * as _ from 'lodash';

import template from './edit.html';

class EditController {
    /*@ngInject*/
    constructor($ngRedux, $state, $q, SharedState, constants, actionContext, dataContext) {
        // services
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$q = $q;
        this.SharedState = SharedState;
        this.constants = constants;
        this.actionContext = actionContext;
        this.dataContext = dataContext;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    /**
     * Called when the attempting to save the entered address.
     * 
     * @returns {} 
     */
    onSave() {
        this.isUpdating = true;
        this.actionContext.premises.validateAddress(this.premises)
            .then((response) => {
                let currentPremises = this.$ngRedux.getState().account.premises.value;
                if (!this.actionContext.premises.isDifferentAddress(this.premises, currentPremises)) {
                    this.isUpdating = false;
                    this.$state.go('premises.home');
                    return this.$q.reject();
                } else {
                    return response;
                }
            })
            .then((response) => {
                if (!response.IsValid) {
                    this.isUpdating = false;
                    this.validation = response;
                    this.SharedState.turnOn('addressValidationModal');
                    return this.$q.reject();
                } else {
                    return this.actionContext.premises.forceAddress(this.premises);
                }
            })
            .then(() => {
                this.$state.go('premises.home');
            })
            .finally(() => {
                this.isPosting = false;
            });
    }

    /**
     * Called when the customer has accepted the suggested address.
     * 
     * @returns {} 
     */
    onAccept() {
        this.premises = this.validation.Address;
        this.SharedState.turnOff('addressValidationModal');
    }

    /**
     * Called when the customer has declined the suggested address.
     * 
     * @returns {} 
     */
    onDecline() {
        this.validation = null;
        this.SharedState.turnOff('addressValidationModal');
    }

    _mapState(state) {
        let map = {};

        let isUpdating = this.isUpdating || state.account.premises.isUpdating;
        if (!isUpdating) {
            map.premises = state.account.premises.value;
        }

        map.hasError = state.account.premises.hasError;
        map.message = state.account.premises.message;
        map.isUpdating = state.account.premises.isUpdating;
        map.states = state.application.states;

        return _.cloneDeep(map);
    }
}

export default {
template: template,
    bindings: {},
controller: EditController
};