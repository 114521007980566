import template from './home.html';

class HomeController {
    /*@ngInject*/
    constructor($ngRedux, actionContext) {
        this.$ngRedux = $ngRedux;
        this.actionContext = actionContext;
        this.hasError = false;
    }

    $onInit() {
        if (this.actionContext.app.hasInitialLoadError('fetchAddress')) {
            this.actionContext.premises.fetchAddress()
                .then(() => {
                    this.hasError = false;
                    this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
                })
                .catch(() => {
                    this.hasError = true;
                    this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
                });
        }
        else
            this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        
    }

    $onDestroy() {
        this.unsubscribe();
    }

    _mapState(state) {
        return {
            customer: state.account.customer.value,
            premises: state.account.premises.value
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: HomeController
};