import template from './complete.html';

class CompleteController {
    /*@ngInject*/
    constructor (actionContext) {
        this.actionContext = actionContext;
        this.isKeypad = this.actionContext.airfxFlow.isKeypad();
    }
}

export default {
    template: template,
    bindings: {},
    controller: CompleteController
};