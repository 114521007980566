import template from './step.html';

class StepController {
    /*@ngInject*/
    constructor($q, $ngRedux, $state, constants) {
        this.$q = $q;
        this.constants = constants;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.isApp = true;
    }
    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.isApp = this.isInMobileApp;
    }
    _mapState(state) {
        return _.cloneDeep({            
            isInMobileApp: state.account.user.isInMobileApp
        });
    }
}

export default {
    template: template,
    bindings: {
        step: '<',
        status: '<',
        index: '<',
        onClick: '&'
    },
    controller: StepController
};