class AbstractErrorController {
    /*@ngInject*/
    constructor ($log, $ngRedux, actionContext, constants, $state) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.actionContext = actionContext;
        this.constants = constants;
        this.$state = $state;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.actionContext.common.error(this.constants.actionTypes.error, this.constants.sectionTypes.sensor, this.getErrorMessage());
    }

    $onDestroy() {
        this.unsubscribe();
    }

    getErrorMessage() {
        throw 'AbstractErrorController.getErrorMessage() must be overridden.';
    }

    refresh() {
        this.actionContext.device.fetchSensors().then(() => {
            this._redirectBasedOnSensorState();
        });
    }

    _redirectBasedOnSensorState() {
        const deviceActions = this.actionContext.device;
        const productActions = this.actionContext.product;

        if (deviceActions.isSensorTampered(this.sensor)) {
            this.error = true;
            this.$state.go('sensor.item.tamper', { sensorId: this.sensor.SensorID });
            return;
        }

        if (deviceActions.isSensorMalfunctioning(this.sensor)) {
            this.error = true;
            this.$state.go('sensor.item.malfunction', { sensorId: this.sensor.SensorID });
            return;
        }

        if (!deviceActions.isSensorConnected(this.sensor)) {
            this.$state.go('sensor.item.not-connected', { sensorId: this.sensor.SensorID });
            return;
        }

        if (!_.isNil(productActions.getBoxText(this.sensor))) {
            this.$state.go('sensor.item.box', { sensorId: this.sensor.SensorID });
            return;
        }

        this.$state.go('sensor.item.overview', { sensorId: this.sensor.SensorID });
    }

    _mapState(state) {
        return {
            sensor: state.system.devices.sensorsByID[state.system.devices.selectedSensorID]
        };
    }
}

export default AbstractErrorController;