import template from './motionInstallationModal.html';

class MotionInstallationModalController {
    /*@ngInject*/
    constructor() {
        
    }
}

export default {
    template: template,
    bindings: {},
    controller: MotionInstallationModalController
};