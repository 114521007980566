import * as _ from 'lodash';
import ensure from '../util/ensure';

export const PASSWORD_UPDATE_REQUEST = 'PASSWORD_UPDATE_REQUEST';
export const PASSWORD_UPDATE_FAILURE = 'PASSWORD_UPDATE_FAILURE';
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';

class PasswordActions {
    /*@ngInject*/
    constructor ($log, $ngRedux, $state, $q, constants, dataContext) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$q = $q;
        this.constants = constants;
        this.dataContext = dataContext;
    }

    /**
     * Update the current user's password.
     * 
     * @param {string} password A string the represents the new password.
     * @returns {promise} A promise that returns undefined if resolved and an error if rejected.
     */
    updatePassword(password) {
        ensure.isString('password', password);
        this._onUpdatePassword();

        return this.dataContext.user.setPassword(password)
            .then(() => {
                let maskedPassword = this._maskPassword(password);
                this._onUpdatePasswordSuccess(maskedPassword);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error updating the customer\'s password.', error);
                this._onUpdatePasswordFailure(error);
                return this.$q.reject(error);
            });
    }

    _dispatch(obj) {
        this.$ngRedux.dispatch(obj);
    }

    _onUpdatePassword() {
        this._dispatch({
            type: PASSWORD_UPDATE_REQUEST
        });
    }

    _onUpdatePasswordSuccess(data) {
        this._dispatch({
            type: PASSWORD_UPDATE_SUCCESS,
            payload: {
                data: data
            },
            metadata: {
                sectionType: this.constants.sectionTypes.password,
                description: 'The password was set',
                persist: true
            }
        });
    }

    _onUpdatePasswordFailure(error) {
        this._dispatch({
            type: PASSWORD_UPDATE_FAILURE,
            payload: {
                error
            },
            metadata: {
                sectionType: this.constants.sectionTypes.password,
                description: `Error updating the customer\'s password.`,
                persist: true,
                isHydratable: false
            }
        });
    }

    _maskPassword(password) {
        let unmaskedChars = this.constants.password.numberOfUnmaskedCharacters;
        let maskedChars = this.constants.password.numberOfMaskedCharacters;
        return password.substring(0, unmaskedChars) + Array(maskedChars + 1).join('*');
    }
}

export default PasswordActions;