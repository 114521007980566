import template from './tamper.html';

import AbstractErrorController from './AbstractErrorController';

class SensorTamperController extends AbstractErrorController {
    /*@ngInject*/
    constructor ($log, $ngRedux, actionContext, constants, $state) {
        super($log, $ngRedux, actionContext, constants, $state);
    }

    $onInit() {
        super.$onInit();
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
    }

    getErrorMessage() {
        return `Sensor is reporting a tamper status. [SensorID: ${this.sensor.SensorID}]`;
    }
}

export default {
    template: template,
    bindings: {},
    controller: SensorTamperController
};