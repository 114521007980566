import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';
import 'ui-router.grant';

import './orderselector.scss';

import commonModule from '../common';
import menuModule from '../menu';

import orderselectorFinalizeComponent from './finalize.component';
import orderselectorHomeComponent from './home.component';

export default angular
    .module('fp:activationswizard:orderselector', [
        'mobile-angular-ui',
        'ui.router',
        'ui.router.grant',
        ngRedux,
        commonModule.name,
        menuModule.name
    ])
    .config(/*@ngInject*/ ($stateProvider) => {
        $stateProvider
            .state('orderselector', {
                abstract: true,
                parent: 'root',
                url: '/orderselector',
                template: '<ui-view></ui-view>',
                data: {
                    persist: false,
                    theme: 'app-theme-default-navbar-gray'
                },
                resolve: {
                    startLoad: /*@ngInject*/ (actionContext) => {
                        actionContext.app.tryStartLoad();
                    },
                    isAuthorized: /*@ngInject*/ (grant) => {
                        return grant.only({ test: 'user', state: 'login' });
                    },
                    loadData: /*@ngInject*/ ($q, actionContext, /*resolve after*/ isAuthorized) => {
                        return actionContext.app.fetchConfiguration();
                    }
                }
            })
            .state('orderselector.home', {
                url: '',
                template: '<aw-order-selector-home></aw-order-selector-home>',
                resolve: {
                    loadOrders: /*@ngInject*/ (actionContext, /*resolve after*/ loadData) => {
                        return actionContext.order.fetchDeliveredAndUnactivatedOrders();
                    },
                    stopLoad: /*@ngInject*/ (actionContext, /*resolve after*/ loadOrders) => {
                        actionContext.app.tryStopLoad();
                    }
                },
                data: {
                    navbar: {
                        bottom: false
                    }
                }
            })
            .state('orderselector.activate', {
                url: '/activate:category',
                template: '<aw-order-selector-finalize></aw-order-selector-finalize>'
            });
    })
    .component('awOrderSelectorFinalize', orderselectorFinalizeComponent)
    .component('awOrderSelectorHome', orderselectorHomeComponent);
