import './login.scss';
import template from './login.html';
import * as _ from 'lodash';
import * as angular from 'angular';

class LoginComponent {
    /*@ngInject*/
    constructor($ngRedux, $log, actionContext, constants, $q, $state, $scope, $interval, SharedState) {
        this.$log = $log;
        this.constants = constants;
        this.actionContext = actionContext;
        this.$state = $state;
        this.$q = $q;
        this.$scope = $scope;
        this.$interval = $interval;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis)(this);
        this.attempts = 0;
        this.refreshCaptchaInterval = null;
        this.SharedState = SharedState;

        /*if we dont initalize here we cannot call the modal
        when the page launches. placing it in constructor 
        allows us to get around screen "flashing" that would
        occur if calling turnOn in init */
        this.SharedState.initialize(this.$scope, 'getStartedModal');
        this.SharedState.turnOn('getStartedModal');

    }

    $onInit() {
        this.initializeCaptcha();
        this.refreshCaptchaToken();
        if (!_.isEmpty(this.activationCode)) {
            this.actionContext.login.postActivationCode(this.activationCode);
        }
    }

    initializeCaptcha() {
        var executeRecaptchaScript = document.createElement("script");
        executeRecaptchaScript.type = "text/javascript";
        executeRecaptchaScript.textContent =
            'var onloadCallback = function() {grecaptcha.ready(function () {grecaptcha.execute("6LdJ2vUcAAAAALfvFO-h0qTNyP_BhtMtDziJEEeM", { action: "setupWizardLogin" }).then(function (token) {var recaptchaResponse = document.getElementById("recaptchaResponse"); angular.element(recaptchaResponse).controller("ngModel").$setViewValue(token); recaptchaResponse.value = token; angular.element(recaptchaResponse).controller("ngModel").$render();});});};';
        angular.element(document.querySelector('head')).append(executeRecaptchaScript);

        var recaptchaApiScript = document.createElement("script");
        recaptchaApiScript.type = "text/javascript";
        recaptchaApiScript.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=6LdJ2vUcAAAAALfvFO-h0qTNyP_BhtMtDziJEEeM";
        angular.element(document.querySelector('head')).append(recaptchaApiScript);
        _.defer(() => this.$scope.$apply());
    }

    refreshCaptchaToken() {
        this.refreshCaptchaInterval = this.$interval(() => {
            this.initializeCaptcha();
        }, 90 * 1000);
    }

    submit(activationCode, form) {
        if (form.$dirty) {
            if (!_.isNil(form) && form.$valid && !_.isNil(this.captchaToken)) {
                return this.actionContext.login.postActivationCode(activationCode, this.captchaToken).then(() => {
                    return this.$q.resolve();
                }).catch(error => {
                    this.initializeCaptcha();
                    this.invalidCaptcha = false;
                    return this.$q.reject(error);
                });
            } else if (_.isNil(this.captchaToken)) {
                this.invalidCaptcha = true;
            } else {
                this.invalidCaptcha = false;
            }
        }
        return this.$q.resolve();
    }

    $onDestroy() {
        this.unsubscribe();
        if (this.refreshCaptchaInterval) {
            this.$interval.cancel(this.refreshCaptchaInterval);
        }

    }

    mapStateToThis(state) {
        return {
            activationCode: state.account.user.activationCode,
            hasError: state.account.user.hasError,
            message: state.account.user.message,
            isBusy: state.account.user.isBusy
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: LoginComponent
};