import './fullScreenModal.scss'
import template from './fullScreenModal.html';

import * as _ from 'lodash';

class FullScreenModal {
    /*@ngInject*/
    constructor() {

    }
}

export default {
    template: template,
    bindings: {
        uiState: '@'
    },
    transclude: true,
    controller: FullScreenModal
};