import * as _ from 'lodash';
import template from './review.html';

class SensorReviewController {
    /*@ngInject*/
    constructor ($ngRedux, SharedState, actionContext, navigation, constants) {
        this.$ngRedux = $ngRedux;
        this.SharedState = SharedState;
        this.actionContext = actionContext;
        this.navigation = navigation;
        this.constants = constants;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this._checkSensors();
    }

    _checkSensors() {
        let hasSensor = false;

        if (!this.isInitialOrder) {
            this.sensors = _.filter(this.sensors, (sensor) => { return sensor.ProductSku === this.sku; });
        }

        _.each(this.sensors, (sensor) => {
            if (this.actionContext.product.isSensor(sensor.ProductSku))
                hasSensor = true;
        });

        if (!hasSensor)
            this.navigation.goNext();

    }

    $doCheck() {
        // get all key fobs and panic pendants if present in the order
        let keyFobOrPanicPendantDevice = _.filter(this.sensors, (sensor) => {
            return _.includes(this.constants.sensorListExceptionSkus, sensor.ProductSku);
        });

        if (!_.isEmpty(keyFobOrPanicPendantDevice))
            this.hasExceptionSkus = true;

        // filter out all devices that are not sensors
        this.sensors = _.filter(this.sensors, (sensor) => { return sensor.IsSensor });

        // only need to show one panic pendant/ key fob in the list
        if (this.hasExceptionSkus) {
            _.forEach(keyFobOrPanicPendantDevice,
                (item) => {
                    // check if panic pendant or key fob exist, if not, add to the list
                    let exists = _.some(this.sensors, s => { return s.ProductSku === item.ProductSku; });
                    if (!exists) {
                        this.sensors.push(item);
                    };
                });
        }
    }

    onSensorSelected(sensor) {
        if (this.actionContext.device.isSensorSkipped(sensor)) {
            return;
        }

        this.sensor = _.cloneDeep(sensor);
        this.SharedState.turnOn('sensorNameModal');
    }

    /**
     * Returns true if a given sensor is in review.
     * 
     * @param {Object} sensor A sensor object.
     * @returns {boolean} True if the given sensor is in review; otherwise, false. 
     */
    isSensorReview(sensor) {
        return !this.actionContext.device.isSensorSkipped(sensor);
    }

    _mapState(state) {
        return {
            eligibleSensorNames: state.application.eligibleSensorNames,
            isUpdating: state.system.devices.isUpdating,
            panelType: state.system.panel.type,
            sensors: _.sortBy(_.map(state.system.devices.sensorsByID), ['SetupOrder']),
            isInitialOrder: state.account.user.isInitialOrder,
            sku: state.account.order.currentSku
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: SensorReviewController
};