export const KIT_CUSTOMER_CONTACT_SUBMISSION_BUSY = 'KIT_CUSTOMER_CONTACT_SUBMISSION_BUSY ';
export const KIT_CUSTOMER_CONTACT_SUBMISSION_SUCCESSFUL = 'KIT_CUSTOMER_CONTACT_SUBMISSION_SUCCESSFUL';
export const KIT_CUSTOMER_CONTACT_SUBMISSION_FAILED = 'KIT_CUSTOMER_CONTACT_SUBMISSION_FAILED';
export const KIT_CUSTOMER_ADDRESS_SUBMISSION_BUSY = 'KIT_CUSTOMER_ADDRESS_SUBMISSION_BUSY';
export const KIT_CUSTOMER_ADDRESS_SUBMISSION_SUCCESSFUL = 'KIT_CUSTOMER_ADDRESS_SUBMISSION_SUCCESSFUL';
export const KIT_CUSTOMER_ADDRESS_SUBMISSION_FAILED = 'KIT_CUSTOMER_ADDRESS_SUBMISSION_FAILED';
export const KIT_CUSTOMER_FETCH_BUSY = 'KIT_CUSTOMER_FETCH_BUSY';
export const KIT_CUSTOMER_FETCH_SUCCESSFUL = 'KIT_CUSTOMER_FETCH_SUCCESSFUL';
export const KIT_CUSTOMER_FETCH_FAILED = 'KIT_CUSTOMER_FETCH_FAILED';
export const KIT_CUSTOMER_ORDER_CONFIGURATION_BUSY = "KIT_CUSTOMER_ORDER_CONFIGURATION_BUSY";
export const KIT_CUSTOMER_ORDER_CONFIGURATION_SUCCESSFUL = "KIT_CUSTOMER_ORDER_CONFIGURATION_SUCCESSFUL";
export const KIT_CUSTOMER_ORDER_CONFIGURATION_FAILED = "KIT_CUSTOMER_ORDER_CONFIGURATION_FAILED";

class KitCustomerActions {
    /*@ngInject*/
    constructor($log, $ngRedux, $q, $state, dataContext, loginActions) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.$state = $state;
        this.dataContext = dataContext;
        this.loginActions = loginActions;
    }

    createLeadAndQuote(leadContact) {
        this.$ngRedux.dispatch({
            type: KIT_CUSTOMER_CONTACT_SUBMISSION_BUSY
        });

        return this.dataContext.kitCustomer.createLeadAndQuote(leadContact).then((result) => {
                this.$ngRedux.dispatch({
                    type: KIT_CUSTOMER_CONTACT_SUBMISSION_SUCCESSFUL,
                    payload: {
                        FirstName: leadContact.FirstName,
                        LastName: leadContact.LastName,
                        Email: leadContact.Email,
                        Phone: leadContact.Phone,
                        LeadID: result.LeadID,
                        QuoteID: result.QuoteID,
                        KitProductSerialNumber: result.kitProduct && result.kitProduct.SerialNumber
                    }
                });
            })
            .catch((error) => {
                this.$ngRedux.dispatch({
                    type: KIT_CUSTOMER_CONTACT_SUBMISSION_FAILED
                });

                this.$log.error('Error creating the lead', error);
                return this.$q.reject();
            });
    }

    setAddressAndCreateAccount(accountRequest) {
        this.$ngRedux.dispatch({
            type: KIT_CUSTOMER_ADDRESS_SUBMISSION_BUSY
        });

        return this.dataContext.kitCustomer.setAddressAndCreateAccount(accountRequest).then((result) => {
                this.$ngRedux.dispatch({
                    type: KIT_CUSTOMER_ADDRESS_SUBMISSION_SUCCESSFUL,
                    payload: {
                        address: {
                            AddressLine1: accountRequest.AddressLine1,
                            AddressLine2: accountRequest.AddressLine2,
                            City: accountRequest.City,
                            State: accountRequest.State,
                            PostalCode: accountRequest.PostalCode
                        },
                        accountId: result
                    }
                });
            return result;
            }).catch((error) => {
                this.$ngRedux.dispatch({
                    type: KIT_CUSTOMER_ADDRESS_SUBMISSION_FAILED
                });

                this.$log.error('Error updating the lead address', error);
                return this.$q.reject();
            });
    }

    fetchKitCustomer() {
        this.$ngRedux.dispatch({
            type: KIT_CUSTOMER_FETCH_BUSY
        });

        return this.dataContext.kitCustomer.fetchKitCustomer().then((result) => {

            // update kit customer state
            this.$ngRedux.dispatch({
                type: KIT_CUSTOMER_FETCH_SUCCESSFUL,
                payload: {
                    address: {
                        AddressLine1: result.Address.AddressLine1,
                        AddressLine2: result.Address.AddressLine2,
                        City: result.Address.City,
                        State: result.Address.State,
                        PostalCode: result.Address.PostalCode
                    },
                    contact: {
                        FirstName: result.Contact.FirstName,
                        LastName: result.Contact.LastName,
                        Email: result.Contact.Email,
                        Phone: result.Contact.Phone,
                        IsResidential: result.Contact.IsResidential,
                        CompanyName: result.Contact.CompanyName
                    },
                    accountId: result.AccountID,
                    quoteId: result.QuoteID,
                    leadId: result.LeadID,
                    contractId: result.ContractID,
                    orderId: result.OrderID,
                    kitProductSerialNumber: result.KitProduct && result.KitProduct.SerialNumber,
                    products: result.Products && result.Products.map(({ Product, ...rest }) => ({ ...rest, ...Product}))
                }
            });

            // hydrate amazon user state
            this.loginActions.hydrate();

        }).catch((error) => {
            this.$ngRedux.dispatch({
                type: KIT_CUSTOMER_FETCH_FAILED
            });

            this.$log.error('Error fetching kit customer data', error);
            return this.$q.reject();
        });
    }

    beginOrderConfiguration() {
        this.$ngRedux.dispatch({
            type: KIT_CUSTOMER_ORDER_CONFIGURATION_BUSY
        });

        return this.dataContext.kitCustomer.beginOrderConfiguration().then(() => {
            this.$ngRedux.dispatch({
                type: KIT_CUSTOMER_ORDER_CONFIGURATION_SUCCESSFUL
            });
        }).catch((error) => {
            this.$ngRedux.dispatch({
                type: KIT_CUSTOMER_ORDER_CONFIGURATION_FAILED
            });

            this.$log.error('Error starting configuration of order', error);
            return this.$q.reject();
        });
    }
}

export default KitCustomerActions;