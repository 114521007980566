import { stateGo } from 'redux-ui-router';
import template from './home.html';
import moment from 'moment';

class OverviewController {
    /*@ngInject*/
    constructor($log, $ngRedux, $state, $resolves, actionContext, $injector, segment, constants) {
        // services
        this.$log = $log;
        this.$state = $state;

        this.actionContext = actionContext;

        // data
        this.currentStepIdx = 0;
        this.menuKey = null;
        this.menuMetadata = $resolves.menu;
        this.order = null;

        // setup
        this.unsubscribe = $ngRedux.connect(this._mapState)(this);

        this._setIndexFromLastCompletedStep();

        //edge case for initial with lock with additional then goes back to menu and refreshed page
        if (this.menuKey === 'automation' && this.currentStepIdx === 0) {
            this.menuKey = 'signage';
            this._setIndexFromLastCompletedStep();
        }
        this.$injector = $injector;
        this.segment = segment;

        this.constants = constants;

        this.numSensors = this.products ? this.products.filter(product => product.ProductGroupID === 1 || product.ProductGroupID === 3).length : 0;
        this.numControllers = this.products ? this.products.filter(product => product.ProductGroupID === 2).length : 0;

        this.steps = [
            { stepIdx: 0 },
            { stepIdx: 1 },
            { stepIdx: 2 },
            { stepIdx: 3 }
        ];

        this.buttonText = this.currentStepIdx === 0 ? 'Start Now' : 'Continue';
    }

    _setIndexFromLastCompletedStep() {
        this.currentStepIdx = this.menuMetadata.reduce((acc, step, idx) => step.key === this.menuKey ? idx + 1 : acc, 0);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    onContinue() {
        // Step InProgress
        const locals = {
            context: this,
            step: this.menuMetadata[this.currentStepIdx]
        };

        this.segment.registerSegmentStepClickEvent(locals.step.title);
        return this.$injector.invoke(locals.step.action, null, locals);
    }

    _mapState({
        account: {
            user: {
                isAmazonUser
            },
            order,
            kitCustomer: {
                products
            }
        },
        application: {
            lastCompletedStep
        }
    }) {
        return {
            isAmazonUser,
            menuKey: lastCompletedStep,
            order,
            products: isAmazonUser ? products : Object.keys(order.itemsByID).map(key => order.itemsByID[key])
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: OverviewController
};