import './scanner.scss';
import * as _ from 'lodash';
import template from './scanner.html';
import 'webrtc-adapter';

class ScannerController {
    /*@ngInject*/
    constructor(constants, barcodeService, $rootScope, $state, $q, scannerService, $scope, $ngRedux) {
        this.constants = constants;
        this.barcodeService = barcodeService;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$q = $q;
        this.scannerService = scannerService;
        this.$scope = $scope;
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.scannerContainer = document.getElementById('scanner');

        this.scannerService.initialize(this.scannerContainer)
            .then(() => {
                this.scannerService.handleScan(this.handleScan.bind(this));
            });
        this.subscribeToAccessCameraEvent();
        this.subscribeToPauseScanner();
    }

    $onDestroy() {
        this.unsubscribe();
    }

    handleScan(scanResult) {
        this.onDetectBarcode(scanResult.barcodes[0].data);
    }

    handleReady() {
        this.onLoadingChange({ isLoading: false });
        this.scannerService.getPicker().setGuiStyle('laser');
        this.$scope.$apply();
    }

    subscribeToAccessCameraEvent() {
        this.$rootScope.$on('accessScanner', (event, args) => {
            return this.scannerService.accessCamera().then(() => {
                return this.scannerService.startScanning();
            }).then(() => {
                this.scannerService.handleReady(this.handleReady.bind(this));
            });
        });
    }

    subscribeToPauseScanner() {
        this.$rootScope.$on(this.constants.events.stateChangeSuccess, (event, args) => {
            const isScannableState = _.get(this, '$state.$current.data.showBarcodeScanner', false);
            if (!isScannableState
                && !_.isNil(this.scannerService.getPicker())
                && !this.scannerService.getPicker().scanningPaused) {
                this.scannerService.stopScanning();
            }
        });
    }

    onDetectBarcode(dlCode) {
        if (this.prevState === 'airfx.barcodeRescan') {
            this.barcodeService.activateSensorWithDlCode(dlCode, 2);
        } else {
            this.barcodeService.activateSensorWithDlCode(dlCode, 1);
        }
    }

    _mapState(state) {
        return {
            prevState: state.router.prevState.name
        };
    }
}

export default {
    template: template,
    bindings: {
        onLoadingChange: '&'
    },
    controller: ScannerController
}