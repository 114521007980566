import * as _ from 'lodash';
import moment from 'moment';
import ensure from '../util/ensure';

export const STATES_FETCH_REQUEST = 'STATES_FETCH_REQUEST';
export const STATES_FETCH_FAILURE = 'STATES_FETCH_FAILURE';
export const STATES_FETCH_SUCCESS = 'STATES_FETCH_SUCCESS';

export const CONFIGURATION_FETCH_REQUEST = 'CONFIGURATION_FETCH_REQUEST';
export const CONFIGURATION_FETCH_FAILURE = 'CONFIGURATION_FETCH_FAILURE';
export const CONFIGURATION_FETCH_SUCCESS = 'CONFIGURATION_FETCH_SUCCESS';

export const FETCH_ELIGIBLE_SENSOR_NAMES_REQUEST = 'FETCH_ELIGIBLE_SENSOR_NAMES_REQUEST';
export const FETCH_ELIGIBLE_SENSOR_NAMES_FAILURE = 'FETCH_ELIGIBLE_SENSOR_NAMES_FAILURE';
export const FETCH_ELIGIBLE_SENSOR_NAMES_SUCCESS = 'FETCH_ELIGIBLE_SENSOR_NAMES_SUCCESS';

export const COMPLETE_ACTIVATION_WIZARD = 'COMPLETE_ACTIVATION_WIZARD';

export const STEP_COMPLETE = 'STEP_COMPLETE';

export const START_LOAD = 'START_LOAD';
export const STOP_LOAD = 'STOP_LOAD';

const MENU_STATE = 'menu.home';

class ApplicationActions {
    /*@ngInject*/
    constructor ($log, $ngRedux, $state, $q, constants, commonActions, dataContext, menuMetadataInitialOrder, fpStore, accountActions, testmodeActions) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.$state = $state;
        this.constants = constants;
        this.commonActions = commonActions;
        this.accountActions = accountActions;
        this.testmodeActions = testmodeActions;
        this.dataContext = dataContext;
        this.menuMetadataInitialOrder = menuMetadataInitialOrder;
        this.fpStore = fpStore;

        this.loadRequestCount = 0;
    }

    /**
     * Fetches a list of valid states and adds them to the application state.
     * 
     * @returns {promise} A promise that returns undefined if resolved and
     * an error if rejected. 
     */
    fetchStates() {
        this._onFetchStates();

        return this.dataContext.state.getStates()
            .then((data) => {
                this._onFetchStatesSuccess(data);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error fetching state lookup information.', error);
                this._onFetchStatesFailure(error);
                return this.$q.reject('fetchStates');
            });
    }

    /**
     * Fetches application configuration.
     * 
     * @returns {promise} A promise that returns undefined if resolved and
     * an error if rejected. 
     */
    fetchConfiguration() {
        this._onFetchConfiguration();

        return this.dataContext.configurations.getAll()
            .then((data) => {
                this._onFetchConfigurationSuccess(data);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error fetching app configuration.', error);
                this._onFetchConfigurationFailure(error);
                return this.$q.reject('fetchConfiguration');
            });
    }


    /**
     * Fetches a list of all eligible sensor names and adds them to the 
     * application state.
     * 
     * @returns {promise} A promise that returns a undefined if resolved 
     * and an error if rejected.  
     */
    fetchEligibleSensorNames() {
        this._onFetchEligibleSensorNames();

        return this.dataContext.system.sensor.getEligibleNames()
            .then((result) => {
                this._onFetchEligibleSensorNamesSuccess(result);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error fetching eligiable sensor names.', error);
                this._onFetchEligibleSensorNamesFailure(error);
                return this.$q.reject('fetchEligibleSensorNames');
            });
    }

    /**
     * Mark the given step as completed in the application state.
     * 
     * @param {string} step The step to mark as complete. 
     */
    completeStep(step) {
        ensure.isNotNullOrUndefined('step', step);
        ensure.isString('step', step);

        this._onStepComplete(step);
    }

    completeModule(module) {
        this.commonActions.end(this.constants.actionTypes.end, module);
    }

    startModule(module) {
        this.commonActions.start(this.constants.actionTypes.start, module);
    }

    /**
     * Checks if initial error is in list
     * 
     */
    hasInitialLoadError(loadError) {
        if (_.isNil(this.intialLoadErrors))
            return false;

        return _.some(this.intialLoadErrors, (result) => result === loadError);
    }

    /**
     * Returns true if the given step has been completed, otherwise false.
     * 
     * @param {string} step The step to check if completed.
     * @returns {boolean} True if the given step has been completed, otherwise false.
     */
    isStepCompleted(step) {
        ensure.isNotNullOrUndefined('step', step);
        ensure.isString('step', step);

        let state = this.$ngRedux.getState();

        // No steps have been completed.
        if (state.application.lastCompletedStep === '') {
            return false;
        }

        for (let i = 0; i < this.menuMetadataInitialOrder.length; i++) {
            let s = this.menuMetadataInitialOrder[i];
            // We found the desired step before reaching the
            // current step. It is considered complete.
            if (s.key === step) {
                return true;
            }

            // We reached the current step without finding
            // the desired step. It is considered incomplete.
            if (s.key === state.application.lastCompletedStep) {
                return false;
            }
        }

        return false;
    }

    /**
     * Attempts to put the application in a loading state and returns true if the operation was successful.
     * 
     * @returns {boolean} True if the application was placed in a loading state.
     */
    tryStartLoad() {
        this.loadRequestCount++;

        if (this.$ngRedux.getState().application.isLoading) {
            return false;
        }

        this._dispatch({
            type: START_LOAD
        });

        return true;
    }

    /**
     * Attempts to remove the application from a loading state and returns true if the operation was successful.
     * 
     * @returns {boolean} True if the application was removed from a loading state.
     */
    tryStopLoad() {
        if (this.loadRequestCount > 0) {
            this.loadRequestCount--;
        }

        if (!this.$ngRedux.getState().application.isLoading) {
            return false;
        }

        this._dispatch({
            type: STOP_LOAD
        });

        return true;
    }

    goToMenu() {
        this.$state.go(MENU_STATE);
    }

    goToStepFromSensorComplete() {
        if (this.$ngRedux.getState().account.user.isInitialOrder) {
            this.$state.go('menu.home');
        }
        else if (!this.dataContext.user.isInMobileApp() && !this.isInitialWithAdditional()) {
            this.$state.go('sensor.activation');
        }
        else {
            this.$state.go(this.constants.routerStates.orderSelectorActivate, { category: 'sensors' });
        }
    }
    
    completeActivationWizard() {
        this._dispatch({
            type: COMPLETE_ACTIVATION_WIZARD
        });
    }

   /**
   * Attempts to see if the workflow is an initial with additional orders
   * 
   */
    isInitialWithAdditional() {
        let initialOrderAdditionalWorkflow = this.fpStore.get(this.constants.storage.initialOrderAdditionalWorkflow, 'session');
        if (_.isNil(initialOrderAdditionalWorkflow) || !_.isBoolean(initialOrderAdditionalWorkflow)) {
            return false;
        }
        return initialOrderAdditionalWorkflow;
    }

    isAdditional() {
        return this.dataContext.user.isInMobileApp() || this.isInitialWithAdditional();
    }

    _dispatch(obj) {
        this.$ngRedux.dispatch(obj);
    }

    _onFetchStates() {
        this._dispatch({
            type: STATES_FETCH_REQUEST
        });
    }

    _onFetchStatesFailure(error) {
        this._dispatch({
            type: STATES_FETCH_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onFetchStatesSuccess(data) {
        this._dispatch({
            type: STATES_FETCH_SUCCESS,
            payload: {
                data: data
            }
        });
    }

    _onFetchConfiguration() {
        this._dispatch({
            type: CONFIGURATION_FETCH_REQUEST
        });
    }

    _onFetchConfigurationFailure(error) {
        this._dispatch({
            type: CONFIGURATION_FETCH_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onFetchConfigurationSuccess(data) {
        this._dispatch({
            type: CONFIGURATION_FETCH_SUCCESS,
            payload: {
                data: data
            }
        });
    }

    _onFetchEligibleSensorNames() {
        this._dispatch({
            type: FETCH_ELIGIBLE_SENSOR_NAMES_REQUEST
        });
    }

    _onFetchEligibleSensorNamesFailure(error) {
        this._dispatch({
            type: FETCH_ELIGIBLE_SENSOR_NAMES_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onFetchEligibleSensorNamesSuccess(data) {
        this._dispatch({
            type: FETCH_ELIGIBLE_SENSOR_NAMES_SUCCESS,
            payload: {
                data: data
            }
        });
    }

    _onStepComplete(data) {
        this._dispatch({
            type: STEP_COMPLETE,
            payload: {
                data: data
            },
            metadata: {
                persist: false,
                description: 'step completed: ' + data,
                actionType: 'Step'
            }
        });
    }

    _activateAccount() {
        this.commonActions.start(this.constants.actionTypes.start, this.constants.sectionTypes.completed);
        return this.accountActions.activateAccount().catch((err) => {
            this.commonActions.error(this.constants.actionTypes.error, this.constants.sectionTypes.completed, err);
            this.$state.go('error');
            return this.$q.reject(err);
        });
    }
}

export default ApplicationActions;