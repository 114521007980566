import * as angular from 'angular';

import activationCodeFormatterDirective from './activationCodeFormatter.directive';
import allowTouchmoveDefaultDirective from './allowTouchmoveDefault.directive';
import anchorDisabledDirective from './anchorDisabled.directive';
import focusIfDirective from './focusIf.directive';
import modelClearOnDestroyDirective from './modelClearOnDestroy.directive';
import navNextDirective from './navNext.directive';
import navPrevDirective from './navPrev.directive';
import scriptDirective from './script.directive';
import scrollToIfDirective from './scrollToIf.directive';
import stopClickDirective from './stopClick.directive';
import textMustMatchDirective from './textMustMatch.directive';
import ignoreDashesDirective from './ignoreDashes.directive';
import maxLimitNumberDirective from './maxLimitNumber.directive';
import iframeCapturePostMessageDataDirective from './iframeCapturePostMessageData.directive';
import onLoadIframeDirective from './onLoadIframe.directive';
import phoneDirective from './phone.directive';

export default angular.module('fp:customerportal:common:directive', [
    'mobile-angular-ui'
])
    .directive('awActivationCodeFormatter', activationCodeFormatterDirective)
    .directive('awAllowTouchmoveDefault', allowTouchmoveDefaultDirective)
    .directive('awAnchorDisabled', anchorDisabledDirective)
    .directive('awNavNext', navNextDirective)
    .directive('awNavPrev', navPrevDirective)
    .directive('awFocusIf', focusIfDirective)
    .directive('awModelClearOnDestroy', modelClearOnDestroyDirective)
    .directive('script', scriptDirective)
    .directive('awScrollToIf', scrollToIfDirective)
    .directive('awStopClick', stopClickDirective)
    .directive('awTextMustMatch', textMustMatchDirective)
    .directive('awIgnoreDashes', ignoreDashesDirective)
    .directive('awMaxLimitNumber', maxLimitNumberDirective)
    .directive('awIframeCapturePostMessageData', iframeCapturePostMessageDataDirective)
    .directive('awOnLoadIframe', onLoadIframeDirective)
    .directive('awPhone', phoneDirective);