import * as _ from 'lodash';
import moment from 'moment';

import AbstractTestController from './AbstractTestController';

import template from './walkthrough.html';

class WalkthroughTestController extends AbstractTestController {
    /*@ngInject*/
    constructor ($document, $log, $ngRedux, $state, $timeout, actionContext, constants) {
        super($document, $log, $ngRedux, $state, constants);
        this.$timeout = $timeout;
        this.constants = constants;
        this.actionContext = actionContext;

        // look for sensor status updates up to 1 hour in the past
        this.startDate = moment.utc().add(-1, 'h');
    }

    $onInit() {
        super.$onInit();
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
    }

    /**
     * Complete the provided step.
     * 
     * @param {string} step A string that represents the step to complete.
     */
    completeStep(step) {
        this.stopTest();

        switch (step) {
        case 'step-poll':
            this.startStep('step-1');
            break;
        case 'step-openClosed':
        case 'step-1':
            this.startStep('step-2');
            break;
        case 'step-2':
            this.startStep('step-3');
            break;
        case 'step-3':
            this.startStep('step-4');
            break;
        case 'step-4':
            this.$state.go('sensor.item.complete');
            break;
        case 'step-tamper':
        case 'step-timeout':
        case 'step-unknown':
        case 'step-failure':
            //activate instantaneous mode again if retrying
            this.actionContext.panel.enableInteractiveMode()
                .then(() => {
                    this.startStep('step-poll');
                });
        break;
        }
    }

    /**
     * Start the provided step.
     * 
     * @param {string} step A string that represents the step to start.
     */
    startStep(step) {
        this.stopTest();
        let unknownCheck = 0;
        switch (step) {
        case 'step-poll':
            this.step = 'step-poll';
            this._doPoll('', /* attempts */ 4).then(() => {
                this.startStep('step-1');
            }).catch((err) => {
                unknownCheck++;
                if (err === 'timeout' && unknownCheck >= 2) {
                    this.startStep('step-timeout');
                    return;
                }
                this.startStep('step-unknown');
            });
            break;
        case 'step-1':
            this.step = 'step-1';
            if (this.isTampered(this.sensor) || this.isMalfunctioning(this.sensor)) {
                this.startStep('step-tamper');
            }else if (this.isOpenClosed(this.sensor)) {
                this.startStep('step-openClosed');
            } 
            else if (this.isUnknown(this.sensor)) {
                this.startStep('step-unknown');
            } else if (this.wasUnknown) {
                this.wasUnknown = false;
                this.actionContext.panel.enableInteractiveMode();
            }
            break;
        case 'step-2':
            this.step = 'step-2';
            this.firstStatus = this.sensor.StatusType;
            this._doPoll(this.getInverseStatus(this.sensor)).then((res) => {
                this.startStep('step-3');
            }).catch((err) => {
                if (err === 'timeout') {
                    this.startStep('step-timeout');
                }
            });
            break;
        case 'step-3':
            this.step = 'step-3';
            this._doPoll(this.getInverseStatus(this.sensor)).then((res) => {
                this.startStep('step-4');
            }).catch((err) => {
                if (err === 'timeout') {
                    this.startStep('step-timeout');
                }
            });
            break;
        case 'step-4':
            this.step = 'step-4';
            this._doSleep(/* timeout */ 5000).then(() => {
                this.$state.go('sensor.item.complete');
            });
            break;
        case 'step-tamper':
            this.step = 'step-tamper';
            break;
        case 'step-timeout':
            this.step = 'step-timeout';
            break;
        case 'step-unknown':
            this.step = 'step-unknown';
            this.wasUnknown = true;
            break;
        case 'step-failure':
            this.step = 'step-failure';
            break;
        case 'step-openClosed':
            this.initial = true;
            this.step = 'step-openClosed';
        }
    }

    /**
     * Returns a string that represents the inverse of the provided sensor's status. Statuses that do not have an
     * inverse status are returned as is. Refer to the table below for the inverse of each status. 
     * 
     * 'Opened' -> 'Closed'
     * 'Closed' -> 'Opened'
     * 'Tampered' -> 'Tampered'
     * 'Unknown' -> 'Unknown'
     * 
     * @param {Object} sensor A sensor object. 
     * @returns {string} A string that represents the inverse of the provided sensor's status. 
     */
    getInverseStatus(sensor) {
        if (sensor.StatusType === this.constants.deviceStatus.opened.Name) {
            if (this.isOpen(sensor) || this.isOpenClosed(sensor)) {
                return 'Closed';
            }
        }
        else if (sensor.StatusType === this.constants.deviceStatus.closed.Name) {
            if (this.isClosed(sensor) || this.isOpenClosed(sensor)) {
                return 'Opened';
            }
        }

        return this.getStatusText(sensor);
    }

    handleFailureClick() {
        if (this.step === 'step-openClosed') {
            this.sensor.StatusType = this.constants.deviceStatus.opened.Name;
            this.completeStep(this.step);
        } else
            this.startStep('step-failure');
    }

    handleSuccessClick() {
        if (this.step === 'step-openClosed')
            this.sensor.StatusType = this.constants.deviceStatus.closed.Name;

        this.completeStep(this.step);
    }

    /**
     * Returns true if the provided step is the current step; otherwise, false.
     * 
     * @param {...string} steps One or more strings that represents a step.
     * @returns {boolean} True if any of the provided steps are the current step; otherwise, false. 
     */
    onStep() {
        return _.some(arguments, (value) => this.step === value);
    }

    canSupportSensor(sensor) {
        return this.isDoorWindow(sensor) || this.isGarageDoor(sensor);
    }

    getName() {
        return 'Walkthrough';
    }

    startTest() {
        this.startStep('step-poll');
    }

    stopTest() {
        this.actionContext.device.cancelPoll(this.poll);
        this.$timeout.cancel(this.wait);
    }

    _doPoll(desiredStatus, attempts) {
        const maxInterval = this.constants.polling.tests.walkthrough.pollingInterval;
        const maxAttempts = _.isNil(attempts) ? this.constants.polling.tests.walkthrough.maxIntervalCount : attempts;

        return this.actionContext.device.updateSensor(this.sensor).then(() => {
            this.poll = this.actionContext.device.startPoll(this.sensor, desiredStatus, maxInterval, maxAttempts);
            return this.poll;
        });
    }

    _doSleep(timeout = 5000) {
        this.wait = this.$timeout(_.noop, timeout);
        return this.wait;
    }
}

export default {
    template: template,
    bindings: {},
    controller: WalkthroughTestController
};