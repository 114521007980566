import * as angular from 'angular';
import 'angular-ui-router';

import './review.scss';

import commonModule from '../common';
import menuModule from '../menu';
import modalModule from './modals';

import reviewHomeComponent from './home.component';


export default angular
    .module('fp:activationswizard:review', [
        'ui.router',
        commonModule.name,
        menuModule.name,
        modalModule.name
    ])
    .config( /*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            .state('review', {
                abstract: true,
                parent: 'secure',
                url: '/review',
                template: '<ui-view></ui-view>',
                data: {
                    next: /*@ngInject*/($state, appActions) => {
                        appActions.completeModule(constants.sectionTypes.review);
                        $state.go('tos.options');
                    },
                    prev: /*@ngInject*/ ($state) => {
                        $state.go('training.home');
                    },
                    sectionType: constants.sectionTypes.review,
                    theme: 'app-theme-default-navbar-gray'
                },
                resolve: {
                    isSensorSetupComplete: /*@ngInject*/ (grant, setupComplete) => {
                        return grant.only({
                            test: 'isSensorSetupComplete',
                            state: 'sensor.home'
                        });
                    }
                }
            })
            .state('review.home', {
                url: '',
                template: '<aw-review-home></aw-review-home>',
                onEnter: /*@ngInject*/ (appActions) => {
                    appActions.startModule(constants.sectionTypes.review);
                }
            })
;
    })
    .component('awReviewHome', reviewHomeComponent);