import template from './registeringComplete.html';

class RegisteringCompleteController {
    /*@ngInject*/
    constructor() {

    }

    $onInit() {
    }
}

export default {
    template: template,
    bindings: {},
    controller: RegisteringCompleteController
};