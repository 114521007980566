import { createBrowserHistory } from 'history';

class HistoryService {
    /*@ngInject*/
    constructor() {
        this.history = createBrowserHistory();
    }

    listenToHistory() {
        return this.history.listen((location, action) => {
            console.log(action, location.pathname, location.state);
        });
    }

    pushToHistory(url, state) {
        this.history.push(url, state);
    }

    /**
     * navigates use to point in browser history
     * @param {int} stepsInHistory how far back to move and direction indicated by positive or negative integers
     * -1 takes user back one step, 1 takes user forward one step in history
     */
    goToHistory(stepsInHistory) {
        this.history.go(stepsInHistory);
    }
}

export default HistoryService;