import * as _ from 'lodash';
import error from '../../util/error';
import moment from 'moment';

import { START_LOADING, STOP_LOADING, CUSTOMER_FETCH_REQUEST, CUSTOMER_FETCH_FAILURE, CUSTOMER_FETCH_SUCCESS, PASSCODE_UPDATE_REQUEST, PASSCODE_UPDATE_FAILURE, PASSCODE_UPDATE_SUCCESS, PASSCODE_VALIDITYCHECK_REQUEST, PASSCODE_VALIDITYCHECK_FAILURE } from '../../action/customer.actions';
import { GET_ACCOUNT_INFORMATION_SUCCESS } from '../../action/account.actions';

import constants from '../../constants/constants';

const initialState = {
    ignore: [],
    value: {},
    lastUpdatedDate: null,
    isFetching: false,
    isUpdating: false,
    hasError: false,
    message: null,
    isVideoOnly: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case CUSTOMER_FETCH_REQUEST:
            return _.mergeCustom({}, state, {
                isFetching: true,
                hasError: false,
                message: null
            });
        case CUSTOMER_FETCH_FAILURE:
            return _.mergeCustom({}, state, {
                isFetching: false,
                hasError: true,
                message: error.parse(action.payload.error)
            });
        case CUSTOMER_FETCH_SUCCESS:
            return _.mergeCustom({}, state, {
                value: action.payload.data,
                lastUpdatedDate: moment.utc(),
                isFetching: false
            });
        case PASSCODE_VALIDITYCHECK_REQUEST:
            return _.mergeCustom({}, state,
                {
                    isUpdating: true
                });
        case PASSCODE_VALIDITYCHECK_FAILURE:
            return _.mergeCustom({}, state,
                {
                    isUpdating: false
                });
        case PASSCODE_UPDATE_REQUEST:
            return _.mergeCustom({}, state, {
                isUpdating: true,
                hasError: false,
                message: null
            });
        case PASSCODE_UPDATE_FAILURE:
            return _.mergeCustom({}, state, {
                isUpdating: false,
                hasError: true,
                message: error.parse(action.payload.error)
            });
        case PASSCODE_UPDATE_SUCCESS:
            return _.mergeCustom({}, state, {
                value: action.payload.data,
                lastUpdatedDate: moment.utc(),
                isUpdating: false
            });
        case START_LOADING:
            return {
                ...state,
                isUpdating: true
            };
        case STOP_LOADING:
            return {
                ...state,
                isUpdating: false
            };
        case GET_ACCOUNT_INFORMATION_SUCCESS:
            return {
                ...state,
                isVideoOnly: action.payload.MonitoringPlanID === constants.monitoringPlan.VideoOnly.id
            };
        default:
            return state;
    }
};