import * as _ from 'lodash';

class Store {
    /*@ngInject*/
    constructor(store, constants) {
        this.local = store.getNamespacedStore(constants.storage.prefix, 'localStorage');
        this.session = store.getNamespacedStore(constants.storage.prefix, 'sessionStorage');
    }

    get(key, storageType) {
        let opts = this.checkStorageType(storageType);
        let result;

        if (opts.session) {
            result = this.session.get(key);
        }

        if (_.isEmpty(result) && opts.local) {
            result = this.local.get(key);
        }

        return result;
    }

    set(key, value, storageType) {
        let opts = this.checkStorageType(storageType);

        if (opts.session) {
            this.session.set(key, value);
        } else {
            this.session.remove(key);
        }
        
        if (opts.local) {
            this.local.set(key, value);
        } else {
            this.local.remove(key);
        }
    }

    remove(key, storageType) {
        let opts = this.checkStorageType(storageType);
        let result;

        if (opts.session) {
            result = this.session.remove(key);
        }

        if (opts.local) {
            result = this.local.remove(key);
        }
    }

    has(key, storageType) {
        let opts = this.checkStorageType(storageType);
        let result;

        if (opts.session) {
            result = !_.isNil(this.session.get(key));
        }

        if (!result && opts.local) {
            result = !_.isNil(this.local.get(key));
        }

        return result;
    }

    checkStorageType(storageType) {
        let local = storageType === 'local' || _.isEmpty(storageType);
        let session = storageType === 'session' || _.isEmpty(storageType);

        return {
            local: local,
            session: session
        };
    }
}

export default Store;