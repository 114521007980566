class SegmentService {
    /*@ngInject*/
    constructor() { }

    registerEvent(eventName, eventData) {
        window.analytics.track(eventName, eventData);
    }

    registerSegmentStepClickEvent(title) {
        this.registerEvent('Step Clicked', {
            'Step Name': title,
            'Step Complete': true
        });
    }
}

export default SegmentService;