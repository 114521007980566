import * as _ from 'lodash';
import template from './setup.html';
import './home.scss';

class LocksSetupController {
    /*@ngInject*/
    constructor($ngRedux, dataContext, $state, $q, actionContext, constants, fpStore, orderActions, appActions, $stateParams, $window, $timeout, zwaveService) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.showModal = false;
        this.$q = $q;
        this.actionContext = actionContext;
        this.constants = constants;
        this.fpStore = fpStore;
        this.dataContext = dataContext;
        this.appActions = appActions;
        this.orderActions = orderActions;
        this.isAdditional = $stateParams.isAdditional;
        this.$window = $window;
        this.$timeout = $timeout;
        this.zwaveService = zwaveService;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.nextState = !this.isInitialOrder ? 'sensor.list' : 'activation.complete';
        this.isLoadingZWaveWizard = true;
        this.displayAdcWizard = false;

        if (!this.actionContext.order.hasLock() ||
            this.actionContext.order.hasYaleLock()) {
            this.$timeout(() => {
                this.showOverlay();
            });
        } 
    }

    $onDestroy() {
        this.unsubscribe();
    }

    showOverlay() {
        this.actionContext.zwave.zWaveHandOff();
        this.showModal = true;
        this.displayAdcWizard = true;
    }


    onWizardComplete() {
        this.actionContext.zwave.zWaveHandBack();
        this.showModal = false;
        this.isLoadingZWaveWizard = true;

        const isInitialOrder = this.isInitialOrder && !this.appActions.isInitialWithAdditional();
        this.zwaveService.activateZwaveDevice(isInitialOrder, this.products).then(() => {
            if (this.fpStore.has(this.constants.storage.numberOfSmartLocksToActivate, 'session')) {
                this.fpStore.remove(this.constants.storage.numberOfSmartLocksToActivate, 'session');
            }
        }).finally(() => {
            if (isInitialOrder) {
                this.appActions.completeStep('automation');
                this.orderActions.hasAdditionalEquipment(false)
                    .then((result) => {
                        this.isLoadingZWaveWizard = false;
                        if (result)
                            this.$state.go(this.constants.routerStates.airfxTunnel);
                        else
                            this.$state.go(this.constants.routerStates.menuHome);
                    });
            } else {
                this.isLoadingZWaveWizard = true;
                this.$timeout(() => {
                    this.$state.go(this.constants.routerStates.redirectToAirfx, { category: 'home automation' });
                }, 2000);
            }
        });
    }

    onLoadZWaveWizard() {
        this.isLoadingZWaveWizard = false;
    }

    onYouTubeApiReady() {
        const onPlayerStateChange = (event) => {
            this.$window.parent.postMessage('youTubeStateChange', '*');
        }

        new YT.Player('player', {
            events: {
                onStateChange: onPlayerStateChange
            }
        });
    }

    _mapState(state) {
        return {
            isInitialOrder: state.account.user.isInitialOrder,
            products: _.map(state.account.order.itemsByID)
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: LocksSetupController
};