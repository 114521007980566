import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import commonModule from '../../../common';
import duressCodesComponent from './duressCodes.component';


export default angular
    .module('fp:activationswizard:airfx:modswap:duressCodes', [
        ngRedux,
        commonModule.name
    ])
    .component('awDuressCodes', duressCodesComponent);