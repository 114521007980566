import template from "./inEligible.html";

class InEligibleController {
    /*@ngInject*/
    constructor($ngRedux, $state, constants, actionContext, segment) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.actionContext = actionContext;
        this.isKeypad = this.actionContext.airfxFlow.isKeypad();
        this.segment = segment;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState)(this);
        this.segment.registerEvent(this.constants.events.segment.panelSwapNotSupportedLanding, {});

        if (this.targetSku === this.constants.panelVersions.iqPanel) {
            this.customerSupportPhone = this.constants.customerSupportIQ.phone;
        } else {
            this.customerSupportPhone = !this.$ngRedux.getState().account.origin.startedOnAmazon
                ? this.constants.customerSupportHub.phone
                : this.constants.customerSupportHub.amazonPhone;
        }
    }

    $onDestroy() {
        this.unsubscribe();
    }

    onCallSupportClick() {
        this.segment.registerEvent(this.constants.events.segment.panelSwapClickCallSupport, {});
    }

    _mapState(state) {
        return {
            sku: state.account.airfxFlow.sku,
            targetSku: state.account.airfxFlow.targetSku
        };
    }
}

export default {
  template: template,
  bindings: {},
  controller: InEligibleController
};