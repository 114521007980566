import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';
import './passcode.scss';

import commonModule from '../common';

import passcodeComponent from './passcode.component';

export default angular
    .module('fp:activationswizard:passcode', [
        'ui.router',
        ngRedux,
        commonModule.name
    ])
    .config( /*@ngInject*/($stateProvider, constants) => {
        $stateProvider
            // Initial
            .state('passcode', {
                abstract: true,
                parent: 'secure',
                url: '/passcode',
                template: '<ui-view></ui-view>',
                data: {
                    next: /*@ngInject*/($state, appActions) => {
                        $state.go('password.home');
                    },
                    prev: /*@ngInject*/ ($state) => {
                        $state.go('predispatch.home');
                    },
                    sectionType: constants.sectionTypes.passcode,
                    theme: 'app-theme-default-navbar-gray'
                }
            })
            .state('passcode.home', {
                url: '',
                template: '<aw-passcode></aw-passcode>',
                onEnter: /*@ngInject*/ (appActions) => {
                    appActions.startModule(constants.sectionTypes.passcode);
                }
            });
    })
    .component('awPasscode', passcodeComponent);
