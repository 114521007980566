import './simpleModalV2.scss';
import template from './simpleModalV2.html';

import * as _ from 'lodash';

class SimpleModalV2Controller {
    /*@ngInject*/
    constructor() {

    }
}

export default {
    template: template,
    bindings: {
        uiState: '@'
    },
    transclude: true,
    controller: SimpleModalV2Controller
};