import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import './mastercode.scss';

import commonModule from '../common';

import masterCodeComponent from './home.component';

export default angular
    .module('fp:activationswizard:mastercode', [
        'ui.router',
        ngRedux,
        commonModule.name
    ])
    .config( /*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            // Initial
            .state('mastercode', {
                abstract: true,
                parent: 'secure',
                url: '/mastercode',
                template: '<ui-view></ui-view>',
                data: {
                    next: /*@ngInject*/($state, appActions, $ngRedux) => {
                        const isIq4Panel = $ngRedux.getState().account.user.isIq4Panel;
                        appActions.completeModule(constants.sectionTypes.mastercode);
                        if (isIq4Panel) {                            
                            $state.go('usercode.home');
                        } else {
                            appActions.completeStep('account');
                            $state.go('menu.home');
                        }                        
                    },
                    prev: /*@ngInject*/ ($state) => {
                        $state.go('password.home');
                    },
                    sectionType: constants.sectionTypes.mastercode,
                    theme: 'app-theme-default-navbar-gray'
                }                
            })
            .state('mastercode.home', {
                url: '',
                template: '<aw-master-code></aw-master-code>',
                onEnter: /*@ngInject*/ (appActions) => {
                    appActions.startModule(constants.sectionTypes.mastercode);
                }
            });
    })
    .component('awMasterCode', masterCodeComponent);
