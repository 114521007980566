import './error.scss';
import template from './error.html';

class ErrorComponent {
    /*@ngInject*/
    constructor(constants, actionContext, $rootScope, $ngRedux, $state) {
        this.constants = constants;
        this.actionContext = actionContext;
        this.$rootScope = $rootScope;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        var previousState = this.$rootScope.$resolves.previousState;
        if (!_.isNil(previousState)) {
            var message = `User directed to Oops page. Previous State - Name: [${previousState.current.name}] Template: [${previousState.current.template}] Url: [${previousState.current.url}]  Next State - Name: [${previousState.next.name}] Url: [${previousState.next.url}].`;
            this.actionContext.equipmentSetup.onErrorPageInitialization(message);
        }
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();

        if (this.targetSku === this.constants.panelVersions.iqPanel) {
            this.customerSupportPhone = this.constants.customerSupportIQ.phone;
        } else {
            this.customerSupportPhone = !this.$ngRedux.getState().account.origin.startedOnAmazon
                ? this.constants.customerSupportHub.phone
                : this.constants.customerSupportHub.amazonPhone;
        }
    }

    _mapState() {
        return {
            networkName: state.system.wifi.networkName,
            targetSku: state.account.airfxFlow.targetSku,
            isInitialOrder: state.account.user.isInitialOrder
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: ErrorComponent
};