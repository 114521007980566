import template from './simpleModal.html';

import * as _ from 'lodash';

class SimpleModalController {
    /*@ngInject*/
    constructor () {

    }

    shouldShowHeader() {
        return this.showHeader || !_.isNil(this.title);
    }

    showPositiveButton() {
        return _.isFunction(this.onPositiveClick) && !_.isNil(this.positiveText);
    }

    showNegativeButton() {
        return _.isFunction(this.onNegativeClick) && !_.isNil(this.negativeText);
    }

    shouldShowFooter() {
        return this.showFooter || this.showPositiveButton() || this.showNegativeButton();
    }

    close() {
        this.show = false;
    }
}

export default {
    template: template,
    bindings: {
        title: '@',
        positiveText: '@',
        negativeText: '@',
        onPositiveClick: '&',
        onNegativeClick: '&',
        show: '<',
        showHeader: '<',
        showFooter: '<'
    },
    transclude: true,
    controller: SimpleModalController
};