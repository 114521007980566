import * as angular from 'angular';
import 'angular-ui-router';
import ngRedux from 'ng-redux';
import 'ui-router.grant';
import * as _ from 'lodash';
import './duresscode.scss';

import { commonModule } from '../common';
import { duresscodeHomeComponent } from './home.component';

export const duresscodeModule = angular
    .module('fp:activationswizard:duresscode',
        [
            ngRedux,
            'ui.router',
            'ui.router.grant',
            commonModule.name
        ])
    .config(/*@ngInject*/
        ($stateProvider: ng.ui.IStateProvider, constants: any) => {
            $stateProvider
                .state('duresscode',
                    {
                        abstract: true,
                        parent: 'secure',
                        url: '/duresscode',
                        template: '<ui-view></ui-view>',
                        data: {
                            next: ($state: ng.ui.IStateService, appActions: any, $ngRedux: any) => {
                                'ngInject';
                                appActions.completeModule(constants.sectionTypes.duresscode);
                                appActions.completeStep('account');
                                $state.go('menu.home');
                            },
                            prev: ($state: ng.ui.IStateService, $ngRedux: any) => {
                                'ngInject';
                                $state.go('usercode.home');
                            },
                            sectionType: constants.sectionTypes.duresscode,
                            theme: 'app-theme-default-navbar-gray'
                        },
                        resolve: {
                            isPanelComplete: /*@ngInject*/ (grant: any, setupComplete: any) => {
                                return grant.only({
                                    test: 'isPanelComplete',
                                    state: 'panel.searching'
                                });
                            }
                        }
                    })
                .state('duresscode.home',
                    {
                        url: '',
                        template: '<aw-duress-code></aw-duress-code>',
                        onEnter: /*@ngInject*/ (appActions: any) => {
                            appActions.startModule(constants.sectionTypes.duresscode);
                        }
                    });
        })
    .component('awDuressCode', duresscodeHomeComponent);
