import template from './keychainRemote.html';

class KeychainRemoteController {
    /*@ngInject*/
    constructor(constants) {
        this.constants = constants;
    }
}

export default {
    template: template,
    bindings: {},
    controller: KeychainRemoteController
};