import * as _ from 'lodash';
import * as angular from 'angular';

import './verticalOverlay.scss';

import Template from './verticalOverlay.html';

class VerticalComponent {
    /*@ngInject*/
    constructor ($ngRedux, $timeout, $window, constants) {
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
        this.$window = $window;
        this.constants = constants;
    }

    $onInit() {
        this.unsubRedux = this.$ngRedux.connect(this._mapState.bind(this))(this._mergeState.bind(this));

        let element = angular.element(this.$window);

        let updateShow = this._updateShow.bind(this);
        element.on('resize', updateShow);
        this.unsubResize = () => {
            element.off('resize', updateShow);
        };

        updateShow();
    }

    $destroy() {
        this.unsubRedux();
        this.unsubResize();
    }

    _updateShow() {
        return this.$timeout(() => {
            this.show = this._displayVerticalOverlay();
        });
    }

    _displayVerticalOverlay() {
        let width = this.$window.screen.width;
        let height = this.$window.screen.height;
        return !this.constants.disableVerticalCheck &&
               this.isEnabled &&
               !this.isImpersonator &&
               (width > height) &&
               ((width / height) > 1.7);
    }

    _mapState(state) {
        return {
            isImpersonator: state.account.user.isImpersonator,
            isEnabled: _.isNil(state.router.currentState.data) || state.router.currentState.data.allowHorizontal !== true
        };
    }

    _mergeState(stateSlice, actions) {
        _.assign(this, stateSlice, actions);
        this._updateShow();
    }
}

export default {
    template: Template,
    bindings: { },
    transclude: true,
    controller: VerticalComponent
};