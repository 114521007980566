import * as _ from 'lodash';
import './home.scss';
import template from './home.html';

class LocksController {
    /*@ngInject*/
    constructor($ngRedux, dataContext, $window, $state, $location, constants, $scope, SharedState, $stateParams, actionContext) {
        this.$ngRedux = $ngRedux;
        this.dataContext = dataContext;
        this.$window = $window;
        this.$state = $state;
        this.$location = $location;
        this.constants = constants;
        this.$scope = $scope;
        this.SharedState = SharedState;
        this.showModal = false;
        this.isAdditional = $stateParams.isAdditional;
        this.actionContext = actionContext;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState)(this);
        this.message = this.instructions 
            ? 'To setup your lock, please follow the instructions included in the brown box.'
            : 'Let\'s finish activating your system before getting your locks setup.';
        this.yaleLock = this.actionContext.order.hasYaleLock();
    }

    showOverlay() {
        this.showModal = true;
    }

    $onDestroy() {
        this.unsubscribe();
    }

    _mapState(state) {
        return {
            locks: _.filter(_.map(state.account.order.itemsByID), (item) => {
                return _.includes(!_.isNil(item.ProductName) ? item.ProductName.toLowerCase(): null, 'lock');
            })
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: LocksController
};