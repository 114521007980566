import * as _ from 'lodash';
class OrderService {
    /*@ngInject*/
    constructor($http, $window, fpStore, Restangular, constants) {
        this.$http = $http;
        this.$window = $window;
        this.fpStore = fpStore;
        this.restangular = Restangular;
        this.constants = constants;
    }

    getDeliveredAndUnactivatedOrders() {
        return this.restangular
            .all('orders')
            .all('delivered-unactivated')
            .withHttpConfig({cache: false}) 
            .getList()
            .then((result) => {
                return result.plain();
            });
    }

    getOrderBoxType() {
        return this.restangular
            .one('orders')
            .one('box')
            .get()
            .then(result => {
                return result;
            });
    }

    getOrderProducts(isInitialOrder, isDelivered) {
        return this.restangular
            .one('account')
            .one('order')
            .all('products')
            .customGET('', {
                isInitialOrder: isInitialOrder,
                isDelivered: isDelivered
            })
            .then((res) => {
                if (!isInitialOrder) {
                    var results = _.filter(res, (p) => {
                        if (_.includes(this.constants.barcodeScannerFlowSkus, p.ProductSku)) {
                            return true;
                        } else {
                            if (p.ShipDate != null) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    });
                    return results;
                } else {
                    return res.plain();
                }
           
            });
    }

    getOrderProduct(orderProductId) {
        return this.restangular
            .one('account')
            .one('order')
            .one('products', orderProductId)
            .get()
            .then((res) => {             
                return res.plain();
            });
    }

    convertToDevice(orderProductId) {
        return this.restangular
            .one('account')
            .one('order')
            .one('products', orderProductId)
            .one('device')
            .get()
            .then((res) => {
                return res.plain();
            });
    }

    setOrderProductActivated(orderProductId) {
        return this.restangular
            .one('account')
            .one('order')
            .one('products', orderProductId)
            .customPUT({}, 'activate');
    }

    /**
     * Set the order that is currently being activated. This should only be used for explicit order activation through
     * the mobile app. Activations that still use an activation code SHOULD NOT call this method.
     * 
     * @param {} orderId
     */
    setOrderIdToActivate(orderId) {
        this.fpStore.set(this.constants.storage.orderIdToActivateKey, orderId, 'session');
    }

    /**
     * Clear out the order that is currently being activated.
     */
    clearOrderIdToActivate() {
        this.fpStore.remove(this.constants.storage.orderIdToActivateKey, 'session');
    }

    /** 
     * Get all the order products from OrderProduct table without any filter 
     */
    getAllOrderProducts() {
        return this.restangular
            .one('account')
            .one('order')
            .one('products')
            .one('getAllOrderProducts')
            .get()
            .then((res) => {
                return res.plain();
            });
    } 

    checkPanelCompatibility(targetSku) {
        return this.restangular
            .one('account')
            .one('order')
            .one('products')
            .one('checkPanelCompatibility')
            .customGET('',
                {
                    targetSku: targetSku
                });
    }

    getOrder() {
        return this.restangular
            .one('orders')
            .withHttpConfig({
                cache: false
            })
            .get();
    }

    getOrderFulfillmentSteps() {
        return this.restangular
            .one('orders/fulfillment-steps')
            .withHttpConfig({
                cache: false
            })
            .get();
    }

    getSwapOrderProduct() {
        return this.restangular
            .one('account')
            .one('order')
            .one('products')
            .one('getSwapOrderProduct')
            .get()
            .then((res) => {
                return res.plain();
            });
    }
}

export default OrderService;