import template from './address.html';

class AddressComponentController {
    /*@ngInject*/
    constructor() {
        
    }
}

export default {
    template: template,
    bindings: {
        address: '<'
    },
    controller: AddressComponentController
};