import template from './panelImg.html';

class PanelImgController {
    /*@ngInject*/
    constructor ($ngRedux, constants) {
        this.$ngRedux = $ngRedux;
        this.constants = constants;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState)(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    _mapState(state) {
        return {
            panelVersion: state.system.panel.type
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: PanelImgController
};