import template from './airfxTunnel.html';
import './airfxTunnel.scss';

class CompleteController {
    /*@ngInject*/
    constructor($ngRedux, $state, $q, constants, fpStore, actionContext, dataContext) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$q = $q;
        this.constants = constants;
        this.fpStore = fpStore;
        this.actionContext = actionContext;
        this.dataContext = dataContext;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.fpStore.set(this.constants.storage.initialOrderAdditionalWorkflow, true, 'session');
        // Need to activate existing initial sensors that are not activated yet,
        // in case of user decides to go through Airfx process to add additional sensors
        this.activateInitialSensors();
    }

    $onDestroy() {
        this.unsubscribe();
    }

    goToAirfx(toAirFxList) {
        if (toAirFxList) {
            this.actionContext.order.hasAdditionalEquipment(true)
              .then(() => {
                  this.actionContext.order.setOrderBoxType(1);
                  this.$state.go(this.constants.routerStates.airfxList);    
              });
        } else
            this.$state.go(this.constants.routerStates.menuHome);
    }

    activateInitialSensors() {
        const promises = [];

        //mark panic sensors complete
        _.forEach(this.sensors, sensor => {
            if (sensor.DeviceType === 'ImageSensor' || sensor.DeviceType === 'PanicButton')
                sensor.isComplete = true;
        });

        //find all sensors to set activation date
        _.forEach(this.sensors, sensor => {
            if (!sensor.isComplete) {
                return;
            }

            //make sure both dl codes are uppercase
            if (!_.isNil(sensor.DLCode))
                sensor.DLCode = sensor.DLCode.toUpperCase();

            _.each(this.products, (product) => {
                if (!_.isNil(product.DLCode))
                    product.DLCode = product.DLCode.toUpperCase();
            });

            const product = _.find(this.products, { DLCode: sensor.DLCode });
            if (_.isNil(product) || product.isActivated) {
                //if panic pendant or image sensor process differently
                if ((sensor.DeviceType === 'ImageSensor' || sensor.DeviceType === 'PanicButton') && sensor.isSkipped !== true) {
                    const linkedProduct = _.find(this.products, { SensorNumber: sensor.SensorID });
                    if (!_.isNil(linkedProduct)) {
                        console.log(linkedProduct);
                        promises.push(this.actionContext.order.setOrderProductActivated(linkedProduct));
                    }
                }
                return;
            }
            promises.push(this.actionContext.order.setOrderProductActivated(product));
        });

        return this.$q.all(promises);
    }

    _mapState(state) {
        return {
            isActivated: state.account.isActivated,
            hasError: state.system.testmode.hasError,
            sensors: _.map(state.system.devices.sensorsByID),
            products: _.map(state.account.order.itemsByID)
        };
    }

}

export default {
template: 
    template,
    bindings: {},
controller: CompleteController
};