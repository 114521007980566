import template from './complete.html';

class CompleteController {
    /*@ngInject*/
    constructor () {

    }
}

export default {
    template: template,
    bindings: {},
    controller: CompleteController
};