import * as _ from 'lodash';
import moment from 'moment';

export default function stateDescriptionMiddleware($log, dataContext, constants) {
    'ngInject';
    return store => next => action => {
        let oldState = store.getState();
        let returnedValue = next(action);
        let newState = store.getState();
        let metadata = extractMetadata(action);

        if (!_.isNil(metadata) && !_.isNil(metadata.customDescription) && metadata.customDescription === true) {
            $log.debug(`Processing custom description for sectionType [${action.sectionType}]...`);

            let customDescription = '';
            switch (metadata.sectionType) {
                case constants.sectionTypes.predispatch:
                    if (oldState.account.predispatch.contacts.length < newState.account.predispatch.contacts.length)
                        customDescription += 'Predispatch has been added. |';
                    else if (oldState.account.predispatch.contacts.length > newState.account.predispatch.contacts.length)
                        customDescription += 'Predispatch has been deleted. |';
                    else {
                        let count = 0;
                        _.forEach(oldState.account.predispatch.contacts, (oldDispatch, key) => {
                            if (oldDispatch.FirstName !== newState.account.predispatch.contacts[count].FirstName ||
                                oldDispatch.LastName !== newState.account.predispatch.contacts[count].LastName ||
                                oldDispatch.Phone.replace(/-/g, '') !== newState.account.predispatch.contacts[count].Phone.replace(/-/g, ''))
                                customDescription += 'Predispatch contact [' + oldDispatch.FirstName + ' ' + oldDispatch.LastName + ', ' + oldDispatch.Phone.replace(/-/g, '') + '] changed to [' +
                                    newState.account.predispatch.contacts[count].FirstName + ' ' + newState.account.predispatch.contacts[count].LastName + ', ' + newState.account.predispatch.contacts[count].Phone.replace(/-/g, '') + ']. |';
                            count++;
                        });
                    }

                    action.metadata.description = _.trimEnd(customDescription, '|');
                    break;

                case constants.sectionTypes.premise:
                    if (oldState.account.premises.value.AddressLine1 !== newState.account.premises.value.AddressLine1 ||
                        oldState.account.premises.value.AddressLine2 !== newState.account.premises.value.AddressLine2 ||
                            oldState.account.premises.value.City !== newState.account.premises.value.City ||
                            oldState.account.premises.value.State !== newState.account.premises.value.State ||
                            oldState.account.premises.value.PostalCode !== newState.account.premises.value.PostalCode)
                        customDescription += 'Premise address [' + oldState.account.premises.value.AddressLine1 + ', ' + oldState.account.premises.value.AddressLine2 + ', ' + oldState.account.premises.value.City + ', ' + oldState.account.premises.value.State + ', ' + oldState.account.premises.value.PostalCode +
                            '] changed to [' + newState.account.premises.value.AddressLine1 + ', ' + newState.account.premises.value.AddressLine2 + ', ' + newState.account.premises.value.City + ', ' + newState.account.premises.value.State + ', ' + newState.account.premises.value.PostalCode + ']. |';

                        action.metadata.description = _.trimEnd(customDescription, '|');
                    break;

                case constants.sectionTypes.mastercode:
                    if (oldState.system.code.mastercode !== newState.system.code.mastercode)
                        action.metadata.description = 'The panel code was changed from [' + oldState.system.code.mastercode + '] to [' + newState.system.code.mastercode + '].';

                    break;
                case constants.sectionTypes.usercode:
                    if (oldState.system.code.codes.length !== newState.system.code.codes.length) {
                        let lastIndex = newState.system.code.codes.length - 1;
                        action.metadata.description = `The user code was added, FirstName: [${newState.system.code.codes[lastIndex].FirstName}] LastName: [${newState.system.code.codes[lastIndex].LastName}] Code: [${newState.system.code.codes[lastIndex].Code}]`;
                    }
                    break;
                default:
                    return returnedValue;
            }

            if (!_.isNil(action.metadata) && !_.isNil(action.metadata.description)) {
                $log.debug(`Custom description [${action.metadata.description}]`);
            }
        }

        return returnedValue;
    };


    /**
     * Extract the metadata object from the action if it exists. If no
     * metadata object exists null is returned.
     * 
     * @param {Object} action 
     * @returns {Object} 
     */
    function extractMetadata(action) {
        if (_.isNil(action)) {
            return null;
        }

        if (!_.isNil(action.payload) &&
            !_.isNil(action.payload.currentState) &&
            !_.isNil(action.payload.currentState.data)) {
            return action.payload.currentState.data;
        }

        if (!_.isNil(action.metadata)) {
            return action.metadata;
        }

        return null;
    }
}