import template from './saved.html';

class SavedController {
    /*@ngInject*/
    constructor(constants) {
        this.constants = constants;
    }

}

export default {
    template: template,
    bindings: {},
    controller: SavedController
};