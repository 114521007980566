import template from './servicePlan.html';

class ServicePlanController {
    /*@ngInject*/
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
    }

    _mapState(state) {
        return _.cloneDeep({
            amazonCustomerRmr: state.application.config.amazonCustomerRmr,
        });
    }
}

export default {
    template: template,
    bindings: {},
    controller: ServicePlanController
};