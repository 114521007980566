import template from './keychainRemoteOverview.html';

class KeychainRemoteOverviewController {
    /*@ngInject*/
    constructor(constants, $state, actionContext, $ngRedux) {
        this.constants = constants;
        this.$state = $state;
        this.actionContext = actionContext;
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
    }

    $onDestroy() {
        this.unsubscribe();
    }

    goToNext() {
        this.actionContext.device.startExceptionDeviceSetup(this.deviceSelected.SensorID);
        this.actionContext.device.finishExceptionDeviceSetup(this.deviceSelected);
        this.actionContext.device.finishSetup();
        this.$state.go('sensor.list');
    }

    _mapState(state) {
        return {
            deviceSelected: state.system.devices.sensorsByID[state.system.devices.selectedSensorID],
            isIq4Panel: state.account.user.isIq4Panel
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: KeychainRemoteOverviewController
};