import * as _ from 'lodash';
import '../../util/mergeCustom';

import {
    SEND_FP_SMS_FAILURE,
    SEND_FP_SMS_STARTED,
    SEND_FP_SMS_SUCCESS,
    ENROLL_CS_SMS_FAILURE,
    ENROLL_CS_SMS_STARTED,
    ENROLL_CS_SMS_SUCCESS
} from '../../action/sms.actions';

const initialState = {
    isSendingFpSms: false,
    isEnrollingRapidSms: false
};

export default (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
    case SEND_FP_SMS_FAILURE:
        return _.mergeCustom({},
            state,
            {
                isSendingFpSms: false
            });
    case SEND_FP_SMS_STARTED:
        return _.mergeCustom({},
            state,
            {
                isSendingFpSms: true
            });
    case SEND_FP_SMS_SUCCESS:
        return _.mergeCustom({},
            state,
            {
                isSending: false
            });
    case ENROLL_CS_SMS_FAILURE:
        return _.mergeCustom({},
            state,
            {
                isEnrollingRapidSms: false
            });
    case ENROLL_CS_SMS_STARTED:
        return _.mergeCustom({},
            state,
            {
                isSending: true
            });
    case ENROLL_CS_SMS_SUCCESS:
        return _.mergeCustom({},
            state,
            {
                isEnrollingRapidSms: false
            });
    default:
        return state;
    }
};
