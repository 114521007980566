import * as _ from 'lodash';

export const MOD_SWAP_DL_VERIFICATION_FAILURE = 'MOD_SWAP_DL_VERIFICATION_FAILURE';
export const MOD_SWAP_DL_VERIFICATION_SUCCESS = 'MOD_SWAP_DL_VERIFICATION_SUCCESS';
export const MOD_SWAP_TIMEOUT = 'MOD_SWAP_TIMEOUT';
export const ROUND_TRIP_COMMUNICATION_FAILURE = 'ROUND_TRIP_COMMUNICATION_FAILURE';
export const ROUND_TRIP_COMMUNICATION_SUCCESS = 'ROUND_TRIP_COMMUNICATION_SUCCESS';
export const MOD_SWAP_COMPLETE = 'MOD_SWAP_COMPLETE';
export const MOD_SWAP_STARTED = 'MOD_SWAP_STARTED';
export const MOD_SWAP_STATUS_CHECK_STARTED = 'MOD_SWAP_STATUS_CHECK_STARTED';
export const MOD_SWAP_STATUS_CHECK_COMPLETED = 'MOD_SWAP_STATUS_CHECK_COMPLETED';
export const MOD_SWAP_STATUS_CHECK_FAILED = 'MOD_SWAP_STATUS_CHECK_FAILED';
export const MOD_SWAP_FAILED = 'MOD_SWAP_FAILED';
export const SENSOR_TRANSFER_COMPLETE = 'SENSOR_TRANSFER_COMPLETE';
export const SENSOR_TRANSFER_STARTED = 'SENSOR_TRANSFER_STARTED';
export const DESIGNATE_SWAP_TYPE = 'DESIGNATE_SWAP_TYPE';

class ModSwapActions {
    /*@ngInject*/
    constructor($log, $ngRedux, $state, constants, $q, dataContext) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.$q = $q;
        this.dataContext = dataContext;
    }

    onModSwapFailed() {
        this._dispatch({
            type: MOD_SWAP_FAILED,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.modSwap.intro,
                description: 'ModSwap process failed.',
                actionType: 'Error'
            }
        });
    }

    onModSwapStatusCheckStarted() {
        this._dispatch({
            type: MOD_SWAP_STATUS_CHECK_STARTED,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.modSwap.intro,
                description: 'ModSwap status check started.',
                actionType: 'Info'
            }
        });
    }

    onModSwapStatusCheckFail() {
        this._dispatch({
            type: MOD_SWAP_STATUS_CHECK_FAILED,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.modSwap.intro,
                description: 'ModSwap check status failed.',
                actionType: 'Error'
            }
        });
    }

    onModSwapStatusCheckCompleted(oldPanelSerial, newPanelSerial) {
        this._dispatch({
            type: MOD_SWAP_STATUS_CHECK_COMPLETED,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.modSwap.intro,
                description: 'ModSwap check status completed - OldSerialNumber: [' + oldPanelSerial + '] NewSerialNumber: [' + newPanelSerial + '].',
                actionType: 'Info'
            }
        });
    }

    onModSwapStarted() {
        this._dispatch({
            type: MOD_SWAP_STARTED,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.modSwap.intro,
                description: 'ModSwap process started.',
                actionType: 'Info'
            }
        });
    }

    onModSwapComplete() {
        this._dispatch({
            type: MOD_SWAP_COMPLETE,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.modSwap.success,
                description: 'ModSwap process successfully completed.',
                actionType: 'Info'
            }
        });
    }

    onRoundTripCommunicationFailure() {
        this._dispatch({
            type: ROUND_TRIP_COMMUNICATION_FAILURE,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.modSwap.roundTrip,
                description: 'Round trip communication test to ADC failed.',
                actionType: 'Error'
            }
        });
    }

    onRoundTripCommunicationSuccess() {
        this._dispatch({
            type: ROUND_TRIP_COMMUNICATION_SUCCESS,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.modSwap.roundTrip,
                description: 'Round trip communication test to ADC completed.',
                actionType: 'Info'
            }
        });
    }

    onModSwapTimeout(milliseconds) {
        this._dispatch({
            type: MOD_SWAP_TIMEOUT,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.modSwap.timeout,
                description: `Timed out at ${this._millisToMinutesAndSeconds(milliseconds)} while finalizing mod swap.`,
                actionType: 'Error'
            }
        });
    }

    onVerifySensorSuccess(message) {
        this._dispatch({
            type: MOD_SWAP_DL_VERIFICATION_SUCCESS,
            payload: {
                message
            },
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.modSwap.dlCodeVerification,
                description: message,
                actionType: 'Info'
            }
        });
    }

    onVerifySensorFailure(error) {
        this._dispatch({
            type: MOD_SWAP_DL_VERIFICATION_FAILURE,
            payload: {
                error
            },
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.modSwap.dlCodeVerification,
                description: error,
                actionType: 'Error'
            }
        });
    }

    onSensorTransferCompleted(milliseconds) {
        this._dispatch({
            type: SENSOR_TRANSFER_COMPLETE,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.modSwap.sensorTransfer,
                description: `ModSwap sensor transfer completed in ${this._millisToMinutesAndSeconds(milliseconds)}`,
                actionType: 'Info'
            }
        });
    }

    onSensorTransferStarted() {
        this._dispatch({
            type: SENSOR_TRANSFER_STARTED,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.modSwap.sensorTransfer,
                description: 'ModSwap sensor transfer started.',
                actionType: 'Info'
            }
        });
    }

    designateSwapType(swapType) {
        this._dispatch({
            type: DESIGNATE_SWAP_TYPE,
            payload: {
                swapType
            }
        });
    }

    _dispatch(obj) {
        this.$ngRedux.dispatch(obj);
    }

    _millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }
}

export default ModSwapActions;