import * as angular from 'angular';
import 'angular-ui-router';

import commonModule from '../../common';

import errorComponent from './error.component';
import partnerErrorComponent from './partnerError.component';
import inProgressComponent from './inProgress.component';

export default angular.module('fp:activationswizard:common:error', [
        'ui.router',
        commonModule.name
    ])
    .config( /*@ngInject*/ ($stateProvider) => {
        $stateProvider
            .state('error', {
                parent: 'root',
                url: '/error',
                template: '<aw-error-component></aw-error-component>',
                data: {
                    navbar: {
                        top: true,
                        bottom: false
                    },
                    persist: false
                }
            })
            .state('error-partner', {
                parent: 'root',
                url: '/error-partner',
                template: '<aw-error-partner></aw-error-partner>',
                data: {
                    theme: 'app-theme-default',
                    persist: false
                }
            })
            .state('inProgress', {
                parent: 'root',
                url: '/in-progress',
                template: '<aw-in-progress-component></aw-in-progress-component>'
            });
    })
    .component('awErrorComponent', errorComponent)
    .component('awErrorPartner', partnerErrorComponent)
    .component('awInProgressComponent', inProgressComponent);