import template from './settingUp.html';

class SettingUpController {
    /*@ngInject*/
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.isIq4Panel = this.$ngRedux.getState().account.user.isIq4Panel;
        this.isVideoOnly = this.$ngRedux.getState().account.customer.isVideoOnly;
    }
}

export default {
    template: template,
    bindings: {},
    controller: SettingUpController
};