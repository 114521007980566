import template from "./intro.html";

class RadioSwapIntroController {
    /*@ngInject*/
    constructor($ngRedux, $state, constants, $log, SharedState, segment, dataContext, actionContext, $interval) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.showModal = false;
        this.SharedState = SharedState;
        this.isLoading = false;
        this.showhelpModal = false;
        this.segment = segment;
        this.$log = $log;
        this.dataContext = dataContext;
        this.actionContext = actionContext;
        this.$interval = $interval;
    }

    $onInit() {
        this.panelType = this.constants.panelVersions.xtPanel;

        this.actionContext.panel.fetchPanelVersion().then((result) => {
            this.panelType = result.PanelType;
            this.oldPanelSerial = result.SerialNumber;
        });
    }

    setIsLoading(isLoading) {
        this.isLoading = isLoading;
    }

    setHelpModal(showhelpModal) {
        this.showhelpModal = showhelpModal;
        if (showhelpModal) {
            this.SharedState.turnOn('helpPanelModal');
        }
    }

    executeSwap() {
        this.setIsLoading(true);
        this.segment.registerEvent(this.constants.events.segment.radioSwapStartSwap, {});
        this.actionContext.radioSwap.onRadioSwapStarted();
        this.actionContext.modSwap.designateSwapType(this.constants.swapType.radio);
        this.dataContext.radioSwap.swapRadio().then((result) => {
                if (result.SwapResult.Success === true) {
                    this.$log.info('Successfully swapped the radio');
                    this.checkSwapStatus();
                    this.setActivationDate();
                } else {
                    this.actionContext.radioSwap.onRadioSwapFailed();
                    this.setHelpModal(true);
                    this.setIsLoading(false);
                    this.$log.error('Error occurred while swapping the radio:' + result.SwapResult.ErrorMessage);
                }
            })
            .catch((error) => {
                this.actionContext.radioSwap.onRadioSwapFailed();
                this.$log.error('Error occurred while swapping the radio: ' + error);
                this.setHelpModal(true);
                this.setIsLoading(false);
            });
    }

    setActivationDate() {
        this.dataContext.account.order.getSwapOrderProduct().then((orderProduct) => {
            // set order product as activated
            this.actionContext.order.setOrderProductActivated(orderProduct);
        }).catch((error) => {
            this.$log.error('Error getting the order product for swap order.', error);
        });
    }

    checkSwapStatus() {
        this.actionContext.radioSwap.onRadioSwapStatusCheckStarted();
        let currentPanelSerial = this.oldPanelSerial;
        const checkPanelVersionInterval = this.$interval(() => {
                this.actionContext.panel.fetchPanelVersion().then((result) => {
                        currentPanelSerial = result.SerialNumber;
                        if (currentPanelSerial !== this.oldPanelSerial) {
                            this.actionContext.radioSwap.onRadioSwapStatusCheckCompleted(this.oldPanelSerial, currentPanelSerial);
                            this.setIsLoading(false);
                            this.$interval.cancel(checkPanelVersionInterval);
                            this.$state.go('airfx.radioswapSensorTransfer',
                                { swapType: this.constants.swapType.radio });
                        }
                    })
                    .catch((error) => {
                        this.$interval.cancel(checkPanelVersionInterval);
                        this._onCheckRadioFailure(error);
                    });
            },
            this.constants.ping.Swap.pollingInterval,
            this.constants.ping.Swap.maxIntervalCount);

        checkPanelVersionInterval.catch((message) => {
            if (message !== 'canceled') {
                this._onCheckRadioFailure(message);
            }
        });
    }

    _onCheckRadioFailure(message) {
        this.setIsLoading(false);
        this.setHelpModal(true);
        this.actionContext.modSwap.onRadioSwapStatusCheckFail();
        this.$log.error('Error occured while validating the radio swap', message);
    }
}

export default {
    template: template,
    bindings: {},
    controller: RadioSwapIntroController
};