import template from './stepList.html';

class StepListController {
    /*@ngInject*/
    constructor($injector, $log, $state, actionContext, segment) {
        // services
        this.$injector = $injector;
        this.$log = $log;
        this.$state = $state;
        this.actionContext = actionContext;
        this.segment = segment;
    }

    /**
     * Returns the Step Number at the given index. 
     *
     * For New Box, Step Number is starting from 2 - Panel Setup to match the Step on the New Box.
     * For Old Box, Step Number is starting from 1 - Box Check.
     * 
     * @param {} idx 
     * @returns {} int 
     */
    getStepNumber(idx) {
        return idx + 1;
    }

    /**
     * Returns the status of the step at the given index. 
     *
     * 'Disabled' is returned if the given step index is greater than the current index.
     * 'Completed' is returned if the given step index is less than the current index.
     * 'InProgress' is returned if the given step index is equal to the current index.
     * 
     * @param {} idx 
     * @param {} step 
     * @returns {} 
     */
    getStepStatus(idx) {
        // Step Disabled
        if (this.currentStepIndex < idx) {
            return 'Disabled';
        }

        // Step Complete
        if (this.currentStepIndex > idx) {
            return 'Completed';
        }

        // Step InProgress
        return 'InProgress';
    }

    /**
     * Perform the selected action only if the given step is the currently in progress.
     * 
     * @param {} idx 
     * @param {} step 
     * @returns {} 
     */
    doAction(idx, step) {
        idx = this.steps.indexOf(step);

        // step not found
        if (idx === -1) {

        }

        // Step Disabled
        if (this.currentStepIndex < idx) {
            this.$log.info('A step that is disabled has been selected; no action will be executed.');
            return false;
        }

        // Step Complete
        if (this.currentStepIndex > idx) {
            this.$log.info('A step that is complete has been selected; no action will be executed.');
            return false;
        }

        // Step InProgress
        let locals = {
            context: this,
            step: step
        };
        this.segment.registerSegmentStepClickEvent(step.title);
        return this.$injector.invoke(step.action, null, locals);
    }
}

export default {
    template: template,
    bindings: {
        steps: '<',
        currentStepIndex: '<'
    },
    controller: StepListController
};