import template from './incompatibleDeviceModal.html';

class IncompatibleDeviceModalController {
    /*@ngInject*/
    constructor($ngRedux, constants, $state) {
        this.$ngRedux = $ngRedux;
        this.constants = constants;
        this.$state = $state;
    }


    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);

    }

    _mapState(state) {
        return _.cloneDeep({
            targetSku: state.account.airfxFlow.targetSku
        });
    }
}

export default {
    template: template,
    bindings: {},
    controller: IncompatibleDeviceModalController
};