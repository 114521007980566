import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import './predispatch.scss';

import commonModule from '../common';
import componentsModule from './components';

import predispatchHomeComponent from './home.component';
import predispatchEditComponent from './edit.component';

export default angular
    .module('fp:activationswizard:predispatch', [
        'ngAnimate',
        'ui.router',
        ngRedux,
        commonModule.name,
        componentsModule.name
    ])
    .config( /*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            .state('predispatch', {
                abstract: true,
                parent: 'secure',
                url: '/predispatch',
                template: '<ui-view></ui-view>',
                data: {
                    theme: 'app-theme-default-navbar-gray'
                }
            })
            .state('predispatch.home', {
                url: '',
                template: '<aw-predispatch-home></aw-predispatch-home>',
                onEnter: /*@ngInject*/ (appActions) => {
                    appActions.startModule(constants.sectionTypes.predispatch);
                },
                data: {
                    next: /*@ngInject*/($state) => {
                        $state.go('passcode.home');
                    }
                }
            })
            .state('predispatch.edit', {
                url: '/edit',
                template: '<aw-predispatch-edit></aw-predispatch-edit>'
            });
    })
    .component('awPredispatchHome', predispatchHomeComponent)
    .component('awPredispatchEdit', predispatchEditComponent);
