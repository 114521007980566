import './getStartedModal.scss';
import template from './getStartedModal.html';

class GetStartedController {
    /*@ngInject*/
    constructor(SharedState, $ngRedux, dataContext, constants) {
        this.SharedState = SharedState;
        this.$ngRedux = $ngRedux;
        this.modswapService = dataContext.modSwap;
        this.constants = constants;
    }
    $onInit() {
    }

    controlModal(show) {
        if (show === false) {
            this.SharedState.turnOff('getStartedModal');
        }
        if (show === true) {
            this.SharedState.turnOn('getStartedModal');
        }
    }
}

export default {
    template: template,
    bindings: {},
    controller: GetStartedController
};

