let navPrevDirective = ($state, navigation) => {
    'ngInject';
    return {
        restrict: 'A',
        link: ($scope, $element, $attr, ctrl) => {
            $element.bind('click', (evt) => {
                navigation.goPrev();
                evt.preventDefault();
            });
        }
    };
};

export default navPrevDirective;