import constants from '../constants/constants.js';

let activationCodeFormatterDirective = () => {
    'ngInject';
    return {
        require: 'ngModel',
        restrict: 'EA',
        link: ($scope, $element, $attr, ngModel) => {
            //locking form length to value passed from HTML assignment
            $element.on('keypress', (event) => {
                if (event.keyCode !== 13 && $element.val().length >= parseInt($attr.awActivationCodeFormatter)) {
                    event.preventDefault();
                }
            });

            //Beginning of form validation and formatting

            let toUpper = function (value) {
                let viewValue = value.toUpperCase();
                return viewValue;
            }

            let dashmanager = function (value) {
                let viewValue = value;
                
                if (viewValue.length == 4) {

                    //should only be hit when string is being cleared (backspace)
                    if (viewValue[3] === '-') {
                        let returnValue = viewValue.slice(0, 3)
                        return returnValue;
                    }

                    //should only be hit when string is being added to
                    else {
                        let returnValue = viewValue.slice(0, 3) + "-" + viewValue.slice(3, 4);
                        return returnValue;
                    }
                }

                //this regex is for the 6 char a-z code which might come from auto complete
                if (viewValue.match(constants.regex.dlCode)) {
                    let returnValue = viewValue.slice(0, 3) + '-' + viewValue.slice(3, 6);
                    return returnValue;
                }

                else {
                    return viewValue;
                }
            }

            let validityManager = function (value) {
                let viewValue = value;
                const dlCodeFormatter = constants.regex.dlCodeFormatted;
                const dlCodeFormatterHalf = constants.regex.dlCodeHalf;
                const validateFirstHalf = (viewValue.slice(0, 3).match(dlCodeFormatterHalf));
                const validateDash = viewValue[3] === '-';
                const validateSecondHalf = (viewValue.slice(4,7).match(dlCodeFormatterHalf));

                if ((viewValue.match(dlCodeFormatter) &&
                    (validateFirstHalf != null) &&
                    (validateDash != false) &&
                    (validateSecondHalf != null))) {
                    ngModel.$setValidity('valid', true);
                }
                else {
                    ngModel.$setValidity('valid', false);
                }

                return viewValue;

            }

            //undefined occurs on initial view load so we're just
            //addresing that error
            let parseViewValue = function (value) {

                if (value != undefined) {
                    let viewValueToUpper = toUpper(value);
                    let viewValueToDash = dashmanager(viewValueToUpper);
                    let viewValueValidity = validityManager(viewValueToDash);

                    ngModel.$viewValue = viewValueValidity;
                    ngModel.$render();

                    return viewValueToDash;
                }
                
            }

            let formatModelValue = function (value) {
                if (value != undefined) {
                    let modelValueToUpper = toUpper(value);
                    let modelValueToDash = dashmanager(modelValueToUpper);
                    let viewValueValidity = validityManager(viewValueToDash);

                    ngModel.$modelValue = viewValueValidity;
                    return modelValueToDash;
                }
                
            }

            //must modify both values (ref): https://stackoverflow.com/questions/22841225/ngmodel-formatters-and-parsers
            ngModel.$parsers.push(parseViewValue);
            ngModel.$formatters.push(formatModelValue);
        }
    };
};

export default activationCodeFormatterDirective;