import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-bootstrap';

import uiModalCallbackComponent from './callback.component';
import uiModalEmailComponent from './email.component';

export default angular.module('fp:activationswizard:common:ui:modals',
    [
        'ui.bootstrap',
        'ngRedux'
    ])
    .component('awUiModalCallback', uiModalCallbackComponent)
    .component('awUiModalEmail', uiModalEmailComponent);