import * as _ from 'lodash';
import ensure from '../util/ensure';
import moment from 'moment';

export const SENSORS_GET_REQUEST = 'SENSORS_GET_REQUEST';
export const SENSORS_GET_FAILURE = 'SENSORS_GET_FAILURE';
export const SENSORS_GET_SUCCESS = 'SENSORS_GET_SUCCESS';

export const SENSOR_UPDATE_REQUEST = 'SENSOR_UPDATE_REQUEST';
export const SENSOR_UPDATE_FAILURE = 'SENSOR_UPDATE_FAILURE';
export const SENSOR_UPDATE_SUCCESS = 'SENSOR_UPDATE_SUCCESS';
export const SENSORS_GET_ASW_SUCCESS = 'SENSORS_GET_ASW_SUCCESS';

export const SENSOR_UPDATE_NAME_REQUEST = 'SENSOR_UPDATE_NAME_REQUEST';
export const SENSOR_UPDATE_NAME_FAILURE = 'SENSOR_UPDATE_NAME_FAILURE';
export const SENSOR_UPDATE_NAME_SUCCESS = 'SENSOR_UPDATE_NAME_SUCCESS';

export const SENSOR_POLL_REQUEST = 'SENSOR_POLL_STATUS_REQUEST';
export const SENSOR_POLL_FAILURE = 'SENSOR_POLL_STATUS_FAILURE';
export const SENSOR_POLL_SUCCESS = 'SENSOR_POLL_STATUS_SUCCESS';
export const SENSOR_POLL_COMPLETE = 'SENSOR_POLL_STATUS_COMPLETE';

export const START_ARM_DISARM_TESTING = 'START_ARM_DISARM_TESTING';
export const FINISH_ARM_DISARM_TESTING = 'FINISH_ARM_DISARM_TESTING';
export const ARM_DISARM_PANEL_STATUS_UPDATED = 'ARM_DISARM_PANEL_STATUS_UPDATED';

export const SENSOR_START_SETUP = 'SENSOR_START_SETUP';
export const SENSOR_STOP_SETUP = 'SENSOR_STOP_SETUP';
export const SENSOR_FINISH_SETUP = 'SENSOR_FINISH_SETUP';
export const SENSOR_EXCEPTION_DEVICE_START_SETUP = 'SENSOR_EXCEPTION_DEVICE_START_SETUP';
export const SENSOR_EXCEPTION_DEVICE_FINISH_SETUP = 'SENSOR_EXCEPTION_DEVICE_FINISH_SETUP';
export const SENSOR_SKIP_SETUP = 'SENSOR_SKIP_SETUP';

export const RESET_DEVICES = 'RESET_DEVICES';

export const SENSOR_INSTALLATION_INSTRUCTIONS = 'SENSOR_INSTALLATION_INSTRUCTIONS';

export const SENSOR_DL_VERIFICATION_SUCCESS = "SENSOR_DL_VERIFICATION_SUCCESS";
export const SENSOR_DL_VERIFICATION_FAILURE = "SENSOR_DL_VERIFICATION_FAILURE";

export const PANEL_REMOVE_SENSOR_REQUEST = 'PANEL_REMOVE_SENSOR_REQUEST';
export const PANEL_REMOVE_SENSOR_FAILURE = 'PANEL_REMOVE_SENSOR_FAILURE';
export const PANEL_REMOVE_SENSOR_SUCCESS = 'PANEL_REMOVE_SENSOR_SUCCESS';

class DeviceActions {
    /*@ngInject*/
    constructor($interval, $log, $ngRedux, $timeout, $q, constants, dataContext, productActions, SharedState, $rootScope, fpStore, appActions, orderActions) {
        this.$interval = $interval;
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
        this.$q = $q;
        this.constants = constants;
        this.dataContext = dataContext;
        this.productActions = productActions;
        this.SharedState = SharedState;
        this.$rootScope = $rootScope;
        this.fpStore = fpStore;
        this.appActions = appActions;
        this.orderActions = orderActions;
        this.unsubscribe = $ngRedux.connect(this._mapState)(this);
    }


    /**
     * Find a device based on the provided ID.
     * 
     * @param {number} deviceId A device ID.
     * @returns {Object} The device that matches the provided ID.
     */
    findById(deviceId) {
        ensure.isNumber('deviceId', deviceId);
        return _.find(this.devices, x => x.SensorID === deviceId);
    }

    /**
     * Gets an array of all sensors.
     * 
     * @returns {Array.<Object>} An array of all sensors.
     */
    getSensors() {
        if (this.dataContext.user.isInMobileApp() || this.appActions.isInitialWithAdditional()) {
            this._filterNotQueuedSensors();
        }

        return _.filter(this.devices, device => this.productActions.isSensor(device.ProductSku));
    }

    getSensorExceptionDevices() {
        return _.filter(this.devices, device => this.productActions.isExceptionDevice(device.ProductSku));
    }

    /*
     * Only grab devices that are queued (have been selected in airfx) for in additional orders
     */
    _filterNotQueuedSensors() {
        //grab all devices that are queued
        let listOfSensorsQueued = _.filter(this.sensors, (sensor) => {
            return sensor.isQueued === true;
        });
        
        //filter the products that are not queued
        let deviceCopy = _.cloneDeep(this.aswDevices);
        _.forEach(deviceCopy, (device) => {
            if (!_.isNil(device)) {
                const foundProduct = _.find(listOfSensorsQueued,
                    item => _.toUpper(item.DLCode) === _.toUpper(device.DLCode));
                if (_.isNil(foundProduct)) {
                    _.remove(this.aswDevices,
                        (s) => {
                            return device.DLCode === s.DLCode;
                        });
                }
            }
        });

        return this._dispatchFetchSensorsSuccess(this.aswDevices);
    }

    _getQueuedSensors() {
        return _.filter(this.sensors, (sensor) => {
            return sensor.isQueued === true;
        });
    }

    /**
     * Gets the provided device's name. If the provided device has not been named throughout the course of the
     * Activation Wizard the default name will be returned.
     * 
     * Default name format:
     * 'ProductName DeviceID'
     * 
     * @param {Object} device  
     * @returns {string} The device's name or its default name if not name has been set.
     */
    getName(device) {
        ensure.isNotNullOrUndefined('device', device);
        ensure.isObject('device', device);

        let deviceDescription = device.SlotNumber ? device.SlotNumber : device.GroupOrder ? device.GroupOrder : '';
        if (device.IsSmartLock)
            deviceDescription = `x ${device.Quantity}`;
        let customName =
            `${device.NameBase || ''} ${device.NameSuffix || ''} ${device.NameToken3 || ''
            } ${device.NameToken4 || ''}`;

        let defaultName = device.isNamed
            ? device.Name
            : _.includes(this.constants.sensorListExceptionSkus, device.ProductSku)
                ? `${device.ProductName}`
                : `${device.ProductName} ${deviceDescription}`;

        if (_.isNil(customName) || _.isEmpty(customName.trim())) {
            return defaultName;
        }
        return customName;
    }

    /**
     * Starts the setup for the given sensor
     * by marking it as the selected sensor 
     * in the application state.
     * 
     * @param {number} deviceId A device Id.
     */
    startSetup(deviceId) {
        ensure.isNumber('deviceId', deviceId);

        this._dispatch({
            type: SENSOR_START_SETUP,
            payload: {
                data: deviceId
            }
        });
    }

    /**
     * Stops setup for the selected sensor
     * by marking the selected sensor as null
     * in the application state.
     */
    stopSetup() {
        this._dispatch({
            type: SENSOR_STOP_SETUP
        });
    }

    /**
     * Marks the selected sensor as complete
     * in the application state.
     */
    finishSetup() {
        if (this.isInitialOrder) {
            const product = _.find(this.sensors, s => {
                return s.DLCode && s.DLCode.toUpperCase() === this.deviceSelected.DLCode.toUpperCase();
            });

            this.orderActions.setOrderProductActivated(product);
        }

        this.$ngRedux.dispatch({
            type: SENSOR_FINISH_SETUP,
            metadata: {
                description: `Sensor testing complete for [Sensor Id: ${this.deviceSelected.SensorID}].`,
                sectionType: this.constants.sectionTypes.sensor,
                persist: true
            }
        });
    }


    /**
     * Starts the setup for the given exception device
     * by marking it as the selected sensor 
     * in the application state.
     * 
     * @param {number} deviceId A device Id.
     */
    startExceptionDeviceSetup(deviceId) {
        ensure.isNumber('deviceId', deviceId);

        this._dispatch({
            type: SENSOR_EXCEPTION_DEVICE_START_SETUP,
            payload: {
                data: deviceId
            }
        });
    }

    /**
     * Marks the selected exception device in sensor list as complete
     * in the application state.
     */
    finishExceptionDeviceSetup(device) {
        this.$ngRedux.dispatch({
            type: SENSOR_EXCEPTION_DEVICE_FINISH_SETUP,
            payload: {
                data: device
            }
        });
    }

    /**
     * Marks the selected sensor as skipped in the application state.
     */
    skipSetup() {
        this.$ngRedux.dispatch({
            type: SENSOR_SKIP_SETUP,
            metadata: {
                description: `Sensor testing skipped. [Sensor Id: ${this.deviceSelected.SensorID}].`,
                sectionType: this.constants.sectionTypes.sensor,
                persist: true
            }
        });
    }

    /**
     * Fetches a list of sensors attached to the current user's panel and adds them to the application state.
     * 
     * @returns {promise} A promise that returns undefined if resolved and an error if rejected.
     */
    fetchSensors() {
        // Show devices that are not connected to the customer's panel? 
        //   * Initial Order: Yes, show an error message for not connected devices. 
        //   * Additional Order: Yes, added not connected devices to customer's panel.

        const includeNotConnected = !this.isInitialOrder;
        // Show devices that are not a sensor?
        //   * Initial Order: No
        //   * Additional Order: Yes
        const includeNonSensors = !this.isInitialOrder;

        this._dispatchFetchSensors();
        this.$log.debug('Fetching the customer\'s sensors...');

        return this.dataContext.system.sensor.getSensors(this.isInitialOrder, includeNonSensors, includeNotConnected)
            .then((result) => {
                //need to set set flag for isSensor
                _.each(result, (sensor) => {
                    sensor.IsSensor = this.productActions.isSensor(sensor.ProductSku);
                });

                this._dispatchFetchSensorsSuccess(result);
                this._dispatchFetchSensorsForASWSuccess(result);
                this.$log.debug('Successfully fetched the customer\'s sensors.');
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error fetching the customer\'s sensors.', error);
                this._dispatchFetchSensorsFailure(error);
                return this.$q.reject('fetchSensors');
            });
    }

    /**
    * Fetches a list of sensors attached to the current user's panel and adss them to the application state.
    * 
    * @returns {promise} A promise that returns undefined if resolved and an error if rejected.
    */
    fetchSensorsForInitial() {
        // Show devices that are not connected to the customer's panel? 
        //   * Initial Order: Yes, show an error message for not connected devices. 
        //   * Additional Order: Yes, added not connected devices to customer's panel.
        const includeNotConnected = this.isInitialOrder;

        this._dispatchFetchSensors();
        this.$log.debug('Fetching the customer\'s sensors...');

        return this.dataContext.system.sensor.getSensors(includeNotConnected, true, !this.dataContext.user.isInMobileApp())
            .then((result) => {
                //need to set set flag for isSensor
                _.each(result, (sensor) => {
                    sensor.IsSensor = this.productActions.isSensor(sensor.ProductSku);
                });
                this._dispatchFetchSensorsSuccess(result);
                this.$log.debug('Successfully fetched the customer\'s sensors.');
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error fetching the customer\'s sensors.', error);
                this._dispatchFetchSensorsFailure(error);
                return this.$q.reject(error);
            });
    }

    fetchSensorsForInitialWithAdditional() {
        this._dispatchFetchSensors();
        this.$log.debug('Fetching the customer\'s sensors...');

        return this.dataContext.system.sensor.getSensors(false, true, false)
            .then((result) => {
                //need to set set flag for isSensor
                _.each(result, (sensor) => {
                    sensor.IsSensor = this.productActions.isSensor(sensor.ProductSku);
                });
                this._dispatchFetchSensorsSuccess(result);
                this.$log.debug('Successfully fetched the customer\'s sensors.');
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error fetching the customer\'s sensors.', error);
                this._dispatchFetchSensorsFailure(error);
                return this.$q.reject(error);
            });
    }

    /**
     * Update the provided sensor's name in the data store.
     * 
     * @param {Object} device A sensor to update.
     * @returns {promise} A promise that returns undefined if resolved and an error if rejected. 
     */
    updateSensorName(device) {
        ensure.isObject('device', device);
        this._dispatchUpdateSensorNameRequest(device);
        const name = this.getName(device);
        // Make sure we are only updating the device's name.
        const deviceClone = _.merge({}, this.findById(device.SensorID), {
            Name: name,
            NameBase: device.NameBase,
            NamePrefix: device.NamePrefix,
            NameSuffix: device.NameSuffix,
            NameToken3: device.NameToken3,
            NameToken4: device.NameToken4
        });

        return this.dataContext.system.sensor.updateSensor(deviceClone)
            .then((res) => {
                this._dispatchUpdateSensorNameSuccess(res);
                return this.$q.resolve();
            })
            .catch((err) => {
                this.$log.error(`Error updating sensor [${deviceClone.SensorID}] name from [${deviceClone.Name}] to NameBase: [${deviceClone.NameBase}] NameSuffix: [${deviceClone.NameSuffix}] NameToken3: [${deviceClone.NameToken3}] NameToken4: [${deviceClone.NameToken4}].`, err);
                this._dispatchUpdateSensorNameFailure(deviceClone, err);
                return this.$q.reject(err);
            });
    }

    /**
     * Update the given sensor.
     * 
     * @param {sensor} sensor A sensor to update.
     * @returns {promise}  A promise that returns undefined if resolved and an error if rejected.
     */
    updateSensor(sensor) {
        ensure.isObject('sensor', sensor);
        this._onUpdateSensor(sensor.SensorID);

        return this.dataContext.system.sensor.updateSensor(sensor)
            .then((result) => {
                this._onUpdateSensorSuccess(result, /*persist*/ false);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error(`Error updating sensor ${sensor.SensorID}.`, error);
                this._onUpdateSensorFailure(sensor.SensorID);
                return this.$q.reject(error);
            });
    }

    /**
     * Disable the provided device.
     * 
     * @param {Object} sensor A sensor object.
     * @returns {promise} A promise that returns undefined if resolved and an error if rejected. 
     */
    disableSensor(sensor) {
        ensure.isObject('sensor', sensor);
        return this.deleteSensor(sensor);
    }

    /**
     * Remove the given sensor from the customer's panel.
     * 
     * @param {Object} sensor 
     * @returns {promise} A promise that return undefined if resolved and an error if rejected.
     */
    deleteSensor(sensor) {
        ensure.isObject('sensor', sensor);

        this._dispatchRemoveSensorRequest(sensor);
        this.$log.debug('Removing sensor from customer\'s panel...');
        return this.dataContext.panel.deleteSensor(sensor)
            .then(() => {
                this._dispatchRemoveSensorSuccess(sensor);
                this.$log.warn('Successfully removed sensor from the customer\'s panel.');
                return this.$q.resolve();
            }).catch((error) => {
                this._dispatchRemoveSensorFailure(error);
                this.$log.error('Error removing sensor from the customer\'s panel.', error);
                return this.$q.reject(error);
            });
    }

    /**
     * Simulate polling a sensor for its status.
     * 
     * @param {sensor} sensor A sensor to poll.
     * @returns {promise} A promise that returns undefined if resolved.
     * The promise will never be rejected.
     */
    simulatePoll(sensor) {
        ensure.isObject('sensor', sensor);
        const maxInterval = this.constants.polling.panel.pollingInterval;

        this._onPollSensorStatus(sensor.SensorID);

        this.$log.info(`Starting sensor [${sensor.SensorID}] polling. [SIMULATED]`);
        let initialTimeout = this.$timeout(_.noop, maxInterval);
        let chainedTimeout = initialTimeout.catch((err) => {
            this.$log.info(`Error during sensor [${sensor.SensorID}] polling.`, err);
            this._onPollSensorStatusFailure(err);
            return this.$q.reject(err);
        }).then((res) => {
            this.$log.info(`Successfully completed sensor [${sensor.SensorID}] polling. [SIMULATED]`);
            this._onPollSensorStatusSuccess();
            return this.$q.resolve(res);
        }).finally(() => {
            this._onPollSensorStatusComplete();
        });

        chainedTimeout._isPoll = true;
        chainedTimeout._isSimulated = true;
        chainedTimeout.$$timeoutId = initialTimeout.$$timeoutId;
        return chainedTimeout;
    }

    /**
     * Poll a sensor for its status. This method will continue to poll the sensor
     * until the desired status is returned or the max number of poll attempts is reached.
     * 
     * @param {Object} sensor A sensor to poll.
     * @param {string=} desiredStatus A status that should be returned from the sensor. 
     * @param {number=} maxInterval Max number of milliseconds between each poll attempt.
     * @param {number=} maxAttempts Max number of poll attempts.
     * @returns {promise} A promise that is notified of each poll attempt and returns undefined if 
     * resolved or 'canceled', 'timeout', or error if rejected.
     */
    startPoll(sensor, desiredStatus, maxInterval = 24, maxAttempts = 5000) {
        ensure.isObject('sensor', sensor);

        this._onPollSensorStatus(sensor.SensorID);

        this.$log.info(`Starting sensor [${sensor.SensorID}] polling; desired status [${desiredStatus}]. [REAL]`);
        let isSuccess = false;
        const initialInterval = this.$interval(() => {
            this.$rootScope.$emit(this.constants.events.callSupportNotification.name);
            return this.dataContext.system.sensor.getSensorStatus(sensor.SensorID).then((result) => {
                // no response
                if (_.isNil(result)) {
                    return;
                }

                const newDate = moment(result.StatusDate);
                const newStatus = result.StatusType;
                const state = this.$ngRedux.getState();
                const updatedSensor = state.system.devices.sensorsByID[sensor.SensorID];
                const clonedSensor = _.cloneDeep(updatedSensor);
                clonedSensor.StatusType = newStatus;
                clonedSensor.StatusDate = newDate;

                if (!_.isNil(desiredStatus) && desiredStatus !== '') {
                    if (newStatus === desiredStatus) {
                        this._onUpdateSensorSuccess(clonedSensor);

                        isSuccess = true;
                        this.$interval.cancel(initialInterval);
                        return;
                    }

                    return;
                }

                const oldDate = moment().add(-1, 'h');
                if (oldDate.isBefore(newDate)) {
                    this._onUpdateSensorSuccess(clonedSensor);

                    isSuccess = true;
                    this.$interval.cancel(initialInterval);
                }
            });
        }, maxInterval, maxAttempts);

        const chainedInterval = initialInterval.then(null, null, (tick) => {
            const state = this.$ngRedux.getState();
            const updatedSensor = state.system.devices.sensorsByID[sensor.SensorID];
            this.$log.debug(`Sensor [${updatedSensor.SensorID}] polling attempt [${tick + 1}] of [${maxAttempts}]; current status [${updatedSensor.StatusType}].`);
        }).then((res) => {
            return res === maxAttempts
                ? this.$q.reject('timeout')
                : this.$q.resolve(res);
        }).catch((err) => {
            if ((err === 'canceled' || err === 'timeout') && isSuccess) {
                return this.$q.resolve();
            }
            this.$log.error(`Error during sensor [${sensor.SensorID}] polling.`, err);
            this._onPollSensorStatusFailure(err);
            return this.$q.reject(err);
        }).then((res) => {
            this.$log.info(`Successfully completed sensor [${sensor.SensorID}] polling. [REAL]`);
            this._onPollSensorStatusSuccess();
            return this.$q.resolve(res);
        }).finally(() => {
            this._onPollSensorStatusComplete();
        });

        chainedInterval._isPoll = true;
        chainedInterval.$$intervalId = initialInterval.$$intervalId;
        return chainedInterval;
    }

    /**
     * Cancel polling for a given sensor.
     * 
     * @param {promise} promise A promise to cancel.
     * @returns {boolean} Returns true if the poll was successfully canceled.
     */
    cancelPoll(promise) {
        if (promise && promise._isPoll) {
            if (promise._isSimulated) {
                return this.$timeout.cancel(promise);
            } else {
                return this.$interval.cancel(promise);
            }
        }

        return false;
    }

    /**
     * Returns true if the given sensor has had its setup complete.
     * 
     * @param {Object} sensor 
     * @returns {boolean} True if the given sensor has had its setup complete, false otherwise.
     */
    isSensorComplete(sensor) {
        ensure.isObject('sensor', sensor);
        return sensor.isComplete;
    }

    /**
     * Returns true if a given sensor is reporting a tampered status.
     * 
     * @param {Object} sensor 
     * @returns {boolean} True if the given sensor is reporting a tampered status, false otherwise.
     */
    isSensorTampered(sensor) {
        ensure.isObject('sensor', sensor);
        return sensor.StatusType === this.constants.deviceStatus.tampered.Name;
    }

    /**
     * Returns true if a given sensor is reporting a malfunction.
     * 
     * @param {Object} sensor 
     * @returns {boolean} True if the given sensor is reporting a malfunction; otherwise, false.
     */
    isSensorMalfunctioning(sensor) {
        ensure.isObject('sensor', sensor);
        return sensor.StatusType === this.constants.deviceStatus.malfunction.Name;
    }

    /**
     * Returns true if a given sensor is marked as skipped.
     * 
     * @param {Object} sensor A sensor object.
     * @returns {boolean} True if the given sensor is makred as skipped; otherwise, false.
     */
    isSensorSkipped(sensor) {
        ensure.isObject('sensor', sensor);
        return sensor.isSkipped;
    }

    /**
     * Returns a flag indicating if all sensors have completed setup.
     * 
     * @returns {boolean} Returns true if all sensors have been setup. 
     */
    isSensorSetupComplete() {
        let sensors = this.getSensors();

        // Check for exception device and if present, add to the sensor list
        let exceptionDevices = this.getSensorExceptionDevices();
        if (!_.isNil(exceptionDevices) && !_.isEmpty(exceptionDevices)) {
            _.forEach(exceptionDevices,
                (item) => {
                    // check to add exception device only once
                    let exists = _.some(sensors, s => { return s.ProductSku === item.ProductSku; });
                    if (!exists) {
                        sensors.push(item);
                    };
                });
        }

        if (this.fpStore.has(this.constants.storage.numberOfSmartLocksToActivate, 'session') &&
            parseInt(this.fpStore.get(this.constants.storage.numberOfSmartLocksToActivate, 'session')) > 0) {
            sensors.push({ ProductName: 'Locks', IsSmartLock: true });
        }

        let isInitial = this.isInitialOrder && !this.appActions.isInitialWithAdditional();

        if (!isInitial && this.sku != '') {
            sensors = _.filter(sensors, (sensor) => { return sensor.ProductSku === this.sku; });
        }

        for (let i = 0; i < sensors.length; i++) {
            const sensor = sensors[i];
            if (sensor.isComplete || sensor.isSkipped) {
                continue;
            }
            return false;
        }
        return true;
    }

    /**
     * Returns a flag indicating if any sensor is marked as skipped; otherwise, false.
     * 
     * @returns {boolean} Returns true if any sensor has been marked as skipped. 
     */
    hasSkippedSensors() {
        return _.some(this.getSensors(), sensor => sensor.isSkipped);
    }

    /**
     * Returns true if the given sensor is connected to the customer's panel.
     * 
     * @param {Object} sensor 
     * @returns {boolean} True if the given sensor is connected to the customer's panel; otherwise, false 
     */
    isSensorConnected(sensor) {
        ensure.isObject('sensor', sensor);
        return sensor.IsConnected === true;
    }

    showInstallationModal(sensor) {
        ensure.isObject('sensor', sensor);
        this._onSensorInstallationInstructions(sensor);
        this.SharedState.turnOn('installationModal');
    }

    /**
     * Returns a flag indicating if an overview page should be shown for the provided sensor.
     * 
     * @param {Object} sensor A sensor object. 
     * @returns {boolean} Returns true if an overview page should be shown for the provided sensor; otherwise, false
     */
    isOverviewRequired(sensor) {
        if (this.isInitialOrder && !this.appActions.isInitialWithAdditional()) {
            ensure.isObject('sensor', sensor);
            const sensors = _.sortBy(this.getSensors(), ['SensorID']);

            let isOverviewRequired = true;
            for (let i = 0; i < sensors.length; i++) {
                if (sensors[i].SensorID === sensor.SensorID) {
                    break;
                }
                if (sensor.ProductSku === sensors[i].ProductSku) {
                    isOverviewRequired = false;
                    break;
                }
            }
            return isOverviewRequired;
        } else {
            return false;
        }
    }

    /**
     * Returns a list of sensors ordered by sensor id or by DatePicked
     * 
     * @returns {Array.<Object>} An array of all sensors ordered.
     */
    getOrderedSensors() {
        if (this.isInitialOrder && !this.appActions.isInitialWithAdditional()) {
            let devices = _.sortBy(this.devices, ['SetupOrder', 'SlotNumber', 'GroupOrder']);
            //set sensor order of reordered sensors
            let index = 1;
            _.forEach(devices,
                (device) => {
                    device.sensorOrder = index;

                    index++;
                });
            return devices;
        } else {
            return this.devices;
        }
    }

    isSmartLock(device) {
        return _.includes(_.map(this.constants.zWaveProducts.locks), device.ProductSku);
    }

    isLight(device) {
        return _.includes(_.map(this.constants.zWaveProducts.lights), device.ProductSku);
    }
    /**
     *Returns true if there are unactivated devices in the category
     *
     * @param {category} product category type to check
     * @returns {boolean} 
     */
    hasDeviceFromCategory(category) {
        let hasMatchingProductGroups = false;
        const productGroup = _.filter(this.constants.productGroups, { type: category });
        if (!_.isNil(productGroup)) {
            const matchingProductGroups = _.intersectionWith(productGroup, this.sensors, (productGroup, sensor) => {
                return productGroup.id === sensor.ProductGroupID;
            });
            hasMatchingProductGroups = !_.isNil(matchingProductGroups) && matchingProductGroups.length > 0;
        }
        return hasMatchingProductGroups;
    }

    getDevicesFromCategory(category, includeExceptionDevices = false) {
        let deviceList = [];
        const productGroup = _.filter(this.constants.productGroups, { type: category });
        if (!_.isNil(productGroup)) {
            _.forEach(this.sensors, (sensor) => {
                if (_.some(productGroup, { 'id': sensor.ProductGroupID }) || (includeExceptionDevices && _.some(this.getSensorExceptionDevices(), { 'ProductSku': sensor.ProductSku }))) {

                    const isActivated = _.get(sensor, 'isActivated', false);
                    if (!isActivated) {
                        // Locate corresponding device from ADC to see if it already has a name
                        const device = _.find(this.devices, (d) => { return d.DLCode === sensor.DLCode; });

                        if (!_.isNil(device) && !_.isNil(device.Name)) {
                            sensor.WebName = device.Name;
                        }

                        deviceList.push(sensor);
                    }
                }
            });
        }
        return _.sortBy(deviceList, ['SetupOrder', 'SlotNumber', 'GroupOrder']);
    }

    /**
     *Returns the status of the panel - Disarmed(5), ArmedStay(6), ArmedAway(7)
     *
     * @returns {promise} A promise that returns panel status(number) if resolved.
     */
    getPanelStatus() {
        return this.dataContext.system.sensor.getSystemControlDevices().then((deviceResponse) => {
            if (!_.isNil(deviceResponse) && deviceResponse.Success && !_.isEmpty(deviceResponse.Devices)) {
                // find the panel from the list of devices and return it's current status
                let panelDevice = _.find(deviceResponse.Devices, x => x.DeviceId === this.constants.panelDeviceId);
                return this.$q.resolve(panelDevice.Status);
            }

            return this.$q.reject();
        })
            .catch((err) => {
                return this.$q.reject(err);
            });
    }

    /**
     * Starts the Arm-Disarm Test for an account to train customers
     * to arm-disarm panel
     *
     */
    startArmDisarmTesting() {
        this._dispatch({
            type: START_ARM_DISARM_TESTING,
            metadata: {
                sectionType: this.constants.sectionTypes.training,
                persist: true,
                description: `Arm-Disarm Testing started.`
            }
        });
    }

    /**
     * Complete the Arm-Disarm Test training for an account 
     *
     */
    completeArmDisarmTesting() {
        this._dispatch({
            type: FINISH_ARM_DISARM_TESTING,
            metadata: {
                sectionType: this.constants.sectionTypes.training,
                persist: true,
                description: `Arm-Disarm Testing completed.`
            }
        });
    }

    /**
     * Updates the status of the panel when testing arm-disarm panel 
     *
     */
    updateArmDisarmPanelStatus(status) {
        this._dispatch({
            type: ARM_DISARM_PANEL_STATUS_UPDATED,
            metadata: {
                sectionType: this.constants.sectionTypes.training,
                persist: true,
                description: `${status}`
            }
        });
    }

    verifySensorByDLCode(devices, dlCode) {
        if (!_.isNil(dlCode)) {
            var dlCodeExists = _.some(devices,
                (device) => !_.isNil(device.DlCode) && device.DlCode.toUpperCase() === dlCode.toUpperCase());

            if (dlCodeExists) {
                this.onVerifySensorSuccess(`Sensor with DL code ${dlCode} found.`);
                return this.$q.resolve();
            }

            this.onVerifySensorFailure(`Sensor with DL code ${dlCode} not found.`);
            return this.$q.reject();
        }

        this.onVerifySensorFailure('Tried to verify against null DL Code.');
        return this.$q.reject();
    }

    onVerifySensorSuccess(message) {
        this._dispatch({
            type: SENSOR_DL_VERIFICATION_SUCCESS,
            payload: {
                message
            },
            metadata: {
                persist: false,
                sectionType: this.constants.sectionTypes.sensor,
                description: message,
                actionType: 'Info'
            }
        });
    }

    onVerifySensorFailure(error) {
        this._dispatch({
            type: SENSOR_DL_VERIFICATION_FAILURE,
            payload: {
                error
            },
            metadata: {
                persist: false,
                sectionType: this.constants.sectionTypes.sensor,
                description: error,
                actionType: 'Error'
            }
        });
    }

    _mapState(state) {
        return {
            sensors: _.map(state.account.order.itemsByID),
            devices: state.system.devices.sensorsByID,
            aswDevices: state.system.devices.aswSensors,
            deviceSelected: state.system.devices.sensorsByID[state.system.devices.selectedSensorID],
            isInitialOrder: state.account.user.isInitialOrder === true,
            sku: state.account.airfxFlow.sku
        };
    }

    _dispatch(obj) {
        this.$ngRedux.dispatch(obj);
    }

    _dispatchRemoveSensorRequest(sensor) {
        this.$ngRedux.dispatch({
            type: PANEL_REMOVE_SENSOR_REQUEST,
            payload: {
                sensor: sensor
            }
        });
    }

    _dispatchRemoveSensorSuccess(sensor) {
        this.$ngRedux.dispatch({
            type: PANEL_REMOVE_SENSOR_SUCCESS,
            payload: {
                sensor: sensor
            }
        });
    }

    _dispatchRemoveSensorFailure(error) {
        this.$ngRedux.dispatch({
            type: PANEL_REMOVE_SENSOR_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _dispatchFetchSensors() {
        this._dispatch({
            type: SENSORS_GET_REQUEST,
            metadata: {
                persist: false
            }
        });
    }

    _dispatchFetchSensorsFailure(error) {
        this._dispatch({
            type: SENSORS_GET_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _dispatchFetchSensorsSuccess(data) {
        this._dispatch({
            type: SENSORS_GET_SUCCESS,
            payload: {
                data: data
            }
        });
    }

    _dispatchFetchSensorsForASWSuccess(data) {
        this._dispatch({
            type: SENSORS_GET_ASW_SUCCESS,
            payload: {
                data: data
            }
        });
    }

    _onUpdateSensor() {
        this._dispatch({
            type: SENSOR_UPDATE_REQUEST
        });
    }

    _onUpdateSensorFailure(error) {
        this._dispatch({
            type: SENSOR_UPDATE_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onUpdateSensorSuccess(data, persist = false) {
        this._dispatch({
            type: SENSOR_UPDATE_SUCCESS,
            payload: { data },
            metadata: {
                description: `Sensor [${data.SensorID}] updated.`,
                sectionType: this.constants.sectionTypes.sensor,
                persist
            }
        });
    }

    _onPollSensorStatus() {
        this._dispatch({
            type: SENSOR_POLL_REQUEST
        });
    }

    _onPollSensorStatusFailure(error) {
        this._dispatch({
            type: SENSOR_POLL_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onPollSensorStatusSuccess() {
        this._dispatch({
            type: SENSOR_POLL_SUCCESS
        });
    }

    _onPollSensorStatusComplete() {
        this._dispatch({
            type: SENSOR_POLL_COMPLETE
        });
    }

    _onSensorInstallationInstructions(sensor) {
        this.$ngRedux.dispatch({
            type: SENSOR_INSTALLATION_INSTRUCTIONS,
            payload: {
                sensor
            },
            metadata: {
                description: `Sensor installation instructions [Sensor Id: ${sensor.SensorID}] [SKU ${sensor.ProductSku}].`,
                sectionType: this.constants.sectionTypes.sensor,
                persist: true
            }
        });
    }

    _dispatchUpdateSensorNameRequest(sensor) {
        this.$ngRedux.dispatch({
            type: SENSOR_UPDATE_NAME_REQUEST,
            payload: {
                sensor
            }
        });
    }

    _dispatchUpdateSensorNameFailure(sensor, error) {
        this.$ngRedux.dispatch({
            type: SENSOR_UPDATE_NAME_FAILURE,
            payload: {
                sensor,
                error
            }
        });
    }

    _dispatchUpdateSensorNameSuccess(sensor) {
        this.$ngRedux.dispatch({
            type: SENSOR_UPDATE_NAME_SUCCESS,
            payload: {
                sensor
            },
            metadata: {
                description: `Sensor [${sensor.SensorID}] name updated from [${sensor.Name}] to NameBase: [${sensor.NameBase}] NameSuffix: [${sensor.NameSuffix}] NameToken3: [${sensor.NameToken3}] NameToken4: [${sensor.NameToken4}].`,
                sectionType: this.constants.sectionTypes.sensor,
                persist: false
            }
        });
    }

    resetSkippedDevices() {
        return this.$ngRedux.dispatch({
            type: RESET_DEVICES
        });
    }
}

export default DeviceActions;