import * as _ from 'lodash';
import error from '../../util/error';
import moment from 'moment';

import { PASSWORD_UPDATE_REQUEST, PASSWORD_UPDATE_FAILURE, PASSWORD_UPDATE_SUCCESS } from '../../action/password.actions';

const initialState = {
    ignore: ['lastUpdatedDate', 'isUpdating', 'hasError', 'message'],
    value: null,
    lastUpdatedDate: null,
    isUpdating: false,
    hasError: false,
    message: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case PASSWORD_UPDATE_REQUEST:
        return _.mergeCustom({}, state, {
            isUpdating: true,
            hasError: false,
            message: null
        });
    case PASSWORD_UPDATE_FAILURE:
        return _.mergeCustom({}, state, {
            isUpdating: false,
            hasError: true,
            message: error.parse(action.payload.error)
        });
    case PASSWORD_UPDATE_SUCCESS:
        return _.mergeCustom({}, state, {
            value: action.payload.data,
            lastUpdatedDate: moment.utc(),
            isUpdating: false
        });
    default:
        return state;
    }
};