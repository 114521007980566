export default class EmailDataService {

    constructor(private Restangular: any) {
        'ngInject';
    }

    public async sendEmail(field: string, targetSku: string = null ) {
        return this.Restangular
            .one('email')
            .customPOST({
                "FieldName": field,
                "TargetPanel": targetSku
            });
    }

    public async isEmailLive(): Promise<boolean> {
        return this.Restangular
            .one('email')
            .one('is-live')
            .withHttpConfig({ cache: false })
            .get();
    }
}