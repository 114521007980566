import * as _ from 'lodash';
import error from '../../util/error';
import moment from 'moment';

import { TEST_MODE_FETCH_REQUEST, TEST_MODE_FETCH_FAILURE, TEST_MODE_FETCH_SUCCESS} from '../../action/testmode.actions';
import { TEST_MODE_UPDATE_RQUEST, TEST_MODE_UPDATE_FAILURE, TEST_MODE_UPDATE_SUCCESS} from '../../action/testmode.actions';

const initialState = {
    ignore: [],
    value: null,
    lastUpdatedDate: null,
    isFetching: false,
    isUpdating: false,
    hasError: false,
    message: null,
    onTest: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case TEST_MODE_FETCH_REQUEST:
        return _.mergeCustom({}, state, {
            isFetching: true,
            hasError: false,
            message: null
        });
    case TEST_MODE_FETCH_FAILURE:
        return _.mergeCustom({}, state, {
            isFetching: false,
            hasError: true,
            message: error.parse(action.payload.error)
        });
    case TEST_MODE_FETCH_SUCCESS:
        return _.mergeCustom({}, state, {
            value: action.payload.data,
            onTest: action.payload.onTest,
            lastUpdatedDate: moment.utc(),
            isFetching: false
        });
    case TEST_MODE_UPDATE_RQUEST:
        return _.mergeCustom({}, state, {
            isUpdating: true,
            hasError: false,
            message: null
        });
    case TEST_MODE_UPDATE_FAILURE:
        return _.mergeCustom({}, state, {
            isUpdating: false,
            hasError: true,
            message: error.parse(action.payload.error)
        });
    case TEST_MODE_UPDATE_SUCCESS:
        return _.mergeCustom({}, state, {
            value: action.payload.data,
            lastUpdatedDate: moment.utc(),
            isUpdating: false,
            onTest:  action.payload.onTest
        });
    default:
        return state;
    }
};