import * as angular from 'angular';
import ngRedux from 'ng-redux';

import modalModule from '../modals';
import manualBarcodeComponent from './manual.component';
import barcodeListComponent from './list.component';
import barcodeRescanComponent from './rescan.component';
import scanBarcodeComponent from './scanBarcode.component';
import barcodeService from './barcode.service';
import commonModule from '../../common';

export default angular
    .module('fp:activationswizard:airfx:barcode', [
        ngRedux,
        modalModule.name,
        commonModule.name
    ]).config([
        '$compileProvider',
        ($compileProvider) => {
            $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|android-app|tel):/);
        }
    ])
    .component('awBarcodeManual', manualBarcodeComponent)
    .component('awBarcodeList', barcodeListComponent)
    .component('awBarcodeRescan', barcodeRescanComponent)
    .component('awScanBarcode', scanBarcodeComponent)
    .service('barcodeService', barcodeService);