import template from './deleteUserCodeModal.html';

class DeleteUserCodeModalController {
    /*@ngInject*/
    constructor(SharedState, $ngRedux, $state, actionContext, segment, constants) {
        this.SharedState = SharedState;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.actionContext = actionContext;
        this.isLoading = false;
        this.showSuccessComponent = false;
        this.segment = segment;
        this.constants = constants;
    }

    onCancel() {
        this.SharedState.turnOff('deleteUserCodeModal');
    }

    onDeleteUserCode() {
        this.segment.registerEvent(this.constants.events.segment.panelSwapUserCodeDeleted, {});
        this.deleteCode.Code = null;
        this.isLoading = true;
        this.actionContext.code.editUserCodes([this.deleteCode])
            .then(() => {
                this.showSuccessComponent = true;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    onContinue() {
        this.SharedState.turnOff('deleteUserCodeModal');
        this.showSuccessComponent = false;
    }
}

export default {
    template: template,
    bindings: {
        deleteCode: '<'
    },
    controller: DeleteUserCodeModalController
};