import * as _ from 'lodash';

import template from './initialize.html';

class InitializeController {
    /*@ngInject*/
    constructor ($interval, $log, $ngRedux, $timeout, $q, actionContext, constants, dataContext, $state) {
        this.$interval = $interval;
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
        this.$q = $q;
        this.actionContext = actionContext;
        this.constants = constants;
        this.dataContext = dataContext;
        this.$state = $state;

        this.isComplete = false;
        this.hasError = false;
        this.numRetries = 0;

        this.sensorsToCheckStatus = [];
        this.sensorsToCheckDLCode = [];
    }

    $onInit() {
        this.retryCount = 0;
        this._startProcess(false);
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
    }

    _startProcess(isRetry) {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        let promise = this.$q.resolve();

        //filter out panic pendant, keychain remote and image sensor
        this.devices = _.filter(this.devices, (device) => { return device.DeviceType !== 'ImageSensor'; });
        this.devices = _.filter(this.devices, (device) => { return device.DeviceType !== 'PanicButton'; });

        if (this.devices.length === 0)
            this.$state.go(this.constants.routerStates.airfxComplete);
        else {
            _.forEach(this.devices,
                (device) => {
                    promise = promise
                        .then(() => {
                            this._setCurrentDeviceId(device.SensorID);
                            this._resetCurrentCount();
                        })
                        .then(() => {
                            //update sensor on retry
                            if (isRetry) {
                                this.dataContext.system.sensor.updateSensor(device)
                                    .then(() => {
                                        return this.$q.resolve();
                                    });
                            } else {
                                return this.$q.resolve();
                            }
                        })
                        .then(() => {
                            const isSensor = this.actionContext.product.isSensor(device.ProductSku);
                            const hasTest = this.actionContext.product.hasTest(device.ProductSku);

                            if (isSensor) {
                                if (hasTest) {
                                    this.sensorsToCheckStatus.push(device);
                                } else {
                                    this.sensorsToCheckDLCode.push(device);
                                }
                            }
                        });
                });

            promise
                .then(() => {
                    let dlCodes = this.sensorsToCheckDLCode.map(sensor => sensor.DLCode);

                    return this.actionContext.panel.getDevices(true, dlCodes);
                })
                .then(() => {
                    let promises = [];

                    _.each(this.sensorsToCheckStatus,
                        (sensor) => {
                            var promise = this.dataContext.system.sensor.getSensorStatus(sensor.SensorID);
                            promises.push(promise);
                        });

                    return this.$q.all(promises);
                })
                .then(() => {
                    this.isComplete = true;
                })
                .catch((error) => {
                    this.hasError = true;
                    this.$log.error('Unable to get devices.', error);
                });
        }
    }

    $onDestroy() {
        this.unsubscribe();
    }

    /**
     * Returns true if the given device has been connected to the customer's panel; otherwise, false.
     * 
     * @param {Object} device 
     * @returns {boolean} 
     */
    isItemConnected(device) {
        if (this.isComplete) {
            return true;
        }

        if (_.isNil(this._getCurrentDeviceId()))
            return false;

        for (let i = 0; i < this.devices.length; i++) {
            const x = this.devices[i];

            if (x.SensorID === this._getCurrentDeviceId()) {
                return false;
            } 

            if (x.SensorID === device.SensorID) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns true if the given device is connecting to the customer's panel; otherwise, false.
     * 
     * @param {Object} device 
     * @returns {boolean} 
     */
    isItemConnecting(device) {
        if (this.isComplete || this.hasError) {
            return false;
        }

        return device.SensorID === this._getCurrentDeviceId();
    }

    /**
     * Handles a list item click event.
     * 
     * @param {} device
     */
    onItemClicked(device) {
        
    }

    _mapState(state) {
        return {
            // We are making the assumption that the only devices in the device list at this point in the application
            // are the devices that were just added to the customer's panel.
            devices: _.map(this._getDevices(state))
        };
    }

    _getDevices(state) {
        //do not pull sensors that have been completed, if they have already tried this additonal order once before
        let notCompletedSensors = _.filter(state.system.devices.sensorsByID, (sensor) => {
            return _.get(sensor, 'isComplete', false) === false;
        });

        //gets the sensors that have been selected in the airfx list (queued)
        let queuedSensors = _.filter(state.account.order.itemsByID, (sensor) => {
            return _.get(sensor, 'isQueued', false) === true && _.get(sensor, 'IsConnected', true) === false;
        });

        _.forEach(queuedSensors, (sensor) => {
            if (!_.isNil(sensor.DLCode))
                sensor.DLCode = sensor.DLCode.toUpperCase();
        });

        //only return devices that have been queued from the notCompletedSensor list
        let sensorsToProcess = [];
        _.forEach(notCompletedSensors, (sensor) => {
            if (!_.isNil(sensor.DLCode))
                sensor.DLCode = sensor.DLCode.toUpperCase();
            let queuedSensor = _.find(queuedSensors, { DLCode: sensor.DLCode });
            if (!_.isNil(queuedSensor)) 
                sensorsToProcess.push(sensor);
        });

        //filter out skipped sensors
        sensorsToProcess =  _.filter(sensorsToProcess, (sensor) => {
            return _.get(sensor, 'isSkipped', false) === false;
        });

        return sensorsToProcess;
    }

    // Gets the current device ID.
    _getCurrentDeviceId() {
        return this.currentDeviceId;
    }

    // Set the current device ID.
    _setCurrentDeviceId(deviceId) {
        this.currentDeviceId = deviceId;
    }

    logHelp() {
        this.actionContext.common.other(this.constants.actionTypes.other, this.constants.sectionTypes.help, false);
    }

    retry() {
        this.hasError = false;
        this.currentDeviceId = null;
        this.retryCount++;

        this.actionContext.panel.enableInteractiveMode()
            .then(() => {
                this._startProcess(true);
            });
    }
}

export default {
    template: template,
    bindings: {},
    controller: InitializeController
};