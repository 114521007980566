import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import './training.scss';

import commonModule from '../../common';
import menuModule from '../../menu';

import trainingVideoComponent from './videos.component';
import airFxArmAwayComponent from './armAway.component';
import airFxDisarmSystemComponent from './disarmSystem.component';
import airFxArmStayComponent from './armStay.component';

export default angular
    .module('fp:activationswizard:airfx:training', [
        'ui.router',
        ngRedux,
        commonModule.name,
        menuModule.name
    ])
    .config( /*@ngInject*/($stateProvider, constants) => {
 
    })
    .component('awTrainingVideos', trainingVideoComponent)
    .component('awAirFxArmAway', airFxArmAwayComponent)
    .component('awAirFxArmStay', airFxArmStayComponent)
    .component('awAirFxDisarmSystem', airFxDisarmSystemComponent);