/**
 * @name awModelClearOnDestory
 * @resetrict A
 * 
 * @description
 * The 'awModelClearOnDestory' directive clears the value of ngModel when it's scope is destroyed. 
 *
 * <div class="alert alert-info">
 * The value will not be cleared if ngModel's scope is destoryed because of a route change.
 * </div>
 * 
 * @usage
 * ```
 * <ANY ng-model="expression" aw-model-clear-on-destory></ANY>
 * ```
 */
let modelClearOnDestroyDirective = () => {
    'ngInject';
    return {
        restrict: 'A',
        require: 'ngModel',
        link: ($scope, $element, $attr, ctrl) => {
            let isRouteChange = false;
            $scope.$on('$routeChangeStart', () => {
                isRouteChange = true;
            });

            $scope.$on('$destroy', () => {
                if (!isRouteChange) {
                    ctrl.$setViewValue(null);
                }
            });
        }
    };
};

export default modelClearOnDestroyDirective;