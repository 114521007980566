import * as _ from 'lodash';

import AbstractTestController from './AbstractTestController';

import template from './status.html';

class StatusTestController extends AbstractTestController {
    /*@ngInject*/
    constructor ($document, $log, $ngRedux, $q, $state, constants, actionContext, appActions, dataContext) {
        super($document, $log, $ngRedux, $state, constants);

        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.appActions = appActions;
        this.dataContext = dataContext;

        this.constants = constants;
        this.actionContext = actionContext; 
    }

    $onInit() {
        super.$onInit();
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
    }

    canSupportSensor(sensor) {
        return true;
    }

    getName() {
        return 'Status';
    }

    startTest() {        
        let shouldSimulate = false; 
        //for additional orders 
        const isAdditionalOrder = this.appActions.isAdditional();
        if (isAdditionalOrder) {            
                shouldSimulate = true;
        } 
        this.$q.resolve().then(() => {
            return shouldSimulate 
                ? this._handleSimulate() 
                : this._handlePoll();
        }).then(() => {
            this.$state.go('sensor.item.complete');
        });
    }

    stopTest() {
        this.actionContext.device.cancelPoll(this.poll);
    }

    _handlePoll() {
        const deviceService = this.actionContext.device;
        const maxInterval = this.constants.polling.tests.status.pollingInterval;
        const maxAttempts = this.constants.polling.tests.status.maxIntervalCount;

        let isSuccess = false;
        this.poll = deviceService.startPoll(this.sensor, '', maxInterval, maxAttempts);
        return this.poll.then(null, null, () => {
            const isTampered = this.sensor.StatusType === this.constants.deviceStatus.tampered.Name;
            const isMalfunction = this.sensor.StatusType === this.constants.deviceStatus.malfunction.Name;
            const isUnknown = this.sensor.StatusType === this.constants.deviceStatus.unknown.Name;
            if (!isTampered && !isUnknown && !isMalfunction && this.sensor.IsConnected) {
                isSuccess = true;
                deviceService.cancelPoll(this.poll);
            }
        }).catch((err) => {
            return err === 'canceled' && isSuccess
                ? this.$q.resolve()
                : this.$q.reject(err);
        });
    }

    _handleSimulate() {
        this.poll = this.actionContext.device.simulatePoll(this.sensor);
        return this.poll;
    }
}

export default {
    template: template,
    bindings: {},
    controller: StatusTestController
};