import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import 'angular-http-auth';

import constantsModule from '../constants';
import dataModule from '../data';
import metadataModule from '../metadata';

import ActionContext from './actionContext.service';
import AppActions from './app.actions';
import CommonActions from './common.actions';
import CustomerActions from './customer.actions';
import DeviceActions from './device.actions';
import LoginActions from './login.actions';
import OrderActions from './order.actions';
import PanelActions from './panel.actions';
import PasswordActions from './password.actions';
import PredispatchActions from './predispatch.actions';
import PremisesActions from './premises.actions';
import ProductActions from './product.actions';
import TestModeActions from './testmode.actions';
import TosActions from './tos.actions';
import TosOptionsActions from './tosOptions.actions';
import WifiActions from './wifi.actions';
import AccountActions from './account.actions';
import PartnerActions from './partner.actions';
import CodeActions from './code.actions';
import ZwaveActions from './zWave.actions';
import AirfxFlowActions from './airfxFlow.actions';
import ModSwapActions from './modSwap.actions';
import RadioSwapActions from './radioSwap.actions';
import EquipmentSetupActions from './equipmentSetup.actions';
import KitCustomerActions from './kitCustomer.actions';
import CustomerBillingInfoActions from './customerBillingInfo.actions';
import SmsActions from './sms.actions';

export default angular.module('fp:activationswizard:common:action', [
            'ui.router',
            'http-auth-interceptor',
            'mobile-angular-ui',
            ngRedux,
            constantsModule.name,
            dataModule.name,
            metadataModule.name
        ])
    .service('actionContext', ActionContext)
    .service('appActions', AppActions)
    .service('commonActions', CommonActions)
    .service('customerActions', CustomerActions)
    .service('deviceActions', DeviceActions)
    .service('loginActions', LoginActions)
    .service('orderActions', OrderActions)
    .service('panelActions', PanelActions)
    .service('passwordActions', PasswordActions)
    .service('predispatchActions', PredispatchActions)
    .service('premisesActions', PremisesActions)
    .service('productActions', ProductActions)
    .service('testmodeActions', TestModeActions)
    .service('tosActions', TosActions)
    .service('tosOptionsActions', TosOptionsActions)
    .service('wifiActions', WifiActions)
    .service('accountActions', AccountActions)
    .service('partnerActions', PartnerActions)
    .service('codeActions', CodeActions)
    .service('zwaveActions', ZwaveActions)
    .service('airfxflowActions', AirfxFlowActions)
    .service('modSwapActions', ModSwapActions)
    .service('radioSwapActions', RadioSwapActions)
    .service('equipmentSetupActions', EquipmentSetupActions)
    .service('kitCustomerActions', KitCustomerActions)
    .service('customerBillingInfoActions', CustomerBillingInfoActions)
    .service('smsActions', SmsActions);
