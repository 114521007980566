import * as _ from 'lodash';

let textMustMatchDirective = () => {
    "ngInject";
    return {
        restrict: 'A',
        'require': 'ngModel',
        scope: {
            awTextMustMatch: '='
        },
        link: (scope, element, attributes, controller) => {
            scope.$watch(() => {
                let modelValue = controller.$modelValue || controller.$$invalidModelValue;
                return (controller.$pristine && _.isUndefined(modelValue)) || scope.awTextMustMatch === modelValue;
            }, (currentValue) => {
                controller.$setValidity('match', currentValue);
            });
        }
    };
};

export default textMustMatchDirective;