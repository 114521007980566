import './userCodes.scss';
import template from './userCodes.html';
import * as _ from 'lodash';

class UserCodeController {
    /*@ngInject*/
    constructor(actionContext, $ngRedux, SharedState, $stateParams, segment, constants, $state) {
        this.actionContext = actionContext;
        this.$ngRedux = $ngRedux;
        this.SharedState = SharedState;
        this.showAddModal = false;
        this.showDeleteModal = false;
        this.deleteCode = null;
        this.swapType = $ngRedux.getState().system.swap.swapType;
        this.segment = segment;
        this.constants = constants;
        this.$state = $state;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        const oldUserCodes = _.cloneDeep(this.userCodes); // this will break if the user refreshes the page

        if (this.swapType !== this.constants.swapType.radio) {
            this._transferCodes(oldUserCodes);
        }
    }

    $onDestroy() {
        this.unsubscribe();
    }

    onAddUserCode() {
        this.showAddModal = true;
        this.SharedState.turnOn('addUserCodeModal');
    }

    onDeleteUserCode(code) {
        this.showDeleteModal = true;
        this.deleteCode = code;
        this.SharedState.turnOn('deleteUserCodeModal');
    }

    onContinue() {
        if (this.swapType === this.constants.swapType.radio) {
            this.actionContext.radioSwap.onRadioSwapComplete();
        }
        else if (this.targetSku === this.constants.panelVersions.iqPanel) {
            this.$state.go('airfx.duressCodes');
        }
        else {
            this.$state.go(this.constants.routerStates.testUserCode);
        }
    }

    onPrevious() {
        if (this.swapType === this.constants.swapType.radio) {
            this.$state.go(this.constants.routerStates.radioSwapSensorTransfer, { swapType: this.swapType });
        } else {
            this.$state.go(this.constants.routerStates.swapSensorTransfer);
        }
    }

    isAddUserButtonDisabled() {
        if (this.targetSku == this.constants.panelVersions.iqPanel) {
            return this.userCodes.length > 241;
        } else {
            return this.userCodes.length > 3;
        }
    }

    _transferCodes(codes) {
        this.isLoading = true;

        //Adding the IsNew so that it will be added
        //Need to revisit this to Update Master Code and add the rest as User Codes
        _.forEach(codes, (code) => {
            code.IsNew = true;
        });

        this.actionContext.code.fetchCode().then(() => {
            this.hubUserCodes = this.userCodes;
            // Exclude Master code
            this.hubUserCodes.shift();
            // set code to null for delete
            _.forEach(this.hubUserCodes, (code) => {
                code.Code = null;
            });
            let masterCode = codes[0];
            //Exclude Master code
            codes.shift();
            
            let userCodesList = codes.concat(this.hubUserCodes);

            this.actionContext.code.updateMasterCode(masterCode.Code).then(() => {
                this.actionContext.code.editUserCodes(userCodesList)
                    .then(() => {
                        this.showSuccessComponent = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            });      
        });          
    }

    _mapState(state) {
        return _.cloneDeep({
            userCodes: state.system.code.codes,
            targetSku: state.account.airfxFlow.targetSku
        });
    }
}

export default {
    template: template,
    bindings: {},
    controller: UserCodeController
};