import * as _ from 'lodash';

import template from './contact.html';

class ContactController {
    /*@ngInject*/
    constructor($ngRedux, $state, constants, actionContext, dataContext, navigation, $log) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.actionContext = actionContext;
        this.dataContext = dataContext;
        this.navigation = navigation;
        this.$log = $log;
        this.isUpdating = false;
        this.isDataLoading = true;
        this.isFeedonomicsOrder = false;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        if (!this.isAmazonUser) {
            this.dataContext.account.order.getOrder().then(order => {
                this.accountId = order.AccountID;
                this.orderAttributes = order.OrderAttributes;
                this.isFeedonomicsOrder = (_.filter(this.orderAttributes, (orderAttribute) => { return orderAttribute.Name === 'FeedonomicsOrder'; })).length > 0 ? true : false;
                this.actionContext.order.setIfOrderIsFeedonomic(this.isFeedonomicsOrder);
                this.isRandomPhone = (_.filter(this.orderAttributes, (orderAttribute) => { return orderAttribute.Name === 'RandomPhone'; })).length > 0 ? true : false;
                this.isWalmartEmail = (_.filter(this.orderAttributes, (orderAttribute) => { return orderAttribute.Name === 'WalmartEmail'; })).length > 0 ? true : false;;
                if (this.isRandomPhone == true) {
                    this.contact.Phone = '';
                }
                if (this.isWalmartEmail == true) {
                    this.contact.Email = '';
                }
                this.isDataLoading = false;
            }).catch((error) => {
                this.isDataLoading = false;
                this.$log.error('Error getting the order for contact information.', error);
            });
        } else
            this.isDataLoading = false;
    }

    validatePhone(form) {
        const phone = form.phone.$modelValue;
        if (phone && phone.match(this.constants.regex.phoneNumber)) {
            form.phone.$setValidity('valid', true);
        } else {
            form.phone.$setValidity('valid', false);
        }
    }

    onSave() {
        if (this.isAmazonUser) {
            this.isUpdating = true;
            this.actionContext.kitCustomer.createLeadAndQuote(this.contact).then(() => {
                this.navigation.goNext();
                this.isUpdating = false;
            });
        } else {
            this.isUpdating = true;            
            this.dataContext.account.getAccountInformation(this.accountId)
                .then((result) => {
                    this.contact.isResidential = result.Contact.IsResidential;
                    this.contact.companyName = result.Contact.CompanyName;                    
                    this.actionContext.customer.updateCustomer(this.contact).then(() => {
                        this.navigation.goNext();
                        this.isUpdating = false;
                    });
                })
                .catch((error) => {
                    this.$log.error('Error fetching customer information.', error);                    
                });            
        }
    }

    _mapState(state) {
        let map = {};

        map.contact = state.account.kitCustomer.contact;
        map.hasError = state.account.kitCustomer.hasError;
        map.isAmazonUser = state.account.user.isAmazonUser;
        if (!map.isAmazonUser) {
            //Refactor this to a util function if we use it more.
            map.contact = _.clone(_.pick(state.account.customer.value, ['FirstName', 'LastName', 'Email', 'Phone']));
        }
        return _.cloneDeep(map);
    }
}

export default {
    template: template,
    bindings: {},
    controller: ContactController
};