import * as _ from 'lodash';

export const CATEGORY_TYPE = 'CATEGORY_TYPE';
export const SETUP_STARTED = 'SETUP_STARTED';
export const SENSOR_SETUP = 'SENSOR_SETUP';
export const SENSOR_PRESENTED = 'SENSOR_PRESENTED';
export const SENSOR_ACTIVATION = 'SENSOR_ACTIVATION';
export const ERROR_LOGGED = 'ERROR_LOGGED';

class EquipmentSetupActions {
    /*@ngInject*/
    constructor($log, $ngRedux, $state, constants, $q, dataContext) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.$q = $q;
        this.dataContext = dataContext;
    }

    onCategorySelect(category) {
        this._dispatch({
            type: CATEGORY_TYPE,
            payload: {
                category: category
            },
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.equipmentSetup,
                description: 'Category Selected - ' + category,
                actionType: 'Info'
            }
        });
    }

    onSetupStart(message) {
        this._dispatch({
            type: SETUP_STARTED,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.equipmentSetup,
                description: message,
                actionType: 'Info'
            }
        });
    }

    onSensorSetup(productName) {
        this._dispatch({
            type: SENSOR_SETUP,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.equipmentSetup,
                description: 'Setting up ' + productName,
                actionType: 'Info'
            }
        });
    }

    sensorPresented(message) {
        this._dispatch({
            type: SENSOR_PRESENTED,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.equipmentSetup,
                description: message,
                actionType: 'Info'
            }
        });
    }

    onSensorSelectionForActivation(device) {
        this._dispatch({
            type: SENSOR_ACTIVATION,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.equipmentSetup,
                description: `Activation started - OrderProductId: [${device.OrderProductID}] DLCode: [${device.DLCode}] SKU: [${device.ProductSku}] SensorNumber: [${device.SensorNumber}] `,
                actionType: 'Info'
            }
        });
    }

    onErrorPageInitialization(message) {
        this._dispatch({
            type: ERROR_LOGGED,
            metadata: {
                persist: false,
                sectionType: this.constants.sectionTypes.equipmentSetup,
                description: message,
                actionType: 'Error'
            }
        });
    }

    _dispatch(obj) {
        this.$ngRedux.dispatch(obj);
    }
}

export default EquipmentSetupActions;