import template from './smokeHeatInstallationModal.html';

class SmokeHeatInstallationModalController {
    /*@ngInject*/
    constructor () {
    }
}

export default {
    template: template,
    bindings: {
        sku: '@'
    },
    controller: SmokeHeatInstallationModalController
};