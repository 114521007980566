import moment from 'moment';

class SupportService {
    /*@ngInject*/
    constructor(Restangular) {
        this.restangular = Restangular;
    }

    scheduleCallback(callbackDate, callbackMsg) {
        return this.restangular
            .one('support')
            .one('callback')
            .customPOST({
                CallbackDateTime: moment.utc(callbackDate).toDate(),
                Body: callbackMsg
            });
    }

    sendEmail(address, subject, body) {
        return this.restangular
            .one('support')
            .one('email')
            .customPOST({
                Address: address,
                Subject: subject,
                Body: body
            });
    }
}

export default SupportService;