import * as _ from 'lodash';
import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import commonModule from '../../common';

import testModule from './tests';
import errorModule from './errors';

import sensorItemCompleteComponent from './complete.component';
import sensorItemInformationComponent from './information.component';
import sensorItemInstallationComponent from './installation.component';
import sensorItemOverviewComponent from './overview.component';
import sensorItemTestComponent from './test.component';
import sensorItemBoxComponent from './box.component';

export default angular
    .module('fp:activationswizard:sensor:item', [
        'ui.router',
        ngRedux,
        commonModule.name,
        testModule.name,
        errorModule.name
    ])
    .config( /*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            .state('sensor.item', {
                abstract: true,
                url: '/{sensorId:int}',
                template: '<ui-view></ui-view>',
                resolve: {
                    sensorId: /*@ngInject*/ ($stateParams) => {
                        return $stateParams.sensorId;
                    },
                    sensorItemSetupComplete: /*@ngInject*/ ($log, $state, $stateParams, $timeout, $q, actionContext, sensorId, sensorSetupComplete) => {
                        const sensor = actionContext.device.findById(sensorId);
                        // The requested sensor does not exist. Redirect to the sensor list.
                        if (_.isNil(sensor)) {
                            $log.error(`Could not find sensor ${sensorId}; redirecting to sensor list page.`);

                            $timeout(() => {
                                $state.go('sensor.list');
                            });

                            return $q.reject(false);
                        }

                        // The requested sensor does exist. If it has an error condition make sure we are on the right page.
                        // Not Connected
                        const notConnectedState = 'sensor.item.not-connected';
                        if (!actionContext.device.isSensorConnected(sensor)) {
                            if ($state.next.name === notConnectedState) {
                                return $q.resolve(true);
                            }

                            $log.debug(`Sensor ${sensorId} is not connected; redirecting to sensor not connected page.`);

                            $timeout(() => {
                                $state.go(notConnectedState, { sensorId: sensorId });
                            });

                            return $q.reject(false);
                        }
                        // Tampered
                        const tamperState = 'sensor.item.tamper';
                        if (actionContext.device.isSensorTampered(sensor)) {
                            if ($state.next.name === tamperState) {
                                return $q.resolve(true);
                            }

                            $log.debug(`Sensor ${sensorId} is tampered; redirecting to sensor tamper page.`);

                            $timeout(() => {
                                $state.go(tamperState, { sensorId: sensorId });
                            });

                            return $q.reject(false);
                        }
                        // Malfunctioning
                        const malfunctionState = 'sensor.item.malfunction';
                        if (actionContext.device.isSensorMalfunctioning(sensor)) {
                            if ($state.next.name === malfunctionState) {
                                return $q.resolve(true);
                            }

                            $log.debug(`Sensor ${sensorId} is malfunctioning; redirecting to sensor malfunction page.`);

                            $timeout(() => {
                                $state.go(malfunctionState, { sensorId: sensorId });
                            });

                            return $q.reject(false);
                        }

                        return $q.resolve(true);
                    }
                },
                onEnter: /*@ngInject*/ (actionContext, sensorId) => {
                    actionContext.device.startSetup(sensorId);
                },
                onExit: /*@ngInject*/ (actionContext) => {
                    actionContext.device.stopSetup();
                },
                data: {
                    persist: false
                }
            })
            .state('sensor.item.box', {
                url: '/box',
                template: '<aw-sensor-item-box></aw-sensor-item-box>',
                resolve: {
                    showBoxInstructions: /*@ngInject*/ ($ngRedux, $state, $timeout, $q, actionContext, sensorId, sensorItemSetupComplete) => {
                        const sensor = actionContext.device.findById(sensorId);

                        if (actionContext.device.isOverviewRequired(sensor) 
                            && !_.isNil(actionContext.product.getBoxText(sensor.ProductSku))) {
                            return $q.resolve();
                        }

                        $timeout(() => {
                            if($state.$current.toString() === 'sensor.item.overview')
                                $state.go('sensor.list');
                            else
                                $state.go('sensor.item.overview', { sensorId: sensorId });
                        });

                        return $q.reject(constants.errors.resolveRedirect);
                    }
                },
                data: {
                    persist: false
                }
            })
            .state('sensor.item.overview', {
                url: '/overview',
                template: '<aw-sensor-item-overview></aw-sensor-item-overview>',
                resolve: {
                    _: /*@ngInject*/ ($ngRedux, $state, $timeout, $q, actionContext, sensorId, sensorItemSetupComplete) => {
                        const sensor = actionContext.device.findById(sensorId);
                        const isOverviewRequired = actionContext.device.isOverviewRequired(sensor);

                        if (isOverviewRequired) {
                            return $q.resolve();
                        }

                        $timeout(() => {
                             $state.go('sensor.item.installation', { sensorId: sensorId });
                        });

                        return $q.reject(constants.errors.resolveRedirect);
                    }
                },
                data: {
                    persist: false
                }
            })
            .state('sensor.item.installation', {
                url: '/installation',
                template: '<aw-sensor-item-installation></aw-sensor-item-installation>',
                data: {
                    persist: false
                }
            })
            .state('sensor.item.keypadinstallation', {
                url: '/keypadinstallation',
                template: '<aw-sensor-item-installation></aw-sensor-item-installation>',
                data: {
                    persist: false
                }
            })
            .state('sensor.item.test', {
                url: '/test',
                template: '<aw-sensor-item-test></aw-sensor-item-test>',
                data: {
                    persist: false
                }
            })
            .state('sensor.item.complete', {
                url: '/test/complete',
                template: '<aw-sensor-item-complete></aw-sensor-item-complete>',
                data: {
                    theme: 'app-theme-green',
                    persist: false
                }
            })
            .state('sensor.item.mark-completed', {
                onEnter: /*@ngInject*/ ($state, actionContext) => {
                    actionContext.device.finishSetup();
                    $state.go('sensor.list');
                },
                data: {
                    persist: false
                }
            })
            .state('sensor.item.mark-skipped', {
                onEnter: /*@ngInject*/ ($state, actionContext) => {
                    actionContext.device.skipSetup();
                    $state.go('sensor.list');
                },
                data: {
                    persist: false
                }
            });
    })
    .component('awSensorItemComplete', sensorItemCompleteComponent)
    .component('awSensorItemInformation', sensorItemInformationComponent)
    .component('awSensorItemInstallation', sensorItemInstallationComponent)
    .component('awSensorItemOverview', sensorItemOverviewComponent)
    .component('awSensorItemTest', sensorItemTestComponent)
    .component('awSensorItemBox', sensorItemBoxComponent);