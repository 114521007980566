import { combineReducers } from 'redux';

import staticReducer from '../static.reducer';

import eligibleSensorNamesReducer from './eligibleSensorNames.reducer';
import statesReducer from './states.reducer';
import configurationReducer from './configuration.reducer';
import stepReducer from './step.reducer';
import loadReducer from './load.reducer';

import { FETCH_ADC_STATUS_SUCCESS, FETCH_RAPID_STATUS_SUCCESS } from '../../action/partner.actions';
import { COMPLETE_ACTIVATION_WIZARD } from '../../action/app.actions';

const adcStatusReducer = (state = true, action = {}) => {
    switch (action.type) {
    case FETCH_ADC_STATUS_SUCCESS:
        return action.payload.status;
    default:
        return state;
    }
};

const rapidStatusReducer = (state = true, action = {}) => {
    switch (action.type) {
    case FETCH_RAPID_STATUS_SUCCESS:
        return action.payload.status;
    default:
        return state;
    }
};

const hydrateStatusReducer = (state = false, action = {}) => {
    switch (action.type) {
    case 'HYDRATE':
        return true;
    default:
        return state;
    }
};

const finishedWizardReducer = (state = false, action = {}) => {
    switch (action.type) {
    case 'COMPLETE_ACTIVATION_WIZARD':
        return true;
    default:
        return state;
    }
};

export default combineReducers({
    ignore: staticReducer(['eligibleSensorNames', 'states', 'config', 'isLoading', 'isAdcAvailable', 'isHydrated', 'isRapidAvailable']),
    eligibleSensorNames: eligibleSensorNamesReducer,
    states: statesReducer,
    config: configurationReducer,
    lastCompletedStep: stepReducer,
    isLoading: loadReducer,
    isAdcAvailable: adcStatusReducer,
    isHydrated: hydrateStatusReducer,
    isRapidAvailable: rapidStatusReducer,
    finishedWizard : finishedWizardReducer
});