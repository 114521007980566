import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import commonModule from '../../../common';
import userCodesComponent from './userCodes.component';
import addUserCodeModalComponent from './addUserCodeModal.component';
import addUserCodeFormComponent from './addUserCodeForm.component';
import deleteUserCodeModalComponent from './deleteUserCodeModal.component';
import deleteUserCodeComponent from './deleteUserCode.component';
import editUserCodeConfirmationComponent from './editUserCodeConfirmation.component';
import testUserCodesComponent from './testUserCodes.component';

export default angular
    .module('fp:activationswizard:airfx:modswap:userCodes', [
        ngRedux,
        commonModule.name
    ])
    .component('awAddUserCodeModal', addUserCodeModalComponent)
    .component('awAddUserCodeForm', addUserCodeFormComponent)
    .component('awModswapUserCodes', userCodesComponent)
    .component('awDeleteUserCodeModal', deleteUserCodeModalComponent)
    .component('awDeleteUserCode', deleteUserCodeComponent)
    .component('awEditUserCodeConfirmation', editUserCodeConfirmationComponent)
    .component('awRadioswapTestUserCodes', testUserCodesComponent);

