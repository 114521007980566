import './zWaveOverlay.scss';
import template from './zWaveOverlay.html';

class ZWaveOverlayController {
    /*@ngInject*/
    constructor(constants, dataContext, $window, $timeout) {
        this.constants = constants;
        this.dataContext = dataContext;
        this.$window = $window;
        this.$timeout = $timeout;
    }

    $onInit() {
        this.$timeout(() => {
            this.url = '';
            this.dataContext.user.getAdcRedirectUrl()
                .then(url => {
                    url = _.replace(url, 'myfrontpoint', 'alarm');
                    const startStep = '';//this.isAdditionalLock ? '?startingStep=3' : '?startingStep=3';
                    this.url = `${url}&returnUrl=${this.constants.zWaveReturnUrl}${startStep}&wizardReturnUrl=${this.$window.location.origin}${this.constants.zWaveWizardReturnUrl}`;
                });
        }, this.constants.zWaveOverlayTimeout);
    }

    iframeLoaded() {
        this.isLoaded();
    }

    zWaveCompleted() {
        this.isCompleted();
    }
}

export default {
    template: template,
    bindings: {
        isLoaded: '&',
        isCompleted: '&',
        isAdditionalLock: '<'
    },
    controller: ZWaveOverlayController
};