import template from './complete.html';

class SensorCompleteController {
    /*@ngInject*/
    constructor(constants, $state) {
        this.constants = constants;
        this.$state = $state;
    }
}

export default {
    template: template,
    bindings: {},
    controller: SensorCompleteController
};