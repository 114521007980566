import * as _ from 'lodash';

import template from './armStay.html';

class ArmStayController {
    /*@ngInject*/
    constructor(deviceActions, constants, $state, $interval, $stateParams) {
        this.deviceActions = deviceActions;
        this.constants = constants;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$interval = $interval;
        this.isPanelArmedStay = false;
        this.canSkip = false;
    }

    $onInit() {
        this.pollPanelStatus();
    }

    pollPanelStatus() {
        // number of milliseconds between each interval
        const intervalDelay = this.constants.polling.training.pollingInterval;
        // indefinite interval to keep checking for the panel status
        this.$interval(() => {
            this.deviceActions.getPanelStatus().then((result) => {
                let panelStatus = result;
                this.isPanelArmedStay = panelStatus === this.constants.deviceStatus.armedStay.ID;
                this.canSkip = panelStatus === this.constants.deviceStatus.disarmed.ID;
            });
        }, intervalDelay);
    }

    skip() {
        this.$state.go('airfx.modswapSuccess');
    }
}

export default {
    template: template,
    bindings: {},
    controller: ArmStayController
};