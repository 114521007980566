import template from './addSensorsDelayComplete.html';

class AddSensorsDelayCompleteController {
    /*@ngInject*/
    constructor(constants, actionContext) {
        this.constants = constants;
        actionContext.device.resetSkippedDevices();
    }
}

export default {
    template: template,
    bindings: {},
    controller: AddSensorsDelayCompleteController
};