import * as _ from 'lodash';
import ensure from '../util/ensure';

export const CUSTOMER_FETCH_REQUEST = 'CUSTOMER_FETCH_REQUEST';
export const CUSTOMER_FETCH_FAILURE = 'CUSTOMER_FETCH_FAILURE';
export const CUSTOMER_FETCH_SUCCESS = 'CUSTOMER_FETCH_SUCCESS';
export const CUSTOMER_UPDATE_REQUEST = 'CUSTOMER_UPDATE_REQUEST';
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';
export const CUSTOMER_UPDATE_FAILURE = 'CUSTOMER_UPDATE_FAILURE';

export const PASSCODE_UPDATE_REQUEST = 'PASSCODE_UPDATE_REQUEST';
export const PASSCODE_UPDATE_FAILURE = 'PASSCODE_UPDATE_FAILURE';
export const PASSCODE_UPDATE_SUCCESS = 'PASSCODE_UPDATE_SUCCESS';

export const PASSCODE_VALIDITYCHECK_REQUEST = 'PASSCODE_VALIDITYCHECK_REQUEST';
export const PASSCODE_VALIDITYCHECK_FAILURE = 'PASSCODE_VALIDITYCHECK_FAILURE';
export const PASSCODE_VALIDITYCHECK_SUCCESS = 'PASSCODE_VALIDITYCHECK_SUCCESS';

export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

class CustomerActions {
    /*@ngInject*/
    constructor($log, $ngRedux, $q, dataContext, constants) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.dataContext = dataContext;
        this.constants = constants;
    }

    /**
     * Fetches the current user's customer information and adds it to the
     * application state.
     * 
     * @returns {promise} A promise that returns undefined if resolved and
     * an error if rejected. 
     */
    fetchCustomer() {
        this._onFetchCustomer();

        return this.dataContext.account.getCustomer()
            .then((result) => {
                this._onFetchCustomerSuccess(result);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error fetching customer information.', error);
                this._onFetchCustomerFailure(error);
                return this.$q.reject('fetchCustomer');
            });
    }

    updateCustomer(customer) {
        this._onUpdateCustomer();
        const updateCustomer = {
            CustomerInformation: customer
        };
        return this.dataContext.account.updateCustomer(updateCustomer)
            .then((result) => {
                this._onUpdateCustomerSuccess(updateCustomer.CustomerInformation);
                return this.$q.resolve();;
            })
            .catch((error) => {
                this.$log.error('Error updating customer information.', error);
                this._onUpdateCustomerFailure(error);
                return this.$q.reject(error);
            });
    }

    updatePasscode(passcode) {
        try {
            ensure.isString('passcode', passcode);
            this._onUpdatePasscode();
            var updatePasscodeRequest =
            {
                newPasscode: passcode
            };
            return this.dataContext.account.updatePasscode(updatePasscodeRequest)
                .then(() => {
                    this._onUpdatePasscodeSuccess(updatePasscodeRequest.newPasscode);
                    return this.$q.resolve();
                })
                .catch((error) => {
                    this.$log.error('Error updating the customer\'s Passcode.', error);
                    this._onUpdatePasscodeFailure(error);
                    return this.$q.reject(error);
                });
        } catch (error) {
            this.$log.error('Error updating the customer\'s Passcode.', error);
            this._onUpdatePasscodeFailure(error);
            return this.$q.reject(error);
        }
        
    }

    checkPasscode(passcode) {
        try {
            ensure.isString('passcode', passcode);
            this._onValidatePasscode();

            return this.dataContext.account.isPasscodeValid(passcode)
                .then((response) => {
                    this._onValidatePasscodeSuccess();
                    return this.$q.resolve(response);
                })
                .catch((error) => {
                    this.$log.error('Error updating the customer\'s Passcode.', error);
                    this._onValidatePasscodeFailure(error);
                    return this.$q.reject(error);
                });
        } catch(error) {
            this.$log.error('Error updating the customer\'s Passcode.', error);
            this._onValidatePasscodeFailure(error);
            return this.$q.reject(error);
        }
    }

    startLoading() {
        this._dispatch({ type: START_LOADING });
    }

    stopLoading() {
        this._dispatch({ type: STOP_LOADING });
    }

    _onValidatePasscode() {
        this._dispatch({
            type: PASSCODE_VALIDITYCHECK_REQUEST
        });
    }

    _onValidatePasscodeSuccess(data) {
        this._dispatch({
            type: PASSCODE_VALIDITYCHECK_SUCCESS
        });
    }

    _onValidatePasscodeFailure(error) {
        this._dispatch({
            type: PASSCODE_VALIDITYCHECK_FAILURE,
            payload: {
                data: error
            }
        });
    }

    _onUpdatePasscode() {
        this._dispatch({
            type: PASSCODE_UPDATE_REQUEST
        });
    }

    _onUpdatePasscodeSuccess(data) {
        var updatedObject = { Passcode: data };
        this._dispatch({
            type: PASSCODE_UPDATE_SUCCESS,
            payload: {
                data: updatedObject
            },
            metadata: {
                sectionType: this.constants.sectionTypes.passcode,
                description: 'The Passcode was set',
                persist: true
            }
        });
    }

    _onUpdatePasscodeFailure(error) {
        this._dispatch({
            type: PASSCODE_UPDATE_FAILURE,
            payload: {
                error
            },
            metadata: {
                sectionType: this.constants.sectionTypes.passcode,
                description: `Error updating the customer\'s Passcode.`,
                persist: true,
                isHydratable: false
            }
        });
    }

    _onUpdateCustomer() {
        this._dispatch({
            type: CUSTOMER_UPDATE_REQUEST
        });
    }

    _onUpdateCustomerSuccess(data) {
        const updatedObject = {Customer: data};
        this._dispatch({
            type: CUSTOMER_UPDATE_SUCCESS,
            payload: {
                data: updatedObject
            },
            metadata: {
                sectionType: this.constants.sectionTypes.account,
                description: 'The Customer was updated.',
                persist: true
            }
        });
    }

    _onUpdateCustomerFailure(error) {
        this._dispatch({
            type: CUSTOMER_UPDATE_FAILURE,
            payload: {
                error
            },
            metadata: {
                sectionType: this.constants.sectionTypes.account,
                description: 'Error updating customer information.',
                persist: true,
                isHydratable: false
            }
        });
    }

    _dispatch(obj) {
        this.$ngRedux.dispatch(obj);
    }

    _onFetchCustomer() {
        this._dispatch({
            type: CUSTOMER_FETCH_REQUEST
        });
    }

    _onFetchCustomerFailure(error) {
        this._dispatch({
            type: CUSTOMER_FETCH_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onFetchCustomerSuccess(data) {
        this._dispatch({
            type: CUSTOMER_FETCH_SUCCESS,
            payload: {
                data: data
            }
        });
    }
}

export default CustomerActions;