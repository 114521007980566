import * as _ from 'lodash';

import { CONFIGURATION_FETCH_REQUEST, CONFIGURATION_FETCH_FAILURE, CONFIGURATION_FETCH_SUCCESS } from '../../action/app.actions';
import clientProducts from '../../constants/products';

const initialState = [];

/**
 * Returns true if the two product data objects are equal; otherwise, false.
 * 
 * @param {Object} product1 
 * @param {Object} product2 
 * @returns {boolean} 
 */
function productComparer(product1, product2) {
    if (product1 === product2) {
        return true;
    }

    if (_.isNil(product1)) {
        return false;
    }

    if (_.isNil(product2)) {
        return false;
    }
    
    return product1.Name === product2.Name;
}

/**
 * Merge the provided server side data with the client side data before persisting in the application state.
 * 
 * @param {Object} serverData 
 * @returns {Object} 
 */
function mergeData(serverData) {
    if (serverData.products) {
        serverData.products = _.mergeArray(/*Server*/ serverData.products, /*Client*/ clientProducts, productComparer);
    }

    return serverData;
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case CONFIGURATION_FETCH_REQUEST:
        case CONFIGURATION_FETCH_FAILURE:
            return state;
        case CONFIGURATION_FETCH_SUCCESS:
            return mergeData(action.payload.data);
        default:
            return state;
    }
};