import './keypadActivation.scss';
import template from './keypadActivation.html';
class KeypadActivationController {

    /*@ngInject*/
    constructor($ngRedux, $state, constants, actionContext, SharedState) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.actionContext = actionContext;
        this.SharedState = SharedState;
    }

    $onInit() {
        this.disableContinue = true;
        setTimeout(this.pollKeypadStatus(), this.constants.ping.Keypad.pollingInterval);
        this.isLoading = true;
        this.Activated = false;
    }

    $onDestroy() {
    }

    setHelper(show) {
        this.sethelp({
            showhelpModal: show
        });
        this.SharedState.turnOn('helpPanelModal');
    }

    pollKeypadStatus() {
        const interval = this.constants.ping.Keypad.pollingInterval;
        const timeout = this.constants.ping.Keypad.maxIntervalCount;
        const deviceId = this.$state.params.sensorId;
        const timerId = setInterval(() => {
            this.actionContext.panel.CheckKeypadStatus(deviceId).then((result) => {
                    if (result === "Active") {
                        this.disableContinue = false;
                        this.Activated = true;
                        clearInterval(timerId);
                    }
                });

            },
            interval);
        setTimeout(() => {
                this.setHelper(true);
                clearInterval(timerId);
            },
            timeout);
    }

    onContinue() {
        if (!this.disableContinue) {
            this.$state.go('sensor.item.keypadinstallation', { sensorId: this.$state.params.sensorId });
        }
    }

}

export default {
  template: template,
  bindings: { sethelp: '&'},
  controller: KeypadActivationController
};