import template from './noSensors.html';

class NoSensorsController {
    /*@ngInject*/
    constructor () {
    }
}

export default {
template: template,
    bindings: {},
controller: NoSensorsController
};