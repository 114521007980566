import './error.scss';
import template from './inProgress.html';

class InProgressComponent {
    /*@ngInject*/
    constructor() {
    }
}

export default {
    template: template,
    bindings: {},
    controller: InProgressComponent
};