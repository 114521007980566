const defaultBannerText = 'All steps must be completed before your system is fully functional and monitored, unless the option to \"skip\" is displayed.';

export default [
    {
        key: 'account',
        bannerTitle: 'Setup Overview:',
        bannerText: defaultBannerText,
        title: 'Account Setup',
        description: 'Position your Display Hub+ and set up your account details.',
        action: /*@ngInject*/ (context, step, $log, $state) => {
            $log.debug(step.title + ' action called');
            $state.go('instruction.place');
        }
    },
    {
        key: 'panel',
        bannerTitle: 'Doing great!',
        bannerText: 'Remember: your system will not be fully activated until you reach the end.',
        title: 'Display Hub+ Setup',
        description: 'Finish setup for your Display Hub+ and orient yourself with arming.',
        action: /*@ngInject*/ (context, step, $log, $state) => {
            $log.debug(step.title + ' action called');
            $state.go('wifi.home');
        }
    },
    {
        key: 'sensor',
        bannerTitle: 'Doing Great!',
        bannerText: 'Remember: your system will not be fully activated until you reach the end.',
        title: 'Sensor Installation',
        description: 'Mount, name, and test your system sensors.',
        action: /*@ngInject*/ (context, step, $log, $state) => {
            $log.debug(step.title + ' action called');
            $state.go('sensor.list');
        }
    },
    {
        key: 'explore',
        bannerTitle: 'Almost done!',
        bannerText: 'Your system will be fully activated once you press \"Continue\".',
        title: 'Explore Mobile App',
        description: 'After downloading, login and add any cameras or devices you\'ve purchased.',
        descriptionApp: 'Set up notifications and add any cameras or devices you\'ve purchased.',
        action: /*@ngInject*/ (context, step, $log, $state) => {
            $log.debug(step.title + ' action called');
            $state.go('activation.complete');
        }
    }
];