import * as _ from 'lodash';

import imageDoorWindowSensorRE122 from '../../../Content/images/illustrations/illu_resolution_door_window.png';
import imageDoorWindowSensor from '../../../Content/images/illustrations/illu_windowDoor.svg';
import imageLongRangeDoorWindowSensorRE101 from '../../../Content/images/illustrations/illu_resolution_longRange_DW.png'
import imageRecessedDoorSensor from '../../../Content/images/illustrations/illu_recessedDoor.png';
import imageGarageDoorSensor from '../../../Content/images/illustrations/Product3garage2.0.png';
import imageResolutionGarageDoorSensor from '../../../Content/images/illustrations/illu_resolution_garageDoor_tilt.png';
import imageImageSensor from '../../../Content/images/illustrations/illu_imageSensor.svg';
import imageMotionSensor from '../../../Content/images/illustrations/Motion3.0_(Alula)_line_art_200x200.png';
import imageGlassBreakSensor from '../../../Content/images/illustrations/illu_glassSensor.svg';
import imageGlassBreakSensorV2 from '../../../Content/images/illustrations/illu_glassSensorV2.svg';
import imageGlassBreakSensorV3 from '../../../Content/images/illustrations/illu_glassSensorV3.svg';
import imageSmokeHeatSensor from '../../../Content/images/illustrations/illu_smokeSensor.svg';
import imageSmokeHeatSensorSDX135Z from '../../../Content/images/illustrations/illu_smokeSensor2.svg';
import imageSmokeHeatSensorRE114 from '../../../Content/images/illustrations/illu_smokeSensor3.svg';
import imageCarbonMonoxideSensor from '../../../Content/images/illustrations/illu_carbonSensor.svg';
import imageCarbonMonoxideSensorRE113 from '../../../Content/images/illustrations/RE113_lineart_CarbonMonoxide.png';
import imageWaterSensor from '../../../Content/images/illustrations/illu_waterSensor.svg';
import imageYardSign from '../../../Content/images/illustrations/illu_sign.png';
import imageIQPanel from '../../../Content/images/illustrations/illu_panelA.svg';
import imageXTPanel from '../../../Content/images/illustrations/illu_panelB.svg';
import imageKeychainRemote from '../../../Content/images/illustrations/illu_keychain.png';
import imagePanicPendant from '../../../Content/images/illustrations/illu_panicPendant.svg';
import imagePanicPendantRE103 from '../../../Content/images/illustrations/illu_panicPendant2.png';
import imageFreezeSensor from '../../../Content/images/illustrations/illu_freezeSensor.svg';
import imageDoorbellCamera from '../../../Content/images/doorbell_camera_green.svg';
import imageOutdoorCamera from '../../../Content/images/outdoor_camera_green.svg';
import imageWirelessCamera from '../../../Content/images/wireless_camera_green.svg';
import cleaningCloth from '../../../Content/images/illustrations/illu_cloth.svg';
import plasticRuler from '../../../Content/images/illustrations/illu_ruler.svg';
import imageFloodSensor from '../../../Content/images/illustrations/illu_floodSensor.png';

import boxImageResolutionDoorWindowSensor from '../../../Content/images/illustrations/illu_newBox_ResolutionDoorWindowSensor.png';
import boxImageDoorWindowSensor from '../../../Content/images/illustrations/illu_newBox_DoorWindowSensor.png';
import boxImageGlassBreakSensor from '../../../Content/images/illustrations/illu_newBox_GlassBreakSensor.png';
import boxImageGlassBreakSensorRE129 from '../../../Content/images/illustrations/illu_newBox_GlassBreakSensor3.png';
import boxImageMotionSensor from '../../../Content/images/illustrations/illu_newBox_MotionSensor.png';
import boxImageSmokeHeatSensor from '../../../Content/images/illustrations/illu_newBox_SmokeHeatSensor.png';
import boxImageSmokeHeatSensorRE114 from '../../../Content/images/illustrations/illu_newBox_SmokeHeatSensor3.png';
import boxImageGarageDoorSensor from '../../../Content/images/illustrations/illu_lineart_Garage Door Sensor.png';

import smartLock from '../../../Content/images/illustrations/lock_illustration_green.svg';
import yaleSmartLock from '../../../Content/images/illustrations/yale_lock_illustration_green.png';

/*
  <Date>1/17/2018</Date>
  <Author>Jhon Yi</Author>
  <Description>To add specific images to a specific sku, Under the Image property, add a child with the key as the sku and
    the value as the imported image, if not the default is selected for all skus in product category
   </Description>
  <Example>Image: { RE103 : imagePanicPendant2 }</Example>
*/
export default [
    {
        Name: 'CarbonMonoxideSensor',
        Image: {
          default: imageCarbonMonoxideSensor,
          RE113: imageCarbonMonoxideSensorRE113,
          RE115: imageCarbonMonoxideSensorRE113
        }
    },
    {
        Name: 'DoorWindowSensor',
        Image: {
            default: imageDoorWindowSensor,
            RE122: imageDoorWindowSensorRE122
        },
        BoxImage: {
            default: boxImageDoorWindowSensor,
            RE122: boxImageResolutionDoorWindowSensor
        }
    },
    {
        Name: 'FreezeSensor',
        Image: {
            default: imageFreezeSensor
        }
    },
    {
        Name: 'GarageDoorSensor',
        Image: {
            default: imageGarageDoorSensor,
            RE106: imageResolutionGarageDoorSensor
        },
        BoxImage: {
            default: boxImageGarageDoorSensor
        }
    },
    {
        Name: 'GlassBreakSensor',
        Image: {
            default: imageGlassBreakSensor,
            ['60-873-95']: imageGlassBreakSensorV2,
            RE129: imageGlassBreakSensorV3
        },
        BoxImage: {
            default: boxImageGlassBreakSensor,
            RE129: boxImageGlassBreakSensorRE129
        }
    },
    {
        Name: 'ImageSensor',
        Image: {
            default: imageImageSensor
        }
    },
    {
        Name: 'IQPanel',
        Image: {
            default: imageIQPanel
        }
    },
    {
        Name: 'KeychainRemote',
        Image: {
            default: imageKeychainRemote
        }
    },
    {
        Name: 'LongRangeDoorWindowSensor',
        Image: {
            default: imageDoorWindowSensor,
            RE101: imageLongRangeDoorWindowSensorRE101
        }
    },
    {
        Name: 'MotionSensor',
        Image: {
            default: imageMotionSensor
        },
        BoxImage: {
            default: boxImageMotionSensor
        }
    },
    {
        Name: 'PanicPendant',
        Image: {
            default: imagePanicPendant,
            RE103: imagePanicPendantRE103
        }
    },
    {
        Name: 'RecessedDoorSensor',
        Image: {
            default: imageRecessedDoorSensor
        }
    },
    {
        Name: 'SmokeHeatSensor',
        Image: {
            default: imageSmokeHeatSensor,
            ["SDX-135Z"]: imageSmokeHeatSensorSDX135Z,
            RE114: imageSmokeHeatSensorRE114
        },
        BoxImage: {
            default: boxImageSmokeHeatSensor,
            RE114: boxImageSmokeHeatSensorRE114
        }
    },
    {
        Name: 'WaterFloodSensor',
        Image: {
            default: imageWaterSensor
        }
    },
    {
        Name: 'FloodSensor',
        Image: {
            default: imageFloodSensor
        }
    },
    {
        Name: 'XTPanel',
        Image: {
            default: imageXTPanel
        }
    },
    {
        Name: 'YardWindowSign',
        Image: {
            default: imageYardSign
        }
    },
    {
        Name: 'DoorbellCameraSilver',
        Image: {
            default: imageDoorbellCamera
        }
    },
    {
        Name: 'DoorbellCameraBronze',
        Image: {
            default: imageDoorbellCamera
        }
    },
    {
        Name: 'OutdoorCamera',
        Image: {
            default: imageOutdoorCamera
        }
    },
    {
        Name: 'WirelessCamera',
        Image: {
            default: imageWirelessCamera
        }
    },
    {
        Name: 'CleaningCloth',
        Image: {
            default: cleaningCloth
        }
    },
    {
        Name: 'PlasticRuler',
        Image: {
            default: plasticRuler
        }
    },
    {
        Name: 'SmartLockAgedBronze',
        Image: {
            default: yaleSmartLock,
            'YRD156-ZW2-0BP': yaleSmartLock
        }
    },
    {
        Name: 'SmartLockBrightBrass',
        Image: {
            default: yaleSmartLock,
            'YRD156ZW2-605': yaleSmartLock
        }
    },
    {
        Name: 'SmartLockSatinNickel',
        Image: {
            default: yaleSmartLock,
            'YRD156-ZW2-619': yaleSmartLock
        }
    }
];