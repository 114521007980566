import * as _ from 'lodash';

import template from './connected.html';

class ConnectedComponent {
    /*@ngInject*/
    constructor($state, actionContext, constants) {
        // services
        this.$state = $state;
        this.actionContext = actionContext;
        this.constants = constants;
    }

    $onInit() {
        this.actionContext.wifi.fetching(false);
        this.actionContext.panel.panelLoaded(true);
    }
}

export default {
    template: template,
    bindings: {},
    controller: ConnectedComponent
};