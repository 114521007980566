export const Z_WAVE_HANDOFF_TO_ADC = 'Z_WAVE_HANDOFF_TO_ADC';
export const Z_WAVE_HANDBACK_FROM_ADC = 'Z_WAVE_HANDBACK_FROM_ADC';
export const Z_WAVE_INITIALIZE = 'Z_WAVE_INITIALIZE';

class ZwaveActions {
    /* @ngInject */
    constructor($ngRedux, constants) {
        this.$ngRedux = $ngRedux;
        this.constants = constants;
    }

    zWaveHandOff() {
        this.$ngRedux.dispatch({
            type: Z_WAVE_HANDOFF_TO_ADC,
            metadata: {
                sectionType: this.constants.sectionTypes.sensor,
                persist: true,
                isHydratable: true
            }
        });
    }

    zWaveHandBack() {
        this.$ngRedux.dispatch({
            type: Z_WAVE_HANDBACK_FROM_ADC,
            metadata: {
                sectionType: this.constants.sectionTypes.sensor,
                persist: true,
                isHydratable: true
            }
        });
    }

    zWaveInitialState() {
        this.$ngRedux.dispatch({
            type: Z_WAVE_INITIALIZE
        });
    }
}

export default ZwaveActions;