import template from './panicPendantModal.html';
import * as _ from 'lodash';

class PanicPendantModalController {
    /*@ngInject*/
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
        this.productImage = '';
        this._ = _;
        this.images = {
            old: {
                src: '/Content/images/illustrations/illu_panicPendant.svg'
            },
            new: {
                src: '/Content/images/illustrations/illu_panicPendant2.png'
            }
        }
    }

    $onInit() {
        this.getProductSku();
    }

    getProductSku() {
        return (this._.find(this.$ngRedux.getState().system.devices.sensorsByID, { ProductSku: 'RE103' })) ? this.productImage = this.images.new : this.productImage = this.images.old;
    }
}

export default {
    template: template,
    bindings: {},
    controller: PanicPendantModalController
};