import * as _ from 'lodash';
import moment from 'moment';

class SystemSensorService {
    /*@ngInject*/
    constructor($log, $timeout, $q, Restangular) {
        // services
        this.$log = $log;
        this.$timeout = $timeout;
        this.$q = $q;
        this.restangular = Restangular;

        this.restangular.addResponseInterceptor((data, operation, what) => {
            switch (what) {
            case 'sensors':
            case 'status':
                this._processObject(data);
            }
            return data;
        });
    }

    /**
     * Get all sensors attached to the current account.
     * 
     * @param {boolean=} includeNotConnected
     * @param {boolean=} includeNonSensors
     * 
     * @returns {} 
     */
    getSensors(isInitialOrder, includeNonSensors = false, includeNotConnected = true) {
        return this.restangular
            .one('system')
            .all('sensors')
            .withHttpConfig({ cache: false })
            .getList({
                includeNotConnected: includeNotConnected,
                includeNonSensors: includeNonSensors,
                isInitialOrder: isInitialOrder
            })
            .then((response) => {
                if (isInitialOrder) {
                    let productGroups = _.groupBy(response.plain(), 'ProductSku');
                    _.forEach(productGroups, (productGroup) => {
                        productGroup = _.sortBy(productGroup, (product) => {
                            return product.SensorNumber ? product.SensorNumber : -1;
                        });
                        _.forEach(productGroup, (product, index) => {
                            product.GroupOrder = index + 1;
                        });
                    });
                    return _.flatten(_.values(productGroups));
                }
                return response.plain();
            });
    }

    /**
     * Gets the last status that the sensor reported.
     * 
     * @param {} sensorId 
     * @returns {} 
     */
    getSensorStatus(sensorId) {
        return this.restangular
            .one('system')
            .one('sensors', sensorId)
            .withHttpConfig({ cache: false })
            .customGET('status')
            .then((response) => {
                return response.plain();
            });
    }

    /**
     * Gets a list of eligible sensor names for customers with XT Panels.
     * 
     * @returns {} 
     */
    getEligibleNames() {
        return this.restangular
            .one('system')
            .all('sensors')
            .customGET('eligible-names')
            .then((response) => {
                return response.plain();
            });
    }

    /**
     * Disable the provided sensor. Disabled sensors will no longer report events.
     * 
     * @param {} sensor 
     * @returns {} 
     */
    disableSensor(sensor) {
        return this.restangular
            .all('system')
            .one('sensors', sensor.SensorID)
            .customPUT(null, 'disable');
    }

    /**
     * Update the given sensor.
     * 
     * NOTE: This endpoint only supports updating the name of the sensor. All other 
     * changes will be ignored.
     * 
     * @param {} sensor 
     * @returns {} 
     */
    updateSensor(sensor) {
        return this.restangular
            .all('system')
            .one('sensors', sensor.SensorID)
            .customPUT(sensor)
            .then((response) => {
                return response.plain();
            });
    }

    getSystemControlDevices() {
        return this.restangular
            .all('system')
            .one('sensors')
            .one('system-control-devices')
            .withHttpConfig({ cache: false })
            .get();
    }

    // Recursively process an object converting all dates and date like properties to moment objects.
    _processObject(obj) {
        // https://www.safaribooksonline.com/library/view/regular-expressions-cookbook/9781449327453/ch04s07.html
        const dateRegex = /^([0-9]{4})-(1[0-2]|0[1-9])/;
        if (_.isNil(obj) || this._isLeaf(obj)) {
            return;
        }

        for (var prop in obj) {
            if (!obj.hasOwnProperty(prop)) {
                continue;
            }

            let value = obj[prop];
            if (_.isDate(value) || _.isString(value) && dateRegex.test(value)) {
                obj[prop] = moment.utc(value);
            } else {
                this._processObject(value);
            }
        }
    }

    _isLeaf(node) {
        return _.isString(node) || _.isNumber(node) || _.isDate(node) || moment.isMoment(node);
    }
}

export default SystemSensorService;