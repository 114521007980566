import template from "./disconnectPanel.html";

class DisconnectPanelController {
    /*@ngInject*/
    constructor($ngRedux, $state, constants, $log, SharedState, segment, dataContext, actionContext, $interval) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.showModal = false;
        this.SharedState = SharedState;
        this.isLoading = false;
        this.showhelpModal = false;
        this.segment = segment;
        this.$log = $log;
        this.dataContext = dataContext;
        this.actionContext = actionContext;
        this.$interval = $interval;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.actionContext.panel.fetchPanelVersion().then((result) => {
            this.oldPanelSerial = result.SerialNumber;
        });
    }

    onContinue() {
        this.setIsLoading(true);
        this.actionContext.modSwap.designateSwapType(this.constants.swapType.mod);
        this.executeSwap();
    }

    setIsLoading(isLoading) {
        this.isLoading = isLoading;
    }

    executeSwap() {
        this.segment.registerEvent(this.constants.events.segment.panelSwapStartSwap, {});
        this.actionContext.modSwap.onModSwapStarted();
        this.dataContext.modSwap.swapPanel().then((result) => {
            if (result.SwapResult.Success === true) {
                this.$log.info('Successfully swapped the panel');
                this.checkSwapStatus();
                this.setActivationDate();
            } else {
                this._onCheckPanelFailure(result.SwapResult.ErrorMessage);
            }
        })
            .catch((error) => {
                this._onCheckPanelFailure(error);
            });
    }

    setActivationDate() {
        this.dataContext.account.order.getSwapOrderProduct().then((orderProduct) => {
            // set order product as activated
            this.actionContext.order.setOrderProductActivated(orderProduct);
        }).catch((error) => {
            this.$log.error('Error getting the order product for swap order.', error);
        });
    }

    setHelpModal(showhelpModal) {
        this.showhelpModal = showhelpModal;
        if (showhelpModal) {
            this.SharedState.turnOn('helpPanelModal');
        }
    }

    checkSwapStatus() {
        this.actionContext.modSwap.onModSwapStatusCheckStarted();
        let currentPanelSerial = this.oldPanelSerial;
        let checkPanelVersionInterval = this.$interval(() => {
                this.actionContext.panel.fetchPanelVersion().then((result) => {
                    currentPanelSerial = result.SerialNumber;
                if ((result.PanelType === this.constants.panelVersions.climaxHub || result.PanelType === this.constants.panelVersions.iqPanel) &&
                    currentPanelSerial !== this.oldPanelSerial) {
                    this.actionContext.modSwap.onModSwapStatusCheckCompleted(this.oldPanelSerial, currentPanelSerial);
                    this.actionContext.app.fetchEligibleSensorNames();
                    this.setIsLoading(false);
                    this.$interval.cancel(checkPanelVersionInterval);
                    this.actionContext.modSwap.designateSwapType(this.constants.swapType.mod);
                    this.$state.go('instruction.place');
                }
            })
                .catch((error) => {
                    this.$interval.cancel(checkPanelVersionInterval);
                    this._onCheckPanelFailure(error);
                });
        },
            this.constants.ping.Swap.pollingInterval,
            this.constants.ping.Swap.maxIntervalCount);

        checkPanelVersionInterval.catch((message) => {
            if (message !== 'canceled') {
                this._onCheckPanelFailure(message);
            }
        });
    }

    _onCheckPanelFailure(message) {
        this.setIsLoading(false);
        this.setHelpModal(true);
        this.actionContext.modSwap.onModSwapStatusCheckFail();
        this.$log.error('Error occured while validating the panel swap', message);
    }

    _mapState(state) {
        let map = {};
        map.targetSku = state.account.airfxFlow.targetSku;
        map.sku = state.account.airfxFlow.sku;
        return _.cloneDeep(map);
    }
}

export default {
    template: template,
    bindings: {},
    controller: DisconnectPanelController
};