import * as _ from 'lodash';

export const FETCH_ADC_STATUS_SUCCESS = 'FETCH_ADC_STATUS_SUCCESS';
export const FETCH_RAPID_STATUS_SUCCESS = 'FETCH_RAPID_STATUS_SUCCESS';

const MAX_RETRIES = 5;

class PartnerActions {
    /*@ngInject*/
    constructor($log, $ngRedux, dataContext) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.dataContext = dataContext;
    }

    /**
     * Pings ADC to ensure they are reachable and adds the result to the application state. It is possible for a single 
     * ping to fail so this method should normally be called with retryOnFailure = true.
     * 
     * @param {boolean=} retryOnFailure A boolean that indicates if the request should be retried on failure.
     * @param {number} retryCount  A number that indicates the current retry attempt.
     * @returns {promise} A promise that returns undefined when resolved and an error when rejected. The result of the
     * ping can be found in the application state.
     */
    fetchAdcStatus(retryOnFailure = false, retryCount = 0) {
        return this.dataContext.partner.getAdcStatus().then((res) => {
            // If retry on failure is set don't mark ADC as down unless multiple pings fail.
            if (retryOnFailure === true && !res && retryCount < MAX_RETRIES) {
                return this.fetchAdcStatus(retryOnFailure, ++retryCount);
            }

            this.$ngRedux.dispatch({
                type: FETCH_ADC_STATUS_SUCCESS,
                payload: {
                    status: res === true
                }
            });
        }).catch((err) => {
            this.$log.error('Error fetching the current ADC status.', err);
        });
    }

    /**
     * Pings Rapid to ensure they are reachable and adds the result to the application state. It is possible for a single 
     * ping to fail so this method should normally be called with retryOnFailure = true.
     * 
     * @param {boolean=} retryOnFailure A boolean that indicates if the request should be retried on failure.
     * @param {number} retryCount  A number that indicates the current retry attempt.
     * @returns {promise} A promise that returns undefined when resolved and an error when rejected. The result of the
     * ping can be found in the application state.
     */
    fetchRapidStatus(retryOnFailure = false, retryCount = 0) {
        return this.dataContext.partner.getRapidStatus().then((res) => {
            // If retry on failure is set don't mark Rapid as down unless multiple pings fail.
            if (retryOnFailure === true && !res && retryCount < MAX_RETRIES) {
                return this.fetchRapidStatus(retryOnFailure, ++retryCount);
            }

            this.$ngRedux.dispatch({
                type: FETCH_RAPID_STATUS_SUCCESS,
                payload: {
                    status: res === true
                }
            });
        }).catch((err) => {
            this.$log.error('Error fetching the current Rapid status.', err);
        });
    }

    /**
     * Creates a function that does the following when invoked:
     * 
     * Enable ADC interactive mode
     * 
     * @param {Object} $q
     * @param {Object} actionContext 
     * @returns {function.<promise>} A function that returns a promise when invoked.
     */
    createKeepAliveFunction($q, actionContext) {
        return () => {
            return actionContext.panel.enableInteractiveMode();
        };
    }

    /**
     * Creates a function that does the following when invoked:
     * 
     * Ping ADC
     * Ping Rapid
     * 
     * @param {Object} $q
     * @param {Object} actionContext 
     * @returns {function.<promise>} A function that returns a promise when invoked.
     */
    createPingFunction($q, actionContext) {
        return () => {
            return $q.all([
                actionContext.partner.fetchAdcStatus(/*retryOnFailure*/ true),
                actionContext.partner.fetchRapidStatus(/*retryOnFailure*/ true)
            ]);
        };
    }

    /**
     * Creates a function that when invoked checks the status of partner services and returns true if the services are
     * reachable.
     * 
     * @param {Object} $ngRedux 
     * @returns {function} A function that when invoked returns true if the partner services are reachable; otherwise, 
     * false. 
     */
    createCheckStatusFunction($ngRedux) {
        return () => {
            const state = $ngRedux.getState();
            const isAdcAvailable = state.application.isAdcAvailable;
            const isRapidAvailable = state.application.isRapidAvailable;
            return isAdcAvailable && isRapidAvailable;
        };
    }

    /**
     * Creates a fnction that when invoked redirects the user to the provided error state.
     * 
     * @param {Object} $state 
     * @param {string} errorState
     * @returns {function} A function that when invoked redirects the user to an error state.
     */
    createReactToStatusFunction($state, errorState) {
        return () => {
            $state.go(errorState);
        };
    }

    /**
     * Creates a function that can be passed to $rootScope.$on() to watch for changes to partner services statuses. When
     * invoked this function will call checkFn and if it returns false it will call reactFn.
     * 
     * @param {function} checkFn 
     * @param {function} reactFn 
     * @param {string} errorState 
     * @returns {function} A function that can be passed to $rootScope.$on()
     */
    createWatchStatusFunction(checkFn, reactFn, errorState) {
        return (event, toState) => {
            if (!_.isFunction(checkFn)) throw 'checkFn must be a function.';
            if (!_.isFunction(reactFn)) throw 'reactFn must be a function.';

            if (toState.name === errorState) {
                return null;
            }

            if (checkFn()) {
                return null;
            }

            event.preventDefault();
            return reactFn();
        };
    }
}

export default PartnerActions;