import * as _ from 'lodash';
import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import './sensors.scss';

import commonModule from '../common';
import menuModule from '../menu';

import componentsModule from './components';
import installationModule from './installation';
import modalModule from './modals';
import itemModule from './item';
import camerasModule from './cameras';
import locksModule from './locks';
import exceptionDeviceModule from './sensorListExceptionDevice';

import sensorCompleteComponent from './complete.component';
import sensorHomeComponent from './home.component';
import sensorListItemComponent from './list-item.component';
import sensorListComponent from './list.component';
import sensorReivewComponent from './review.component';
import sensorSkippedReviewComponent from './skipped-review.component';
import systemActivationComponent from './activation.component';

export default angular
    .module('fp:activationswizard:sensor', [
        'ui.router',
        ngRedux,
        itemModule.name,
        commonModule.name,
        menuModule.name,
        componentsModule.name,
        installationModule.name,
        modalModule.name,
        camerasModule.name,
        locksModule.name,
        exceptionDeviceModule.name
    ])
    .config( /*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            .state('sensor',
                {
                    abstract: true,
                    parent: 'secure',
                    url: '/sensor',
                    template: '<ui-view></ui-view>',
                    data: {
                        next: /*@ngInject*/ ($state, appActions) => {
                            appActions.completeModule(constants.sectionTypes.sensor);
                            appActions.completeStep('sensor');
                            appActions.goToStepFromSensorComplete();
                        },
                        prev: /*@ngInject*/ ($state) => {
                            $state.go('menu.home');
                        },
                        sectionType: constants.sectionTypes.sensor,
                        theme: 'app-theme-default-navbar-gray'
                    },
                    resolve: {
                        isMasterCodeComplete: /*@ngInject*/ (grant, $q, isIQPanel, setupComplete) => {
                            if (isIQPanel) {
                                return $q.resolve(true);
                            }

                            return grant.only({
                                test: 'isMasterCodeComplete',
                                state: 'mastercode.home'
                            });
                        },
                        isWifiComplete: /*@ngInject*/ (grant, $q, isIQPanel, setupComplete) => {
                            if (!isIQPanel) {
                                return $q.resolve(true);
                            }

                            return grant.only({
                                test: 'isWifiComplete',
                                state: 'wifi.home'
                            });
                        },
                        sensorGrantCheckComplete: /*@ngInject*/ (isMasterCodeComplete, isWifiComplete) => {

                        },
                        sensorLoadComplete: /*@ngInject*/ ($timeout, $state, $q, actionContext,
                            sensorGrantCheckComplete, fpStore, constants, appActions, dataContext) => {
                            actionContext.app.tryStartLoad();
                            if (appActions.isInitialWithAdditional())
                                return actionContext.device.fetchSensorsForInitialWithAdditional()
                                    .catch(() => {
                                        return $timeout(() => {
                                            actionContext.common.error(constants.actionTypes.error,
                                                constants.sectionTypes.sensor,
                                                'Load sensors encountered a fatal error.');
                                            $state.go('error');
                                            return $q.reject();
                                        });
                                    });
                            else
                                return actionContext.device.fetchSensors()
                                    .catch(() => {
                                        return $timeout(() => {
                                            actionContext.common.error(constants.actionTypes.error,
                                                constants.sectionTypes.sensor,
                                                'Load sensors encountered a fatal error.');
                                            $state.go('error');
                                            return $q.reject();
                                        });
                                    });
                        },
                        setAdditionalEquipment: /*@ngInject*/ (actionContext) => {
                            return actionContext.order.hasAdditionalEquipment(true);
                        },
                        sensorSetupComplete: /*@ngInject*/ (sensorGrantCheckComplete, sensorLoadComplete,
                            setAdditionalEquipment) => {

                        }
                    }
                })
            .state('sensor.home',
                {
                    url: '/',
                    template: '<aw-sensor-home></aw-sensor-home>',
                    onEnter: /*@ngInject*/(appActions) => {
                        appActions.startModule(constants.sectionTypes.sensor);
                    },
                    resolve: {
                        hasOnlyLocks: /*@ngInject*/ (grant, actionContext) => {
                            // Go to the sensor list page if the item on the list is just locks
                            return actionContext.order.isInitialOrder() || grant.except({
                                test: 'hasOnlyLocks',
                                state: 'sensor.list'
                            });
                        }
                    },
                    data: {
                        persist: true
                    }
                })
            .state('sensor.complete',
                {
                    url: '/complete',
                    template: '<aw-sensor-complete></aw-sensor-complete>',
                    data: {
                        theme: 'app-theme-green',
                        persist: true
                    }
                })
            .state('sensor.activation', {
                url: '/activation',
                template: '<aw-system-activation></aw-system-activation>',
                data: {
                    theme: 'app-theme-green',
                    persist: false
                },
                resolve: {
                    refreshWifi: /*@ngInject*/ (dataContext, $log) => {
                        //Refresh Wifi
                        dataContext.wifi.refreshWifi().then((result) => {
                            $log.info('Successfully refreshed the wi-fi...');
                        })
                            .catch((error) => {
                                $log.error('Error occurred while refreshing the wifi', error);
                            });
                    },
                    hasAdditionalOrder: /*@ngInject*/ (grant, actionContext, $state, refreshWifi) => {
                        return grant.except({
                            test: 'hasAdditionalOrder',
                            state: 'menu.home'
                        });
                    }
                }
            })
            .state('sensor.list', {
                url: '/list:sku',
                template: '<aw-sensor-list></aw-sensor-list>',
                data: {
                    navbar: {
                        top: true,
                        bottom: false
                    },
                    persist: false
                },
                resolve: {
                    // do not remove sensorSetupComplete from injected parameters
                    isSensorSetupComplete: /*@ngInject*/ (grant, sensorSetupComplete) => {
                        // Go to the skipped review page if the sensor setup is complete.
                        return grant.except({
                            test: 'isSensorSetupComplete',
                            state: 'sensor.skipped-review'
                        });
                    }
                }
            })
            .state('sensor.review', {
                url: '/review',
                template: '<aw-sensor-review></aw-sensor-review>',
                resolve: {
                    // do not remove sensorSetupComplete from injected parameters
                    isSensorSetupComplete: /*@ngInject*/ (grant, sensorSetupComplete) => {
                        // Go to the sensors list page if setup has not been completed.
                        return grant.only({
                            test: 'isSensorSetupComplete',
                            state: 'sensor.list'
                        });
                    }
                },
                data: {
                    persist: false
                }
            })
            .state('sensor.skipped-review', {
                url: '/skipped-review',
                template: '<aw-sensor-skipped-review></aw-sensor-skipped-review>',
                resolve: {
                    // do not remove sensorSetupComplete from injected parameters
                    hasSkippedSensors: /*@ngInject*/ (grant, $ngRedux, appActions, sensorSetupComplete) => {
                        if ($ngRedux.getState(!$ngRedux.getState().account.user.isInitialOrder)) {
                            appActions.completeModule(constants.sectionTypes.sensor);
                            appActions.completeStep('sensor');
                            appActions.goToStepFromSensorComplete();
                            return true;
                        } else {
                            // Go to the complete page if their are no skipped sensors.
                            return grant.only({
                                test: 'hasSkippedSensors',
                                state: 'sensor.complete'
                            });
                        }
                    }
                },
                data: {
                    persist: false
                }
            })
            .state('sensor.cameras-home', {
                url: '/cameras',
                template: '<aw-cameras data-instructions="false" data-complete="true"></aw-cameras>',
                resolve: {
                    hasCamera: /*@ngInject*/ (grant) => {
                        return grant
                            .only({
                                test: 'hasCamera',
                                state: 'sensor.locks-home'
                            });
                    }
                },
                data: {
                    persist: false
                }
            })
            .state('sensor.cameras-instructions', {
                url: '/cameras/instructions',
                template: '<aw-cameras data-instructions="true" data-final-step="true" data-theme="green"></aw-cameras>',
                resolve: {
                    hasCamera: /*@ngInject*/ (grant) => {
                        return grant
                            .only({
                                test: 'hasCamera',
                                state: 'sensor.locks-home'
                            });
                    }
                },
                data: {
                    theme: 'app-theme-green',
                    persist: false
                }
            })
            .state('sensor.locks-home', {
                url: '/locks',
                params: { isAdditional: false },
                template: '<aw-locks></aw-locks>',
                resolve: {
                    hasLock: /*@ngInject*/ (grant, $ngRedux, constants) => {
                        let failState = !$ngRedux.getState().account.user.isInitialOrder ? constants.routerStates.sensorList : constants.routerStates.menuHome;
                        return grant
                            .only({
                                test: 'hasLock',
                                state: failState
                            });
                    }
                },
                data: {
                    theme: 'app-theme-green',
                    persist: false
                }
            })
            .state('sensor.locks-setup', {
                url: '/locks-setup',
                params: { isAdditional: false },
                template: '<aw-locks-setup></aw-locks-setup>',
                resolve: {
                    hasLock: /*@ngInject*/ (grant, $ngRedux, constants) => {
                        let failState = !$ngRedux.getState().account.user.isInitialOrder ? constants.routerStates.sensorList : constants.routerStates.menuHome;
                        return grant
                            .only({
                                test: 'hasLock',
                                state: failState
                            });
                    }
                },
                data: {
                    theme: 'app-theme-default-navbar-gray',
                    persist: false
                }
            })
            .state('sensor.keychain-remote', {
                url: '/keychainRemote',
                template: '<aw-keychain-remote></aw-keychain-remote>',
                params: {
                    sensor: null
                },
                data: {
                    theme: 'app-theme-default-navbar-gray',
                    persit: false
                }
            })
            .state('sensor.keychain-remote-overview', {
                url: '/keychainRemoteOverview',
                template: '<aw-keychain-remote-overview></aw-keychain-remote-overview>',
                params: {
                    sensor: null
                },
                data: {
                    theme: 'app-theme-default-navbar-gray',
                    persit: false
                }
            })
            .state('sensor.panic-pendant', {
                url: '/panicPendant',
                template: '<aw-panic-pendant></aw-panic-pendant>',
                params: {
                    sensor: null
                },
                data: {
                    theme: 'app-theme-default-navbar-gray',
                    persist: false
                }
            })
            .state('sensor.panic-pendant-overview', {
                url: '/panicPendantOverview',
                template: '<aw-panic-pendant-overview></aw-panic-pendant-overview>',
                params: {
                    sensor: null
                },
                data: {
                    theme: 'app-theme-default-navbar-gray',
                    persist: false
                }
            });
    })
    .component('awSensorHome', sensorHomeComponent)
    .component('awSensorComplete', sensorCompleteComponent)
    .component('awSystemActivation', systemActivationComponent)
    .component('awSensorList', sensorListComponent)
    .component('awSensorReview', sensorReivewComponent)
    .component('awSensorSkippedReview', sensorSkippedReviewComponent)
    .component('awSensorListItem', sensorListItemComponent);