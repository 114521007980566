import template from './editContact.html';
import './editContact.scss';

class EditContactController {
    /*@ngInject*/
    constructor (constants, actionContext) {
        this.constants = constants;
        this.actionContext = actionContext;
    }
}

export default {
    template: template,
    bindings: {
        index: '<',
        canDelete: '<',
        canPromote: '<',
        canDemote: '<',
        contact: '=',
        label: '<',
        onDelete: '&',
        onPromote: '&',
        onDemote: '&'
    },
    controller: EditContactController
};