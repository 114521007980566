import * as _ from 'lodash';

export const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY';
export const SET_CURRENT_SKU = 'SET_CURRENT_SKU';
export const SET_CURRENT_DLCODE = 'SET_CURRENT_DLCODE';
export const SET_TARGET_SKU = 'SET_TARGET_SKU';

class AirfxFlowActions {
    /*@ngInject*/
    constructor($log, $ngRedux, constants) {
        this.$ngRedux = $ngRedux;
        this.unsubscribe = $ngRedux.connect(this._mapState)(this);
        this.constants = constants;
    }

    setCurrentCategory(category) {
        this.$ngRedux.dispatch({
            type: SET_CURRENT_CATEGORY,
            payload: {
                category: category
            }
        });
    }

    setCurrentSku(sku) {
        this.$ngRedux.dispatch({
            type: SET_CURRENT_SKU,
            payload: {
                sku: sku
            }
        });
    }

    setTargetSku(targetSku) {
        this.$ngRedux.dispatch({
            type: SET_TARGET_SKU,
            payload: {
                targetSku: targetSku
            }
        });
    }

    setCurrentDlcode(dlcode) {
        this.$ngRedux.dispatch({
            type: SET_CURRENT_DLCODE,
            payload: {
                dlcode: dlcode
            }
        });
    }

    isKeypad() {
        return _.includes(this.constants.keypads.skus, this.sku);
    }

    _mapState(state) {
        return {
            category: _.map(state.account.airfxFlow.category),
            sku: state.account.airfxFlow.sku,
            targetSku: state.account.airfxFlow.targetSku,
            dlcode: _.map(state.account.airfxFlow.dlcode)
        };
    }
}

export default AirfxFlowActions;