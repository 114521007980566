import './setupActivationCode.scss';
import template from './setupActivationCode.html';
import * as _ from 'lodash';

class SetupActivationCodeComponent {
    /*@ngInject*/
    constructor($ngRedux, $state, constants, actionContext, SharedState) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.actionContext = actionContext;
        this.SharedState = SharedState;
    }

    submitCode(form) {
        if (form.$dirty && !form.isBusy && !form.$invalid) {
            this.onSubmit({ activationCode: this.code.replace(/-/g, ""), form: form })
                .then(() => {},
                    (error) => {
                        this.hasCodeError = true;
                        this.code = '';
                    });
            form.$setPristine();
        }
    }

    submitSid(form) {
        if (form.$dirty && !form.isBusy && !form.$invalid) {
            this.onSubmit({ activationCode: this.sid, form: form })
                .then(() => { },
                    (error) => {
                        this.hasSidError = true;
                        this.sid = '';
                    });
            form.$setPristine();
        }
    }
    setShowSid(showSid) {
        this.showSid = showSid;
    }
}

export default {
    template: template,
    bindings: {
        isBusy: '<',
        onSubmit: '&'
    },
    controller: SetupActivationCodeComponent
};