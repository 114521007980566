import * as _ from 'lodash';
export const MASTER_CODE_FETCH_REQUEST = 'MASTER_CODE_FETCH_REQUEST';
export const MASTER_CODE_FETCH_FAILURE = 'MASTER_CODE_FETCH_FAILURE';
export const MASTER_CODE_FETCH_SUCCESS = 'MASTER_CODE_FETCH_SUCCESS';

export const MASTER_CODE_UPDATE_REQUEST = 'MASTER_CODE_UPDATE_REQUEST';
export const MASTER_CODE_UPDATE_FAILURE = 'MASTER_CODE_UPDATE_FAILURE';
export const MASTER_CODE_UPDATE_SUCCESS = 'MASTER_CODE_UPDATE_SUCCESS';

export const USER_CODE_UPDATE_REQUEST = 'USER_CODE_UPDATE_REQUEST';
export const USER_CODE_UPDATE_FAILURE = 'USER_CODE_UPDATE_FAILURE';
export const USER_CODE_UPDATE_SUCCESS = 'USER_CODE_UPDATE_SUCCESS';

export const USER_CODE_DELETE_REQUEST = 'USER_CODE_DELETE_REQUEST';
export const USER_CODE_DELETE_FAILURE = 'USER_CODE_DELETE_FAILURE';
export const USER_CODE_DELETE_SUCCESS = 'USER_CODE_DELETE_SUCCESS';

class CodeActions {
    /*@ngInject*/
    constructor($log, $ngRedux, $timeout, $q, constants, dataContext) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
        this.$q = $q;
        this.constants = constants;
        this.dataContext = dataContext;
        $ngRedux.connect(this._mapState.bind(this))(this);
    }

    /**
    * Fetches the current master code for the user's panel and adds it to the application
    * state.
    * 
    * @returns {promise} A promise that returns undefined if resolved and an error if rejected.
    */
    fetchCode() {
        this._onFetchCode();

        return this.dataContext.code.getUserCodes()
            .then((result) => {
                let masterCode = null;
                if (!_.isNil(result) && result.length > 0) {
                    masterCode = result[0].Code;
                }

                this._onFetchCodeSuccess(masterCode, result);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error fetching the customer\s user codes.', error);
                this._onFetchCodeFailure(error);
                return this.$q.reject('fetchCode');
            });
    }

    /**
     * Update the panel's master code.
     * 
     * @param {string} masterCode A string the represents the new master code.
     * @returns {promise} A promise that returns undefined if resolved and an error if rejected.
     */
    updateMasterCode(masterCode) {
        this._onUpdateMasterCode();

        return this.dataContext.code.putMasterCode(masterCode)
            .then(() => {
                this._onUpdateMasterCodeSuccess(masterCode);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error updating the customer\'s master code', error);
                this._onUpdateMasterCodeFailure(error);
                return this.$q.reject(error);
            });
    }

    
    /**
     * Add/Delete the panel's User codes. Need to loop through codes.
     * 
     * @param {string} codes list of codes to add.
     * @returns {promise} A promise that returns undefined if resolved and an error if rejected.
     */
    editUserCodes(codes) {
        this._onUpdateUserCodeRequest();

        let promise = this.$q.resolve();
        let deleteCodes = _.filter(codes, (code) => { return code.Code === null; });
        let addCodes = _.filter(codes, (code) => { return code.IsNew === true; });

        _.forEach(deleteCodes, (code) => {
            promise = promise.then(() => {
                return this._deleteUserCode(code);
            });
        });

        _.forEach(addCodes, (code) => {
            delete code.IsNew;
            promise = promise.then(() => {
                return this._addUserCodes(code);
            });
        });

        promise = promise.then(() => {
            return this.fetchCode();
        });

        return promise;
    }

    /**
     * checks to see if the code is part of the blacklist
     * 
     * @param {string} code A string the represents the user code.
     * @returns true of false.
     */
    isBlackListCode(code) {
        let isBlacklistCode = false;
            _.forEach(this.panelType === this.constants.panelVersions.xtPanel ?
                this.constants.codes.blackListCodes.xtPanel :
                this.constants.codes.blackListCodes.iqPanel, (blackListCode) => {
                    if (blackListCode === _.padStart(code, 4, '0'))
                        isBlacklistCode = true;
            });
        return isBlacklistCode;
    }

    /**
     * checks to see if the code is part of the insecurecode list
     * 
     * @param {string} code A string the represents the user code.
     * @returns true of false.
     */
    isInsecureCode(code) {
        return _.includes(this.constants.codes.insecureCodes, code);        
    }

    /**
     * edits user codes, we are using this for delete, unfortunately there is no delete so we just have to update the code and set the userid to null
     * 
     * @param {string} code A string the represents the user code.
     * @returns {promise} A promise that returns undefined if resolved and an error if rejected.
     */
    _deleteUserCode(code) {
        this._onDeleteUserCodeRequest();
        return this.dataContext.code.putUserCode(code)
              .then(() => {
                    return this._onDeleteUserCodeSuccess(code);
              })
              .catch((error) => {
                  this.$log.error('Error deleting the customer\'s user code', error);
                  this._onDeleteUserCodeFailure(error);
                  return this.$q.reject(error);
              });
    }

     /**
     * Single save call for adc to save user code.
     * 
     * @param {string} code A string the represents the new user code.
     * @returns {promise} A promise that returns undefined if resolved and an error if rejected.
     */
    _addUserCodes(code) {
        return this.dataContext.code.postUserCode(code)
                .then(() => {
                    return this._onUpdateUserCodeSuccess(code);
                })
                .catch((error) => {
                    this.$log.error('Error updating the customer\'s user code', error);
                    this._onUpdateUserCodeFailure(error);
                    return this.$q.reject(error);
                });
    }

    _onFetchCode() {
        this._dispatch({
            type: MASTER_CODE_FETCH_REQUEST
        });
    }

    _onFetchCodeFailure(error) {
        this._dispatch({
            type: MASTER_CODE_FETCH_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onFetchCodeSuccess(masterCode, codes){
        this._dispatch({
            type: MASTER_CODE_FETCH_SUCCESS,
            payload: {
                masterCode: masterCode,
                codes: codes
            }
        });
    }

    _onUpdateMasterCode() {
        this._dispatch({
            type: MASTER_CODE_UPDATE_REQUEST
        });
    }

    _onUpdateMasterCodeFailure(error) {
        this._dispatch({
            type: MASTER_CODE_UPDATE_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onUpdateMasterCodeSuccess(data) {
        this._dispatch({
            type: MASTER_CODE_UPDATE_SUCCESS,
            payload: {
                data: data
            },
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.mastercode,
                customDescription: true,
                description: ''
            }
        });
    }

    _onUpdateUserCodeRequest() {
        this._dispatch({
            type: USER_CODE_UPDATE_REQUEST
        });
    }

    _onUpdateUserCodeFailure(error) {
        this._dispatch({
            type: USER_CODE_UPDATE_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onUpdateUserCodeSuccess(code) {
        return this._dispatch({
            type: USER_CODE_UPDATE_SUCCESS,
            payload: {
                code:  code
            },
            metadata: {
                sectionType: this.constants.sectionTypes.usercode,
                persist: true,
                customDescription: true,
                description: ''
            }
        });
    }

    _onDeleteUserCodeRequest() {
        this._dispatch({
            type: USER_CODE_DELETE_REQUEST
        });
    }

    _onDeleteUserCodeFailure(error) {
        this._dispatch({
            type: USER_CODE_DELETE_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onDeleteUserCodeSuccess(code) {
        return this._dispatch({
            type: USER_CODE_DELETE_SUCCESS,
            payload: {
                code:  code
            },
            metadata: {
                sectionType: this.constants.sectionTypes.usercode,
                persist: true,
                description: `The user code was removed, FirstName: [${code.FirstName}] LastName: [${code.LastName}] Code: [${code.Code}]`
            }
        });
    }

    _dispatch(obj) {
        this.$ngRedux.dispatch(obj);
    }

    _mapState(state) {
        return {
            panelType: state.system.panel.type
        };
    }

}

export default CodeActions;