import '@babel/polyfill';
import './public-path';

import 'font-awesome/scss/font-awesome.scss';
import './vendor';
import './vendor.mobile-ui';

import * as angular from 'angular';
import * as _ from 'lodash';

import ngRedux from 'ng-redux';
import ngReduxUiRouter from 'redux-ui-router';

import './styles/app.scss';

import accessControlRun from './accessControl.run';
import { activationModule } from './activation';
import airfxModule from './airfx';
import boxCheckModule from './boxcheck';
import commonModule from './common';
import instructionModule from './instruction';
import loginModule from './login';
import mastercodeModule from './mastercode';
import menuModule from './menu';
import orderSelectorModule from './orderselector';
import panelModule from './panel';
import passwordModule from './password';
import predispatchModule from './predispatch';
import premisesModule from './premises';
import reduxConfig from './redux.config';
import reviewModule from './review';
import sensorModule from './sensor';
import shellModule from './shell';
import signageModule from './signage';
import testmodeModule from './testmode';
import trainingModule from './training';
import usercodeModule from './usercode';
import welcomeModule from './welcome';
import { wifiModule } from './wifi';
import zWaveModule from './zwave';
import passcodeModule from './passcode';
import tosModule from './tos';
import accountModule from './account';
import { duresscodeModule } from './duresscode';

const deps = [
    'ngAnimate',
    'ngSanitize',
    'ngMessages',
    'ui.router',
    'ui.router.$resolve',
    'ui.router.grant',
    'ui.bootstrap',
    'mobile-angular-ui',
    'mobile-angular-ui.gestures',
    'vcRecaptcha',
    ngRedux,
    ngReduxUiRouter,
    activationModule.name,
    airfxModule.name,
    boxCheckModule.name,
    commonModule.name,
    instructionModule.name,
    loginModule.name,
    mastercodeModule.name,
    orderSelectorModule.name,
    menuModule.name,
    panelModule.name,
    passwordModule.name,
    predispatchModule.name,
    premisesModule.name,
    reviewModule.name,
    sensorModule.name,
    shellModule.name,
    signageModule.name,
    trainingModule.name,
    testmodeModule.name,
    welcomeModule.name,
    wifiModule.name,
    usercodeModule.name,
    zWaveModule.name,
    passcodeModule.name,
    tosModule.name,
    accountModule.name,
    duresscodeModule.name
];

const app = angular
    .module('fp:activationswizard', deps)

    .config(/*@ngInject*/ ($urlRouterProvider: ng.ui.IUrlRouterProvider) => {
        // Calling $urlRouterProvider.otherwise() with the path that includes a failed resolve causes an infinite loop.
        // Replacing it with a call to $state.go() fixes the issue.
        // https://github.com/angular-ui/ui-router/issues/1022
        $urlRouterProvider.otherwise(($injector: ng.auto.IInjectorService) => {
            const $state: ng.ui.IStateService = $injector.get('$state');
            // $urlRouterProvider.otherwise() should replace the last history record to avoid a loop caused when the
            // browser back button is used right after otherwise() has performed a redirected.
            $state.go('welcome.home', null, { location: 'replace' });
        });
    })

    .config(/*@ngInject*/ ($locationProvider: ng.ILocationProvider) => {
        $locationProvider.html5Mode(true);
    })

    .config(/*@ngInject*/ ($urlMatcherFactoryProvider: ng.ui.IUrlMatcherFactory) => {
        $urlMatcherFactoryProvider.caseInsensitive(true);
        $urlMatcherFactoryProvider.strictMode(false);
    })

    .config(/*@ngInject*/ ($httpProvider: ng.IHttpProvider) => {
        $httpProvider.useApplyAsync(true);

        // http://stackoverflow.com/questions/16098430/angular-ie-caching-issue-for-http
        // initialize get if not there
        if (_.isNil($httpProvider.defaults.headers.get)) {
            $httpProvider.defaults.headers.get = {};
        }

        // disable IE ajax request caching
        $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
        // extra
        $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
        $httpProvider.defaults.headers.get.Pragma = 'no-cache';
    })

    .config(/*@ngInject*/ ($compileProvider: ng.ICompileProvider) => {
        if (!window.location.host.match(/localhost/)) {
            $compileProvider.debugInfoEnabled(false);
        }
    })

    .config(/*@ngInject*/($provide: ng.auto.IProvideService, constants: any) => {
        $provide.decorator('$state', ($delegate: any, $rootScope: ng.IRootScopeService) => {
            $rootScope.$on(constants.events.stateChangeStart, (event, toState, toParams) => {
                $delegate.next = toState;
                $delegate.nextParams = toParams;
            });
            return $delegate;
        });

        $provide.decorator('uiStateDirective', ($delegate: any) => {
            return !_.isEmpty($delegate) && $delegate.length > 1
                ? _.filter($delegate, (d: any) => { // suppress ui state change errors from mobile angular ui modals
                    return d.$$moduleName !== 'ui.router.state';
                })
                : $delegate;
        });

        $provide.decorator('grant', /*@ngInject*/ ($delegate: any, $log: ng.ILogService) => {
            const addTestFn = $delegate.addTest;
            // Create a function that wraps addTest() and skips validation and returns the provided value when gate
            // guards are disabled.
            const debugAddTestFnFactory = (debugResult: any) => (testName: string, validate: any) => {
                addTestFn(testName, () => {
                    if (constants.disableStateGuards === true) {
                        $log.debug(`Skipping grant test [${testName}].`);
                        return debugResult;
                    } else {
                        $log.debug(`Performing grant test [${testName}].`);
                        return validate();
                    }
                });
            };

            // Return true when gate guards are disabled.
            $delegate.addTest = debugAddTestFnFactory(true);

            // Return false when gate guards are disabled.
            $delegate.addTestWithDebugFalse = debugAddTestFnFactory(false);

            // Return true when gate guards are disabled.
            $delegate.addTestWithDebugTrue = debugAddTestFnFactory(true);

            // Always execute the test even when gate guards are disabled.
            $delegate.addTestRequired = (testName: string, validate: any) => {
                addTestFn(testName, () => {
                    $log.debug(`Performing grant test [${testName}].`);
                    return validate();
                });
            };

            return $delegate;
        });
    })

    .config(reduxConfig)

    .run(accessControlRun)

    .run(/*@ngInject*/ ($rootScope: ng.IRootScopeService, $log: ng.ILogService, constants: any, actionContext: any) => {
        $rootScope.$on(constants.events.stateChangeStart, () => {
            $rootScope.$broadcast('$routeChangeStart');
        });

        $rootScope.$on(constants.events.stateChangeSuccess, () => {
            // Fail-safe to make sure the loading spinner is stopped after a successfull state change
            actionContext.app.tryStopLoad();

            $rootScope.$broadcast('$routeChangeSuccess');
        });

        $rootScope.$on(constants.events.stateChangeError, (event, toState, toParams, fromState, fromParams, error) => {
            // Fail-safe to make sure the loading spinner is stopped if the application encounters an error during a
            // state change.
            actionContext.app.tryStopLoad();

            if (error) {
                // Do not log rejected resolve that are performing a redirect as an error.
                if (error === constants.errors.resolveRedirect) {
                    $log.info(
                        `State change rejected because of redirect. [From ${fromState.name}] [To ${toState.name}]`);
                    return;
                }

                // Do not log rejected grant tests as an error.
                if (error.type === constants.errors.grantRejected) {
                    $log.info('State change rejected because of grant test. ' +
                        `[From ${fromState.name}] [To ${toState.name}] [${angular.toJson(error)}]`);
                    return;
                }
            }

            $log.warn(`State change error. [From ${fromState.name}] [To ${toState.name}] [${_.isNil(error) ? '' : error.toString()}]`);
        });

        $rootScope.$on(constants.events.stateNotFound, (event, unfoundState, fromState) => {
            $log.debug(`State not found [From ${fromState.name}] [To ${unfoundState.to}]`);
        });
    });

angular.element(document).ready(() => {
    angular.bootstrap(document.body, ['fp:activationswizard'], {
        strictDi: true
    });
});
angular.element(document).on('click', () => {
    window.top.parent.postMessage('submitSetupClick', '*');
});

export default app;
