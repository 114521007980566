import * as _ from 'lodash';

export default function ($q, $log, $ngRedux, $state, grant, dataContext, actionContext, constants, appActions) {
    'ngInject';

    grant.addTestRequired('user', () => {
        return dataContext.user.getUserInfo()
            .then((user) => {
                return actionContext.login.userInfoUpdated(user).then(() => {
                    return user;
                });                
            });
    });

    grant.addTest('isPendingActivation', () => {
        return !$ngRedux.getState().application.finishedWizard ? $q.resolve(true) : $q.resolve(false);
    });

    grant.addTest('isActivated', () => {
        return actionContext.login.getAccountStatus()
            .then((result) => {
                return result.Status === 'Active'
                    ? $q.resolve(true)
                    : $q.reject(false);
            });
    });

    grant.addTest('isBoxCheckComplete', () => {
        return true;
    });

    grant.addTest('isInstructionComplete', () => {
        return true; // temp
    });

    grant.addTest('isPremisesComplete', () => {
        return $ngRedux.getState().account.premises.isConfirmed;
    });

    grant.addTest('isPredispatchComplete', () => {
        return $ngRedux.getState().account.predispatch.isConfirmed;
    });

    grant.addTest('isPasswordComplete', () => {
        return true; // temp
    });

    grant.addTest('isPanelComplete', () => {
        return true; // temp
    });

    grant.addTest('isMasterCodeComplete', () => {
        return true; // temp
    });

    grant.addTest('isWifiComplete', () => {
        return true; // temp
    });

    grant.addTestRequired('isSensorSetupComplete', () => {
        return actionContext.device.isSensorSetupComplete();
    });

    grant.addTestRequired('hasSkippedSensors', () => {
        return actionContext.device.hasSkippedSensors();
    });

    grant.addTest('isReviewComplete', () => {
        return true; // temp
    });

    grant.addTest('isTestModeComplete', () => {
        return actionContext.app.isStepCompleted('activation');
    });

    grant.addTest('isSignageComplete', () => {
        return actionContext.app.isStepCompleted('signage');
    });

    grant.addTestRequired('hasKeychainRemote', () => {
        return actionContext.order.hasProduct('KR') || actionContext.order.hasProduct('TX-E101') || actionContext.order.hasProduct('FP100');;
    });

    grant.addTestRequired('hasPanicPendant', () => {
        return actionContext.order.hasProduct('PP') || actionContext.order.hasProduct('RE103') ;
    });

    grant.addTestRequired('hasPanicPendantOrKeychain', () => {
        return actionContext.order.hasProduct('PP') || actionContext.order.hasProduct('RE103') || actionContext.order.hasProduct('KR') || actionContext.order.hasProduct('TX-E101') || actionContext.order.hasProduct('FP100');
    });

    grant.addTestRequired('hasCamera', () => {
        return actionContext.order.hasCamera();
    });

    grant.addTestRequired('hasLock', () => {
        if (!constants.enableLocksForInitialSetupWizard && !appActions.isInitialWithAdditional() && !dataContext.user.isInMobileApp())
            return false;
        else
            return actionContext.order.hasLock() || actionContext.order.hasLight();
    });
    
    grant.addTestRequired('hasOnlyLocks', () => {
        let lockSkus = constants.zWaveProducts.locks;

        let queuedDevices = _.filter(actionContext.order.getItemsById(), (device) => {
            return device.isQueued;
        });

        let zWaveDevices = _.filter(queuedDevices, (device) => {
            return _.includes(lockSkus, device.ProductSku);
        });

        if (queuedDevices.length == zWaveDevices.length && zWaveDevices.length != 0)
            return true;
        else
            return false;
    });
    grant.addTestRequired('hasAdditionalOrder', () => {
        return actionContext.order.getAdditionalEquipment().length > 0 && (!actionContext.app.isAdditional() || actionContext.app.isInitialWithAdditional());
    });
}
