import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';
import 'angularjs-slider';
import 'angularjs-slider/dist/rzslider.css';

import './testmode.scss';

import commonModule from '../common';

import testModeHomeComponent from './home.component';
import testModeCompleteComponent from './complete.component';

export default angular
    .module('fp:activationswizard:testmode', [
        'ui.router',
        'rzModule',
        ngRedux,
        commonModule.name
    ])
    .config( /*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            .state('testmode', {
                abstract: true,
                parent: 'secure',
                url: '/testmode',
                template: '<ui-view></ui-view>',
                data: {
                    next: /*@ngInject*/ ($state, appActions, $ngRedux) => {
                        appActions.completeModule(constants.sectionTypes.testmode);
                        // check if order has iq panel
                        const isIq4Panel = $ngRedux.getState().account.user.isIq4Panel;
                        if (isIq4Panel) {
                            $state.go('training.arm-away');
                        } else {
                            appActions.completeStep('activation');
                            $state.go('panel.finalizing'); 
                        }                                               
                    },
                    prev: /*@ngInject*/ ($state, $ngRedux) => {
                        // check if order has iq panel
                        const isIq4Panel = $ngRedux.getState().account.user.isIq4Panel;
                        if (isIq4Panel) {
                            $state.go('wifi.home');
                        } else {
                            $state.go('usercode.home');
                        }
                    },
                    sectionType: constants.sectionTypes.testmode,
                    theme: 'app-theme-default-navbar-gray'
                }
            })
            .state('testmode.home', {
                url: '',
                template: '<aw-test-mode-home></aw-test-mode-home>',
                onEnter: /*@ngInject*/ (appActions, $ngRedux) => {
                    // check if order has iq panel
                    const isIq4Panel = $ngRedux.getState().account.user.isIq4Panel;
                    if (!isIq4Panel) {
                        appActions.startModule(constants.sectionTypes.testmode);
                    }                    
                }
            })
            .state('testmode.complete', {
                url: '/complete',
                template: '<aw-test-mode-complete></aw-test-mode-complete>',
                data: {
                    theme: 'app-theme-green'
                }
            });
    })
    .component('awTestModeHome', testModeHomeComponent)
    .component('awTestModeComplete', testModeCompleteComponent);