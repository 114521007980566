import * as _ from 'lodash';

import template from './list.html';

class SensorListController {
    /*@ngInject*/
    constructor($document, $log, $ngRedux, $state, constants, actionContext, fpStore, appActions) {
        // services
        this.$document = $document;
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.actionContext = actionContext;
        this.appActions = appActions;
        this.sensorListExceptionSkus = _.map(this.constants.sensorListExceptionSkus);
        this.hasExceptionSkus = false;
        this.numberOfSmartLocksToActivate = fpStore.has(constants.storage.numberOfSmartLocksToActivate, 'session') ? fpStore.get(constants.storage.numberOfSmartLocksToActivate, 'session') : 0;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        if (!this.isInitialOrder) {
            this.sensors = _.filter(this.sensors, (sensor) => { return sensor.ProductSku === this.sku; });
        }
        if (!_.isNil(this.constants.debug) && this.constants.debug) {
            this.$document.bind('keypress', this._onKeyPress.bind(this));
        }
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
    }

    $onDestroy() {
        if (!_.isNil(this.constants.debug) && this.constants.debug) {
            this.$document.unbind('keypress');
        }

        this.unsubscribe();
    }

    $doCheck() {
        // get all key fobs and panic pendants if present in the order
            let keyFobOrPanicPendantDevice = _.filter(this.sensors, (sensor) => {
                return _.includes(this.sensorListExceptionSkus, sensor.ProductSku);
            });

            if (!_.isEmpty(keyFobOrPanicPendantDevice))
                this.hasExceptionSkus = true;

            // filter out all devices that are not sensors 
            this.sensors = _.filter(this.sensors, (sensor) => { return sensor.IsSensor });

            // only need to show one panic pendant/ key fob in the list
            if (this.hasExceptionSkus) {
                _.forEach(keyFobOrPanicPendantDevice,
                    (item) => {
                        // check if panic pendant or key fob exist, if not, add to the list
                        let exists = _.some(this.sensors, s => { return s.ProductSku === item.ProductSku; });
                        if (!exists) {
                            this.sensors.push(item);
                        };
                    });
            }
        
        let hasSmartLock = _.some(this.sensors,
            (sensor) => {
                return !_.isNil(sensor.IsSmartLock) && sensor.IsSmartLock;
            });
        if (this.numberOfSmartLocksToActivate > 0 && !hasSmartLock) {
            this.sensors.push({
                ProductName: `LOCKS`,
                IsSmartLock: true,
                Quantity: this.numberOfSmartLocksToActivate
            });
        }
    }

    /**
     * When an active sensor is selected the user is sent to the sensor overview
     * page. No action is taken if the user selects an inactive sensor.
     * 
     * @param {} sensor 
     * @returns {} 
     */
    onSensorSelected(sensor) {
        const deviceActions = this.actionContext.device;
        const productActions = this.actionContext.product;

        if (!this.isSensorEnabled(sensor)) {
            return;
        }

        if (sensor.ProductSku === 'FP100') {
            this.actionContext.device.startSetup(sensor.SensorID);
            this.$state.go(this.constants.routerStates.keychainRemote, { sensor: sensor });
            return;
        }

        if (sensor.ProductSku === 'RE103') {
            this.actionContext.device.startSetup(sensor.SensorID);
            this.$state.go(this.constants.routerStates.panicPendant, { sensor: sensor });
            return;
        }

        if (sensor.IsSmartLock) {
            this.$state.go(this.constants.routerStates.locksHome, { isAdditional: true });
        }

        if (deviceActions.isSensorTampered(sensor)) {
            this.$state.go('sensor.item.tamper', { sensorId: sensor.SensorID });
            return;
        }

        if (deviceActions.isSensorMalfunctioning(sensor)) {
            this.$state.go('sensor.item.malfunction', { sensorId: sensor.SensorID });
            return;
        }

        if (!deviceActions.isSensorConnected(sensor)) {
            this.$state.go('sensor.item.not-connected', { sensorId: sensor.SensorID });
            return;
        }


        this.$state.go('sensor.item.overview', { sensorId: sensor.SensorID });
    }

    /**
     * Returns true if the given sensor is enabled.
     * 
     * @param {Object} sensor 
     * @returns {boolean} True if the given sensor is enabled, false otherwise.
     */
    isSensorEnabled(sensor) {
        const deviceActions = this.actionContext.device;
        const productActions = this.actionContext.product;

        if (deviceActions.isSensorComplete(sensor) || deviceActions.isSensorSkipped(sensor)) {
            return false;
        }

        let idx = this.sensors.indexOf(sensor);
        if (idx === 0) {
            return true;
        }

        // Iterate backwards throught the device list looking for the last
        // device that is a sensor. If the previous device that is a sensor
        // is completed the given sensor is active.
        

        if (this.hasExceptionSkus) {
            for (let i = idx - 1; i >= 0; i--) {
                let prevSensor = this.sensors[i];
                if (productActions.isExceptionDevice(prevSensor.ProductSku) || productActions.isSensor(prevSensor.ProductSku)) {
                    return deviceActions.isSensorComplete(prevSensor) || deviceActions.isSensorSkipped(prevSensor);
                }
            }
        } else {
            for (let i = idx - 1; i >= 0; i--) {
                let prevSensor = this.sensors[i];
                if (productActions.isSensor(prevSensor.ProductSku)) {
                    return deviceActions.isSensorComplete(prevSensor) || deviceActions.isSensorSkipped(prevSensor);
                }
            }
        }
        
        // If we iterate backwards through the device list and do not find
        // a device that is a sensor the given sensor is active.
        return true;
    }

    /**
     * Returns true if the given sensor is disabled.
     * 
     * @param {Object} sensor 
     * @returns {boolean} True if the given sensor is disabled, false otherwise.
     */
    isSensorDisabled(sensor) {
        return !this.actionContext.device.isSensorComplete(sensor) && !this.isSensorEnabled(sensor);
    }

    _mapState(state) {
        return {
            sensors: this.actionContext.device.getOrderedSensors(),
            completeByID: state.system.devices.completeByID,
            cameras: _.filter(_.map(state.account.order.itemsByID), (item) => {
                return _.includes(!_.isNil(item.ProductName) ? item.ProductName.toLowerCase() : null, 'camera');
            }),
            isInitialOrder: state.account.user.isInitialOrder,
            sku: state.account.airfxFlow.sku
        };
    }

    _onKeyPress(event) {
        if (event.ctrlKey && event.code === 'Space') {
            for (let i = 0; i < this.sensors.length; i++) {
                this.actionContext.device.startSetup(this.sensors[i].SensorID);
                this.actionContext.device.finishSetup(this.sensors[i].SensorID);
            }

            this.$state.go('sensor.review');
        }
    }
}

export default {
    template: template,
    bindings: {},
    controller: SensorListController
};