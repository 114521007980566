import * as _ from 'lodash';
import error from '../../util/error';
import moment from 'moment';

import { MASTER_CODE_FETCH_REQUEST, MASTER_CODE_FETCH_FAILURE, MASTER_CODE_FETCH_SUCCESS,
         MASTER_CODE_UPDATE_REQUEST, MASTER_CODE_UPDATE_FAILURE, MASTER_CODE_UPDATE_SUCCESS,
         USER_CODE_UPDATE_REQUEST,USER_CODE_UPDATE_FAILURE, USER_CODE_UPDATE_SUCCESS,
         USER_CODE_DELETE_REQUEST, USER_CODE_DELETE_FAILURE, USER_CODE_DELETE_SUCCESS }  from '../../action/code.actions';

const initialState = {
    ignore: ['codes'],
    mastercode: null,
    lastUpdatedDate: null,
    isFetching: false,
    isUpdating: false,
    hasError: false,
    message: null,
    codes: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case MASTER_CODE_FETCH_REQUEST:
            return _.mergeCustom({}, state, {
                isFetching: true,
                hasError: false,
                message: null
            });
        case MASTER_CODE_FETCH_FAILURE:
        case USER_CODE_UPDATE_FAILURE:
        case USER_CODE_DELETE_FAILURE:
            return _.mergeCustom({}, state, {
                isFetching: false,
                hasError: true,
                message: error.parse(action.payload.error)
            });
        case MASTER_CODE_FETCH_SUCCESS:
            return _.mergeCustom({}, state, {
                mastercode: action.payload.masterCode,
                codes: action.payload.codes,
                lastUpdatedDate: moment.utc(),
                isFetching: false
            });
        case MASTER_CODE_UPDATE_REQUEST:
            return _.mergeCustom({}, state, {
                isUpdating: true,
                hasError: false,
                message: null
            });
        case MASTER_CODE_UPDATE_FAILURE:
            return _.mergeCustom({}, state, {
                isUpdating: false,
                hasError: true,
                message: error.parse(action.payload.error)
            });
        case MASTER_CODE_UPDATE_SUCCESS:
            return _.mergeCustom({}, state, {
                mastercode: action.payload.data,
                lastUpdatedDate: moment.utc(),
                isUpdating: false
            });
        case USER_CODE_UPDATE_REQUEST:
        case USER_CODE_DELETE_REQUEST:
            return _.mergeCustom({}, state, {
                isUpdating: true,
                hasError: false,
                message: null
            });
        case USER_CODE_UPDATE_SUCCESS:
            return _.mergeCustom({}, state, {
                lastUpdatedDate: moment.utc(),
                isUpdating: false,
                codes: _.concat(state.codes,  action.payload.code)
            });
        case USER_CODE_DELETE_SUCCESS:
            return _.mergeCustom({}, state, {
                lastUpdatedDate: moment.utc(),
                isUpdating: false,
                codes: _.remove(state.codes, (code) => { return code.PanelUserId !== action.payload.code.PanelUserId; })
            });
        default:
            return state;
    }
};