// http://stackoverflow.com/a/25636705

/**
 * @name awStopClick
 * @restrict A
 * 
 * @description
 * The 'awStopClick' directive stops the propagation of all click events if the provided function evaluates to true.
 * 
 * @usage
 * ```
 * <ANY ng-click="someFunction()" aw-stop-click="stopSomeFunction()"></ANY>
 * ```
 */
let stopClick = ($parse, $rootScope) => {
    'ngInject';
    return {
        priority: 100,
        restrict: 'A',
        compile: ($element, attr) => {
            const fn = $parse(attr.stopClick);
            return {
                pre: (scope, element) => {
                    const eventName = 'click';
                    element.on(eventName, (event) => {
                        const callback = () => {
                            if (fn(scope, { $event: event })) {
                                // prevents ng-click to be executed
                                event.stopImmediatePropagation();
                                // prevents href 
                                event.preventDefault();
                                return false;
                            }
                        };
                        if ($rootScope.$$phase) {
                            scope.$evalAsync(callback);
                        } else {
                            scope.$apply(callback);
                        }
                    });
                },
                post: () => {}
            };
        }
    };
};

export default stopClick;