import template from './addKeypad.html';

class AddKeypadController {
    /*@ngInject*/
    constructor($state) {
        this.$state = $state;
        this.deviceName = this.$state.params.deviceName;
    }
}

export default {
    template: template,
    bindings: {},
    controller: AddKeypadController
};