import template from './installationModal.html';

class InstallationModal {
    /*@ngInject*/
    constructor() {
        
    }
}

export default {
    template: template,
    bindings: {
        sku: '@'
    },
    controller: InstallationModal
};