import template from './missing.html';

class MissingController {
    /*@ngInject*/
    constructor(constants, actionContext) {
        this.constants = constants;
        this.actionContext = actionContext;
    }

    $onInit() {
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
    }
}

export default {
    template: template,
    bindings: {},
    controller: MissingController
};