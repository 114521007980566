export const START = 'START';
export const END = 'END';
export const ERROR = 'ERROR';
export const OTHER = 'OTHER';
import * as _ from 'lodash';

class CommonActions {
    /*@ngInject*/
    constructor ($log, $ngRedux) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
    }

    start(actionType, sectionType) {
        this.$ngRedux.dispatch({
            type: START,
            metadata: {
                actionType: actionType,
                sectionType: sectionType,
                persist: true 
            }
        });
    }

    end(actionType, sectionType) {
        this.$ngRedux.dispatch({
            type: END,
            metadata: {
                actionType: actionType,
                sectionType: sectionType,
                persist: true 
            }
        });
    }

    error(actionType, sectionType, description) {
        this.$log.error(description);

        this.$ngRedux.dispatch({
            type: ERROR,
            metadata: {
                actionType: actionType,
                sectionType: sectionType,
                description: description,
                persist: false,
                isHydratable: false
            }
        });
    }

    other(actionType, sectionType, isHydratable) {
        this.$ngRedux.dispatch({
            type: OTHER,
            metadata: {
                actionType: actionType,
                sectionType: sectionType,
                persist: true,
                isHydratable: !_.isNil(isHydratable) &&  isHydratable === false ? false : true
            }
        });
    }
}

export default CommonActions;