let ignoreDashes =  () => {
    'ngInject';
    return {
        require: 'ngModel',
        restrict: 'A',
        link: ($scope, $element, $attr, ctrl) => {
            $element.on('keypress', (event) => {
                if (event.keyCode === 45 && $attr.awIgnoreDashes === 'true') { 
                    event.preventDefault();
                }
            });
        }
    };
};

export default ignoreDashes;