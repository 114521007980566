import * as _ from 'lodash';

let anchorDisabledDirective = () => {
    'ngInject';
    return {
        restrict: 'A',
        link: ($scope, $element, $attrs, ctrl) => {
            //Toggle 'disabled' to class when awAnchorDisabled becomes true
            $scope.$watch($attrs.awAnchorDisabled, (newValue) => {
                if (!_.isNil(newValue)) {
                    $element.toggleClass('disabled', newValue === true || newValue === 'true');
                }
            });

            //Disable href on click
            $element.on('click', (e) => {
                if ($attrs.awAnchorDisabled === true || $attrs.awAnchorDisabled === 'true') {
                    e.preventDefault();
                }
            });
        }
    };
};

export default anchorDisabledDirective;