import template from './panicPendantOverview.html';
import * as _ from 'lodash';

class PanicPendantOverviewController {
    /*@ngInject*/
    constructor(constants, $ngRedux, $stateParams, $state, actionContext) {
        this.constants = constants;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.actionContext = actionContext;
        this.sensor = $stateParams.sensor;
            this._ = _;        
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    goToNext() {
        this.actionContext.device.startExceptionDeviceSetup(this.deviceSelected.SensorID);
        this.actionContext.device.finishExceptionDeviceSetup(this.deviceSelected);
        this.actionContext.device.finishSetup();
        this.$state.go('sensor.list');
    }

    _mapState(state) {
        return {
            deviceSelected: state.system.devices.sensorsByID[state.system.devices.selectedSensorID]
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: PanicPendantOverviewController
};