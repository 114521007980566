import * as _ from 'lodash';
import moment from 'moment';

import template from './return.html';

class CompleteController {
    /*@ngInject*/
    constructor ($ngRedux, constants, actionContext) {
        this.$ngRedux = $ngRedux;
        this.constants = constants;
        this.actionContext = actionContext;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    _mapState(state) {
        let testEnd = null;
        let activeStart = null;
        if (state.system.testmode.onTest && !_.isNil(state.system.testmode.endDate)) {
            testEnd = moment(state.system.testmode.endDate);
            activeStart = moment(testEnd).add(1, 'days').startOf('day');
        }

        return _.cloneDeep({
            activationDate: moment(state.account.user.activationDate),
            isTestMode: !_.isNil(testEnd),
            testModeEndDate: testEnd,
            alertModeStartDate: activeStart
        });
    }
}

export default {
    template: template,
    bindings: {},
    controller: CompleteController
};