import * as _ from 'lodash';
import { SET_CURRENT_CATEGORY, SET_CURRENT_SUBCATEGORY, SET_CURRENT_SKU, SET_TARGET_SKU, SET_CURRENT_DLCODE } from '../../action/airfxFlow.actions';

const initialState = {
    category: '',
    sku: '',
    dlcode: ''
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_CURRENT_CATEGORY:
            return _.mergeCustom({}, state, {
                category: action.payload.category
            });
        case SET_CURRENT_SKU:
            return _.mergeCustom({}, state, {
                sku: action.payload.sku
            });
        case SET_CURRENT_DLCODE:
            return _.mergeCustom({}, state, {
                dlcode: action.payload.dlcode
            });
        case SET_TARGET_SKU:
            return _.mergeCustom({}, state, {
                targetSku: action.payload.targetSku
            });
        default:
            return state;
    }
};