import * as _ from 'lodash';
import error from '../../util/error';
import moment from 'moment';

import { PREDISPATCH_FETCH_REQUEST, PREDISPATCH_FETCH_FAILURE, PREDISPATCH_FETCH_SUCCESS } from '../../action/predispatch.actions';
import { PREDISPATCH_UPDATE_REQUEST, PREDISPATCH_UPDATE_FAILURE, PREDISPATCH_UPDATE_SUCCESS } from '../../action/predispatch.actions';

import { PREDISPATCH_CONFIRMED } from '../../action/predispatch.actions';
import { PREDISPATCH_CLEAR_ERROR } from '../../action/predispatch.actions';

const initialState = {
    ignore: ['contacts', 'lastUpdatedDate', 'isFetching', 'isUpdating', 'hasError', 'message'],
    contacts: {},
    isConfirmed: false,
    lastUpdatedDate: null,
    isFetching: false,
    isUpdating: false,
    hasError: false,
    message: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case PREDISPATCH_FETCH_REQUEST:
        return _.mergeCustom({}, state, {
            isFetching: true,
            hasError: false,
            message: null
        });
    case PREDISPATCH_FETCH_FAILURE:
        return _.mergeCustom({}, state, {
            isFetching: false,
            hasError: true,
            message: error.parse(action.payload.error)
        });
    case PREDISPATCH_FETCH_SUCCESS:
        return _.mergeCustom({}, state, {
            contacts: action.payload.data,
            lastUpdatedDate: moment.utc(),
            isFetching: false
        });
    case PREDISPATCH_UPDATE_REQUEST:
        return _.mergeCustom({}, state, {
            isUpdating: true,
            hasError: false,
            message: null
        });
    case PREDISPATCH_UPDATE_FAILURE:
        return _.mergeCustom({}, state, {
            isUpdating: false,
            hasError: true,
            message: error.parse(action.payload.error)
        });
    case PREDISPATCH_UPDATE_SUCCESS:
        return _.mergeCustom({}, state, {
            contacts: action.payload.data,
            lastUpdatedDate: moment.utc(),
            isUpdating: false
        });
    case PREDISPATCH_CONFIRMED:
        return _.mergeCustom({}, state, {
            isConfirmed: true
        });
    case PREDISPATCH_CLEAR_ERROR:
        return _.mergeCustom({}, state, {
            hasError: false,
            message: null
        });
    default:
        return state;
    }
};