import * as _ from 'lodash';

export const WIFI_CHANGED = 'WIFI_CHANGED';
export const WIFI_NETWORKS_REFRESHED = 'WIFI_NETWORKS_REFRESHED';
export const WIFI_PANEL_ON = 'WIFI_PANEL_ON';
export const WIFI_DATE_RECEIVED = 'WIFI_DATE_RECEIVED';

export const WIFI_NETWORKS_FETCH_REQUEST = 'WIFI_NETWORKS_FETCH_REQUEST';
export const WIFI_NETWORKS_FETCH_FAILURE = 'WIFI_NETWORKS_FETCH_FAILURE';
export const WIFI_NETWORKS_FETCH_SUCCESS = 'WIFI_NETWORKS_FETCH_SUCCESS';

export const WIFI_NETWORKS_SET_REQUEST = 'WIFI_NETWORKS_SET_REQUEST';
export const WIFI_NETWORKS_SET_FAILURE = 'WIFI_NETWORKS_SET_FAILURE';
export const WIFI_NETWORKS_SET_SUCCESS = 'WIFI_NETWORKS_SET_SUCCESS';

export const WIFI_CLEAR_ERROR = 'WIFI_CLEAR_ERROR';
export const WIFI_CONNECTED = 'WIFI_CONNECTED';

class WifiActions {
    /*@ngInject*/
    constructor($interval, $log, $ngRedux, $q, constants, dataContext, $timeout, $rootScope, panelActions) {
        this.$interval = $interval;
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.constants = constants;
        this.dataContext = dataContext;
        this.$timeout = $timeout;
        this.$rootScope = $rootScope;
        this.panelActions = panelActions;
    }

    getWifiNetworks() {
        return this.dataContext.wifi.getWifiNetworks().then((result) => {
            let filteredNetworks = [];
            _.forEach(result.WifiNetworks,
                network => {
                    if (!_.includes(network.WiFiNetworkName, "FP-HUB")) {
                        filteredNetworks.push(network);
                    }
                });
            return filteredNetworks;
        });
    }

    fetching(doPolling) {
        const state = this.$ngRedux.getState();

        //if it's a xtpanel return
        if (state.system.panel.type === this.constants.panelVersions.xtPanel) {
            return this.$q.when(true);
        }

        const pollingInterval = this.constants.polling.wifi.pollingInterval;
        const maxInterval = this.constants.polling.wifi.maxIntervalGettingCount;
        const connectedNetworkName = state.system.wifi.networkName;
        this.$log.debug('Polling wifi networks.');

        this._onFetching();

        // 1. Get a baseline for the last message date.
        let baseline = state.system.wifi.lastNetworkDateReceived;

        let promise;
        if (_.isNil(baseline)) {
            // 2.1 A baseline does not exist in the application state.
            promise = this.getWifiNetworks()
                .then((result) => {
                    _.forEach(result, (network) => {
                        if (network.EncryptionType !== 'None') {
                            baseline = network.LastReported;
                        }
                    });

                    this.dataContext.wifi.refreshWifiNetworks()
                        .then(() => {
                            if (!doPolling) {
                                this.wifiLastDateReceieved(baseline);
                                this.dataContext.wifi.enableWifi()
                                    .then((result) => {
                                        this.wifiPanelOn();
                                    })
                                    .catch((error) => {
                                        this.$log.error('Error in panel turnPanelWifiOn', error);
                                    });
                            }
                        })
                        .catch((error) => {
                            this.$log.error('Error in panel refreshWifiNetworks', error);
                        });

                    this.$log.debug(`Baseline last message date [${baseline}] established from the server.`);
                });
        } else {
            // 2.2 A basline does exist in the application state.
            promise = this.$q.resolve()
                .then(() => {
                    this.$log.debug(`Baseline last message date [${baseline}] established from the application state.`);
                });
        }

        return promise
            .then(() => {
                if (doPolling) {
                    let interval = this.$interval(() => {
                        this.$rootScope.$emit(this.constants.events.callSupportNotification.name);
                        this.getWifiNetworks()
                            .then((result) => {
                                if (_.isNil(result)) {
                                    return;
                                }
                                let canContinue = false;
                                let reportedByServerDate;

                                _.forEach(result, (network) => {
                                    reportedByServerDate = network.LastReported;
                                    network.PasswordRequired = network.EncryptionType !== 'None';
                                    network.Connected = network.WiFiNetworkName === connectedNetworkName;

                                    if (network.EncryptionType !== 'None' && network.LastReported !== baseline)
                                        canContinue = true;
                                });

                                if (canContinue) {
                                    this._onFetchingSuccess(_.uniqBy(result, 'WiFiNetworkName'));
                                    this.$interval.cancel(interval);
                                } else {
                                    this.$log.debug(
                                        `Last reported message date is [${reportedByServerDate
                                        }]. The baseline last message date is [${baseline}].`);
                                }
                            });
                    }, pollingInterval, maxInterval);
                    return interval;
                }
            })
            .catch((error) => {
                let state = this.$ngRedux.getState();
                let isFetched = state.system.wifi.isFetched;
                if (error === 'canceled') {
                    if (isFetched) {
                        this.$log.debug('Successfully retrieved up to date wifi networks');
                    } else {
                        this.$log.debug('Canceled retrieving wifi networks');
                    }

                    return this.$q.resolve();
                }

                this.$log.error('Canceled retrieving wifi networks', error);
                this._onFetchingFailure(error);
                return this.$q.reject(error);
            });
    }

    connecting(networkCredentials) {
        this._onConnecting();
        return this.dataContext.wifi.setWifi(networkCredentials);
    }

    wifiConnected() {
        this.$log.debug('Getting network name');

        return this.panelActions.getSystemSettings(true, ['Wifi Network Name']).then((settings) => {
            this.$log.debug('Refreshing panel settings');

            var wifiNetworkSetting = this.panelActions.getWifiNetWorkSetting(settings);

            if (!_.isNil(wifiNetworkSetting) && !_.isNil(wifiNetworkSetting.UploadedValue) && _.trim(wifiNetworkSetting.UploadedValue) !== '') {
                this.$log.debug(`Network name is [${wifiNetworkSetting.UploadedValue}].`);
                this._onWifiConnected(wifiNetworkSetting.UploadedValue);
                return wifiNetworkSetting;
            }
        }).catch((error) => {
            this.$log.error('Getting network name', error);
            this._onConnectingFailure(error);
            return this.$q.reject(error);
        });
    }

    wifiChanged(networkName, sectionType) {
        this.$ngRedux.dispatch({
            type: WIFI_CHANGED,
            payload: {
                networkName
            },
            metadata: {
                sectionType: sectionType,
                description: 'The wifi network has changed',
                persist: true
            }
        });
    }

    wifiNetworksRefreshed(message) {
        this.$ngRedux.dispatch({
            type: WIFI_NETWORKS_REFRESHED,
            payload: {
                message
            }
        });
    }

    wifiPanelOn() {
        this.$ngRedux.dispatch({
            type: WIFI_PANEL_ON
        });
    }

    wifiLastDateReceieved(lastNetworkDateReceived) {
        this.$ngRedux.dispatch({
            type: WIFI_DATE_RECEIVED,
            payload: {
                lastNetworkDateReceived
            }
        });
    }

    /**
        * Clears any errors associated with predispatch contacts in the
        * application state.
    */
    clearError() {
        this.$ngRedux.dispatch({
            type: WIFI_CLEAR_ERROR
        });
    }

    _onFetching() {
        this.$ngRedux.dispatch({
            type: WIFI_NETWORKS_FETCH_REQUEST
        });
    }

    _onFetchingSuccess(networks) {
        this.$ngRedux.dispatch({
            type: WIFI_NETWORKS_FETCH_SUCCESS,
            payload: {
                networks: networks
            }
        });
    }

    _onFetchingFailure(error) {
        this.$ngRedux.dispatch({
            type: WIFI_NETWORKS_FETCH_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onConnecting() {
        this.$ngRedux.dispatch({
            type: WIFI_NETWORKS_SET_REQUEST
        });
    }

    _onConnectingSuccess() {
        this.$ngRedux.dispatch({
            type: WIFI_NETWORKS_SET_SUCCESS
        });
    }

    _onConnectingFailure(error) {
        this.$ngRedux.dispatch({
            type: WIFI_NETWORKS_SET_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onWifiConnected(networkName) {
        this.$ngRedux.dispatch({
            type: WIFI_CONNECTED,
            payload: {
                networkName: networkName
            }
        });
    }
}

export default WifiActions;