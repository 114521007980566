import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';
//import '../modswap/modswap.scss';
import swapIntroComponent from './intro.component';

export default angular
    .module('fp:activationswizard:airfx:radioswap',
        [
            ngRedux
        ])
    .config(/*@ngInject*/
        ($compileProvider) => {
            $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|android-app|tel):/);
        }
    )
    .component('awRadioswapIntro', swapIntroComponent);