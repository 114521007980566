import * as _ from 'lodash';
import * as extendify from 'extendify';

declare module 'lodash' {
    interface LoDashStatic {
        mergeCustom<TValue>(object: object, ...sources: object[]): TValue[];
    }
    interface LoDashExplicitWrapper<TValue> {
        mergeCustom(index1: number, index2: number): LoDashExplicitWrapper<TValue>;
    }
}

_.mixin({
    mergeCustom: extendify({
        inPlace: false,
        isDeep: true,
        arrays: 'replace'
    }),
});
