import template from './list-item.html';
import * as _ from 'lodash';

class ListItemController {
    /*@ngInject*/
    constructor (fpStore, constants) {
        this.fpStore = fpStore;
        this.constants = constants;
    }

    $onInit() {
        if (_.get(this.device, 'IsSmartLock', false)
            && this.fpStore.has(this.constants.storage.numberOfSmartLocksToActivate, 'session') && 
            this.fpStore.get(this.constants.storage.numberOfSmartLocksToActivate, 'session') > 0) {
            this.device.isQueued = true;
        }

        this.displayName = !_.isNil(this.device.WebName) ? this.device.WebName :  this.device.ProductName + " " + this.device.SensorNumber;
    }

    onClicked() {
        if (!this.isConnected) {
            this.device.isQueued = !this.device.isQueued;
            this.onClick({ item: this.device, isChecked: this.device.isQueued });
        }
    }
}

export default {
    template: template,
    bindings: {
        device: '<',
        isConnected: '<',
        onClick: '&'
    },
    controller: ListItemController
};