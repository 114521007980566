import * as _ from 'lodash';
import template from './systemControlListItem.html';

class SystemControlListItemController {
    /*@ngInject*/
    constructor(constants, dataContext, $log, $state, $ngRedux, actionContext, segment, scannerService) {
        this.constants = constants;
        this.dataContext = dataContext;
        this.$log = $log;
        this.$state = $state;
        this.$ngRedux = $ngRedux;
        this.actionContext = actionContext;
        this.segment = segment;
        this.scannerService = scannerService;
        this.isBarcodeScanSupported = this.scannerService.checkBrowserCompatibility().fullSupport;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
    }

    checkEligibility() {
        this.segment.registerEvent(this.constants.events.segment.panelSwapClickSetupOption, {});
        this.dataContext.modSwap.checkEligibility().then((res) => {
            if (res != null && res.Message === 'Eligible') {
                if (_.some(this.constants.panelIDs.hub, (productId) => res.TargetPanelProductId === productId)) {
                    this.actionContext.airfxFlow.setTargetSku('HSVGW');
                    this.actionContext.airfxFlow.setCurrentSku(this.panelVersion);
                }
                else if (_.some(this.constants.panelIDs.iq4, (productId) => res.TargetPanelProductId === productId)) {
                    this.actionContext.airfxFlow.setTargetSku('IQPANEL');
                    this.actionContext.airfxFlow.setCurrentSku(this.panelVersion);
                }
                
                this.actionContext.equipmentSetup.onSetupStart(`Panel swap eligibility check passed - OldSerialNumber: [${res.OldPanelSerialNumber}] NewSerialNumber: [${res.NewPanelSerialNumber}] `);
                this.$state.go(this.constants.routerStates.swapintro);
            }
            else if (res != null && res.Message === 'InEligible') {
                this.actionContext.equipmentSetup.onSetupStart(`Panel swap eligibility check failed - OldSerialNumber: [${res.OldPanelSerialNumber}] NewSerialNumber: [${res.NewPanelSerialNumber}] `);
                this.$state.go('airfx.modswapInEligible');
            }
        }).catch((error) => {
            this.$log.error('Error checking for eligible swap order.', error);
        });
    }

    checkRadioSwapEligibility() {
        // Check if radio swap and move to swap
        this.dataContext.radioSwap.checkEligibility().then((res) => {
            if (res != null && res.Message === 'Eligible') {
                this.actionContext.equipmentSetup.onSetupStart(`Radio swap eligibility check passed - OldSerialNumber: [${res.OldPanelSerialNumber}] NewSerialNumber: [${res.NewPanelSerialNumber}] `);
                this.$state.go(this.constants.routerStates.radioSwapIntro);
            }
            else if (res != null && res.Message === 'InEligible') {
                this.actionContext.equipmentSetup.onSetupStart(`Radio swap eligibility check failed - OldSerialNumber: [${res.OldPanelSerialNumber}] NewSerialNumber: [${res.NewPanelSerialNumber}] `);
            }
        }).catch((error) => {
            this.$log.error('Error checking for eligible radio swap order.', error);
        });

    }

    onKeypadSetup() {
        this.dataContext.panel.getExtendedFirmwareVersion().then((version) => {
            this.actionContext.airfxFlow.setCurrentSku('FPKEY2');

            this.route = this._checkKeypadEligibilityAndGetRoute(version);
            if (this.route === 'airfx.categoryEmpty') {
                this.$state.go(this.route, { category: 'keypad' });
            } else {
                if (!this.isBarcodeScanSupported) {
                    this.$state.go('airfx.barcodeManual', { error: false });
                } else {
                    this.$state.go(this.route);
                }
            }
        });
    }

    _checkKeypadEligibilityAndGetRoute(extendedFirmwareVersion) {
        let route;
        if (this.panelVersion === this.constants.panelVersions.climaxHub) {
            if (_.isEmpty(this.allAdditionalOrdersWithKeypad)) {
                route = 'airfx.categoryEmpty';
            } else {
                let panelSupported = this._isLatestPanelFirmwareAndKeypadVersion(parseInt(extendedFirmwareVersion));
                route = panelSupported ? 'airfx.barcodeList' : 'airfx.modswapInEligible';
            }
        } else {
            route = 'airfx.modswapInEligible';
        }
        return route;
    }

    _isLatestPanelFirmwareAndKeypadVersion(extendedFirmwareVersion) {
        let keypadDlCode = this.constants.regex.latestKeypadDlCode;
        let keypadsWithLatestDlCodes = _.filter(this.allAdditionalOrdersWithKeypad, (keypad) => { return keypadDlCode.test(keypad.SerialNumber) });
        let isLatestFirmwareVersion = extendedFirmwareVersion >= this.constants.latestHubFirmwareVersion;

        return isLatestFirmwareVersion && !_.isEmpty(keypadsWithLatestDlCodes);
    }

    _mapState(state) {
        const orderProducts = state.account.order.itemsByID;
        return {
            targetSku: state.account.airfxFlow.targetSku,
            panelVersion: state.system.panel.type,
            allAdditionalOrdersWithKeypad: _.filter(orderProducts, (order) => { return !order.IsInitialOrder && !order.isActivated && _.indexOf(this.constants.keypads.skus, order.ProductSku) !== -1 })
        };
    }
}



export default {
    template: template,
    bindings: {
        isRadioSwapEligible: '<'
    },
    controller: SystemControlListItemController
};