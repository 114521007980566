import * as _ from 'lodash';
import dateKind from '../util/dateKind';
import moment from 'moment';

export const TEST_MODE_SUCCESSFUL = 'TEST_MODE_SUCCESSFUL';
export const TEST_MODE_LOADED = 'TEST_MODE_LOADED';

export const TEST_MODE_FETCH_REQUEST = 'TEST_MODE_FETCH_REQUEST';
export const TEST_MODE_FETCH_FAILURE = 'TEST_MODE_FETCH_FAILURE';
export const TEST_MODE_FETCH_SUCCESS = 'TEST_MODE_FETCH_SUCCESS';

export const TEST_MODE_UPDATE_RQUEST = 'TEST_MODE_UPDATE_RQUEST';
export const TEST_MODE_UPDATE_FAILURE = 'TEST_MODE_UPDATE_FAILURE';
export const TEST_MODE_UPDATE_SUCCESS = 'TEST_MODE_UPDATE_SUCCESS';

class TestModeActions {
    /*@ngInject*/
    constructor ($log, $ngRedux, $state, $q, constants, commonActions, dataContext) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$q = $q;
        this.constants = constants;
        this.commonActions = commonActions;
        this.dataContext = dataContext;
    }

    /**
     * Updates the test mode end date for the current user's system.
     * 
     * @param {date} endDate A date that represents the last date the user's system should be in testmode.
     * @param {boolean} isOnTest 
     * @returns {promise} A promise that returns undefined if resolved and an error if rejected. 
     */
    updateEndDate(endDate, isOnTest) {
        this._onUpdateEndDate();
        return this.dataContext.testMode.setTestMode(endDate, isOnTest)
            .then(() => {
                this._onUpdateEndDateSuccess(dateKind.toLocalKind(endDate), isOnTest);
                return this.$q.resolve();
            })
            .catch((error) => {
                let message = 'Unable to set test mode';
                if (!_.isNil(error.data) && !_.isNil(error.data.Message)) {
                    message = error.data.Message;
                }

                this.commonActions.error(this.constants.actionTypes.error, this.constants.sectionTypes.testmode, message);
                this.$log.error('Error updating the customer\'s test mode status.', error);
                this._onUpdateEndDateFailure(error);
                return this.$q.reject(error);
            });
    }

    /**
     * Fetches the current user's test mode end date and adds it to the application state.
     * 
     * @returns {promise} A promise that returns undefined if resolved and an error if rejected. 
     */
    fetchEndDate() {
        this.onFetchTestMode();

        return this.dataContext.testMode.getTestModeEndDate()
            .then((result) => {
                let endDate = null;
                if (!_.isNil(result)) {
                    endDate = moment(result).subtract(1, 'minutes').toDate();
                }
                    
                this._onFetchEndDateSuccess(endDate);
                return this.$q.resolve();
            }).catch((error) => {
                this.$log.error('Error fetching the customer\'s test mode status.', error);
                this._onFetchEndDateFailure(error);
                return this.$q.reject('fetchEndDate');
            });
    }

    onFetchTestMode() {
        this._dispatch({
            type: TEST_MODE_FETCH_REQUEST
        });
    }

    _dispatch(obj) {
        this.$ngRedux.dispatch(obj);
    }

    _onFetchEndDateFailure(error) {
        this._dispatch({
            type: TEST_MODE_FETCH_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onFetchEndDateSuccess(data) {
        this._dispatch({
            type: TEST_MODE_FETCH_SUCCESS,
            payload: {
                data: data,
                onTest: !_.isNil(data)
            }
        });
    }

    _onUpdateEndDate() {
        this._dispatch({
            type: TEST_MODE_UPDATE_RQUEST
        });
    }

    _onUpdateEndDateFailure(error) {
        this._dispatch({
            type: TEST_MODE_UPDATE_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onUpdateEndDateSuccess(data, onTest) {
        this._dispatch({
            type: TEST_MODE_UPDATE_SUCCESS,
            payload: {
                data: data,
                onTest: onTest
            },
            metadata: {
                sectionType: this.constants.sectionTypes.testmode,
                description: !_.isNil(data) ? 'Test mode was configured until ' + data  : 'Test mode was set to off',
                persist: true 
            }
        });
    }
}

export default TestModeActions;