import * as _ from 'lodash';

import template from './list.html';

class ListController {
    /*@ngInject*/
    constructor ($ngRedux, actionContext, dataContext, constants, $state, fpStore, SharedState, $timeout, appActions, browserHistory) {
        this.$ngRedux = $ngRedux;
        this.unsubscribe = $ngRedux.connect(this._mapState)(this);
        this.actionContext = actionContext;
        this.dataContext = dataContext;
        this.SharedState = SharedState;
        this.constants = constants;
        this.$state = $state;
        this.fpStore = fpStore;
        this.$timeout = $timeout;
        this.appActions = appActions;
        this.lockSkus = _.map(this.constants.zWaveProducts.locks);
        this.browserHistory = browserHistory;
    }

    $onInit() {
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);

        this.isInitialWithAdditional = this.appActions.isInitialWithAdditional() && !this.dataContext.user.isInMobileApp();

        //filter out already activated sensors, initial order sensors, and sensors of other type
        this.devices = _.filter(this.actionContext.order.getItemsToActivate(), (sensor) => {
            return !_.get(sensor, 'isActivated', false);
        });

        //filter out already connected devices that are skipped
        this.devices = this.actionContext.order.getSensorsThatAreSkippedButStillActivated(this.devices);

        //check if this is initial with additional or pure additional
        if (!this.isInitialWithAdditional) {
            this.devices = _.filter(this.devices, (device) => { return device.ProductSku === this.sku; });
        }

        var sensorList = '';
        //queue up the sensors that are connected already
        _.forEach(this.devices, (device) => {

            sensorList += `OrderProductId: [${device.OrderProductID}] DLCode: [${device.DLCode}] SKU: [${device.ProductSku}] SensorNumber: [${device.SensorNumber}] `;
            if (device.IsConnected === true)
                device.isQueued = true;
        });

        this.actionContext.equipmentSetup.sensorPresented(sensorList);

        this.deviceList = this.devices;

        let zWaveDevicesCount = this.getZWaveDevices().length;
        if (zWaveDevicesCount > 0) {
            this.deviceList = _.filter(this.devices, (device) => {
                return !_.includes(this.lockSkus, device.ProductSku);
            });
            this.deviceList.push({
                ProductName: `LOCKS x ${zWaveDevicesCount}`,
                IsSmartLock: true,
                isQueued: false,
                ProductSku: 'locks'
            });
            if (this.fpStore.has(this.constants.storage.numberOfSmartLocksToActivate, 'session'))
                this.queueSmartLocks();
        }
        const isSensorsByType = !_.isEmpty(this.sku);
        if (isSensorsByType) {
            this.deviceList = _.filter(this.deviceList, (device) => { return device.ProductSku === this.sku; });
        }

        if (this.devices.length === 0) {
            this.$state.go(this.constants.routerStates.airfxNoSensors);
        }

        //display the warning modal for new sessions
        if (!this.fpStore.has('armedPanelModal', 'session') || this.fpStore.has('armedPanelModal', 'session') && !this.fpStore.get('armedPanelModal', 'session')) {
            this.$timeout(() => {

                this.SharedState.turnOn('armedPanelModal');
                this.fpStore.set('armedPanelModal', true, 'session');

            }, 100);
        }
    }

    $onDestroy() {
        this.unsubscribe();
    }

    onItemClicked(item, isChecked) {
        if (_.get(item, 'IsConnected', false) === false) {
            const isSmartLock = _.get(item, 'IsSmartLock', false);
            if (isChecked) {
                if (isSmartLock) 
                    this.queueSmartLocks();
                else
                    this.actionContext.order.queueAirFx(item);
            } else {
                if (isSmartLock)
                    this.dequeueSmartLocks();
                else
                    this.actionContext.order.dequeueAirFx(item);
            }
        }
    }

    /**
     * Check all devices.
     */
    onSelectAll() {
        _.forEach(this.devices, (device) => {
            if (!device.IsConnected) {
                device.isQueued = true;
                this.actionContext.order.queueAirFx(device);
            }
        });

        //Handle the UI Check All for Locks
        let locksItem = _.find(this.deviceList, x => x.IsSmartLock === true);
        if (locksItem)
            locksItem.isQueued = true;

        this.queueSmartLocks();
    }

    /**
     * Uncheck all devices. 
     */
    onClearAll() {
        _.forEach(this.devices, (device) => {
            device.isQueued = false;
            this.actionContext.order.dequeueAirFx(device);
        });

        //Handle the UI Uncheck All for Locks
        let locksItem = _.find(this.deviceList, x => x.IsSmartLock === true);
        if (locksItem)
            locksItem.isQueued = false;

        this.dequeueSmartLocks();
    }

    /**
     * Returns true if the application can proceed to the next step.
     * 
     * @returns {boolean} 
     */
    canContinue() {
        let allConnected = _.every(this.devices, (device) => {
            return device.IsConnected;
        });

        if (allConnected)
            return false;
        else 
            return _.every(this.devices, (device) => {
                return !device.isQueued;
            });
    }

    getZWaveDevices() {
        let zWaveDevices = [];
        if (!_.isNil(this.devices) && !_.isNil(this.lockSkus)) {
            zWaveDevices = _.filter(this.devices, (device) => {
                return _.includes(this.lockSkus, device.ProductSku);
            });
        }
        return zWaveDevices;
    }

    queueSmartLocks() {
        this.fpStore.set(this.constants.storage.numberOfSmartLocksToActivate, this.getZWaveDevices().length, 'session');
        _.forEach(this.getZWaveDevices(), zWaveDevice => {
            zWaveDevice.isQueued = true;
            this.actionContext.order.queueAirFx(zWaveDevice);
        });
    }

    dequeueSmartLocks() {
        this.fpStore.remove(this.constants.storage.numberOfSmartLocksToActivate, 'session');
        _.forEach(this.getZWaveDevices(), zWaveDevice => {
            zWaveDevice.isQueued = false;
            this.actionContext.order.queueAirFx(zWaveDevice);
        });
    }

    onClickBack() {
        if (this.isInitialWithAdditional) {
            this.$state.go('airfx.tunnel');
        } else {
            this.$state.go('airfx.subCategoryList');
        }
    }

    _mapState(state) {
        return {
            category: state.account.airfxFlow.category,
            sku: state.account.airfxFlow.sku,
            dlcode: state.account.airfxFlow.dlcode
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: ListController
};