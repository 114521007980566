import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import './modswap.scss';

import commonModule from '../../common';
import instructionModule from '../../instruction';
import userCodesModule from './userCodes';
import duressCodesModule from './duressCodes';
import disconnectPanelModalComponent from './disconnectPanelModal.component';
import disconnectPanelComponent from './disconnectPanel.component';
import incompatibleDeviceModalComponent from './incompatibleDeviceModal.component';
import swapIntroComponent from './intro.component';
import inEligibleComponent from './inEligible.component';
import sensorTransferComponent from './sensorTransfer.component';
import successComponent from './success.component';
import helpPanelModalComponent from './helpPanelModal.component ';
import zWaveModalComponent from './zWaveModal.component';

export default angular
    .module('fp:activationswizard:airfx:modswap',
        [
            ngRedux,
            commonModule.name,
            instructionModule.name,
            userCodesModule.name,
            duressCodesModule.name
        ])
    .config(/*@ngInject*/
        ($compileProvider) => {
            $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|android-app|tel):/);
        }
    )
    .component('awDisconnectPanelModal', disconnectPanelModalComponent)
    .component('awModswapIntro', swapIntroComponent)
    .component('awModswapInEligible', inEligibleComponent)
    .component('awModswapSensorTransfer', sensorTransferComponent)
    .component('awModswapSuccess', successComponent)
    .component('awHelpPanelModal', helpPanelModalComponent)
    .component('awDisconnectPanel', disconnectPanelComponent)
    .component('awZWaveModal', zWaveModalComponent)
    .component('awIncompatibleDeviceModal', incompatibleDeviceModalComponent);

