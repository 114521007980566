import template from './navbar-bottom.html';
import './nav.scss';

class NavbarBottomComponent {
    /*@ngInject*/
    constructor() {
    }

    $onInit() {
        
    }
}

export default {
    template: template,

    bindings: {
        theme: '<'
    },

    controller: NavbarBottomComponent
};