import 'angular-ui-router';
import * as angular from 'angular';
import ngRedux from 'ng-redux';

import sensorContactTestComponent from './contact.component';
import sensorEventTestComponent from './event.component';
import sensorInteractiveTestComponent from './interactive.component';
import sensorWalkthroughTestComponent from './walkthrough.component';
import sensorStatusTestComponent from './status.component';

export default angular
    .module('fp:activationswizard:sensor:test', [
        ngRedux
    ])
    .component('awSensorContactTest', sensorContactTestComponent)
    .component('awSensorEventTest', sensorEventTestComponent)
    .component('awSensorInteractiveTest', sensorInteractiveTestComponent)
    .component('awSensorWalkthroughTest', sensorWalkthroughTestComponent)
    .component('awSensorStatusTest', sensorStatusTestComponent);