import * as _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import './callback.scss';
import template from './callback.html';

class CallbackModalController {
    /*@ngInject*/
    constructor($log, $ngRedux, $scope, SharedState, dataContext, constants) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.SharedState = SharedState;
        this.dataContext = dataContext;
        this.constants = constants;
        this.configurationsService = dataContext.configurations;

        this.initCallbackData();
        this.initCallbackFlags();

        this.FORMAT_DATE_READABLE = 'MMMM Do, YYYY h:mm A z';
        this.FORMAT_DATE_SORTABLE = 'YYYY-MM-DD HH:mm:ss';
        this.MODAL_CALLBACK = 'modalCallback';

        let unwatch = $scope.$watch(() => {
            return this.supportHours;
        }, (newValue) => {
            if (!_.isNil(newValue)) {
                this.setHours();
                unwatch();
            }
        });
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.setHours();
    }

    $onDestroy() {
        this.unsubscribe();
    }

    callBackErrorContinue() {
        this.resetCallbackFlags();
    }

    callbackSuccessContinue() {
        this.SharedState.turnOff(this.MODAL_CALLBACK);
        this.resetCallbackData();
        this.resetCallbackFlags();
    }

    close() {
        this.SharedState.turnOff(this.MODAL_CALLBACK);
        this.resetCallbackData();
        this.resetCallbackFlags();
    }

    isValidTime() {
        return !_.isNil(this.callbackTime);
    }

    formatCallbackDateTime() {
        return moment(this.getCallbackDateTime()).format(this.FORMAT_DATE_READABLE);
    }

    formatCallbackDateTimeSortable() {
        return moment(this.getCallbackDateTime()).format(this.FORMAT_DATE_SORTABLE);
    }

    formatTime(time) {
        return moment(time).format('h:mm A z');
    }
    
    getCallbackDateTime() {
        return this.callbackTime.clone().toDate();
    }

    getValidCallbackTimes(date, supportHours) {
        let times = [];
        
        const increment = this.constants.customerSupport.callback.timeIncrement;

        const easternTimeZone = 'America/New_York';

        let startHour = 9;
        let endHour = 17;

        let baseDate = moment.tz(date, easternTimeZone).startOf('day');

        if (!_.isNil(supportHours)) {
            let dayOfWeek = baseDate.clone().isoWeekday();
            let match = _.find(supportHours, (sh) => {
                return _.some(sh.Days, (d) => d === dayOfWeek);
            });
            if (!_.isNil(match)) {
                startHour = match.Open;
                endHour = match.Close;
            }
        }

        let startTime = baseDate.clone();
        startTime.hour(startHour);

        let now = moment().tz(easternTimeZone);
        if (startTime < now) {
            startTime = now.clone().startOf('hour').add(increment * Math.ceil((now.clone().minute() + (increment / 2)) / increment), 'minute');
        }

        let endTime = baseDate.clone();
        endTime.hour(endHour);
        endTime.add(-increment, 'minute');

        const localTimeZone = this.constants.customerSupport.callback.timeZone === 'local'
         ? moment.tz.guess() 
         : this.constants.customerSupport.callback.timeZone;

        while(startTime <= endTime){
            times.push(startTime.clone().tz(localTimeZone));
            startTime.add(15, 'minute');
        }

        return times;
    }

    initCallbackFlags() {
        this.isBusy = false;
        this.isError = false;
        this.isSuccess = false;
    }

    setHours() {
        let selected = moment.isMoment(this.callbackTime) ? this.callbackTime.clone() : null;
        this.validCallbackTimes = this.getValidCallbackTimes(this.callbackDate, this.supportHours);

        if (!_.isNil(selected)) {
            let selectedStr = selected.format('h:mm a');
            let match = _.find(this.validCallbackTimes, (ct) => {
                return ct.format('h:mm a') === selectedStr;
            });
            if (!_.isNil(match)) {
                this.callbackTime = match;
            }
        }
    }

    submit() {
        if (!this.isValidTime(this.getCallbackDateTime())) {
            this.isError = true;
            return;
        }

        this.isBusy = true;
        this.dataContext.support.scheduleCallback(this.getCallbackDateTime(), this.callbackMessage)
            .then(() => {
                this.$log.debug(`Successfully scheduled customer callback for ${this.formatCallbackDateTimeSortable()}.`);
                this.isSuccess = true;
            })
            .catch((error) => {
                this.$log.error(`Error scheduling customer callback for ${this.formatCallbackDateTimeSortable()}.`, error);
                this.isError = true;
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    // Reset the value of all callback related data to their initial value.
    resetCallbackData() {
        this.initCallbackData();
    }

    // Initialize the value of callback related variables.
    initCallbackData() {
        let date = moment().startOf('day').toDate();
        while (this._isHoliday(date)) {
            date.add(1, 'day');
        }

        this.callbackDate = date;

        this.callbackDateOptions = {
            minDate: moment().startOf('day').toDate(),
            maxDate: moment().add(30, 'day').startOf('day').toDate(),
            showWeeks: false,
            maxMode: 'day',
            dateDisabled: (input) => this._isHoliday(input.date)
        };

        this.callbackMessage = null;
    }

    // Reset the value of all callback related flags to their initial value.
    resetCallbackFlags() {
        this.initCallbackFlags();
    }

    // Returns true if the given date is a Frontpoint holiday; otherwise, false. Supports both JavaScript date objects 
    // and momentjs objects.
    _isHoliday(date) {
        return _.some(this.holidays, holidayStr => moment(date).isSame(moment(holidayStr, 'YYYY-MM-DD'), 'day'));
    }

    // Returns true if the given date is a weekend; otherwise, false. Supports both JavaScript date objects and 
    // momentjs objects.
    _isWeekend(date) {
        return moment(date).isoWeekday() === 6 || moment(date).isoWeekday() === 7;
    }

    // Returns true if the given date is a weekday; otherwise, false. Supports both JavaScript date objects and 
    // momentjs objects.
    _isWeekday(date) {
        return !this._isWeekend(date);
    }

    _mapState(state) {
        return _.cloneDeep({
            holidays: state.application.config.holidays,
            supportHours: state.application.config.businessHours
        });
    }
}

export default {
    template: template,
    bindings: {
    },
    controller: CallbackModalController
};