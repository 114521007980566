import * as angular from 'angular';

// components
import armedPanelModalComponent from './armedPanelModal.component';
import invalidBarCodeModalComponent from './invalidBarCodeModal.component';
import cancelConfirmModalComponent from './cancelConfirmModal.component';

export default angular
.module('fp:activationswizard:airfx:modals', [])
    .component('awArmedPanelModal', armedPanelModalComponent)
    .component('invalidBarCodeModal', invalidBarCodeModalComponent)     
    .component('cancelConfirmModal', cancelConfirmModalComponent);