import * as _ from 'lodash';

import template from './finalizing.html';

class FinalizingComponent {
    /*@ngInject*/
    constructor($document, $ngRedux, $q, $state, constants, actionContext, dataContext, $interval, appActions) {
        this.$document = $document;
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.$state = $state;
        this.constants = constants;
        this.actionContext = actionContext;
        this.dataContext = dataContext;
        this.$interval = $interval;
        this.appActions = appActions;
        this.progressOverlayTitle = 'Finalizing Hub Setup....';
        this.statusChanges = {
            0: '...Registering with Central Station Responders...',
            70: '...Performing quality assurance checks...',
            200: '...Validating account information...',
            310: '...Preparing system to add sensors...',
            540: '...Final registration with Frontpoint HQ...'
        };
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);

        if (this.targetSku === this.constants.panelVersions.iqPanel) {
            this.customerSupportPhone = this.constants.customerSupportIQ.phone;
        } else {
            this.customerSupportPhone = !this.$ngRedux.getState().account.origin.startedOnAmazon
                ? this.constants.customerSupportHub.phone
                : this.constants.customerSupportHub.amazonPhone;
        }

        this.isSearching = true;
        if (!this.startedOnAmazon &&
            this.isInitialOrder &&
            this.hasChildOrders &&
            !this.dataContext.user.isInMobileApp()) {

            this.actionContext.panel.addChildSensorsForInitialWithChildOrders().then(() => {
                this._pollFulfillmentStepStatus();
            });
        } else {
            this._pollFulfillmentStepStatus();
        }
        if (this.isIq4Panel) {
            this.progressOverlayTitle = 'Finalizing Panel Setup....'
        }
    }

    $onDestroy() {
        this._killIntervals();
    }

    _killIntervals() {
        if(this.checkPendingCommandsPollInterval) {
            this.$interval.cancel(this.checkPendingCommandsPollInterval);
        }
    }

    _pollFulfillmentStepStatus() {
        this.checkPendingCommandsPollInterval = this.$interval((count) => {
            return this.dataContext.panel.checkPendingCommands().then(commandsFinished => {
                // run for 10 minutes
                if (count === 100) {
                    this._killIntervals();
                    this.isSearching = false;
                }

                if (commandsFinished) {
                    // change account status to pending activation
                    this.dataContext.account.updateAccountStatusV2(this.constants.accountStatusTypes.pendingActivation)
                        .then(() => {
                            this._killIntervals();
                            this.appActions.completeStep('panel');
                            this.$state.go('panel.complete');
                        })
                        .catch(() => {
                            this._killIntervals();
                            this.isSearching = false;
                        });
                }
            }).catch(() => {
                this._killIntervals();
                this.isSearching = false;
            });
        },
            6000);
    }

    _mapState(state) {
        return {
            startedOnAmazon: state.account.origin.startedOnAmazon,
            isInitialOrder: state.account.user.isInitialOrder,
            hasChildOrders: state.account.order.hasChildOrders,
            targetSku: state.account.airfxFlow.targetSku,
            isIq4Panel: state.account.user.isIq4Panel,
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: FinalizingComponent
};