import './rescan.scss';
import template from './rescan.html';
import * as _ from 'lodash';

class BarcodeRescanController {
    /*@ngInject*/
    constructor(barcodeService, constants, $state, $ngRedux, mobileDetectService, $interval, SharedState, dataContext, actionContext) {
        this.barcodeService = barcodeService;
        this.constants = constants;
        this.$state = $state;
        this.unsubscribe = $ngRedux.connect(this._mapState)(this);
        this.error = _.get($state, 'params.error', undefined) === 'true';
        this.$interval = $interval;
        this.mobileDetectService = mobileDetectService;
        this.SharedState = SharedState;
        this.dataContext = dataContext;
        this.actionContext = actionContext;
        this.isKeypad = this.actionContext.airfxFlow.isKeypad();
    }

    $onInit() {
        this.cameraUrl = this.barcodeService.getCameraUrl();
        this.showProgress = false;
        this.resetDlCodeData();
        this.interval = () => {
            this.$interval(() => {
                const code = document.getElementById('dlCode').value;
                if (!_.isEqual(code, this.initialDlCode)) {
                    this.dlCode = code;
                    this.initialDlCode = _.cloneDeep(code);
                    this.$interval.cancel(this.interval);
                    this.onBarcodeReceived();
                }
            }, this.constants.barcodePollingInterval);
        };
    }

    $onDestroy() {
        this.unsubscribe();
    }

    onBarcodeReceived() {
        const dlCode = _.cloneDeep(this.dlCode);
        this.resetDlCodeData();
        this.barcodeService.activateSensorWithDlCode(dlCode,2);
    }

    scan() {
        if (this.dataContext.user.isBrowser()) {
            this.$state.go('airfx.barcodeScanner');
        } else {
            this.interval();
        }
    }
    onClickBack() {
        if (this.dataContext.user.isBrowser()) {
            this.$state.go('airfx.barcodeScanner');
        } else {
            this.$state.go('airfx.barcodeList');
        }
    }
    
    cancel() {
        this.$state.go('airfx.categoryList');
    }

    resetDlCodeData() {
        this.dlCode = '';
        this.initialDlCode = '';
        document.getElementById('dlCode').value = this.initialDlCode;
    }

    open() {
        this.SharedState.turnOn('cancelConfirmModal');
    }

    cancelBarcode() {
        this.$state.go('airfx.redirectToAirfx', { category: this.category });
    }
    
    _mapState(state) {
        return {
            sku: _.map(state.account.order.currentSku),
            category: state.account.airfxFlow.category
        };
    }
}

export default {
    template: template,
    bindings: {
    },
    controller: BarcodeRescanController
}