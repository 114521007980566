import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';
import 'ui-router.grant';
import * as _ from 'lodash';

import './shell.scss';

import shellComponent from './shell.component';
import commonModule from '../common';
import loginModule from '../login';
import navModule from './nav';
import errorModule from './error';

export default angular
    .module('fp:activationswizard:shell', [
        'ui.router',
        'ui.router.grant',
        ngRedux,
        commonModule.name,
        loginModule.name,
        navModule.name,
        errorModule.name,
       'angular-svg-round-progressbar'
    ])
    // SateBuilder Decorator
    .config(/*@ngInject*/ ($stateProvider) => {
        $stateProvider
            .decorator('views', (state, superFn) => {
                const onlyOnFirstLoadAnnotation = 'runOnlyOnFirstLoad';

                _.forEach(state.resolve, (resolveAnnotatedFn) => {
                    let resolveFnIdx = resolveAnnotatedFn.length - 1;
                    let resolveFn = resolveAnnotatedFn[resolveFnIdx];

                    // Any function that injects the only on first load annotation should only be 
                    // executed on the first load of the application.
                    if (_.includes(resolveAnnotatedFn, onlyOnFirstLoadAnnotation)) {
                        // Decorate the resolve function so that it is only invoked on the
                        // first load. After the first load a function that returns the initial result 
                        // will be invoked.
                        //
                        // NOTE: We cannot use an arrow function because it does not expose an 
                        // arguments object; instead, arguments is simply a reference to the name 
                        // in the enclosing scope.
                        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions
                        resolveAnnotatedFn[resolveFnIdx] = function() {
                            // We cannot inject values into the provider so we have to reference the
                            // window object directly.
                            if (!window.isFirstLoadComplete) {
                                resolveFn._result = resolveFn(...arguments);
                                return resolveFn._result;
                            } else {
                                return () => resolveFn._result;
                            }
                        };
                    }
                });

                return superFn(state);
            });
    })
    .config( /*@ngInject*/ ($stateProvider) => {
        $stateProvider
            .state('root', {
                abstract: true,
                template: '<aw-shell></aw-shell>'
            })
            .state('secure', {
                abstract: true,
                parent: 'root',
                template: '<div ui-view></div>',
                resolve: {
                    // Any resolve that injects this value will only be executed the first time the secure application 
                    // state is resolved. See the $stateBuilder decorator at the top of this file.
                    runOnlyOnFirstLoad: /*@ngInject*/ ($window) => {
                        return !$window.isFirstLoadComplete;
                    },
                    startLoad: /*@ngInject*/ (actionContext) => {
                        actionContext.app.tryStartLoad();
                    },
                    currentUser: /*@ngInject*/ (grant) => {
                        return grant.only({ test: 'user', state: 'login' });
                    },
                    isPendingActivation: /*@ngInject*/ (grant, $ngRedux, $q, $location, /*resolve after*/ currentUser) => {
                        // Only the initial order cares about pending activation status.
                        if (!$ngRedux.getState().account.user.isInitialOrder) {
                            return $q.resolve(false);
                        }
                        
                        return grant.only({ test: 'isPendingActivation', state: 'activation.return' });
                    },
                    loadState: /*@ngInject*/ ($log, $ngRedux, $q, actionContext, constants, $location, /*resolve after*/ isPendingActivation, runOnlyOnFirstLoad) => {
                        if (constants.disableStateHydration || $location.search().reset) {
                            $log.debug('State restoration has been disabled.');
                            return $q.resolve(false);
                        }

                        if ($ngRedux.getState().application.isHydrated) {
                            $log.debug('Application state already hydrated.');
                            return $q.resolve(false);
                        }

                        return actionContext.login.hydrate();
                    },
                    processState: /*@ngInject*/ ($log, $ngRedux, $q, $state, $timeout, /*resolve after*/ loadState, runOnlyOnFirstLoad, $window, dataContext, actionContext) => {
                        const ignoredStates = [
                            'login',
                            'airfx.list',
                            'setup',
                            'activate',
                            'zwave-completed',
                            'airfx.categoryList'
                        ];

                        if (loadState === false) {
                            return $q.resolve();
                        }
                        const hydratedState = $ngRedux.getState().router.currentState.name;
                        const hydratedParams = $ngRedux.getState().router.currentParams;

                        // The last state does not exist. Let the application continue normally.
                        if (_.isEmpty(hydratedState) ) {

                            $log.debug('Hydrated state is empty. No redirect performed.');
                            return $q.resolve();
                        }

                        // The state is from the orderselector for additional order, do not hydrate state
                        if (dataContext.user.isInMobileApp()) {
                            //clear the state if it is the first session attempt
                            if (!dataContext.user.isFirstAdditionalAttempt()) {
                                actionContext.order._resetProducts();
                                dataContext.user.setFirstAdditionalAttempt();
                                return $q.resolve();
                            }

                            $log.debug('From order selector and is an additional order. No hydration necessary.');
                            return $state.go(hydratedState, hydratedParams, { location: 'replace' });
                        }

                        // The last state is in the ignore list. Let the application continue normally.
                        if (_.includes(ignoredStates, hydratedState)) {
                            $log.debug('Hydrated state is explicitly ignored. No redirect performed.');
                            return $q.resolve();
                        }

                        // The next state is the previous state. Do not perform a redict.
                        const nextState = $state.next.name;
                        if (hydratedState === $state.next.name) {
                            $log.debug(`Hydrated state [${hydratedState}] equals next state [${nextState}]. No redirect performed.`);
                            return $q.resolve();
                        }

                        return $timeout(() => {
                            $log.debug(`Redirecting to hydrated state [${hydratedState}].`);
                            return $state.go(hydratedState, hydratedParams, { location: 'replace' });
                        });
                    },
                    loadData: /*@ngInject*/ ($state, $timeout, $q, actionContext, constants, $ngRedux, /*resolve after*/ processState, runOnlyOnFirstLoad) => {
                        const keepAliveFn = actionContext.partner.createKeepAliveFunction($q, actionContext);
                        const pingFn = actionContext.partner.createPingFunction($q, actionContext);

                        let canProceed = () => {
                            return $timeout(() => {
                                return $q.resolve();
                            });
                        };

                        //hard fail promise to error page
                        let hardFail = () => {
                            return $timeout(() => {
                                $state.go('error');
                                return $q.reject();
                            });
                        };

                        let addErrors = (errorType) => {
                            //log the error
                            actionContext.common.error(constants.actionTypes.error, constants.sectionTypes.welcome, `Inital load failed for function call [${errorType}]`);
                            if (_.isNil(actionContext.app.intialLoadErrors))
                                actionContext.app.intialLoadErrors = [];
                            //push a list to initial lost errors
                            actionContext.app.intialLoadErrors.push(errorType);

                            switch (errorType) {
                                case 'fetchCustomer':
                                case 'fetchCode':
                                case 'fetchPanelVersion':
                                case 'fetchContacts':
                                case 'fetchAddress':
                                case 'fetchStates':
                                case 'fetchEligibleSensorNames':
                                case 'fetchEndDate':
                                case 'getUserSettings':
                                    return canProceed();
                                case 'fetchOrderProducts':
                                case 'fetchConfiguration':
                                case 'fetchSensors':
                                    return hardFail();
                                default:
                                    return canProceed();
                                }
                        };

                        if (!$ngRedux.getState().account.user.isAmazonUser) {
                            var promises = [
                                actionContext.customer.fetchCustomer().catch((result) => addErrors(result)),
                                actionContext.panel.fetchPanelVersion().catch((result) => addErrors(result)),
                                actionContext.predispatch.fetchContacts().catch((result) => addErrors(result)),
                                actionContext.premises.fetchAddress().catch((result) => addErrors(result)),
                                actionContext.order.fetchOrderProducts().catch((result) => addErrors(result)),
                                actionContext.order.fetchOrderBoxType().catch((result) => addErrors(result)),
                                actionContext.app.fetchEligibleSensorNames().catch((result) => addErrors(result)),
                                actionContext.app.fetchStates().catch((result) => addErrors(result)),
                                actionContext.app.fetchConfiguration().catch((result) => addErrors(result)),
                                actionContext.testmode.fetchEndDate().catch((result) => addErrors(result)),
                                actionContext.login.getUserSettings().catch((result) => addErrors(result)),
                                actionContext.device.fetchSensors().catch((result) => addErrors(result)),
                                actionContext.account.getAccountInformation().catch((result) => addErrors(result)),
                                actionContext.account.getAccountOrigin().catch((result) => addErrors(result)),
                                actionContext.zwave.zWaveInitialState(),
                                keepAliveFn(),
                                pingFn()
                            ];
                            return $q.all(promises).then((results) => {
                                if (!$ngRedux.getState().account.customer.isVideoOnly) {

                                    const additionalPromises = [
                                        actionContext.code.fetchCode().catch((result) => addErrors(result)),
                                    ];

                                    return Promise.all(additionalPromises);
                                }

                                return Promise.resolve();
                            });
                        } else {
                            var promises = [
                                actionContext.app.fetchStates().catch((result) => addErrors(result)),
                                actionContext.app.fetchConfiguration().catch((result) => addErrors(result)),
                                actionContext.kitCustomer.fetchKitCustomer(),
                                actionContext.order.fetchOrderProducts()
                            ];
                            return $q.all(promises).then(() => {
                                const { account: { kitCustomer: { accountId } } } = $ngRedux.getState();
                                if (accountId)
                                {
                                    actionContext.account.getAccountInformation();
                                }
                            });
                        }
                    },
                    setupComplete: /*@ngInject*/ ($window, /*resolve after*/ loadData) => {
                        $window.isFirstLoadComplete = true;
                        return true;
                    },
                    isInitialOrder: /*@ngInject*/ ($ngRedux, /*resolve after*/ setupComplete) => {
                        return $ngRedux.getState().account.user.isInitialOrder === true;
                    },
                    isAdditionalOrder: /*@ngInject*/ ($ngRedux, /*resolve after*/ setupComplete) => {
                        return $ngRedux.getState().account.user.isInitialOrder === false;
                    },
                    isIQPanel: /*@ngInject*/ ($ngRedux, constants, /*resolve after*/ setupComplete) => {
                        return $ngRedux.getState().system.panel.type === constants.panelVersions.iqPanel;
                    },
                    isXTPanel: /*@ngInject*/ ($ngRedux, constants, /*resolve after*/ setupComplete) => {
                        return $ngRedux.getState().system.panel.type === constants.panelVersions.xtPanel;
                    },
                    isClimaxHub: /*@ngInject*/ ($ngRedux, constants, /*resolve after*/ setupComplete) => {
                        return $ngRedux.getState().system.panel.type === constants.panelVersions.climaxHub;
                    },
                    getInitialSystemSettings: /*@ngInject*/ ($ngRedux, panelActions, $state, /*resolve after*/ setupComplete) => {
                        // As part of the above handleInitialWithChildOrders we already do a getDevices, no need to do it again
                        if (!$ngRedux.getState().account.user.isAmazonUser && !$ngRedux.getState().account.customer.isVideoOnly) {
                            let isLoadingSystemSettings = $ngRedux.getState().system.panel.isLoadingSystemSettings;
                            if (!isLoadingSystemSettings) {
                                return panelActions.getSystemSettings(false).then(() => {
                                    return panelActions.getDevices(false);
                                }).catch(() => {
                                    $state.go('error');
                                });
                            }
                        } else {
                            return null;
                        }
                    }
                },
                onEnter: ($interval, $ngRedux, $q, $rootScope, $state, $window, actionContext, constants) => {
                    const keepAliveFn = actionContext.partner.createKeepAliveFunction($q, actionContext);
                    $window.keepAliveInterval = $interval(keepAliveFn, constants.polling.application.keepAliveInterval);
                    const pingFn = actionContext.partner.createPingFunction($q, actionContext);
                    $window.pingInterval = $interval(pingFn, constants.polling.application.pingInterval);

                    const errorState = 'error-partner';
                    const checkStatusFn = actionContext.partner.createCheckStatusFunction($ngRedux);
                    const reactStatusFn = actionContext.partner.createReactToStatusFunction($state, errorState);
                    const watchStatusFn = actionContext.partner.createWatchStatusFunction(checkStatusFn, reactStatusFn, errorState);
                    if (!checkStatusFn()) {
                        return reactStatusFn();
                    }
                    $window.watchStatus = $rootScope.$on('$stateChangeStart', watchStatusFn);
                },
                onExit: /*@nInject*/ ($interval, $window) => {
                    $interval.cancel($window.keepAliveInterval);
                    $interval.cancel($window.pingInterval);
                    $window.watchStatus();
                    delete $window.keepAliveInterval;
                    delete $window.pingInterval;
                    delete $window.watchStatus;
                }
            });
    })
    .component('awShell', shellComponent);