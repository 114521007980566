import template from "./success.html";
import * as _ from 'lodash';

class SuccessController {
    /*@ngInject*/
    constructor($ngRedux, $state, segment, deviceActions, actionContext, constants, $stateParams) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.segment = segment;
        this.deviceActions = deviceActions;
        this.actionContext = actionContext;
        this.sensorsCategory = 'sensors';
        this.constants = constants;
        this.swapType = $ngRedux.getState().system.swap.swapType;
        this.showReturnMessage = true;

    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        if (this.swapType === this.constants.swapType.radio) {
            this.segment.registerEvent(this.constants.events.segment.radioSwapComplete, {});
        } else {
            this.segment.registerEvent(this.constants.events.segment.panelSwapComplete, {});
            this.actionContext.modSwap.onModSwapComplete();
        }

        if (this.currentSku === this.constants.panelVersions.climaxHub
            && this.targetSku === this.constants.panelVersions.iqPanel) {
            this.showReturnMessage = false;
        } 
    }

    goToNext() {
        this.$state.go('airfx.categoryList');
    }

    _mapState(state) {
        return _.cloneDeep({
            targetSku: state.account.airfxFlow.targetSku,
            currentSku: state.account.airfxFlow.sku
        });
    }
}

export default {
    template: template,
    bindings: {},
    controller: SuccessController
};