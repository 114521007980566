import * as _ from 'lodash';
import moment from 'moment';

import AbstractTestController from './AbstractTestController';

import template from './event.html';

class EventTestController extends AbstractTestController {
    /*@ngInject*/
    constructor($document, $log, $ngRedux, $q, $state, constants, actionContext, appActions, dataContext) {
        super($document, $log, $ngRedux, $state, constants);

        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.appActions = appActions;
        this.dataContext = dataContext;

        this.constants = constants;
        this.actionContext = actionContext;
        this.isSelectionNo = false;
        this.showSkippedModal = false;
        this.isEmailLive = false;
        this.hasSkippedPrior = actionContext.app.fpStore.get('skippedSensor', 'session');

        // look for sensor status updates up to 23 hours in the past
        this.startDate = moment.utc().add(-23, 'h');
    }

    $onInit() {
        super.$onInit();
        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.isIq4Panel = this.$ngRedux.getState().account.user.isIq4Panel;
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
        this.setIsEmailLive();
    }

    canSupportSensor(sensor) {
        return true;
    }

    getName() {
        return 'Event';
    }

    startTest() {
        if (!this.$ngRedux.getState().account.user.isIq4Panel) {
            const initialStatus = this.sensor.StatusType;
            const initialDate = moment(this.sensor.StatusDate);

            // If we already know the last sensor status we should simulate
            // polling for the sensor so that it gives the user the impression
            // that we are doing something.
            let shouldSimulate = !_.isNil(initialStatus) &&
                initialStatus !== this.constants.deviceStatus.tampered.Name &&
                initialStatus !== this.constants.deviceStatus.malfunction.Name &&
                initialStatus !== this.constants.deviceStatus.unknown.Name;

            //for additional orders do not do the 23 hour check
            const isAdditionalOrder = this.appActions.isAdditional();
            const displayName = this.actionContext.product.getDisplayName(this.sensor.ProductSku);
            if (isAdditionalOrder) {
                if (displayName === 'Door & Window Sensor' || displayName === 'Recessed Door Sensor') {
                    shouldSimulate = false;
                } else {
                    shouldSimulate = true;
                }
            } else {
                if (displayName === 'Motion Sensor') {
                    shouldSimulate = true;
                } else {
                    shouldSimulate = shouldSimulate && this.startDate.isBefore(initialDate);
                }
            }

            this.$q.resolve().then(() => {
                return shouldSimulate
                    ? this._handleSimulate()
                    : this._handlePoll();
            }).then(() => {
                this.$state.go('sensor.item.complete');
            });
        }
    }

    stopTest() {
        if (!this.$ngRedux.getState().account.user.isIq4Panel) {
            this.actionContext.device.cancelPoll(this.poll);
        }
    }

    _handlePoll() {
        const deviceService = this.actionContext.device;
        const maxInterval = this.constants.polling.tests.event.pollingInterval;
        const maxAttempts = this.constants.polling.tests.event.maxIntervalCount;

        // ensure we are dealing with moment objects
        const oldDate = moment(this.sensor.StatusDate);

        let isSuccess = false;
        this.poll = deviceService.startPoll(this.sensor, '', maxInterval, maxAttempts);
        return this.poll.then(null, null, () => {
            const isTampered = this.sensor.StatusType === this.constants.deviceStatus.tampered.Name;
            const isMalfunction = this.sensor.StatusType === this.constants.deviceStatus.malfunction.Name;
            const isUnknown = this.sensor.StatusType === this.constants.deviceStatus.unknown.Name;
            const newDate = moment(this.sensor.StatusDate);
            if (!isTampered && !isUnknown && oldDate.isBefore(newDate) && !isMalfunction) {
                isSuccess = true;
                deviceService.cancelPoll(this.poll);
            }
        }).catch((err) => {
            return err === 'canceled' && isSuccess
                ? this.$q.resolve()
                : this.$q.reject(err);
        });
    }

    _handleSimulate() {
        this.poll = this.actionContext.device.simulatePoll(this.sensor);
        return this.poll;
    }

    handleYesClick() {
        this.$state.go('sensor.item.complete');
    }

    handleNoClick() {
        this.isSelectionNo = true;
    }

    handleSkip() {
        if (this.isEmailLive) {
            this.showSkippedModal = !this.hasSkippedPrior;
            if (this.hasSkippedPrior) {
                this.$state.go('sensor.item.mark-skipped');
            }
        } else {
            this.showSkippedModal = true;
        }
    }

    onNegativeSkippedModalClick() {
        this.showSkippedModal = false;
    }

    onPositiveSkippedModalClick() {
        this.showSkippedModal = false;
        if (this.isEmailLive) {
            this.actionContext.app.fpStore.set('skippedSensor', true, 'session');
            this.dataContext.email.sendEmail("SensorsSetUp");
        }
        this.$state.go('sensor.item.mark-skipped');
    }

    setIsEmailLive() {
        return this.dataContext.email.isEmailLive().then(result => {
            this.isEmailLive = result;
        });
    }
}

export default {
    template: template,
    bindings: {},
    controller: EventTestController
};