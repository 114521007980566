import * as _ from 'lodash';
import ensure from '../util/ensure';

export const ORDER_LINE_ITEMS_FETCH_REQUEST = 'ORDER_LINE_ITEMS_FETCH_REQUEST';
export const ORDER_LINE_ITEMS_FETCH_FAILURE = 'ORDER_LINE_ITEMS_FETCH_FAILURE';
export const ORDER_LINE_ITEMS_FETCH_SUCCESS = 'ORDER_LINE_ITEMS_FETCH_SUCCESS';

export const ORDER_DELIVERED_FETCH_REQUEST = 'ORDER_DELIVERED_FETCH_REQUEST';
export const ORDER_DELIVERED_FETCH_FAILURE = 'ORDER_DELIVERED_FETCH_FAILURE';
export const ORDER_DELIVERED_FETCH_SUCCESS = 'ORDER_DELIVERED_FETCH_SUCCESS';

export const ORDER_PRODUCT_QUEUE_AIRFX = 'ORDER_PRODUCT_QUEUE_AIRFX';
export const ORDER_PRODUCT_DEQUEUE_AIRFX = 'ORDER_PRODUCT_DEQUEUE_AIRFX';

export const ORDER_PRODUCT_ACTIVATE_REQUEST = 'ORDER_PRODUCT_ACTIVATE_REQUEST';
export const ORDER_PRODUCT_ACTIVATE_FAILURE = 'ORDER_PRODUCT_ACTIVATE_FAILURE';
export const ORDER_PRODUCT_ACTIVATE_SUCCESS = 'ORDER_PRODUCT_ACTIVATE_SUCCESS';
export const ORDER_PRODUCT_ADDITIONAL_EQUIPMENT = 'ORDER_PRODUCT_ADDITIONAL_EQUIPMENT';

export const ORDER_BOX_TYPE = 'ORDER_BOX_TYPE';
export const Is_Feedonomic_Order = 'Is_Feedonomic_Order';

export const SET_PROGRESS = 'SET_PROGRESS';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';

class OrderActions {
    /*@ngInject*/
    constructor($log, $ngRedux, $q, dataContext, constants, $timeout, appActions) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.dataContext = dataContext;
        this.constants = constants;
        this.$timeout = $timeout;
        this.unsubscribe = $ngRedux.connect(this._mapState)(this);
        this.appActions = appActions;
    }

    queueAirFx(product) {
        this.$ngRedux.dispatch({
            type: ORDER_PRODUCT_QUEUE_AIRFX,
            payload: {
                product: product
            }
        });
    }

    dequeueAirFx(product) {
        this.$ngRedux.dispatch({
            type: ORDER_PRODUCT_DEQUEUE_AIRFX,
            payload: {
                product: product
            }
        });
    }

    setOrderBoxType(boxType) {
        this.$ngRedux.dispatch({
            type: ORDER_BOX_TYPE,
            payload: {
                boxType: boxType
            }
        });
    }

    setIfOrderIsFeedonomic(isFeedonomicOrder) {
        this.$ngRedux.dispatch({
            type: Is_Feedonomic_Order,
            payload: {
                isFeedonomicOrder: isFeedonomicOrder
            }
        });
    }

    isInitialOrder() {
        return this.$ngRedux.getState().account.user.isInitialOrder && !this.appActions.isInitialWithAdditional();
    }

    /**
    * Fetches a list of order line items for the current user and adds them
    * to the application state.
    * @returns {promise} Returns a promise that returns undefined if
    * resolved and an error if rejected. 
    */
    fetchOrderProducts() {
        this._onFetchOrderLineItems();
        return this.dataContext.account.order.getOrderProducts(!this.dataContext.user.isInMobileApp(), false)
            .then((products) => {
                this._onFetchOrderLineItemsSuccess(products);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error fetching the customer\'s order line items.', error);
                this._onFetchOrderLineItemsFailure(error);
                return this.$q.reject('fetchOrderProducts');
            });
    }

    fetchOrderBoxType() {
        if (this.dataContext.user.isInMobileApp()) {
            this.setOrderBoxType(2);
            return this.$q.resolve();
        }
        return this.dataContext.account.order.getOrderBoxType()
            .then(response => {
                this.setOrderBoxType(response);
            })
            .catch((error) => {
                this.$log.error('Error fetching the fetchOrderBoxType', error);
                return this.$q.reject('fetchOrderBoxType');
            });
    }

    /**
    * Gets a count of the number of products in the current user's
    * order that match the given SKU.
    * 
    * @param {string=} sku A product SKU. 
    * @returns {number} The number of products in the current user's 
    * order the match the given SKU. 
    */
    getItemCount(sku) {
        let itemsBySku = this.getItemsBySku();
        let items = itemsBySku[sku];

        return _.isNil(items) ? 0 : items.length;
    }

    /**
    * Gets a hash object of items mapped to their SKU.
    * 
    * @returns {Object.<string, Array.<Object>>} A hash object of items mapped 
    * to their SKU.
    */
    getItemsBySku() {
        return this.exposeItemsBySku(this.$ngRedux.getState());
    }

    getItemsToActivate() {
        return _.filter(this.getItemsById(), (item) => {
            const isActivatable = !_.some(this.constants.productsNotToActivate, (product) => {
                return _.includes(!_.isNil(item.ProductName) ? item.ProductName.toLowerCase() : null, product);
            });
            if (isActivatable && this.appActions.isInitialWithAdditional()) {
                return !item.IsInitialOrder;

            } else
                return isActivatable;
        });
    }


    /**
    * Removes skipped sensors that have not been deleted from the panel yet
    *
    * @param {Array.<Object>} devices a list of devices to trim skipped sensors from
    * @returns {Object.<number, Object>} A hash object of items mapped
    * to their ID.
    */
    getSensorsThatAreSkippedButStillActivated(devices) {
        //find sensors that are connected, meaning we added them to the panel already
        const connectedSensors = _.filter(devices, (sensor) => {
            return _.get(sensor, 'IsConnected', false) === true;
        });

        if (connectedSensors.length > 0) {
            const sensorsToRemove = [];
            //find sensors that are skipped from previous step
            const skippedSensorsList = _.filter(this.processedDevices, (device) => {
                return _.get(device, 'isSkipped', false) === true;
            });

            //make list of skipped sensors that are connected to panel
            _.forEach(skippedSensorsList, (sensor) => {
                const skippedSensor = _.find(connectedSensors, { DLCode: sensor.DLCode });
                if (!_.isNil(skippedSensor))
                    sensorsToRemove.push(sensor);
            });

            //remove them from the list
            devices = _.filter(devices, (sensor) => {
                return _.isNil(_.find(sensorsToRemove, { DLCode: sensor.DLCode }));
            });

            return devices;
        } else
            return devices;

    }

    /**
    * Gets a hash object of items mapped to their ID.
    * 
    * @returns {Object.<number, Object>} A hash object of items mapped
    * to their ID.
    */
    getItemsById() {
        return this.exposeItemsByID(this.$ngRedux.getState());
    }

    /**
    * Gets a hash object of items mapped to their SKU. This function
    * should be used instead of accessing the state object directly.
    * 
    * @param {Object} state The state object that will be used to retrieve the result.
    * @returns {Object.<string, Array.<Object>>} A hash object of items mapped to
    * their SKU. 
    */
    exposeItemsBySku(state) {
        ensure.isNotNullOrUndefined('state', state);
        return state.account.order.itemsBySku;
    }

    /**
    * Gets a hash object of items mapped to their ID. This function
    * should be used instead of accessing the state object directly.
    * 
    * @param {Object} state The state object that will be used to reterive the result.
    * @returns {Object.<number, Object>} A hash object of items mapped to their ID.
    */
    exposeItemsByID(state) {
        ensure.isNotNullOrUndefined('state', state);
        return state.account.order.itemsByID;
    }

    hasProduct(skuId) {
        const allItems = this.getItemsBySku();
        return !_.isNil(allItems) && !_.isEmpty(allItems[skuId]);
    }

    /**
    * Returns true if product is in initial order
    *
    * @param {string} skuId The item's sku to check for
    * @returns {boolean} True if product is in initial order; otherwise, false.
    */
    hasProductInInitial(skuId) {
        const allItems = this.getItemsBySku();

        // filter out non initial order 
        _.forEach(allItems,
            (items) => {
                _.remove(items,
                    (item) => {
                        return item.IsInitialOrder !== true;
                    });
            });

        return !_.isNil(allItems) && !_.isEmpty(allItems[skuId]);
    }

    /**
    * Returns true if customer has camera in order
    *
    * @returns {boolean} True if the customer has camera in order; otherwise, false.
    */
    hasCamera() {
        return _.some(this.constants.cameras.skus, (sku) => {
            return this.hasProduct(sku);
        });
    }

    /**
    * Returns true if customer has camera in initial order
    *
    * @returns {boolean} True if the customer has camera in initial order; otherwise, false.
    */
    hasCameraInInitial() {
        return _.some(this.constants.cameras.skus, (sku) => {
            return this.hasProductInInitial(sku);
        });
    }


    /**
    * Returns true if customer has lock in order
    *
    * @returns {boolean} True if the customer has lock in order; otherwise, false.
    */
    hasLock() {
        const allItems = this.getItemsById();
        const locks = _.filter(allItems, (item) => {
            return _.includes(this.constants.zWaveProducts.locks, item.ProductSku) && !item.isActivated;
        });

        return !_.isEmpty(locks);
    }

    /**
    * Returns true if customer has a Yale lock
    * @returns {boolean} True if customer has new yale lock; otherwise, false.
    */
    hasYaleLock() {
        const allItems = this.getItemsById();
        const locks = _.filter(allItems, (item) => {
            return _.includes(this.constants.zWaveProducts.yaleLocks, item.ProductSku) && !item.isActivated;
        });

        return !_.isEmpty(locks);
    }

    /**
    * Returns true if customer has a keypad
    */
    hasKeypad() {
        const allItems = this.getItemsById();
        const keypad = _.filter(allItems, (item) => {
            return _.includes(this.constants.keypads.skus, item.ProductSku);
        });

        return !_.isEmpty(keypad);
    }

    /** 
    * Returns true if customer has light in order
    *
    * @returns {boolean} True if the customer has light in order; otherwise, false.
    */
    hasLight() {
        const allItems = this.getItemsById();
        const lights = _.filter(allItems, (item) => {
            return _.includes(this.constants.zWaveProducts.lights, item.ProductSku) && !item.isActivated;
        });

        return !_.isEmpty(lights);
    }

    /**
    * Returns true if customer has lock in order in initial order
    *
    * @returns {boolean} True if customer has lock in order in initial order; otherwise, false.
    */
    hasLockInInitial() {
        return _.some(this.constants.zWaveProducts.locks, (sku) => {
            return this.hasProductInInitial(sku);
        });
    }

    /**
    * Returns true if a given item is already connected to the customer's panel.
    *
    * @param {Object} item item to check if it's connected to customer's panel
    * @returns {boolean} True if the given item is already connected to the customer's panel; otherwise, false.
    */
    isConnected(item) {
        return item.IsConnected === true;
    }

    /**
    * Fetches a list of delivered orders and adds them to the application state.
    *
    * @returns {promise} that returns list of delivered orders or error
    */
    fetchDeliveredAndUnactivatedOrders() {
        this._onFetchDeliveredOrders();

        return this.dataContext.account.order.getDeliveredAndUnactivatedOrders()
            .then((orders) => {
                this._onFetchDeliveredOrdersSuccess(orders);
                return this.$q.resolve();
            })
            .catch((error) => {
                this.$log.error('Error fetching the customer\'s delivered orders.', error);
                this._onFetchDeliveredOrdersFailure(error);
                return this.$q.reject(error);
            });
    }

    hasAdditionalEquipment(addtoState) {
        return this.dataContext.account.order.getOrderProducts(false, true)
            .then((products) => {
                let items = _.filter(products, (sensor) => {
                    return !_.isNil(sensor.DLCode) || (_.isNil(sensor.DLCode) && !_.isNil(sensor.MacAddress));
                });

                items = _.filter(items, (sensor) => {
                    return !sensor.IsConnected && !sensor.IsInitialOrder && !(sensor.IsConnected && _.includes(_.map(this.constants.zWaveProducts.locks), sensor.ProductSku));
                });

                if (!_.isNil(addtoState) && addtoState && items.length !== 0) {
                    this._resetProducts();
                    this._onFetchOrderLineItemsSuccess(items);
                }

                this._setAdditionalEquipment(items);
                return items.length > 0;
            })
            .catch((error) => {
                this.$log.error('Error fetching the customer\'s order line items.', error);
                return this.$q.reject(error);
            });
    }

    /**
    * Sets the given order product as activated in the application state.
    *
    * @param {Object} product product to activate
    * @returns {promise} returns result of activating customer's order product
    */
    setOrderProductActivated(product) {
        this._handleOrderProductActivateRequest(product);

        this.$log.debug(`Activating customer\'s order product ${product.OrderProductID}...`);
        return this.dataContext.account.order.setOrderProductActivated(product.OrderProductID)
            .then((result) => {
                this.$timeout(() => {
                    this._handleOrderProductActivateSuccess(product);
                    this.$log.debug(`Successfully activated customer\'s order product ${product.OrderProductID}.`);
                    return this.$q.resolve(result);
                }, 1000);
            })
            .catch((error) => {
                this._handleOrderProductActivateFailure(error);
                this.$log.error(`Error activating customer\'s order product ${product.OrderProductID}.`, error);
                return this.$q.reject(error);
            });
    }

    /**
    * Keeps track of current progress
    *
    * @param {number} progress decimal value to set progress from 0 to 1
    */
    setProgress(progress) {
        this.$ngRedux.dispatch({
            type: SET_PROGRESS,
            payload: {
                progress: progress
            }
        });
    }

    _resetProducts() {
        this.$ngRedux.dispatch({
            type: RESET_PRODUCTS
        });
    }
    _dispatch(obj) {
        this.$ngRedux.dispatch(obj);
    }

    _onFetchOrderLineItems() {
        this._dispatch({
            type: ORDER_LINE_ITEMS_FETCH_REQUEST,
            metadata: {
                persist: false
            }
        });
    }

    _onFetchOrderLineItemsFailure(error) {
        this._dispatch({
            type: ORDER_LINE_ITEMS_FETCH_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onFetchOrderLineItemsSuccess(data) {
        this._dispatch({
            type: ORDER_LINE_ITEMS_FETCH_SUCCESS,
            payload: {
                data: data
            }
        });
    }

    _onFetchDeliveredOrders() {
        this.$ngRedux.dispatch({
            type: ORDER_DELIVERED_FETCH_REQUEST
        });
    }

    _onFetchDeliveredOrdersFailure(error) {
        this.$ngRedux.dispatch({
            type: ORDER_DELIVERED_FETCH_FAILURE,
            payload: {
                error: error
            }
        });
    }

    _onFetchDeliveredOrdersSuccess(data) {
        this.$ngRedux.dispatch({
            type: ORDER_DELIVERED_FETCH_SUCCESS,
            payload: {
                orders: data
            }
        });
    }

    _handleOrderProductActivateRequest(product) {
        this.$ngRedux.dispatch({
            type: ORDER_PRODUCT_ACTIVATE_REQUEST,
            payload: {
                product
            }
        });
    }

    _handleOrderProductActivateFailure(error) {
        this.$ngRedux.dispatch({
            type: ORDER_PRODUCT_ACTIVATE_FAILURE,
            payload: {
                error
            },
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.modSwap.sensorActivation,
                description: error,
                actionType: 'Error'
            }
        });
    }

    _handleOrderProductActivateSuccess(product) {
        this.$ngRedux.dispatch({
            type: ORDER_PRODUCT_ACTIVATE_SUCCESS,
            payload: {
                product
            },
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.modSwap.sensorActivation,
                description: `Activation date set for OrderProductId: [${product.OrderProductID}] DLCode: [${product.DLCode}] SKU: [${product.ProductSku}] SensorNumber: [${product.SensorNumber}] .`,
                actionType: 'Info'
            }
        });
    }

    _setAdditionalEquipment(items) {
        this.$ngRedux.dispatch({
            type: ORDER_PRODUCT_ADDITIONAL_EQUIPMENT,
            payload: {
                items
            }
        });
    }

    getAdditionalEquipment() {
        return this.additionalEquipment;
    }

    _mapState(state) {
        return {
            processedDevices: _.map(state.system.devices.sensorsByID),
            items: _.map(state.account.order.itemsByID),
            additionalEquipment: _.map(state.account.order.additionalEquipment),
        };
    }
}

export default OrderActions;