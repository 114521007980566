import template from './waterFloodInstallationModal.html';

class WaterFloodInstallationModalController {
    /*@ngInject*/
    constructor () {
    }
}

export default {
    template: template,
    bindings: {},
    controller: WaterFloodInstallationModalController
};