import './manual.scss';
import template from './manual.html';
import * as _ from 'lodash';

class ManualBarcodeController {
    /*@ngInject*/
    constructor(barcodeService, constants, $state, $ngRedux, SharedState, actionContext) {
        this.barcodeService = barcodeService;
        this.dlCode = '';
        this.constants = constants;
        this.$state = $state;
        this.unsubscribe = $ngRedux.connect(this._mapState)(this);
        this.form = 'barcode-form';
        this.error = _.get($state, 'params.error', undefined) === 'true';
        this.SharedState = SharedState;
        this.actionContext = actionContext;
        this.submitted = false;
        this.isKeypad = this.actionContext.airfxFlow.isKeypad();
        this.regex = !this.isKeypad ? this.constants.regex.dlCode : this.constants.regex.keypadDlCode;
    }

    $onDestroy() {
        this.unsubscribe();
    }

    submit() {
        this.dlCode = this._trimDl(this.dlCode).toUpperCase();
        this.invalidDlCode = true;
        if (this.dlCode !== '') {
            this.invalidDlCode = !this.barcodeService.activateSensorWithDlCode(this.dlCode, 0, true);
        }
        this.submitted = true;
        this.error = true;
        this.dlCode = '';
    }

    showError(formData) {
        this.errorMsg = 'Please enter a valid 6 digit code that starts after DL (i.e. DL 123CA4).';
        if (this.isKeypad)
            this.errorMsg = 'Please enter a valid 14 digit code that starts after DL (i.e. DL 00A7F274400002).';
        return formData.$dirty && formData.$invalid;
    }

    onClickBack() {
        this.$state.go(this.prevState);
    }

    cancelBarcode() {
        this.$state.go('airfx.redirectToAirfx', { category: this.category });
    }

    _trimDl(code) {
        if (!_.isNil(code)) {
            code.replace(/[|&;$%@"<>()+,]/g, '');
            const codePrefix = 'DL';
            if (_.startsWith(_.lowerCase(code), _.lowerCase(codePrefix))
                && code.length === 6 + codePrefix.length) {
                code = _.trimStart(codePrefix);
            }
        }
        return code;
    }

    _mapState(state) {
        return {
            sku: state.account.airfxFlow.sku,
            category: state.account.airfxFlow.category,
            prevState: state.router.prevState.name
        };
    }

    open() {
        this.SharedState.turnOn('cancelConfirmModal');
    }
}

export default {
    template: template,
    bindings: {
    },
    controller: ManualBarcodeController
}