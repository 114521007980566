import template from './subCategoryListItem.html';

class SubCategoryListItemController {
    /*@ngInject*/
    constructor($state, actionContext) {
        this.$state = $state;
        this.actionContext = actionContext;
    }

    goToDeviceSetup(productSku, productName) {
        this.actionContext.airfxFlow.setCurrentSku(productSku);
        this.actionContext.equipmentSetup.onSensorSetup(productName);;
        this.$state.go('airfx.individual', { sku: productSku });
    }
}

export default {
    template: template,
    bindings: {
        device: '<',
        isConnected: '<',
        onClick: '&'
    },
    controller: SubCategoryListItemController
};