import * as _ from 'lodash';

import MobileDetect from 'mobile-detect';

class MobileDetectService {
    /*@ngInject*/
    constructor ($window, constants) {
        this.detector = new MobileDetect($window.navigator.userAgent);
        this.$window = $window;
        this.constants = constants;
    }
    
    isMobile() {
        let hasTouch = this._hasTouch();
        return !_.isNil(this.detector.mobile()) || hasTouch;
    }

    storeUrl() {
        let store = this._store();
        return !_.isNil(store) && !_.isNil(store.store) && !_.isNil(store.store.url) ? store.store.url : '';
    }

    storeBadge() {
        let store = this._store();
        return !_.isNil(store) && !_.isNil(store.store) && !_.isNil(store.store.badge) ? store.store.badge : '';
    }
    
    _hasTouch() {
        return (!_.isNil(this.$window.ontouchstart)
                || (this.$window.navigator.MaxTouchPoints > 0) 
                || (this.$window.navigator.maxTouchPoints > 0)
                || (this.$window.navigator.msMaxTouchPoints > 0));
    }

    getOs() {
        return this.detector.os();
    }

    _store() {
        let store = _.find(this._stores(), { 'OS': this.getOs() });
        return store;
    }

    _stores() {
        return [{
            'OS': 'iOS',
            'store': {
                'url': this.constants.urls.appleAppStore,
                'badge': "/Content/images/apple_appstore_badge.svg"
            }
        }, {
            'OS': 'AndroidOS',
            'store': {
                'url': this.constants.urls.googlePlayStore,
                'badge': "/Content/images/google-play-badge.png"
            }
        }];
    }
}

export default MobileDetectService;