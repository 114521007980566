import moment from 'moment';
import template from './orderArrivalCheck.html';

class OrderArrivalCheckController {
    /*@ngInject*/
    constructor(dataContext) {
        this.dataContext = dataContext;
    }

    updateSelection() {
        let expiration = moment.utc().add('weeks', 1);
        if (this.skipOrderArrivalCheck) {
            this.dataContext.user.setSkipOrderArrivalCheckExpiration(expiration);
        } else {
            this.dataContext.user.clearSkipOrderArrivalCheckExpiration();
        }
    }
}

export default {
    template: template,
    bindings: {},
    controller: OrderArrivalCheckController
};