import template from './place.html';

class PlaceController {
    /*@ngInject*/
    constructor($ngRedux, $state, constants, actionContext, SharedState) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.actionContext = actionContext;
        this.SharedState = SharedState;
        
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.hasCompletedRequirements = false;
    }

    continue() {
        if (this.hasCompletedRequirements === false) {
            this.SharedState.turnOn('placeRequirementsModal');
        }
        else if (this.hasCompletedRequirements === true) {
            this.$state.go('instruction.plugin');
        }
    }

    setStatusComplete() {
        this.hasCompletedRequirements = true;
    }

    $onDestroy() {
        this.unsubscribe();
    }

    _mapState(state) {
        let map = {};
        map.targetSku = state.account.airfxFlow.targetSku;
        map.isIq4Panel = state.account.user.isIq4Panel;
        return _.cloneDeep(map);
    }
}

export default {
    template: template,
    bindings: {},
    controller: PlaceController
};