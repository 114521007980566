class PartnerService {
    /*@ngInject*/
    constructor(Restangular) {
        this.restangular = Restangular;
    }

    getAdcStatus() {
        return this.restangular
            .one('partners')
            .one('adc')
            .withHttpConfig({ cache: false }) 
            .get();
    }

    getRapidStatus() {
        return this.restangular
            .one('partners')
            .one('rapid')
            .withHttpConfig({ cache: false }) 
            .get();
    }
}

export default PartnerService;