import * as _ from 'lodash';
import error from '../../util/error';
import moment from 'moment';

import { PREMISES_FETCH_REQUEST, PREMISES_FETCH_FAILURE, PREMISES_FETCH_SUCCESS } from '../../action/premises.actions';
import { PREMISES_UPDATE_REQUEST, PREMISES_UPDATE_FAILURE, PREMISES_UPDATE_SUCCESS } from '../../action/premises.actions';

import { PREMISES_CONFIRMED } from '../../action/premises.actions';
import { PREMISES_CLEAR_ERROR } from '../../action/premises.actions';

const initialState = {
    ignore: ['value', 'lastUpdatedDate', 'isFetching', 'isUpdating', 'hasError', 'message'],
    value: {},
    isConfirmed: false,
    lastUpdatedDate: null,
    isFetching: false,
    isUpdating: false,
    hasError: false,
    message: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case PREMISES_FETCH_REQUEST:
        return _.mergeCustom({}, state, {
            isFetching: true,
            hasError: false,
            message: null
        });
    case PREMISES_FETCH_FAILURE:
        return _.mergeCustom({}, state, {
            isFetching: false,
            hasError: true,
            message: error.parse(action.payload.error)
        });
    case PREMISES_FETCH_SUCCESS:
        return _.mergeCustom({}, state, {
            value: action.payload.data,
            lastUpdatedDate: moment.utc(),
            isFetching: false
        });
    case PREMISES_UPDATE_REQUEST:
        return _.mergeCustom({}, state, {
            isUpdating: true,
            hasError: false,
            message: null
        });
    case PREMISES_UPDATE_FAILURE:
        return _.mergeCustom({}, state, {
            isUpdating: false,
            hasError: true,
            message: error.parse(action.payload.error)
        });
    case PREMISES_UPDATE_SUCCESS:
        return _.mergeCustom({}, state, {
            value: action.payload.data,
            lastUpdatedDate: moment.utc(),
            isUpdating: false
        });
    case PREMISES_CONFIRMED:
        return _.mergeCustom({}, state, {
            isConfirmed: true
        });
    case PREMISES_CLEAR_ERROR:
        return _.mergeCustom({}, state, {
            hasError: false,
            message: null
        });
    default:
        return state;
    }
};