import template from './power.html';

class PowerController {
    /*@ngInject*/
    constructor(constants) {
        this.constants = constants;
    }
}

export default {
    template: template,
    bindings: {},
    controller: PowerController
};