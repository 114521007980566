import * as _ from 'lodash';
import template from './keypad.html';

class KeypadController {
    /*@ngInject*/
    constructor($ngRedux, $state, constants, segment) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.segment = segment;
        this.isAmazonUser = false;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.swapType = this.$ngRedux.getState().system.swap.swapType;
        if (this.$ngRedux.getState().account.user.isAmazonUser ||
            this.$ngRedux.getState().account.origin.startedOnAmazon) {
            this.isAmazonUser = true;
        }
    }

    $onDestroy() {
        this.unsubscribe();
    }

    onKeypadSetupNext() {
        let notConnectedKeypadsCount = _.filter(this.additionalOrderKeypads, (item) => { if (!_.isNil(this.sensorsByID[item.SensorNumber])) { return _.get(this.sensorsByID[item.SensorNumber], 'IsConnected', false) === false;}
            return null;
        }).length;
        if (notConnectedKeypadsCount >= 1) {
            this.$state.go('airfx.addKeypad', { deviceName: this.$state.params.deviceName });
        } else {
            this.additionalOrderKeypads = _.filter(this.additionalOrderKeypads,
                (item) => {
                    return _.get(item, 'isActivated', false) === false;
                });
            this.$state.go('airfx.keypadConnected', { additionalOrderKeypadsCount: this.additionalOrderKeypads.length });
        }
    }

    onKeypadSwapNext() {
        this.segment.registerEvent(this.constants.events.segment.panelSwapClickKeypadSetupNextButton, {});
        this.$state.go(this.$state.$current.data.nextState);
    }

    _mapState(state) {
        return {
            panelVersion: state.system.panel.type,
            additionalOrderKeypads: _.filter(state.account.order.itemsByID, (item) => {
                return _.indexOf(this.constants.keypads.skus, item.ProductSku) !== -1 && _.get(item, 'IsInitialOrder', false) === false;
            }),
            sensorsByID: state.system.devices.sensorsByID
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: KeypadController
};