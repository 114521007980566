class RadioSwapService {
    /*@ngInject*/
    constructor(Restangular) {
        this.Restangular = Restangular;
    }

    checkEligibility() {
        return this.Restangular
            .all('radioswap')
            .one('eligibilitycheck')
            .withHttpConfig({ cache: false })
            .get();
    }

    swapRadio() {
        return this.Restangular
            .all('radioswap')
            .one('swapradio')
            .withHttpConfig({ cache: false })
            .get();
    }
}

export default RadioSwapService;