import * as _ from 'lodash';

export const RADIO_SWAP_DL_VERIFICATION_FAILURE = 'RADIO_SWAP_DL_VERIFICATION_FAILURE';
export const RADIO_SWAP_DL_VERIFICATION_SUCCESS = 'RADIO_SWAP_DL_VERIFICATION_SUCCESS';
export const RADIO_SWAP_TIMEOUT = 'RADIO_SWAP_TIMEOUT';
export const ROUND_TRIP_COMMUNICATION_FAILURE = 'ROUND_TRIP_COMMUNICATION_FAILURE';
export const ROUND_TRIP_COMMUNICATION_SUCCESS = 'ROUND_TRIP_COMMUNICATION_SUCCESS';
export const RADIO_SWAP_COMPLETE = 'RADIO_SWAP_COMPLETE';
export const RADIO_SWAP_STARTED = 'RADIO_SWAP_STARTED';
export const RADIO_SWAP_STATUS_CHECK_STARTED = 'RADIO_SWAP_STATUS_CHECK_STARTED';
export const RADIO_SWAP_STATUS_CHECK_COMPLETED = 'RADIO_SWAP_STATUS_CHECK_COMPLETED';
export const RADIO_SWAP_STATUS_CHECK_FAILED = 'RADIO_SWAP_STATUS_CHECK_FAILED';
export const RADIO_SWAP_FAILED = 'RADIO_SWAP_FAILED';
export const SENSOR_TRANSFER_COMPLETE = 'SENSOR_TRANSFER_COMPLETE';
export const SENSOR_TRANSFER_STARTED = 'SENSOR_TRANSFER_STARTED';


class RadioSwapActions {
    /*@ngInject*/
    constructor($log, $ngRedux, $state, constants, $q, dataContext) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.constants = constants;
        this.$q = $q;
        this.dataContext = dataContext;
    }

    onRadioSwapFailed() {
        this._dispatch({
            type: RADIO_SWAP_FAILED,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.radioSwap.intro,
                description: 'RadioSwap process failed.',
                actionType: 'Error'
            }
        });
    }

    onRadioSwapStatusCheckStarted() {
        this._dispatch({
            type: RADIO_SWAP_STATUS_CHECK_STARTED,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.radioSwap.intro,
                description: 'RadioSwap status check started.',
                actionType: 'Info'
            }
        });
    }

    onRadioSwapStatusCheckFail() {
        this._dispatch({
            type: RADIO_SWAP_STATUS_CHECK_FAILED,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.radioSwap.intro,
                description: 'RadioSwap check status failed.',
                actionType: 'Error'
            }
        });
    }

    onRadioSwapStatusCheckCompleted(oldPanelSerial, newPanelSerial) {
        this._dispatch({
            type: RADIO_SWAP_STATUS_CHECK_COMPLETED,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.radioSwap.intro,
                description: 'RadioSwap check status completed - OldSerialNumber: [' + oldPanelSerial + '] NewSerialNumber: [' + newPanelSerial + '].',
                actionType: 'Info'
            }
        });
    }

    onRadioSwapStarted() {
        this._dispatch({
            type: RADIO_SWAP_STARTED,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.radioSwap.intro,
                description: 'RadioSwap process started.',
                actionType: 'Info'
            }
        });
    }

    onRadioSwapComplete() {
        this._dispatch({
            type: RADIO_SWAP_COMPLETE,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.radioSwap.success,
                description: 'RadioSwap process successfully completed.',
                actionType: 'Info'
            }
        });
    }

    onRoundTripCommunicationFailure() {
        this._dispatch({
            type: ROUND_TRIP_COMMUNICATION_FAILURE,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.radioSwap.roundTrip,
                description: 'Round trip communication test to ADC failed.',
                actionType: 'Error'
            }
        });
    }

    onRoundTripCommunicationSuccess() {
        this._dispatch({
            type: ROUND_TRIP_COMMUNICATION_SUCCESS,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.radioSwap.roundTrip,
                description: 'Round trip communication test to ADC completed.',
                actionType: 'Info'
            }
        });
    }

    onRadioSwapTimeout(milliseconds) {
        this._dispatch({
            type: RADIO_SWAP_TIMEOUT,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.radioSwap.timeout,
                description: `Timed out at ${this._millisToMinutesAndSeconds(milliseconds)} while finalizing radio swap.`,
                actionType: 'Error'
            }
        });
    }

    onSensorTransferCompleted(milliseconds) {
        this._dispatch({
            type: SENSOR_TRANSFER_COMPLETE,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.radioSwap.sensorTransfer,
                description: `RadioSwap sensor transfer completed in ${this._millisToMinutesAndSeconds(milliseconds)}`,
                actionType: 'Info'
            }
        });
    }

    onSensorTransferStarted() {
        this._dispatch({
            type: SENSOR_TRANSFER_STARTED,
            metadata: {
                persist: true,
                sectionType: this.constants.sectionTypes.radioSwap.sensorTransfer,
                description: 'RadioSwap sensor transfer started.',
                actionType: 'Info'
            }
        });
    }

    _dispatch(obj) {
        this.$ngRedux.dispatch(obj);
    }

    _millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }
}

export default RadioSwapActions;