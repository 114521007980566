import template from './helpPanelModal.html';

class HelpModalController {
    /*@ngInject*/
    constructor(SharedState, $ngRedux, dataContext, constants, $state) {
        this.SharedState = SharedState;
        this.$ngRedux = $ngRedux;
        this.modswapService = dataContext.modSwap;
        this.constants = constants;
        this.$state = $state;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);

        if (this.targetSku === this.constants.panelVersions.iqPanel) {
            this.customerSupportPhone = this.constants.customerSupportIQ.phone;
        } else {
            this.customerSupportPhone = !this.$ngRedux.getState().account.origin.startedOnAmazon
                ? this.constants.customerSupportHub.phone
                : this.constants.customerSupportHub.amazonPhone;
        }
    }
    showHelpModal() {
        this.showhelpModal = true;
        this.SharedState.turnOn('helpPanelModal');
    }

    _mapState() {
        return {
            targetSku: state.account.airfxFlow.targetSku
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: HelpModalController
};

