import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import './account.scss';

import commonModule from '../common';

import accountContactComponent from './contact.component';
import accountPremiseAddressComponent from './premiseAddress.component';
import accountServicePlanComponent from './servicePlan.component';
import accountPaymentMethodComponent from './paymentMethod.component';
import accountContractComponent from './contract.component';
import accountRegisteringComponent from './registering.component';
import accountRegisteringCompleteComponent from './registeringComplete.component';
import accountSaleRestrictedComponent from './saleRestricted.component';
import accountContractDeclinedComponent from './contractDeclined.component';
export default angular
    .module('fp:activationswizard:account',
        [
            'ui.router',
            ngRedux,
            commonModule.name
        ])
    .config(/*@ngInject*/
        ($stateProvider, constants) => {
            $stateProvider
                .state('account',
                    {
                        abstract: true,
                        parent: 'secure',
                        url: '/account',
                        template: '<ui-view></ui-view>',
                        data: {
                            next: /*@ngInject*/ ($state, appActions) => {
                                appActions.completeModule(constants.sectionTypes.account);
                                $state.go('menu.home');
                            },
                            prev: /*@ngInject*/ ($state) => {
                                $state.go('menu.home');
                            },
                            sectionType: constants.sectionTypes.account,
                            theme: 'app-theme-default-navbar-gray',
                            isHydratable: false
                        }
                    })
                .state('account.contact',
                    {
                        url: '/contact',
                        template: '<aw-account-contact></aw-contact>',
                        onEnter: /*@ngInject*/ (actionContext, constants) => {
                            actionContext.common.other(constants.actionTypes.other, constants.sectionTypes.contact, false);
                        },
                        data: {
                            prev: /*@ngInject*/ ($state) => {
                                $state.go('instruction.plugin');
                            },
                            next: /*@ngInject*/ ($state) => {
                                $state.go('account.premise-address');
                            },
                            isHydratable: false
                        }
                    })
                .state('account.premise-address',
                    {
                        url: '/premiseaddress',
                        template: '<aw-premise-address></aw-premise-address>',
                        onEnter: /*@ngInject*/ (actionContext, constants) => {
                            actionContext.common.other(constants.actionTypes.other, constants.sectionTypes.premiseAddress, false);
                        },
                        data: {
                            prev: /*@ngInject*/ ($state, $ngRedux) => {
                                if ($ngRedux.getState().account.customer.isVideoOnly) {
                                    $state.go('welcome.settingUp');
                                }
                                else if ($ngRedux.getState().account.user.isAmazonUser ||
                                    $ngRedux.getState().account.origin.startedOnAmazon) {
                                    $state.go('account.contact');
                                } else {
                                    $state.go('instruction.plugin');
                                }
                            },
                            next: /*@ngInject*/ ($state, $ngRedux) => {
                                if ($ngRedux.getState().account.user.isAmazonUser ||
                                    $ngRedux.getState().account.origin.startedOnAmazon) {
                                    $state.go('account.service-plan');
                                } else {
                                    $state.go('predispatch.home');
                                }
                            },
                            isHydratable: false
                        }
                    })
                .state('account.service-plan',
                    {
                        url: '/serviceplan',
                        template: '<aw-service-plan></aw-service-plan>',
                        onEnter: /*@ngInject*/ (actionContext, constants) => {
                            actionContext.common.other(constants.actionTypes.other, constants.sectionTypes.servicePlanDescription, false);
                        },
                        data: {
                            isHydratable: false,
                            prevState: 'service-plan',
                            nextState: 'account.payment-method'
                        }
                    })
                .state('account.payment-method',
                    {
                        url: '/paymentMethod',
                        template: '<aw-payment-method></aw-payment-method>',
                        onEnter: /*@ngInject*/ (actionContext, constants) => {
                            actionContext.common.other(constants.actionTypes.other, constants.sectionTypes.paymentMethod, false);
                        },
                        data: {
                            prevState: 'account.service-plan',
                            nextState: 'account.contract',
                            isHydratable: false
                        }
                    })
                .state('account.contract',
                    {
                        url: '/contract',
                        template: '<aw-contract></aw-contract>',
                        onEnter: /*@ngInject*/ (actionContext, constants) => {
                            actionContext.common.other(constants.actionTypes.other, constants.sectionTypes.contract, false);
                        },
                        data: {
                            prevState: 'account.payment-method',
                            nextState: 'account.registering',
                            isHydratable: false
                        }
                    })
                .state('account.contract-declined',
                    {
                        url: '/contract-declined',
                        template: '<aw-contract-declined></aw-contract-declined>',
                        onEnter: /*@ngInject*/ (actionContext, constants) => {
                            actionContext.common.other(constants.actionTypes.other, constants.sectionTypes.contractDeclined, false);
                        }
                    })
                .state('account.registering',
                    {
                        url: '/registering',
                        template: '<aw-registering></aw-registering>',
                        onEnter: /*@ngInject*/ (actionContext, constants) => {
                            actionContext.common.other(constants.actionTypes.other, constants.sectionTypes.registering, false);
                        },
                        data: {
                            prevState: 'account.payment-method',
                            isHydratable: false,
                            persist: false
                        }
                    })
                .state('account.registering-complete',
                    {
                        url: '/registering-complete',
                        template: '<aw-registering-complete></aw-registering-complete>',
                        onEnter: /*@ngInject*/ (actionContext, constants) => {
                            actionContext.common.other(constants.actionTypes.other, constants.sectionTypes.registeringCompleted, false);
                        },
                        data: {
                            prevState: 'account.payment-method',
                            theme: 'app-theme-green'
                        }
                    })
                .state('account.sale-restricted',
                    {
                        url: '/sale-restricted',
                        template: '<aw-sale-restricted></aw-sale-restricted>',
                        onEnter: /*@ngInject*/ (actionContext, constants) => {
                            actionContext.common.other(constants.actionTypes.other, constants.sectionTypes.saleRestricted, false);
                        }
                    });
        })
    .component('awAccountContact', accountContactComponent)
    .component('awPremiseAddress', accountPremiseAddressComponent)
    .component('awServicePlan', accountServicePlanComponent)
    .component('awPaymentMethod', accountPaymentMethodComponent)
    .component('awContract', accountContractComponent)
    .component('awRegistering', accountRegisteringComponent)
    .component('awRegisteringComplete', accountRegisteringCompleteComponent)
    .component('awSaleRestricted', accountSaleRestrictedComponent)
    .component('awContractDeclined', accountContractDeclinedComponent);

    