import * as _ from 'lodash';

import {
    DESIGNATE_SWAP_TYPE
} from '../../action/modSwap.actions';

const initialState = {
    swapType: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case DESIGNATE_SWAP_TYPE:
            return _.mergeCustom({},
                state,
                {
                    swapType: action.payload.swapType
                });
        default:
            return state;
    }
};