import template from './initialize-item.html';

class InitializeItemController {
    /*@ngInject*/
    constructor () {
        
    }

    shouldShowPending() {
        return !this.isConnecting && !this.isConnected;
    }

    shouldShowConnecting() {
        return this.isConnecting && !this.isConnected;
    }

    shouldShowConnected() {
        return this.isConnected;
    }
}

export default {
    template: template,
    bindings: {
        device: '<',
        isConnected: '<',
        isConnecting: '<',
        onClick: '&'
    },
    controller: InitializeItemController
};