import * as _ from 'lodash';

class Error {
    /**
     * Parses the error message contained in the object. If no
     * error message is found 'Unknown error' is returned.
     * 
     * @param {Object|string} obj The object to parse for an error message.
     * @returns {string} The error message or 'Unknown error'.
     */
    static parse(error) {
        if (_.isNil(error)) {
            return '';
        }

        if (_.isString(error)) {
            return error;
        }

        if (_.isString(error.data)) {
            return error.data;
        }

        if (_.isNil(error.data)) {
            return 'Unknown error';
        }

        return error.data.Message;
    }
}

export default Error;