import * as _ from 'lodash';
import error from '../../util/error';
import moment from 'moment';

import { ORDER_LINE_ITEMS_FETCH_REQUEST, ORDER_LINE_ITEMS_FETCH_FAILURE, ORDER_LINE_ITEMS_FETCH_SUCCESS } from '../../action/order.actions';
import { ORDER_DELIVERED_FETCH_REQUEST, ORDER_DELIVERED_FETCH_FAILURE, ORDER_DELIVERED_FETCH_SUCCESS} from '../../action/order.actions';
import { ORDER_PRODUCT_QUEUE_AIRFX, ORDER_PRODUCT_DEQUEUE_AIRFX } from '../../action/order.actions';
import { ORDER_PRODUCT_ACTIVATE_REQUEST, ORDER_PRODUCT_ACTIVATE_FAILURE, ORDER_PRODUCT_ACTIVATE_SUCCESS, SET_PROGRESS, RESET_PRODUCTS } from '../../action/order.actions';
import { ORDER_BOX_TYPE, ORDER_PRODUCT_ADDITIONAL_EQUIPMENT, Is_Feedonomic_Order } from '../../action/order.actions';

/**
 * Gets the order product id from the given action.
 * 
 * @param {Object} action The action object.
 * @returns {number} The id of the order product. 
 */
const getOrderProductId = (action) => action.payload.product.OrderProductID;

/**
 * Updates the order product in the application state.
 * 
 * @param {Object} state The application state.
 * @param {number} id The id of the order product to update.
 * @param {Object} update The object to merge into the current order product. 
 * @returns {Object} The updated application state.
 */
const updateOrderProduct = (state, id, update) => _.mergeCustom({}, state, {
    itemsByID: _.mergeCustom({}, state.itemsByID, {
        [id]: _.mergeCustom({}, state.itemsByID[id], update)
    })
});

const initialState = {
    ignore: ['itemsByID','itemsBySku', 'hasError', 'message', 'isFetching', 'lastUpdateDate', 'additionalEquipment', 'boxType', 'delivered'],
    itemsByID: {},
    itemsBySku: {},
    lastUpdatedDate: null,
    isFetching: false,
    hasError: false,
    message: null,
    hasChildOrder: false,
    additionalEquipment: [],
    delivered: [],
    isQueued: false,
    isActivated: false,
    progress: 0,
    boxType: 2,
    currentSku: 'SKU'
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        // Line Items
        case ORDER_LINE_ITEMS_FETCH_REQUEST:
            return _.mergeCustom({}, state, {
                isFetching: true,
                hasError: false,
                message: null
            });
        case ORDER_LINE_ITEMS_FETCH_FAILURE:
            return _.mergeCustom({}, state, {
                isFetching: false,
                hasError: true,
                message: error.parse(action.payload.error)
            });
        case ORDER_LINE_ITEMS_FETCH_SUCCESS:
            return _.mergeCustom({}, state, {
                itemsByID: _.keyBy(action.payload.data, (x) => {
                    return x.OrderProductID;
                }),
                itemsBySku: _.reduce(action.payload.data, (result, value) => {
                    (result[value.ProductSku] || (result[value.ProductSku] = [])).push(value);
                    return result;
                }, {}),
                hasChildOrders: _.some(action.payload.data, (order) => { return !_.isNil(order.ParentOrderID); }),
                lastUpdatedDate: moment.utc(),
                isFetching: false

            });
        case ORDER_PRODUCT_ADDITIONAL_EQUIPMENT:
            return _.mergeCustom({}, state, {
                additionalEquipment: action.payload.items
            });

            // Delivered Orders
        case ORDER_DELIVERED_FETCH_REQUEST:
        case ORDER_DELIVERED_FETCH_FAILURE:
            return state;
        case ORDER_DELIVERED_FETCH_SUCCESS:
            return _.mergeCustom({}, state, {
                delivered: action.payload.orders        
            });

            // AirFX
        case ORDER_PRODUCT_QUEUE_AIRFX:
            return updateOrderProduct(state, getOrderProductId(action), { isQueued: true });
        case ORDER_PRODUCT_DEQUEUE_AIRFX:
            return updateOrderProduct(state, getOrderProductId(action), { isQueued: false });

            // Order Product Activate
        case ORDER_PRODUCT_ACTIVATE_REQUEST:
        case ORDER_PRODUCT_ACTIVATE_FAILURE:
            return state;
        case ORDER_PRODUCT_ACTIVATE_SUCCESS:
            return updateOrderProduct(state, getOrderProductId(action), { isActivated: true, isQueued:false });
        case SET_PROGRESS:
            return _.mergeCustom({}, state, {
                progress: action.payload.progress        
            });
        case RESET_PRODUCTS:
            state.itemsByID = {};
            return state;
        case ORDER_BOX_TYPE:
            return _.mergeCustom({}, state, {
               boxType: action.payload.boxType
            });
        case Is_Feedonomic_Order:
            return _.mergeCustom({}, state, {
                isFeedonomicOrder: action.payload.isFeedonomicOrder
            });
        default:
            return state;
    }
};