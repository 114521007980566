import * as _ from 'lodash';

let phoneDirective = () => {
    'ngInject';
    return {
        restrict: 'EA',
        require: 'ngModel',
        link: ($scope, element, attrs,ngModel) => {

            let removeFormat = function(value) {
                if (typeof(value) === typeof(undefined) || value === null) {
                    return value;
                }
                let parsedValue = value.toString().replace(/\s/g, '').replace(/[^\d]/g, '');
                return parsedValue;
            }
            let formatPhone = function(value) {
                if (typeof(value) === typeof(undefined) || value === null) {
                    return value;
                }
                let country;
                let city;
                let number;

                switch (value.length) {
                    case 10: // +1PPP####### -> C (PPP) ###-####
                        country = 1;
                        city = value.slice(0, 3);
                        number = value.slice(3);
                        break;

                    case 11: // +CPPP####### -> CCC (PP) ###-####
                        country = value[0];
                        city = value.slice(1, 4);
                        number = value.slice(4);
                        break;

                    case 12: // +CCCPP####### -> CCC (PP) ###-####
                        country = value.slice(0, 3);
                        city = value.slice(3, 5);
                        number = value.slice(5);
                        break;
                    case 14:
                        //We already formatted the number correctly
                        return value;

                    default:
                        return removeFormat(value);
                }


                number =  number.slice(0, 3) + '-' + number.slice(3);

                return ('(' +city + ') ' + number).trim();
            }


            let parseViewValue = function(value) {
                let viewValue = formatPhone(value);
                ngModel.$viewValue = viewValue;
                ngModel.$render();

                //Removes custom formatting from the actual model value;
                return removeFormat(viewValue);
            }

            let formattedModelValue = function(value) {
                let modelValue = removeFormat(value);
                ngModel.$modelValue = modelValue;
                return formatPhone(modelValue);
            }

            ngModel.$parsers.push(parseViewValue);
            ngModel.$formatters.push(formattedModelValue);
        }
    };
};

export default phoneDirective;