import * as angular from 'angular';

import 'restangular';
import 'angular-cache';
import 'angular-storage';
import 'angular-http-auth';

import DataCacheService from './dataCache.service';
import DataContextService from './dataContext.service';
import StoreService from './store.service';

import AccountService from './account.service';
import AddressService from './address.service';
import ApplicationStateService from './applicationState.service';
import ConfigurationsService from './configurations.service';
import MonitoringService from './monitoring.service';
import OrderService from './order.service';
import PanelService from './panel.service';
import PartnerService from './partner.service';
import StateService from './state.service';
import SensorService from './sensor.service';
import SupportService from './support.service';
import TestModeService from './testmode.service';
import UserService from './user.service';
import WifiService from './wifi.service';
import CodeService from './code.service';
import ZwaveService from './zwave.service';
import ModSwapService from './modswap.service';
import RadioSwapService from './radioSwap.service';
import KitCustomerService from './kitCustomer.service';
import CustomerBillingInfoService from './customerBillingInfo.service';
import LoggingService from './logging.service';
import SmsService from './sms.service';
import EmailService from './email.service';

export default angular.module('fp:activationswizard:common:data', [
        'angular-storage',
        'angular-cache',
        'restangular',
        'http-auth-interceptor'
    ])

    .service('dataContext', DataContextService)
    .service('fpDataCache', DataCacheService)
    .service('fpStore', StoreService)

    .service('accountService', AccountService)
    .service('addressService', AddressService)
    .service('applicationStateService', ApplicationStateService)
    .service('configurationsService', ConfigurationsService)
    .service('monitoringService', MonitoringService)
    .service('orderService', OrderService)
    .service('panelService', PanelService)
    .service('partnerService', PartnerService)
    .service('stateService', StateService)
    .service('sensorService', SensorService)
    .service('supportService', SupportService)
    .service('testModeService', TestModeService)
    .service('userService', UserService)
    .service('wifiService', WifiService)
    .service('codeService', CodeService)
    .service('zwaveService', ZwaveService)
    .service('modSwapService', ModSwapService)
    .service('radioSwapService', RadioSwapService)
    .service('kitCustomerService', KitCustomerService)
    .service('customerBillingInfoService', CustomerBillingInfoService)
    .service('loggingService', LoggingService)
    .service('smsService', SmsService)
    .service('emailService', EmailService)

    .run(/*@ngInject*/($http: any, $rootScope: any, Restangular: any, fpDataCache: any) => {
        $http.defaults.cache = fpDataCache.instance;
        Restangular.setResponseInterceptor((data: any, operation: any, what: any, url: any, response: any) => {
            // clear the cache for any non-GET
            if (operation !== 'get' && operation !== 'getList' && response.config.clearCache !== false) {
                fpDataCache.clear();
            }
            return data;
        });
    });
