import template from './glassBreakInstallationModal.html';

class GlassBreakInstallationModalController {
    /*@ngInject*/
    constructor () {
    }
}

export default {
    template: template,
    bindings: {
        sku: '@'
    },
    controller: GlassBreakInstallationModalController
};