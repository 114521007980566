import template from './keypadConnected.html';

class KeypadConnectedController {
    /*@ngInject*/
    constructor($state) {
        this.$state = $state;
        this.additionalOrderKeypadsCount = this.$state.params.additionalOrderKeypadsCount;
    }

    $onInit() {
        if (this.additionalOrderKeypadsCount > 1) {
            this.message = 'All Keypads have';
        } else {
            this.message = 'Your Keypad has';
        }
    }
}

export default {
    template: template,
    bindings: {},
    controller: KeypadConnectedController
};