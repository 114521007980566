import { STEP_COMPLETE } from '../../action/app.actions';

const initialState = '';

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case STEP_COMPLETE:
        return action.payload.data;
    default:
        return state;
    }
};