import template from './navbar-top.html';
import './nav.scss';

class NavbarTopComponent {
    /*@ngInject*/
    constructor(constants, actionContext, $ngRedux, $state) {
        this.constants = constants;
        this.actionContext = actionContext;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
    }

    $onInit() {
        this.isApp = true;
    }

    logHelp() {
        this.actionContext.common.other(this.constants.actionTypes.other, this.constants.sectionTypes.help, false);
    }
    
}

export default {
    template: template,
    bindings: {
        theme: '<',
        showProgress: '<'
    },
    controller: NavbarTopComponent
};