import template from './productImg.html';
import './productImage.scss';

class ProductImgController {
    /*@ngInject*/
    constructor (actionContext) {
        this.actionContext = actionContext;
    }
}

export default {
    template: template,
    bindings: {
        sku: '@'
    },
    controller: ProductImgController
};