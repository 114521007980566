import template from './information.html';

class SensorItemInformationController {
    /*@ngInject*/
    constructor () {
        
    }
}

export default {
    template: template,
    bindings: {
        sensor: '<'
    },
    controller: SensorItemInformationController
};