import * as _ from 'lodash';

import template from './home.html';

class HomeComponent {
    /*@ngInject*/
    constructor(constants, $log, $ngRedux, $q, $state, actionContext, navigation, SharedState, dataContext) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.$state = $state;
        this.actionContext = actionContext;
        this.dataContext = dataContext;
        this.constants = constants;
        this.navigation = navigation;
        this.SharedState = SharedState;
        this.cannotLoadUserCodes = false;
        this.typeUserCode = true;
        this.isEmailLive = dataContext.email.isEmailLive().then((res) => {this.isEmailLive = res});
    }

    $onInit() {
        this.isIq4Panel = this.$ngRedux.getState().account.user.isIq4Panel;
        if (this.actionContext.app.hasInitialLoadError('fetchCode')) {
            this.actionContext.code.fetchCode()
                .then(() => {
                    this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
                    this.cannotLoadUserCodes = false;
                })
                .catch((error) => {
                    this.cannotLoadUserCodes = true;
                });
        }
        else
            this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    toggleUserCode() {
        this.typeUserCode = !this.typeUserCode;
    }

    submitCodes() {
        this.isUpdating = true;
        let filteredCodes = _.filter(this.codes, (filteredCode) => { return filteredCode.IsNew === true || filteredCode.Code === null; });

        this.actionContext.code.editUserCodes(filteredCodes)
            .then(() => {
                this.navigation.goNext();
            })
            .catch((error) => {
                this.hasError = true;
            })
            .finally(() => {
                this.isUpdating = false;
            });
    }

    openModal() {
        if (this.maxInputs())
            return;

        this._resetInputs();
        this.showModal = true;
    }

    emailModal() {
        if (this.isEmailLive === true) {
            this.showEmailModal = true;
        } else {
            this.navigation.goNext();
        }
    }

    removeUser(user) {
        if (user.IsNew) {
            this.codes = _.remove(this.codes, (code) => {
                return code.Code !== user.Code;
            });
        } else
            user.Code = null;
    }

    onPositiveClick() {
        this.showCodeExists = false;
        this.isBlacklistCode = this.actionContext.code.isBlackListCode(this.newCode);
        if (this.userCodeEditForm.$invalid || this.isBlacklistCode || (this.userCodeEditForm.usercode.$viewValue !== this.userCodeEditForm.confirmUsercode.$viewValue)) {
            this.userCodeEditForm.$setDirty();
            return;
        }

        _.forEach(_.filter(this.codes, (filteredCode) => { return filteredCode.Code !== null; }), (userCode) => {
            if (userCode.Code === this.newCode || userCode.Code === this.newCode.toString()) {
                this.showCodeExists = true;
                this.codeExistsText = `The code ${userCode.Code} is already in use by ${userCode.FirstName} ${userCode.LastName}, please select another.`;
            }
        });

        if (this.showCodeExists)
            return;

        this.codes.push({
            'FirstName': this.newFirstName,
            'LastName': this.newLastName,
            'Code': _.padStart(this.newCode, 4, '0'),
            'IsNew': true
        });
        this.showModal = false;
    }

    onNegativeClick() {
        this._resetInputs();
        this.showModal = false;
    }

    sendEmail() {
        //send email
        this.dataContext.email.sendEmail("AddUsers");
        this.navigation.goNext();
    }

    declineEmail() {
        this.navigation.goNext();
    }

    maxInputs() {
        if (_.isNil(this.codes))
            return null;
        return this.codes.length > this.constants.codes.maxUserCodes;
    }

    hasUserCodes() {
        if (_.isNil(this.codes))
            return null;
        return this.codes.length > 1;
    }

    resetBlackList() {
        if (this.isBlacklistCode === true)
            this.isBlacklistCode = false;
    }

    _resetInputs() {
        this.newFirstName = 
        this.newLastName = 
        this.newCode = '';
        this.confirmNewCode = '';
        this.showCodeExists = false;
        this.isBlacklistCode = false;
    }

    _mapState(state) {
        if (this.codes) {
            return {codes: this.codes}
        }

        return _.cloneDeep({
            codes: state.system.code.codes 
        });
    }
}

export default {
    template: template,
    bindings: {},
    controller: HomeComponent
};