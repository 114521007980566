import * as _ from 'lodash';

import template from './home.html';

class HomeComponent {
    /*@ngInject*/
    constructor(constants, $log, $ngRedux, $q, $state, actionContext, navigation, $interval) {
        this.$log = $log;
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.$state = $state;
        this.actionContext = actionContext;
        this.constants = constants;
        this.navigation = navigation;
        this.$interval = $interval;
        this.typeMasterCode = true;

        this.statusChanges = {
            0: '...Registering pre-dispatch contacts...',
            50: '...Updating credentials...',
            160: '...Validating system address...',
            300: '...Configuring settings...'
        };
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this._killIntervals();
    }

    $onDestroy() {
        this._killIntervals();
        this.unsubscribe();
    }

    _killIntervals() {
        if (this.masterCodePollInterval) {
            this.$interval.cancel(this.masterCodePollInterval);
        }

        if (this.pingPromise) {
            this.actionContext.panel.cancelPing(this.pingPromise);
        }
    }

    onSet() {
        const form = this.masterCodeEditForm;
        this.isBlacklistCode = this.actionContext.code.isBlackListCode(this.masterCode);
        if (!form || form.$invalid || this.isBlacklistCode) {
            return;
        }

        this.isUpdating = true;
        this.$q.resolve()
            .then(() => {
                if (this.isConnected) {
                    return this.$q.resolve();
                }
                this.pingPromise = this.actionContext.panel.startPing();

                return this.pingPromise;
            })
            .then(() => {
                this._pollMasterCode();
            })
            .catch(() => {
                this.isUpdating = false;
                this.$state.go('error');
            });
    }

    resetBlackList() {
        if (this.isBlacklistCode === true)
            this.isBlacklistCode = false;
    }

    _pollMasterCode() {
        this.masterCodePollInterval = this.$interval((count) => {
                return this.actionContext.code.updateMasterCode(this.masterCode).then(() => {
                    this._killIntervals();
                    if (this.startedOnAmazon) {
                        this.actionContext.kitCustomer.beginOrderConfiguration();
                    }
                    this.isUpdating = false;
                    this.navigation.goNext();
                }).catch(() => {
                    // run for 10 minutes
                    if (count === 100) {
                        this._killIntervals();
                        this.isUpdating = false;
                        this.$state.go('error');
                    }
                });
            },
            6000);
    }

    toggleMasterCode() {
        this.typeMasterCode = !this.typeMasterCode;
    }

    _mapState(state) {
        return {
            currentMasterCode: state.system.code.mastercode,
            isConnected: state.system.panel.isConnected,
            isFetching: state.system.code.isFetching,
            startedOnAmazon: state.account.origin.startedOnAmazon,
            isIq4Panel: state.account.user.isIq4Panel
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: HomeComponent
};