import * as _ from 'lodash';

import template from './process.html';

class ProcessingController {
    /*@ngInject*/
    constructor($ngRedux, $q, $state, $timeout, actionContext, dataContext, constants, $interval, $log, $rootScope) {
        this.$ngRedux = $ngRedux;
        this.$q = $q;
        this.$state = $state;
        this.$timeout = $timeout;
        this.actionContext = actionContext;
        this.dataContext = dataContext;
        this.constants = constants;
        this.$rootScope = $rootScope;

        this.isError = false;
        this.showProgress = false;
        this.$interval = $interval;
        this.isKeypad = this.actionContext.airfxFlow.isKeypad();
        this.addedSensors = [];
        this.$log = $log;

        this.MAX_RETRIES = 8;
        this.INITIAL_POLL_INTERVAL = 20000;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
        this.doProcess();

        this.isClimaxHub = this.actionContext.panel.isClimaxHub();
        this.isIQPanel = this.actionContext.panel.isIQPanel();
        this.customerSupportPhone =
            this.isClimaxHub ? this.constants.customerSupportHub.phone : (this.isIQPanel ? this.constants.customerSupportIQ.phone : this.constants.customerSupport.phone);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    doProcess() {
        this.DLCode = '';
        this.currentPercentage = 0;
        this.maxPercentage = 100;
        this.progressInterval = () => {
            this.$interval(() => {
                if (this.currentPercentage < this.maxPercentage) {
                    this.currentPercentage++;
                }
            }, 3000);
        };

        this.spinnerTitle = 'CONNECTING TO FRONTPOINT HQ...';
        if (this.isKeypad) {
            this.spinnerTitle = 'CONNECTING KEYPAD TO HUB';
            this.spinnerText = 'Please wait while we connect your keypads to the Hub.';
        }
        //show progress and start the interval
        this.showProgress = true;
        this.progressInterval();

        let addSensorsPromise = this.$q.resolve();

        //filter the list, remove isActivated, isConnected,isSkipped
        this.filteredItems = _.filter(this.items, (product) => { return _.get(product, 'isActivated', false) === false && _.get(product, 'IsConnected', false) === false; });
        this.filteredItems = this.actionContext.order.getSensorsThatAreSkippedButStillActivated(this.filteredItems);
        if (this.filteredItems.length === 0) {
            this.$interval.cancel(this.progressInterval);
            this.onSuccess(this.items);
        } else {
            _.forEach(this.filteredItems,
                (item) => {
                    this.actionContext.equipmentSetup.onSensorSelectionForActivation(item);
                    addSensorsPromise = addSensorsPromise
                        .then(() => {
                            if (this.actionContext.device.isSmartLock(item)) {
                                return this.$q.resolve();
                            }
                            return this.dataContext.account.order.convertToDevice(item.OrderProductID);
                        }).then((res) => {
                            if (_.isNil(res)) {
                                return this.$q.resolve();
                            }
                            if (this.isKeypad) {
                                res.DLCode = res.SerialNumber;
                            }
                            this.DLCode = res.DLCode;
                            return this.actionContext.panel.addSensor(res);
                        }).then((device) => {
                            this.addedSensors.push(device);
                        }).catch(() => {
                            this.onFailure();
                        });
                });
            addSensorsPromise.then(() => {
                let dlCodes = this.addedSensors.map(sensor => sensor.DLCode);
                return this.actionContext.panel.getDevices(true, dlCodes);
            }).then(() => {
                this.$interval.cancel(this.progressInterval);
                this.onSuccess(this.filteredItems);
            }).catch(() => {
                this.onFailure();
            });
        }
    }

    onFailure() {   
        this.$interval.cancel(this.progressInterval);

        this.isError = true;
        this.showProgress = false;
        var message = 'User directed to Oops page from process.component. ';
        var previousState = this.$rootScope.$resolves.previousState;
        if (!_.isNil(previousState)) {
            message += `Previous State - Name: [${previousState.current.name}] Template: [${previousState.current.template}] Url: [${previousState.current.url}]  Next State - Name: [${previousState.next.name}] Url: [${previousState.next.url}].`;
        }
        this.actionContext.equipmentSetup.onErrorPageInitialization(message);
        this.$log.error('Unable to add all sensors to panel.');
    }

    onSuccess(items) {
        this.showProgress = false;
        this.$interval.cancel(this.progressInterval);

        if (this.isKeypad) {
            var sensorNumber = items[0].SensorNumber;
            this.$state.go('airfx.keypadActivate', { sensorId: sensorNumber });
        } else {
            this.$state.go(this.constants.routerStates.airfxComplete, { sku: this.$state.params.sku });
        }
    }

    _mapState(state) {
        return {
            items: _.filter(_.map(state.account.order.itemsByID), (product) => product.isQueued),
            processedDevices: _.map(state.system.devices.sensorsByID)
        };
    }
    
}

export default {
    template: template,
    bindings: {},
    controller: ProcessingController
};