import template from './home.html';

class OverviewController {
    /*@ngInject*/
    constructor() {

    }
}

export default {
    template: template,
    bindings: {},
    controller: OverviewController
};