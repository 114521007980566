import template from './home.html';

class HomeController {
    /*@ngInject*/
    constructor () {
        
    }
}

export default {
    template: template,
    bindings: {},
    controller: HomeController
};