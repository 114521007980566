import DataContext from '@app/common/data/dataContext.service';
export const SEND_FP_SMS_FAILURE = 'SEND_FP_SMS_FAILURE';
export const SEND_FP_SMS_SUCCESS = 'SEND_FP_SMS_SUCCESS';
export const SEND_FP_SMS_STARTED = 'SEND_FP_SMS_STARTED';

export const ENROLL_CS_SMS_FAILURE = 'ENROLL_CS_SMS_FAILURE';
export const ENROLL_CS_SMS_SUCCESS = 'ENROLL_CS_SMS_SUCCESS';
export const ENROLL_CS_SMS_STARTED = 'ENROLL_CS_SMS_STARTED';

export default class SmsActions {
    constructor(
        private $ngRedux: any,
        private dataContext: DataContext,
        private constants: any) {
        'ngInject';
    }

    public async sendInitialFpSmsForEmergencyContacts() {
        this.$ngRedux.dispatch({
            type: SEND_FP_SMS_STARTED
        });

        try {
            await this.dataContext.sms.sendInitialFpSmsForEmergencyContacts();
        } catch (error) {
            this.$ngRedux.dispatch({
                type: SEND_FP_SMS_FAILURE,
                metadata: {
                    description: 'Failed to send SMS to emergency contacts.',
                    sectionType: this.constants.sectionTypes.activation,
                    persist: true
                }
            });

            return;
        }

        this.$ngRedux.dispatch({
            type: SEND_FP_SMS_SUCCESS,
            metadata: {
                description: 'Successfully sent SMS to emergency contacts',
                sectionType: this.constants.sectionTypes.activation,
                persist: true
            }
        });
    }

    public async enrollCentralStationSms() {
        this.$ngRedux.dispatch({
            type: ENROLL_CS_SMS_STARTED
        });

        try {
            await this.dataContext.sms.enrollCentralStationSms();
        } catch (error) {
            this.$ngRedux.dispatch({
                type: ENROLL_CS_SMS_FAILURE,
                metadata: {
                    description: 'Failed to enroll in central station SMS.',
                    sectionType: this.constants.sectionTypes.activation,
                    persist: true
                }
            });

            return;
        }

        this.$ngRedux.dispatch({
            type: ENROLL_CS_SMS_SUCCESS,
            metadata: {
                description: 'Successfully enrolled in central station SMS.',
                sectionType: this.constants.sectionTypes.activation,
                persist: true
            }
        });
    }
}
