import * as _ from 'lodash';

import {
    ACCOUNT_CUSTOMER_BILLING_INFO_SUBMISSION_SUCCESSFUL,
    ACCOUNT_CUSTOMER_BILLING_INFO_SUBMISSION_FAILED,
    VALIDATE_ROUTING_NUMBER_SUCCESSFUL,
    VALIDATE_ROUTING_NUMBER_FAILED,
    ACCOUNT_CUSTOMER_BILLING_INFO_RETRIEVAL_SUCCESSFUL,
    ACCOUNT_CUSTOMER_BILLING_INFO_RETRIEVAL_FAILED,
    TOGGLE_IS_CREDIT,
    START_LOADING,
    STOP_LOADING,
    ALIGN_STATE,
    RESET_STATE
} from '../../action/customerBillingInfo.actions';

import { GET_ACCOUNT_INFORMATION_SUCCESS, ACCOUNT_CUSTOMER_BILLING_INFO_RETRIEVAL_BUSY } from '../../action/account.actions';

const initialState = {
    customerBillingInfoID: null,
    isUpdating: false,
    hasError: false,
    isRoutingNumberValid: false,
    IsCredit: false,
    PaymentTypeIsCredit: false,
    FirstName: "",
    LastName: "",
    CardNumber: "",
    CardExpirationMonth: "",
    CardExpirationYear: null,
    CardSecurityCode: "",
    CardholderName: "",
    RoutingNumber: "",
    AccountNumber: "",
    wasAutofilled: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_ACCOUNT_INFORMATION_SUCCESS:
            const { RecurringBillingInfo } = action.payload;
            return {
                ...state,
                customerBillingInfoID: RecurringBillingInfo ? RecurringBillingInfo.CustomerBillingInfoID : null 
            };
        case ACCOUNT_CUSTOMER_BILLING_INFO_SUBMISSION_SUCCESSFUL:
            return _.mergeCustom({},
                state,
                {
                    customerBillingInfoID: action.payload.CustomerBillingInfoID,
                    isUpdating: false,
                    hasError: false
                });
        case ACCOUNT_CUSTOMER_BILLING_INFO_SUBMISSION_FAILED:
            return _.mergeCustom({},
                state,
                {
                    isUpdating: false,
                    hasError: true
                });
        case ACCOUNT_CUSTOMER_BILLING_INFO_RETRIEVAL_SUCCESSFUL:
            const {
                AccountName,
                IsCredit,
                LastFour,
                CardExpirationMonth,
                CardExpirationYear,
                CardSecurityCode,
                CardholderName,
                RoutingNumber,
                AccountNumber,
            } = action.payload;
            const [FirstName, LastName] = AccountName ? AccountName.split(" ") : ["",""];
            return {
                ...state,
                hasError: false,
                isUpdating: false,
                IsCredit,
                PaymentTypeIsCredit: IsCredit,
                FirstName,
                LastName,
                LastFour,
                CardNumber: IsCredit && LastFour ? `xxxxxx${LastFour}`: "",
                CardExpirationMonth: `0${CardExpirationMonth}`.slice(-2),
                CardExpirationYear: 2000 + CardExpirationYear,
                CardSecurityCode,
                CardholderName: AccountName,
                RoutingNumber: state.customerBillingInfoID === 0 ? "" : (!IsCredit && !RoutingNumber) ? "" : RoutingNumber,
                AccountNumber: !IsCredit && LastFour ? `xxxxxx${action.payload.LastFour}` : "",
                wasAutofilled: true
            };
        case ACCOUNT_CUSTOMER_BILLING_INFO_RETRIEVAL_FAILED:
            return _.mergeCustom({},
                state,
                {
                    hasError: true,
                    wasAutofilled: false
                });
        case ACCOUNT_CUSTOMER_BILLING_INFO_RETRIEVAL_BUSY:
            return _.mergeCustom({},
                state,
                {
                    isUpdating: true
                });
        case TOGGLE_IS_CREDIT:
            return {
                ...state,
                IsCredit: !state.IsCredit
            };
        case START_LOADING:
            return {
                ...state,
                isUpdating: true
            };
        case STOP_LOADING:
            return {
                ...state,
                isUpdating: false
            };
        case ALIGN_STATE:
            return {
                ...state,
                ...action.payload
            };
        case RESET_STATE:
            const { customerBillingInfoID } = state;
            return {
                isUpdating: false,
                hasError: false,
                isRoutingNumberValid: false,
                IsCredit: false,
                PaymentTypeIsCredit: false,
                FirstName: "",
                LastName: "",
                CardNumber: "",
                CardExpirationMonth: "",
                CardExpirationYear: null,
                CardSecurityCode: "",
                RoutingNumber: "",
                AccountNumber: "",
                wasAutofilled: false,
                customerBillingInfoID
            };
        default:
            return state;
    }
};