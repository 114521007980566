
import template from './disconnectPanelModal.html';

class DisconnectPanelModalController {
    /*@ngInject*/
    constructor(actionContext, constants) {
        this.actionContext = actionContext;
        this.constants = constants;
    }

    onNext() {
        this.executeswap();
    }

    setloader(show) {
        this.onloading({
            isloading: show
        });
    }

    setHelper(show) {
        this.sethelp({
            showhelpModal: show
        });
    }
}

export default {
    template: template,
    bindings: {
        onloading: '&',
        sethelp: '&',
        executeswap: '&'
    },
    controller: DisconnectPanelModalController
};