import * as angular from 'angular';
import ngRedux from 'ng-redux';
import 'angular-ui-router';

import './welcome.scss';

import boxcheckModule from '../boxcheck';
import commonModule from '../common';
import predispatchModule from '../predispatch';
import premisesModule from '../premises';

import welcomeHomeComponent from './home.component';
import settingUpComponent from './settingUp.component';

export default angular
    .module('fp:activationswizard:welcome',
        [
            'ui.router',
            ngRedux,
            boxcheckModule.name,
            commonModule.name,
            predispatchModule.name,
            premisesModule.name
        ])
    .config(/*@ngInject*/
        ($stateProvider, constants) => {
            $stateProvider
                .state('welcome',
                    {
                        abstract: true,
                        parent: 'secure',
                        url: '/welcome',
                        template: '<ui-view></ui-view>',
                        data: {
                            next: /*@ngInject*/ ($state, appActions, $ngRedux) => {
                                appActions.completeModule(constants.sectionTypes.welcome);
                                if ($ngRedux.getState().account.customer.isVideoOnly) {
                                    $state.go('account.premise-address');
                                } else {
                                    $state.go('menu.home');
                                }
                            },
                            prev: /*@ngInject*/ () => {

                            },
                            sectionType: constants.sectionTypes.welcome,
                            theme: 'app-theme-default-navbar-gray',
                            isHydratable: false
                        }
                    })
                .state('welcome.settingUp',
                    {
                        url: '',
                        template: '<aw-setting-up></aw-setting-up>',
                        next: /*@ngInject*/ ($state) => {
                            $state.go('menu.home');
                        },
                        data: {
                            isHydratable: false
                        }
                    })
                .state('welcome.home',
                    {
                        url: '',
                        template: '<aw-welcome-home></aw-welcome-home>',
                        onEnter: /*@ngInject*/ (appActions) => {
                            appActions.startModule(constants.sectionTypes.welcome);
                        },
                        data: {
                            isHydratable: false
                        }
                    });

        })
    .component('awWelcomeHome', welcomeHomeComponent)
    .component('awSettingUp', settingUpComponent);
