import * as _ from 'lodash';
import ngRedux from 'ng-redux';
import { Unsubscribe } from 'redux';

export class WifiConnectedController {
    public hasNetworkName: boolean;
    public networkName: string;

    private unsubscribe: Unsubscribe;

    constructor(
        private $ngRedux: ngRedux.INgRedux,
        public constants: any
    
    ) {
        'ngInject';
    }

    public $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this.mapState.bind(this))(this);
        this.hasNetworkName = !_.isNil(this.networkName);
    }

    public $onDestroy() {
        this.unsubscribe();
    }

    private mapState(state: any) {
        return {
            networkName: state.system.wifi.networkName,
            targetSku: state.account.airfxFlow.targetSku,
            isInitialOrder: state.account.user.isInitialOrder
        };
    }
}

export const wifiConnectedComponent = {
    template: require('./connected.html'),
    bindings: {},
    controller: WifiConnectedController
};
