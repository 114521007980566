import template from './panelSignalStrength.html';

class PanelSignalStrengthComponent {
    /*@ngInject*/
    constructor($ngRedux, actionContext) {
        this.actionContext = actionContext;

        // setup
        $ngRedux.connect(this._mapState)(this);
        this.show = false;
    }

    $onInit() {
        this.actionContext.panel.fetchSignalStrength().then(() => {
            this._processSignalStrengthDescription();
        });
    }

    _mapState(state) {
        return {
            signalStrength: state.system.panel.signalStrength,
            lastSignalDate: state.system.panel.lastSignalDate
        };
    }

    _processSignalStrengthDescription() {
        switch(this.signalStrength) {
            case '0 Bars':
                this.signalStrengthDescription = 'Your panel is not showing a signal.';
                break;
            case '1 Bars':
            case '2 Bars':
                this.signalStrengthDescription = 'Your panel is showing a poor signal.';
                break;
            case '3 Bars':
            case '4 Bars':
                this.signalStrengthDescription = 'Your panel is showing a satisfactory signal.';
                break;
            case '5 Bars':
            case '6 Bars':
                this.signalStrengthDescription = 'Your panel is showing a good signal.';
                break;
            default :
                this.show = false;
        }
    }
}

export default {
template: 
    template,
    bindings: {},
    controller: PanelSignalStrengthComponent
};