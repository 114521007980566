import * as _ from 'lodash';
import './categoryEmpty.scss';
import template from './categoryEmpty.html';

class CategoryEmptyController {
    /*@ngInject*/
    constructor($state, segment, constants) {
        this.category = $state.params.category;
        this.segment = segment;
        this.constants = constants;
    }

    $onInit() {
        if (this.category === 'panels') {
            this.segment.registerEvent(this.constants.events.segment.panelSwapKeyScreenLanding, {});
        }
    }
}

export default {
    template: template,
    bindings: {},
    controller: CategoryEmptyController
};