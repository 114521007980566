import * as _ from 'lodash';

import template from './howTo.html';

const CATEGORY = 'training';

class HowToController {
    /*@ngInject*/
    constructor ($ngRedux, $timeout, actionContext, constants) {
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
        this.actionContext = actionContext;
        this.constants = constants;
    }

    $onInit() {
        this.unsubscribe = this.$ngRedux.connect(this._mapState.bind(this))(this);
    }

    $onDestroy() {
        this.unsubscribe();
    }

    _mapState(state) {
        return {
            panel: state.system.panel,
            videos: state.application.config.video.items
        };
    }

    videoFilter(video) {
        return video.category === CATEGORY &&
               video.condition.panel === this.panel.type;
    }
}

export default {
    template: template,
    bindings: {},
    controller: HowToController
};