import * as angular from 'angular';
import ngRedux from 'ng-redux';
import modals from './modals';
import 'angular-animate';

import barcodeModule from './barcode';
import appStoreBadgeComponent from './appStoreBadge.component';
import desktopOverlayComponent from './desktopOverlay.component';
import errorBannerComponent from './errorBanner.component';
import helpOverlayComponent from './helpOverlay.component';
import indeterminateProgressOverlayComponent from './indeterminateProgressOverlay.component';
import loaderProgressOverlay from './loaderProgressOverlay.component';
import panelImgComponent from './panelImg.component';
import phoneLinkComponent from './phoneLink.component';
import productImgComponent from './productImg.component';
import simpleModalComponent from './simpleModal.component';
import spinnerComponent from './spinner.component';
import verticalOverlayComponent from './verticalOverlay.component';
import panelSignalStrengthComponent from './panelSignalStrength.component';
import popupNotificationComponent from './popupNotification.component';
import ZWaveOverlayComponent from './zWaveOverlay.component';
import simpleModalV2Component from './simpleModalV2.component';
import fullScreenModalComponent from './fullScreenModal.component';

export default angular.module('fp:activationswizard:common:ui', [
        'ngAnimate',
        'ui.bootstrap',    
        ngRedux,
        modals.name,
        barcodeModule.name
    ]).config([
        '$compileProvider',
        ($compileProvider) => {
            $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|tel|mailto|android-app|tel):/);
        }
    ])
    .component('awAppStoreBadge', appStoreBadgeComponent)
    .component('awDesktopOverlay', desktopOverlayComponent)
    .component('awErrorBanner', errorBannerComponent)
    .component('awHelpOverlay', helpOverlayComponent)
    .component('awIndeterminateProgressOverlay', indeterminateProgressOverlayComponent)
    .component('awLoaderProgressOverlay', loaderProgressOverlay)
    .component('awPanelImg', panelImgComponent)
    .component('awPhoneLink', phoneLinkComponent)
    .component('awProductImg', productImgComponent)
    .component('awSimpleModal', simpleModalComponent)
    .component('awSimpleModalV2', simpleModalV2Component)
    .component('awSpinner', spinnerComponent)
    .component('awVerticalOverlay', verticalOverlayComponent)
    .component('awPanelSignalStrength', panelSignalStrengthComponent)
    .component('awPopupNotification', popupNotificationComponent)
    .component('awFullScreenModal', fullScreenModalComponent)
    .component("awZwaveOverlay", ZWaveOverlayComponent);
