import './popupNotification.scss';

import * as _ from 'lodash';

import template from './popupNotification.html';

class PopupNotificationController {
    /*@ngInject*/
    constructor($rootScope, $window, $timeout, constants, $state, $document, $ngRedux, actionContext) {
        this.$rootScope = $rootScope;
        this.$window = $window;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$document = $document;
        this.constants = constants;
        this.actionContext = actionContext;
        this.isDismissed = false;
        this.retries = 0;
        this.$ngRedux = $ngRedux;
        this.unsubscribe = $ngRedux.connect(this._mapState.bind(this))(this);
        this.customerSupportPhone = this.constants.customerSupportHub.phone;
    }

    $onInit() {
        this.unsubscribeStateChange = this.$rootScope.$on(this.constants.events.stateChangeSuccess, () => {
            this.isDismissed = false;
            this.displayNotification = false;
            this.retries = 0;
            let timeout = this.$state.$current.toString() === this.constants.routerStates.swapSensorTransfer
                ? this.constants.callSupportDelayForSwapInSeconds
                : this.constants.callSupportDelayInSeconds;
            this.delay = parseInt(timeout) * 1000;
            if (!_.isNil(this.sendAlert))
                this.$timeout.cancel(this.sendAlert);
            if (!_.some(this.constants.callSupportNotificationExceptions, (state) => {
                return state === this.$state.$current.toString() || state === this.$state.$current.parent.toString();
            })) {
                this.sendAlert = this.$timeout(() => {
                    this._checkPhoneNumber();
                    this.displayNotification = true;
                }, this.delay);
            }
            this._filterPagesNotToShowMessage();
        });

        this.unsubscribeRetries = this.$rootScope.$on(this.constants.events.callSupportNotification.name, () => {
            this.retries++;
            if (this.retries > (this.constants.events.callSupportNotification.intervalCount)) {
                this._checkPhoneNumber();
                this.displayNotification = true;
            }
            this._filterPagesNotToShowMessage();
        });
    }

    $onDestroy() {
        this.unsubscribeStateChange();
        this.unsubscribeRetries();
    }

    _checkPhoneNumber() {
        if (this.targetSku === this.constants.panelVersions.iqPanel) {
            this.customerSupportPhone = this.constants.customerSupportIQ.phone;
        } else {
            this.customerSupportPhone = !this.$ngRedux.getState().account.origin.startedOnAmazon
                ? this.constants.customerSupportHub.phone
                : this.constants.customerSupportHub.amazonPhone;
        }
    }

    _filterPagesNotToShowMessage() {
        if (this.currentState.name === (this.constants.routerStates.airfxNoSensors
            || this.constants.routerStates.welcome
            || this.constants.routerStates.activationComplete)) {
            this.displayNotification = false;
        }
    }

    showNotification() {
        return !this.isDismissed && this.displayNotification;
    }

    closeNotification() {
        this.displayNotification = false;
        this.isDismissed = true;
    }

    _mapState(state) {
        return {
            targetSku: state.account.airfxFlow.targetSku,
            currentState: state.router.currentState
        };
    }
}

export default {
    template: template,
    bindings: {},
    controller: PopupNotificationController
};