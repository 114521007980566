import * as _ from 'lodash';
import error from '../../util/error';
import moment from 'moment';

import { SENSORS_GET_REQUEST, SENSORS_GET_FAILURE, SENSORS_GET_SUCCESS, RESET_DEVICES, SENSORS_GET_ASW_SUCCESS } from '../../action/device.actions';
import { SENSOR_UPDATE_REQUEST, SENSOR_UPDATE_FAILURE, SENSOR_UPDATE_SUCCESS } from '../../action/device.actions';
import { SENSOR_UPDATE_NAME_REQUEST, SENSOR_UPDATE_NAME_FAILURE, SENSOR_UPDATE_NAME_SUCCESS } from '../../action/device.actions';
import { SENSOR_START_SETUP, SENSOR_STOP_SETUP, SENSOR_FINISH_SETUP, SENSOR_SKIP_SETUP, SENSOR_EXCEPTION_DEVICE_START_SETUP, SENSOR_EXCEPTION_DEVICE_FINISH_SETUP } from '../../action/device.actions';
import { SENSOR_POLL_REQUEST, SENSOR_POLL_FAILURE, SENSOR_POLL_SUCCESS, SENSOR_POLL_COMPLETE } from '../../action/device.actions';
import { SENSOR_INSTALLATION_INSTRUCTIONS } from '../../action/device.actions';

import { PANEL_ADD_SENSOR_REQUEST, PANEL_ADD_SENSOR_FAILURE, PANEL_ADD_SENSOR_SUCCESS } from '../../action/panel.actions';
import { PANEL_REMOVE_SENSOR_REQUEST, PANEL_REMOVE_SENSOR_FAILURE, PANEL_REMOVE_SENSOR_SUCCESS } from '../../action/panel.actions';

const initialState = {
    ignore: ['selectedSensor', 'selectedSensorID', 'lastUpdatedDate', 'isFetching', 'isPolling', 'isUpdating', 'hasError', 'message', 'sensorsByID'],
    sensorsByID: {},
    selectedSensor: null,
    selectedSensorID: null,
    namedByID: {},
    completeByID: {},
    installationInstructionsViewed: [],
    lastUpdatedDate: null,
    isFetching: false,
    isPolling: false,
    isUpdating: false,
    hasError: false,
    message: null,
    isComplete: false
};

const initialSensorState = {
    ignore: ['isPolling', 'isUpdating'],
    isComplete: false,
    isNamed: false,
    isPolling: false,
    isSkipped: false,
    isUpdating: false
};

let sensorFn = (state, action) => {
    switch (action.type) {

        case RESET_DEVICES:
            _.forEach(state.sensorsByID,
                (sensor) => {
                    sensor.isSkipped = false;
                });
            return state;
        case SENSORS_GET_REQUEST:
            return _.mergeCustom({}, state, {
                isFetching: true,
                hasError: false,
                message: null
            });
        case SENSORS_GET_FAILURE:
            return _.mergeCustom({}, state, {
                isFetching: false,
                hasError: true,
                message: error.parse(action.payload.error)
            });
        case SENSORS_GET_SUCCESS:
            return _.mergeCustom({}, state, { sensorsByID: null }, {
                sensorsByID: _.keyBy(_.map(action.payload.data, (newSensorState) => {
                    const existingSensorState = _.find(state.sensorsByID, sensor => sensor.DLCode === newSensorState.DLCode);
                    return _.mergeCustom(initialSensorState, existingSensorState, newSensorState);
                }), (value) => {
                    return value.SensorID;
                }),
                lastUpdatedDate: moment.utc(),
                isFetching: false
            });
        case SENSORS_GET_ASW_SUCCESS:
            return _.mergeCustom(initialSensorState, state, {
                aswSensors: action.payload.data
            });
        case SENSOR_UPDATE_NAME_REQUEST:
        case SENSOR_UPDATE_REQUEST:
            return _.mergeCustom({}, state, {
                isUpdating: true,
                hasError: false,
                message: null
            });
        case SENSOR_UPDATE_NAME_FAILURE:
        case SENSOR_UPDATE_FAILURE:
            return _.mergeCustom({}, state, {
                isUpdating: false,
                hasError: true,
                message: error.parse(action.payload.error)
            });
        case SENSOR_UPDATE_SUCCESS:
            return _.mergeCustom({}, state, {
                sensorsByID: _.mergeCustom({}, state.sensorsByID, {
                    [action.payload.data.SensorID]: _.mergeCustom({}, state.sensorsByID[action.payload.data.SensorID], action.payload.data, {
                        isUpdating: false
                    })
                }),
                lastUpdatedDate: moment.utc(),
                isUpdating: false
            });
        case SENSOR_UPDATE_NAME_SUCCESS:
            return _.mergeCustom({}, state, {
                sensorsByID: _.mergeCustom({}, state.sensorsByID, {
                    [action.payload.sensor.SensorID]: _.mergeCustom({}, state.sensorsByID[action.payload.sensor.SensorID], action.payload.sensor, {
                        isNamed: true,
                        isUpdating: false
                    })
                }),
                namedByID: _.mergeCustom({}, state.namedByID, {
                    [action.payload.sensor.SensorID]: true
                }),
                lastUpdatedDate: moment.utc(),
                isUpdating: false
            });
        default:
            return state;
    }
};

let setupFn = (state, action) => {
    switch (action.type) {
        case SENSOR_START_SETUP:
            return _.mergeCustom({}, state, {
                selectedSensorID: action.payload.data
            });
        case SENSOR_STOP_SETUP:
            return _.mergeCustom({}, state, {
                selectedSensorID: null
            });
        case SENSOR_SKIP_SETUP:
            return _.mergeCustom({}, state, {
                sensorsByID: _.mergeCustom({}, state.sensorsByID, {
                    [state.selectedSensorID]: _.mergeCustom({}, state.sensorsByID[state.selectedSensorID], {
                        isSkipped: true
                    })
                })
            });
        case SENSOR_FINISH_SETUP:
            return _.mergeCustom({}, state, {
                sensorsByID: _.mergeCustom({}, state.sensorsByID, {
                    [state.selectedSensorID]: _.mergeCustom({}, state.sensorsByID[state.selectedSensorID], {
                        isComplete: true,
                        isSkipped: false
                    })
                }),
                completeByID: _.mergeCustom({}, state.completeByID, {
                    [state.selectedSensorID]: true
                })
            });
        case SENSOR_EXCEPTION_DEVICE_START_SETUP:
            return _.mergeCustom({}, state, {
                selectedSensorID: action.payload.data
            });
        case SENSOR_EXCEPTION_DEVICE_FINISH_SETUP:
            return _.mergeCustom({}, state, {
                sensorsByID: _.mergeCustom({}, state.sensorsByID, {
                    [state.selectedSensorID]: _.mergeCustom({}, action.payload.data, {
                        isComplete: true,
                        isSkipped: false
                    })
                }),
                completeByID: _.mergeCustom({}, state.completeByID, {
                    [state.selectedSensorID]: true
                })
            });
        default:
            return state;
    }
};

let pollFn = (state, action) => {
    switch (action.type) {
        case SENSOR_POLL_REQUEST:
            return _.mergeCustom({}, state, {
                isPolling: true,
                hasError: false,
                message: null
            });
        case SENSOR_POLL_FAILURE:
            return _.mergeCustom({}, state, {
                hasError: true,
                message: error.parse(action.payload.error)
            });
        case SENSOR_POLL_SUCCESS:
            return _.mergeCustom({}, state, {
                lastUpdatedDate: moment.utc()
            });
        case SENSOR_POLL_COMPLETE:
            return _.mergeCustom({}, state, {
                isPolling: false
            });
        default:
            return state;
    }
};

let addSensorFn = (state, action) => {
    switch (action.type) {
        case PANEL_ADD_SENSOR_REQUEST:
            return _.mergeCustom({}, state, {
                isUpdating: true,
                hasError: false,
                message: null
            });
        case PANEL_ADD_SENSOR_FAILURE:
            return _.mergeCustom({}, state, {
                isUpdating: false,
                hasError: true,
                message: action.payload.error
            });
        case PANEL_ADD_SENSOR_SUCCESS:
            return _.mergeCustom({}, state, {
                isUpdating: false,
                sensorsByID: _.mergeCustom({}, state.sensorsByID, {
                    [action.payload.sensor.SensorID]: _.mergeCustom({}, action.payload.sensor)
                })
            });
        default:
            return state;
    }
};

let removeSensorFn = (state, action) => {
    switch (action.type) {
        case PANEL_REMOVE_SENSOR_REQUEST:
            return _.mergeCustom({}, state, {
                isUpdating: true,
                hasError: false,
                message: null
            });
        case PANEL_REMOVE_SENSOR_FAILURE:
            return _.mergeCustom({}, state, {
                isUpdating: false,
                hasError: true,
                message: action.payload.error
            });
        case PANEL_REMOVE_SENSOR_SUCCESS:
            const newState = _.mergeCustom({}, state, {
                isUpdating: false
            });

            const sensorId = action.payload.sensor.SensorID;
            newState.sensorsByID[sensorId] = _.mergeCustom({}, newState.sensorsByID[sensorId], {
                IsConnected: false
            });

            return newState;
        default:
            return state;
    }
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SENSORS_GET_REQUEST:
        case SENSORS_GET_FAILURE:
        case SENSORS_GET_SUCCESS:
        case SENSORS_GET_ASW_SUCCESS:
        case SENSOR_UPDATE_REQUEST:
        case SENSOR_UPDATE_FAILURE:
        case SENSOR_UPDATE_SUCCESS:
        case SENSOR_UPDATE_NAME_REQUEST:
        case SENSOR_UPDATE_NAME_FAILURE:
        case SENSOR_UPDATE_NAME_SUCCESS:
        case RESET_DEVICES:
            return sensorFn(state, action);
        case SENSOR_START_SETUP:
        case SENSOR_STOP_SETUP:
        case SENSOR_FINISH_SETUP:
        case SENSOR_EXCEPTION_DEVICE_START_SETUP:
        case SENSOR_EXCEPTION_DEVICE_FINISH_SETUP:
        case SENSOR_SKIP_SETUP:
            return setupFn(state, action);
        case SENSOR_POLL_REQUEST:
        case SENSOR_POLL_FAILURE:
        case SENSOR_POLL_SUCCESS:
        case SENSOR_POLL_COMPLETE:
            return pollFn(state, action);
        case SENSOR_INSTALLATION_INSTRUCTIONS:
            return _.mergeCustom({}, state, {
                installationInstructionsViewed: _.union(state.installationInstructionsViewed, [action.payload.sensor.ProductSku])
            });
        case PANEL_ADD_SENSOR_REQUEST:
        case PANEL_ADD_SENSOR_FAILURE:
        case PANEL_ADD_SENSOR_SUCCESS:
            return addSensorFn(state, action);
        case PANEL_REMOVE_SENSOR_REQUEST:
        case PANEL_REMOVE_SENSOR_FAILURE:
        case PANEL_REMOVE_SENSOR_SUCCESS:
            return removeSensorFn(state, action);
        default:
            return state;
    }
};