export default class WifiDataService {

    constructor(private Restangular: any) {
        'ngInject';
    }

    public async refreshWifiNetworks() {
        return this.Restangular
            .all('Wifi')
            .one('Refresh')
            .customPUT();
    }

    public async getWifiNetworks() {
        return this.Restangular
            .all('Wifi')
            .withHttpConfig({cache: false}) 
            .customGET();
    }

    public async getDualPath() {
        return this.Restangular
            .all('Wifi')
            .one('DualPath')
            .withHttpConfig({cache: false}) 
            .get();
    }

    public async setWifi(info: any) {
        return this.Restangular
            .all('Wifi')
            .customPUT(info);
    }

    public async refreshWifi() {
        return this.Restangular
            .all('Wifi')
            .one('RefreshWifi')
            .get();
    }

    public async enableWifi() {
        return this.Restangular
           .all('Wifi')
           .one('EnableWifi')
           .customPUT();
    }

}
